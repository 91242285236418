import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Layer, ThemeContext } from 'grommet'

import SubInfoPanelContent from 'components/leveling/levelingPanel/SubInfoPanelContent'

class SubInfoPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  open = panelProps => {
    this.setState({
      open: true,
      panelProps
    })
  }

  close = () => {
    this.setState({
      open: false
    })
    this.props.onClose()
  }

  render () {
    const { open, panelProps } = this.state
    if (open) {
      return (
        <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
          <Layer modal position='right' full='vertical' onEsc={this.close} onClickOutside={this.close}>
            <Box
              fill='vertical'
              width='400px'
              border='start'
              customStyle={`box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)`}
            >
              <SubInfoPanelContent {...panelProps} onClose={this.close} />
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )
    } else {
      return null
    }
  }
}

SubInfoPanel.defaultProps = {
  onClose: () => null
}

SubInfoPanel.propTypes = {
  onClose: PropTypes.func
}

export default SubInfoPanel
