import React from 'react'
import { Box, Text } from 'grommet'
import numeral from 'numeral'

import colors from 'shared/constants/colors'

const ItemTotal = ({ total }) => {
  const v = numeral(total).format('$0,0.[00]')
  return (
    <Box pad={{ top: 'xsmall', left: 'xsmall' }}>
      <Text color={colors.LIGHT_NAVY_BRIGHT} weight={600}>
        {v}
      </Text>
    </Box>
  )
}

export default React.memo(ItemTotal)
