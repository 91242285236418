import { db, firestore } from 'constants/firebase'
import * as Permissions from 'expo-permissions'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import Sentry from 'shared/utils/sentry'
import colors from 'shared/constants/colors'

// console.log('Sentry', Sentry)

export const unsubscribeDeviceFromNotifications = async userId => {
  try {
    const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
    if (existingStatus === 'granted') {
      const token = (await Notifications.getExpoPushTokenAsync()).data
      const upd = { tokens: firestore.FieldValue.arrayRemove(token) }
      db.collection('expoPushTokens')
        .doc(userId)
        .set(upd, { merge: true })
    }
  } catch (e) {
    console.log('unsubscribe device from notification failed', e)
  }
}

export const registerForPushNotificationsAsync = async userId => {
  try {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
      let finalStatus = existingStatus
      console.log('permission notifications status', existingStatus)
      if (existingStatus !== 'granted') {
        console.log('ask notifictions permission')
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!')
        return
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data
      console.log('expo push token:', token)
      const upd = { tokens: firestore.FieldValue.arrayUnion(token) }
      db.collection('expoPushTokens')
        .doc(userId)
        .set(upd, { merge: true })
    } else {
      console.log('Must use physical device for Push Notifications')
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('Mason', {
        name: 'Mason',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: colors.AQUA_MARINE,
        enableLights: true
      })
    }
  } catch (e) {
    console.warn('registerForPushNotificationsAsync failed', e)
    Sentry.captureException(e)
  }
}
