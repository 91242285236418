export const FAILED = 'failed'
export const NEW = 'new'
export const SENT = 'sent'
export const DELIVERED = 'delivered'
export const READ = 'read'

export default {
  NEW,
  SENT,
  DELIVERED,
  READ,
  FAILED
}