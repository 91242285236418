import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, ActivityIndicator } from 'react-native'
import { StyledText } from 'shared/components/StyledComponents'
import colors from 'shared/constants/colors'

const Container = styled(View)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const Loading = ({ text, children, hideActivityIndicator }) => (
  <Container>
    <StyledText color={colors.LIGHT_NAVY} fontSize={14} customStyle='margin-bottom: 10px;'>
      {text}
    </StyledText>
    {!hideActivityIndicator && <ActivityIndicator color={colors.LIGHT_NAVY} />}
    {children}
  </Container>
)

Loading.defaultProps = {
  text: 'Loading...',
  hideActivityIndicator: false
}

Loading.propTypes = {
  text: PropTypes.string,
  hideActivityIndicator: PropTypes.bool
}

export default Loading
