import _ from 'lodash'
import { formatNational } from 'shared/utils/phone'

export const getEmails = p => {
  return _.uniq(_.compact([_.get(p, 'email'), ..._.get(p, 'emails', [])]))
}

export const getPhones = p => {
  return _.uniq(_.compact([_.get(p, 'phone'), ..._.get(p, 'phones', [])]))
}

export const getEmailsPhonesString = p => {
  const emails = getEmails(p)
  const phones = _.map(getPhones(p), formatNational)
  return _.join([...emails, ...phones], ', ')
}
