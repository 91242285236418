import { storageRef } from 'constants/firebase'
// import Sentry from 'shared/utils/sentry'
import * as Sentry from '@sentry/react'

// the method receives local url
export async function saveFile (storagePath, fileUrl, onProgress = () => null) {
  try {
    console.log('saveFile', storagePath, fileUrl)
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.log('saveFile error', e)
        Sentry.captureException(e)
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileUrl, true)
      xhr.send(null)
    })
    const uploadTask = storageRef.child(storagePath).put(blob)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.totalBytes)
    const fileData = await Promise.all([snapshot.ref.getDownloadURL(), snapshot.ref.getMetadata()])
    const url = fileData[0]
    const { contentType } = fileData[1]
    return {
      url,
      size: snapshot.totalBytes,
      contentType
    }
  } catch (error) {
    console.log(error)
  }
}

export async function deleteFile (storagePath) {
  try {
    // Kasim: I commented this out, we wil not really delete files
    // const snapshot = await storageRef.child(storagePath).delete()
    // return snapshot
    return null
  } catch (e) {
    console.log('Storage delete file error: ', e)
    // Sentry.captureException(e)
  }
}

export async function saveImageBase64DataUrl (storagePath, base64Data, onProgress = () => null) {
  const url = await saveFile(storagePath, base64Data, onProgress)
  return url
  // } else {
  //   const base64 = base64Data.substring(22)
  //   const fileUri = FileSystem.documentDirectory + 'sig.png'
  //   await FileSystem.writeAsStringAsync(fileUri, base64, { encoding: FileSystem.EncodingType.Base64 })
  //   url = await saveFile(storagePath, fileUri, onProgress)
  //   FileSystem.deleteAsync(fileUri, { idempotent: true })
  // }
  // return url
}

export async function getContentType (url) {
  const storagePath = url
    .split('o/')[1]
    .split('?alt')[0]
    .split('%2F')
    .join('/')
  const { contentType } = await storageRef.child(storagePath).getMetadata()
  return contentType
}

export const saveFileObject = async (fileObject, storagePath, onProgress = () => null) => {
  try {
    console.log('saveFileObject', fileObject, storagePath)
    const uploadTask = storageRef.child(storagePath).put(fileObject)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.totalBytes)
    const fileData = await Promise.all([snapshot.ref.getDownloadURL(), snapshot.ref.getMetadata()])
    const url = fileData[0]
    const { contentType } = fileData[1]
    return {
      url,
      size: snapshot.totalBytes,
      contentType
    }
  } catch (e) {
    console.log('saveFileObject error: ', e)
  }
}

export default {
  saveFile,
  deleteFile,
  saveImageBase64DataUrl,
  getContentType
}
