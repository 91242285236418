import React, { useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { Collapsible, Button } from 'grommet'

import LayoutContext from 'webPages/layout/LayoutContext'

const HamburgerButton = () => {
  useEffect(() => {
    console.log('---HamburgerButton CONSTRUCTOR---')
  }, [])
  const { sideMenuOpen, toggleSideMenu } = useContext(LayoutContext)
  const onButtonClick = () => toggleSideMenu(true)
  // console.log('HamburgerButton --> sideMenuOpen', sideMenuOpen)
  return (
    <Collapsible direction='horizontal' open={!sideMenuOpen} baseline={20}>
      <Button
        plain
        label={<FontAwesomeIcon icon={faBars} size={20} />}
        margin={{ right: 'small' }}
        onClick={onButtonClick}
      />
    </Collapsible>
  )
}

export default React.memo(HamburgerButton)
