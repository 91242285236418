import * as types from 'model/actionTypes'

export function receiveProfile (userId, profile) {
  return {
    type: types.RECEIVE_PROFILE,
    userId,
    profile
  }
}

export function receiveProfiles (profiles) {
  return {
    type: types.RECEIVE_PROFILES,
    profiles
  }
}

export function receiveProfileChange (id, key, value) {
  return {
    type: types.RECEIVE_PROFILE_CHANGE,
    id,
    key,
    value
  }
}

export function receiveMasonAdmins (masonAdmins) {
  return {
    type: types.RECEIVE_MASON_ADMINS,
    masonAdmins
  }
}
