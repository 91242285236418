import React, { useContext } from 'react'
import { Box, Collapsible } from 'grommet'

import LayoutContext from 'webPages/layout/LayoutContext'

const SideBarWrapper = ({ children }) => {
  const { sideMenuOpen } = useContext(LayoutContext)
  return (
    <Collapsible direction='horizontal' open={sideMenuOpen}>
      <Box as='nav' width={'280px'} background='brand' height={{ min: '100%' }}>
        {children}
      </Box>
    </Collapsible>
  )
}

export default SideBarWrapper
