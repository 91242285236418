import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import numeral from 'numeral'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'

const SpaceBetweenFlexDiv = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  ${props => props.customStyle || ''}
`
const LineItemsTotal = styled(View)`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
const TitleContainer = styled(View)`
  display: flex;
  flex: 7;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`

const Total = ({ getSize, title, total, hideBorder, customStyle, perc }) => {
  return (
    <SpaceBetweenFlexDiv
      customStyle={`
        border: 0;
        border-style: solid;
        border-top-width: ${hideBorder ? 0 : '1px'};
        border-top-color: ${colors.TWILIGHT_BLUE};
        padding-top: ${getSize(12)}px;
        ${customStyle}
      `}
    >
      <TitleContainer getSize={getSize}>
        <StyledText bold fontSize={getSize(12)} textAlign='right'>
          {title}
        </StyledText>
        {perc && (
          <StyledText fontSize={getSize(12)} customstyle={`margin-left: ${getSize(4)}px`}>{`(${perc}%)`}</StyledText>
        )}
      </TitleContainer>
      <LineItemsTotal>
        <StyledText bold fontSize={getSize(12)} textAlign='right'>
          {!_.isNil(total) && numeral(total).format('$0,0.00')}
        </StyledText>
      </LineItemsTotal>
    </SpaceBetweenFlexDiv>
  )
}

Total.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  getSize: PropTypes.func.isRequired,
  hideBorder: PropTypes.bool
}

export default Total
