import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Layer, Text, CheckBox, DropButton, ThemeContext, Heading } from 'grommet'
import Tip from 'components/Tip'
import _ from 'lodash'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
// import { faTimes, faPlus } from '@fortawesome/pro-light-svg-icons'
// import { faCheck, faTrash, faClipboardCheck } from '@fortawesome/pro-solid-svg-icons'
// import moment from 'moment'
import Table, { Column, AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'

// import lineItemType from 'shared/constants/lineItemType'
import colors from 'shared/constants/colors'
import { formatNational } from 'shared/utils/phone'
import Tag from 'components/contacts/Tag'
// import { getWorkOrdersWithTitles, getWorkOrderIdsByLineItemId } from 'model/selectors/workOrdersSelector'
// import { updateImportedLi } from 'controllers/project'
// // import RemoveImportedLi from 'webPages/workOrder/RemoveImportedLi'
// import ImportedLiPanelFilterMenu from 'webPages/workOrder/importedLiPanel/ImportedLiPanelFilterMenu'
// import SectionsSelector from 'shared/pages/createBid/addLineItemFromTemplate/SectionsSelector'

const ALL_OPTIONS = 'ALL_OPTIONS'

// const renderCellData = (cellData, rowData, workOrderIdByLineItemId) => {
//   if (_.isBoolean(cellData)) {
//     return cellData ? (
//       <Box fill align='center' justify='center'>
//         <FontAwesomeIcon icon={faCheck} size={12} color={colors.AQUA_MARINE} />
//       </Box>
//     ) : (
//       ''
//     )
//   } else {
//     const added = _.size(_.get(workOrderIdByLineItemId, rowData.id, [])) > 0
//     return (
//       <Tip message={cellData} down>
//         <Text size='small' color={added ? colors.ANOTHER_GREY : colors.DARK_GRAY_TWO}>
//           {cellData}
//         </Text>
//       </Tip>
//     )
//   }
// }

const TableHeaderCell = ({ column }) => {
  return (
    // <Tip message={column.title} down>
    <Text size='small'>{column.title}</Text>
    // </Tip>
  )
}

const ContactsTable = ({ selectedRows, setSelectedRows, currentFilters }) => {
  const stateContacts = useSelector(state => _.get(state, 'contacts', {}))
  const contacts = _.get(stateContacts, 'contacts')
  const companies = _.get(stateContacts, 'companies')
  const [sortBy, setSortBy] = useState({})

  console.log('currentFilters', currentFilters)

  const data = useMemo(() => {
    const res = []
    _.forEach(contacts, c => {
      let needed = true
      if (_.has(currentFilters, 'byTags')) {
        const filterTags = _.map(currentFilters.byTags.options, opt => opt.value)
        if (!_.isEmpty(filterTags)) {
          needed = !_.isEmpty(_.intersection(filterTags, c.tags))
        }
      }
      if (needed) {
        res.push({
          id: c.id,
          name: c.name,
          company: _.get(companies, [c.companyId, 'name']),
          emails: _.join(c.emails, ', '),
          phones: _.join(_.map(c.phones, formatNational), ', '),
          tags: c.tags
        })
      }
    })
    return res
  }, [contacts, companies, currentFilters])

  const sortedData = useMemo(() => _.orderBy(data, [sortBy.key], [sortBy.order]), [data, sortBy])

  const headers = ['name', 'company', 'emails', 'phones']
  const selectedRowsDict = useMemo(() => _.keyBy(selectedRows), [selectedRows])

  const renderCell = ({ rowData, cellData }) => {
    return (
      <Text size='small' color={colors.DARK_GRAY_TWO}>
        {cellData}
      </Text>
    )
  }

  const renderRow = ({ rowData, cells }) => {
    const selected = false
    return (
      <Box key={_.get(rowData, 'id')} background={selected ? colors.RIPTIDE10 : colors.WHITE} fill direction='row'>
        {cells}
      </Box>
    )
  }

  const selectAll = () => {
    if (_.size(selectedRows) < _.size(contacts)) {
      setSelectedRows(_.keys(contacts))
    } else {
      setSelectedRows([])
    }
  }

  const selectRow = i => {
    if (_.has(selectedRowsDict, i)) {
      setSelectedRows(_.pull(selectedRows, i))
    } else {
      setSelectedRows([i, ...selectedRows])
    }
  }

  const onSort = sortBy => {
    console.log('onSort', sortBy)
    setSortBy(sortBy)
  }

  return (
    <ThemeContext.Extend
      value={{
        checkBox: {
          hover: {
            border: {
              color: colors.AQUA_MARINE
            }
          },
          size: '18px',
          color: colors.AQUA_MARINE
        }
      }}
    >
      <Box fill pad={{ right: 'medium' }}>
        {/* {this.renderFilters()} */}
        {/* <Box  overflow='auto' width='100%' pad={{ bottom: 'small' }}> */}
        <AutoResizer key='autoresizer'>
          {({ width, height }) => (
            <Table
              key='contacts_table'
              data={sortedData}
              width={width}
              height={height}
              components={{ TableCell: renderCell, TableHeaderCell }}
              ignoreFunctionInColumnCompare={false}
              rowRenderer={renderRow}
              onColumnSort={onSort}
              sortBy={sortBy}
              // fixed
            >
              <Column
                key='select'
                dataKey='select'
                headerRenderer={() => (
                  <Box width='50px' align='center'>
                    <CheckBox
                      checked={_.isEqual(_.size(selectedRows), _.size(data))}
                      onChange={selectAll}
                      indeterminate={_.size(selectedRows) > 0 && !_.isEqual(_.size(selectedRows), _.size(contacts))}
                    />
                  </Box>
                )}
                cellRenderer={({ rowData }) => (
                  <Box width='50px' align='center'>
                    <CheckBox
                      checked={_.has(selectedRowsDict, `${_.get(rowData, 'id')}`)}
                      onChange={() => selectRow(`${_.get(rowData, 'id')}`)}
                    />
                  </Box>
                )}
                title='Select Line Items'
                // width={0}
                // flexGrow={1}
                fixed
                width={50}
                frozen={Column.FrozenDirection.LEFT}
              />
              {_.map(headers, h => (
                <Column
                  sortable
                  key={h}
                  dataKey={h}
                  title={_.capitalize(h)}
                  // fixed={false}
                  width={0}
                  flexGrow={2}
                  resizable
                />
              ))}
              <Column
                key='tags'
                dataKey='tags'
                cellRenderer={({ rowData }) => (
                  <Box direction='row' gap='xsmall' wrap flex={{ shrink: 0 }}>
                    {_.map(rowData.tags, tag => {
                      return <Tag key={tag} label={tag} noNeedRemoveTag neededMargin />
                    })}
                  </Box>
                )}
                title='Tags'
                width={0}
                flexGrow={5}
                resizable
              />
            </Table>
          )}
        </AutoResizer>
        {/* </Box> */}
      </Box>
    </ThemeContext.Extend>
  )
}

export default ContactsTable
