import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Text, Avatar, Tip } from 'grommet'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import numeral from 'numeral'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faGripVertical, faTrophyAlt, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { getInitials, getName } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import { updateWorkOrder } from 'controllers/workOrder'
import { toTimestamp } from 'shared/utils/date'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import HeaderFirstCell from 'webPages/dashboard/dashboardTable/HeaderFirstCell'
import HeaderSecondCell from 'webPages/dashboard/dashboardTable/HeaderSecondCell'
import WorkOrderTitle from 'components/dashboard/WorkOrderTitle'
import ProjectBudget from 'components/dashboard/ProjectBudget'
import DropDownMenu from 'components/DropDownMenu'
import InviteSubDrop from 'components/workOrder/InviteSubDrop'
import MessagesIcon from 'components/leveling/MessagesIcon'
import sendTypes from 'shared/constants/inviteStatus'

const INVITE_SUB = 'INVITE_SUB'
const ADD_INTERNAL_BID = 'ADD_INTERNAL_BID'

const TableContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  overflow: auto;
  > div:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
  }
  ${props =>
    props.showBudget
      ? `
    > div:nth-child(2) {
      position: sticky;
      left: 300px;
      z-index: 10;
    }
  `
      : `
    > div:first-child {
      border-right: solid 1px ${colors.PALE_GREY}
    }
  `}
`
const TableColumn = styled.div`
  display: grid;
  ${props => props.customStyle};
  grid-template-rows: ${props => props.gridTemplateRows};
  > div:first-child {
    z-index: 5;
    position: sticky;
    top: 0;
  }
  > div:last-child {
    position: sticky;
    bottom: 0;
    z-index: 5;
    background-color: ${colors.WHITE};
  }
`

const TableRow = styled.div`
  display: grid;
  height: 69px;
  ${props => props.customStyle};
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-template-rows: 69px;
`

const TableContent = styled.div`
  display: grid;
  grid-template-rows: ${props => props.gridTemplateRows};
  > div:first-child {
    z-index: 5;
    position: sticky;
    top: 0;
  }
  > div:last-child {
    position: sticky;
    bottom: 0;
    z-index: 5;
    background-color: ${colors.WHITE};
  }
`

const DashboardTable = ({
  project,
  workOrders,
  bids,
  accountsProfiles,
  channel,
  messages,
  dispatch,
  openSubDetails,
  addBidInvite,
  openCreateBidManuallyModal,
  settings
}) => {
  const [budgetTotal, setBudgetTotal] = useState(0)

  const filteredWorkOrders = useMemo(() => {
    let res = []
    if (!settings.declined) {
      _.forOwn(workOrders, wo => {
        const woBids = _.filter(bids, bid => bid.workOrderId === wo.id)
        const invitations = _.pickBy(wo.invitations, (inv, accId) => {
          let subAccountId = _.get(inv, 'subAccountId', accId)
          if (_.isNil(subAccountId)) subAccountId = accId
          const bid = _.find(woBids, bid => _.isEqual(bid.accountId, subAccountId))
          return !_.isEqual(_.get(bid, 'status'), 'declined')
        })
        res.push({ ...wo, invitations })
      })
    } else {
      res = workOrders
    }
    return res
  }, [workOrders, bids, settings])

  const maxBids = useMemo(() => {
    return _.reduce(
      filteredWorkOrders,
      (res, wo) => {
        if (_.size(wo.invitations) > res) {
          return _.size(wo.invitations)
        } else {
          return res
        }
      },
      0
    )
  }, [filteredWorkOrders])

  const invitationsData = useMemo(() => {
    const res = {}
    _.forEach(filteredWorkOrders, ({ id, invitations, acceptedBy, declinedBy, approvedBidId, bids }) => {
      res[id] = []
      _.forEach(invitations, (inv, accId) => {
        let bid = _.find(bids, bidData => bidData.accountId === accId && bidData.workOrderId === id)
        if (!_.isNil(bid) && !_.has(bid, 'bidId')) {
          bid = {
            ...bid,
            id: `bid_${bid.id}`,
            bidId: bid.id
          }
        }
        const info = {
          id: accId,
          invitedAt: toTimestamp(inv.timestamp),
          bid,
          ...inv
        }
        if (approvedBidId && approvedBidId === _.get(bid, 'bidId')) {
          info.won = true
        }
        if (_.has(acceptedBy, accId)) {
          info.acceptedAt = toTimestamp(_.get(acceptedBy, [accId, 'timestamp']))
        }
        if (_.has(declinedBy, accId) || _.isEqual(_.get(bid, 'status'), 'declined')) {
          info.declinedAt = toTimestamp(_.get(declinedBy, [accId, 'timestamp'], _.get(bid, 'timestamp')))
        }
        res[id].push(info)
      })
    })
    return res
  }, [filteredWorkOrders, bids])

  useEffect(() => {
    const res = numeral(
      _.sumBy(filteredWorkOrders, wo => {
        if (!wo.projectBudgetBidId) {
          return 0
        } else {
          const bid = _.get(bids, wo.projectBudgetBidId)
          return _.get(bid, 'total', 0)
        }
      })
    ).format('$0,0.00')
    setBudgetTotal(res)
  }, [filteredWorkOrders, bids])

  const gridTemplateColumns = useMemo(() => {
    const bidsWidth = (maxBids + 1) * 224
    return `300px ${settings.projectBudget ? '' : '150px'} ${bidsWidth}px`
  }, [maxBids, settings])

  const gridTemplateColumnsBids = useMemo(() => {
    const bidsArray = _.fill(Array(maxBids + 1), '224px')
    return `${bidsArray.join(' ')}`
  }, [maxBids])

  const gridTemplateRows = useMemo(() => {
    const itemsArray = _.fill(Array(_.size(filteredWorkOrders) + 2), '69px')
    return itemsArray.join(' ')
  }, [filteredWorkOrders])

  const onTradeClick = workOrderId => {
    const projectId = _.get(project, 'id')
    universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, { projectId, workOrderId })
  }

  const renderWorkOrder = ({ title, id, bids, invitations, deleted }, index) => {
    let color = colors.VERY_LIGHT_PINK
    let statusTitle = 'No Subs invited'
    if (!_.isEmpty(bids)) {
      color = colors.LIGHT_NAVY_BRIGHT
      statusTitle = 'Bids Received'
    } else if (!_.isEmpty(invitations)) {
      color = colors.AQUA_MARINE
      statusTitle = 'Subs Invited'
    }

    if (deleted > 0) {
      statusTitle = 'Archived'
      color = colors.BADGE_BG
    }
    const isLast = _.isEqual(_.size(workOrders) - 1, index)

    return (
      <WorkOrderTitle
        key={id}
        title={title}
        id={id}
        color={color}
        statusTitle={statusTitle}
        onTradeClick={onTradeClick}
        archived={deleted > 0}
        isLast={isLast}
      />
    )
  }

  const renderBudget = ({ id, projectBudgetBidId, bids }, index) => {
    const bid = _.isNil(projectBudgetBidId) ? null : _.get(bids, projectBudgetBidId)
    const accId = _.get(bid, 'accountId')
    const subAccount = _.get(accountsProfiles, accId)
    const name = getName(subAccount)
    const isLast = _.isEqual(_.size(workOrders) - 1, index)
    return (
      <ProjectBudget
        key={id}
        id={id}
        bid={bid}
        removeProjectBudget={removeProjectBudget}
        accountName={name}
        invitations={_.filter(invitationsData[id], inv => !_.isNil(_.get(inv, 'bid')))}
        handleProjectBudgetSet={handleProjectBudgetSet}
        isLast={isLast}
      />
    )
  }

  function getStyle (style, snapshot) {
    if (!snapshot.isDragging) return {}
    if (!snapshot.isDropAnimating) {
      return style
    }

    return {
      ...style,
      transitionDuration: `0.001s`,
      '& div': {
        border: 'none'
      }
    }
  }

  const renderName = name => {
    if (_.size(name) > 30) {
      return (
        <Tip content={name}>
          <Text>{_.truncate(name, { length: 30 })}</Text>
        </Tip>
      )
    } else {
      return name
    }
  }

  const Bids = ({ id, accountId }) => {
    const levelingInvitations = invitationsData[id]
    const dropMenuRef = useRef(null)
    const inviteSubRef = useRef(null)

    const sortedInvitations = _.sortBy(levelingInvitations, info => {
      let t = info.invitedAt
      if (!_.isNil(info.bid)) {
        t = t / 100
      } else if (_.has(info, 'acceptedAt')) {
        t = info.acceptedAt / 10
      }
      return t
    })

    const handleOptionClick = optionId => {
      switch (optionId) {
        case INVITE_SUB: {
          inviteSubRef.current.open()
          break
        }
        case ADD_INTERNAL_BID: {
          openCreateBidManuallyModal(accountId, id)
          break
        }
        default:
          break
      }
    }
    return (
      <TableRow
        gridTemplateColumns={gridTemplateColumnsBids}
        key={`${id}_bids`}
        customStyle={`
          > .invite-button {
            display: none;
          }
          :hover > .invite-button {
            display: flex;
          }
        `}
      >
        {_.map(sortedInvitations, (invitation, index) => (
          <Droppable droppableId={`${id}_bids`} key={`${id}_${index}_bids`}>
            {(provided, snapshot) => (
              <React.Fragment>
                <Box
                  flex={false}
                  direction='row'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  margin={{ bottom: '1px', right: '1px' }}
                >
                  {renderBid(invitation, id, index)}
                </Box>
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </React.Fragment>
            )}
          </Droppable>
        ))}
        <Box className='invite-button' width='xsmall' onClick={e => e.stopPropagation()}>
          <DropDownMenu
            dropRef={dropMenuRef}
            label={
              <Box fill justify='center' align='center' onClick={() => {}} hoverIndicator>
                <FontAwesomeIcon icon={faPlus} size={20} color={colors.LIGHT_NAVY_BRIGHT} />
              </Box>
            }
            options={[
              { id: INVITE_SUB, label: 'Invite Sub' },
              { id: ADD_INTERNAL_BID, label: 'Add Internal Bid' }
            ]}
            dropContentProps={{ boxProps: { width: { min: '160px' } }, optionLabelProps: { textAlign: 'center' } }}
            dropAlign={{ top: 'bottom', left: 'left' }}
            onOptionClick={handleOptionClick}
            fill
          />
        </Box>
        <InviteSubDrop
          dropButtonProps={{ plain: true, fill: true, disabled: true }}
          dropButtonLabel={null}
          workOrderId={id}
          dropRef={inviteSubRef}
          openLayerCallback={() => dropMenuRef.current.close()}
        />
      </TableRow>
    )
  }

  const renderBid = ({ id, bid, won, acceptedAt, declinedAt, sendType, companyName }, woId, index) => {
    const profile = _.get(accountsProfiles, id, null)
    const name = !_.isNil(profile) ? getName(profile) : companyName
    const isDragDisabled = _.isNil(bid) || !!declinedAt || settings.projectBudget
    return (
      <Draggable
        draggableId={_.isNil(bid) ? `${id}_${woId}` : `${bid.bidId}_wo_${woId}`}
        key={id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
            key={id}
            fill
          >
            <Box flex={false} fill customStyle={`outline: solid 1px ${colors.VERY_LIGHT_GREY_TWO};`}>
              {renderSub(id, bid, won, acceptedAt, declinedAt, woId, name, sendType)}
            </Box>
            <div style={{ visibility: 'hidden', height: 0 }}>{provided.placeholder}</div>
          </Box>
        )}
      </Draggable>
    )
  }

  const renderSub = (id, bid, won, acceptedAt, declinedAt, woId, name, sendType) => {
    const subMessages = _.filter(_.get(messages, id), msg => msg.workOrderId === woId)
    const hasMessages = _.size(subMessages) > 0
    const lastMessage = _.last(subMessages)
    const lastMessageUserId = _.get(lastMessage, 'userId')
    const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp'))
    const lastReadTime = toTimestamp(_.get(channel, `${woId}_${id}`))
    const hasNewMessages = lastReadTime < lastMessageTime && lastMessageUserId === id
    if (declinedAt) {
      return renderDeclinedInvitation(id, woId, hasMessages, hasNewMessages, name)
    }
    if (!_.isNil(bid)) {
      return renderBidLabel(id, bid, won, woId, hasMessages, hasNewMessages, name)
    }
    if (acceptedAt) {
      return renderAcceptedInvitation(id, woId, hasMessages, hasNewMessages, name)
    }
    return renderUndecidedInvitation(id, woId, hasMessages, hasNewMessages, name, sendType)
  }

  const renderUndecidedInvitation = (id, woId, hasMessages, hasNewMessages, name, sendType) => (
    <Box
      onClick={() => openSubDetails(id, woId)}
      pad={{ right: 'small', left: 'medium' }}
      gap='small'
      justify='center'
      fill
      customStyle={`position: relative;`}
    >
      <Box align='center' direction='row' gap='small' customStyle={`> div { opacity: 0.5 }`}>
        <Avatar
          flex={false}
          size='24px'
          src={_.get(accountsProfiles, [id, 'avatarSmall'])}
          background={colors.LIGHT_NAVY_BRIGHT}
        >
          <Text color={colors.WHITE} weight='bold' size='small'>
            {getInitials(_.get(accountsProfiles, id))}
          </Text>
        </Avatar>
        <Text truncate weight={600} color={colors.BROWN_GREY_TWO}>
          {renderName(name)}
        </Text>
      </Box>
      <Box direction='row' align='center' gap='small'>
        <Text size='medium' color={colors.VERY_LIGHT_GREY}>
          {_.isEqual(sendType || sendTypes.IMMEDIATELY, sendTypes.IMMEDIATELY) ? (
            <i>Undecided</i>
          ) : _.isEqual(sendType || sendTypes.SILENT, sendTypes.SILENT) ? (
            <i>Silent invite</i>
          ) : (
            <i>Invite not sent</i>
          )}
        </Text>
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessages} />}
      </Box>
    </Box>
  )

  const renderDeclinedInvitation = (id, woId, hasMessages, hasNewMessages, name) => (
    <Box
      onClick={() => openSubDetails(id, woId)}
      pad={{ vertical: 'small', right: 'small', left: 'medium' }}
      gap='small'
      justify='center'
      fill
      customStyle={`position: relative;`}
    >
      <Box align='center' direction='row' gap='small'>
        <Avatar
          flex={false}
          size='24px'
          src={_.get(accountsProfiles, [id, 'avatarSmall'])}
          background={colors.LIGHT_NAVY_BRIGHT}
        >
          <Text color={colors.WHITE} weight='bold' size='xsmall'>
            {getInitials(_.get(accountsProfiles, id))}
          </Text>
        </Avatar>
        <Text truncate weight={600} color={colors.CORAL_TWO} customStyle={`text-decoration-line: line-through;`}>
          {renderName(name)}
        </Text>
      </Box>
      <Box direction='row' align='center' gap='small'>
        <Text size='medium' color={colors.CORAL_TWO}>
          Declined
        </Text>
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessages} />}
      </Box>
    </Box>
  )

  const renderAcceptedInvitation = (id, woId, hasMessages, hasNewMessages, name) => (
    <Box
      onClick={() => openSubDetails(id, woId)}
      pad={{ vertical: 'small', right: 'small', left: 'medium' }}
      gap='small'
      justify='center'
      fill
      customStyle={`position: relative;`}
    >
      <Box align='center' direction='row' gap='small' customStyle={`> div { opacity: 0.5 }`}>
        <Avatar
          flex={false}
          size='24px'
          src={_.get(accountsProfiles, [id, 'avatarSmall'])}
          background={colors.LIGHT_NAVY_BRIGHT}
        >
          <Text color={colors.WHITE} weight='bold' size='small'>
            {getInitials(_.get(accountsProfiles, id))}
          </Text>
        </Avatar>
        <Text truncate weight={600} color={colors.BROWN_GREY_TWO}>
          {renderName(name)}
        </Text>
      </Box>
      <Box direction='row' align='center' gap='small'>
        <Text size='medium' color={colors.AQUA_MARINE}>
          <i>Waiting for bid</i>
        </Text>
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessages} />}
      </Box>
    </Box>
  )

  const renderBidLabel = (id, bid, won, woId, hasMessages, hasNewMessages, name) => (
    <Box
      fill
      customStyle={`
        position: relative;
        :hover {
          > .drag_icon {
            display: block;
            top: 35%;
            left: 2%;
          }
        }
      `}
    >
      {!settings.projectBudget && (
        <Box
          className='drag_icon'
          customStyle={`
          position: absolute;
          display: none;
        `}
        >
          <FontAwesomeIcon icon={faGripVertical} size={18} color={colors.ANOTHER_GREY} />
        </Box>
      )}
      <Box
        onClick={() => openSubDetails(id, woId)}
        pad={{ right: 'small', left: 'medium' }}
        gap='small'
        justify='center'
        fill
      >
        <Box align='center' direction='row' gap='small'>
          <Avatar
            flex={false}
            size='24px'
            src={_.get(accountsProfiles, [id, 'avatarSmall'])}
            background={colors.LIGHT_NAVY_BRIGHT}
          >
            <Text color={colors.WHITE} size='small'>
              {getInitials(_.get(accountsProfiles, id))}
            </Text>
          </Avatar>
          <Box>
            <Text truncate size='medium' weight={600} color={colors.TEXT}>
              {renderName(name)}
            </Text>
          </Box>
          {won && (
            <Box customStyle='position: relative' width='16px' height='16px' margin={{ left: 'auto' }}>
              <Box customStyle='position: absolute' align='center'>
                <FontAwesomeIcon icon={faCircle} color={colors.AQUA_MARINE} size={24} />
              </Box>
              <Box fill customStyle='z-index: 5; top: 30%; left: 12%; position: absolute;' justify='center'>
                <FontAwesomeIcon icon={faTrophyAlt} color={colors.WHITE} size={12} />
              </Box>
            </Box>
          )}
        </Box>
        <Box direction='row' align='center' gap='small'>
          <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
            {numeral(_.get(bid, 'total')).format('$0,0.00')}
          </Text>
          {hasMessages && <MessagesIcon hasNewMessage={hasNewMessages} />}
        </Box>
      </Box>
    </Box>
  )

  const handleProjectBudgetSet = (woId, id) => {
    dispatch(updateWorkOrder(woId, { projectBudgetBidId: id }))
  }

  const handleDragEnd = ({ destination, source, draggableId }) => {
    const sourceId = _.get(source, 'droppableId', '').split('_bids')
    const destinationId = _.get(destination, 'droppableId', '').split('_bids')
    const bidId = _.split(draggableId, '_wo_')[0]
    const isFromBidsToWorkOrder = _.size(sourceId) > _.size(destinationId)
    if (_.isEqual(sourceId[0], destinationId[0]) && isFromBidsToWorkOrder) {
      const woId = _.get(destination, 'droppableId')
      handleProjectBudgetSet(woId, bidId)
    }
  }

  const removeProjectBudget = woId => {
    dispatch(updateWorkOrder(woId, { projectBudgetBidId: null }))
  }

  const firstColumn = (
    <TableColumn gridTemplateRows={gridTemplateRows}>
      <Box>
        <HeaderFirstCell />
      </Box>
      {_.map(filteredWorkOrders, renderWorkOrder)}
      <Box
        hoverIndicator
        color={colors.ASANA_GRAY_TEXT_HOVERED}
        onClick={addBidInvite}
        direction='row'
        align='center'
        gap='small'
        pad='small'
        border='top'
        margin={{ top: '-1px' }}
      >
        <FontAwesomeIcon size={14} icon={faPlus} color={colors.ANOTHER_GREY} />
        <Text size='medium' color={colors.ANOTHER_GREY}>
          Add Bid Invitation
        </Text>
      </Box>
    </TableColumn>
  )

  const secondColumn = (
    <TableColumn gridTemplateRows={gridTemplateRows}>
      <Box>
        <HeaderSecondCell />
      </Box>
      {_.map(filteredWorkOrders, renderBudget)}
      <Box align='center' direction='row' gap='small' pad='small' border={['left', 'top']} margin={{ top: '-1px' }}>
        <Text color={colors.MEDIUM_GREY} size='medium'>
          SUM
        </Text>
        <Text color={colors.TEXT} size='medium'>
          {budgetTotal}
        </Text>
      </Box>
    </TableColumn>
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box flex>
        <TableContainer gridTemplateColumns={gridTemplateColumns} showBudget={!settings.projectBudget}>
          {firstColumn}
          {!settings.projectBudget && secondColumn}
          <TableContent gridTemplateRows={gridTemplateRows}>
            <Box border='bottom' flex background={colors.WHITE} customStyle='position: sticky; z-index: 5; top: 0;' />
            {_.map(filteredWorkOrders, ({ id, accountId }) => (
              <Bids key={id} id={id} accountId={accountId} />
            ))}
            <Box flex height='69px' background={colors.WHITE} />
          </TableContent>
        </TableContainer>
      </Box>
    </DragDropContext>
  )
}

const mapStateToProps = state => ({
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(DashboardTable)
