import React from 'react'
import { Box, Text, Select, ThemeContext } from 'grommet'
// import _ from 'lodash'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'

// import CreateBidContext from 'webPages/createBid/CreateBidContext'
import colors from 'shared/constants/colors'

const ContractTemplate = () => {
  // const { getField, setField } = useContext(CreateBidContext)

  const renderSelect = () => {
    // const templateId = getField('contractTemplate')
    return (
      <ThemeContext.Extend
        value={{
          select: {
            container: {
              extend: css`
                :hover {
                  opacity: 0.9;
                }
              `
            },
            control: {
              extend: css`
                border-radius: 6px;
              `
            }
          }
        }}
      >
        <Select
          value={'none'}
          icon={<FontAwesomeIcon icon={faChevronDown} color={colors.LIGHT_NAVY} size={16} />}
          options={['none']}
          onChange={({ option }) => console.log('selected option', option)}
          disabled
        />
      </ThemeContext.Extend>
    )
  }

  return (
    <Box pad={{ horizontal: 'medium', top: 'small' }} direction='row' align='center' justify='between'>
      <Text>Contract template</Text>
      <Box width='220px'>{renderSelect()}</Box>
    </Box>
  )
}

export default ContractTemplate
