import _ from 'lodash'
import { createSelector } from 'reselect'

import { getOwnProjectsInfo, getIncomingProjectsInfo } from 'model/selectors/projectsInfoSelector'
import { PRIVATE_CHAT_CHANNEL } from 'constants/index'
import { toTimestamp } from 'shared/utils/date'
import { getProjectId, getUserId, getWorkOrders } from './base'
import messageType from 'constants/messageType'

const getAccountId = state => _.get(state, 'account.id')
const getChannels = state => _.get(state, 'channels')
const getMessages = state => _.get(state, 'messagesWeb')

export const getChannelsInfo = createSelector(
  [getChannels, getAccountId, getOwnProjectsInfo, getIncomingProjectsInfo, getUserId],
  (channels, currentAccountId, ownProjects, incomingProjects, userId) => {
    const res = []

    _.forEach(channels, (channel, channelKey) => {
      const projectId = _.get(channel, 'projectId')
      const hasAccess = _.has(ownProjects, projectId) || _.has(incomingProjects, projectId)
      const creatorAccountId = _.get(channel, 'creatorAccountId')
      const invitedAccountId = _.get(channel, 'invitedAccountId')
      const accounts = _.get(channel, 'accounts')
      const isPublic = _.size(accounts) > 1
      if (hasAccess && isPublic) {
        const lastMessage = _.get(channel, 'lastMessage')
        const lastMessageTime = toTimestamp(_.get(channel, ['lastMessage', 'timestamp'], 0))
        const myLastReadTime = toTimestamp(_.get(channel, ['readBy', userId], 0))
        const lastMessageSeen =
          _.isEmpty(lastMessage) || lastMessageTime <= myLastReadTime || _.get(lastMessage, 'userId') === userId
        const ch = {
          channelKey,
          projectId: projectId,
          partnerAccountId: invitedAccountId === currentAccountId ? creatorAccountId : invitedAccountId,
          lastMessageTime,
          invited: invitedAccountId === currentAccountId,
          lastMessageSeen
        }
        res.push(ch)
      }
    })
    return _.orderBy(res, ['lastMessageSeen', 'lastMessageTime'], ['asc', 'desc'])
  }
)

export const getPrivateChannelsInfo = createSelector(
  [getChannels, getOwnProjectsInfo, getIncomingProjectsInfo, getUserId],
  (channels, ownProjects, incomingProjects, userId) => {
    const res = []
    _.forEach(channels, (channel, channelKey) => {
      const projectId = _.get(channel, 'projectId')
      const hasAccess = _.has(ownProjects, projectId) || _.has(incomingProjects, projectId)
      const accounts = _.get(channel, 'accounts')
      const isPublic = _.size(accounts) > 1
      if (hasAccess && !isPublic) {
        const lastMessage = _.get(channel, 'lastMessage')
        const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp', 0))
        const myLastReadTime = toTimestamp(_.get(channel, ['readBy', userId]), 0)
        const lastMessageSeen = lastMessageTime <= myLastReadTime || _.get(lastMessage, 'userId') === userId
        const ch = {
          channelKey,
          projectId: projectId,
          partnerAccountId: PRIVATE_CHAT_CHANNEL,
          lastMessageTime,
          invited: false,
          lastMessageSeen
        }
        res.push(ch)
      }
    })

    return _.orderBy(res, ['lastMessageTime'], ['desc'])
  }
)

export const getPublicChannelsByProject = createSelector(
  [getChannels, getAccountId, getProjectId, getUserId],
  (channels, currentAccountId, projectId, userId) => {
    const res = []
    _.forEach(channels, (channel, channelKey) => {
      const chProjectId = _.get(channel, 'projectId')
      if (chProjectId === projectId) {
        const creatorAccountId = _.get(channel, 'creatorAccountId')
        const invitedAccountId = _.get(channel, 'invitedAccountId')
        const accounts = _.get(channel, 'accounts')
        const isPublic = _.size(accounts) > 1
        if (isPublic) {
          const lastMessage = _.get(channel, 'lastMessage')
          const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp', 0))
          const myLastReadTime = toTimestamp(_.get(channel, ['readBy', userId], 0))
          const lastMessageSeen =
            _.isEmpty(lastMessage) || lastMessageTime <= myLastReadTime || _.get(lastMessage, 'userId') === userId
          if (!lastMessageSeen) {
            console.log('last message unseen', channelKey, 'lastMessage', lastMessage, 'myLastReadTime', myLastReadTime)
          }
          const ch = {
            channelKey,
            projectId: projectId,
            partnerAccountId: invitedAccountId === currentAccountId ? creatorAccountId : invitedAccountId,
            lastMessageTime,
            invited: invitedAccountId === currentAccountId,
            lastMessageSeen
          }
          res.push(ch)
        }
      }
    })
    return _.orderBy(res, ['lastMessageTime'], ['desc'])
  }
)

export const getUnseenMessagesAmountByProject = createSelector([getPublicChannelsByProject], channels => {
  return _.reduce(channels, (res, ch) => (ch.lastMessageSeen ? res : res + 1), 0)
})

export const getLevelingTableMessages = createSelector([getMessages, getWorkOrders], (allMessages, workOrders) => {
  const announcements = {} // itemId: array of messages,
  const subs = {} // subId: array of messages,
  const cells = {} // subId_itemId:  array of objects
  _.forEach(allMessages, m => {
    const mType = _.get(m, 'type')
    switch (mType) {
      case messageType.ITEM:
        _.set(cells, [m.subId, m.itemId, m.id], m)
        break
      case messageType.ANNONCEMENT:
        _.set(announcements, [m.itemId, m.id], m)
        const wo = _.get(workOrders, m.workOrderId)
        const invitations = _.get(wo, 'invitations')
        _.forEach(invitations, (inv, subId) => {
          _.set(cells, [subId, m.itemId, m.id], m)
        })
        break
      case messageType.SUB:
        _.set(subs, [m.subId, m.id], m)
        break
      case messageType.NOTE:
        _.set(subs, [m.subId, m.id], m)
        break
    }
  })

  const res = {
    announcements: {},
    subs: {},
    cells: {}
  }
  // console.log('cells raw', cells)
  _.forEach(cells, (byItem, subId) => {
    _.forEach(byItem, (msgs, itemId) => {
      const sortedMessages = _.sortBy(msgs, m => toTimestamp(m.timestamp))
      _.set(res, ['cells', subId, itemId], sortedMessages)
    })
  })
  _.forEach(announcements, (msgs, itemId) => {
    const sortedMessages = _.sortBy(msgs, m => -toTimestamp(m.timestamp))
    _.set(res, ['announcements', itemId], sortedMessages)
  })
  _.forEach(subs, (msgs, subId) => {
    const sortedMessages = _.sortBy(msgs, m => toTimestamp(m.timestamp))
    _.set(res, ['subs', subId], sortedMessages)
  })
  return res
})
