import React, { useMemo, useState, useEffect } from 'react'
import { Box, Text, Select, Button } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'

const ALL_OPTIONS = 'ALL_OPTIONS'

const ImportedLiPanelFilterMenu = ({ filters, onApply, close, filtersSettings, filterMenuOpen }) => {
  const [filterCategory, setFilterCategory] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])

  const columns = useMemo(() => {
    return _.map(filtersSettings, (v, k) => ({
      label: v.label,
      value: k
    }))
  }, [filtersSettings])

  useEffect(() => {
    if (!_.isEqual(filterMenuOpen, ALL_OPTIONS)) {
      setFilterCategory(filterMenuOpen)
      setSelectedOptions(_.get(filters, [_.get(filterMenuOpen, 'value'), 'options'], []))
    }
  }, [filters, filterMenuOpen])

  const values = _.get(filtersSettings, [_.get(filterCategory, 'value'), 'options'], [])

  console.log('columns', columns)

  const applyFilter = () => {
    console.log('apply filter')
    onApply(filterCategory, selectedOptions)
  }

  const onFilterCategoryChange = e => {
    console.log('onFilterCategoryChange', e)
    setSelectedOptions(_.get(filters, [e.value.value, 'options'], []))
    setFilterCategory(e.value)
  }

  const onOptionsSelected = e => {
    console.log('onOptionsSelected', e)
    setSelectedOptions(e.value)
  }

  const multipleMessage = `${_.get(_.values(selectedOptions), [0, 'label'])} and ${_.size(selectedOptions) - 1} other`

  return (
    <Box pad='small' width={{ min: 'medium' }} flex>
      <Text color={colors.VERY_LIGHT_PINK} size='small'>
        Column
      </Text>
      <Select
        name='criteria'
        id='filter-select-column'
        labelKey='label'
        valueKey='value'
        value={filterCategory}
        options={columns}
        onChange={onFilterCategoryChange}
        clear
        placeholder={'choose a column'}
      />
      <Text color={colors.BLACK} size='2xl' textAlign='center'>
        =
      </Text>
      <Select
        name='values'
        id='filter-select-value'
        labelKey='label'
        valueKey='value'
        options={values}
        value={selectedOptions}
        onChange={onOptionsSelected}
        multiple
        messages={{ multiple: multipleMessage }}
        clear
        placeholder='options'
      />
      <Box direction='row' justify='between' pad='xsmall'>
        <Button
          plain
          label={
            <Text size='medium' color={colors.LINK} onClick={close}>
              Cancel
            </Text>
          }
        />
        <Button
          plain
          label={
            <Text size='medium' color={colors.AQUA_MARINE} onClick={applyFilter}>
              Apply
            </Text>
          }
        />
      </Box>
    </Box>
  )
}

export default ImportedLiPanelFilterMenu
