import React from 'react'
import { Box } from 'grommet'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faGripVertical } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import LineItemTitle from 'components/workOrder/LineItemTitle'
import LineItemDescription from 'components/workOrder/LineItemDescription'
import LineItemQuantity from 'components/workOrder/LineItemQuantity'
import LineItemComments from 'components/workOrder/LineItemComments'
import LineItemAlternates from 'components/workOrder/LineItemAlternates'
// import LineItemType from 'components/workOrder/LineItemType'
import LineItemDelete from 'components/workOrder/LineItemDelete'
import LineItemRequired from 'components/workOrder/LineItemRequired'
import LineItemLabor from 'components/workOrder/LineItemLabor'
import colors from 'shared/constants/colors'

const draggingStyle = css`
  border-top: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`

const GridRow = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 26px 2fr 70px 70px 1fr 2fr 70px 70px 60px;
  ${props => (props.isDragging ? draggingStyle : '')};
  ${props =>
    !props.isDraggingOver &&
    `:hover:not(.alt-row) {
      background-color: ${colors.TABLE_HOVER};
      #delete_line_item_icon svg path {
        fill: ${colors.CORAL_TWO};
      }
      #drag_icon path {
        fill: ${colors.BROWN_GREY_TWO};
      }
    }
  `}
`

const textInputThemeValue = {
  global: {
    control: { border: { color: 'transparent', radius: '0px' } }
  },
  textInput: {
    container: {
      extend: css`
        input:focus {
          background-color: ${colors.WHITE};
          border: 1px ${colors.AQUA_MARINE} solid;
        }
      `
    }
  }
}

const LineItemRow = ({
  rowId,
  li,
  parentLi,
  addAlternate,
  toggleAlternates,
  selectRow,
  unselectRow,
  removeLineItem,
  expanded,
  updateItem,
  messagesAmount,
  setCommentId,
  tableRowProps,
  dragHandleProps,
  isDraggingOver
}) => {
  const renderCommentsField = (li, parentLi) => (
    <LineItemComments messagesAmount={messagesAmount} li={li} parentLi={parentLi} setCommentId={setCommentId} />
  )

  const renderAlternatesField = (li, parentLi) => {
    return (
      <LineItemAlternates
        li={li}
        parentLi={parentLi}
        setShow={() => toggleAlternates(li.id, false)}
        addAlternate={addAlternate}
      />
    )
  }

  const renderTitleField = (li, parentLi) => {
    return (
      <LineItemTitle
        li={li}
        parentLi={parentLi}
        textInputThemeValue={textInputThemeValue}
        selectRow={selectRow}
        unselectRow={unselectRow}
        removeLineItem={removeLineItem}
        updateItem={updateItem}
        handleShowChange={() => toggleAlternates(li.id)}
        show={expanded}
      />
    )
  }

  // const onTypeChange = li => ({ option }) => {
  //   updateItem(li.id, { type: option.id })
  // }

  // const renderTypeField = (li, parentLi) => {
  //   return (
  //     <LineItemType
  //       li={li}
  //       parentLi={parentLi}
  //       onTypeChange={onTypeChange}
  //       selectRow={selectRow}
  //       unselectRow={unselectRow}
  //     />
  //   )
  // }

  const renderDescriptionField = (li, parentLi) => {
    const handleUpdate = value => updateItem(li.id, { desc: value }, parentLi)
    return (
      <LineItemDescription
        li={li}
        parentLi={parentLi}
        handleUpdate={handleUpdate}
        textInputThemeValue={textInputThemeValue}
        selectRow={selectRow}
        unselectRow={unselectRow}
      />
    )
  }

  const renderQuantityField = (li, parentLi) => {
    const handleUpdate = value => {
      updateItem(li.id, { ...value }, parentLi)
    }
    return (
      <LineItemQuantity
        li={li}
        parentLi={parentLi}
        handleUpdate={handleUpdate}
        selectRow={selectRow}
        unselectRow={unselectRow}
        textInputThemeValue={textInputThemeValue}
      />
    )
  }

  const renderRequiredField = (li, parentLi) => {
    const onChange = event => updateItem(li.id, { required: event.target.checked }, parentLi)
    return <LineItemRequired li={li} parentLi={parentLi} onChange={onChange} />
  }

  const renderLaborField = (li, parentLi) => {
    const onChange = event => updateItem(li.id, { labor: event.target.checked }, parentLi)
    return <LineItemLabor li={li} parentLi={parentLi} onChange={onChange} />
  }

  const renderDeleteRowField = (li, parentLi) => {
    return <LineItemDelete onClick={() => removeLineItem(li.id, parentLi)} />
  }

  const renderDragIcon = (li, parentLi) => {
    if (!_.has(parentLi) && !_.isEmpty(dragHandleProps)) {
      return (
        <Box align='end' justify='center' {...dragHandleProps}>
          <FontAwesomeIcon
            icon={faGripVertical}
            color={tableRowProps.isDragging ? colors.BROWN_GREY_TWO : 'transparent'}
            size={18}
            id='drag_icon'
          />
        </Box>
      )
    } else {
      return <Box />
    }
  }

  return (
    <GridRow key={rowId} id={rowId} {...tableRowProps} isDraggingOver={isDraggingOver}>
      {renderDragIcon(li, parentLi)}
      {renderTitleField(li, parentLi)}
      {renderCommentsField(li, parentLi)}
      {renderAlternatesField(li, parentLi)}
      {/* {renderTypeField(li, parentLi)} */}
      {renderQuantityField(li, parentLi)}
      {renderDescriptionField(li, parentLi)}
      {renderRequiredField(li, parentLi)}
      {renderLaborField(li, parentLi)}
      {renderDeleteRowField(li, parentLi)}
    </GridRow>
  )
}

export default LineItemRow
