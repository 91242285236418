import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Layer, ThemeContext, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { getInboxMessages, hasMoreInbox } from 'model/selectors/inbox'
import InboxMessage from 'components/chat/InboxMessage'
import messageType from 'constants/messageType'
import { loadMoreInbox, fetchInboxNotes } from 'controllers/inbox'
import { getChannel } from 'model/selectors/base'
import { wrapMentions } from 'shared/utils/stringUtils'
import { toTimestamp } from 'shared/utils/date'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'

const Tab = ({ label, isActive, onClick }) => {
  return (
    <Box
      onClick={onClick}
      pad={{ bottom: 'xsmall', horizontal: 'xsmall' }}
      border={
        isActive ? {
          side: 'bottom',
          size: '2px',
          color: colors.AQUA_MARINE
        } : false
      }
    >
      <Text size='large'>{label}</Text>
    </Box>
  )
}

class CommentsInbox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      tab: 'all',
      notes: []
    }
  }

  open = () => {
    this.setState({ visible: true })
  }

  loadMore = () => {
    const { tab } = this.state
    if (tab === 'all') {
      loadMoreInbox()
    } else {
      this.unsubscribeNotes()
      this.fetchNotes(true)
    }
  }

  close = () => {
    this.setState({ visible: false, tab: 'all' })
    if (_.isFunction(this.unsubscribeNotes)) this.unsubscribeNotes()
  }

  renderInboxMessage = (msg, index) => {
    // const currentWorkOrder = _.isEqual(workOrderId, msg.workOrderId)
    const { account, workOrders } = this.props
    // const isInternalMessage = _.isEqual(msg.subId, _.get(workOrder, 'accountId'))
    const workOrderId = _.get(msg, 'workOrderId')
    const workOrder = _.get(workOrders, workOrderId)
    const projectId = _.get(workOrder, 'projectId')
    const accountId = _.get(account, 'id')
    const isGC = accountId === _.get(workOrder, 'accountId')
    let onClick = () => {
      console.log('on message click', msg)
      if (_.isNil(projectId) || _.isNil(workOrderId)) {
        console.log('no project or workorder - skip')
        return null
      }
      this.close()
      switch (msg.type) {
        case messageType.ANNOTATION:
          console.log('annotation message, navigate to work order file')
          const fileId = _.get(msg, 'fileId')
          universalNavigation.push(screens.WORKORDER_FILE, { workOrderId, fileId, selectAnnotationId: msg.id })
          break
        case messageType.ANNONCEMENT:
          // annoncement message can appear as incoming for subs only
          console.log('annoncement message, open sub_create_bid interface')
          universalNavigation.push(screens.SUB_CREATE_BID, {
            projectId,
            workOrderId,
            cellOpen: msg.itemId,
            cellOpenTimestamp: _.now()
          })
          break
        case messageType.ITEM:
          if (isGC) {
            console.log('item comment: GC clicked, open leveling page')
            universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, {
              projectId,
              workOrderId,
              cellOpen: { subId: msg.subId, itemId: msg.itemId },
              cellOpenTimestamp: _.now()
            })
          } else {
            console.log('item comment: SUB clicked, open create bid page')
            universalNavigation.push(screens.SUB_CREATE_BID, {
              projectId,
              workOrderId,
              cellOpen: msg.itemId,
              cellOpenTimestamp: _.now()
            })
          }
          break
        case messageType.SUB:
          if (isGC) {
            console.log('GC: SUB MESSAGE CLICKED')
            universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, {
              projectId,
              workOrderId,
              cellOpen: { subId: msg.subId },
              cellOpenTimestamp: _.now()
            })
          } else {
            console.log('SUB: SUB MESSAGE CLICKED')
            universalNavigation.push(screens.SUB_CREATE_BID, {
              projectId,
              workOrderId,
              cellOpen: accountId,
              cellOpenTimestamp: _.now()
            })
          }
          break
        case messageType.NOTE:
          universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, {
            projectId,
            workOrderId,
            cellOpen: { subId: msg.subId },
            cellOpenTimestamp: _.now()
          })
          break
        default:
          console.warn('cannot navigate to msg', msg)
      }
    }
    if (_.isNil(projectId)) {
      onClick = () => {
        console.warn('on message click', msg, 'no projectId')
      }
    }
    return (
      <InboxMessage
        key={msg.id}
        index={index}
        profile={msg.profile}
        strAction={msg.strAction}
        timestamp={msg.timestamp}
        text={msg.text}
        itemName={msg.itemName}
        title={msg.title}
        isNew={msg.unseen}
        onClick={onClick}
        type={msg.type}
      />
    )
  }

  hasMoreNotes = () => {
    const { notes } = this.state
    const { firstNoteId } = this.props
    const notesIds = _.map(notes, n => n.id)
    return !_.isNil(firstNoteId) && !_.includes(notesIds, firstNoteId)
  }

  renderLoadMoreButton = () => {
    const { tab } = this.state
    const hasMore = tab === 'all' ? this.props.hasMore : this.hasMoreNotes()
    if (hasMore) {
      return (
        <Box
          direction='row'
          pad='small'
          fill='horizontal'
          margin={{ bottom: 'xlarge' }}
          justify='center'
          flex={{ shrink: 0 }}
        >
          <Button
            primary
            color={colors.VERY_LIGHT_PINK_TWO}
            label={
              <Box pad='none' align='start'>
                <Text color={colors.ANOTHER_GREY}>Load more</Text>
              </Box>
            }
            onClick={this.loadMore}
          />
        </Box>
      )
    }
  }

  formatNote = m => {
    const { readAt, workOrders, profiles } = this.props
    const workOrderId = _.get(m, 'workOrderId')
    const wo = _.get(workOrders, workOrderId)
    const channelKey = `${workOrderId}_${m.subId}`
    const readTime = toTimestamp(_.get(readAt, channelKey))
    const timestamp = toTimestamp(m.timestamp)
    const unseen = readTime < timestamp
    const strAction = 'mentioned you'
    const regex = /(@\[.*?\]\(.*?\))/
    const text = _.join(wrapMentions(_.get(m, 'text'), regex), '')
    const itemName = _.get(
      wo,
      ['invitations', m.subId, 'companyName'],
      _.get(
        wo,
        ['invitaitons', m.subId, 'name']
      )
    )
    let title = _.get(wo, 'projectAddress.name', '')
    const woTitle = _.get(wo, 'title')
    if (!_.isEmpty(woTitle)) title = `${title} - ${woTitle}`
    return {
      id: m.id,
      type: m.type,
      profile: _.get(profiles, m.userId),
      strAction,
      timestamp,
      text,
      itemName,
      unseen,
      title,
      itemId: m.itemId,
      subId: m.subId,
      workOrderId: workOrderId
    }
  }

  fetchNotes = loadMore => {
    const { userId } = this.props
    const { notes } = this.state
    if (loadMore) {
      const lastNote = _.last(notes)
      this.unsubscribeNotes = fetchInboxNotes(userId, notesRaw => {
        const notes = _.map(notesRaw, this.formatNote)
        this.setState({ notes: [...this.state.notes, ...notes], notesFetched: true })
      }, lastNote)
    } else {
      this.unsubscribeNotes = fetchInboxNotes(userId, notesRaw => {
        const notes = _.map(notesRaw, this.formatNote)
        this.setState({ notes, notesFetched: true })
      })
    }
  }

  renderInboxMessages = () => {
    const { inboxMessages } = this.props
    const { tab, notes, notesFetched } = this.state
    if (tab === 'mentions' && !notesFetched) this.fetchNotes(false)
    const messages = tab === 'all' ? inboxMessages : notes
    return (
      <Box overflow='scroll' align='start' height='100%'>
        {_.map(messages, this.renderInboxMessage)}
        {this.renderLoadMoreButton()}
      </Box>
    )
  }

  handleNavigation = tab => {
    this.setState({ tab })
  }

  renderHeader = () => (
    <Box pad='medium' direction='row' align='center' justify='between'>
      <Box justify='center' direction='row' gap='large'>
        <Tab label='All' isActive={this.state.tab === 'all'} onClick={() => this.handleNavigation('all')} />
        <Tab label='Mentions' isActive={this.state.tab === 'mentions'} onClick={() => this.handleNavigation('mentions')} />
      </Box>
      <Box onClick={this.close}>
        <FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.TEXT} />
      </Box>
    </Box>
  )

  render () {
    const { visible } = this.state
    if (visible) {
      return (
        <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
          <Layer position='right' full='vertical' modal onClickOutside={this.close} onEsc={this.close}>
            <Box
              width='400px'
              fill='vertical'
              border='left'
              customStyle='box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)'
            >
              <Box fill='vertical' height='100%' justify='start'>
                {this.renderHeader()}
                {this.renderInboxMessages()}
              </Box>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, props) => ({
  inboxMessages: getInboxMessages(state),
  firstNoteId: _.get(state, 'inbox.firstNote.id'),
  account: state.account,
  workOrders: getWorkOrdersWithTitlesDict(state, props),
  hasMore: hasMoreInbox(state),
  userId: _.get(state, 'user.id'),
  readAt: getChannel(state),
  profiles: _.get(state, 'profiles')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(CommentsInbox)
