import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import { StyledText, StyledImage } from 'shared/components/StyledComponents'
import logo from 'assets/images/logo.png'

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10%;
`

const LoadingApp = ({ message, viewport }) => {
  return (
    <Container>
      <StyledImage viewport={viewport} size={200} source={logo} resizeMode='contain' />
    </Container>
  )
}

LoadingApp.defaultProps = {
  message: 'Loading...'
}

LoadingApp.propTypes = {
  message: PropTypes.string
}

const mapStateToProps = (state, props) => ({
  viewport: state.viewport
})

export default connect(mapStateToProps)(LoadingApp)
