import * as types from 'model/actionTypes'

const initialState = null

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_AUTH_DATA:
      return action.authData
    default:
      return state
  }
}
