import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import CreateClientModal from 'webPages/createBid/CreateClientModal'
import AddUserToCompanyModal from 'webPages/createBid/AddUserToCompanyModal'
import FilesPanel from 'components/panels/FilesPanel'
import { generateId } from 'constants/firebase'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getMyBidsByWorkOrders } from 'model/selectors/bids'
import { createSubProposalWorkOrder } from 'controllers/workOrder'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import { updateQuoteDraft, updateQuoteDraftParams } from 'controllers/user'
import { areRequiredFieldsDone, getDiscountAmount, getTotals, getUserScopeMemo } from 'shared/utils/bid'
import ScheduleOfPaymentsModal from 'webPages/createBid/ScheduleOfPaymentsModal'
import amountType from 'shared/constants/amountType'
import { getProgressPaymentsSum } from 'shared/utils/proposal'
import { getUsersProfilesByAccountId } from 'model/selectors/profiles'
import { getTradesDict } from 'model/selectors/references'
import { getName } from 'shared/utils/stringUtils'
import Comments from 'webPages/Comments'
import CommentsInbox from 'components/chat/CommentsInbox'

class CreateBidDataProvider extends Component {
  constructor (props) {
    super(props)
    // console.log('!!!!!!!!!!!!! CreateBidDataProvider constructor', props)
    this.state = {
      gcAccountId: _.get(props, 'workOrder.accountId'),
      gcProfile: _.get(props, 'workOrder.gcProfile'),
      setGCAccountId: this.setGCAccountId,
      address: _.get(props, 'workOrder.projectAddress'),
      setAddress: this.setAddress,
      openCreateClientModal: this.openCreateClientModal,
      getGCAccountProfile: this.getGCAccountProfile,
      openFilesPanel: this.openFilesPanel,
      projectId: _.get(props, 'projectId'),
      workOrderId: _.get(props, 'workOrderId'),
      bidId: _.get(props, 'bid.id', generateId()),
      openCommentsInbox: this.openCommentsInbox,
      closeCommentsInbox: this.closeCommentsInbox,
      setGCName: this.setGCName,
      tradeId: _.get(props.workOrder, 'tradeId', _.get(props.account, ['contractorTypeIds', 0])),
      setTradeId: this.setTradeId,
      isSubProposal: this.isSubProposal,
      getField: this.getField,
      setField: this.setField,
      setFields: this.setFields,
      setParams: this.setParams,
      openScheduleOfPaymentsModal: this.openScheduleOfPaymentsModal,
      isScheduleOfPaymetsValid: this.isScheduleOfPaymetsValid,
      setHighlightTradeId: this.setHighlightTradeId,
      highlightTradeId: false,
      openAddUserModal: this.openAddUserModal,
      canSendBid: this.canSendBid,
      getNewBid: this.getNewBid,
      getUserScopeOfWork: this.getUserScopeOfWork,
      setDepositType: this.setDepositType,
      setDepositValue: this.setDepositValue,
      openCommentsPanel: this.openCommentsPanel
    }
    this.createClientRef = React.createRef()
    this.filesPanelRef = React.createRef()
    this.commentsInboxRef = React.createRef()
    this.scheduleOfPaymentsRef = React.createRef()
    this.addUserModal = React.createRef()
    this.commentsPanelRef = React.createRef()
  }

  openAddUserModal = profile => {
    this.addUserModal.current.open(profile)
  }

  openCommentsPanel = channelId => {
    const { account } = this.props
    // console.log('openCommentsPanel', channelId)
    this.commentsPanelRef.current.open(channelId || _.get(account, 'id'))
  }

  setHighlightTradeId = v => this.setState({ highlightTradeId: v })

  getField = (fieldName, defaultValue) => {
    const { draft, bid, usersProfilesByAccountId, workOrder } = this.props
    const { gcAccountId } = this.state
    const res = _.get(draft, fieldName, _.get(bid, fieldName, defaultValue))
    if (fieldName === 'sendTo' && _.isEmpty(res)) {
      const defaultSendTo = {}
      const gcUsersProfiles = _.get(usersProfilesByAccountId, gcAccountId)
      _.forEach(gcUsersProfiles, (p, userId) => _.set(defaultSendTo, userId, { profile: p, checked: true }))
      return defaultSendTo
    } else if (fieldName === 'sections' && _.isEmpty(res)) {
      return _.get(workOrder, 'sections')
    } else {
      return res
    }
  }

  setDepositType = v => {
    this.setField('deposit')({ type: v, value: null })
  }

  setDepositValue = v => {
    const deposit = this.getField('deposit')
    const total = this.getField('total')
    const type = _.get(deposit, 'type', amountType.FLAT_AMOUNT)
    let value = v
    if (type === amountType.FLAT_AMOUNT) {
      value = _.max([0, value])
      value = _.min([value, total])
    } else {
      value = _.max([0, value])
      value = _.min([100, value])
    }
    value = value === 0 ? null : value
    this.setField('deposit')({ type, value })
  }

  isScheduleOfPaymetsValid = () => {
    const progressPayments = this.getField('progressPayments')
    if (_.isEmpty(progressPayments)) return true
    const total = this.getField('total')
    const deposit = this.getField('deposit')
    const depositValue = getDiscountAmount(deposit, total)
    const balanceDue = total - depositValue
    const progressPaymentsValue = getProgressPaymentsSum(progressPayments, balanceDue)
    return progressPaymentsValue <= balanceDue
  }

  setField = fieldName => v => {
    const { dispatch, workOrderId } = this.props
    const fieldsToCheck = ['items', 'discount', 'unpricedAmount', 'deposit']
    if (_.includes(fieldsToCheck, fieldName)) {
      const prevValues = {
        total: this.getField('total', 0),
        discount: this.getField('discount'),
        unpricedAmount: this.getField('unpricedAmount', 0),
        items: this.getField('items'),
        deposit: this.getField('deposit')
      }
      const newValues = { ...prevValues, [fieldName]: v }
      const totals = getTotals(newValues)
      this.setParams(totals)
    } else {
      dispatch(updateQuoteDraft(workOrderId, fieldName, v))
    }
  }

  setFields = params => {
    const fieldNames = _.keys(params)
    const fieldsToCheck = ['items', 'discount', 'unpricedAmount', 'deposit']
    const reacalcNeeded = _.size(_.intersection([fieldNames, fieldsToCheck])) > 0
    if (reacalcNeeded) {
      // console.log('setField fieldName', fieldName, 'value', v)
      const prevValues = {
        total: this.getField('total', 0),
        discount: this.getField('discount'),
        unpricedAmount: this.getField('unpricedAmount', 0),
        items: this.getField('items'),
        deposit: this.getField('deposit')
      }
      const newValues = { ...prevValues, ...params }
      const totals = getTotals(newValues)
      this.setParams(totals)
    } else {
      this.setParams(params)
    }
  }

  setParams = params => {
    const { dispatch, workOrderId } = this.props
    dispatch(updateQuoteDraftParams(workOrderId, params))
  }

  getUserScopeOfWork = () => {
    const { workOrder, account } = this.props
    const accountId = _.get(account, 'id')
    const scope = _.get(workOrder, 'scope', {})
    return getUserScopeMemo(scope, accountId)
  }

  canSendBid = () => {
    const total = this.getField('total')
    const items = this.getField('items')
    const scope = this.getUserScopeOfWork()
    const requiredFieldDone = areRequiredFieldsDone(scope, items)
    const scheduleOfPaymentsValid = this.isScheduleOfPaymetsValid()
    // console.log(
    //   'total',
    //   total,
    //   'requiredFieldDone',
    //   requiredFieldDone,
    //   'scheduleOfPaymentsValid',
    //   scheduleOfPaymentsValid
    // )
    return total > 0 && requiredFieldDone && scheduleOfPaymentsValid
  }

  getNewBid = () => {
    const {
      workOrderId,
      accountsProfiles,
      account,
      contractorTypes,
      projectTypeGroups,
      profiles,
      draft,
      bid,
      user,
      workOrder
    } = this.props
    console.log('getNewBid, draft', draft)
    const { bidId, tradeId } = this.state
    if (_.isNil(workOrderId)) return {}
    const accountId = _.get(account, 'id')
    const subAccountProfile = _.get(accountsProfiles, accountId)
    const contractorTypeIds = _.get(account, 'contractorTypeIds')
    const groupsAr = _.map(contractorTypeIds, ctId => _.get(contractorTypes, [ctId, 'groups'], []))
    const groups = _.uniq(_.flatten(groupsAr))
    const serviceTypeGroups = _.filter(_.orderBy(
      _.map(groups, gId => _.get(projectTypeGroups, gId)),
      ['displayOrder']
    ), g => g)
    const admins = _.get(account, 'admins', {})
    const hiddenContacts = _.get(subAccountProfile, 'hiddenContacts', [])
    const scope = this.getUserScopeOfWork()
    const gcProfile = this.getGCAccountProfile()
    const teammates = _.reduce(
      admins,
      (res, adm, admId) => {
        if (!_.includes(hiddenContacts, admId)) {
          const profile = _.get(profiles, admId, {})
          res.push(
            _.omitBy(
              {
                id: admId,
                name: getName(profile),
                avatar: _.get(profile, 'avatar'),
                avatarSmall: _.get(profile, 'avatarSmall'),
                phone: _.get(profile, 'phone'),
                role: adm.role
              },
              _.isNil
            )
          )
        }
        return res
      },
      []
    )
    const scopeItems = _.reduce(
      scope,
      (res, item, itemId) => {
        _.set(res, itemId, {})
        return res
      },
      {}
    )
    const draftItems = this.getField('items')
    // const draftItems = _.get(draft, 'items', {})
    // console.log('getNewBid scopeItems', scopeItems)
    // console.log('getNewBid draftItems', draftItems)
    const items = { ...scopeItems, ...draftItems }
    const newBid = {
      id: bidId,
      ...(bid || {}),
      createdBy: user.id,
      scope: _.get(workOrder, 'scope'),
      workOrderId: workOrderId,
      ...draft,
      items,
      owner: _.omitBy(
        {
          ...subAccountProfile,
          contractorLicense: _.get(account, 'contractorLicense')
        },
        _.isNil
      ),
      client: gcProfile,
      accountId: account.id,
      projectId: workOrder.projectId,
      seenBy: {},
      status: null,
      workOrderAccountId: workOrder.accountId,
      accounts: [account.id, workOrder.accountId],
      projectAddress: _.get(workOrder, 'projectAddress'),
      // needsGCSig: needsGCSig,
      tradeId,
      typeOfWork: _.get(workOrder, 'label', _.get(contractorTypes, [tradeId, 'name'])),
      serviceTypeGroups, // FIXME: it would be good to remove it
      teammates // FIXME: it would be good to remove it
      // cc
    }
    // console.log('new bid', newBid)
    return _.omitBy(newBid, _.isNil)
  }

  isSubProposal = () => {
    const { workOrder } = this.props
    return _.isNil(workOrder) || _.get(workOrder, 'isSubProposal', false)
  }

  onSubProfileReceived = gcInfo => {
    this.setState({ gcInfo })
  }

  setGCAccountId = async v => {
    this.setState({ gcAccountId: v })
  }

  setAddress = async v => {
    const { gcAccountId, gcProfile } = this.state
    const workOrderId = generateId()
    const projectId = generateId()
    const wo = {
      id: workOrderId,
      projectId,
      projectAddress: v,
      accountId: gcAccountId,
      gcProfile
    }
    await createSubProposalWorkOrder(wo)
    universalNavigation.navigate(screens.SUB_CREATE_BID, { projectId, workOrderId })
  }

  openCreateClientModal = name => this.createClientRef.current.open(name)
  openScheduleOfPaymentsModal = () => this.scheduleOfPaymentsRef.current.open()

  setTradeId = tradeId => this.setState({ tradeId })

  setGCName = name => this.setState({ gcProfile: { ...this.state.gcProfile, name } })

  openFilesPanel = () => {
    // console.log('open files panel')
    const { workOrderId, bidId } = this.state
    this.filesPanelRef.current.open(workOrderId, bidId)
  }

  openCommentsInbox = () => this.commentsInboxRef.current.open()
  closeCommentsInbox = () => this.commentsInboxRef.current.close()

  getGCAccountProfile = () => {
    const { gcProfile, gcAccountId } = this.state
    const { accountsProfiles } = this.props
    if (_.isEmpty(gcAccountId)) {
      return null
    } else if (_.isEmpty(gcProfile)) {
      return _.get(accountsProfiles, gcAccountId)
    } else {
      return gcProfile
    }
  }

  onClientCreated = profile => {
    this.setState({
      gcProfile: profile,
      gcAccountId: profile.id
    })
  }

  onUserCreated = profile => {
    const sendTo = this.getField('sendTo')
    const checked = _.get(sendTo, [profile.id, 'checked'], true)
    const newSendTo = { ...sendTo, [profile.id]: { profile, checked } }
    this.setField('sendTo')(newSendTo)
  }

  removeUserFromSentTo = userId => {
    const sendTo = this.getField('sendTo')
    const newSendTo = { ...sendTo }
    _.unset(newSendTo, userId)
    this.setField('sendTo')(newSendTo)
  }

  render () {
    const { workOrderId } = this.props
    return (
      <CreateBidContext.Provider value={{ ...this.state }}>
        {this.props.children}
        <CreateClientModal ref={this.createClientRef} onClientCreated={this.onClientCreated} />
        <AddUserToCompanyModal
          ref={this.addUserModal}
          gcProfile={this.getGCAccountProfile()}
          onUserCreated={this.onUserCreated}
          removeUserFromSentTo={this.removeUserFromSentTo}
        />
        <FilesPanel ref={this.filesPanelRef} title='Bid Invite Files' />
        <ScheduleOfPaymentsModal ref={this.scheduleOfPaymentsRef} />
        <Comments
          items={this.getField('items')}
          scope={this.getUserScopeOfWork()}
          workOrderId={workOrderId}
          commentsRef={this.commentsPanelRef}
          gcProfile={this.getGCAccountProfile()}
        />
        <CommentsInbox ref={this.commentsInboxRef} />
      </CreateBidContext.Provider>
    )
  }
}

CreateBidDataProvider.contextType = CreateBidContext

const mapStateToProps = (state, props) => ({
  accountsProfiles: state.accountsProfiles,
  workOrder: getWorkOrder(state, props),
  bid: _.get(getMyBidsByWorkOrders(state), props.workOrderId),
  account: state.account,
  draft: _.get(state.user, ['quoteDrafts', props.workOrderId], {}),
  usersProfilesByAccountId: getUsersProfilesByAccountId(state),
  contractorTypes: getTradesDict(state),
  projectTypeGroups: _.get(state, 'references.projectTypeGroups'),
  profiles: state.profiles,
  user: state.user
})

export default connect(mapStateToProps)(CreateBidDataProvider)
