import _ from 'lodash'
import Sentry from 'shared/utils/sentry'
import { Alert } from 'react-native'

import { auth, db, fieldValue } from 'constants/firebase'
import store from 'model/store'
import { receiveOutgoingInvitations, receiveIncomingInvitations } from 'model/actions/invitationsAC'
import { addListener } from 'controllers/listeners'
import { isWeb } from 'shared/constants/index'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import { switchAccount } from 'controllers/user'

export const getInvitationData = async invId => {
  try {
    const invitationSN = await db
      .collection('invitations')
      .doc(invId)
      .get()
    const invitation = invitationSN.data()
    return invitation
  } catch (error) {
    return null
  }
}

export const fetchOutgoingInvitations = accountId => {
  try {
    console.log('fetchOutgoingInvitations, accountId:', accountId)
    const unsubscribe = db
      .collection('invitations')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchOutgoingInvitations, amount', _.size(res))
          store.dispatch(receiveOutgoingInvitations(res))
        },
        err => {
          console.log(`fetchOutgoingInvitations error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('outgoingInvitations', unsubscribe)
  } catch (e) {
    console.log('fetchOutgoingInvitations error', e)
    Sentry.captureException(e)
  }
}

export const fetchIncomingInvitations = () => {
  try {
    const userId = auth.currentUser.uid
    console.log('fetchIncomingInvitations, userId:', userId)
    const unsubscribe = db
      .collection('invitations')
      .where('userId', '==', userId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchIncomingInvitations, amount', _.size(res))
          store.dispatch(receiveIncomingInvitations(res))
        },
        err => {
          console.log(`fetchIncomingInvitations error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('incomingInvitations', unsubscribe)
  } catch (e) {
    console.log('fetchIncomingInvitations error', e)
    Sentry.captureException(e)
  }
}

export const cancelInvitation = async invId => {
  try {
    await db
      .collection('invitations')
      .doc(invId)
      .delete()
  } catch (e) {
    console.log('cancelInvitation error', e)
    Sentry.captureException(e)
  }
}

export const declineInvitation = async invId => {
  try {
    const upd = {
      userId: fieldValue.delete(),
      declined: true
    }
    await db
      .collection('invitations')
      .doc(invId)
      .update(upd)
  } catch (e) {
    console.log('declineInvitation error', e)
    Sentry.captureException(e)
  }
}

export const acceptInvitation = async inv => {
  try {
    await db
      .collection('invitations')
      .doc(inv.id)
      .update({ accepted: true })

    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      invId: inv.id
    }
    const url = `${config.dynamicUrl}/proto/acceptInvitation`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('acceptInvitation answer', answer)
    if (answer.success) {
      const onPositivePress = () => {
        switchAccount(inv.accountId)
      }
      const onNegativePress = () => console.log('cancel')
      const title = `Would you like to switch to ${inv.companyName}`
      const desc = 'You can switch companies in your settings whenever you need to.'
      if (isWeb) {
        const res = window.confirm(`${title}\n${desc}`)
        if (res) onPositivePress()
        else onNegativePress()
      }
      Alert.alert(
        title,
        desc,
        [
          { text: 'Yes', onPress: onPositivePress },
          {
            text: 'No',
            onPress: onNegativePress
          }
        ],
        { cancelable: true }
      )
    } else {
      await db
        .collection('invitations')
        .doc(inv.id)
        .update({ accepted: false })
    }
  } catch (e) {
    // I'm not sure this has to be here, If the `inv` object does not exist, there will be an error
    // await db
    //   .collection('invitations')
    //   .doc(inv.id)
    //   .update({ accepted: true })
    console.log('acceptInvitation error', e)
    Sentry.captureException(e)
  }
}

export const inviteMasonAdmins = async (ids, accountId) => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      ids,
      accountId
    }
    const url = `${config.dynamicUrl}/proto/inviteMasonAdmins`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const res = await response.json()
    console.log('inviteMasonAdmins response', res)
    return true
  } catch (e) {
    console.log('inviteMasonAdmins error', e.message)
    return false
  }
}
