import React, { useContext, useMemo } from 'react'
import { Box, Text, ThemeContext } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import { css } from 'styled-components'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import { getTradesDict } from 'model/selectors/references'
import colors from 'shared/constants/colors'
import DropDownMenu from 'components/DropDownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

const Trade = ({ tradesDict }) => {
  const { isSubProposal, tradeId, setTradeId, highlightTradeId, setHighlightTradeId } = useContext(CreateBidContext)
  const disabled = !isSubProposal()

  const menuOptions = useMemo(() => _.map(tradesDict, ct => ({ label: ct.name, id: ct.id })), [tradesDict])

  const renderDropButton = () => {
    const title = _.isNil(tradeId) ? 'Select trade' : _.get(tradesDict, [tradeId, 'name'])
    let color = _.isNil(tradeId) ? colors.CLEAR_BLUE : colors.ANOTHER_GREY
    if (highlightTradeId) color = colors.CORAL_TWO
    return (
      <ThemeContext.Extend
        value={{
          box: {
            extend: props =>
              css`
                svg {
                  display: none;
                }
                :hover {
                  ${!_.isNil(tradeId) &&
                    `
                    span {
                      color: ${colors.ASANA_GRAY_TEXT_HOVERED};
                    }
                    svg path {
                      fill: ${colors.ASANA_GRAY_TEXT_HOVERED};
                    }
                  `}
                  background-color: ${highlightTradeId ? 'transparent' : colors.VERY_LIGHT_PINK_TWO};
                  svg {
                    display: block;
                  }
                }
              `
          }
        }}
      >
        <Box
          direction='row'
          align='center'
          gap='xsmall'
          pad={{ vertical: 'xxsmall', horizontal: 'small' }}
          round='xsmall'
          border={highlightTradeId ? { side: 'all', color: colors.CORAL_TWO } : undefined}
        >
          <Text color={color}>{title}</Text>
          <FontAwesomeIcon color={color} icon={faChevronDown} size={12} />
        </Box>
      </ThemeContext.Extend>
    )
  }

  const onMenuOptionClick = option => {
    console.log('onMenuOptionClick', option)
    setTradeId(option)
  }

  const onOpen = () => {
    setHighlightTradeId(false)
  }

  if (disabled) {
    return (
      <Box height={{ min: '46px' }} justify='center'>
        <Text color={colors.ANOTHER_GREY}>{_.get(tradesDict, [tradeId, 'name'])}</Text>
      </Box>
    )
  } else {
    // return (
    //   <TradesSelect
    //     tradeId={tradeId}
    //     onSelect={setTradeId}
    //     disabled={disabled}
    //     formFieldProps={{ label: undefined, height: undefined }}
    //   />
    // )

    return (
      <DropDownMenu
        options={menuOptions}
        dropContentProps={{ boxProps: { width: { min: '260px' } } }}
        dropAlign={{ top: 'bottom', left: 'right' }}
        onOptionClick={onMenuOptionClick}
        onOpen={onOpen}
        selectedId={tradeId}
        // disabled={!isSetup}
      >
        {renderDropButton()}
      </DropDownMenu>
    )
  }
}

const mapStateToProps = state => ({
  tradesDict: getTradesDict(state)
})

export default connect(mapStateToProps)(Trade)
