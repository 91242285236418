import React from 'react'
import PropTypes from 'prop-types'
import { Box, TextInput, FormField, ThemeContext, Text, Button, Keyboard } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'

const AuthLayout = ({
  header,
  subheader,
  error,
  isError,
  valueName,
  value,
  placeholder,
  handleChange,
  handleSubmit,
  isValid,
  submitName,
  footer,
  hideInputField
}) => {
  const renderSubheader = () => {
    if (_.isString(subheader)) {
      return (
        <Text textAlign='start' color={colors.LIGHT_NAVY_BRIGHT}>
          {subheader}
        </Text>
      )
    } else {
      return subheader
    }
  }

  const onEnter = () => {
    if (isValid) {
      handleSubmit()
    }
  }

  return (
    <Box pad='medium' align='start' fill>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box fill='horizontal' align='center' margin={{ top: 'large' }} flex={false} pad='large'>
        <Box flex={false} width='500px'>
          <Box align='start'>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              {header}
            </Text>
          </Box>

          <Box align='start' pad={{ top: 'medium' }}>
            {renderSubheader()}
          </Box>

          {!hideInputField && (
            <ThemeContext.Extend
              value={{
                formField: {
                  round: '5px',
                  border: {
                    color: isError ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                  },
                  focus: {
                    border: {
                      color: isError ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                    }
                  }
                }
              }}
            >
              <FormField label={<Text>{valueName}</Text>} width='medium' margin={{ top: 'medium' }}>
                <Keyboard onEnter={onEnter}>
                  <TextInput placeholder={placeholder} value={value} onChange={handleChange} />
                </Keyboard>
              </FormField>
            </ThemeContext.Extend>
          )}

          <Box height='xxsmall' margin={{ bottom: 'xxsmall' }}>
            {error}
          </Box>

          <Box width='medium' height='45px'>
            <Button
              fill
              primary
              color={colors.AQUA_MARINE}
              label={submitName}
              onClick={handleSubmit}
              disabled={!isValid}
              size='large'
            />
          </Box>

          {footer}
        </Box>
      </Box>
    </Box>
  )
}

AuthLayout.defaultProps = {
  subheader: 'We will text you a verification code to complete your registration.',
  submitName: 'Continue'
}

AuthLayout.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.any,
  error: PropTypes.any,
  isError: PropTypes.bool,
  valueName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  submitName: PropTypes.string,
  footer: PropTypes.any,
  hideInputField: PropTypes.bool
}

export default AuthLayout
