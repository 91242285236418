import React, { Component } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  FormField,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ThemeContext
} from 'grommet'
import _ from 'lodash'
import validator from 'validator'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'

import colors from 'shared/constants/colors'
import { FIRST_PART_LENGTH } from 'shared/constants/index'
import { generateId } from 'constants/firebase'
import { getName } from 'shared/utils/stringUtils'
import InputsList from 'components/InputsList'
import { emailMask, phoneMask } from 'utils/inputMask'
import { formatNational } from 'shared/utils/phone'

class AddUserToCompanyModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }
    this.nameInputRef = React.createRef()
  }

  open = profile => {
    const generatedId = generateId()
    console.log('add user modal, open, profile', profile)
    this.setState({
      visible: true,
      name: _.get(profile, 'name', ''),
      emails: _.get(profile, 'emails', []),
      phones: _.map(_.get(profile, 'phones', []), formatNational),
      id: _.get(profile, 'id', generatedId),
      isNew: _.isNil(profile),
      errors: {}
    })
    setTimeout(() => this.nameInputRef.current.focus(), 100)
  }

  close = () => {
    this.setState({ visible: false })
  }

  onChangeName = e => this.setState({ name: e.target.value })
  onChangeEmail = i => e => {
    const { emails } = this.state
    const newEmails = [...emails]
    _.set(newEmails, i, e.target.value)
    this.setState({ emails: newEmails })
  }

  phoneIsValid = () => {
    const { phone } = this.state
    if (phone === '') {
      return false
    } else {
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      if (phoneNumber) {
        return phoneNumber.isValid()
      } else {
        return false
      }
    }
  }

  submit = () => {
    const { phones, emails, name, id } = this.state
    const { onUserCreated } = this.props
    const errors = {}
    // if (!this.phoneIsValid()) _.set(errors, 'phone', 'Enter a valid phone number')
    // if (!validator.isEmail(email)) _.set(errors, 'email', 'Enter a valid email address')
    if (name.length === 0) _.set(errors, 'name', 'The user name must be not empty')
    if (_.isEmpty(phones) && _.isEmpty(emails)) {
      _.set(errors, 'emailsPhones', 'Specify at least one phone number or email address')
    }
    if (!_.isEmpty(errors)) {
      this.setState({ errors })
    } else {
      console.log('create client')
      const profile = {
        phones: _.map(phones, phone => parsePhoneNumberFromString(phone, 'US').number),
        name,
        emails,
        id
      }
      onUserCreated(profile)
      this.close()
    }
  }

  delete = () => {
    const { id } = this.state
    const { removeUserFromSentTo } = this.props
    removeUserFromSentTo(id)
    this.close()
  }

  resetErrors = () => this.setState({ errors: {} })

  onChangePhone = event => {
    const v = event.target.value
    console.log('onPhoneCnahge', v)
    if (v && v.length < FIRST_PART_LENGTH) {
      this.setState({ phone: v })
    } else {
      const aYT = new AsYouType('US')
      const formattedPhone = aYT.input(v)
      this.setState({
        phone: formattedPhone
      })
    }
  }

  addEmail = () => {
    const { emails } = this.state
    this.setState({ emails: [...emails, ''] })
  }

  updateEmails = emails => {
    console.log('update emails', emails)
    this.setState({ emails })
  }

  updatePhones = phones => {
    console.log('update phones', phones)
    this.setState({ phones })
  }

  renderEmails = () => {
    const { emails } = this.state
    return (
      <Box flex={{ shrink: 0 }} gap='small'>
        <Text>Email address</Text>
        <InputsList
          values={emails}
          setValues={this.updateEmails}
          mask={emailMask}
          isValid={validator.isEmail}
          buttonLabel='Add email'
        />
      </Box>
    )
  }

  isValidPhone = phone => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    if (phoneNumber) {
      return phoneNumber.isValid()
    } else {
      return false
    }
  }

  renderPhones = () => {
    const { phones } = this.state
    return (
      <Box flex={{ shrink: 0 }} gap='small' pad={{ top: 'medium' }}>
        <Text>Phone number</Text>
        <InputsList
          values={phones}
          setValues={this.updatePhones}
          mask={phoneMask}
          isValid={this.isValidPhone}
          buttonLabel='Add phone'
        />
      </Box>
    )
  }

  render () {
    const { visible, name, errors, isNew } = this.state
    const { gcProfile, title, buttonLabel } = this.props
    // console.log('projectAddress', address, 'timeZone', timeZone)
    const formFieldHeight = '120px'
    const modalTitle = title ?? `${isNew ? 'Create' : 'Edit'} User at ${getName(gcProfile)}`
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card>
            <CardHeader
              pad='medium'
              border={{
                color: 'border',
                size: '1px',
                side: 'bottom'
              }}
            >
              <Text size='xlarge' color={colors.BLACK} weight={600}>
                {modalTitle}
              </Text>
              {!isNew && (
                <Button
                  label='Delete'
                  color={colors.CORAL_TWO}
                  hoverTextColor={colors.CORAL_TWO}
                  onClick={this.delete}
                />
              )}
            </CardHeader>
            <CardBody
              pad={{ horizontal: 'medium', top: 'medium' }}
              overflow={{ vertical: 'auto', horizontal: 'hidden' }}
              wrap={false}
              width='large'
            >
              <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
                <Box flex height={{ min: formFieldHeight }}>
                  <FormField label='Name' flex round='small' error={errors.name}>
                    <TextInput
                      placeholder='Enter the user name'
                      color='dark-1'
                      maxLength={40}
                      value={name}
                      onChange={this.onChangeName}
                      plain
                      onFocus={this.resetErrors}
                      ref={this.nameInputRef}
                    />
                  </FormField>
                </Box>
                {this.renderEmails()}
                {this.renderPhones()}
              </ThemeContext.Extend>
            </CardBody>
            {errors.emailsPhones && (
              <Box pad={{ left: 'medium', top: 'small' }}>
                <Text margin={{ left: 'small' }} color={colors.CORAL_TWO}>
                  {errors.emailsPhones}
                </Text>
              </Box>
            )}
            <CardFooter align='center' justify='end'>
              <Button
                primary
                margin='medium'
                label={buttonLabel || (isNew ? 'Create' : 'Save')}
                onClick={this.submit}
                customStyle='border-radius: 4px'
              />
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

AddUserToCompanyModal.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  gcProfile: PropTypes.object,
  onUserCreated: PropTypes.func.isRequired,
  removeUserFromSentTo: PropTypes.func.isRequired
}

export default AddUserToCompanyModal
