import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Button, ThemeContext, Avatar, TextArea, Keyboard } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import numeral from 'numeral'
import { css } from 'styled-components'
import ReactResizeDetector from 'react-resize-detector'

import colors from 'shared/constants/colors'
import { updatePlugs, removePlug } from 'controllers/privateWorkOrder'
import LevelingPanelHeaderContainer from 'components/leveling/levelingPanel/LevelingPanelHeaderContainer'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import CurrencyInput from 'components/CurrencyInput'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import { sendMessage, updateSeenStatus } from 'controllers/chat'
import { MessagesList, renderHeader as renderCommentsHeader } from 'components/leveling/levelingPanel/Comments'
import { getLevelingTableMessages } from 'model/selectors/channels'
import messageType from 'constants/messageType'
import InputWithSelect from 'components/InputWithSelect'

class CellInfoPanelContent extends Component {
  constructor (props) {
    super(props)
    const { subId, itemId, workOrder } = props
    this.state = {
      message: '',
      focused: false,
      cost: _.get(workOrder, ['plugs', itemId, subId, 'cost'], null)
    }
    this.messagesEndRef = React.createRef()
  }

  static getDerivedStateFromProps = (props, state) => {
    const lastMessage = _.last(props.messages)
    if (lastMessage && lastMessage.id !== state.lastMessageId) {
      const channelKey = `${props.workOrderId}_${props.subId}_${props.itemId}`
      props.dispatch(updateSeenStatus(channelKey, lastMessage))
      return {
        lastMessageId: lastMessage.id
      }
    }
    return null
  }

  onQuantityCostChange = (quantity, cost) => {
    if (!_.isNil(quantity) && !_.isNil(cost)) {
      const total = _.floor(quantity * cost, 2)
      this.updatePlug({ quantity, cost, total })
    } else {
      this.updatePlug({
        quantity,
        cost
      })
    }
  }

  handleQuantityChange = v => {
    const { workOrder, itemId, subId } = this.props
    const plug = _.get(workOrder, ['plugs', itemId, subId])
    this.onQuantityCostChange(v, _.get(plug, 'cost'))
  }

  handleQuantityTypeChange = event => {
    this.updatePlug({ quantityType: event.value })
  }

  handleCostChange = e => {
    const value = e.target.value
    const cost = _.size(value) > 0 ? numeral(value).format('0,0.[00]') : null
    this.setState({ cost })
  }

  handleCostSubmit = () => {
    const { cost } = this.state
    const { workOrder, itemId, subId } = this.props
    const plug = _.get(workOrder, ['plugs', itemId, subId])
    const value = _.size(cost) > 0 ? numeral(cost).value() : null
    this.onQuantityCostChange(_.get(plug, 'quantity'), value)
  }

  updatePlug = params => {
    const { workOrderId, workOrder, itemId, subId, dispatch } = this.props
    const plug = _.get(workOrder, ['plugs', itemId, subId])
    const newPlug = _.omitBy({ ...plug, ...params }, _.isUndefined)
    console.log('updatePlug, newPlug', newPlug)
    if (_.isEmpty(_.omitBy(newPlug, _.isNil))) {
      console.log('the plug is empty, delete it')
      dispatch(removePlug(workOrderId, itemId, subId))
    } else {
      dispatch(updatePlugs(workOrderId, itemId, subId, newPlug))
    }
  }

  handleResetPlug = () => {
    const { workOrderId, itemId, subId, dispatch } = this.props
    dispatch(removePlug(workOrderId, itemId, subId))
  }

  handleTotalChange = v => {
    console.log('handleTotalChange', v)
    const plug = this.getPlug()
    const quantity = _.get(plug, 'quantity')
    console.log('handleTotalChange, quantity', quantity)
    if (!_.isNil(v)) {
      if (!_.isNil(quantity) && quantity > 0) {
        const newCost = _.ceil(v / quantity, 2)
        const newTotal = newCost * quantity
        this.updatePlug({ total: newTotal, cost: newCost })
      } else {
        this.updatePlug({ total: v, quantity: null, cost: null })
      }
    } else {
      this.updatePlug({ total: null, cost: null, quantity: null })
    }
  }

  renderHeader = () => {
    const { onClose, subId, accountsProfiles, bid, itemId, workOrder } = this.props
    const accountProfile = _.get(accountsProfiles, subId, {})
    // console.log('bid', bid, 'itemId', itemId)
    const itemName = _.get(bid, ['items', itemId, 'name'], _.get(workOrder, ['scope', itemId, 'name']))
    return (
      <LevelingPanelHeaderContainer onClose={onClose}>
        <Box gap='xxsmall' flex>
          <Text size='large' color={colors.TEXT_PRIMARY} weight={800}>
            {itemName}
          </Text>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            {getName(accountProfile)}
          </Text>
        </Box>
      </LevelingPanelHeaderContainer>
    )
  }

  renderTotalRow = () => {
    const plug = this.getPlug()
    const total = _.get(plug, 'total')
    return (
      <Box direction='row' align='start' justify='center'>
        <CurrencyInput placeholder='Total' value={total} onChange={this.handleTotalChange} size='small' />
      </Box>
    )
  }

  renderQuantityRow = () => {
    const plug = this.getPlug()
    const value = _.get(plug, 'quantity')
    return (
      <Box direction='row' align='start' justify='center'>
        <CurrencyInput placeholder='Quantity' value={value} onChange={this.handleQuantityChange} size='small' formatString='0,0.[00]' />
      </Box>
    )
  }

  getItem = () => {
    const { bid, subId, itemId, workOrder } = this.props
    const selectedAlternates = _.get(workOrder, 'alternatesConf', {})
    const altItemId = _.get(selectedAlternates, [subId, itemId])
    const altInfo = _.get(bid, ['items', itemId, 'alternates', altItemId])
    let item = _.get(bid, ['items', itemId])
    if (!_.isEmpty(altInfo)) item = altInfo
    return item
  }

  getPlug = () => {
    const { subId, itemId, workOrder } = this.props
    return _.get(workOrder, ['plugs', itemId, subId])
  }

  renderCostRow = () => {
    const { cost } = this.state
    const plug = this.getPlug()
    const item = this.getItem()
    const options = ['sf', 'cy', 'lf', 'ls', 'ea', 'cf', 'lbs', 'ft']
    const quantityType = _.get(plug, 'quantityType', _.get(item, 'quantityType'))
    // const value = _.get(plug, 'cost')
    return (
      <Box direction='row' align='start' justify='center' className={IGNORE_OUTSIDE_CLICK_CLASS}>
        <InputWithSelect
          onFocus={() => null}
          onChange={this.handleCostChange}
          onBlur={this.handleCostSubmit}
          onSelectChange={this.handleQuantityTypeChange}
          value={cost}
          selectValue={quantityType}
          options={options}
          placeholder='Cost / Unit ($)'
          inputRef={null}
          id='plug-cost'
          dropProps={{ className: IGNORE_OUTSIDE_CLICK_CLASS }}
          alwaysShow
          controlProps={{ border: { color: colors.VERY_LIGHT_GREY_TWO, radius: '4px' } }}
          textInputProps={{
            extend: css`
              text-align: start;
            `
          }}
        />
      </Box>
    )
  }

  renderComments = () => {
    const { messages, profiles, workOrder } = this.props
    return (
      <Box
        justify='end'
        background={colors.PALE_GREY}
        className={IGNORE_OUTSIDE_CLICK_CLASS}
        fill
        overflow='auto'
        customStyle='{ scroll-behavior: smooth }'
      >
        <Box justify='end' flex>
          <MessagesList workOrder={workOrder} messages={messages} messagesEndRef={this.messagesEndRef} profiles={profiles} />
        </Box>
      </Box>
    )
  }

  renderFooter = () => {
    const { accountsProfiles, userProfile, subId } = this.props
    const { message, focused } = this.state
    const profile = _.get(accountsProfiles, subId)

    const scrollToBottom = () => {
      if (this.messagesEndRef.current && focused) this.messagesEndRef.current.scrollIntoView(false)
    }

    const handleFocus = () => {
      this.setState({ focused: true })
    }

    const handleBlur = e => {
      const { relatedTarget } = e
      if (_.get(relatedTarget, 'id') === 'send_message') {
        updateMessages()
        this.setState({ focused: false })
      } else {
        this.setState({ focused: false })
      }
    }

    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        updateMessages()
      }
    }

    const updateMessages = () => {
      const { message } = this.state
      if (message !== '') {
        const { workOrderId, workOrder, itemId, subId, userId } = this.props
        const gcAccountId = _.get(workOrder, 'accountId')
        const params = _.omitBy(
          {
            text: message,
            accountId: gcAccountId,
            itemId,
            subId,
            userId,
            accounts: [gcAccountId, subId],
            workOrderId,
            type: messageType.ITEM,
            projectId: _.get(workOrder, 'projectId')
          },
          _.isNil
        )
        sendMessage(params)
        this.setState({ message: '' })
        scrollToBottom()
      }
    }

    const onChange = event => {
      const message = event.target.value
      this.setState({ message })
    }

    const textInputThemeValue = {
      textArea: {
        extend: css`
          background: ${colors.WHITE};
        `
      }
    }

    return (
      <Box
        background={colors.PALE_GREY}
        direction='row'
        align='center'
        height={focused ? { min: '115px' } : { min: '60px' }}
        pad={focused ? { bottom: 'small' } : 'none'}
      >
        <Box fill='vertical' justify='start' pad='small' width={{ min: '55px' }}>
          <Avatar size='32px' src={_.get(userProfile, 'avatar')}>
            {getInitials(userProfile)}
          </Avatar>
        </Box>
        <Box
          fill
          margin={{ right: 'small' }}
          background={colors.WHITE}
          customStyle={`
            height: ${focused ? '90px' : '40px'};
            border-radius: 5px;
            padding-top: ${focused ? '6px' : '0'};
          `}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <ThemeContext.Extend value={textInputThemeValue}>
            <ReactResizeDetector handleHeight onResize={scrollToBottom}>
              <Keyboard onKeyDown={handleKeyDown}>
                <TextArea
                  plain
                  onChange={onChange}
                  value={message}
                  size='small'
                  resize={false}
                  placeholder='Ask a question about this line item or post an update...'
                />
              </Keyboard>
            </ReactResizeDetector>
          </ThemeContext.Extend>
          {focused && (
            <Box
              direction='row'
              gap='small'
              align='center'
              customStyle={`
                position: absolute;
                bottom: 20px;
                right: 15px;
                animation: slide-up 0.5s ease;
                @keyframes slide-up {
                  0% {
                      opacity: 0;
                  }
                  100% {
                      opacity: 1;
                  }
                }
              `}
            >
              <Text color={colors.ANOTHER_GREY} size='xsmall'>
                {getName(profile)} will be notified
              </Text>
              <Box width='xsmall'>
                <Button
                  disabled={!focused}
                  id='send_message'
                  fill
                  size='small'
                  primary
                  label='Comment'
                  color={colors.AQUA_MARINE}
                  onClick={updateMessages}
                  tabIndex={-1}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  renderItemDetails = () => {
    const { bid, itemId, workOrder } = this.props
    let item = _.get(bid, ['items', itemId], null)
    const isExcluded = _.get(item, 'excluded', false)
    if (isExcluded) {
      return (
        <Box pad='small' flex={{ shrink: 0 }} height='small'>
          <Box align='start' justify='center' pad={{ vertical: 'small' }}>
            <Text color={colors.MEDIUM_GREY} size='medium'>
              Add internal Plug
            </Text>
          </Box>
          <Box direction='row' gap='xxsmall'>
            {this.renderQuantityRow()}
            {this.renderCostRow()}
            {this.renderTotalRow()}
          </Box>
        </Box>
      )
    } else if (_.isNil(_.get(item, 'answer'))) {
      if (_.isNil(item)) {
        const merging = _.get(workOrder, 'merging')
        const mergingIndex = _.indexOf(_.values(merging), itemId)
        if (mergingIndex >= 0) {
          const mergingId = _.keys(merging)[mergingIndex]
          item = _.get(bid, ['items', mergingId], null)
        }
      }
      const cost = _.get(item, 'cost')
      const total = _.get(item, 'total')
      const quantity = _.get(item, 'quantity')
      const quantityType = _.get(item, 'quantityType')
      return (
        <Box
          pad={{ left: 'medium', right: 'large' }}
          gap='medium'
          flex={{ shrink: 0 }}
          margin={{ bottom: 'large', top: 'medium' }}
        >
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Total</Text>
            <Box direction='row' width='small'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {`${!_.isNil(total) ? numeral(total).format('$0,0.[00]') : ''}`}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Quantity</Text>
            <Box direction='row' width='small' justify='between'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {quantity}
                </Text>
              </Box>
              <Box>
                <Text color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
                  {quantityType}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between'>
            <Text color={colors.MEDIUM_GREY}>Cost / unit</Text>
            <Box direction='row' width='small'>
              <Box width='xsmall'>
                <Text textAlign='end' color={colors.TEXT_PRIMARY}>
                  {`${!_.isNil(cost) ? numeral(cost).format('$0,0.[00]') : ''}`}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
  }

  render () {
    const { accountsProfiles, subId } = this.props
    const profile = _.get(accountsProfiles, subId)
    return (
      <Box fill height='100%' flex customStyle='* { transition: all 0s ease; }'>
        {this.renderHeader()}
        {this.renderItemDetails()}
        {renderCommentsHeader(getName(profile), colors.PALE_GREY)}
        {this.renderComments()}
        {this.renderFooter()}
      </Box>
    )
  }
}

CellInfoPanelContent.propTypes = {
  onClose: PropTypes.func,
  subId: PropTypes.string,
  workOrderId: PropTypes.string,
  itemId: PropTypes.string
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: state.accountsProfiles,
  workOrder: getWorkOrder(state, props),
  bid: _.get(getBidsByWorkOrdersAccounts(state), [props.workOrderId, props.subId]),
  messages: _.get(getLevelingTableMessages(state), ['cells', props.subId, props.itemId], []),
  userProfile: getCurrentUserProfile(state),
  gcAccountId: _.get(state, ['user', 'currentAccountId']),
  userId: _.get(state, 'user.id'),
  profiles: state.profiles
})

export default connect(mapStateToProps)(CellInfoPanelContent)
