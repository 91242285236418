import React from 'react'
import { View } from 'react-native'
import styled from 'sc'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'

const LineItemRowHeader = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.getSize(3)}px;
  padding-bottom: ${props => props.getSize(3)}px;
  min-height: ${props => props.getSize(24)}px;
  ${props => props.customStyle || ''}
  padding-top: ${props => props.getSize(14)}px;
  padding-bottom: ${props => props.getSize(14)}px;
  border: 0;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.TWILIGHT_BLUE};
`

const Cell = styled(View)`
  display: flex;
  flex: ${props => props.flex || 1};
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  ${props => props.customStyle || ''}
`

const TableHeader = ({ getSize, isCalculated }) => {
  if (isCalculated) {
    return (
      <LineItemRowHeader key={'table_header'} getSize={getSize}>
        <Cell flex={4}>
          <StyledText bold fontSize={getSize(12)}>Description</StyledText>
        </Cell>
        <Cell customStyle='justify-content: center;'>
          <StyledText bold fontSize={getSize(12)}>Quantity</StyledText>
        </Cell>
        <Cell customStyle='justify-content: center;'>
          <StyledText bold fontSize={getSize(12)}>Rate</StyledText>
        </Cell>
        <Cell customStyle='justify-content: flex-end;'><StyledText bold fontSize={getSize(12)}>Amount</StyledText></Cell>
      </LineItemRowHeader>
    )
  } else {
    return (
      <LineItemRowHeader key={'table_header'} getSize={getSize}>
        <Cell flex={4}><StyledText bold fontSize={getSize(12)}>Description</StyledText></Cell>
        <Cell customStyle='justify-content: flex-end;'>
          <StyledText bold fontSize={getSize(12)}>Quantity</StyledText>
        </Cell>
      </LineItemRowHeader>
    )
  }
}

export default TableHeader
