import React, { useContext } from 'react'
import { Box, Text, Button, List } from 'grommet'
import numeral from 'numeral'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment-timezone'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import colors from 'shared/constants/colors'
import { getDiscountAmount } from 'shared/utils/bid'
import { getProgressPaymentsSum } from 'shared/utils/proposal'
import { toTimestamp } from 'shared/utils/date'
import { timeZone as defaultTimeZone } from 'shared/constants/index'
import amountType from 'shared/constants/amountType'

const ScheduleOfPayments = () => {
  const { getField, address, openScheduleOfPaymentsModal, isScheduleOfPaymetsValid } = useContext(CreateBidContext)
  const progressPayments = getField('progressPayments')
  const total = getField('total')
  const deposit = getField('deposit')
  const depositValue = getDiscountAmount(deposit, total)
  const balanceDue = total - depositValue
  const progressPaymentsSum = getProgressPaymentsSum(progressPayments, balanceDue)
  const defaultPhaseValue = balanceDue - progressPaymentsSum
  const phases = _.sortBy(progressPayments, pp => toTimestamp(pp.date))
  if (defaultPhaseValue > 0) {
    phases.push({
      id: 'default',
      name: 'Completion of all work',
      cost: { value: defaultPhaseValue, type: amountType.FLAT_AMOUNT }
    })
  }

  const timeZone = _.get(address, 'timeZone', defaultTimeZone)

  const isValid = isScheduleOfPaymetsValid()

  const renderPhase = phase => {
    const phaseValue = getDiscountAmount(phase.cost, balanceDue)
    // const phaseType = _.get(phase, 'cost.type')
    return (
      <Box key={phase.id} height={{ min: '36px' }} direction='row' align='center'>
        <Box pad={{ left: 'medium' }} basis='1/2'>
          <Text size='small' color={colors.TEXT_PRIMARY} truncate>
            {phase.name}
          </Text>
        </Box>
        <Box basis='1/4' align='center'>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            {phase.date ? moment.tz(phase.date, timeZone).format('ll') : null}
          </Text>
        </Box>
        <Box basis='1/4' align='center'>
          <Text size='small' color={colors.DARK_GRAY_TWO}>
            {numeral(phaseValue).format('$0,0.00')}
          </Text>
        </Box>
      </Box>
    )
  }
  return (
    <Box pad={{ top: 'small', bottom: 'large' }} flex={{ shrink: 0 }}>
      <Box
        pad={{ horizontal: 'medium', bottom: 'small' }}
        flex={{ shrink: 0 }}
        align='center'
        justify='between'
        direction='row'
      >
        <Text color={isValid ? colors.MEDIUM_GREY : colors.CORAL_TWO}>Schedule of payments</Text>
        {!isValid && (
          <Text size='small' color={colors.CORAL_TWO}>
            <i>Needs your attention</i>
          </Text>
        )}
      </Box>
      <List data={phases} children={renderPhase} pad='none' />
      <Box direction='row' pad={{ left: 'medium', top: 'xsmall' }} flex={{ shrink: 0 }}>
        <Button
          icon={<FontAwesomeIcon icon={faEdit} size={12} />}
          label='Edit schedule of payments'
          gap='xsmall'
          size='small'
          onClick={openScheduleOfPaymentsModal}
        />
      </Box>
    </Box>
  )
}

export default ScheduleOfPayments
