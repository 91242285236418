import _ from 'lodash'
import reportWorkOrderOption from 'shared/constants/reportWorkOrderOption'

export const getSelectedBidOption = (reportSettings, workOrderId, bids) => {
  const curValue = _.get(reportSettings, ['includedBids', workOrderId])
  bids = _.values(bids)
  if (_.isUndefined(curValue)) {
    if (_.size(bids) === 1) {
      return _.get(bids, [0, 'id'])
    } else if (_.size(bids) > 1) {
      return reportWorkOrderOption.EXCLUDED
    } else {
      return reportWorkOrderOption.EXCLUDED
    }
  } else {
    return curValue
  }
}

export default {
  getSelectedBidOption
}
