import React, { useState, useRef, useEffect } from 'react'
import { ThemeContext, Box, Select, Text } from 'grommet'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import CurrencyInput from 'components/CurrencyInput.web'
import amountType from 'shared/constants/amountType'
import { getDiscountAmount } from 'shared/utils/bid'
import OutsideClickHandlerWrapper from 'components/OutsideClickHandlerWrapper'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'

const InputCostPercOrFlat = ({
  phaseId,
  cost,
  onChangePhase,
  onFocus,
  onBlur,
  highlightIncomlete,
  maxValue,
  balanceDue
}) => {
  const [focused, setFocused] = useState(false)
  const inputRef = useRef()
  const value = _.get(cost, 'value')
  const costType = _.get(cost, 'type', amountType.FLAT_AMOUNT)
  const isIncomplete = highlightIncomlete && value <= 0

  useEffect(() => {
    if (focused) {
      inputRef.current.focus()
    }
  }, [focused])

  const onChange = v => {
    let value = v
    if (costType === amountType.FLAT_AMOUNT) {
      value = _.max([0, value])
      value = _.min([value, maxValue])
    } else {
      const maxPerc = (maxValue / balanceDue) * 100
      value = _.max([0, value])
      value = _.min([100, value, maxPerc])
    }
    if (value === 0) value = null
    const phaseCost = { type: costType, value }
    console.log('onChange, initial value', v, 'phaseCost', phaseCost)
    onChangePhase(phaseId, 'cost', phaseCost)
    _onBlur()
  }

  const _onBlur = () => {
    console.log('onBlur')
    setFocused(false)
    onBlur()
  }

  const _onFocus = () => {
    console.log('onFocus')
    setFocused(true)
    onFocus()
  }

  const onChangeType = ({ value }) => {
    console.log('onChangeType', value)
    onChangePhase(phaseId, 'cost', { type: value.id, value: null })
  }

  const options = {
    [amountType.FLAT_AMOUNT]: { id: amountType.FLAT_AMOUNT, label: '$' },
    [amountType.PERCENTAGE]: { id: amountType.PERCENTAGE, label: '%' }
  }

  const startEditing = () => {
    setFocused(true)
    // inputRef.current.focus()
  }

  const renderInput = () => (
    <ThemeContext.Extend
      value={{
        global: {
          control: {
            // border: { color: focused ? colors.AQUA_MARINE : 'transparent', radius: '0px' }
          },
          input: {
            extend: css`
              background-color: ${focused ? colors.WHITE : 'transparent'};
            `
          },
          colors: {
            placeholder: isIncomplete ? colors.CORAL_TWO : colors.VERY_LIGHT_PINK
          }
        },
        select: {
          control: {
            extend: css`
              input {
                padding-right: 3px;
                font-weight: 400;
                width: 26px;
              }
              > div > div {
                width: 26px;
                margin: 0;
                padding-left: 0;
              }
            `
          },
          container: {
            extend: css`
              button > div {
                align-items: center;
              }
            `
          }
        }
      }}
    >
      <OutsideClickHandlerWrapper
        onClick={_onBlur}
        enabled
        customStyle={`
          background-color: auto;
          display: flex;
          flex-direction: column;
          width: auto;
          height: auto;
          flex: 1;
          box-sizing: border-box;
          box-shadow: none;
          z-index: 2;
        `}
      >
        <Box
          direction='row'
          align='center'
          border={{ color: focused ? colors.AQUA_MARINE : 'transparent', radius: '0px' }}
          pad={{ left: 'xxsmall' }}
          background={focused ? colors.WHITE : 'transparent'}
        >
          <CurrencyInput
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            onFocus={_onFocus}
            // onBlur={_onBlur}
            placeholder={isIncomplete ? 'Enter an amount' : 'Amount'}
            textAlign='end'
            focusIndicator
            formatString={costType === amountType.FLAT_AMOUNT ? '$0,0.[00]' : '0.[00]'}
            plain
          />
          <Select
            options={_.values(options)}
            onChange={onChangeType}
            size='medium'
            value={_.get(options, costType)}
            plain
            labelKey='label'
            valueKey='id'
            onFocus={_onFocus}
            onOpen={_onFocus}
            // textAlign='end'
            icon={<FontAwesomeIcon icon={faChevronDown} size={10} />}
            dropProps={{ className: IGNORE_OUTSIDE_CLICK_CLASS }}
          />
        </Box>
      </OutsideClickHandlerWrapper>
    </ThemeContext.Extend>
  )

  if (focused) {
    return renderInput()
  } else if (_.isNil(value)) {
    return (
      <Box onClick={startEditing} fill align='center' justify='center' gap='xxsmall' direction='row'>
        <Text color={isIncomplete ? colors.CORAL_TWO : colors.VERY_LIGHT_PINK}>
          {isIncomplete ? 'Enter an amount' : 'Amount'}
        </Text>
      </Box>
    )
  } else {
    const flatValue = getDiscountAmount(cost, balanceDue)
    return (
      <Box onClick={startEditing} fill align='center' justify='center' gap='xxsmall' direction='row'>
        <Text color={colors.DARK_GRAY_TWO} weight={600}>
          {numeral(flatValue).format('$0,0.[00]')}
        </Text>
        {costType === amountType.PERCENTAGE && (
          <Text color={colors.VERY_LIGHT_PINK} weight={400}>
            {`(${numeral(cost.value).format('0.[00]')}%)`}
          </Text>
        )}
      </Box>
    )
  }
}

InputCostPercOrFlat.propTypes = {
  phaseId: PropTypes.string,
  value: PropTypes.number,
  onChangePhase: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  highlightIncomlete: PropTypes.bool,
  maxValue: PropTypes.number
}

export default InputCostPercOrFlat
