import React from 'react'
import { View } from 'react-native'

import { SpaceBetweenFlexDiv, StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import _ from 'lodash'

const renderHeader = getSize => {
  return (
    <SpaceBetweenFlexDiv
      key='project_summary_table_header'
      customStyle={`
        border: 0;
        border-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: ${colors.LIGHT_NAVY_BRIGHT};
        padding-bottom: ${getSize(12)}px;
      `}
    >
      <StyledText fontSize={getSize(12)} bold>
        Trade
      </StyledText>
      <StyledText fontSize={getSize(12)} bold>
        Amount
      </StyledText>
    </SpaceBetweenFlexDiv>
  )
}

const renderRow = (getSize, key, title) => {
  return (
    <SpaceBetweenFlexDiv
      key={key}
      customStyle={`padding-top: ${getSize(6)}px; padding-bottom: ${getSize(6)}px; align-items: center;`}
    >
      <View style={{ flex: 1 }}>
        <StyledText fontSize={getSize(12)}>{title}</StyledText>
      </View>
    </SpaceBetweenFlexDiv>
  )
}

const renderWorkOrders = params => {
  const { getSize, workOrders, contractorTypes } = params
  const res = []
  _.forEach(workOrders, wo => {
    const tradeName = _.get(contractorTypes, [wo.tradeId, 'name'])
    const label = _.get(wo, 'label', tradeName)
    res.push(renderRow(getSize, wo.id, label))
  })
  return res
}

const renderLine = () => {
  return (
    <SpaceBetweenFlexDiv
      customStyle={`
        border: 0;
        border-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: ${colors.LIGHT_NAVY_BRIGHT};
      `}
    />
  )
}

const renderProjectSummary = params => {
  const { getSize } = params
  return [renderHeader(getSize), ...renderWorkOrders(params), renderLine()]
}

export default renderProjectSummary
