import React, { useRef, useContext, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { Box } from 'grommet'
import _ from 'lodash'

import { fetchMessagesWebDashboard } from 'controllers/chat'
import { getWorkOrdersWithTitles } from 'model/selectors/workOrdersSelector'
import { getProject } from 'model/selectors/projectsInfoSelector'
import { getBidsByProject, getBidsByWorkOrders } from 'model/selectors/bids'
import DashboardSubmenu from 'webPages/dashboard/DashboardSubmenu'
import DashboardTable from 'webPages/dashboard/DashboardTable'
import LayoutContext from 'webPages/layout/LayoutContext'
import LevelingContext from 'webPages/leveling/LevelingContext'
import { getLevelingTableMessages } from 'model/selectors/channels'
import { offListener } from 'controllers/listeners'
import SubInfoPanel from 'webPages/dashboard/SubInfoPanel'
import CreateBidManually from 'webPages/createBid/CreateBidManually'
import { getAccountsProfiles } from 'model/selectors/profiles'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import reportType from 'shared/constants/reportType'
import levelingTableSettings from 'constants/levelingTableSettings'

const Dashboard = ({
  project,
  workOrders,
  bids,
  accountsProfiles,
  messages,
  channel,
  accountId,
  dispatch,
  bidsByWorkOrder,
  settings
}) => {
  const { openBidInviteModal } = useContext(LayoutContext)
  const { archivedVisible } = useContext(LevelingContext)
  const panelRef = useRef(null)
  const createBidManuallyRef = useRef()

  const projectId = _.get(project, 'id')

  useEffect(() => {
    if (!_.isNil(projectId)) fetchMessagesWebDashboard(projectId, accountId)
    return () => offListener('messagesDashboard')
  }, [accountId, projectId])

  const handleAddBidInvite = () => {
    openBidInviteModal(projectId)
  }

  const openCreateBidManuallyModal = (subId, workOrderId) => {
    console.log('openCreateBidManuallyModal, subId', subId, workOrderId)
    if (!_.isEmpty(subId)) {
      createBidManuallyRef.current.open({ subId, workOrderId })
    }
  }

  const openSubDetails = (invId, woId) => {
    const panelProps = {
      invId,
      workOrderId: woId,
      openCreateBidManuallyModal: () => openCreateBidManuallyModal(invId, woId)
    }
    panelRef.current.open(panelProps)
  }

  const workOrdersList = useMemo(() => {
    return _.reduce(
      workOrders,
      (res, wo) => {
        if (wo.deleted > 0 && !archivedVisible) {
          return res
        } else {
          return [...res, { ...wo, bids: _.get(bidsByWorkOrder, wo.id) }]
        }
      },
      []
    )
  }, [workOrders, archivedVisible, bidsByWorkOrder])

  const generateReport = useCallback(() => {
    universalNavigation.push(screens.GC_PROJECT_SHARE, { projectId, reportType: reportType.SELECTED_BIDS })
  }, [projectId])

  const settingsChanged = _.size(
    _.filter(settings, (v, name) => {
      if (_.includes([levelingTableSettings.SHOW_SECTIONS, levelingTableSettings.SHOW_DECLINED], name)) {
        return !v
      }
      return v
    })
  )

  return (
    <Box fill>
      <DashboardSubmenu
        addBidInvite={handleAddBidInvite}
        generateReport={generateReport}
        settingsChanged={settingsChanged}
        projectId={projectId}
      />
      <Box fill>
        <Box height='100%' wrap={false}>
          <Box overflow='auto' height='100%' flex>
            <DashboardTable
              addBidInvite={handleAddBidInvite}
              openSubDetails={openSubDetails}
              workOrders={workOrdersList}
              project={project}
              bids={bids}
              accountsProfiles={accountsProfiles}
              channel={channel}
              messages={messages}
              dispatch={dispatch}
              openCreateBidManuallyModal={openCreateBidManuallyModal}
            />
          </Box>
        </Box>
      </Box>
      <SubInfoPanel ref={panelRef} />
      <CreateBidManually ref={createBidManuallyRef} />
    </Box>
  )
}

const mapStateToProps = (state, props) => ({
  project: getProject(state, props),
  workOrders: getWorkOrdersWithTitles(state, props),
  bids: getBidsByProject(state, props),
  bidsByWorkOrder: getBidsByWorkOrders(state),
  accountsProfiles: getAccountsProfiles(state),
  channel: state.channelWeb,
  messages: _.get(getLevelingTableMessages(state, props), 'subs'),
  accountId: _.get(state, 'account.id'),
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(Dashboard)
