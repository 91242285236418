import React, { useState, useEffect } from 'react'
import { Box, TextInput, ThemeContext, Keyboard } from 'grommet'
import _ from 'lodash'

const RenderDescriptionField = ({ li, parentLi, handleUpdate, textInputThemeValue, selectRow, unselectRow }) => {
  const [desc, setDesc] = useState(_.get(li, 'desc', ''))

  useEffect(() => {
    if (_.has(li, 'desc')) {
      setDesc(li.desc)
    }
  }, [li])

  const onChange = event => setDesc(event.target.value)
  const onBlur = () => {
    document.activeElement.blur()
    unselectRow()
    if (li.desc !== desc) {
      handleUpdate(desc)
    }
  }
  return (
    <Box align='center' justify='center' border={['bottom', 'right']}>
      <ThemeContext.Extend value={textInputThemeValue}>
        <Keyboard onEnter={onBlur}>
          <TextInput value={desc} onChange={onChange} onFocus={selectRow} onBlur={onBlur} />
        </Keyboard>
      </ThemeContext.Extend>
    </Box>
  )
}
export default RenderDescriptionField
