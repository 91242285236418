import React from 'react'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'

const SharePanelContainer = ({ children, address }) => {
  return (
    <Box
      fill='vertical'
      width='400px'
      border='left'
      customStyle={`box-shadow: 0px 4px 8px ${colors.BLACK20}`}
      flex={{ shrink: 0 }}
    >
      <Box height={'55px'} border='bottom' justify='center' gap='xxsmall' pad={{ left: 'small' }} flex={{ shrink: 0 }}>
        <Text size='large' weight={800} color={colors.TEXT_PRIMARY}>
          Report
        </Text>
        <Text size='small' color={colors.ANOTHER_GREY}>
          {address}
        </Text>
      </Box>
      <Box fill='vertical' overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
        {children}
      </Box>
    </Box>
  )
}

export default SharePanelContainer
