import _ from 'lodash'
import Sentry from 'shared/utils/sentry'
import generate from 'firebase-auto-ids'

import { db, auth, fieldValue } from 'constants/firebase'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import store from 'model/store'
import { saveImageBase64DataUrl } from 'shared/controllers/storage'

export function updateSettings (accountId, name, value) {
  return async function (dispatch, getState) {
    db.collection('accounts')
      .doc(accountId)
      .update({ [`settings.${name}`]: value })
  }
}

export function updateAccount (update) {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'user.currentAccountId')
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export function updateProfileSettings (accountId, name, value) {
  return async function (dispatch, getState) {
    db.collection('accountsProfiles')
      .doc(accountId)
      .update({ [`settings.${name}`]: value })
  }
}

export function updateContractorLicense (accountId, r) {
  return async function (dispatch, getState) {
    const cl = {
      ...r,
      updateNum: _.now()
    }
    db.collection('accounts')
      .doc(accountId)
      .update({ contractorLicense: cl })
  }
}

export async function fetchAccount (accountId) {
  const accountDoc = await db
    .collection('accounts')
    .doc(accountId)
    .get()
  return accountDoc.data()
}

export function editUserRole (userId, roleId) {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'user.currentAccountId')
    db.collection('accounts')
      .doc(accountId)
      .update({ [`admins.${userId}.role`]: roleId })
  }
}

export const plaidConnected = async metadata => {
  try {
    console.log('plaidConnected, metadata', metadata)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const body = {
      authToken,
      metadata,
      accountId
    }
    console.log('body', body)
    const url = `${config.dynamicUrl}/proto/plaidConnectedAccount`
    console.log('post to url ', url, 'body', body)
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      const res = await response.json()
      console.log('plaidConnected response json', res)
      return res
    } else {
      console.log('plaidConnected response status', response.status)
      return { error: `Server response status: ${response.status}` }
    }
  } catch (e) {
    console.log('plaidConnected error', e)
    Sentry.captureException(e)
    return { error: e.message }
  }
}

export const createStripeSession = async (priceId, preconConfId) => {
  try {
    console.log('createCheckoutSession, priceId', priceId)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const body = {
      authToken,
      priceId,
      accountId,
      preconConfId,
      redirectUrl: window.location.origin + window.location.pathname
    }
    console.log('body', body)
    const url = `${config.dynamicUrl}/stripe/createCheckoutSession`
    console.log('post to url ', url, 'body', body)
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      const res = await response.json()
      console.log('createCheckoutSession response json', res)
      return res
    } else {
      console.log('createCheckoutSession response status', response.status)
      return { error: `Server response status: ${response.status}` }
    }
  } catch (e) {
    console.log('createCheckoutSession error', e)
    Sentry.captureException(e)
    return { error: e.message }
  }
}

export const createCustomerPortalSession = async () => {
  try {
    console.log('createCustomerPortalSession')
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const body = {
      authToken,
      accountId,
      returnUrl: window.location.origin + window.location.pathname
    }
    console.log('body', body)
    const url = `${config.dynamicUrl}/stripe/createCustomerPortalSession`
    console.log('post to url ', url, 'body', body)
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      const res = await response.json()
      console.log('createCustomerPortalSession response json', res)
      return res
    } else {
      console.log('createCustomerPortalSession response status', response.status)
      return { error: `Server response status: ${response.status}` }
    }
  } catch (e) {
    console.log('createCustomerPortalSession error', e)
    Sentry.captureException(e)
    return { error: e.message }
  }
}

export const cancelSubscription = async () => {
  try {
    console.log('cancelSubscription')
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const body = {
      authToken,
      accountId
    }
    console.log('body', body)
    const url = `${config.dynamicUrl}/stripe/cancelSubscription`
    console.log('post to url ', url, 'body', body)
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      const res = await response.json()
      console.log('cancelSubscription response json', res)
      return res
    } else {
      console.log('cancelSubscription response status', response.status)
      return { error: `Server response status: ${response.status}` }
    }
  } catch (e) {
    console.log('cancelSubscription error', e)
    Sentry.captureException(e)
    return { error: e.message }
  }
}

export const saveReferralAgreementSignature = async (base64str, onProgress) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const path = `account/${accountId}/referralAgreementSignature`
  const res = await saveImageBase64DataUrl(path, base64str, onProgress)
  return res
}

export const saveReferralAgreementSignatureInfo = sigInfo => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    const upd = {
      referralAgreementSignature: sigInfo
    }
    db.collection('accounts')
      .doc(accountId)
      .update(upd)
  }
}

export const getCustomContract = async accountId => {
  const ccSN = await db
    .collection('customContracts')
    .doc(accountId)
    .get()
  return ccSN.data()
}

export const saveTemplate = (id, params, liId, value) => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    let resParams = { ...params }
    let update = {}
    if (!liId) {
      const lineItemId = generate(_.now())
      resParams = { ...resParams, values: [lineItemId] }
      update = {
        ...update,
        [`lineItems.${lineItemId}`]: {
          id: lineItemId,
          name: value
        }
      }
    }
    update = { ...update, [`templates.${id}`]: { ...resParams } }
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export const updateTemplate = (id, value, isNewInTemplate, liId) => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    const lineItemId = liId || generate(_.now())
    let update = {
      [`templates.${id}.values`]: isNewInTemplate
        ? fieldValue.arrayUnion(lineItemId)
        : fieldValue.arrayRemove(lineItemId)
    }
    if (!liId) {
      update = {
        ...update,
        [`lineItems.${lineItemId}`]: {
          id: lineItemId,
          ...value
        }
      }
    }
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export const addNewLineItem = (name, templateId) => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    const id = generate(_.now())
    let update = {
      [`lineItems.${id}`]: { id, name }
    }
    if (!_.isNil(templateId)) {
      update = {
        ...update,
        [`templates.${templateId}.values`]: fieldValue.arrayUnion(id)
      }
    }
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export const addImportLineItems = (item, templateId) => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    let update = {
      [`lineItems.${item.id}`]: item
    }
    if (!_.isNil(templateId)) {
      update = {
        ...update,
        [`templates.${templateId}.values`]: fieldValue.arrayUnion(item.id)
      }
    }
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export const deleteTemplate = templateId => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    db.collection('accounts')
      .doc(accountId)
      .update({
        [`templates.${templateId}`]: fieldValue.delete()
      })
  }
}

export const deleteLineItem = (id, templatesIds) => {
  return (dispatch, getState) => {
    const state = store.getState()
    const accountId = _.get(state, 'account.id')
    let update = {
      [`lineItems.${id}`]: fieldValue.delete()
    }
    if (_.size(templatesIds) > 0) {
      _.forEach(templatesIds, templateId => {
        update = {
          ...update,
          [`templates.${templateId}.values`]: fieldValue.arrayRemove(id)
        }
      })
    }
    db.collection('accounts')
      .doc(accountId)
      .update(update)
  }
}

export const createTemplate = async (accountId, templateId, templateName) => {
  const update = {
    [`templates.${templateId}`]: {
      id: templateId,
      name: templateName,
      values: []
    }
  }

  await db
    .collection('accounts')
    .doc(accountId)
    .update(update)
}

export const addTradeId = tradeId => async (dispatch, getState) => {
  const state = getState()
  const account = state.account
  const currentTradesList = _.get(account, 'contractorTypeIds', [])
  const newTradesList = _.uniq([tradeId, ...currentTradesList])
  await db
    .collection('accounts')
    .doc(account.id)
    .update({ contractorTypeIds: newTradesList })
}
