import React, { useState } from 'react'
import { Box, Text, TextInput, Avatar, ThemeContext, Keyboard } from 'grommet'
import _ from 'lodash'
import { css } from 'styled-components'

import colors from 'shared/constants/colors'
import { sendMessage } from 'controllers/chat'
import messageType from 'constants/messageType'
import { getInitials } from 'shared/utils/stringUtils'

const textInputThemeValue = {
  global: {
    control: { border: { color: 'transparent', radius: '0px' } }
  },
  textInput: {
    container: {
      extend: css`
        height: 100%;
        input {
          background-color: ${colors.WHITE};
          text-align: left;
          height: 100%;
          border-radius: 5px;
          border: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
        }
      `
    }
  }
}

const MessageInput = ({ userProfile, account, workOrderId, id, gcId }) => {
  const avatar = _.get(userProfile, 'avatarSmall')
  const subAccountId = _.get(account, 'id')
  const subUserId = _.get(userProfile, 'id')

  const [value, setValue] = useState('')
  const updateMessages = e => {
    if (value !== '') {
      const type = id === subAccountId ? messageType.SUB : messageType.ITEM
      const message = {
        text: value,
        accountId: subAccountId,
        subId: subAccountId,
        userId: subUserId,
        accounts: [subAccountId, gcId],
        workOrderId,
        type
      }
      if (type === messageType.ITEM) {
        message.itemId = id
      }
      sendMessage(message)
      setValue('')
    }
  }
  return (
    <Box
      align='center'
      border='top'
      gap='small'
      pad={{ left: 'small', bottom: 'medium', top: 'medium' }}
      direction='row'
    >
      <Box pad={{ left: 'xsmall' }} flex={false}>
        <Avatar size='32px' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
          <Text color={colors.WHITE}>{getInitials(userProfile)}</Text>
        </Avatar>
      </Box>
      <Box fill='horizontal' pad={{ right: 'small' }}>
        <ThemeContext.Extend value={textInputThemeValue}>
          <Keyboard onEnter={updateMessages}>
            <TextInput
              border
              size='small'
              value={value}
              onChange={e => setValue(e.target.value)}
              placeholder='Ask a question about this line item or post an update...'
            />
          </Keyboard>
        </ThemeContext.Extend>
      </Box>
    </Box>
  )
}

export default MessageInput
