import React, { Component } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  FormField,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ThemeContext
} from 'grommet'
import _ from 'lodash'
import validator from 'validator'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import colors from 'shared/constants/colors'
import InputsList from 'components/InputsList'
import { emailMask } from 'utils/inputMask'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import { addNewContacts, editContactRow } from 'controllers/contacts'
import { WithTags } from 'components/contacts/TagInput'
import { formatPhoneInput } from 'shared/utils/phone'

const SUGGESTIONS_LIMIT = 12
const formFieldHeight = '120px'

class AddContactModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      name: '',
      companyName: '',
      selectedCompanyId: '',
      emails: [],
      phones: [],
      tags: [],
      errors: {},
      contactId: null
    }
  }

  open = ({ contactId }) => {
    if (contactId) {
      const contact = _.get(this.props.contacts, `contacts.${contactId}`, {})
      const name = _.get(contact, 'name', '')
      const emails = _.get(contact, 'emails', [])
      const phones = _.get(contact, 'phones', [])
      const tags = _.get(contact, 'tags', [])
      const selectedCompanyId = _.get(contact, 'companyId', '')
      const companyName = _.get(
        this.props.contacts,
        `companies.${_.get(this.props.contacts, `contacts.${contactId}.companyId`, '')}.name`,
        ''
      )
      this.setState({ visible: true, contactId, name, emails, phones, tags, selectedCompanyId, companyName })
    } else {
      this.setState({ visible: true })
    }
  }

  resetValues = () => {
    this.setState({
      name: '',
      companyName: '',
      selectedCompanyId: '',
      emails: [],
      phones: [],
      tags: [],
      errors: {},
      contactId: null
    })
  }

  close = () => {
    this.setState({ visible: false })
    this.resetValues()
  }

  onChangeName = e => {
    this.setState({ name: e.target.value })
  }

  submit = () => {
    const { selectedCompanyId, companyName, emails, phones, tags, contactId, name } = this.state
    const errors = {}
    if (name.length === 0) _.set(errors, 'name', 'The user name must be not empty')
    if (_.isEmpty(selectedCompanyId) && _.isEmpty(companyName)) {
      _.set(errors, 'company', 'Specify company name')
    }
    if (_.isEmpty(phones) && _.isEmpty(emails)) {
      _.set(errors, 'emailsPhones', 'Specify at least one phone number or email address')
    }
    if (!_.isEmpty(errors)) {
      this.setState({ errors })
    } else {
      if (_.isNull(contactId)) {
        // is new
        addNewContacts([
          {
            contactName: name,
            companyName,
            companyId: selectedCompanyId,
            emails,
            phones,
            tags
          }
        ])
      } else {
        editContactRow({
          contactId,
          contactName: name,
          companyName,
          companyId: selectedCompanyId,
          emails,
          phones,
          tags
        })
      }
      this.close()
    }
  }

  resetErrors = () => {
    this.setState({ errors: {} })
  }

  updateEmails = emails => this.setState({ emails })

  updatePhones = phones => this.setState({ phones })

  onChangeCompany = e => {
    this.setState({ companyName: e.target.value, selectedCompanyId: '' })
  }

  onSuggestionSelect = event => {
    this.setState({ companyName: _.get(event, 'suggestion.label'), selectedCompanyId: _.get(event, 'suggestion.id') })
  }

  renderCompanySelect = () => {
    return (
      <Box flex={{ shrink: 0 }} gap='xsmall' height={{ min: formFieldHeight }}>
        <Text>Company Name</Text>
        <TextInput
          value={this.state.companyName}
          onChange={this.onChangeCompany}
          suggestions={this.getSuggestions()}
          onSuggestionSelect={this.onSuggestionSelect}
        />
        {this.state.errors.company && (
          <Box pad={{ top: 'xxsmall' }}>
            <Text color={colors.CORAL_TWO}>{this.state.errors.company}</Text>
          </Box>
        )}
      </Box>
    )
  }

  renderEmails = () => {
    return (
      <Box flex={{ shrink: 0 }} gap='xsmall'>
        <Text>Email address</Text>
        <InputsList
          values={this.state.emails}
          setValues={this.updateEmails}
          mask={emailMask}
          isValid={validator.isEmail}
          buttonLabel='Add email'
        />
      </Box>
    )
  }

  renderPhones = () => {
    return (
      <Box flex={{ shrink: 0 }} gap='xsmall' pad={{ top: 'medium' }}>
        <Text>Phone number</Text>
        <InputsList
          values={this.state.phones}
          setValues={this.updatePhones}
          formatOnChange={formatPhoneInput}
          isValid={this.isValidPhone}
          buttonLabel='Add phone'
        />
      </Box>
    )
  }

  renderTags = () => {
    return (
      <Box gap='small' margin={{ top: 'medium' }}>
        <Text>Tags</Text>
        <WithTags
          tags={this.state.tags}
          updateTags={tags => {
            this.setState({ tags })
          }}
          allSuggestions={_.get(this.props.contacts, 'tags', {})}
        />
      </Box>
    )
  }

  isValidPhone = phone => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    if (phoneNumber) {
      return phoneNumber.isValid()
    } else {
      return false
    }
  }

  getSuggestions = () => {
    if (_.isEmpty(this.state.companyName)) return []
    let res = []
    const reg = new RegExp(_.toLower(this.state.companyName), 'gi')

    const _companies = _.get(this.props.contacts, 'companies', {})
    _.map(_.keys(_companies), companyId => {
      const _companyName = _.get(_companies, `${companyId}.name`, '')
      if (_companyName.match(reg)) {
        res.push({
          id: companyId,
          label: _companyName
        })
      }
    })
    res = _.orderBy(res, ['label'], ['asc'])

    res = _.take(res, SUGGESTIONS_LIMIT)
    return res
  }

  render () {
    const { errors, visible, contactId, name } = this.state
    if (!visible) return null
    return (
      <Layer onEsc={this.close} onClickOutside={this.close} className={IGNORE_OUTSIDE_CLICK_CLASS}>
        <Card>
          <CardHeader
            pad='medium'
            border={{
              color: 'border',
              size: '1px',
              side: 'bottom'
            }}
          >
            <Text size='xlarge' color={colors.BLACK} weight={600}>
              {_.isNull(contactId)
                ? 'Add New Contact'
                : `Edit ${_.get(this.props.contacts, `contacts.${contactId}.name`, '')}`}
            </Text>
          </CardHeader>
          <CardBody
            pad={{ horizontal: 'medium', top: 'medium' }}
            overflow={{ vertical: 'auto', horizontal: 'hidden' }}
            wrap={false}
            width='large'
          >
            <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
              <Box flex height={{ min: formFieldHeight }}>
                <FormField label='Contact Name' flex round='small' error={errors.name}>
                  <TextInput
                    placeholder='Enter the contact name'
                    color='dark-1'
                    maxLength={40}
                    value={name}
                    onChange={this.onChangeName}
                    plain
                    onFocus={this.resetErrors}
                  />
                </FormField>
              </Box>
              {this.renderCompanySelect()}
              {this.renderEmails()}
              {this.renderPhones()}
              {this.renderTags()}
            </ThemeContext.Extend>
          </CardBody>
          {errors.emailsPhones && (
            <Box pad={{ left: 'medium', top: 'small' }}>
              <Text margin={{ left: 'small' }} color={colors.CORAL_TWO}>
                {errors.emailsPhones}
              </Text>
            </Box>
          )}
          <CardFooter align='center' justify='end'>
            <Button
              primary
              margin='medium'
              label={_.isNull(contactId) ? 'Create' : 'Update'}
              onClick={this.submit}
              customStyle='border-radius: 4px'
            />
          </CardFooter>
        </Card>
      </Layer>
    )
  }
}

export default AddContactModal
