import React from 'react'
import { Avatar, Box, Text } from 'grommet'
import _ from 'lodash'

import { getInitials } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'

const formatFromCamelCase = string => {
  if (string) {
    const res = string.replace(/([A-Z])/g, ' $1')
    return res.charAt(0).toUpperCase() + res.slice(1)
  }
  return false
}

const ProjectDetails = ({ avatar, gcName, invitedBy, address, bidsDue, isEmptySow, typeOfWork, projectType, projectSize }) => {
  const type = typeOfWork && projectType
    ? `${formatFromCamelCase(typeOfWork)} - ${formatFromCamelCase(projectType)}`
    : formatFromCamelCase(typeOfWork) || formatFromCamelCase(projectType) || ''
  return (
    <Box flex justify='start' gap='large' pad='medium'>
      <Box align='center' fill='horizontal'>
        <Avatar size='xlarge' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
          <Text color={colors.WHITE} size='xxlarge'>{getInitials(gcName, true)}</Text>
        </Avatar>
      </Box>
      <Box direction='row' gap='small' justify='center' overflow='auto'>
        <Text color={colors.LIGHT_NAVY_BRIGHT} size='small' weight={600}>{invitedBy}</Text>
        <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>from</Text>
        <Text color={colors.LIGHT_NAVY_BRIGHT} size='small' weight={600}>{gcName}</Text>
        <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>has invited you to bid on</Text>
      </Box>
      <Box direction='row' gap='small' justify='center'>
        <Text weight={600} size='xlarge' color={colors.LIGHT_NAVY_BRIGHT}>Project address:</Text>
        <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT}>{address || '–'}</Text>
      </Box>
      <Box gap='small' justify='start'>
        <Box>
          <Text weight={600} size='large' color={colors.TEXT}>Project details</Text>
        </Box>
        <Box direction='row' align='center' justify='between'>
          <Text size='medium' color={colors.TEXT}>Location:</Text>
          <Text size='medium' color={colors.TEXT}>{address}</Text>
        </Box>
        <Box direction='row' align='center' justify='between'>
          <Text size='medium' color={colors.TEXT}>Bids due:</Text>
          <Text size='medium' color={colors.TEXT}>{bidsDue}</Text>
        </Box>
        <Box direction='row' align='center' justify='between'>
          <Text size='medium' color={colors.TEXT}>Scope of work:</Text>
          <Text size='medium' color={isEmptySow ? colors.CORAL_TWO : colors.AQUA_MARINE}>
            {isEmptySow ? 'not provided' : 'provided'}
          </Text>
        </Box>
        {(typeOfWork || projectType) && (
          <Box direction='row' align='center' justify='between'>
            <Text size='medium' color={colors.TEXT}>Type:</Text>
            <Text size='medium' color={colors.TEXT}>
              {type}
            </Text>
          </Box>
        )}
        {projectSize && (
          <Box direction='row' align='center' justify='between'>
            <Text size='medium' color={colors.TEXT}>Project size:</Text>
            <Text size='medium' color={colors.TEXT}>
              {projectSize} sf
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProjectDetails
