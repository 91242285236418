import Sentry from 'shared/utils/sentry'
import _ from 'lodash'

import { auth, db, fieldValue } from 'constants/firebase'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import { saveImageBase64DataUrl } from 'shared/controllers/storage'
import { isWeb } from 'shared/constants/index'
import history from 'shared/utils/history'
import { clearListeners } from 'controllers/listeners'
// import universalNavigation from 'utils/universalNavigation'

export async function switchAccount (accId) {
  console.log('SWITCH ACCOUNT', accId, 'isWeb', isWeb)
  if (_.isNil(accId)) {
    console.warn('cannot switch to account', accId)
    return null
  }
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      if (isWeb) {
        console.log(' ----> history replace ')
        history.replace('/')
      }
      clearListeners()
      await db
        .collection('users')
        .doc(uid)
        .update({ currentAccountId: accId })
    } else {
      console.log('switchAccount, no uid')
      Sentry.captureMessage(`${accId} switchAccount, uid is undefined`, 'fatal')
    }
  } catch (e) {
    console.log('cant change account', e)
    Sentry.captureException(e)
  }
}

export async function setTooltipAsViewed (requestType) {
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      await db
        .collection('users')
        .doc(uid)
        .update({ [`tooltipsStatuses.${requestType}`]: true })
    }
  } catch (e) {
    console.log('setTooltipAsViewed error:', e)
    Sentry.captureException(e)
  }
}

export const updateNotifications = (kind, lst) => {
  return async (dispatch, getState) => {
    const upd = {}
    _.forEach(lst, (v, k) => {
      upd[`disabledNotifications.${kind}.${k}`] = v || fieldValue.delete()
    })
    console.log('updateNotifications', upd)
    const uid = auth.currentUser.uid
    db.collection('users')
      .doc(uid)
      .update(upd)
  }
}

export const updateUserLocation = async locations => {
  try {
    console.log('updateUserLocation', locations)
    const uid = _.get(auth, 'currentUser.uid')
    if (!_.isNil(uid)) {
      const upd = {}
      _.forEach(locations, l => {
        const k = `${_.toInteger(l.timestamp)}`
        const loc = {
          lat: _.get(l, 'coords.latitude', null),
          lng: _.get(l, 'coords.longitude', null),
          id: k
        }
        console.log('set k', k, 'loc', loc)
        upd[k] = loc
      })
      console.log('upd', upd)
      await db
        .collection('users')
        .doc(uid)
        .collection('locationsHistory')
        .doc('history')
        .set(upd, { merge: true })
    } else {
      console.log('cant update userLocation, uid is undefined')
      // Sentry.captureException(new Error('Cannot update userLocation, uid is undefined'))
    }
  } catch (e) {
    console.log('updateUserLocation error;', e)
    Sentry.captureException(e)
  }
}

export function updateQuoteDraft (draftId, draftPath, value) {
  return async function (dispatch, getState) {
    try {
      const authUser = auth.currentUser
      value = _.isObject(value) ? _.omitBy(value, _.isNil) : value
      value = _.isNil(value) ? fieldValue.delete() : value
      const upd = { [`quoteDrafts.${draftId}.${draftPath}`]: value }
      const state = getState()
      const accountId = _.get(state, 'account.id')

      const batch = db.batch()
      batch.update(db.collection('users').doc(authUser.uid), upd)
      batch.update(db.collection('workOrders').doc(draftId), {
        [`invitations.${accountId}.draftCreated.${authUser.uid}`]: _.now()
      })
      await batch.commit()
    } catch (e) {
      console.log('updateQuoteDraft error:', e)
      Sentry.captureException(e)
    }
  }
}

export function updateQuoteDraftParams (draftId, params) {
  return async function (dispatch, getState) {
    try {
      const authUser = auth.currentUser
      const upd = {}
      _.forEach(params, (v, k) => {
        v = _.isNil(v) ? fieldValue.delete() : v
        _.set(upd, [`quoteDrafts.${draftId}.${k}`], v)
      })
      const state = getState()
      const accountId = _.get(state, 'account.id')
      const batch = db.batch()
      batch.update(db.collection('users').doc(authUser.uid), upd)
      batch.update(db.collection('workOrders').doc(draftId), {
        [`invitations.${accountId}.draftCreated.${authUser.uid}`]: _.now()
      })
      await batch.commit()
    } catch (e) {
      console.log('updateQuoteDraft error:', e)
      Sentry.captureException(e)
    }
  }
}

export function updateSalespersonRegistration (r) {
  return async function (dispatch, getState) {
    const authUser = auth.currentUser
    db.collection('users')
      .doc(authUser.uid)
      .update({ salespersonRegistration: r })
  }
}

export async function updateUserEmail (email) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/updateUserEmail`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ email, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateUserEmail error', e)
    return { success: false, error: e.message }
  }
}

export const saveUserSignature = async (base64str, onProgress) => {
  try {
    const userId = auth.currentUser.uid
    const path = `user/${userId}/signatures/${_.now()}.png`
    const res = await saveImageBase64DataUrl(path, base64str, onProgress)
    await db
      .collection('users')
      .doc(userId)
      .update({ signature: res.url })
    return res
  } catch (e) {
    Sentry.captureException(e)
    console.log('saveUserSignature error', e)
    return null
  }
}

export const updateLevelingCustomization = (workOrderId, k, v) => async (dispatch, getState) => {
  try {
    const userId = auth.currentUser.uid
    const upd = {
      [`customizationSettings.${workOrderId}.${k}`]: v
    }
    await db
      .collection('users')
      .doc(userId)
      .update(upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateLevelingCustomization error', e)
  }
}

export const setFilesSeen = (workOrderId, fileId) => async (dispatch, getState) => {
  try {
    const userId = auth.currentUser.uid
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const upd = {
      [`invitations.${accountId}.filesSeen.${fileId}.${userId}`]: _.now()
    }
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(upd)
  } catch (error) {
    Sentry.captureException(error)
    console.log('setFilesSeen error', error)
  }
}
