import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Box, Text, Layer, Button, ThemeContext, List } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'

import { getMyBidsByWorkOrders, getBidsByWorkOrders } from 'model/selectors/bids'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'
import colors from 'shared/constants/colors'
import { deleteWorkOrderAttachment, saveFilesToWorkOrder, saveFileToWorkOrder } from 'controllers/workOrder'
import { updateProjectFiles, removeProjectFile } from 'controllers/project'
import FileRow from 'components/panels/filesPanel/FileRow'
import { toTimestamp } from 'shared/utils/date'
import FilesPickerButton from 'components/web/FilesPickerButton'
import { updateQuoteDraft } from 'controllers/user'
import filesTypes from 'shared/constants/filesTypes'
import ConfirmationModal from 'components/panels/ConfirmationModal'

class FilesPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.confirmationModalRef = React.createRef()
  }

  open = (workOrderId, bidId) => {
    console.log('open workOrderId, bidId', workOrderId, bidId)
    const { workOrders, workOrdersWithTitles, account } = this.props
    const woAccountId = _.get(workOrders, [workOrderId, 'accountId'])
    const woTitle = _.get(workOrdersWithTitles, [workOrderId, 'title'])

    const isCreator = woAccountId === _.get(account, 'id')
    // console.log('open', workOrderId, isCreator, 'woAccountId', woAccountId, 'accountId', _.get(account, 'id'))
    this.setState({
      workOrderId,
      isWorkOrderCreator: isCreator,
      open: true,
      bidId,
      woTitle
    })
  }

  close = () => {
    this.setState({
      open: false
    })
  }

  getWorkOrderFiles = () => {
    const { workOrderId } = this.state
    const { workOrders } = this.props
    return _.get(workOrders, [workOrderId, 'files'], {})
  }

  getProjectFiles = () => {
    const { workOrderId, isWorkOrderCreator } = this.state
    const { workOrders, projects } = this.props
    const wo = _.get(workOrders, workOrderId)
    if (isWorkOrderCreator) {
      return _.get(projects, [_.get(wo, 'projectId'), 'attachments'], {})
    }
  }

  getBidFiles = () => {
    const { workOrderId } = this.state
    const { myBids } = this.props
    return _.get(myBids, [workOrderId, 'files'], {})
  }

  renderHeader = () => {
    return (
      <Box direction='row' justify='between' pad={{ horizontal: 'medium' }} margin={{ top: 'large' }} align='center'>
        <Box fill='horizontal'>
          <Text weight={600} size='large'>
            Plan Room
          </Text>
        </Box>
        <Box align='center'>
          <Button
            onClick={this.close}
            label={<FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.LIGHT_NAVY} />}
          />
        </Box>
      </Box>
    )
  }

  renderListHeader = (title, props) => {
    return (
      <Box
        key={title}
        pad={{ horizontal: 'medium', top: 'small', bottom: 'small' }}
        direction='row'
        flex={{ shrink: 0 }}
      >
        <Text {...props}>{title}</Text>
      </Box>
    )
  }

  deleteWorkOrderFile = fileId => {
    const { workOrderId, woTitle } = this.state
    console.log('deleteWorkOrderFile', fileId)
    const workOrderFiles = this.getWorkOrderFiles()
    const f = _.get(workOrderFiles, fileId)
    this.confirmationModalRef.current.open({
      title: `Are you sure you want to delete file from ${woTitle}?`,
      renderButtons: () => (
        <Box direction='row' gap='small' justify='end'>
          <Button
            secondary
            color={colors.CORAL_TWO}
            label='Cancel'
            onClick={() => this.confirmationModalRef.current.close()}
          />
          <Button
            secondary
            color={colors.AQUA_MARINE}
            label='Accept'
            onClick={() => {
              deleteWorkOrderAttachment(workOrderId, f)
              this.confirmationModalRef.current.close()
            }}
          />
        </Box>
      )
    })
  }

  openFilePreview = fileId => {
    const { workOrderId } = this.state
    const url = `${window.location.origin.toString()}/workorderfile/${workOrderId}/${fileId}`
    window.open(url, '_blank')
  }

  toggleProjectFile = f => {
    const { workOrderId } = this.state
    console.log('toggleProjectFile', f.id)
    const workOrderFiles = this.getWorkOrderFiles()
    if (_.has(workOrderFiles, f.id)) {
      deleteWorkOrderAttachment(workOrderId, f)
    } else {
      saveFileToWorkOrder(workOrderId, f)
    }
  }

  renderBidInviteFile = file => {
    const { readOnly } = this.props
    const { woTitle } = this.state
    const projectFiles = this.getProjectFiles()
    const workOrderFiles = this.getWorkOrderFiles()
    const isProjectFile = _.has(projectFiles, file.id)
    return (
      <FileRow
        key={file.id}
        file={file}
        onDelete={readOnly ? undefined : () => this.deleteWorkOrderFile(file.id)}
        included={_.has(workOrderFiles, file.id)}
        onToggle={isProjectFile ? () => this.toggleProjectFile(file) : undefined}
        onPreview={() => this.openFilePreview(file.id)}
        type={filesTypes.BID_INVITE}
        title={woTitle}
      />
    )
  }

  renderProjectFile = (file, projectId) => {
    const { woTitle } = this.state
    return (
      <FileRow
        key={file.id}
        file={file}
        onDelete={() => this.onDeleteProjectFile(file.id, projectId)}
        onToggle={() => this.toggleProjectFile(file)}
        onPreview={() => this.openFilePreview(file.id)}
        type={filesTypes.PROJECT}
        title={woTitle}
      />
    )
  }

  renderBidFile = file => {
    return <FileRow key={file.id} file={file} onPreview={() => this.openFilePreview(file.id)} />
  }

  renderJobOfferFile = file => {
    return <FileRow key={file.id} file={file} onPreview={() => this.openFilePreview(file.id)} />
  }

  renderBidDraftFile = file => {
    const { readOnly } = this.props
    return (
      <FileRow
        key={file.id}
        file={file}
        onDelete={readOnly ? undefined : () => this.deleteBidDraftFile(file.id)}
        onPreview={() => this.openFilePreview(file.id)}
      />
    )
  }

  onWorkOrderFilesPicked = (newFiles, projectId) => {
    console.log('onFilesPicked', newFiles)
    const { workOrderId } = this.state
    saveFilesToWorkOrder(projectId, workOrderId, newFiles)
  }

  onProjectFilesPicked = (newFiles, projectId) => {
    console.log('onFilesPicked', newFiles)
    updateProjectFiles(newFiles, projectId)
  }

  onDeleteProjectFile = (fileId, projectId) => {
    this.confirmationModalRef.current.open({
      title: 'Are you sure you want to delete file from project?',
      renderButtons: () => (
        <Box direction='row' gap='small' justify='end'>
          <Button
            secondary
            color={colors.CORAL_TWO}
            label='Cancel'
            onClick={() => this.confirmationModalRef.current.close()}
          />
          <Button
            secondary
            color={colors.AQUA_MARINE}
            label='Accept'
            onClick={() => {
              removeProjectFile(fileId, projectId)
              this.confirmationModalRef.current.close()
            }}
          />
        </Box>
      )
    })
  }

  onBidFilesPicked = newFiles => {
    console.log('onBidFilesPicked', newFiles)
    const { workOrderId } = this.state
    const { dispatch } = this.props
    const curFiles = this.getDraftFiles() || {}
    const newDraftFiles = { ...curFiles }
    _.forEach(newFiles, f => _.set(newDraftFiles, f.id, f))
    dispatch(updateQuoteDraft(workOrderId, 'files', newDraftFiles))
  }

  deleteBidDraftFile = fileId => {
    console.log('deleteBidDraftFile', fileId)
    const { workOrderId } = this.state
    const { dispatch } = this.props
    const curFiles = this.getDraftFiles() || {}
    const newDraftFiles = { ...curFiles }
    _.unset(newDraftFiles, fileId)
    dispatch(updateQuoteDraft(workOrderId, 'files', newDraftFiles))
  }

  getDraftFiles = () => {
    const { user } = this.props
    const { workOrderId } = this.state
    const bidFiles = this.getBidFiles()
    console.log('bidFiles', bidFiles)
    return _.get(user, ['quoteDrafts', workOrderId, 'files'], bidFiles)
  }

  renderFiles = () => {
    const { isWorkOrderCreator, workOrderId, bidId, woTitle } = this.state
    const { readOnly, workOrders } = this.props
    const projectId = _.get(workOrders, [workOrderId, 'projectId'])
    const workOrderFiles = this.getWorkOrderFiles()
    const res = []
    if (isWorkOrderCreator) {
      const bidInviteData = []
      _.forEach(workOrderFiles, f => {
        bidInviteData.push(f)
      })
      const sortedDataBidInvite = _.sortBy(bidInviteData, f => toTimestamp(_.get(f, 'createdAt')))
      res.push(this.renderListHeader(`Files included in ${woTitle}`, { size: 'medium', color: colors.MEDIUM_GREY }))
      res.push(<List key='wo_files' pad='none' children={this.renderBidInviteFile} data={sortedDataBidInvite} />)
      if (!readOnly) {
        res.push(
          <Box key='wo_add_files' pad='small' margin={{ bottom: 'small' }} flex={{ shrink: 0 }}>
            <FilesPickerButton
              onComplete={newFiles => this.onWorkOrderFilesPicked(newFiles, projectId)}
              storagePath={`/projects/${projectId}/`}
            />
          </Box>
        )
      }

      const projectFiles = this.getProjectFiles()
      const projectData = []
      _.forEach(projectFiles, f => {
        if (!_.has(workOrderFiles, f.id)) projectData.push(f)
      })
      const sortedDataProject = _.sortBy(projectData, f => toTimestamp(_.get(f, 'createdAt')))
      res.push(this.renderListHeader('Project Files', { size: 'large', color: colors.BROWN_GREY_TWO }))
      res.push(
        <List
          key='project_files'
          pad='none'
          children={file => this.renderProjectFile(file, projectId)}
          data={sortedDataProject}
        />
      )
      if (!readOnly) {
        res.push(
          <Box key='project_add_files' pad='small' margin={{ bottom: 'small' }} flex={{ shrink: 0 }}>
            <FilesPickerButton
              onComplete={newFiles => this.onProjectFilesPicked(newFiles, projectId)}
              storagePath={`/projects/${projectId}/`}
            />
          </Box>
        )
      }
    } else {
      const workOrderFiles = this.getWorkOrderFiles()
      const sortedData = _.sortBy(workOrderFiles, f => toTimestamp(_.get(f, 'createdAt')))
      if (!_.isEmpty(sortedData)) {
        res.push(this.renderListHeader('Files'))
        res.push(<List key='wo_files' pad='none' children={this.renderJobOfferFile} data={sortedData} />)
      }

      res.push(this.renderListHeader('Bid Files'))
      const sortedBidData = _.sortBy(this.getDraftFiles(), f => toTimestamp(_.get(f, 'createdAt')))
      res.push(<List key='bid_files' pad='none' children={this.renderBidDraftFile} data={sortedBidData} />)
      if (!_.isNil(bidId) && !readOnly) {
        res.push(
          <Box key='bid_add_files' pad='small' margin={{ bottom: 'small' }} flex={{ shrink: 0 }}>
            <FilesPickerButton
              onComplete={this.onBidFilesPicked}
              storagePath={`/workOrders/${workOrderId}/bid/${bidId}`}
            />
          </Box>
        )
      }
    }
    return res
  }

  render () {
    const { open } = this.state

    if (open) {
      return (
        <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
          <Layer modal position='right' full='vertical' onEsc={this.close} onClickOutside={this.close}>
            <Box
              fill='vertical'
              width='400px'
              border='start'
              customStyle={`box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)`}
            >
              {this.renderHeader()}
              <Box margin={{ top: 'medium' }} overflow='auto'>
                {this.renderFiles()}
              </Box>
            </Box>
          </Layer>
          <ConfirmationModal ref={this.confirmationModalRef} />
        </ThemeContext.Extend>
      )
    } else {
      return null
    }
  }
}

FilesPanel.defaultProps = {
  readOnly: false
}

FilesPanel.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string
}

const mapStateToProps = (state, props) => ({
  workOrdersWithTitles: getWorkOrdersWithTitlesDict(state, props),
  workOrders: state.workOrders,
  projects: state.projects,
  myBids: getMyBidsByWorkOrders(state),
  bidsByWorkOrders: getBidsByWorkOrders(state),
  account: state.account,
  accountsProfiles: state.accountsProfiles,
  user: state.user
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(FilesPanel)
