import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'
import { getUnseenAmount } from 'model/selectors/inbox'
import LayoutContext from 'webPages/layout/LayoutContext'

const CommentsInboxButton = ({ unseenAmount }) => {
  const { openCommentsInbox } = useContext(LayoutContext)
  let tip = 'Notifications'
  if (unseenAmount > 0) {
    tip = unseenAmount === 1 ? '1 new notification' : `${unseenAmount} new notifications`
  }

  return (
    <Button
      plain
      icon={<FontAwesomeIcon icon={unseenAmount > 0 ? faBellSolid : faBell} size={20} />}
      color={unseenAmount > 0 ? colors.AQUA_MARINE : colors.LIGHT_NAVY_BRIGHT}
      onClick={openCommentsInbox}
      tip={tip}
    />
  )
}

const mapStateToProps = state => ({
  unseenAmount: getUnseenAmount(state)
})

export default connect(mapStateToProps)(CommentsInboxButton)
