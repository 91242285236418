import React, { useEffect, useContext, useCallback, useState, useMemo, useRef } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'

import NavBarContainer from 'components/web/NavBarContainer'
import ShareViewNavBar from 'webPages/shareView/ShareViewNavBar'
import LayoutContext from 'webPages/layout/LayoutContext'
import colors from 'shared/constants/colors'
import PanelSelectedBids from 'webPages/shareView/ReportsPanel'
import ReportView from 'webPages/shareView/ReportView'
import { openReportPdf, shareReport, updateAllReportSettings } from 'controllers/reports'
import config from 'shared/config'
import { type2code } from 'shared/constants/reportType'
import { getName } from 'shared/utils/stringUtils'
import { getEmails, getPhones } from 'shared/utils/profile'
import Toast from 'components/Toast'

const ShareView = ({ projectId, project, reportType, profiles, currentReportSettings }) => {
  const [reportSettings, setReportSettings] = useState(null)
  const toastRef = useRef(null)
  const { toggleSideMenu } = useContext(LayoutContext)
  const [sendTo, setSendTo] = useState({})
  const [contacts, setContacts] = useState({})
  const [shareButtonLoading, setShareButtonLoading] = useState(false)
  const [updatingReport, setUpdatingReport] = useState(false)

  const settingsChanged = useMemo(() => {
    return !_.isEqual(reportSettings, currentReportSettings)
  }, [reportSettings, currentReportSettings])

  const idsToSend = useMemo(() => {
    return _.filter(sendTo, (v, k) => v)
  }, [sendTo])

  const address = _.get(project, 'address.name')

  useEffect(() => {
    toggleSideMenu(false)
  }, [toggleSideMenu])

  useEffect(() => {
    setReportSettings(null)
  }, [reportType])

  useEffect(() => {
    if (_.isNil(reportSettings)) {
      setReportSettings(currentReportSettings)
    }
  }, [reportSettings, currentReportSettings])

  const updateReportState = v => {
    if (v) {
      toastRef.current.close()
      toastRef.current.open(
        <Box
          elevation='small'
          pad='small'
          width='small'
          gap='small'
          background={colors.PALE_GREY}
          margin='small'
          round='small'
        >
          <Text>Saving report...</Text>
        </Box>, false)
      setUpdatingReport(true)
    } else {
      toastRef.current.close()
      toastRef.current.open(
        <Box
          elevation='small'
          pad='small'
          width='small'
          gap='small'
          background={colors.AQUA_MARINE10}
          margin='small'
          round='small'
        >
          <Text>Report saved!</Text>
        </Box>)
      setUpdatingReport(false)
    }
  }

  const updateSettings = useCallback(async () => {
    const hasChanges = !_.isEqual(currentReportSettings, reportSettings)
    if (!hasChanges) return null
    updateReportState(true)
    await updateAllReportSettings(projectId, reportType, reportSettings)
    updateReportState(false)
  }, [projectId, reportSettings, reportType, currentReportSettings])

  const onDownloadClick = useCallback(async () => {
    await updateSettings()
    openReportPdf(projectId, reportType, address)
  }, [reportType, projectId, updateSettings, address])

  const onCopyUrlClick = useCallback(async () => {
    await updateSettings()
    const url = `${config.reportUrl}/${projectId}/${type2code(reportType)}`
    navigator.clipboard.writeText(url)
    toastRef.current.close()
    toastRef.current.open(
      <Box
        elevation='small'
        pad='small'
        width='small'
        gap='small'
        background={colors.AQUA_MARINE10}
        margin='small'
        round='small'
      >
        <Text>Link copied</Text>
      </Box>)
  }, [projectId, reportType, updateSettings])

  const onShareClick = async () => {
    await updateSettings()
    setShareButtonLoading(true)
    const data = _.reduce(
      sendTo,
      (res, v, k) => {
        if (v) {
          const p = _.get(profiles, k, _.get(contacts, k))
          res.push({
            id: p.id,
            name: getName(p),
            emails: getEmails(p),
            phones: getPhones(p)
          })
        }
        return res
      },
      []
    )
    await shareReport(projectId, reportType, data)
    setShareButtonLoading(false)
    setSendTo({})
  }

  const updateReportSetting = (key, value) => {
    if (_.isUndefined(value)) {
      const res = { ...reportSettings }
      _.unset(res, key)
      setReportSettings(res)
    } else {
      const res = { ...reportSettings }
      _.set(res, key, value)
      setReportSettings(res)
    }
  }

  const onSaveReportClick = async () => {
    await updateSettings()
  }

  return (
    <Box fill>
      <NavBarContainer>
        <ShareViewNavBar
          fill='horizontal'
          pad={{ vertical: 'small', horizontal: 'medium' }}
          border={false}
          onDownloadClick={onDownloadClick}
          onCopyUrlClick={onCopyUrlClick}
          onShareClick={onShareClick}
          onSaveReportClick={onSaveReportClick}
          shareButtonDisabled={_.isEmpty(idsToSend)}
          shareButtonLoading={shareButtonLoading}
          settingsChanged={settingsChanged}
          updatingReport={updatingReport}
        >
          <Text weight={600} size='large'>
            {address}
          </Text>
        </ShareViewNavBar>
      </NavBarContainer>
      <Box flex direction='row'>
        <Box background={colors.PALE_GREY} fill align='center' pad={{ horizontal: 'small' }} overflow={'auto'}>
          <ReportView projectId={projectId} type={reportType} reportSettings={reportSettings} />
        </Box>
        <PanelSelectedBids
          address={address}
          reportType={reportType}
          projectId={projectId}
          sendTo={sendTo}
          setSendTo={setSendTo}
          contacts={contacts}
          setContacts={setContacts}
          reportSettings={reportSettings}
          updateReportSetting={updateReportSetting}
        />
        <Toast ref={toastRef} />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state, props) => ({
  project: _.get(state.projects, props.projectId),
  profiles: state.profiles,
  currentReportSettings: _.get(state, ['projects', props.projectId, 'reports', props.reportType, 'settings'], {})
})

export default connect(mapStateToProps)(ShareView)
