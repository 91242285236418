import React, { useCallback, useMemo } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import SharePanelContainer from 'webPages/shareView/SharePanelContainer'
import ReportTypeRow from 'webPages/shareView/components/ReportTypeRow'
import BidsToInclude from 'webPages/shareView/components/BidsToInclude'
import ShareReportWith from 'webPages/shareView/components/ShareReportWith'
import rt from 'shared/constants/reportType'
import SelectRow from 'webPages/shareView/components/SelectRow'
import reportCostPlusOrMarkup from 'shared/constants/reportCostPlusOrMarkup'
import DiscountInput from 'components/createBid/DiscountInput.web'
import amountType from 'shared/constants/amountType'
import colors from 'shared/constants/colors'
import ToggleRow from 'webPages/shareView/components/ToggleRow'
import reportSetting from 'shared/constants/reportSetting'

const ReportsPanel = ({ projectId, address, reportType, reportSettings, sendTo, setSendTo, contacts, setContacts, updateReportSetting }) => {
  const costPlusOrMarkupSelect = useMemo(() => {
    const v = reportSetting.getValue(reportSettings, reportSetting.COST_PLUS_OR_MARKUP)
    const onOptionClick = optionId => {
      updateReportSetting(reportSetting.COST_PLUS_OR_MARKUP, optionId)
    }
    return (
      <SelectRow
        key='cporm'
        title='Cost Plus or Markup'
        selectedId={v}
        options={[
          { id: reportCostPlusOrMarkup.NONE, label: 'none' },
          { id: reportCostPlusOrMarkup.COST_PLUS, label: 'Cost Plus' },
          { id: reportCostPlusOrMarkup.MARKUP, label: 'Markup' }
        ]}
        onOptionClick={onOptionClick}
      />
    )
  }, [reportSettings, updateReportSetting])

  const costPlusOrMarkupAmount = useMemo(() => {
    const cpom = reportSetting.getValue(reportSettings, reportSetting.COST_PLUS_OR_MARKUP)
    if (cpom !== reportCostPlusOrMarkup.NONE) {
      const cpomAmount = _.get(reportSettings, reportSetting.COST_PLUS_OR_MARKUP_AMOUNT)
      const type = _.get(cpomAmount, 'type', amountType.FLAT_AMOUNT)
      const value = _.get(cpomAmount, 'value')

      const setType = t => {
        console.log('setType', t)
        updateReportSetting(reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, { type: t, value: null })
      }

      const setValue = v => {
        let value = v
        if (type === amountType.FLAT_AMOUNT) {
          value = _.max([0, value])
        } else {
          value = _.max([0, value])
          value = _.min([100, value])
        }
        value = value === 0 ? null : value
        updateReportSetting(reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, { type, value })
      }

      const label = cpom === reportCostPlusOrMarkup.COST_PLUS ? 'Cost Plus Amount' : 'Markup Amount'
      return (
        <Box key='cpom_amount' direction='row' align='center' justify='between' flex={{ shrink: 0 }}>
          <Text>{label}</Text>
          <DiscountInput
            title=''
            discountType={type}
            discountValue={value}
            setDiscountType={setType}
            setDiscountValue={setValue}
            boxProps={{ border: undefined, gap: undefined, pad: undefined, width: '175px', flex: { shrink: 0 } }}
            inputBoxProps={{
              customStyle: `color: ${colors.TEXT_PRIMARY};`
              // width: '100%'
              // height: '36px'
            }}
            inputProps={{
              size: 'medium',
              customStyle: 'padding-right: 6px; font-weight: 400;',
              placeholder: '-'
            }}
            titleProps={{ color: colors.TEXT }}
          />
        </Box>
      )
    } else {
      return null
    }
  }, [reportSettings, updateReportSetting])

  const renderToggle = useCallback(
    (settingName, title) => {
      const v = reportSetting.getValue(reportSettings, settingName)
      const onChange = v => {
        updateReportSetting(settingName, v)
      }
      return <ToggleRow key={settingName} title={title} checked={v} onChange={onChange} />
    },
    [reportSettings, updateReportSetting]
  )

  const reportMainSettings = () => {
    switch (reportType) {
      case rt.SELECTED_BIDS:
        return [
          costPlusOrMarkupSelect,
          costPlusOrMarkupAmount,
          renderToggle(reportSetting.SHOW_SUMMARY, 'Show project summary table'),
          renderToggle(reportSetting.SHOW_NUMBER_OF_BIDS, 'Show number of bids'),
          renderToggle(reportSetting.SHOW_NUMBER_OF_INVITED, 'Show number of invited'),
          renderToggle(reportSetting.SHOW_BIDS_TOTALS, 'Show pricing'),
          renderToggle(reportSetting.SHOW_TRADES_WITH_NO_BIDS, 'Show trades with no bids'),
          renderToggle(reportSetting.SHOW_SUB_NAME, 'Show selected contractor name'),
          renderToggle(reportSetting.SHOW_SECTION_SUBTOTALS, 'Show section subtotals'),
          renderToggle(reportSetting.SHOW_COMPANY_LOGO, 'Show company logo')
        ]
      case rt.SCOPES_ONLY:
        return [
          renderToggle(reportSetting.SHOW_SUMMARY, 'Show project summary table'),
          renderToggle(reportSetting.SHOW_COMPANY_LOGO, 'Show company logo')
        ]
      default:
        return []
    }
  }

  return (
    <SharePanelContainer address={address}>
      <Box pad={{ top: 'large', horizontal: 'medium', bottom: 'medium' }} flex={{ shrink: 0 }} gap='xsmall'>
        <ReportTypeRow currentType={reportType} projectId={projectId} />
        {_.compact(reportMainSettings())}
      </Box>
      <ShareReportWith
        projectId={projectId}
        reportType={reportType}
        sendTo={sendTo}
        setSendTo={setSendTo}
        contacts={contacts}
        setContacts={setContacts}
      />
      <BidsToInclude projectId={projectId} reportType={reportType} reportSettings={reportSettings} updateReportSetting={updateReportSetting} />
    </SharePanelContainer>
  )
}

export default ReportsPanel
