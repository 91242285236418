import { createSelector } from 'reselect'
import _ from 'lodash'

import {
  getAccountId,
  getAccountsProfiles,
  getProfiles,
  getProjectId,
  getUserId,
  getWorkOrders,
  getContractorTypes,
  sortByTimeAndSeen
} from 'model/selectors/base'
import requestType from 'shared/constants/requestType'
import { getMyBidsByWorkOrders } from 'model/selectors/bids'
import jobOfferStatus from 'shared/constants/jobOfferStatus'
import { getName } from 'shared/utils/stringUtils'
import { getJobOfferStatus } from 'utils/jobOffer'
import { toTimestamp } from 'shared/utils/date'
import { getAllProjectsInfo } from 'model/selectors/projectsInfoSelector'

export const getPendingJobOffers = createSelector(
  [
    getAccountId,
    getWorkOrders,
    getUserId,
    getMyBidsByWorkOrders,
    getAccountsProfiles,
    getProfiles,
    getContractorTypes,
    getAllProjectsInfo
  ],
  (accountId, workOrders, userId, myBidsByWorkOrder, accountsProfiles, profiles, contractorTypes, ownProjects) => {
    // console.log('own projects', ownProjects)
    const res = {
      all: [],
      byProject: {},
      byProjectPendingAmount: {},
      byWorkOrder: {},
      byProjectAccountId: {},
      pending: []
    }
    _.forEach(workOrders, (wo, woId) => {
      const woAccountId = _.get(wo, 'accountId')
      const projectId = _.get(wo, 'projectId')
      const hasAccess = _.has(ownProjects, projectId)
      if (woAccountId !== accountId && hasAccess) {
        // incoming
        const myBid = _.get(myBidsByWorkOrder, woId)
        const { status, seen } = getJobOfferStatus(wo, myBid, accountId, userId)
        // console.log('jojbOfferStatus', wo.id, 'status', status, 'seen', seen)
        const timestamp = toTimestamp(_.get(wo, 'createdAt', 0))
        const profile = _.get(accountsProfiles, wo.accountId, _.get(profiles, wo.accountId, _.get(wo, 'gcProfile')))
        const avatar = _.get(profile, 'avatarSmall')
        const label = _.get(wo, 'label', _.get(contractorTypes, [wo.tradeId, 'name']))
        const isPending =
          !seen ||
          status === jobOfferStatus.NEW ||
          status === jobOfferStatus.PENDING ||
          status === jobOfferStatus.ACCEPTED_BY_SUB
        let invId = _.get(wo, ['invitations', accountId, 'sendTo', userId, 'invId'])
        const sendTo = _.get(wo, ['invitations', accountId, 'sendTo'])
        if (!invId && sendTo) {
          const firstMatch = _.keys(sendTo)[0]
          invId = _.get(wo, ['invitations', accountId, 'sendTo', firstMatch, 'invId'])
        }
        const r = {
          id: wo.id,
          key: `jobOffer_${wo.id}`,
          type: requestType.JOB_OFFER,
          address: _.get(wo, 'projectAddress.structured.main'),
          label,
          workOrderId: wo.id,
          timestamp: timestamp,
          invitedAt: toTimestamp(_.get(wo, ['invitations', accountId, 'timestamp'])),
          companyName: getName(profile),
          bidsDueDate: wo.bidsDueDate,
          timeZone: _.get(wo, 'projectAddress.timeZone'),
          status,
          avatar,
          total: _.get(myBid, 'total', null),
          bidId: _.get(myBid, 'id', null),
          seen,
          isHighlighted: !seen,
          needsAction: status === jobOfferStatus.NEW || status === jobOfferStatus.ACCEPTED_BY_SUB,
          isPending,
          projectId: wo.projectId,
          wo,
          archived: _.get(myBid, 'deleted', 0) > 0,
          updatedAt: _.max([_.get(myBid, 'updatedAt', 0), _.get(wo, 'updatedAt', 0)]),
          invId
        }

        _.set(res, ['byProject', wo.projectId, r.id], r)
        _.set(res, ['byWorkOrder', wo.id, r.id], r)
        _.set(res, ['byProjectAccountId', wo.projectId, wo.accountId, r.id], r)
        if (!r.seen || r.needsAction) {
          _.set(
            res,
            ['byProjectPendingAmount', wo.projectId],
            _.get(res, ['byProjectPendingAmount', wo.projectId], 0) + 1
          )
        }
        if (r.isPending) {
          res.pending.push(r)
        }
        res.all.push(r)
      }
    })
    res.all = _.sortBy(res.all, r => -r.invitedAt)
    return res
  }
)

export const getJobOffersPendingForProject = createSelector(
  [getPendingJobOffers, getProjectId],
  (jobOffers, projectId) => {
    return _.omitBy(_.get(jobOffers, ['byProject', projectId], {}), r => !r.isPending)
  }
)

export const getJobOffersByProject = createSelector([getPendingJobOffers, getProjectId], (jobOffers, projectId) => {
  const offers = _.get(jobOffers, ['byProject', projectId], {})
  return sortByTimeAndSeen(_.values(offers))
})

export const getJobOffersForProjectsToCreateBid = createSelector(
  [getPendingJobOffers, getProjectId],
  (jobOffers, projectId) => {
    return _.omitBy(_.get(jobOffers, ['byProject', projectId], {}), r => !r.needsAction)
  }
)
