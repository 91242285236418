import React from 'react'
const Ctx = React.createContext({
  archivedVisible: false,
  setArchivedVisible: () => null,
  values: false,
  compact: false,
  references: false,
  quantities: false,
  declined: false,
  sections: false,
  handleViewChange: () => null
})

export default Ctx
