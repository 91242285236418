import React from 'react'
import { Box } from 'grommet'

import StyledCheckbox from 'components/StyledCheckbox'

const LineItemRequired = ({ li, parentLi, onChange }) => {
  return (
    <Box align='center' justify='center' border={['bottom', 'right']}>
      <StyledCheckbox checked={li.required} onChange={onChange} />
    </Box>
  )
}

export default LineItemRequired
