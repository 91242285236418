import React from 'react'
import { Route } from 'navigation/rrouter'
import _ from 'lodash'
import qs from 'qs'

const PublicRoute = ({ component: Component, componentProps, ...rest }) => {
  if (!_.isNil(Component)) {
    return (
      <Route
        {...rest}
        render={props => (
          <Component
            {...componentProps}
            {...props}
            {..._.get(props, 'match.params', {})}
            {..._.get(props, 'history.location.state', {})}
            queryParams={qs.parse(_.get(props, 'history.location.search', {}), { ignoreQueryPrefix: true })}
          />
        )}
      />
    )
  } else {
    return <Route {...rest} />
  }
}

export default PublicRoute
