const COMMERCIAL = 'commercial'
const RESIDENTIAL = 'residential'

const stringOfType = v => {
  switch (v) {
    case COMMERCIAL:
      return 'Commercial'
    case RESIDENTIAL:
      return 'Residential'
  }
}

const list = [COMMERCIAL, RESIDENTIAL]

export default { COMMERCIAL, RESIDENTIAL, stringOfType, list }
