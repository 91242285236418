import React, { useContext } from 'react'
import { Box, List } from 'grommet'
import _ from 'lodash'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import FilesPickerButton from 'components/web/FilesPickerButton'
import FileRow from 'components/panels/filesPanel/FileRow'
import history from 'shared/utils/history'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const Attachments = () => {
  const { setField, getField, workOrderId, bidId } = useContext(CreateBidContext)

  const files = getField('files')
  const hasFiles = !_.isEmpty(files)

  const deleteFile = fileId => {
    const newDraftFiles = { ...files }
    _.unset(newDraftFiles, fileId)
    setField('files')(newDraftFiles)
  }

  const openFilePreview = fileId => {
    const curRoute = _.get(history, 'location.pathname')
    if (_.startsWith(curRoute, '/workorderfile')) {
      universalNavigation.navigate(screens.WORKORDER_FILE, { workOrderId, fileId })
    } else {
      universalNavigation.push(screens.WORKORDER_FILE, { workOrderId, fileId })
    }
  }

  const renderFile = file => {
    return (
      <FileRow
        key={file.id}
        file={file}
        onDelete={() => deleteFile(file.id)}
        onPreview={() => openFilePreview(file.id)}
      />
    )
  }

  const onFilesPicked = newFiles => {
    const newDraftFiles = { ...files }
    _.forEach(newFiles, f => _.set(newDraftFiles, f.id, f))
    setField('files')(newDraftFiles)
  }

  return (
    <Box pad={{ top: 'small' }} flex={{ shrink: 0 }}>
      {hasFiles && <List data={_.values(files)} children={renderFile} pad='none' />}
      <Box direction='row' pad={{ left: 'medium', top: 'xsmall' }} flex={{ shrink: 0 }}>
        <FilesPickerButton onComplete={onFilesPicked} storagePath={`/workOrders/${workOrderId}/bid/${bidId}`} />
      </Box>
    </Box>
  )
}

export default Attachments
