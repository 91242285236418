import React, { useState, useEffect, useContext } from 'react'
import { Box, ResponsiveContext } from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import colors from 'shared/constants/colors'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getTradesDict } from 'model/selectors/references'
import SubBiddingInterface from 'webPages/createBid/SubBiddingInterface'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { getMyBidsByWorkOrders } from 'model/selectors/bids'
import { getName } from 'shared/utils/stringUtils'
import { acceptJobOffer, removeAccountFromWorkOrder, cancelRemindersBidInviteMessages } from 'controllers/workOrder'
import Loading from 'shared/pages/Loading'
import ResolveInvite from 'webPages/invitation/ResolveInvite'
import { navigateToHomePage } from 'controllers/init'
import LayoutContext from 'webPages/layout/LayoutContext'
import { fetchMessagesWeb } from 'controllers/chat'
import CreateBidNavBar from 'webPages/createBid/CreateBidNavBar'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import CreateBidBottomMenu from 'webPages/createBid/CreateBidBottomMenu'
import PdfEditor from 'webPages/pdf/PdfEditor'
import { setFilesSeen } from 'controllers/user'
import FilePreview from 'components/FilePreview'

const CreateBid = ({
  workOrderId,
  workOrder,
  contractorTypes,
  bid,
  dispatch,
  accountsProfiles,
  user,
  account,
  profiles,
  isAccepted,
  workOrders,
  cellOpen,
  cellOpenTimestamp,
  projectId,
  ...rest
}) => {
  const mode = _.get(rest, 'queryParams.mode')
  const [errorComments, setError] = useState({})
  const size = useContext(ResponsiveContext)
  const files = _.get(workOrder, 'files', {})

  const { toggleSideMenu } = useContext(LayoutContext)
  const {
    getGCAccountProfile,
    getUserScopeOfWork,
    canSendBid,
    getField,
    setField,
    openCommentsPanel,
    setFields
  } = useContext(CreateBidContext)

  const accountId = _.get(account, 'id')
  const gcProfile = getGCAccountProfile()

  useEffect(() => {
    if (!_.isNil(workOrderId)) fetchMessagesWeb(workOrderId, accountId)
  }, [workOrderId, accountId])

  useEffect(() => toggleSideMenu(false), [])

  const scopeForTheSub = getUserScopeOfWork()

  const onNextClick = () => {
    console.log('onNextClick')
    universalNavigation.push(screens.SUB_CREATE_BID_PREVIEW, { workOrderId, projectId })
  }

  const changeTab = tab => {
    universalNavigation.push(screens.SUB_CREATE_BID, { workOrderId, projectId }, { mode: tab })
  }

  const goBack = () => {
    universalNavigation.navigate(screens.SUB_APP)
  }

  const renderContent = () => {
    if (mode === 'scope') {
      return (
        <SubBiddingInterface
          scope={scopeForTheSub}
          errorComments={errorComments}
          setError={setError}
          gcProfile={gcProfile}
          workOrderId={workOrderId}
          cellOpen={cellOpen}
          cellOpenTimestamp={cellOpenTimestamp}
          getField={getField}
          setField={setField}
          setFields={setFields}
          openCommentsPanel={openCommentsPanel}
          itemsDB={getField('items')}
          sectionsDB={getField('sections')}
          archived={_.get(workOrder, 'deleted') > 0}
        />
      )
    } else if (_.has(files, mode)) {
      const file = _.get(files, mode)
      const contentType = _.get(file, 'contentType')
      const userId = _.get(user, 'id')

      if (!_.has(workOrder, ['invitations', accountId, 'filesSeen', mode, userId])) {
        dispatch(setFilesSeen(workOrderId, mode))
      }

      if (contentType === 'application/pdf') {
        return (
          <Box flex>
            <PdfEditor key={_.get(file, 'id')} file={file} workOrderId={workOrderId} />
          </Box>
        )
      }
      return (
        <Box flex>
          <FilePreview file={file} />
        </Box>
      )
    }
    return (
      <SubBiddingInterface
        scope={scopeForTheSub}
        errorComments={errorComments}
        setError={setError}
        gcProfile={gcProfile}
        workOrderId={workOrderId}
        cellOpen={cellOpen}
        cellOpenTimestamp={cellOpenTimestamp}
        getField={getField}
        setField={setField}
        setFields={setFields}
        openCommentsPanel={openCommentsPanel}
        itemsDB={getField('items')}
        sectionsDB={getField('sections')}
        archived={_.get(workOrder, 'deleted') > 0}
      />
    )
  }

  // console.log('scopeForTheSub', scopeForTheSub)
  const renderCreateBidInterface = () => {
    if (_.isNil(workOrderId)) {
      return (
        <Box flex={false} background={colors.WHITE} height='100%'>
          <CreateBidNavBar nextDisabled previewMode={false} />
        </Box>
      )
    } else {
      return (
        <Box flex={false} background={colors.WHITE} height='100%'>
          <CreateBidNavBar
            onNextClick={onNextClick}
            nextDisabled={!canSendBid()}
            workOrder={workOrder}
            previewMode={false}
            mode='create'
            activeTab={mode}
            files={files}
            changeTab={changeTab}
          />
          {renderContent()}
          <CreateBidBottomMenu mode={mode} goBack={() => changeTab('scope')} onNextClick={onNextClick} />
          {/* <CalendarSidepanel open={calendarOpen} setOpen={setCalendarOpen} type='sub' /> */}
        </Box>
      )
    }
  }

  const handleAccept = v => {
    console.log('handleAccept', v)
    console.log('canceling automation, token: ', `${_.get(workOrder, 'id')}_${accountId}`)
    cancelRemindersBidInviteMessages(`${_.get(workOrder, 'id')}_${accountId}`)
    if (v) {
      dispatch(acceptJobOffer(_.get(workOrder, 'id')))
    } else {
      dispatch(removeAccountFromWorkOrder(workOrder))
      dispatch(navigateToHomePage())
    }
  }

  const hasInAccepted = _.has(workOrder, ['acceptedBy', _.get(account, 'id')])
  const hasInDeclined = _.has(workOrder, ['declinedBy', _.get(account, 'id')])
  const isMobile = size === 'small'

  if (_.isNil(workOrders) || _.isEmpty(account)) {
    return <Loading />
  } else if ((hasInAccepted || _.isNil(workOrderId)) && !isMobile) {
    return renderCreateBidInterface()
  } else {
    const accountProfile = _.get(accountsProfiles, _.get(workOrder, 'accountId'))
    const invitedById = _.get(workOrder, ['invitations', accountId, 'invitedBy'])
    const creatorUserProfile = _.get(profiles, invitedById)
    const address = _.get(workOrder, 'projectAddress.name')
    const contractorTypeName = _.get(contractorTypes, [_.get(workOrder, 'tradeId'), 'name'])
    const tradeName = _.get(workOrder, 'label', contractorTypeName)
    const bidsDueDate = _.has(workOrder, 'bidsDueDate') ? moment(_.get(workOrder, 'bidsDueDate')).format('lll') : '-'
    const invId = _.get(workOrder, ['invitations', accountId, 'invId'])

    const files = _.values(_.get(workOrder, 'files'))
    return (
      <ResolveInvite
        handleAccept={handleAccept}
        onBack={goBack}
        userId={_.get(user, 'id')}
        address={address}
        files={files}
        gcName={getName(accountProfile)}
        invitedBy={getName(creatorUserProfile)}
        tradeName={tradeName}
        desc={_.get(workOrder, 'desc')}
        archived={_.get(workOrder, 'deleted', 0) > 0}
        accountId={accountId}
        workOrderId={workOrderId}
        scope={_.get(workOrder, 'scope')}
        sections={_.get(workOrder, 'sections')}
        projectId={projectId}
        mode={mode}
        bidsDue={bidsDueDate}
        hasInAccepted={hasInAccepted}
        hasInDeclined={hasInDeclined}
        id={invId}
      />
    )
  }
}

CreateBid.propTypes = {
  projectId: PropTypes.string,
  workOrderId: PropTypes.string,
  workOrder: PropTypes.object,
  contractorTypes: PropTypes.object,
  jobOffers: PropTypes.array,
  draft: PropTypes.object,
  bid: PropTypes.object
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrder(state, props),
  workOrders: state.workOrders,
  contractorTypes: getTradesDict(state),
  account: state.account,
  bid: _.get(getMyBidsByWorkOrders(state), props.workOrderId),
  accountsProfiles: state.accountsProfiles,
  user: state.user,
  profiles: state.profiles,
  bids: state.bids
})

export default connect(mapStateToProps)(CreateBid)
