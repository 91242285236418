import React from 'react'
import { Box, Button, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faStars } from '@fortawesome/pro-solid-svg-icons'
import { faFilePlus, faShoppingCart } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import HamburgerButton from 'webPages/layout/HamburgerButton'
import ButtonCard from 'components/web/ButtonCard'
import { appName } from 'constants/'

const ScopeCreationOptions = () => {
  return (
    <Box>
      <Box direction='row' flex={false} pad='small' fill='horizontal' border='bottom' justify='between' align='center'>
        <Box align='center'>
          <HamburgerButton />
          <Text>sdfsd</Text>
        </Box>
        <Box align='center' width='small'>
          <Button
            gap='xsmall'
            fill
            label='Upgrade'
            color={colors.AQUA_MARINE}
            icon={<FontAwesomeIcon icon={faStars} size={12} color={colors.WHITE} />}
            primary
          />
        </Box>
      </Box>
      <Box fill align='center' margin={{ top: 'xlarge' }} gap='large'>
        <Box>
          <Text color={colors.LIGHT_NAVY_BRIGHT} size='xxlarge' weight={700}>
            {`Welcome to ${appName}`}
          </Text>
        </Box>
        <Box direction='row' gap='large'>
          <ButtonCard
            icon={<FontAwesomeIcon icon={faFilePlus} size={48} color={colors.LIGHT_NAVY_BRIGHT} />}
            title='Start new project'
            description='Add line items to your bid invite for subcontractors to bid.'
            onClick={() => console.log('on start new project click')}
          />
          {/*   <ButtonCard
            icon={<FontAwesomeIcon icon={faShoppingCart} size={48} color={colors.LIGHT_NAVY_BRIGHT} />}
            title='Order takeoffs & scope'
            description={`Place an order and ${appName} will create your takeoffs and initial scope. For as little as $250.`}
            onClick={() => console.log('on Order takeoffs click')}
          /> */}
        </Box>
      </Box>
    </Box>
  )
}

export default ScopeCreationOptions
