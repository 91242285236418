import React from 'react'
import PropTypes from 'prop-types'
import { Collapsible, Box, Button } from 'grommet'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faMinus } from '@fortawesome/pro-regular-svg-icons'
import colors from 'shared/constants/colors'

const LevelingBottomPanel = ({
  isOpen,
  unselectAll,
  approveAllForAll,
  declineAllLineItems,
  openMergingModal,
  canMerge
}) => {
  return (
    <Collapsible direction='vertical' open={isOpen}>
      <Box
        height='65px'
        width='100%'
        direction='row'
        align='center'
        background={colors.VERY_LIGHT_PINK_TWO}
        pad={{ left: 'medium' }}
        gap='medium'
      >
        <Button
          primary
          icon={<FontAwesomeIcon icon={faMinus} color={colors.WHITE} size={14} />}
          color={colors.AQUA_MARINE}
          onClick={unselectAll}
          customStyle={`
              border-radius: 24px;
              width: 24px;
              height: 24px;
              padding: 0px;
              svg { margin: auto; }
              svg path {fill: #FFF; margin: auto; }
          `}
        />
        <Button primary label='Add to Scope Template' color={colors.AQUA_MARINE} onClick={approveAllForAll} />
        <Button
          primary
          label='Merge with existing line item'
          color={colors.LIGHT_NAVY_BRIGHT}
          disabled={!canMerge}
          onClick={openMergingModal}
        />
        <Box border={{ side: 'left', color: colors.VERY_LIGHT_PINK }} pad={{ left: 'medium' }}>
          <Button
            secondary
            color={colors.CORAL_TWO}
            label='Decline line item'
            onClick={declineAllLineItems}
            customStyle='padding: 6px 24px;'
          />
        </Box>
      </Box>
    </Collapsible>
  )
}

LevelingBottomPanel.propTypes = {
  isOpen: PropTypes.bool,
  workOrderId: PropTypes.string,
  unselectAll: PropTypes.func,
  approveAllForAll: PropTypes.func,
  declineAllLineItems: PropTypes.func,
  openMergingModal: PropTypes.func,
  canMerge: PropTypes.bool
}

export default LevelingBottomPanel
