import React, { useContext } from 'react'
import { Box, List, Text, Button } from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircle, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import colors from 'shared/constants/colors'
import { getUsersProfilesByAccountId } from 'model/selectors/profiles'
import { getName } from 'shared/utils/stringUtils'
import { formatNational } from 'shared/utils/phone'

const SendTo = ({ usersProfilesByAccountId }) => {
  const { getField, setField, gcAccountId, getGCAccountProfile, openAddUserModal } = useContext(CreateBidContext)

  const gcUsersProfiles = _.get(usersProfilesByAccountId, gcAccountId)
  const data = getField('sendTo')

  let error
  if (_.isEmpty(data)) {
    error = 'Add at least one recipient'
  } else {
    if (_.isEmpty(_.filter(data, info => info.checked))) error = 'Select at least one recipient'
  }

  const toggleUserId = userId => e => {
    console.log('toggleUserId', userId, e)
    e.stopPropagation()
    const newSendTo = { ...data }
    const curValue = _.get(newSendTo, [userId, 'checked'])
    _.set(newSendTo, [userId, 'checked'], !curValue)
    setField('sendTo')(newSendTo)
  }

  const renderRadio = (userId, checked) => {
    const size = 24
    const faIcon = checked ? faCheckCircle : faCircle
    return (
      <Box onClick={toggleUserId(userId)} flex={{ shrink: 0 }}>
        <FontAwesomeIcon icon={faIcon} size={size} color={colors.AQUA_MARINE} />
      </Box>
    )
  }

  const renderProfileRow = ({ profile, checked = false }) => {
    const emails = _.get(profile, 'emails', [])
    const phones = _.map(_.get(profile, 'phones', []), formatNational)
    const desc = [...emails, ...phones].join(',')
    return (
      <Box
        direction='row'
        justify='between'
        align='center'
        pad={{ vertical: 'xsmall', horizontal: 'medium' }}
        gap='medium'
        onClick={_.has(gcUsersProfiles, profile.id) ? undefined : () => openAddUserModal(profile)}
        hoverIndicator
      >
        <Box gap='xxsmall'>
          <Text>{getName(profile)}</Text>
          <Text size='small' color={colors.VERY_LIGHT_PINK} truncate customStyle='white-space: nowrap;'>
            {desc}
          </Text>
        </Box>
        {renderRadio(profile.id, checked)}
      </Box>
    )
  }

  const renderAddUserButton = () => {
    if (_.isEmpty(gcUsersProfiles)) {
      return (
        <Box direction='row' pad={{ horizontal: 'medium', top: 'small' }}>
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            label={`Add a contact to ${getName(getGCAccountProfile())}`}
            onClick={() => openAddUserModal()} // in other case it adds event to the  callback params
          />
        </Box>
      )
    }
  }

  return (
    <Box pad={{ top: 'medium' }} flex={{ shrink: 0 }}>
      <Box
        pad={{ left: 'medium', bottom: 'small', right: 'medium' }}
        flex={{ shrink: 0 }}
        direction='row'
        align='center'
        justify='between'
      >
        <Text color={_.isEmpty(error) ? colors.ASANA_GRAY_TEXT_HOVERED : colors.CORAL_TWO}>Send to</Text>
        {!_.isEmpty(error) && (
          <Text size='small' color={colors.CORAL_TWO}>
            <i>{error}</i>
          </Text>
        )}
      </Box>
      <List pad='none' children={renderProfileRow} data={_.values(data)} />
      {renderAddUserButton()}
    </Box>
  )
}

const mapStateToProps = state => ({
  usersProfilesByAccountId: getUsersProfilesByAccountId(state)
})

export default connect(mapStateToProps)(SendTo)
