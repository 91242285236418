import _ from 'lodash'
import { auth, db } from 'constants/firebase'
import Sentry from 'shared/utils/sentry'
import { trackEvent } from 'utils/analytics'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import { unsubscribeDeviceFromNotifications } from 'shared/controllers/notifications'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import authAction from 'shared/constants/authAction'
import { clearUserListener, clearListeners } from 'controllers/listeners'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import localStorage from 'controllers/localStorage'

export const setInvId = async id => {
  await localStorage.set('invId', id)
}

export const resetInvId = async id => {
  await localStorage.remove('invId')
}

export const setSubProposalId = async id => {
  await localStorage.set('subProposalId', id)
}

export const setPreconConfId = async id => {
  await localStorage.set('preconConfId', id)
}

export const resetPreconConfId = async id => {
  await localStorage.remove('preconConfId', id)
}

export const resetSubProposalId = async id => {
  await localStorage.remove('subProposalId')
}

export const setOpenPageOnAuth = async (screen, params) => {
  await localStorage.set('openPage', JSON.stringify({ screen, params }))
}

export const resetOpenPageOnAuth = async () => {
  await localStorage.remove('openPage')
}

export const getOpenPageOnAuth = async () => {
  try {
    const str = await localStorage.get('openPage')
    console.log('getOpenPageOnAuth', str)
    if (!_.isEmpty(str)) {
      const res = JSON.parse(str)
      console.log('getOpenPageOnAuth', res)
      return res
    } else {
      return null
    }
  } catch (e) {
    console.log(e)
    await resetOpenPageOnAuth()
    return null
  }
}

export async function signOut () {
  try {
    if (auth && auth.currentUser) {
      trackEvent('logout')
      // Intercom.logout()
      // NavigationService.navigate(screens.LOADING)
      clearUserListener()
      clearListeners()
      await unsubscribeDeviceFromNotifications(auth.currentUser.uid)
      Sentry.setUser()
      await auth.signOut()
    } else {
      universalNavigation.navigate(screens.AUTH)
    }
  } catch (e) {
    console.log('signOut error:', e.message)
    Sentry.captureException(e)
  }
}

export async function signUpWithEmailAndPassword (email, password, userName) {
  try {
    await auth.createUserWithEmailAndPassword(email, password)
    const user = auth.currentUser
    await user.updateProfile({ displayName: userName })
    return null
  } catch (e) {
    Sentry.captureException(e)
    return e
  }
}

export function signInWithEmailAndPassword (email, password, onError, onSuccess) {
  return async function (dispatch, getState) {
    try {
      await auth.signInWithEmailAndPassword(email, password)
      onSuccess()
    } catch (e) {
      Sentry.captureException(e)
      onError(e)
    }
  }
}

export const sendVerificationEmail = async () => {
  try {
    console.log('send verification email')
    const authToken = await auth.currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/generateEmailVerificationLink`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ authToken })
    })
    const jsonResponse = await response.json()
    console.log('sendVerificationEmail jsonResponse', jsonResponse)
    return jsonResponse
  } catch (e) {
    console.log('onVerifyEmailPress error', e)
    return { success: false, error: e.message }
  }
}

export function sendPasswordResetEmail (email, onSuccess, onError) {
  return async function (dispatch, getState) {
    try {
      await auth.sendPasswordResetEmail(email)
      onSuccess()
    } catch (e) {
      Sentry.captureException(e)
      console.log(e)
      onError(e)
    }
  }
}

export function sendInvite (phone, role = 'employee', name = null, title = null, email = null, message = null) {
  return async function (dispatch, getState) {
    try {
      const lookBy = {
        label: 'email',
        value: email
      }
      if (!_.isNil(phone)) {
        const phoneNumber = parsePhoneNumberFromString(phone, 'US')
        const phoneNum = phoneNumber.number
        _.set(lookBy, 'label', 'phone')
        _.set(lookBy, 'value', phoneNum)
      }
      const currentUser = auth.currentUser
      const state = getState()
      const accountId = _.get(state, 'user.currentAccountId')

      const invitationsSN = await db
        .collection('invitations')
        .where('accountId', '==', accountId)
        .where(lookBy.label, '==', lookBy.value)
        .get()
      const batch = db.batch()
      invitationsSN.docs.forEach(doc => {
        batch.delete(doc.ref)
      })
      await batch.commit()

      if (accountId) {
        const inviteId = db.collection('invitations').doc().id
        const invite = _.omitBy(
          {
            id: inviteId,
            accountId,
            role,
            name,
            phone: lookBy.label === 'phone' ? lookBy.value : null,
            email
          },
          _.isNil
        )
        await db
          .collection('invitations')
          .doc(inviteId)
          .set(invite)
        const authToken = await currentUser.getIdToken()
        const body = _.omitBy(
          {
            authToken,
            phone: lookBy.label === 'phone' ? lookBy.value : null,
            role,
            name,
            title,
            inviteId,
            email,
            message
          },
          _.isNil
        )
        const url = `${config.dynamicUrl}/proto/inviteMember`
        const response = await fetch(url, {
          method: 'post',
          headers: headers,
          body: JSON.stringify(body)
        })
        const answer = await response.json()
        console.log('sendInvite answer', answer)
      } else {
        console.log('current account is not set')
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log('sendInvite e:', e.message)
    }
  }
}

export const resendInvite = inviteId => async (dispatch, getState) => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      inviteId
    }
    const url = `${config.dynamicUrl}/proto/resendInviteMember`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('resendInviteMember answer', answer)
  } catch (e) {
    Sentry.captureException(e)
    console.log('resendInviteMember e:', e.message)
  }
}

export async function removeInvite (invId) {
  try {
    await db
      .collection('invitations')
      .doc(invId)
      .delete()
  } catch (error) {
    console.log('remove invite error: ', error.message)
  }
}

export async function sendRequest (url, searchParams) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      searchParams
    }
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    return await response.json()
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendInvite e:', e.message)
  }
}

export async function requestSmsCode (phone) {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  const phoneNum = phoneNumber.number
  try {
    const body = {
      phone: phoneNum
    }
    const url = `${config.dynamicUrl}/proto/requestSmsCode`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('requestSmsCode', answer)
    return _.get(answer, 'sent', false)
  } catch (e) {
    Sentry.captureException(e)
    console.log('requestSmsCode error:', e.message)
    return false
  }
}

export async function checkPhoneAgainstFirebaseAuth (phone) {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  const phoneNum = phoneNumber.number
  const body = { phone: phoneNum }
  const url = `${config.dynamicUrl}/proto/checkPhoneAgainstFirebaseAuth`
  const response = await window.fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  return answer
}

export const signIn = async authToken => {
  const authData = await auth.signInWithCustomToken(authToken)
  if (authData) {
    trackEvent('login')
  }
  return authData
}

export const requestAccess = async (subAccountId, projectId) => {
  const authToken = await auth.currentUser.getIdToken()
  const url = `${config.dynamicUrl}/proto/requestAccess`
  const body = { subAccountId, authToken, projectId }
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('answer', answer)
}

export async function checkVerificationCode (phone, code, email, name, teammateInvite = false, inviteId = null) {
  const invId = inviteId || (await localStorage.get('invId'))
  const subProposalId = await localStorage.get('subProposalId')
  const preconConfId = await localStorage.get('preconConfId')
  const body = {
    phone,
    code,
    invId,
    email,
    subProposalId,
    name,
    teammateInvite,
    preconConfId
  }
  console.log('checkVerificationCode', body)
  const url = `${config.dynamicUrl}/proto/checkVerificationCode`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('checkVerificationCode answer', answer)
  const authToken = _.get(answer, 'authToken')
  const params = _.get(answer, 'params')
  if (authToken) {
    const action = _.get(answer, 'params.action')
    let needToSignIn = true
    switch (action) {
      case authAction.SHOW_CREATE_BID: {
        universalNavigation.push(screens.SUB_CREATE_BID, {
          workOrderId: params.workOrderId,
          projectId: params.projectId
        })
        break
      }
      case authAction.SHOW_REQUEST_ACCESS: {
        console.log('SHOW_REQUEST_ACCESS')
        needToSignIn = false
        universalNavigation.navigate(screens.REQUEST_ACCESS, { ...params, authToken })
      }
    }
    if (needToSignIn) {
      await signIn(authToken)
    }
  }
  await resetInvId()
  await resetSubProposalId()
  await resetPreconConfId()
  return answer
}

export function addAccountPhone (id, phone) {
  return async function (dispatch, getState) {
    try {
      const accountRef = db.collection('accountsProfiles').doc(id)
      const accountSn = await accountRef.get()
      const account = accountSn.data()
      if (!_.has(account, 'phone')) {
        await db
          .collection('accountsProfiles')
          .doc(id)
          .update({
            phone,
            phones: [phone]
          })
      }
    } catch (error) {
      console.error('error checking account for phone #: ', error)
    }
  }
}

export const getInvitationInfo = async invId => {
  try {
    const sn = await db
      .collection('subInvitations')
      .doc(invId)
      .get()
    return sn.data()
  } catch (e) {
    Sentry.captureException(e)
    console.log('getInvitationInfo error:', e.message)
    return null
  }
}

export const getSubProposalInfo = async invId => {
  try {
    const sn = await db
      .collection('subProposalsInvitations')
      .doc(invId)
      .get()
    return sn.data()
  } catch (e) {
    Sentry.captureException(e)
    console.log('getSubProposalInfo error:', e.message)
    return null
  }
}

export const updateInvitationInfo = async (invId, params) => {
  try {
    await db
      .collection('authProxy')
      .doc(invId)
      .update(params)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateInvitationInfo error:', e.message)
  }
}

export function updateAdditionalInfo (userName, accountName, address) {
  return async function (dispatch, getState) {
    const state = getState()
    try {
      if (userName !== '') {
        const uid = _.get(state, 'user.id')
        db.collection('usersProfiles')
          .doc(uid)
          .update({ name: userName })
      }
      if (accountName !== '' || !_.isNil(address)) {
        const update = {}
        if (accountName !== '') {
          update.name = accountName
        }
        if (!_.isNil(address)) {
          update.address = { ...address }
        }
        const accountId = _.get(state, 'user.currentAccountId')
        db.collection('accountsProfiles')
          .doc(accountId)
          .update(update)
      }
    } catch (error) {
      console.log('updateAdditionalInfo error: ', error.message)
    }
  }
}

export const removeUserFromAccount = uid => {
  return async function (dispatch, getState) {
    try {
      const currentUser = auth.currentUser
      const state = getState()
      const accountId = _.get(state, 'user.currentAccountId')
      const authToken = await currentUser.getIdToken()
      const body = {
        authToken,
        uid,
        accountId
      }
      const url = `${config.dynamicUrl}/proto/removeUserFromAccount`
      const response = await fetch(url, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      const answer = await response.json()
      console.log('answer', answer)
    } catch (error) {
      console.log('removeUserFromAccount error,', error.message)
    }
  }
}
