import React from 'react'
import { Helmet } from 'react-helmet'
import { isGCHost } from 'constants/index'

const HtmlHead = () => {
  if (isGCHost) {
    return (
      <Helmet>
        <title>BidLevel</title>
        <link rel='apple-touch-icon' sizes='180x180' href='/iconsGC/apple-touch-icon.png' />
        <link id='favicon' rel='icon' href='/iconsGC/favicon.ico' type='image/x-icon' />
        <link id='icon32' rel='icon' type='image/png' sizes='32x32' href='/iconsGC/favicon-32x32.png' />
        <link id='icon16' rel='icon' type='image/png' sizes='16x16' href='/iconsGC/favicon-16x16.png' />
        <link rel='manifest' href='/iconsGC/site.webmanifest' />
        <link rel='mask-icon' href='/iconsGC/safari-pinned-tab.svg' color='#004069' />
        <meta name='msapplication-TileColor' content='#004069' />
        <meta name='msapplication-config' content='/iconsGC/browserconfig.xml' />
        <meta name='theme-color' content='#004069' />
      </Helmet>
    )
  } else {
    return (
      <Helmet>
        <title>Mason - Build more</title>
        <link rel='apple-touch-icon' sizes='180x180' href='/iconsSub/apple-touch-icon.png' />
        <link id='favicon' rel='icon' href='iconsSub/favicon.ico' type='image/x-icon' />
        <link id='icon32' rel='icon' type='image/png' sizes='32x32' href='/iconsSub/favicon-32x32.png' />
        <link id='icon16' rel='icon' type='image/png' sizes='16x16' href='/iconsSub/favicon-16x16.png' />
        <link rel='manifest' href='/iconsSub/site.webmanifest' />
        <link rel='mask-icon' href='/iconsSub/safari-pinned-tab.svg' color='#18446f' />
        <meta name='msapplication-TileColor' content='#18446f' />
        <meta name='msapplication-config' content='/iconsSub/browserconfig.xml' />
        <meta name='theme-color' content='#18446f' />
      </Helmet>
    )
  }
}

export default HtmlHead
