import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext, Box, Keyboard, TextArea, Tip } from 'grommet'
import { css } from 'styled-components'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import lineItemType from 'shared/constants/lineItemType'

const LineItemDescriptionCell = ({ li, scope, parentLi, updateItem, selectRow, unselectRow, focusNextElement, disabled }) => {
  const [value, setValue] = useState(null)
  const inputRef = useRef(null)
  const liType = _.get(li, 'type', lineItemType.DEFAULT)
  const isQuestion = liType === lineItemType.QUESTION
  const scopeItemNotQuestion = _.has(scope, li.id) && !isQuestion
  const isDisabled = scopeItemNotQuestion || disabled

  useEffect(() => {
    setValue(null)
  }, [li.desc])

  const fieldName = isQuestion ? 'answer' : 'desc'

  const onBlur = () => {
    inputRef.current.blur()
    unselectRow()
    if (!_.isNil(value)) {
      updateItem(li.id, { [fieldName]: value }, parentLi)
    }
  }

  const onChange = event => setValue(event.target.value)

  let curValue = _.get(li, fieldName)
  if (!_.isNil(value)) curValue = value

  const textInputThemeValue = {
    global: {
      control: { border: { color: 'transparent', radius: '0px' } }
    },
    textArea: {
      extend: css`
        padding-top: 2px;
        padding-bottom: 2px;
        height: 100%;
        line-height: 14px;
        border: 1px ${isDisabled ? 'transparent' : colors.LINK} solid;
        margin: 8px;
        border-radius: 4px;
        width: auto;
        :focus {
          background-color: ${colors.WHITE};
          border: 1px ${colors.AQUA_MARINE} solid;
          height: 100%;
        }
      `
    }
  }

  const onEnter = event => {
    if (event.shiftKey) {
      // setValue(value + '\n')
    } else {
      onBlur()
    }
  }

  const textInput = () => {
    if (!isQuestion && _.has(scope, li.id)) {
      return (
        <Tip content='Line item is part of the scope and read-only'>
          <Box fill pad='4px'>
            <TextArea
              value={curValue}
              onChange={onChange}
              onFocus={selectRow}
              onBlur={onBlur}
              placeholder={isQuestion ? 'Type your response...' : isDisabled ? '' : 'Notes'}
              ref={inputRef}
              disabled={isDisabled}
              size='small'
              resize={!isDisabled && 'vertical'}
              plain
              fill
              id={`${li.id}_description`}
            />
          </Box>
        </Tip>
      )
    }
    return (
      <Box fill>
        <TextArea
          value={curValue}
          onChange={onChange}
          onFocus={selectRow}
          onBlur={onBlur}
          placeholder={isQuestion ? 'Type your response...' : isDisabled ? '' : 'Notes'}
          ref={inputRef}
          disabled={isDisabled}
          size='small'
          resize={!isDisabled && 'vertical'}
          plain
          id={`${li.id}_description`}
        />
      </Box>
    )
  }

  return (
    <Box plain size='350px' border={['bottom', 'right']}>
      <ThemeContext.Extend value={textInputThemeValue}>
        <Keyboard onEnter={onEnter} onTab={e => focusNextElement('description', e)}>
          <Box fill>
            {textInput()}
          </Box>
        </Keyboard>
      </ThemeContext.Extend>
    </Box>
  )
}

LineItemDescriptionCell.propTypes = {
  scope: PropTypes.object,
  li: PropTypes.object,
  parentLi: PropTypes.object,
  updateItem: PropTypes.func,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func
}

export default LineItemDescriptionCell
