import React, { useState, useRef, useEffect, useImperativeHandle } from 'react'
import { Box, ThemeContext, Layer } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { updateSeenStatus } from 'controllers/chat'
import Comment from 'components/leveling/levelingPanel/Comment'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getName } from 'shared/utils/stringUtils'
import { getLevelingTableMessages } from 'model/selectors/channels'
import Header from 'webPages/comments/Header'
import GCHeader from 'webPages/comments/GCHeader'
import MessageInput from 'webPages/comments/MessageInput'

const Comments = ({
  items,
  scope,
  allMessages,
  userProfile,
  workOrderId,
  workOrder,
  accountsProfiles,
  account,
  dispatch,
  commentsRef,
  gcProfile
}) => {
  const messagesEndRef = useRef(null)
  const [id, setId] = useState()

  useImperativeHandle(commentsRef, () => ({
    open: channelId => setId(channelId),
    close: () => setId(null)
  }))

  const handleClose = () => setId(null)

  const accountId = _.get(account, 'id')
  const cellMessages = _.get(allMessages, ['cells', accountId, id], [])
  const gcMessages = _.get(allMessages, ['subs', accountId], [])
  const messages = id === accountId ? gcMessages : cellMessages
  const lastMessage = _.last(messages)
  const lastMessageId = _.get(_.last(messages), 'id')
  const channelKey = id === accountId ? `${workOrderId}_${accountId}` : `${workOrderId}_${accountId}_${id}`

  const scrollToBottom = () => {
    if (messagesEndRef.current) messagesEndRef.current.scrollIntoView(false)
  }

  useEffect(() => scrollToBottom())

  useEffect(() => {
    dispatch(updateSeenStatus(channelKey, lastMessage))
  }, [lastMessageId, channelKey, lastMessage, dispatch])

  const renderHeader = () => {
    const gcId = _.get(workOrder, 'accountId')
    const gcAccountProfile = gcProfile || _.get(accountsProfiles, gcId)
    if (id === accountId) {
      return <GCHeader name={getName(gcAccountProfile)} onClose={handleClose} />
    } else {
      return (
        <Header
          name={getName(gcAccountProfile)}
          items={items}
          id={id}
          scope={scope}
          setCommentId={setId}
          accountId={accountId}
        />
      )
    }
  }

  if (!id) {
    return null
  } else {
    const gcId = _.get(workOrder, 'accountId')
    return (
      <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
        <Layer position='right' full='vertical' onClickOutside={handleClose} onEsc={handleClose}>
          <Box
            width='400px'
            fill='vertical'
            border='left'
            customStyle='box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)'
          >
            <Box fill='vertical' height='100%' justify='between'>
              {renderHeader()}
              <Box overflow='auto' align='start' height='100%'>
                {_.map(messages, (msg, index) => {
                  return <Comment key={msg.id} msg={msg} index={index} prevMessage={_.get(messages, index - 1)} />
                })}
                <Box ref={messagesEndRef} />
              </Box>
              <MessageInput userProfile={userProfile} workOrderId={workOrderId} id={id} gcId={gcId} account={account} />
            </Box>
          </Box>
        </Layer>
      </ThemeContext.Extend>
    )
  }
}

Comments.propTypes = {
  gcProfile: PropTypes.object,
  items: PropTypes.object,
  scope: PropTypes.object,
  workOrderId: PropTypes.string,
  commentsRef: PropTypes.any
}

const mapStateToProps = (state, props) => ({
  allMessages: getLevelingTableMessages(state),
  account: state.account,
  userProfile: getCurrentUserProfile(state),
  profiles: state.profiles,
  workOrder: getWorkOrder(state, props),
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(Comments)
