import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveAppointments } from 'model/actions/appointmentsAC'
import { receiveAppointmentsOffers } from 'model/actions/appointmentsOffersAC'
import { db, auth, fieldValue } from 'constants/firebase'
// import appointmentStatuses from 'shared/constants/appointmentStatuses'
// import config from 'shared/config'
// import headers from 'shared/controllers/headers'

export const fetchAppointments = async accountId => {
  try {
    // const apptsSN = await db.collection('appointments').where('assignedAccountId', '==', accountId).get()
    // if (apptsSN.empty) store.dispatch(receiveAppointments({}))

    const unsubscribe = db
      .collection('appointments')
      .where('assignedAccountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchAppointments:', _.size(res))
          store.dispatch(receiveAppointments(res))
        },
        err => {
          console.log('Appointments onSnapshot error: ', err)
          Sentry.captureException(err)
        }
      )
    addListener('appointments', unsubscribe)
  } catch (e) {
    console.log('fetchAppointments error', e)
    Sentry.captureException(e)
  }
}

export const fetchAppointmentsOffers = async accountId => {
  try {
    // const pendingSN = await db.collection('appointmentsOffers').where('accountsPending', 'array-contains', accountId).get()
    // if (pendingSN.empty) store.dispatch(receiveAppointmentsOffers({}, true))
    const unsubscribePendingOffers = db
      .collection('appointmentsOffers')
      .where('accountsPending', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchAppointmentsOffers pending:', _.size(res))
          store.dispatch(receiveAppointmentsOffers(res, true))
        },
        err => {
          console.log('fetchAppointmentsOffers pending onSnapshot error: ', err)
          Sentry.captureException(err)
        }
      )
    addListener('appointmentsOffersPending pending', unsubscribePendingOffers)

    const missingSN = await db
      .collection('appointmentsOffers')
      .where('accountsMissing', 'array-contains', accountId)
      .get()
    if (missingSN.empty) store.dispatch(receiveAppointmentsOffers({}, false))
    const unsubscribeMissingOffers = db
      .collection('appointmentsOffers')
      .where('accountsMissing', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchAppointmentsOffers missing:', _.size(res))
          store.dispatch(receiveAppointmentsOffers(res, false))
        },
        err => {
          console.log('fetchAppointmentsOffers missing onSnapshot error: ', err)
          Sentry.captureException(err)
        }
      )
    addListener('appointmentsOffersMissing', unsubscribeMissingOffers)
  } catch (e) {
    console.log('fetchAppointments error missing', e)
    Sentry.captureException(e)
  }
}

export const declineAppointment = async appointmentId => {
  try {
    if (appointmentId) {
      await db
        .collection('appointments')
        .doc(appointmentId)
        .update({
          cancelledBy: fieldValue.arrayUnion(auth.currentUser.uid),
          assignedUserId: fieldValue.delete()
        })
    } else {
      console.warn('declineAppointment: No appointmentId passed')
    }
  } catch (e) {
    console.warn('declineAppointment error: ', e.message)
  }
}

export const declineAppointmentOffer = async appointmentId => {
  try {
    if (appointmentId) {
      await db
        .collection('appointmentsOffers')
        .doc(appointmentId)
        .update({
          declinedByUsers: fieldValue.arrayUnion(auth.currentUser.uid)
        })
    } else {
      console.warn('declineAppointmentOffer: No appointmentId passed')
      return null
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('declineAppointmentOffer error:', e)
    return null
  }
}

export const acceptAppointmentOffer = async (appointmentId, accountId) => {
  try {
    if (appointmentId) {
      await db
        .collection('appointmentsOffers')
        .doc(appointmentId)
        .update({
          assignedAccountId: accountId,
          assignedUserId: auth.currentUser.uid
        })
      // await db.collection('appointments').doc(appointmentId).update({
      //   assignedAccountId: accountId,
      //   assignedUserId: auth.currentUser.uid
      // })
      // await db.collection('appointmentsOffers').doc(appointmentId).delete()
    } else {
      console.warn('acceptAppointmentOffer: No appointmentId passed')
      return null
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('acceptAppointmentOffer error:', e)
    return null
  }
}

export const getPropertyData = (appointmentId, onDataReceived) => {
  return new Promise((resolve, reject) => {
    const unsubscrbe = db
      .collection('propertyData')
      .doc(appointmentId)
      .onSnapshot(
        sn => onDataReceived(sn.data()),
        err => reject(err)
      )
    resolve(unsubscrbe)
  })
}
