import React from 'react'
import { Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'

const LineItemDelete = ({ onClick, disabled, hideBorder }) => {
  const border = hideBorder ? undefined : 'bottom'
  if (disabled) {
    return <Box scope='col' border={border} align='center' />
  } else {
    return (
      <Box justify='center' border={border} align='center'>
        <Box onClick={onClick} id='delete_line_item_icon'>
          <FontAwesomeIcon icon={faTimesCircle} size={20} color='transparent' />
        </Box>
      </Box>
    )
  }
}

export default LineItemDelete
