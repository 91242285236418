import React from 'react'
import { Layer } from 'grommet'

export default class Toast extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  open = (content, withTimeout = true) => {
    this.setState({
      open: true,
      content
    }, () => {
      if (withTimeout) setTimeout(this.close, 5000)
    })
  }

  close = () => {
    this.setState({
      open: false,
      content: null
    })
  }

  render () {
    const { open, content } = this.state
    if (open) {
      return (
        <Layer
          position='bottom-right'
          plain
          modal={false}
          margin='small'
        >
          {content}
        </Layer>
      )
    }
    return null
  }
}
