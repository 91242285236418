import React, { useRef, useEffect, useMemo } from 'react'
import { Box, Text, Avatar } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import { getName, getInitials } from 'shared/utils/stringUtils'
import { fromNow } from 'shared/utils/date'
import colors from 'shared/constants/colors'
import { getInvitedUserProfile, getInvitedAccountProfile } from 'controllers/chat'

const Messages = ({ messages, acceptedBy, accountsProfiles, profiles, workOrder }) => {
  const messagesEndRef = useRef()

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const deliveredTo = useMemo(() => {
    const names = _.map(acceptedBy, accId => {
      if (_.has(accountsProfiles, accId)) return getName(_.get(accountsProfiles, accId))
      return _.get(getInvitedAccountProfile({ accountId: accId }, workOrder), 'name')
    })
    return _.join(names, ', ')
  }, [acceptedBy, accountsProfiles, workOrder])

  const renderMessage = m => {
    const profile = _.get(profiles, m.userId, getInvitedUserProfile(m, workOrder))
    return (
      <Box key={m.id} border={{ side: 'horizontal' }} flex={{ shrink: 0 }} pad='small'>
        <Box direction='row' gap='xsmall'>
          <Box flex={{ shrink: 0 }}>
            <Text size='xsmall' color={colors.LIGHT_NAVY_BRIGHT}>
              Comment delivered to
            </Text>
          </Box>
          <Text size='xsmall' color={colors.AQUA_MARINE} truncate>
            {deliveredTo}
          </Text>
        </Box>
        <Box direction='row' pad={{ top: 'small' }} gap='small'>
          <Avatar size='32px' src={_.get(profile, 'avatarSmall')} background={colors.LIGHT_NAVY_BRIGHT}>
            <Text color={colors.WHITE}>{getInitials(profile)}</Text>
          </Avatar>
          <Box direction='column' customStyle='flex: 1;' gap='xxsmall'>
            <Box direction='row' align='center' customStyle='flex: 1;' justify='between'>
              <Box direction='row' gap='xxsmall'>
                <Text size='small' weight={600} color={colors.LIGHT_NAVY_BRIGHT}>{`${getName(profile)}`}</Text>
                <Text size='small'>broadcasted</Text>
              </Box>
              <Text size='xsmall' color={colors.ANOTHER_GREY}>
                {fromNow(m.timestamp)}
              </Text>
            </Box>
            <Text size='small' color={colors.ANOTHER_GREY}>
              {m.text}
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <Box customStyle={'flex: 1; flex-direction: column-reverse'} overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
      <Box flex={{ grow: 1 }} justify='end' ref={messagesEndRef} />
      {_.map(messages, renderMessage)}
    </Box>
  )
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles,
  profiles: state.profiles
})

export default connect(mapStateToProps)(Messages)
