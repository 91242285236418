import React from 'react'
import PropTypes from 'prop-types'
import { RadioButton, Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const Radio = ({ checked, onChange, size }) => {
  console.log('render radio', checked)
  return (
    <RadioButton
      name='radio button'
      checked={checked}
      // onChange={onChange}
      children={({ checked }) => {
        if (checked) {
          return (
            <Box onClick={onChange}>
              <FontAwesomeIcon icon={faCheckCircle} size={size} color={colors.AQUA_MARINE} />
            </Box>
          )
        } else {
          return (
            <Box onClick={onChange}>
              <FontAwesomeIcon icon={faCircle} size={size} color={colors.LIGHT_NAVY_DARK} />
            </Box>
          )
        }
      }}
    />
  )
}

Radio.defaultProps = {
  size: 18
}

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number
}

export default Radio
