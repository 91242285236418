import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Text, Box, Collapsible } from 'grommet'
import generate from 'firebase-auto-ids'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'

import colors from 'shared/constants/colors'
import LineItemTableRow from 'components/createBid/LineItemTableRow'
import LineItemGeneralExclusion from 'components/createBid/LineItemGeneralExclusion'
import RemoveLineItemModal from 'components/createBid/RemoveLineItemModal'
import { getName } from 'shared/utils/stringUtils'
import { fieldValue } from 'constants/firebase'
import { getLevelingTableMessages } from 'model/selectors/channels'
import { toTimestamp } from 'shared/utils/date'
import AddLineItemFromTemplate from 'components/createBid/AddLineItemFromTemplate.web'
import SectionRow from 'components/createBid/SectionRow'
import lineItemSection from 'shared/constants/lineItemSection'
import AddItemButton from 'components/createBid/AddItemButton'
import { arrayMove } from 'shared/utils/array'
import CreateBidSubMenu from 'webPages/createBid/CreateBidSubMenu'
import { useStateWithCallbackLazy } from 'shared/utils/hooks'
import lineItemType from 'shared/constants/lineItemType'

const draggingCss = css`
  border: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`

const DraggableGridContainer = styled.div`
  display: grid;
  ${props => (props.isDragging ? draggingCss : '')}
  ${props => (props.dropRejected ? `.table_row {background-color: ${colors.CORAL_TWO10};}` : '')}
`
const GridRow = styled.div`
  display: grid;
  grid-template-columns: 26px 3.2fr 60px 60px 60px 60px 0.8fr 0.8fr 0.8fr 2fr 60px;
  ${props => props.customStyle}
`
const TableBodyContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  .selected_row {
    background-color: ${colors.AQUA_MARINE_LIGHT} !important;
  }
  .selected_cell {
    border-color: ${colors.AQUA_MARINE} !important;
  }
`
const SectionContainer = styled.div`
  ${props => (props.isDragging ? draggingCss : '')}
  ${props => props.customStyle}
`
const SectionItemsContainer = styled.div`
  min-height: 1px;
`

const SubBiddingInterface = ({
  scope,
  gcProfile,
  workOrderId,
  messages,
  cellOpen,
  cellOpenTimestamp,
  account,
  channel,
  userId,
  getField,
  setField,
  setFields,
  openCommentsPanel,
  itemsDB,
  sectionsDB,
  disabled
}) => {
  const removalRequests = getField('removalRequests')
  const [typeOfAddedLi, setTypeOfAddedLi] = useStateWithCallbackLazy(lineItemType.DEFAULT)
  const [focused, setFocus] = useState(null)
  const [collapsedRows, setCollapsedRows] = useState({})
  const [sections, setSections] = useState([])
  const removeLineItemModalRef = useRef()
  const addLineItemRef = useRef()
  const [geExpanded, setGeExpanded] = useState(false)
  const tableEndRef = useRef()

  const resetTypeOfAddedLi = () => setTypeOfAddedLi(lineItemType.DEFAULT)

  const addLineitem = useCallback(() => {
    const sowSectionIndex = _.findIndex(sections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
    const btnId = `add_li_button_${sowSectionIndex}`
    const elt = document.getElementById(btnId)
    if (elt) {
      elt.click()
    }
  }, [sections])

  const addGEItem = useCallback(() => {
    const sowSectionIndex = _.findIndex(sections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
    const btnId = `add_li_button_${sowSectionIndex}`
    const elt = document.getElementById(btnId)
    if (elt) {
      elt.click()
    }
  }, [sections])

  const onMenuAddQuestionClick = useCallback(
    id => {
      setTypeOfAddedLi(id, () => {
        const sowSectionIndex = _.findIndex(sections, s => s.id === lineItemSection.QUESTIONS_ID)
        const btnId = `add_li_button_${sowSectionIndex}`
        const elt = document.getElementById(btnId)
        if (elt) {
          elt.click()
        }
      })
    },
    [sections, setTypeOfAddedLi]
  )

  const saveItems = useCallback(
    v => {
      setField('items')(v)
    },
    [setField]
  )

  const getItem = useCallback(
    itemId => {
      const scopeItem = _.get(scope, itemId, {})
      const dbItem = _.get(itemsDB, itemId, {})
      return {
        ...scopeItem,
        ...dbItem,
        id: itemId
      }
    },
    [itemsDB, scope]
  )

  useEffect(() => {
    console.log('%cstart DB sections processing', 'color: lightblue', sectionsDB)
    if (_.isEqual(sectionsDB, sections)) {
      console.log('%cignore the sections update, they are the same', 'color: purple;')
      return
    }
    const itemsKeys = _.uniq([..._.keys(itemsDB), ..._.keys(scope)])
    // console.log('itemsKeys', itemsKeys)
    const scopeOfWorkSection = {
      id: lineItemSection.SCOPE_OF_WORK_ID,
      title: 'Scope of Work',
      items: []
    }

    const generalExclusionsSection = {
      id: lineItemSection.GENERAL_EXCLUSIONS_ID,
      title: 'General exclusions',
      items: []
    }

    if (_.isEmpty(sectionsDB)) {
      const data = [
        {
          ...scopeOfWorkSection,
          items: itemsKeys
        },
        generalExclusionsSection
      ]
      console.log('sections are empty data', data)
      setSections(data)
    } else {
      const newSections = _.values(sectionsDB)
      let sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      const geSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
      console.log('sowSectionIndex', sowSectionIndex, 'geSectionIndex', geSectionIndex)
      if (sowSectionIndex < 0 && geSectionIndex < 0) {
        newSections.push(scopeOfWorkSection)
        newSections.push(geSectionIndex)
      } else if (sowSectionIndex < 0) {
        const geSection = _.get(newSections, geSectionIndex)
        newSections.splice(geSectionIndex, 1)
        newSections.push(scopeOfWorkSection)
        newSections.push(geSection)
      } else if (geSectionIndex < 0) {
        const sowSection = _.get(newSections, sowSectionIndex)
        newSections.splice(sowSectionIndex, 1)
        newSections.push(sowSection)
        newSections.push(generalExclusionsSection)
      }
      const itemsIdsInSections = _.keyBy(_.flatten(_.map(newSections, s => _.get(s, 'items', []))))
      console.log('itemsIdsInsections', itemsIdsInSections)
      const notInSections = _.reduce(
        itemsKeys,
        (res, itemId) => {
          if (!_.has(itemsIdsInSections, itemId)) res.push(itemId)
          return res
        },
        []
      )

      console.log('notInSections', notInSections)
      // figuring out saw section index again, maybe it has been changed
      sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      const sowItems = _.get(newSections, [sowSectionIndex, 'items'])
      if (_.isNil(sowItems)) {
        _.set(newSections, [sowSectionIndex, 'items'], notInSections)
      } else {
        _.set(newSections, [sowSectionIndex, 'items'], [...sowItems, ...notInSections])
      }
      console.log('%c ->setNewSections', 'color: yellow;', newSections)
      const itemsKyesDict = _.keyBy(itemsKeys)
      const validatedSections = _.map(newSections, s => {
        return {
          ...s,
          items: _.compact(_.uniq(_.filter(s.items, itemId => _.has(itemsKyesDict, itemId))))
        }
      })
      console.log('%c ->validatedSections', 'color: green;', validatedSections)
      setSections(validatedSections)
    }
  }, [sectionsDB, itemsDB, scope])

  const saveSections = v => {
    setSections(v)
    setField('sections')(v)
  }

  useEffect(() => {
    if (!_.isNil(cellOpen)) {
      openCommentsPanel(cellOpen)
    }
  }, [openCommentsPanel, cellOpen, cellOpenTimestamp])

  useEffect(() => {
    if (focused) {
      const elt = document.getElementById(focused)
      if (elt) elt.focus()
      setFocus(null)
    }
  }, [focused])

  const toggleAlternates = useCallback(
    (id, expand = null) => {
      let isCollapced = _.get(collapsedRows, id, false)
      if (!_.isNil(expand)) isCollapced = expand
      if (isCollapced) {
        setCollapsedRows({ ...collapsedRows, [id]: false })
      } else {
        setCollapsedRows({ ...collapsedRows, [id]: true })
      }
    },
    [collapsedRows]
  )

  const toggleAlternatesOn = useCallback(
    id => {
      setCollapsedRows({ ...collapsedRows, [id]: false })
    },
    [collapsedRows]
  )

  const selectRow = useCallback(id => {
    // console.log('selectRow', id)
    const elt = document.getElementById(id)
    if (elt) elt.classList.add('selected_row')
  }, [])

  const unselectRow = useCallback(id => {
    // console.log('unselectRow', id)
    const elt = document.getElementById(id)
    if (elt) elt.classList.remove('selected_row')
  }, [])

  const getRowId = (li, parentLi) => {
    return _.isNil(parentLi) ? li.id : `${parentLi.id}-${li.id}`
  }

  const renderTableHeaderTitle = (t, props) => (
    <Text size='xsmall' color={colors.ANOTHER_GREY} {...props}>
      {t}
    </Text>
  )

  const renderTableHeader = () => {
    return (
      <GridRow customStyle={`position: sticky; top: 0; background-color: white; z-index: 3;`}>
        <Box />
        <Box pad={{ horizontal: 'medium', vertical: 'medium' }} border={['bottom', 'right']}>
          {renderTableHeaderTitle('Scope of Work', { margin: { left: 'small' } })}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Comments')}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Alternates')}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Excluded')}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Optional')}
        </Box>
        <Box justify='center' align='center' pad='none' size='150px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Quantity')}
        </Box>
        <Box justify='center' align='center' pad='none' size='120px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Unit Price')}
        </Box>
        <Box justify='center' align='center' pad='none' size='120px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Total')}
        </Box>
        <Box justify='center' align='center' pad='none' size='350px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Description')}
        </Box>
        <Box justify='center' align='center' pad='none' size='50px' border={['bottom']} />
      </GridRow>
    )
  }

  const generateId = () => generate(_.now())

  const updateItem = useCallback(
    (liId, params, parentLi) => {
      // console.log('updateItem', liId, params, parentLi)
      if (!_.isNil(parentLi)) {
        const parentItem = getItem(parentLi.id)
        const newParentItem = {
          ...parentItem,
          updatedAt: _.now(),
          updatedBy: userId,
          alternates: {
            ..._.get(parentItem, 'alternates', {}),
            [liId]: {
              ..._.get(parentItem, ['alternates', liId], {}),
              id: liId,
              ...params
            }
          }
        }
        saveItems({ ...itemsDB, [parentItem.id]: newParentItem })
      } else {
        const newItems = {
          ...itemsDB,
          [liId]: {
            ...getItem(liId),
            id: liId,
            updatedAt: _.now(),
            updatedBy: userId,
            ...params
          }
        }
        saveItems(newItems)
      }
    },
    [itemsDB, userId]
  )

  const addAlternate = useCallback(
    li => {
      const id = generateId()
      const alternates = {
        ..._.get(li, 'alternates', {}),
        [id]: {
          id
        }
      }
      updateItem(li.id, { alternates })
      toggleAlternatesOn(li.id)
    },
    [toggleAlternatesOn, updateItem]
  )

  const removeLineItem = useCallback(
    (liId, parentLi) => {
      console.log('remove line item', liId)
      // console.log('updateItem', liId, parentLi)
      const newItems = { ...itemsDB }
      // console.log('newItems before deletion', newItems)
      if (!_.isNil(parentLi)) {
        _.unset(newItems, [parentLi.id, 'alternates', liId])
      } else {
        _.unset(newItems, liId)
      }
      console.log('newItems after deletion', newItems)
      const newSections = _.map(sections, s => {
        return {
          ...s,
          items: _.filter(s.items, itemId => itemId !== liId)
        }
      })
      setFields({
        items: newItems,
        sections: newSections
      })
      console.log('newSections', newSections)
    },
    [itemsDB, sections]
  )

  const onRemoveAction = useCallback(
    (li, isRemoving) => {
      removeLineItemModalRef.current.open(
        li,
        isRemoving,
        getName(gcProfile),
        getField('total'),
        getField('unpricedAmount', 0)
      )
    },
    [gcProfile, getField]
  )

  const removeSection = sectionIndex => {
    console.log('removeSection', sectionIndex)
    const sectionToRemove = _.get(sections, sectionIndex)
    const sectionId = _.get(sectionToRemove, 'id')
    if (sectionId === lineItemSection.SCOPE_OF_WORK_ID || sectionId === lineItemSection.GENERAL_EXCLUSIONS_ID) {
      console.log('cancel removing, cannot remove standard section', sectionId)
    }
    const sectionItems = _.get(sectionToRemove, 'items')
    const newSections = [...sections]
    newSections.splice(sectionIndex, 1)
    if (!_.isEmpty(sectionItems)) {
      const sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      const sowItems = _.get(newSections, [sowSectionIndex, 'items'], [])
      const newSowItems = [...sowItems, ...sectionItems]
      _.set(newSections, [sowSectionIndex, 'items'], newSowItems)
    }
    console.log('sections after removing', newSections)
    saveSections(newSections)
  }

  const updateSection = (id, params) => {
    const newSections = _.map(sections, elt => {
      if (elt.id === id) {
        return { ...elt, ...params }
      } else {
        return elt
      }
    })
    saveSections(newSections)
  }

  const renderTableRow = (
    li,
    parentLi,
    tableRowProps,
    dragHandleProps,
    nextLiId,
    dropRejected,
    isDraggingOver,
    sectionId = lineItemSection.SCOPE_OF_WORK_ID
  ) => {
    const rowId = getRowId(li, parentLi)
    const cellMessages = _.get(messages, li.id)
    const messagesAmount = _.size(cellMessages)
    const lastMessage = _.last(cellMessages)
    const lastMessageUserId = _.get(lastMessage, 'userId')
    const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp'))
    const lastReadTime = toTimestamp(_.get(channel, `${workOrderId}_${_.get(account, 'id')}_${li.id}`))
    const expanded = !_.get(collapsedRows, li.id, false)
    // console.log('tableRowProps', tableRowProps)
    return (
      <LineItemTableRow
        isDraggingOver={isDraggingOver}
        tableRowProps={tableRowProps}
        dragHandleProps={dragHandleProps}
        key={rowId}
        rowId={rowId}
        scope={scope}
        li={li}
        parentLi={parentLi}
        addAlternate={addAlternate}
        toggleAlternates={toggleAlternates}
        updateItem={updateItem}
        selectRow={selectRow}
        unselectRow={unselectRow}
        removeLineItem={removeLineItem}
        expanded={expanded}
        parentExpanded={!_.get(collapsedRows, _.get(parentLi, 'id'), false)}
        removalRequest={_.get(removalRequests, li.id)}
        gcProfile={gcProfile}
        onRemoveAction={onRemoveAction}
        setCommentId={openCommentsPanel}
        messagesAmount={messagesAmount}
        nextLiId={nextLiId}
        hasNewMessage={lastReadTime < lastMessageTime && lastMessageUserId !== userId}
        items={itemsDB}
        disabled={disabled}
        dropRejected={dropRejected}
      />
    )
  }

  const renderGeneralExclusionLineItem = (li, nextLiId, index, isDraggingOver) => {
    return (
      <Draggable draggableId={li.id} index={index} key={li.id}>
        {(provided, snapshot) => {
          const tableRowProps = {
            ...provided.draggableProps,
            ref: provided.innerRef,
            isDragging: snapshot.isDragging
          }
          const dropRejected =
            !_.isNil(snapshot.draggingOver) && snapshot.draggingOver !== lineItemSection.GENERAL_EXCLUSIONS_ID
          // console.log('li.id', li.id, dropRejected)
          return (
            <LineItemGeneralExclusion
              isDraggingOver={isDraggingOver}
              tableRowProps={tableRowProps}
              dragHandleProps={provided.dragHandleProps}
              key={li.id}
              rowId={li.id}
              scope={scope}
              li={li}
              updateItem={updateItem}
              selectRow={selectRow}
              unselectRow={unselectRow}
              removeLineItem={removeLineItem}
              onRemoveAction={onRemoveAction}
              nextLiId={nextLiId}
              disabled={disabled}
              dropRejected={dropRejected}
            />
          )
        }}
      </Draggable>
    )
  }

  const renderLineItem = (li, nextLiId, index, isDraggingOver, sectionId) => {
    const expanded = !_.get(collapsedRows, li.id, false)
    const canDnD = sectionId !== lineItemSection.QUESTIONS_ID
    if (!_.isEmpty(li.alternates) && sectionId !== lineItemSection.QUESTIONS_ID) {
      return (
        <Draggable draggableId={li.id} index={index} key={li.id} isDragDisabled={!canDnD}>
          {(provided, snapshot) => {
            const tableRowProps = {
              ...provided.draggableProps,
              // ...provided.dragHandleProps,
              ref: provided.innerRef,
              isDragging: snapshot.isDragging
            }
            // console.log('snapshot.draggingOver', snapshot.draggingOver)
            const dropRejected = snapshot.draggingOver === lineItemSection.GENERAL_EXCLUSIONS_ID
            // console.log('li.id', li.id, dropRejected)
            return (
              <DraggableGridContainer {...tableRowProps} dropRejected={dropRejected}>
                {renderTableRow(li, null, {}, provided.dragHandleProps, nextLiId, false, isDraggingOver, sectionId)}
                <Collapsible open={expanded}>{_.map(li.alternates, alt => renderTableRow(alt, li))}</Collapsible>
              </DraggableGridContainer>
            )
          }}
        </Draggable>
      )
    } else {
      return (
        <Draggable draggableId={li.id} index={index} key={li.id} isDragDisabled={!canDnD}>
          {(provided, snapshot) => {
            // console.log('snapshot.draggingOver', snapshot.draggingOver)
            const tableRowProps = {
              ...provided.draggableProps,
              // ...provided.dragHandleProps,
              ref: provided.innerRef,
              isDragging: snapshot.isDragging
            }
            const dropRejected =
              snapshot.draggingOver === lineItemSection.GENERAL_EXCLUSIONS_ID ||
              snapshot.draggingOver === lineItemSection.QUESTIONS_ID
            return renderTableRow(
              li,
              null,
              tableRowProps,
              provided.dragHandleProps,
              nextLiId,
              dropRejected,
              isDraggingOver,
              sectionId
            )
          }}
        </Draggable>
      )
    }
    // return res
  }

  const createLineItem = (li, sectionIndex) => {
    console.log('%c createLineItem', 'color: red;', li, sectionIndex)
    const newItems = { ...itemsDB, [li.id]: li }
    const newSections = [...sections]
    const sectionItems = _.get(newSections, [sectionIndex, 'items'], [])
    _.set(newSections, [sectionIndex, 'items'], [...sectionItems, li.id])
    const newData = {
      sections: newSections,
      items: newItems
    }
    console.log('%c createLineItem: setFields', 'color: red;', newData)
    setFields(newData)
  }

  const renderAddItemButton = (sectionIndex, isGeneralExclusions, isDraggingOver, sectionId) => {
    return (
      <AddItemButton
        sectionIndex={sectionIndex}
        isGeneralExclusions={isGeneralExclusions}
        createLineItem={createLineItem}
        selectRow={selectRow}
        unselectRow={unselectRow}
        disabled={disabled}
        isDraggingOver={isDraggingOver}
        typeOfAddedLi={typeOfAddedLi}
        setTypeOfAddedLi={setTypeOfAddedLi}
        resetTypeOfAddedLi={resetTypeOfAddedLi}
        sectionId={sectionId}
      />
    )
  }

  const toggleGESection = () => {
    const needsScrollDown = !geExpanded
    setGeExpanded(!geExpanded)
    if (needsScrollDown) {
      setTimeout(() => {
        tableEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }

  const addNewSection = () => {
    const id = generate(_.now())
    const section = {
      title: '',
      id,
      items: []
    }
    console.log('sections now', sections)
    console.log('add new section', section)
    console.log('final to save', [section, ...sections])
    saveSections([section, ...sections])
  }

  const renderSectionLine = (section, sectionIndex, provided, isDragging, expanded, isDraggingOver) => {
    const sectionTotal = _.sumBy(_.map(_.get(section, 'items'), getItem), 'total')
    const sectionId = _.get(section, 'id', lineItemSection.SCOPE_OF_WORK_ID)
    return (
      <SectionRow
        key={section.id}
        li={section}
        index={sectionIndex}
        isDraggingOver={isDraggingOver}
        isDragging={isDragging}
        dragHandleProps={provided.dragHandleProps}
        removeSection={() => removeSection(sectionIndex)}
        updateSection={updateSection}
        expanded={expanded}
        toggle={toggleGESection}
        disabled={disabled}
        sectionTotal={sectionTotal}
        canAddItem={disabled}
        canRename={
          !_.includes(
            [lineItemSection.GENERAL_EXCLUSIONS_ID, lineItemSection.QUESTIONS_ID, lineItemSection.SCOPE_OF_WORK_ID],
            sectionId
          ) || disabled
        }
        canDelete={
          !_.includes(
            [lineItemSection.GENERAL_EXCLUSIONS_ID, lineItemSection.QUESTIONS_ID, lineItemSection.SCOPE_OF_WORK_ID],
            sectionId
          ) || disabled
        }
      />
    )
  }

  const getNextLiId = (sectionIndex, itemIndex) => {
    let nextId = _.get(sections, [sectionIndex, 'items', itemIndex + 1])
    if (_.isNil(nextId)) {
      nextId = _.get(sections, [sectionIndex + 1, 'items', 0])
    }
    return nextId
  }

  const renderSectionItems = (section, sectionIndex, isDraggingOverSection) => {
    const items = section.items
    return (
      <Droppable droppableId={section.id} type='ITEM'>
        {(provided, snapshot) => {
          console.log('onSection droppable snapshot isDraggingOver', snapshot.isDraggingOver)
          return (
            <SectionItemsContainer ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(items, (itemId, i) => {
                const li = getItem(itemId)
                if (!_.isEmpty(li)) {
                  const nextLiId = getNextLiId(sectionIndex, i)
                  if (section.id === lineItemSection.GENERAL_EXCLUSIONS_ID) {
                    return renderGeneralExclusionLineItem(
                      li,
                      nextLiId,
                      i,
                      snapshot.isDraggingOver || isDraggingOverSection
                    )
                  } else {
                    return renderLineItem(li, nextLiId, i, snapshot.isDraggingOver || isDraggingOverSection, section.id)
                  }
                } else {
                  return <Box key={itemId} />
                }
              })}
              {provided.placeholder}
            </SectionItemsContainer>
          )
        }}
      </Droppable>
    )
  }

  const renderSection = (section, sectionIndex, isDraggingOver) => {
    console.log('render section', sectionIndex)
    const isDragDisabled = section.id === lineItemSection.GENERAL_EXCLUSIONS_ID || disabled
    // console.log('renderSection', section.id, 'isDragDisabled', isDragDisabled)
    return (
      <Draggable draggableId={section.id} index={sectionIndex} key={section.id} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => {
          const isGeneralExclusionSection = section.id === lineItemSection.GENERAL_EXCLUSIONS_ID
          const isQuestionsSection = section.id === lineItemSection.QUESTIONS_ID
          if (isQuestionsSection && _.isEmpty(section.items)) {
            return null
          } else if (isQuestionsSection) {
            return (
              <SectionContainer
                {...provided.draggableProps}
                isDragging={snapshot.isDragging}
                ref={provided.innerRef}
                customStyle={'margin-bottom: 20px;'}
              >
                {renderSectionLine(section, sectionIndex, provided, snapshot.isDragging, false, isDraggingOver)}
                {renderSectionItems(section, sectionIndex, isDraggingOver)}
                {renderAddItemButton(sectionIndex, false, isDraggingOver, _.get(section, 'id'))}
              </SectionContainer>
            )
          } else if (isGeneralExclusionSection) {
            return (
              <SectionContainer {...provided.draggableProps} isDragging={snapshot.isDragging} ref={provided.innerRef}>
                {renderSectionLine(section, sectionIndex, provided, snapshot.isDragging, geExpanded, isDraggingOver)}
                <Collapsible open={geExpanded}>
                  {renderSectionItems(section, sectionIndex, isDraggingOver)}
                  {renderAddItemButton(sectionIndex, true, isDraggingOver, _.get(section, 'id'))}
                </Collapsible>
              </SectionContainer>
            )
          } else {
            return (
              <SectionContainer {...provided.draggableProps} isDragging={snapshot.isDragging} ref={provided.innerRef}>
                {renderSectionLine(section, sectionIndex, provided, snapshot.isDragging, false, isDraggingOver)}
                {renderSectionItems(section, sectionIndex, isDraggingOver)}
                {renderAddItemButton(sectionIndex, false, isDraggingOver, _.get(section, 'id'))}
              </SectionContainer>
            )
          }
        }}
      </Draggable>
    )
  }

  const onDragEnd = event => {
    console.log('onDragEnd', event)
    const fromIndex = _.get(event, 'source.index')
    const toIndex = _.get(event, 'destination.index')
    console.log('move', fromIndex, ' => ', toIndex)
    if (_.isNil(fromIndex) || _.isNil(toIndex)) {
      console.log('cannot get indexes')
      return
    }
    if (event.type === 'SECTION') {
      const fromSectionId = _.get(sections, [fromIndex, 'id'])
      const toSectionId = _.get(sections, [toIndex, 'id'])
      if (
        toSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID ||
        fromSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID
      ) {
        console.log('cannot move scope of work and general exclusions')
        return null
      }
      const newSections = arrayMove(sections, fromIndex, toIndex)
      // console.log('section move', newSections)
      saveSections(newSections)
    } else {
      const fromSectionId = _.get(event, 'source.droppableId')
      const toSectionId = _.get(event, 'destination.droppableId')
      if (fromSectionId === toSectionId) {
        const sectionIndex = _.findIndex(sections, s => s.id === fromSectionId)
        const sectionItems = _.get(sections, [sectionIndex, 'items'], [])
        const newItems = arrayMove(sectionItems, fromIndex, toIndex)
        const newSections = [...sections]
        _.set(newSections, [sectionIndex, 'items'], newItems)
        saveSections(newSections)
      } else {
        // console.log('fromSectionId', fromSectionId, 'toSectionId', toSectionId)
        const fromSectionIndex = _.findIndex(sections, s => s.id === fromSectionId)
        const toSectionIndex = _.findIndex(sections, s => s.id === toSectionId)
        // console.log('fromSectionIndex', fromSectionIndex, 'toSectionIndex', toSectionIndex)
        const fromSectionItems = _.get(sections, [fromSectionIndex, 'items'], [])
        const toSectionItems = _.get(sections, [toSectionIndex, 'items'], [])
        // console.log('fromSectionItems', fromSectionItems, 'toSectionItems', toSectionItems)
        const newSections = [...sections]
        const newFromSectionItems = [...fromSectionItems]
        const itemId = _.get(newFromSectionItems, fromIndex)
        if (
          toSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID ||
          fromSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID ||
          toSectionId === lineItemSection.QUESTIONS_ID ||
          fromSectionId === lineItemSection.QUESTIONS_ID
        ) {
          console.log('cannot move GE item to/from GE section')
          return null
        }
        if (
          _.has(scope, itemId) &&
          (toSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID ||
            fromSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID)
        ) {
          console.log('cannot move scope of work item to/from GE section')
          return null
        }
        newFromSectionItems.splice(fromIndex, 1)
        _.set(newSections, [fromSectionIndex, 'items'], newFromSectionItems)
        const newToSectionItems = [...toSectionItems]
        newToSectionItems.splice(toIndex, 0, itemId)
        _.set(newSections, [toSectionIndex, 'items'], newToSectionItems)
        // console.log('new Sections after moving item', itemId, newSections)
        saveSections(newSections)
      }
    }
  }

  const onDragStart = () => {
    // setDraggingMode(true)
  }

  const renderTableBody = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <TableBodyContainer>
          {renderTableHeader()}
          <Droppable droppableId='board' type='SECTION'>
            {(provided, snapshot) => {
              // console.log('Droppable isDragging over', snapshot.isDraggingOver)
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {_.compact(_.map(sections, (s, i) => renderSection(s, i, snapshot.isDraggingOver)))}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
          <div ref={tableEndRef} />
        </TableBodyContainer>
      </DragDropContext>
    )
  }

  const addNewLineItemsFromTemplate = (addItems, sectionInfo) => {
    console.log('addNewLineItemsFromTemplate, items', addItems)
    console.log('addNewLineItemsFromTemplate, sectionInfo', sectionInfo)
    const sectionId = _.get(sectionInfo, 'id', lineItemSection.SCOPE_OF_WORK_ID)
    const sectionIndex = _.findIndex(sections, s => s.id === sectionId)
    const s = _.get(sections, sectionIndex)
    console.log('sow section found', s)
    if (_.isEmpty(s)) {
      console.log('section ', sectionIndex, 'not found, add a new section')
      const newS = {
        id: sectionInfo.id,
        title: sectionInfo.title,
        items: _.keys(addItems)
      }
      const newSections = [newS, ...sections]
      const newItems = { ...itemsDB, ...addItems }
      setFields({
        items: newItems,
        sections: newSections
      })
    } else {
      const newItems = { ...itemsDB, ...addItems }
      const newSections = [...sections]
      const sectionItems = _.get(s, 'items', [])
      const newSectionItems = [...sectionItems, ..._.keys(addItems)]
      _.set(newSections, [sectionIndex, 'items'], newSectionItems)
      setFields({
        items: newItems,
        sections: newSections
      })
    }
  }

  const renderTable = () => {
    return (
      <Box customStyle={'flex: 1;'}>
        {renderTableBody()}
      </Box>
    )
  }

  const onRemovingDeclined = (item, comment) => {
    console.log('onRemovingDeclined', item, comment)
    const rr = _.get(removalRequests, item.id, {})
    const newRemovalRequests = {
      ...removalRequests,
      [item.id]: {
        ...rr,
        declined: true,
        declinedComment: comment || fieldValue.delete()
      }
    }
    console.log('rr', rr)
    console.log('newRemovalRequests', newRemovalRequests)
    setField('removalRequests')(newRemovalRequests)
  }

  const onRemovingApproved = (item, comment, dec) => {
    console.log('onRemovingApproved, item', item, 'comment', comment, 'dec', dec)
    if (dec > 0) {
      const unpricedAmount = getField('unpricedAmount', 0)
      setField('unpricedAmount')(unpricedAmount - dec)
    }
    const newRemovalRequests = { ...removalRequests }
    _.unset(newRemovalRequests, item.id)
    setField('removalRequests')(newRemovalRequests)
    removeLineItem(item.id)
  }

  const openTemplateModal = () => {
    addLineItemRef.current.open()
  }

  return (
    <Box flex>
      <Box>
        <CreateBidSubMenu
          addLineItem={addLineitem}
          addQuestion={onMenuAddQuestionClick}
          addSection={addNewSection}
          addLineItemFromTemplate={openTemplateModal}
          addGEItem={addGEItem}
          disabled={disabled}
        />
        {renderTable()}
      </Box>
      <RemoveLineItemModal
        ref={removeLineItemModalRef}
        onRemovingDeclined={onRemovingDeclined}
        onRemovingApproved={onRemovingApproved}
      />
      <AddLineItemFromTemplate ref={addLineItemRef} addNewLineItem={addNewLineItemsFromTemplate} sections={sections} />
    </Box>
  )
}

SubBiddingInterface.propTypes = {
  scope: PropTypes.object,
  gcProfile: PropTypes.object,
  errorComments: PropTypes.object,
  setError: PropTypes.func,
  workOrderId: PropTypes.string,
  cellOpen: PropTypes.string,
  cellOpenTimestamp: PropTypes.number,
  getField: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  openCommentsPanel: PropTypes.func,
  setFields: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  userId: _.get(state, ['user', 'id']),
  messages: _.get(getLevelingTableMessages(state), ['cells', _.get(state, 'account.id')], {}),
  channel: state.channelWeb,
  profiles: state.profiles,
  account: state.account
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(SubBiddingInterface)
