import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Text, Avatar, Button, Layer, ThemeContext, MaskedInput, TextArea, DropButton } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faEllipsisV, faTimes } from '@fortawesome/pro-light-svg-icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import validator from 'validator'

import DropContent from 'components/DropContent'
import { PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import colors from 'shared/constants/colors'
import { getAccountAdmins } from 'model/selectors/base'
import { sendInvite, removeInvite, resendInvite, removeUserFromAccount } from 'controllers/auth'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { emailMask, phoneMask } from 'utils/inputMask'
import { getManageUsersPermission } from 'model/selectors/permissionsSelector'
import roles from 'shared/constants/roles'
import { editUserRole } from 'controllers/account'

const borderBottom = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'bottom'
}

const menuActionId = {
  REMOVE_USER: 'REMOVE_USER',
  CHANGE_ROLE: 'CHANGE_ROLE'
}

const Row = ({ onClick, children }) => (
  <Box
    border={borderBottom}
    pad='small'
    direction='row'
    align='center'
    hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
    onClick={onClick}
    height='52px'
  >
    {children}
  </Box>
)

const RemoveModal = ({ user, setUser, dispatch }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(!_.isNil(user))
  }, [user])

  const handleRemove = () => {
    dispatch(removeUserFromAccount(user.id))
    setUser(null)
  }

  if (!open) return null
  return (
    <Layer margin='large' onEsc={() => setUser(null)} onClickOutside={() => setUser(null)} animate={false}>
      <Box width='medium' height='small'>
        <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
          <Text color={colors.BLACK} size='medium'>
            Removing{' '}
            <Text size='medium' color={colors.BLACK} weight='bold'>
              {_.get(user, 'name')}
            </Text>{' '}
            from company
          </Text>
        </Box>
        <Box pad='small' fill='horizontal' align='center' direction='row' gap='small'>
          <Text size='medium' color={colors.BLACK}>
            Are you sure?
          </Text>
        </Box>
        <Box direction='row' justify='end' gap='small' margin={{ top: 'auto' }} pad='small'>
          <Box>
            <Button primary color={colors.AQUA_MARINE} onClick={handleRemove} label='Confirm' />
          </Box>
          <Box>
            <Button primary color={colors.CORAL_TWO} onClick={() => setUser(null)} label='Cancel' />
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}

const Teammates = ({ currentUser, admins, invitations, canManageUsers, dispatch, goNext }) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [removingUser, setRemovingUser] = useState(null)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleSubmit = () => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    dispatch(sendInvite(phoneNumber.number, roles.OWNER, null, null, email, message))
    setOpen(false)
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const renderRole = user => {
    if (user.invited) {
      return (
        <Button
          primary
          color={colors.AQUA_MARINE}
          label='Resend'
          onClick={() => dispatch(resendInvite(user.inviteId))}
        />
      )
    } else {
      return (
        <Text size='small' color={colors.VERY_LIGHT_PINK}>
          {_.upperFirst(user.role)}
        </Text>
      )
    }
  }

  const ActionButton = ({ user }) => {
    const [open, setOpen] = useState(false)

    const handleAction = async actionId => {
      setOpen(false)
      switch (actionId) {
        case menuActionId.REMOVE_USER: {
          setRemovingUser(user)
          break
        }
        case menuActionId.CHANGE_ROLE: {
          dispatch(editUserRole(user.id, roles.OWNER))
          break
        }
        default:
          break
      }
    }

    if (user.invited) {
      return (
        <Button
          plain
          label={<FontAwesomeIcon icon={faTimes} size={18} color={colors.MEDIUM_GREY} />}
          onClick={() => removeInvite(user.inviteId)}
        />
      )
    } else {
      return (
        <DropButton
          plain
          label={<FontAwesomeIcon icon={faEllipsisV} size={18} color={colors.MEDIUM_GREY} />}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          dropContent={
            <DropContent
              onOptionClick={actionId => handleAction(actionId)}
              options={[
                {
                  id: menuActionId.CHANGE_ROLE,
                  label: 'Change role to Owner',
                  disabled: _.get(user, 'role') === 'owner' || !canManageUsers
                },
                {
                  id: menuActionId.REMOVE_USER,
                  label: <Text color={colors.CORAL_TWO}>Remove user</Text>,
                  disabled: _.isEqual(user.id, currentUser.id) || _.get(user, 'role') === 'owner' || !canManageUsers
                }
              ]}
              boxProps={{ width: '200px' }}
              optionLabelProps={{ textAlign: 'start' }}
            />
          }
          dropProps={{ align: { top: 'bottom' } }}
        />
      )
    }
  }

  const renderUser = (user, index) => {
    const email = _.get(user, 'email')
    const phone = _.get(user, 'phone') || ''
    const phoneNumber = parsePhoneNumberFromString(phone)
    const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
    return (
      <Box
        flex={false}
        direction='row'
        align='center'
        key={user.id}
        pad='small'
        border={borderBottom}
        hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
        height='52px'
        onClick={() => null}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={user.avatarSmall}>
            <Text color={colors.WHITE}>{getInitials(user)}</Text>
          </Avatar>
        </Box>
        <Box fill margin={{ left: 'medium' }} align='start' justify='center' gap='xsmall'>
          <Text>{getName(user)}</Text>
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {contacts}
          </Text>
        </Box>
        <Box align='end' width='small' pad={{ horizontal: 'small' }}>
          {renderRole(user)}
        </Box>
        <Box width='xsmall' align='center'>
          <ActionButton user={user} />
        </Box>
      </Box>
    )
  }

  const renderInviteTeammate = () => {
    if (open) {
      return (
        <ThemeContext.Extend
          value={{
            maskedInput: { extend: `background-color: ${colors.WHITE};` },
            textArea: { extend: `background-color: ${colors.WHITE};` }
          }}
        >
          <Box gap='small' flex={false} background={colors.ASANA_GRAY_BACKGROUND} pad={{ bottom: 'large' }}>
            <Box pad={{ horizontal: 'medium', top: 'large' }} gap='xsmall' flex={false}>
              <Text color={colors.TEXT}>Email</Text>
              <MaskedInput mask={emailMask} value={email} onChange={e => setEmail(e.target.value)} />
            </Box>
            <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
              <Text color={colors.TEXT}>Phone number:</Text>
              <MaskedInput mask={phoneMask} value={phone} onChange={e => setPhone(e.target.value)} />
            </Box>
            <Box pad={{ horizontal: 'medium' }} height='100px' flex={false}>
              <TextArea
                resize={false}
                fill
                placeholder='Add message (optional)'
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Box>
            <Box fill='horizontal' pad={{ vertical: 'small', horizontal: 'medium' }}>
              <Button
                disabled={!validator.isEmail(email) || phone.length < PHONE_NUMBER_LENGTH}
                primary
                color={colors.LIGHT_NAVY_BRIGHT}
                label='Invite'
                onClick={handleSubmit}
                fill
              />
            </Box>
            <Button color={colors.LINK} label='Cancel' onClick={handleOpen} />
          </Box>
        </ThemeContext.Extend>
      )
    } else {
      return (
        <Row onClick={handleOpen}>
          <Box
            width='32px'
            height='32px'
            border={{ color: colors.ANOTHER_GREY, style: 'dashed' }}
            round='xxsmall'
            align='center'
            justify='center'
            responsive={false}
          >
            <FontAwesomeIcon icon={faPlus} size={20} color={colors.ANOTHER_GREY} />
          </Box>

          <Text margin={{ left: 'medium' }} color={colors.ANOTHER_GREY}>
            Invite Teammate
          </Text>
        </Row>
      )
    }
  }

  return (
    <Box>
      <Box alignSelf='center' width='large' direction='column' flex={false}>
        <Box flex={false} fill='vertical' direction='column'>
          {/* <Box overflow='auto' height='100%'> */}

          {_.map(_.concat(admins, invitations), renderUser)}
          {renderInviteTeammate()}
          {/* </Box> */}
        </Box>
      </Box>
      <RemoveModal user={removingUser} setUser={setRemovingUser} dispatch={dispatch} />
      {!open && (
        <Box pad={{ vertical: 'large', horizontal: 'small' }} fill={'horizontal'}>
          <Button size='large' primary label='Next' color={colors.AQUA_MARINE} onClick={goNext} />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = state => ({
  currentUser: state.user,
  canManageUsers: getManageUsersPermission(state),
  admins: _.map(getAccountAdmins(state), admin => ({ ...admin, ..._.get(state, ['profiles', admin.id]) })),
  invitations: _.map(_.values(state.outgoingInvitations), inv => ({
    ...inv,
    ..._.get(state, ['profiles', inv.userId]),
    invited: true,
    inviteId: inv.id
  }))
})

export default connect(mapStateToProps)(Teammates)
