import _ from 'lodash'

import navigationService from 'shared/navigation/service'
import { isMediumScreen } from 'shared/utils/breakpoints'
import { isWeb } from 'shared/constants/index'
import history from 'shared/utils/history'
import p from 'utils/pattern'
import store from 'model/store'
import screens from 'constants/screens'
import { switchMode } from 'model/actions/modeAC'
import analytics from 'utils/analytics'

let block = null

const setBlock = b => {
  console.log('UNINAV: set block')
  block = b
}

const resetBlock = () => {
  console.log('UNINAV: reset block')
  block = null
}

const getQueryString = queryData => {
  if (_.isObject(queryData)) {
    const str = _.map(queryData, (v, k) => `${k}=${v}`).join('&')
    return `?${str}`
  } else {
    return undefined
  }
}

const processBlock = async () => {
  if (!_.isNil(block)) {
    console.log('UNINAV: block found')
    const canContinue = await block()
    console.log('UNINAV: block ended, canContinue', canContinue)
    return canContinue
  } else {
    return true
  }
}

const createPath = (screenName, params) => {
  const hasPath = _.has(p, screenName)
  // console.log('hasPath', hasPath)
  if (hasPath) {
    const toPath = p[screenName].stringify(params)
    return toPath
  } else {
    console.warn('no route to screen', screenName, params)
    return null
  }
}

const isNavigationForMedium = () => {
  const { viewport } = store.getState()
  return isMediumScreen(viewport) || isWeb
}

const push = async (screenName, params, query) => {
  const canContinue = await processBlock()
  if (!canContinue) {
    console.log('UNINAV push blocked, stop')
    return null
  }
  console.log('UNINAV: push', screenName, params, query)
  if (!screenName) {
    console.warn('set screenName')
    return null
  } else if (isNavigationForMedium()) {
    const toPath = createPath(screenName, params)
    if (toPath) {
      analytics.trackScreen(screenName)
      const historyState = _.omitBy(params, _.isFunction)
      history.push({
        pathname: toPath,
        search: getQueryString(query),
        state: historyState
      })
    }
  } else {
    if (_.includes(mainScreens, screenName)) {
      store.dispatch(switchMode(screenName))
    } else {
      navigationService.push(screenName, params)
    }
  }
}

const mainScreens = [
  screens.LOADING,
  screens.UPDATE_APP,
  screens.AUTH,
  screens.APP,
  screens.ONBOARDING,
  screens.PROFILE,
  screens.USER_ONBOARDING
]

const navigate = async (screenName, params, query) => {
  console.log('UNINAV: navigate', screenName, params, query)
  const canContinue = await processBlock()
  if (!canContinue) {
    console.log('UNINAV navigate blocked, stop')
    return null
  }
  if (!screenName) {
    console.warn('set screenName')
    return null
  } else if (isNavigationForMedium()) {
    const toPath = createPath(screenName, params)
    if (toPath) {
      analytics.trackScreen(screenName)
      const historyState = _.omitBy(params, _.isFunction)
      // console.log('HISTORY REPLACE', toPath, historyState)
      history.replace({
        pathname: toPath,
        search: getQueryString(query),
        state: historyState
      })
    }
  } else {
    if (_.includes(mainScreens, screenName)) {
      store.dispatch(switchMode(screenName))
    } else {
      navigationService.navigate(screenName, params)
    }
  }
}

const pop = (n = 1) => {
  console.log('UNINAV: pop', n)
  if (isNavigationForMedium()) {
    history.go(-n)
  } else {
    navigationService.pop(n)
  }
}

const popToTop = () => {
  console.log('UNINAV: popToTop')
  if (isNavigationForMedium()) {
    // console.log('history', history)
    // history.go(-history.length)
  } else {
    navigationService.popToTop()
  }
}

const goBack = async () => {
  console.log('UNINAV: goBack')
  const canContinue = await processBlock()
  if (!canContinue) {
    console.log('UNINAV goBack blocked, stop')
    return null
  }
  if (isNavigationForMedium()) {
    history.goBack()
  } else {
    navigationService.goBack()
  }
}

export default {
  push,
  navigate,
  pop,
  popToTop,
  goBack,
  setBlock,
  resetBlock
}
