import _ from 'lodash'
// import generate from 'firebase-auto-ids'
// import Sentry from 'shared/utils/sentry'

import { db, fieldValue } from 'constants/firebase'
import { firstNameByName, lastNameByName } from 'shared/utils/stringUtils'
// import store from 'model/store'

export function updateLicenseType (isSalesperson, hasLicense, licenseNumber) {
  return async function (dispatch, getState) {
    console.log('updateLicenseType', isSalesperson, hasLicense, licenseNumber)
    const state = getState()
    const account = state.account
    // const user = state.user
    // const userUpd = {
    //   isSalesperson: isSalesperson ? true : fieldValue.delete()
    // }
    // console.log('userUpd', userUpd)
    // await db.collection('users').doc(user.id).update(userUpd)
    if (!isSalesperson && hasLicense && !_.isEmpty(licenseNumber)) {
      if (licenseNumber !== _.get(account, 'contractorLicense.license')) {
        const upd = {
          contractorLicense: {
            license: licenseNumber,
            updateNum: _.now()
          }
        }
        await db
          .collection('accounts')
          .doc(account.id)
          .update(upd)
      }
    }
  }
}

export function updateContacts (userName, accountName, accountEmail, accountPhone, address, apartment) {
  return async function (dispatch, getState) {
    console.log('updateContacts', userName, accountName, accountEmail, accountPhone, address, apartment)
    const state = getState()
    const account = state.account
    const user = state.user
    const userUpd = {
      name: userName,
      firstName: firstNameByName(userName),
      lastName: lastNameByName(userName)
    }
    await db
      .collection('usersProfiles')
      .doc(user.id)
      .update(userUpd)
    const accUpd = {
      name: accountName,
      email: accountEmail,
      phone: accountPhone,
      address: _.omitBy(address, _.isNil),
      apartment: _.isEmpty(apartment) ? fieldValue.delete() : apartment
    }
    await db
      .collection('accountsProfiles')
      .doc(account.id)
      .update(accUpd)
  }
}

export function updateUserContacts (userName, title) {
  return async function (dispatch, getState) {
    console.log('updateUserContacts', userName)
    const state = getState()
    const user = state.user
    const userUpd = {
      name: userName,
      firstName: firstNameByName(userName),
      lastName: lastNameByName(userName),
      title
    }
    await db
      .collection('usersProfiles')
      .doc(user.id)
      .update(userUpd)
  }
}

export const updateContractorType = (contractorTypeIds, typesOfWork, projectsSize) => {
  return async (dispatch, getState) => {
    console.log('updateContractorType', contractorTypeIds, typesOfWork, projectsSize)
    const state = getState()
    const account = state.account
    const curContractorTypeIds = _.get(account, 'contractorTypeIds', [])
    const upd = {
      typesOfWork,
      projectsSize
    }
    if (!_.isEqual(curContractorTypeIds, contractorTypeIds)) {
      upd.contractorTypeIds = contractorTypeIds
      upd.projectTypes = fieldValue.delete()
    }
    console.log('upd', upd)
    await db
      .collection('accounts')
      .doc(account.id)
      .update(upd)
  }
}

export const updateCompanyServiceTypes = serviceTypes => {
  return async (dispatch, getState) => {
    console.log('updateServiceTypes', serviceTypes)
    const state = getState()
    const account = state.account
    const upd = {
      projectTypes: serviceTypes
    }
    await db
      .collection('accounts')
      .doc(account.id)
      .update(upd)
  }
}

export const updateUserServiceTypes = serviceTypes => {
  return async (dispatch, getState) => {
    console.log('updateServiceTypes', serviceTypes)
    const state = getState()
    const account = state.account
    const user = state.user
    const upd = {
      [`admins.${user.id}.projectTypes`]: serviceTypes
    }
    await db
      .collection('accounts')
      .doc(account.id)
      .update(upd)
  }
}

export const updateServiceArea = (address, radius) => {
  return async (dispatch, getState) => {
    console.log('updateServiceArea', address, radius)
    const state = getState()
    const user = state.user
    const account = state.account
    const isCreator = user.id === account.id
    if (isCreator) {
      const upd = {
        serviceAddress: address,
        serviceRadius: radius
      }
      await db
        .collection('accounts')
        .doc(account.id)
        .update(upd)
    }
  }
}

export const updateOnlineContacts = (yearFounded, website, fbpage, offerFinancing, interestedInFinancing) => {
  return async (dispatch, getState) => {
    console.log('updateOnlineContacts', yearFounded, website, fbpage, offerFinancing)
    const state = getState()
    const account = state.account
    const upd = {
      yearFounded: _.isEmpty(yearFounded) ? fieldValue.delete() : yearFounded,
      website: _.isEmpty(website) ? fieldValue.delete() : website,
      fbpage: _.isEmpty(fbpage) ? fieldValue.delete() : fbpage,
      offerFinancing: !offerFinancing ? fieldValue.delete() : true,
      interestedInFinancing: offerFinancing || !interestedInFinancing ? fieldValue.delete() : true
    }
    await db
      .collection('accountsProfiles')
      .doc(account.id)
      .update(upd)
  }
}

export const updateUserTerritories = userTerritories => {
  return async (dispatch, getState) => {
    const state = getState()
    const account = state.account
    const user = state.user
    const upd = {
      [`admins.${user.id}.territories`]: userTerritories
    }
    await db
      .collection('accounts')
      .doc(account.id)
      .update(upd)
  }
}

export const setOnboardingComplete = () => {
  return async (dispatch, getState) => {
    console.log('set onboarding complete')
    const state = getState()
    const user = state.user
    const account = state.account
    const upd = {
      [`admins.${user.id}.onboardingComplete`]: true
    }
    await await db
      .collection('accounts')
      .doc(account.id)
      .update(upd)
  }
}
