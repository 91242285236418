import React from 'react'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'
import { getHeight } from 'shared/utils/dynamicSize'
import { A4K } from 'shared/constants/documents'
import Estimate from 'shared/documents/Estimate'

const EstimatePreviewContainer = styled(ScrollView)`
  /* margin-top: ${props => getHeight(20, props.viewport)}; */
  width: ${props => props.viewport.width}px;
  /* flex-grow: 0; */
  /* flex: 1; */
`

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom
}

const EstimatePreview = React.forwardRef(
  ({ viewport, doc, onPageClick, isAtBottomCallback, isAtBottom, ...rest }, ref) => {
    const previewWidth = viewport.width
    return (
      <EstimatePreviewContainer
        ref={ref}
        viewport={viewport}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent) && !isAtBottom) {
            isAtBottomCallback(true)
          } else if (!isCloseToBottom(nativeEvent) && isAtBottom) {
            isAtBottomCallback(false)
          }
        }}
        scrollEventThrottle={100}
        // horizontal
        // contentContainerStyle={{height: previewWidth * A4K}}
        // pagingEnabled
        // showsHorizontalScrollIndicator={false}
      >
        <Estimate
          pageWidth={previewWidth}
          pageHeight={previewWidth * A4K}
          doc={doc}
          onPageClick={onPageClick}
          pageProps={{
            customStyle: `
            align-self: center;
            margin-top: ${getHeight(5, viewport)}px;
            margin-bottom: ${getHeight(5, viewport)}px;
          `,
            firstPageCustomStyle: `
            margin-top: 0px;
          `,
            lastPageCustomStyle: `
            margin-bottom: 0px;
          `
          }}
          {...rest}
        />
      </EstimatePreviewContainer>
    )
  }
)

EstimatePreview.defaultProps = {
  isAtBottomCallback: () => null,
  isAtBottom: () => null
}

EstimatePreview.propTypes = {
  viewport: PropTypes.object,
  doc: PropTypes.object,
  onPageClick: PropTypes.func,
  isAtBottomCallback: PropTypes.func,
  isAtBottom: PropTypes.func
}

export default EstimatePreview
