import React from 'react'
import styled from 'sc'
import { View, Image } from 'react-native'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import { getName } from 'shared/utils/stringUtils'
import DocAvatar from 'shared/documents/components/DocAvatar'
import { getInitials } from 'shared/utils/stringUtils'

const Container = styled(View)`
  width: ${props => props.pageWidth}px;
  /* height: ${props => props.pageHeight}px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-bottom: ${props => props.getSize(40)}px;
`
const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
`

const BackgroundOveraly = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
  background-color: rgba(51, 51, 51, 0.73);
`
const HeaderContainer = styled(View)`
  margin-top: ${props => props.getSize(200)}px;
  position: relative;
`
const BottomLeftContainer = styled(View)`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`
const TitleContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${props => props.getSize(20)}px;
`
const TitleTextContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: ${props => props.getSize(20)}px;
  height: ${props => props.getSize(32)}px;
`
const AboutTextContainer = styled(View)`
  margin-top: ${props => props.getSize(10)}px;
`

const defaultImageUri = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Fabout_us_header.png?alt=media&token=159d1ed9-9df6-4fed-8b68-86d11bed0fc0'

export const renderAboutUs = (getSize, width, height, ssr, profile) => {
  if (_.isEmpty(profile)) return null
  const about = _.get(profile, 'about', '')

  return (
    <Container pageWidth={width} pageHeight={height - 1} getSize={getSize} key='aboutUs'>
      <BackgroundImage
        source={{ uri: defaultImageUri }}
        defaultSource={ ssr ? { uri: defaultImageUri } : undefined}
        resizeMode='cover'
        getSize={getSize}
      />

      <BackgroundOveraly getSize={getSize} />

      <HeaderContainer getSize={getSize}>
        <StyledText
          // color={colors.WHITE}
          fontSize={getSize(32)}
          textAlign='center'
          bold
        >
          About us
        </StyledText>
      </HeaderContainer>

      <TitleContainer getSize={getSize}>
        <DocAvatar
          size={getSize(40)}
          ssr={ssr}
          url={_.get(profile, 'avatarSmall')}
          initials={getInitials(profile)}
        />
        <TitleTextContainer getSize={getSize}>
          <StyledText fontSize={getSize(16)} color={colors.LIGHT_NAVY} bold>{getName(profile)}</StyledText>
          <StyledText fontSize={getSize(10)}>In business since {_.get(profile, 'yearFounded')}</StyledText>
        </TitleTextContainer>
      </TitleContainer>

      {!_.isEmpty(about) &&
        <AboutTextContainer getSize={getSize}>
          <StyledText fontSize={getSize(10)}>{about}</StyledText>
        </AboutTextContainer>
      }
    </Container>
  )
}
