import React, { useMemo } from 'react'
import _ from 'lodash'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import DropDownMenu from 'components/DropDownMenu'
import colors from 'shared/constants/colors'

const SelectRow = ({ title, selectedId, options, onOptionClick }) => {
  const selectedLabel = useMemo(
    () =>
      _.get(
        _.find(options, o => o.id === selectedId),
        'label'
      ),
    [options, selectedId]
  )

  return (
    <Box direction='row' align='center' justify='between' flex={{ shrink: 0 }}>
      <Text>{title}</Text>
      <DropDownMenu options={options} selectedId={selectedId} onOptionClick={onOptionClick}>
        <Box
          width='175px'
          height='36px'
          align='center'
          justify='between'
          direction='row'
          border='all'
          round='xsmall'
          pad={{ horizontal: 'small' }}
        >
          <Text color={colors.TEXT_PRIMARY}>{selectedLabel}</Text>
          <FontAwesomeIcon icon={faAngleDown} color={colors.TEXT_PRIMARY} size={16} />
        </Box>
      </DropDownMenu>
    </Box>
  )
}

export default SelectRow
