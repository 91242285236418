import Sentry from 'shared/utils/sentry'
import _ from 'lodash'

import { getName } from 'shared/utils/stringUtils'
import config from 'shared/config'
import analytics from 'utils/analytics'

let userParams = {}
let companyParams = {}

export const setSentryUserProfile = profile => {
  // console.log('Sentry', Sentry)
  const params = {
    username: getName(profile),
    email: _.get(profile, 'email'),
    phone: _.get(profile, 'phone'),
    id: _.get(profile, 'id'),
    accountId: _.get(profile, 'currentAccountId'),
    internalVersion: _.toString(config.internalVersion),
    env: config.isDev ? 'dev' : 'live'
  }
  userParams = params
  Sentry.setUser({ ...params, ...companyParams })
  analytics.trackUserParams({ ...params, ...companyParams })
}

export const setSentryCompanyProfile = accountProfile => {
  const params = {
    companyName: _.get(accountProfile, 'name'),
    companyPhone: _.get(accountProfile, 'phone')
  }
  companyParams = params
  Sentry.setUser({ ...params, ...userParams })
  analytics.trackUserParams({ ...params, ...userParams })
}
