import React from 'react'
import { connect } from 'react-redux'
import { View, Image } from 'react-native'
import styled from 'sc'

import Page from 'shared/components/Page'
import { StyledText } from 'shared/components/StyledComponents'
import { WHITE, AQUA_MARINE, LIGHT_NAVY, DARK_GRAY } from 'shared/constants/colors'
import { getWidth, getHeight, fontSize } from 'shared/utils/dynamicSize'
import PlainButton from 'shared/components/buttons/PlainButton'
// images
import logoImage from 'assets/images/logo2.png'
import appleImage from 'assets/images/apple.png'
import googlePlayImage from 'assets/images/google-play.png'
import houseImage from 'assets/images/house-illustration.png'

const Logo = styled(Image)`
  width: ${props => getWidth(props.width, props.viewport)}px;
  height: ${props => getHeight(props.height, props.viewport)}px;
`
const MasonLogo = styled(Logo)`
  margin-top: ${props => getWidth(28, props.viewport)}px;
  margin-left: ${props => getWidth(20, props.viewport)}px;
  width: ${props => getWidth(128, props.viewport)}px;
  height: ${props => getHeight(42, props.viewport)}px;
`
const MainTextContainer = styled(View)`
  margin-top: ${props => getHeight(65, props.viewport)}px;
  margin-bottom: ${props => getHeight(25, props.viewport)}px;
`
const Text = styled(StyledText)`
  color: ${props => props.color};
  font-size: ${props => fontSize(28, props.viewport)}px;
  text-align: center;
  font-weight: bold;
`
const ButtonTextContainer = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ${props => getWidth(7, props.viewport)}px;
`
const TopButtonText = styled(StyledText)`
  font-size: ${props => fontSize(9, props.viewport)}px;
  text-align: center;
  color: ${props => props.color};
`
const BottomButtonText = styled(StyledText)`
  font-size: ${props => fontSize(10, props.viewport)}px;
  text-align: center;
  color: ${props => props.color};
`
const Description = styled(StyledText)`
  color: ${WHITE};
  text-align: center;
`
const ButtonsContainer = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-vertical: ${props => getHeight(55, props.viewport)}px;
`
const HouseImageContainer = styled(View)`
  justify-content: center;
  align-items: center;
`
const HouseImage = styled(Image)`
  width: ${props => getWidth(250, props.viewport)}px;
  height: ${props => getHeight(250, props.viewport)}px;
`

const ButtonWithLogo = ({
  viewport,
  logoSource,
  topTitleText,
  bottomTitleText,
  titleColor,
  backgroundColor,
  logoSize,
  customStyle
}) => {
  return (
    <PlainButton
      prefix={<Logo source={logoSource} width={logoSize} height={logoSize} viewport={viewport} resizeMode='contain' />}
      titleComponent={
        <ButtonTextContainer viewport={viewport}>
          <TopButtonText color={titleColor} viewport={viewport}>
            {topTitleText}
          </TopButtonText>
          <BottomButtonText color={titleColor} viewport={viewport}>
            {bottomTitleText}
          </BottomButtonText>
        </ButtonTextContainer>
      }
      customStyle={`
      background-color: ${backgroundColor};
      width: ${getWidth(112, viewport)}px;
      marginHorizontal: ${getWidth(10, viewport)}px;
      height: ${getHeight(45, viewport)}px;
      ${customStyle};
    `}
    />
  )
}

const WebMobilePlaceholder = ({ viewport }) => (
  <Page backgroundColor={AQUA_MARINE}>
    <MasonLogo source={logoImage} resizeMode='contain' viewport={viewport} />
    <MainTextContainer viewport={viewport}>
      <Text color={WHITE} viewport={viewport}>
        Build more with
      </Text>
      <Text color={LIGHT_NAVY} viewport={viewport}>
        Mason.
      </Text>
    </MainTextContainer>
    <Description viewport={viewport}>Download the app to get started</Description>
    <ButtonsContainer viewport={viewport}>
      <ButtonWithLogo
        viewport={viewport}
        logoSource={googlePlayImage}
        titleColor={DARK_GRAY}
        backgroundColor={WHITE}
        topTitleText='Download on'
        bottomTitleText='Google Play'
        logoSize={22}
      />
      <ButtonWithLogo
        viewport={viewport}
        logoSource={appleImage}
        titleColor={WHITE}
        backgroundColor='transparent'
        topTitleText='Download on the'
        bottomTitleText='App Store'
        customStyle={`border-width: 1px; border-color: ${WHITE};`}
        logoSize={24}
      />
    </ButtonsContainer>
    <HouseImageContainer>
      <HouseImage viewport={viewport} source={houseImage} resizeMode='contain' />
    </HouseImageContainer>
  </Page>
)

const mapStateToProps = state => ({
  viewport: state.viewport
})

export default connect(mapStateToProps)(WebMobilePlaceholder)
