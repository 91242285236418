import React from 'react'
const Ctx = React.createContext({
  gcAccountId: null,
  gcProfile: null,
  setGCAccountId: () => null,
  address: null,
  setAddress: () => null,
  openCreateClientModal: () => null,
  getGCAccountProfile: () => null,
  openFilesPanel: () => null,
  openCommentsInbox: () => null,
  closeCommentsInbox: () => null,
  setGCName: () => null,
  workOrderId: null,
  tradeId: {},
  bidId: null,
  setTradeId: () => null,
  isSubProposal: () => false,
  getField: () => null,
  setField: () => null,
  setFields: () => null,
  setParams: () => null,
  openScheduleOfPaymentsModal: () => null,
  isScheduleOfPaymetsValid: () => true,
  highlightTradeId: false,
  setHighlightTradeId: () => null,
  openAddUserModal: () => null,
  canSendBid: () => null,
  getNewBid: () => null,
  getUserScopeOfWork: () => null,
  setDepositType: () => null,
  setDepositValue: () => null,
  openCommentsPanel: () => null
})

export default Ctx
