import React from 'react'
import { Tip, Box, Text, ThemeContext } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import colors from 'shared/constants/colors'

const TipContent = ({ message, down }) => (
  <Box direction={down ? 'column-reverse' : 'column'} justify='start' align='center'>
    <FontAwesomeIcon icon={down ? faCaretDown : faCaretUp} size={24} style={{ position: down ? 'absolute' : 'none' }} />
    <Box
      margin={{ top: down ? '0px' : '-9px' }}
      background='black'
      direction='column'
      justify='center'
      align='center'
      pad='small'
      round='xsmall'
      // style={{ marginBottom: down ? '13px' : 0 }}
    >
      <Text size='medium' textAlign='center' color={colors.WHITE}>
        {message}
      </Text>
    </Box>
  </Box>
)

const CustomTip = ({ message, down, children }) => {
  return (
    <ThemeContext.Extend
      value={{
        global: {
          drop: { extend: 'box-shadow: none' }
        }
      }}
    >
      <Tip plain elevation='none' content={<TipContent message={message} down={down} />}>
        {children}
      </Tip>
    </ThemeContext.Extend>
  )
}

export default CustomTip
