import React, { useState, useEffect } from 'react'
import { Box, TextInput, FormField, ThemeContext, Text, Avatar, Button, MaskedInput, Keyboard } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { connect } from 'react-redux'
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import validator from 'validator'

import colors from 'shared/constants/colors'
import { userIdByPhone } from 'controllers/data'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { updateUserProfile, saveUserProfile, removeAvatarFromUser } from 'controllers/profiles'
import { PHONE_NUMBER_LENGTH, UNFORMATTED_PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import { browseFiles } from 'shared/controllers/files'
import { emailMask, phoneMask } from 'utils/inputMask'

const formatPhone = phone =>
  phone.length === UNFORMATTED_PHONE_NUMBER_LENGTH
    ? `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 12)}`
    : ''

const Profile = ({ profile, dispatch }) => {
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [email, setEmail] = useState('')
  const [emails, setEmails] = useState([])
  const [phone, setPhone] = useState('')
  const [phones, setPhones] = useState([])

  useEffect(() => {
    if (!_.isNil(profile)) {
      setName(_.get(profile, 'name', ''))
      setTitle(_.get(profile, 'title', ''))
      setAvatarUrl(_.get(profile, 'avatarSmall', _.get(profile, 'avatar', null)))
      setPhone(formatPhone(_.get(profile, 'phone', '')))
      setEmail(_.get(profile, 'email', ''))
      setEmails(_.get(profile, 'emails', []))
      setPhones(_.map(_.get(profile, 'phones', []), formatPhone))
    }
  }, [profile])

  useEffect(() => {
    if (_.size(emails) > _.size(_.get(profile, 'emails'))) {
      const element = document.getElementById(`email_${_.size(emails) - 1}`)
      if (element) {
        element.focus()
      }
    }
  }, [emails])

  useEffect(() => {
    if (_.size(phones) > _.size(_.get(profile, 'phones'))) {
      const element = document.getElementById(`phone_${_.size(phones) - 1}`)
      if (element) {
        element.focus()
      }
    }
  }, [phones])

  const renderAvatar = () => {
    const removeAvatar = () => {
      dispatch(removeAvatarFromUser())
    }

    const handleUpload = async () => {
      const f = await browseFiles('images', 50)
      if (!_.isNil(f)) {
        const avatar = _.get(f, 'url')
        const avatarSmall = _.get(f, 'thumbUrl')
        dispatch(saveUserProfile({ avatar, avatarSmall }, false))
      }
    }

    if (_.isNil(avatarUrl)) {
      return (
        <Box flex={false} direction='row' align='center' width='small' onClick={handleUpload}>
          <Box
            round='full'
            width='30px'
            height='30px'
            align='center'
            justify='center'
            margin={{ right: 'small' }}
            border={{ color: colors.VERY_LIGHT_PINK, size: 'xsmall', style: 'dashed', side: 'all' }}
          >
            <FontAwesomeIcon icon={faUserCircle} color={colors.VERY_LIGHT_PINK} size={12} />
          </Box>
          <Text color={colors.VERY_LIGHT_PINK}>Profile photo</Text>
        </Box>
      )
    } else {
      return (
        <Box flex={false} direction='row' align='center' onClick={removeAvatar} width='small'>
          <Box round='full' width='30px' height='30px' align='center' justify='center' margin={{ right: 'small' }}>
            <Avatar src={avatarUrl} />
          </Box>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>Remove photo</Text>
        </Box>
      )
    }
  }

  const removeFocus = () => {
    document.activeElement.blur()
  }

  const renderEmail = (email, index) => {
    const onChange = event => {
      const value = event.target.value
      const newEmails = [...emails]
      newEmails[index] = value
      setEmails(newEmails)
    }

    const onBlur = () => {
      if (!validator.isEmail(email)) {
        removeItem()
      } else {
        dispatch(updateUserProfile({ emails }))
      }
    }

    const removeItem = () => {
      if (_.size(emails) > _.size(_.get(profile, 'emails'))) {
        const newEmails = [...emails]
        newEmails.splice(index, 1)
        setEmails(newEmails)
      } else {
        const newEmails = _.get(profile, 'emails')
        newEmails.splice(index, 1)
        dispatch(updateUserProfile({ emails: newEmails }))
      }
    }

    return (
      <Box fill='horizontal' direction='row' flex={false} key={`email_${index}`}>
        <Box flex={false} width='85%' align='center'>
          <MaskedInput mask={emailMask} id={`email_${index}`} value={email} onChange={onChange} onBlur={onBlur} />
        </Box>
        <Box flex={false} width='15%' justify='center' align='center' onClick={() => null}>
          <FontAwesomeIcon icon={faTrash} color={colors.VERY_LIGHT_PINK} size={14} onClick={removeItem} />
        </Box>
      </Box>
    )
  }

  const renderPhone = (phone, index) => {
    const onChange = event => {
      const value = event.target.value
      const newPhones = [...phones]
      newPhones[index] = value
      setPhones(newPhones)
    }

    const onBlur = async () => {
      if (phone === '' || phone < PHONE_NUMBER_LENGTH) {
        removeItem()
      } else if (!_.isEqual(phones, _.get(profile, 'phones'))) {
        const phoneNumber = parsePhoneNumberFromString(phone, 'US')
        if (!_.includes(_.get(profile, 'phones', []), phoneNumber.number)) {
          const existingUid = await userIdByPhone(phoneNumber.number)
          if (_.isNil(existingUid)) {
            const newPhones = _.get(profile, 'phones', [])
            newPhones.push(phoneNumber.number)
            dispatch(updateUserProfile({ phones: newPhones }))
          } else {
            removeItem()
          }
        }
      }
    }

    const removeItem = () => {
      if (_.size(phones) > _.size(_.get(profile, 'phones'))) {
        const newPhones = [...phones]
        newPhones.splice(index, 1)
        setPhones(newPhones)
      } else {
        const newPhones = _.get(profile, 'phones')
        newPhones.splice(index, 1)
        dispatch(updateUserProfile({ phones: newPhones }))
      }
    }

    return (
      <Box fill='horizontal' direction='row' flex={false} key={`phone_${index}`}>
        <Box flex={false} width='85%' align='center'>
          <MaskedInput mask={phoneMask} id={`phone_${index}`} value={phone} onBlur={onBlur} onChange={onChange} />
        </Box>
        <Box flex={false} width='15%' justify='center' align='center' onClick={removeItem}>
          <FontAwesomeIcon icon={faTrash} color={colors.VERY_LIGHT_PINK} size={14} />
        </Box>
      </Box>
    )
  }

  const addEmail = () => {
    setEmails([...emails, ''])
  }

  const addPhone = () => {
    setPhones([...phones, ''])
  }

  const submitField = async type => {
    switch (type) {
      case 'title': {
        dispatch(updateUserProfile({ title }))
        break
      }
      case 'name': {
        dispatch(updateUserProfile({ name }))
        break
      }
      case 'email': {
        dispatch(updateUserProfile({ email }))
        break
      }
      case 'phone': {
        const phoneNumber = parsePhoneNumberFromString(phone, 'US')
        const existingUid = await userIdByPhone(phoneNumber.number)
        if (_.isNil(existingUid)) {
          dispatch(updateUserProfile({ phone }))
        } else {
          setPhone(formatPhone(_.get(profile, 'phone', '')))
        }
        break
      }
      default:
        break
    }
  }

  return (
    <ThemeContext.Extend value={{ formField: { round: '5px' } }}>
      <Keyboard onEnter={removeFocus}>
        <Box fill flex={false} gap='medium' overflow='scroll' pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
          {renderAvatar()}
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={false}>
            <Box fill>
              <FormField label='Name'>
                <TextInput value={name} onChange={e => setName(e.target.value)} onBlur={() => submitField('name')} />
              </FormField>
            </Box>
            <Box fill>
              <FormField label='Job title'>
                <TextInput value={title} onChange={e => setTitle(e.target.value)} onBlur={() => submitField('title')} />
              </FormField>
            </Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={false}>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Email' flex={false}>
                  <MaskedInput
                    mask={emailMask}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => submitField('email')}
                  />
                </FormField>
              </Box>
              {_.map(emails, renderEmail)}
              <Box width='xsmall'>
                <Button size='small' label='+ Add email' onClick={addEmail} />
              </Box>
            </Box>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Phone' flex={false}>
                  <MaskedInput
                    mask={phoneMask}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    onBlur={() => submitField('phone')}
                  />
                </FormField>
              </Box>
              {_.map(phones, renderPhone)}
              <Box width='xsmall'>
                <Button size='small' label='+ Add phone' onClick={addPhone} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Keyboard>
    </ThemeContext.Extend>
  )
}

const mapStateToProps = (state, props) => ({
  profile: getCurrentUserProfile(state)
})

export default connect(mapStateToProps)(Profile)
