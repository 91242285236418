import React from 'react'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const WorkOrderTitle = ({ title, id, onTradeClick, color, statusTitle, archived, isLast }) => {
  return (
    <Box align='center' direction='row' flex={false} key={id} justify='between' background={colors.WHITE}>
      <Box
        border={isLast ? [] : ['bottom']}
        justify='center'
        pad={{ left: 'medium' }}
        fill
        onClick={() => onTradeClick(id)}
        hoverIndicator={{ color: colors.WHITE_GREY }}
        customStyle={`
          position: relative;
          :hover {
            * .hover_indicator {
              display: flex;
              right: 2%;
              align-items: center;
            }
          }
        `}
      >
        <Box gap='small' direction='row' align='center'>
          <Box>
            <FontAwesomeIcon icon={faCircle} size={8} color={color} />
          </Box>
          <Box gap='xsmall'>
            <Text size='medium' color={archived ? colors.BADGE_BG : colors.TEXT}>
              {title}
            </Text>
            <Text size='small' color={color}>
              {statusTitle}
            </Text>
          </Box>
          <Box
            direction='row'
            justify='center'
            fill='vertical'
            className='hover_indicator'
            customStyle={`position: absolute; display: none; z-index: 10;`}
          >
            <Text size='xsmall' color={colors.MEDIUM_GREY}>
              Leveling Table
            </Text>
            <FontAwesomeIcon icon={faAngleRight} size={12} color={colors.MEDIUM_GREY} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WorkOrderTitle
