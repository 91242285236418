import React from 'react'
import { Box, Button, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEdit, faSlidersHSquare, faUserPlus, faQuestionCircle, faFileImport } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import DropDownMenu from 'components/DropDownMenu'
import lineItemType from 'shared/constants/lineItemType'

const CreateBidSubMenu = ({ addLineItem, addQuestion, addSection, addLineItemFromTemplate, addGEItem, disabled }) => {
  const addLineItemButton = (
    <Button
      icon={<FontAwesomeIcon icon={faEdit} />}
      label='Add line item'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addLineItem}
      disabled={disabled}
    />
  )

  const addQuestionButton = (
    <DropDownMenu
      options={[
        {
          id: 'lineItem_title_id',
          label: 'Question type',
          disabled: true
        },
        {
          id: lineItemType.QUESTION_BOOL,
          label: 'Yes / No response'
        },
        {
          id: lineItemType.QUESTION,
          label: 'Text response'
        }
      ]}
      dropContentProps={{ boxProps: { width: { min: 'small' } } }}
      dropAlign={{ top: 'bottom', left: 'left' }}
      onOptionClick={id => addQuestion(id)}
      disabled={disabled}
    >
      <Box
        round='xxsmall'
        direction='row'
        justify='between'
        align='center'
        gap='small'
        onClick={() => null}
        hoverIndicator
        pad={{
          vertical: 'xsmall',
          horizontal: 'small'
        }}
        customStyle={`
          :hover {
            * {
              color: ${colors.TEXT};
              fill: ${colors.TEXT};
            }
          }
        `}
      >
        <FontAwesomeIcon icon={faQuestionCircle} color={colors.ASANA_GRAY_TEXT_HOVERED} />
        <Text color={colors.ASANA_GRAY_TEXT_HOVERED}>Add question</Text>
      </Box>
    </DropDownMenu>
  )

  const addSectionButton = (
    <Button
      icon={<FontAwesomeIcon icon={faSlidersHSquare} />}
      label='Add section'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addSection}
      disabled={disabled}
    />
  )

  const addFromTemplateButton = (
    <Button
      icon={<FontAwesomeIcon icon={faUserPlus} />}
      label='Add from template'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addLineItemFromTemplate}
      disabled={disabled}
    />
  )

  const addGeneralExclusion = (
    <Button
      icon={<FontAwesomeIcon icon={faFileImport} />}
      label='Add General Exclusion'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addGEItem}
      disabled={disabled}
    />
  )

  return (
    <Box
      fill='horizontal'
      direction='row'
      height='40px'
      background={colors.WHITE_GREY}
      align='center'
      justify='between'
      pad={{ left: 'small', right: 'small' }}
    >
      <Box direction='row' align='center' gap='small'>
        {addLineItemButton}
        {addQuestionButton}
        {addSectionButton}
        {addFromTemplateButton}
        {addGeneralExclusion}
      </Box>
    </Box>
  )
}

export default CreateBidSubMenu
