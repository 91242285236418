import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSms, faPaperclip } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import { Box, Tip, Text, Button } from 'grommet'

import messageStatus from 'shared/constants/bidInvitationStatus'
import { copyToClipboard } from 'shared/controllers/input'
import colors from 'shared/constants/colors'
import { formatNational } from 'shared/utils/phone'
import config from 'shared/config'

const UserRow = ({ user, borderTop, userProfile, invId }) => {
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy Link')

  const userInvId = _.get(user, 'invId')
  const userId = _.get(user, 'id')
  const invUrl = `${_.get(config, 'invitationUrl')}/i/${`${userInvId}?notrack=true` || `${invId}?uid=${userId}&notrack=true`}`
  const jobTitle = _.get(userProfile, 'title', '')
  const emails = _.get(user, 'emails', [])
  let phones = _.get(user, 'phones', [])
  const status = _.get(user, 'status', '')
  const isEmptyStatusEmail = _.isNil(_.get(user, 'status')) || !_.get(user, 'status.statusSendgrid', false)
  const isEmptyStatusSms = _.isNil(_.get(user, 'status')) || !_.get(user, 'status.statuTwilio', false)
  let statusBackColor = ''
  let statusTitle = ''
  switch (status.statusSendgrid) {
    case messageStatus.SENT:
      statusBackColor = colors.BADGE_BG
      statusTitle = 'Sent'
      break
    case messageStatus.DELIVERED:
      statusBackColor = colors.BADGE_BG
      statusTitle = 'Sent'
      break
    case messageStatus.OPENED:
      statusBackColor = colors.LIGHT_NAVY_BRIGHT
      statusTitle = 'Seen'
      break
    case messageStatus.CLICKED:
      statusBackColor = colors.AQUA_MARINE
      statusTitle = 'Opened'
      break
    case messageStatus.UNDELIVERABLE:
      statusBackColor = colors.CORAL_TWO
      statusTitle = 'Failed'
      break
    default:
      statusBackColor = colors.CORAL_TWO
      statusTitle = 'Failed'
      break
  }
  let statusTwilio = false
  switch (status.statusTwilio) {
    case messageStatus.SENT:
      statusTwilio = true
      break
    case messageStatus.DELIVERED:
      statusTwilio = true
      break
    case messageStatus.OPENED:
      statusTwilio = true
      break
    case messageStatus.CLICKED:
      statusTwilio = true
      break
    case messageStatus.UNDELIVERABLE:
      statusTwilio = false
      break
    default:
      statusTwilio = false
      break
  }
  phones = _.map(phones, phone => formatNational(phone))
  const Sms = (
    <Box margin={{ left: 'small' }} style={{ opacity: statusTwilio ? 1 : 0.25 }}>
      <FontAwesomeIcon icon={faSms} size={24} color={statusTwilio ? colors.BADGE_BG : colors.VERY_LIGHT_PINK} />
    </Box>
  )
  const SmsWithTip = <Tip message='SMS not delivered'>{Sms}</Tip>
  const isCopied = copyButtonLabel === 'Copied!'
  return (
    <Box
      justify='center'
      border={borderTop}
      pad='small'
      onClick={() => {
        copyToClipboard(invUrl)
        setCopyButtonLabel('Copied!')
        setTimeout(() => {
          setCopyButtonLabel('Copy Link')
        }, 2500)
      }}
      hoverIndicator={{ background: colors.AQUA_MARINE10 }}
      customStyle={`
          position: relative;
          .copy_link_button {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            display: ${isCopied ? 'flex' : 'none'};
          }
          :hover .copy_link_button {
            display: flex;
          }
        `}
    >
      <Box flex='grow' justify='center'>
        <Box direction='row' justify='between' align='center'>
          <Box direction='row' justify='center' align='center'>
            <Text truncate color={colors.TEXT_PRIMARY} size='small'>
              {user.name}
            </Text>
            {!isEmptyStatusEmail && (
              <Box
                background={statusBackColor}
                margin={{ left: 'small' }}
                round
                justify='center'
                align='center'
                width='80px'
                height='23px'
              >
                <Text size='xsmall' color={colors.WHITE}>
                  {statusTitle}
                </Text>
              </Box>
            )}
            {!isEmptyStatusSms && (statusTwilio ? Sms : SmsWithTip)}
          </Box>
          <Text truncate color={colors.DARK_GRAY_TWO} size='small'>
            {jobTitle}
          </Text>
        </Box>
        <Text truncate color={colors.VERY_LIGHT_PINK} size='small' margin={{ top: 'xxsmall' }}>
          {_.concat(emails, phones).join(', ')}
        </Text>
        {(invId || userInvId) && (
          <Button
            plain
            className='copy_link_button'
            label={
              <Box direction='row' align='center' gap='xsmall'>
                {!isCopied && <FontAwesomeIcon icon={faPaperclip} color={colors.LINK} size={12} />}
                <Text size='small' color={colors.LINK} weight={600}>{copyButtonLabel}</Text>
              </Box>
            }
          />
        )}
      </Box>
    </Box>
  )
}

export default UserRow
