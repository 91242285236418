import React, { useContext, useEffect, useCallback } from 'react'
import { Box, Button } from 'grommet'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'

// import history from 'shared/utils/history'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import PreconContext from 'webPages/precon/PreconContext'
import config from 'shared/config'
import Logo from 'components/logos/LogoOnLight'
import colors from 'shared/constants/colors'

const PreconOrderDetails = ({ projectId, queryParams }) => {
  const userId = useSelector(state => _.get(state, 'user.id'))
  const { goToNextScreen } = useContext(PreconContext)

  const goNext = useCallback(() => {
    goToNextScreen(screens.PRECON_ORDER_DETAILS)
  }, [goToNextScreen])

  const onMessage = useCallback(
    event => {
      const msg = _.get(event, 'data')
      if (msg === 'onPayClick') {
        goNext()
      }
    },
    [goNext]
  )

  useEffect(() => {
    window.addEventListener('message', onMessage, false)
    return () => window.removeEventListener('message', onMessage)
  }, [onMessage])

  const goBack = () => {
    universalNavigation.navigate(screens.PRECON_PROJECT, { projectId })
  }

  return (
    <Box align='start' fill>
      <Box
        pad={{ vertical: 'small', horizontal: 'medium' }}
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        // margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
        <Button icon={<FontAwesomeIcon icon={faEdit} />} label='Edit project' color={colors.LINK} onClick={goBack} />
      </Box>
      <Box fill>
        <iframe
          style={{ width: '100%', height: '100%' }}
          src={`${config.retoolServicesPage}#projectId=${projectId}&userId=${userId}`}
          title='retool'
          frameBorder={0}
        />
      </Box>
    </Box>
  )
}

export default PreconOrderDetails
