import { auth, db, firestore } from 'constants/firebase'
import generate from 'firebase-auto-ids'
import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

export async function sendMessage (msg, channelKey) {
  const text = _.get(msg, 'text', null)
  const source = _.get(msg, 'source', null)
  const sourceThumb = _.get(msg, 'sourceThumb', null)
  try {
    const message = {
      id: msg._id,
      userId: auth.currentUser.uid,
      text,
      source,
      sourceThumb,
      timestamp: firestore.FieldValue.serverTimestamp(),
      type: msg.type || null,
      file: _.get(msg, 'file', null)
    }
    await db
      .collection('channels')
      .doc(channelKey)
      .collection('messages')
      .doc(message.id)
      .set(_.omitBy(message, _.isNil))
  } catch (e) {
    console.log('sendMessage error:', e.message)
    Sentry.captureException(e)
  }
}

export async function updateMessage (channelKey, messageId, k, v) {
  try {
    await db
      .collection('channels')
      .doc(channelKey)
      .collection('messages')
      .doc(messageId)
      .update({ [k]: v })
  } catch (e) {
    console.log('Update Message Error: ', e.message)
    Sentry.captureException(e)
  }
}

export async function sendDocMessage (channelKey, text, doc) {
  try {
    const message = {
      id: generate(_.now()),
      userId: auth.currentUser.uid,
      text,
      timestamp: firestore.FieldValue.serverTimestamp(),
      doc
    }
    await db
      .collection('channels')
      .doc(channelKey)
      .collection('messages')
      .doc(message.id)
      .set(_.omitBy(message, _.isNil))
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendDocMessage error:', e.message)
  }
}

export async function addSystemMessage (channelKey, text) {
  try {
    const message = {
      id: generate(_.now()),
      text,
      timestamp: firestore.FieldValue.serverTimestamp(),
      system: true
    }
    await db
      .collection('channels')
      .doc(channelKey)
      .collection('messages')
      .doc(message.id)
      .set(_.omitBy(message, _.isNil))
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendMessage error:', e.message)
  }
}
