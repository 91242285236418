import React from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const ACTIVE_BORDER = { side: 'bottom', color: colors.AQUA_MARINE, size: 'xsmall' }
const NOT_ACTIVE_BORDER = { side: 'bottom', color: 'transparent', size: 'xsmall' }

const NavBar = ({ activeIndex, isEmptySow, workOrderId, projectId }) => {
  if (activeIndex === 'info' && !isEmptySow) {
    return null
  }
  return (
    <Box direction='row' gap='large' justify='center' align='center' pad={{ top: 'small', bottom: 'medium' }}>
      <Box
        pad={{ horizontal: 'xlarge', bottom: 'small' }}
        onClick={() => universalNavigation.push(screens.SUB_CREATE_BID, { workOrderId, projectId }, { mode: 'scope' })}
        border={_.isEqual(activeIndex, 'scope') ? ACTIVE_BORDER : NOT_ACTIVE_BORDER}
      >
        <Text color={_.isEqual(activeIndex, 'scope') ? colors.AQUA_MARINE : colors.ANOTHER_GREY}>{isEmptySow ? 'Project info' : 'Scope'}</Text>
      </Box>
      <Box
        pad={{ horizontal: 'xlarge', bottom: 'small' }}
        onClick={() => universalNavigation.push(screens.SUB_CREATE_BID, { workOrderId, projectId }, { mode: 'files' })}
        border={_.isEqual(activeIndex, 'files') ? ACTIVE_BORDER : NOT_ACTIVE_BORDER}
      >
        <Text color={_.isEqual(activeIndex, 'files') ? colors.AQUA_MARINE : colors.ANOTHER_GREY}>Plan files</Text>
      </Box>
    </Box>
  )
}

export default NavBar
