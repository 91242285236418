import React from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'
import CellContainer from 'components/leveling/CellContainer'
import MessagesIcon from 'components/leveling/MessagesIcon'
import StatusPendingAnswer from 'components/leveling/cellElements/StatusPendingAnswer'

const AnswerBool = ({ answer, selected, onClick, hasMessages, hasNewMessage, compact }) => {
  if (!_.isNil(answer)) {
    const answerColor = answer ? colors.LIGHT_NAVY_BRIGHT : colors.ALMOST_BLACK
    if (compact) {
      return (
        <CellContainer selected={selected} onClick={onClick} fill align='center' direction='row'>
          <Box
            direction='row'
            justify='center'
            gap='xsmall'
            align='center'
            width='63px'
          >
            <FontAwesomeIcon icon={answer ? faThumbsUp : faThumbsDown} color={answerColor} size={10} />
            <Text weight={600} color={answerColor} size='small'>
              {answer ? 'Yes' : 'No'}
            </Text>
          </Box>
          <Box direction='row' justify='center' align='center'>
            {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} size={14} customStyle={null} />}
          </Box>
        </CellContainer>
      )
    }
    return (
      <CellContainer selected={selected} onClick={onClick}>
        <Box direction='row' align='start'>
          <Box
            direction='row'
            pad={{ top: 'xsmall' }}
            justify='center'
            alignSelf='center'
            gap='xsmall'
            align='center'
            // background={bgColor}
            width='63px'
          >
            <FontAwesomeIcon icon={answer ? faThumbsUp : faThumbsDown} color={answerColor} size={10} />
            <Text weight={600} color={answerColor} size='small'>
              {answer ? 'Yes' : 'No'}
            </Text>
          </Box>
        </Box>
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} />}
      </CellContainer>
    )
  } else {
    return (
      <CellContainer selected={selected} onClick={onClick}>
        <Box fill direction='row' align='start'>
          <StatusPendingAnswer />
        </Box>
      </CellContainer>
    )
  }
}

export default AnswerBool
