import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Text,
  Button,
  Avatar,
  Keyboard,
  TextArea,
  ThemeContext,
  TextInput,
  CheckBox,
  Tip,
  DropButton
} from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faProjectDiagram, faAngleDown, faCheck } from '@fortawesome/pro-light-svg-icons'
import { faProjectDiagram as faProjectDiagramSolid } from '@fortawesome/pro-solid-svg-icons'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import { sendMessage } from 'controllers/chat'
import { updateLineItem } from 'controllers/workOrder'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import LevelingPanelHeaderContainer from 'components/leveling/levelingPanel/LevelingPanelHeaderContainer'
import messageType from 'constants/messageType'
import { getInitials } from 'shared/utils/stringUtils'
import { getLevelingTableMessages } from 'model/selectors/channels'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import Messages from 'components/leveling/levelingPanel/lineItemPanelContent/Messages'
import ItemPriceRow from 'components/leveling/ItemPriceRow'
import InputWithSelect from 'components/InputWithSelect'
import quantityTypes from 'shared/constants/quantityTypes'

const FIELDS_TYPES = {
  name: 'name',
  description: 'description',
  quantity: 'quantity',
  quantityType: 'quantityType',
  required: 'required',
  labor: 'labor'
}

const StyledText = styled(Text)`
  line-height: 1;
`

const LineItemPanelContent = ({ item, itemId, onClose, userProfile, workOrder, record, messages, workOrderId }) => {
  const [name, setName] = useState('')
  const [quantity, setQuantity] = useState('')
  const [quantityType, setQuantityType] = useState('')
  const [description, setDescription] = useState('')
  const [required, setRequired] = useState(false)
  const [labor, setLabor] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [altOpen, setAltOpen] = useState(false)
  const [selectedAlt, setSelectedAlt] = useState(null)

  const updateValues = useCallback(v => {
    const quantityUnformatted = _.get(v, 'quantity', null)
    const quantityFormatted = _.isNil(quantityUnformatted) ? '' : numeral(quantityUnformatted).format('0,0.[00]')
    setName(_.get(v, 'name', ''))
    setQuantity(quantityFormatted)
    setQuantityType(_.get(v, 'quantityType', ''))
    setDescription(_.get(v, 'desc', ''))
    setRequired(_.get(v, 'required', false))
    setLabor(_.get(v, 'labor', false))
  }, [])

  const resetToDefault = useCallback(() => {
    if (!_.isNil(item)) {
      updateValues(item)
    }
    setUpdated(false)
    setSelectedAlt(null)
    setAltOpen(false)
  }, [item, updateValues])

  useEffect(() => {
    resetToDefault()
  }, [itemId, resetToDefault])

  useEffect(() => {
    if (!_.isNil(selectedAlt)) {
      const alternate = _.get(item, ['alternates', selectedAlt])
      updateValues(alternate)
    } else {
      resetToDefault()
    }
  }, [selectedAlt, item, resetToDefault, updateValues])

  const [message, setMessage] = useState('')
  const [focused, setFocused] = useState(false)

  const getItemName = () => {
    const selectedLi = !_.isNil(selectedAlt) ? _.get(item, ['alternates', selectedAlt]) : item
    const name = _.get(selectedLi, 'name')
    if (_.size(name) < 20) {
      return (
        <Text size='large' color={colors.TEXT_PRIMARY} weight={800}>
          {name}
        </Text>
      )
    } else {
      return (
        <Tip content={name}>
          <Text size='large' color={colors.TEXT_PRIMARY} weight={800}>
            {_.truncate(name, { length: 20 })}
          </Text>
        </Tip>
      )
    }
  }

  const alternatesLabel = () => {
    const active = false
    const color = active ? colors.LIGHT_NAVY_BRIGHT : colors.DARK_GRAY_TWO
    return (
      <Box direction='row' fill='vertical' align='center' justify='start' flex={false} pad={{ top: 'xxsmall' }}>
        <FontAwesomeIcon icon={active ? faProjectDiagramSolid : faProjectDiagram} size={14} color={color} />
        <FontAwesomeIcon icon={faAngleDown} size={16} color={color} />
      </Box>
    )
  }

  const checkmarkInCircle = (
    <Box
      flex={false}
      align='center'
      justify='center'
      background={colors.AQUA_MARINE}
      round='full'
      width='20px'
      height='20px'
    >
      <FontAwesomeIcon icon={faCheck} color={colors.WHITE} size={12} />
    </Box>
  )

  const handleSelect = id => {
    if (_.isEqual(id, itemId)) {
      setSelectedAlt(null)
    } else {
      setSelectedAlt(id)
    }
    setAltOpen(false)
  }

  const renderAlternates = alternates =>
    _.map([item, ...alternates], alt => {
      const active = selectedAlt === alt.id || (_.isNil(selectedAlt) && alt.id === item.id)
      return (
        <Box
          key={_.get(alt, 'id')}
          pad={{ horizontal: 'small', vertical: 'small' }}
          round='none'
          onClick={() => handleSelect(_.get(alt, 'id'))}
          hoverIndicator
          border={{ side: 'bottom' }}
          width='small'
        >
          <Box direction='row' align='center' gap='xsmall'>
            {active && checkmarkInCircle}
            <StyledText size='small'>{_.get(alt, 'name')}</StyledText>
          </Box>
          <Box direction='row' align='center' justify='between' pad={{ top: 'xsmall' }}>
            <ItemPriceRow quantity={_.get(alt, 'quantity', '')} quantityType={_.get(alt, 'quantityType')} compact />
          </Box>
        </Box>
      )
    })

  const alternatesSelect = alternates => {
    return (
      <DropButton
        plain
        open={altOpen}
        onClose={() => setAltOpen(false)}
        label={alternatesLabel()}
        dropAlign={{ top: 'bottom', left: 'right' }}
        dropProps={{ style: { overflow: 'visible' } }}
        dropContent={<Box>{renderAlternates(alternates)}</Box>}
        onClick={() => setAltOpen(!altOpen)}
      />
    )
  }

  const onSubmit = () => {
    const rest = _.isNil(selectedAlt) ? item : _.get(item, ['alternates', selectedAlt])
    const submitItem = _.omitBy(
      {
        ...rest,
        name,
        quantity: _.size(quantity) > 0 ? numeral(quantity).value() : null,
        quantityType,
        desc: description,
        required,
        labor
      },
      _.isNil
    )
    updateLineItem(workOrderId, itemId, submitItem, selectedAlt)
    onClose()
  }

  const getAlternatesStatus = size => {
    if (!_.isNil(selectedAlt)) {
      return (
        <Box direction='row' gap='small' align='center' pad={{ vertical: 'xxsmall' }}>
          <Text>Alternate selected</Text>
          <Button
            plain
            label='reset'
            onClick={() => setSelectedAlt(null)}
            customStyle={`color: ${colors.CLEAR_BLUE}`}
          />
        </Box>
      )
    } else if (size > 1) {
      return (
        <Box justify='center' pad={{ vertical: 'xxsmall' }}>
          <Text>{size} alternates</Text>
        </Box>
      )
    } else {
      return (
        <Box justify='center' pad={{ vertical: 'xxsmall' }}>
          <Text>{size} alternate</Text>
        </Box>
      )
    }
  }

  const renderHeader = () => {
    const alternates = _.values(_.get(item, 'alternates', {}))
    const alternatesPresented = _.size(alternates) > 0
    return (
      <LevelingPanelHeaderContainer onClose={onClose} skipCloseRender={!_.isNil(_.get(record, 'unapproved'))}>
        <Box flex={{ grow: 1 }} direction='row' justify='between' align='center' gap='small'>
          <Box height='xxsmall' justify='center'>
            <Box direction='row' align='center' gap='small'>
              {getItemName()}
              {alternatesPresented && alternatesSelect(alternates)}
            </Box>
            {alternatesPresented && getAlternatesStatus(_.size(alternates))}
          </Box>
          {updated && (
            <Box margin={{ left: 'auto' }}>
              <Button onClick={onSubmit} color={colors.AQUA_MARINE} primary label='Update Scope' />
            </Box>
          )}
        </Box>
      </LevelingPanelHeaderContainer>
    )
  }

  const renderContent = () => {
    return (
      <Box background={colors.PALE_GREY} direction='row' justify='between' height='xxsmall' align='center' pad='small'>
        <Text size='medium' weight='bold' color={colors.BLACK}>
          Broadcast comment
        </Text>
        <Text size='xsmall' color={colors.ANOTHER_GREY}>
          Send comment to all invited subcontractors
        </Text>
      </Box>
    )
  }

  const renderMessages = () => {
    return <Messages workOrder={workOrder} messages={messages} acceptedBy={_.keys(_.get(workOrder, 'acceptedBy'))} />
  }

  const renderFooter = () => {
    const handleFocus = () => {
      setFocused(true)
    }

    const handleBlur = e => {
      const { relatedTarget } = e
      if (_.get(relatedTarget, 'id') === 'send_message') {
        updateMessages()
        setFocused(false)
      } else {
        setFocused(false)
      }
    }

    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
          updateMessages()
        } else {
          setMessage(message + '\n')
        }
      }
    }

    const updateMessages = () => {
      if (message !== '') {
        const accountId = _.get(workOrder, 'accountId')
        const workOrderId = _.get(workOrder, 'id')
        const userId = _.get(userProfile, 'id')
        const accounts = _.get(workOrder, 'accounts')
        const params = {
          text: message,
          accounts,
          accountId,
          userId,
          workOrderId,
          itemId,
          type: messageType.ANNONCEMENT,
          projectId: _.get(workOrder, 'projectId')
        }
        sendMessage(params)
        setMessage('')
        document.activeElement.blur()
      }
    }

    const onChange = event => {
      const newMessage = event.target.value
      setMessage(newMessage)
    }

    const textInputThemeValue = {
      textArea: {
        extend: css`
          background: ${colors.WHITE};
        `
      }
    }

    return (
      <Box
        background={colors.PALE_GREY}
        direction='row'
        align='center'
        height={focused ? { min: '115px' } : { min: '60px' }}
        pad={focused ? { bottom: 'small' } : 'none'}
      >
        <Box fill='vertical' justify='start' pad='small' width={{ min: '55px' }}>
          <Avatar size='32px' src={_.get(userProfile, 'avatar')}>
            {getInitials(userProfile)}
          </Avatar>
        </Box>
        <Box
          fill
          margin={{ right: 'small' }}
          background={colors.WHITE}
          customStyle={`
            height: ${focused ? '90px' : '40px'};
            border-radius: 5px;
            padding-top: ${focused ? '6px' : '0'};
          `}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <ThemeContext.Extend value={textInputThemeValue}>
            <Keyboard onKeyDown={handleKeyDown}>
              <TextArea plain onChange={onChange} value={message} size='small' resize={false} />
            </Keyboard>
          </ThemeContext.Extend>
          {focused && (
            <Box
              direction='row'
              gap='small'
              align='center'
              customStyle={`
                position: absolute;
                bottom: 20px;
                right: 15px;
                animation: slide-up 0.5s ease;
                @keyframes slide-up {
                  0% {
                      opacity: 0;
                  }
                  100% {
                      opacity: 1;
                  }
                }
              `}
            >
              <Box width='xsmall'>
                <Button
                  disabled={!focused}
                  id='send_message'
                  fill
                  size='small'
                  primary
                  label='Comment'
                  color={colors.AQUA_MARINE}
                  onClick={() => updateMessages()}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  const handleChange = type => e => {
    let value = _.get(e, 'target.value')
    if (!updated) setUpdated(true)
    switch (type) {
      case FIELDS_TYPES.name: {
        setName(value)
        break
      }
      case FIELDS_TYPES.description: {
        setDescription(value)
        break
      }
      case FIELDS_TYPES.quantity: {
        setQuantity(numeral(value).format('0,0.[00]'))
        break
      }
      case FIELDS_TYPES.quantityType: {
        value = e.value
        setQuantityType(value)
        break
      }
      case FIELDS_TYPES.required: {
        value = _.get(e, 'target.checked')
        setRequired(value)
        break
      }
      case FIELDS_TYPES.labor: {
        value = _.get(e, 'target.checked')
        setLabor(value)
        break
      }
    }
  }

  const renderItemDetails = () => {
    return (
      <Box pad='small' gap='small' overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
        <Box direction='row' align='center' justify='between' flex={false}>
          <Box>
            <Text color={colors.TEXT} size='medium'>
              Line item name
            </Text>
          </Box>
          <Box width='small'>
            <TextInput value={name} onChange={handleChange(FIELDS_TYPES.name)} />
          </Box>
        </Box>
        <Box direction='row' align='center' justify='between' flex={false}>
          <Box>
            <Text color={colors.TEXT} size='medium'>
              Quantity
            </Text>
          </Box>
          <Box width='small'>
            <InputWithSelect
              onFocus={() => null}
              onChange={handleChange(FIELDS_TYPES.quantity)}
              onBlur={() => null}
              onSelectChange={handleChange(FIELDS_TYPES.quantityType)}
              value={quantity}
              selectValue={quantityType}
              options={quantityTypes}
              placeholder=''
              inputRef={null}
              id='plug-quantity'
              dropProps={{ className: IGNORE_OUTSIDE_CLICK_CLASS }}
              alwaysShow
              controlProps={{ border: { color: colors.VERY_LIGHT_GREY_TWO, radius: '4px' } }}
              showBorderOnFocus={false}
              textInputProps={{
                extend: css`
                  text-align: start;
                `
              }}
            />
          </Box>
        </Box>
        <Box direction='row' align='center' justify='between' flex={false}>
          <Box margin={{ bottom: 'auto' }} pad={{ top: 'xsmall' }}>
            <Text color={colors.TEXT} size='medium'>
              Description
            </Text>
          </Box>
          <Box width='small'>
            <TextArea value={description} onChange={handleChange(FIELDS_TYPES.description)} />
          </Box>
        </Box>
        <Box direction='row' align='center' justify='between' flex={false}>
          <Box>
            <Text color={colors.TEXT} size='medium'>
              Required
            </Text>
          </Box>
          <Box width='small' justify='center' align='end' pad={{ right: 'small' }}>
            <ThemeContext.Extend
              value={{
                checkBox: {
                  color: colors.CORAL_TWO,
                  size: '18px',
                  hover: {
                    border: {
                      color: colors.CORAL_TWO
                    }
                  }
                }
              }}
            >
              <CheckBox checked={required} onChange={handleChange(FIELDS_TYPES.required)} />
            </ThemeContext.Extend>
          </Box>
        </Box>
        <Box direction='row' align='center' justify='between' flex={false}>
          <Box>
            <Text color={colors.TEXT} size='medium'>
              Labor only
            </Text>
          </Box>
          <Box width='small' justify='center' align='end' pad={{ right: 'small' }}>
            <ThemeContext.Extend
              value={{
                checkBox: {
                  size: '18px'
                }
              }}
            >
              <CheckBox checked={labor} onChange={handleChange(FIELDS_TYPES.labor)} />
            </ThemeContext.Extend>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={IGNORE_OUTSIDE_CLICK_CLASS}
      fill
      flex
      customStyle={`
        display: grid;
        grid-template-rows: 1.3fr 1fr;
      `}
    >
      <Box fill>
        {renderHeader()}
        {renderItemDetails()}
      </Box>
      <Box flex>
        {renderMessages()}
        <Box>
          {renderContent()}
          {renderFooter()}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state, props) => ({
  userProfile: _.get(state, ['profiles', _.get(state, 'user.id')]),
  workOrder: getWorkOrder(state, props),
  messages: _.get(getLevelingTableMessages(state), ['announcements', props.itemId], [])
})

export default connect(mapStateToProps)(LineItemPanelContent)
