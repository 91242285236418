import * as types from 'model/actionTypes'

const initialState = null

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_APPOINTMENTS_OFFERS:
      if (action.isPending) {
        return {
          ...state,
          pending: action.appointmentsOffers
        }
      } else {
        return {
          ...state,
          missing: action.appointmentsOffers
        }
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
