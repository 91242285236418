import React from 'react'
import { Box } from 'grommet'

const DropContentContainer = ({ children, boxProps, ...rest }) => {
  return (
    <Box width='medium' {...boxProps} {...rest}>
      {children}
    </Box>
  )
}

export default DropContentContainer
