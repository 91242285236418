import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import Checkbox from 'components/Toggle'

const ToggleRow = ({ title, checked, onChange }) => {
  return (
    <Box direction='row' align='center' justify='between' flex={{ shrink: 0 }} height='36px'>
      <Text>{title}</Text>
      <Checkbox value={checked} onChange={onChange} />
    </Box>
  )
}

ToggleRow.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleRow
