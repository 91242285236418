const SIGN_IN = 'SIGN_IN'
const SIGN_UP = 'SIGN_UP'
const SUB_PROPOSAL = 'SUB_PROPOSAL'
const BID_INVITE = 'BID_INVITE'

export default {
  SIGN_IN,
  SIGN_UP,
  SUB_PROPOSAL,
  BID_INVITE
}
