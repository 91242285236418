import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { getName } from 'shared/utils/stringUtils'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { formatNational } from 'shared/utils/phone'
import { getAccountsProfiles } from 'model/selectors/profiles'

class SubsListModeContent extends Component {
  renderHeader = () => {
    return (
      <Box direction='row' align='center' justify='between' pad={{ horizontal: 'small', bottom: 'medium' }}>
        <Text size='large' weight={600}>
          Select sub to manually add a bid for
        </Text>
      </Box>
    )
  }

  renderItem = accId => {
    const { accountsProfiles, onSubClick, invitations } = this.props
    const profile = _.get(accountsProfiles, accId)
    const details = _.omitBy([_.get(profile, 'email'), formatNational(_.get(profile, 'phone'))], _.isNil)
    const name = getName(profile)
    // const name = profile ? getName(profile) : _.get(_.find(invitations, inv => inv.id === accId), 'companyName')
    return (
      <Box
        flex={{ shrink: 0 }}
        border={{ side: 'bottom' }}
        pad='small'
        onClick={() => onSubClick(accId)}
        hoverIndicator
        key={accId}
      >
        <Text color={colors.LIGHT_NAVY_BRIGHT}>{name}</Text>
        <Text color={colors.LIGHT_NAVY_GREY} size='small'>
          {_.join(_.values(details), ', ')}
        </Text>
      </Box>
    )
  }

  renderInviteNew = () => {
    const { onNewClick } = this.props
    return (
      <Box
        border={{ side: 'bottom' }}
        pad='small'
        height={{ min: '59px' }}
        onClick={onNewClick}
        hoverIndicator
        direction='row'
        key='addNew'
        align='center'
      >
        <FontAwesomeIcon icon={faPlus} color={colors.LIGHT_NAVY_BRIGHT} size={14} />
        <Text weight={600} color={colors.LIGHT_NAVY_BRIGHT} margin={{ left: 'small' }}>
          Add new sub
        </Text>
      </Box>
    )
  }

  renderContent = () => {
    const { invitationsWithNoBids } = this.props
    const items = _.map(invitationsWithNoBids, this.renderItem)
    // console.log('items length', _.size(items))
    items.push(this.renderInviteNew())
    return items
  }

  render () {
    // console.log('accountsProfiles', this.props.accountsProfiles)
    return (
      <Box width={{ min: '400px' }} height={{ min: '435px' }} pad={{ top: 'medium' }} responsive={false}>
        {this.renderHeader()}
        {this.renderContent()}
      </Box>
    )
  }
}

SubsListModeContent.defaultProps = {}

SubsListModeContent.propTypes = {
  invitationsWithNoBids: PropTypes.array,
  onSubClick: PropTypes.func,
  onNewClick: PropTypes.func
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: getAccountsProfiles(state)
})

export default connect(mapStateToProps)(SubsListModeContent)
