import React from 'react'
import { Layer, Card, Box, Text, CardHeader, CardBody, Select, CheckBox, TextArea, Button, ThemeContext } from 'grommet'
import _ from 'lodash'
import moment from 'moment-timezone'

import colors from 'shared/constants/colors'
import DatePicker from 'components/DatePicker'
import { scheduleReminder, cancelReminder } from 'controllers/workOrder'

const DATES_VALUES = {
  tomorrow_9am: 'tomorrow_9am',
  tomorrow_2pm: 'tomorrow_2pm',
  two_days: '2_days',
  four_days: '4_days',
  one_week: '1_week',
  two_weeks: '2_weeks',
  custom: 'custom'
}

const DATES = [
  { label: '10 minutes', value: 10 * 60 * 1000, relative: true },
  { label: '20 minutes', value: 20 * 60 * 1000, relative: true },
  { label: '30 minutes', value: 30 * 60 * 1000, relative: true },
  { label: '40 minutes', value: 40 * 60 * 1000, relative: true },
  { label: '50 minutes', value: 50 * 60 * 1000, relative: true },
  { label: '1 hour', value: 60 * 60 * 1000, relative: true },
  { label: '1 hour and a half', value: 90 * 60 * 1000, relative: true },
  { label: '2 hours', value: 120 * 60 * 1000, relative: true },
  { label: '3 hours', value: 180 * 60 * 1000, relative: true },
  { label: '4 hours', value: 240 * 60 * 1000, relative: true },
  { label: 'Tomorrow 9am',
    value: {
      add: {
        value: 1,
        type: 'day'
      },
      set: { hour: 9, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: 'Tomorrow 2pm',
    value: {
      add: {
        value: 1,
        type: 'day'
      },
      set: { hour: 14, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: '2 days',
    value: {
      add: {
        value: 2,
        type: 'days'
      },
      set: { hour: 12, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: '4 days',
    value: {
      add: {
        value: 4,
        type: 'days'
      },
      set: { hour: 12, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: '1 week',
    value: {
      add: {
        value: 7,
        type: 'days'
      },
      set: { hour: 12, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: '2 weeks',
    value: {
      add: {
        value: 14,
        type: 'days'
      },
      set: { hour: 12, minute: 0, second: 0, millisecond: 0 }
    },
    relative: false },
  { label: 'Custom', value: DATES_VALUES.custom, relative: false }
]

const DEFAULT_STATE = {
  open: false,
  workOrderId: null,
  invId: null,
  selectedDate: { value: null },
  date: null,
  sendTo: {
    email: true,
    sms: true
  },
  message: ''
}

class ScheduledReminderModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = DEFAULT_STATE
  }

  open = (workOrderId, invId, userId, reminder = null) => {
    let newState = {
      open: true,
      workOrderId,
      invId,
      timeZone: moment.tz.guess(),
      userId
    }
    if (!_.isNil(reminder)) {
      newState = { ...newState, ...reminder, selectedDate: { value: DATES_VALUES.custom } }
    }
    this.setState(newState)
  }

  close = () => {
    this.setState(DEFAULT_STATE)
  }

  handleSelect = e => {
    const { timeZone } = this.state
    const selectedDate = _.get(e, 'option')
    const relative = _.get(selectedDate, 'relative')
    const value = _.get(selectedDate, 'value')
    let date = null
    if (relative) {
      const currentDate = _.now()
      date = currentDate + value
    } else if (value === DATES_VALUES.custom) {
      date = _.now()
    } else {
      date = moment()
        .add(_.get(value, 'add.value'), _.get(value, 'add.type'))
        .set(_.get(value, 'set'))
        .tz(timeZone)
        .valueOf()
    }
    this.setState({ selectedDate, date })
  }

  handleDateChange = date => {
    this.setState({ date })
  }

  handleCheckBoxChange = ({ target }) => {
    this.setState({
      sendTo: { ...this.state.sendTo, [target.name]: target.checked }
    })
  }

  handleMessageChange = e => {
    this.setState({
      message: e.target.value
    })
  }

  submit = () => {
    const { date, message, sendTo, invId, workOrderId, userId, id } = this.state
    const res = _.omitBy({
      date,
      message,
      sendTo,
      invId,
      workOrderId,
      userId,
      id
    }, _.isNil)
    scheduleReminder(res)
    this.close()
  }

  handleCancel = () => {
    const { workOrderId, invId, id } = this.state
    cancelReminder(workOrderId, invId, id)
    this.close()
  }

  render () {
    const { open, timeZone, date, selectedDate, sendTo, message, id } = this.state
    if (open) {
      return (
        <ThemeContext.Extend value={{ checkBox: { color: colors.AQUA_MARINE, hover: { border: { color: colors.AQUA_MARINE } } } }}>
          <Layer
            modal
            onClickOutside={this.close}
            onEsc={this.close}
          >
            <Box width='large' height='650px'>
              <Card fill>
                <CardHeader
                  pad='medium'
                  border={{
                    color: 'border',
                    size: '1px',
                    side: 'bottom'
                  }}
                >
                  <Box>
                    <Text size='xlarge' color={colors.BLACK} weight={600}>
                      {id ? 'Edit' : 'Create'} scheduled notification
                    </Text>
                  </Box>
                </CardHeader>
                <CardBody overflow='auto'>
                  <Box flex={{ shrink: 0 }} pad='medium' gap='medium' overflow='auto'>
                    <Box width='medium' gap='small' flex={{ shrink: 0 }}>
                      <Text size='large' weight={400} color={colors.TEXT}>Time to send</Text>
                      <Select
                        options={DATES}
                        labelKey='label'
                        valueKey='value'
                        placeholder='Select'
                        onChange={this.handleSelect}
                        value={selectedDate}
                      />
                    </Box>
                    <Box flex={{ shrink: 0 }} direction='row' gap='large' customStyle={!_.isNil(selectedDate.value) ? 'visibility: visible' : 'visibility: hidden'}>
                      <Box>
                        <DatePicker
                          title='Date'
                          value={date}
                          timeZone={timeZone}
                          onChange={this.handleDateChange}
                        />
                      </Box>
                      <Box>
                        <DatePicker
                          title='Time'
                          type='time'
                          value={date}
                          timeZone={timeZone}
                          onChange={this.handleDateChange}
                        />
                      </Box>
                    </Box>
                    <Box flex={{ shrink: 0 }}>
                      <Text size='large' weight={400} color={colors.TEXT}>Where to send</Text>
                      <Box direction='row' gap='xlarge' pad={{ vertical: 'large' }}>
                        <Box direction='row' gap='large' align='center'>
                          <Text size='medium' weight={400} color={colors.TEXT}>Email</Text>
                          <CheckBox name='email' checked={_.get(sendTo, 'email', false)} onChange={this.handleCheckBoxChange} />
                        </Box>
                        <Box direction='row' gap='large' align='center'>
                          <Text size='medium' weight={400} color={colors.TEXT}>SMS</Text>
                          <CheckBox name='sms' checked={_.get(sendTo, 'sms', false)} onChange={this.handleCheckBoxChange} />
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={{ shrink: 0 }} gap='medium'>
                      <Text size='large' weight={400} color={colors.TEXT}>Message</Text>
                      <TextArea fill placeholder='Add a custom message (optional)' onChange={this.handleMessageChange} value={message} />
                    </Box>
                    <Box flex={{ shrink: 0 }} margin={{ left: 'auto' }} direction='row' align='center'>
                      {id && (
                        <Box width='small'>
                          <Button plain label={<Text color={colors.CORAL_TWO}>Cancel notification</Text>} onClick={this.handleCancel} />
                        </Box>
                      )}
                      <Box width='small'>
                        <Button disabled={_.isNil(selectedDate.value) || _.size(_.filter(_.values(sendTo))) < 1} label='Schedule' primary color={colors.LIGHT_NAVY_BRIGHT} onClick={this.submit} />
                      </Box>
                    </Box>
                  </Box>
                </CardBody>
              </Card>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )
    }
    return null
  }
}

export default ScheduledReminderModal
