import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'navigation/rrouter'
import _ from 'lodash'

import Loading from 'shared/components/LoadingApp'
import { getViewports } from 'shared/model/selectors/viewportSelector'

const PrivateRoute = ({ component: Component, screenViewport, componentProps, user, ...rest }) => {
  if (_.isNil(user)) {
    return <Route {...rest} component={Loading} />
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          <Component
            {...componentProps}
            {...props}
            {..._.get(props, 'match.params', {})}
            {..._.get(props, 'history.location.state', {})}
          />
        }
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  ...getViewports(state, props),
  user: state.user
})

export default connect(mapStateToProps)(PrivateRoute)
