import _ from 'lodash'
import bidStatus from 'shared/constants/bidStatus'
import amountType from 'shared/constants/amountType'
import lineItemType from 'shared/constants/lineItemType'
import sendTypes from 'shared/constants/inviteStatus'

export const getBidStatusType = (bid, workOrder) => {
  const isDeclinedBySub = _.get(workOrder, ['declinedBy', bid.accountId])
  const isDeclinedByHic = _.get(bid, 'status.type') === bidStatus.DECLINED
  let statusType = _.get(bid, 'status.type', bidStatus.NEW)
  const approvedBidId = _.get(workOrder, 'approvedBidId')
  if (isDeclinedBySub || isDeclinedByHic) {
    statusType = bidStatus.DECLINED
  } else if (_.get(bid, 'id') === approvedBidId) {
    statusType = bidStatus.ACCEPTED
  } else if (approvedBidId && approvedBidId !== _.get(bid, 'id')) {
    statusType = bidStatus.LOST
  }
  return statusType
}

export const getUserScope = (scope, accountId) => {
  return _.reduce(
    scope,
    (res, item, itemId) => {
      // console.log('processItem', item)
      const priceRequested = _.get(item, 'priceRequested')
      if (_.isEmpty(priceRequested) || _.includes(item.priceRequested, accountId)) {
        _.set(res, itemId, item)
      }
      return res
    },
    {}
  )
}

export const getUserScopeMemo = _.memoize(getUserScope)

export const getItemsTotal = items => {
  return _.reduce(
    items,
    (res, item) => {
      const itemTotal = item.excluded ? 0 : _.get(item, 'total', 0)
      return res + itemTotal
    },
    0
  )
}

export const getDiscountAmount = (discount, v) => {
  const type = _.get(discount, 'type', amountType.FLAT_AMOUNT)
  const value = _.get(discount, 'value', 0)
  if (type === amountType.PERCENTAGE) {
    return _.floor((v * value) / 100, 2)
  } else {
    return value
  }
}

export const getTotals = newValues => {
  const newItemsTotal = getItemsTotal(newValues.items)
  // console.log('prevItemsTotal', prevItemsTotal, 'newItemsTotal', newItemsTotal)
  const unpricedAmount = newValues.unpricedAmount
  let total = unpricedAmount + newItemsTotal
  const discountAmount = getDiscountAmount(newValues.discount, total)
  total = total - discountAmount
  total = _.max([0, total])
  const deposit = { ...newValues.deposit }
  if (!_.isEmpty(deposit) && deposit.type === amountType.FLAT_AMOUNT) {
    deposit.value = _.min([total, deposit.value])
  }
  return {
    ...newValues,
    total,
    deposit
  }
}

export const areRequiredFieldsDone = (scope, items) => {
  let requiredFieldDone = true
  _.forEach(scope, (scopeItem, scopeItemId) => {
    const li = _.get(items, scopeItemId)
    if (scopeItem.required) {
      const liType = _.get(scopeItem, 'type', lineItemType.DEFAULT)
      switch (liType) {
        // case lineItemType.DEFAULT:
        //   if (_.get(li, 'total', 0) <= 0) {
        //     requiredFieldDone = false
        //     return false
        //   }
        //   break
        case lineItemType.QUESTION:
          if (_.isEmpty(_.toString(_.get(li, 'answer')))) {
            requiredFieldDone = false
            return false
          }
          break
        case lineItemType.QUESTION_BOOL: {
          if (!_.has(li, 'answer')) {
            requiredFieldDone = false
            return false
          }
          break
        }
      }
    }
  })
  return requiredFieldDone
}

export const isSilentBid = (workOrder, accountId) => {
  const sendType = _.get(workOrder, ['invitations', accountId, 'sendType'])
  return _.isEqual(sendType, sendTypes.SILENT)
}

export default {
  getItemsTotal,
  getBidStatusType,
  getDiscountAmount,
  getTotals,
  areRequiredFieldsDone,
  isSilentBid
}
