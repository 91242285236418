import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import PreconContext from 'webPages/precon/PreconContext'
import { getPreconConf } from 'controllers/data'
import { navigateToHomePage } from 'controllers/init'
import Loading from 'shared/pages/Loading'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'

class PreconDataProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      preconConf: null,
      goToNextScreen: this.goToNextScreen,
      skipProjectCreation: this.skipProjectCreation,
      projectCreationSkipped: false
    }
    console.log('precon data provider constructor, props', props)
  }

  skipProjectCreation = curScreen => {
    this.setState({ projectCreationSkipped: true }, () => this.goToNextScreen(curScreen))
  }

  componentDidMount = async () => {
    const { projectId, preconConfId, projects, dispatch } = this.props
    const confId = preconConfId || _.get(projects, [projectId, 'preconConfId'])
    console.log('%cPreconDataProvider confId', 'color: gold;', confId)
    if (_.isNil(confId)) {
      dispatch(navigateToHomePage())
    } else {
      console.log('get precon conf', confId)
      const r = await getPreconConf(confId)
      this.onPreconConfReceived(r)
    }
  }

  goToNextScreen = curScreen => {
    console.log('goToNextScreen, curScreen', curScreen)
    const { subscription, dispatch, projectId } = this.props
    const { preconConf, projectCreationSkipped } = this.state
    const flowScreens = [
      screens.PRECON_PROJECT,
      screens.PRECON_ORDER_DETAILS
      // screens.PRECON_PAYMENT_PLAN,
      // screens.PRECON_TEAMMATES,
      // screens.PRECON_BOOK_A_CALL
    ]
    const curScreenIndex = _.indexOf(flowScreens, curScreen)
    // const skipInvite = _.get(preconConf, 'skipInvite', false)
    // const skipCall = _.get(preconConf, 'skipCall', false)
    // const skipPricing = _.get(preconConf, 'skipPricing', false)
    const nextScreen = _.find(
      flowScreens,
      s => {
        if (s === screens.PRECON_ORDER_DETAILS && projectCreationSkipped) return false
        // if (s === screens.PRECON_PAYMENT_PLAN && !_.isEmpty(subscription)) return false
        // if (s === screens.PRECON_PAYMENT_PLAN && skipPricing) return false
        // if (s === screens.PRECON_TEAMMATES && skipInvite) return false
        // if (s === screens.PRECON_BOOK_A_CALL && skipCall) return false
        return true
      },
      curScreenIndex + 1
    )
    console.log('nextScreen', nextScreen)
    if (_.isNil(nextScreen)) {
      if (projectCreationSkipped) {
        dispatch(navigateToHomePage())
      } else {
        universalNavigation.push(screens.GC_PROJECT, { projectId })
      }
    } else {
      universalNavigation.push(nextScreen, { projectId: projectCreationSkipped ? 'none' : projectId })
    }
  }

  componentWillUnmount = () => {
    this.onPreconConfReceived = () => null
  }

  onPreconConfReceived = r => {
    console.log('onPreconConfReceived', r)
    const { dispatch } = this.props
    if (_.isNil(r)) {
      dispatch(navigateToHomePage())
    } else {
      console.log('preconConf', r)
      this.setState({ preconConf: r })
    }
  }

  render () {
    const { preconConf } = this.state
    if (_.isNil(preconConf)) {
      return <Loading />
    } else {
      return <PreconContext.Provider value={{ ...this.state }}>{this.props.children}</PreconContext.Provider>
    }
  }
}

PreconDataProvider.contextType = PreconContext

const mapStateToProps = state => ({
  projects: state.projects,
  subscription: _.get(state, 'account.subscription')
})

export default connect(mapStateToProps)(PreconDataProvider)
