import React, { useState, useEffect } from 'react'
import { Box, CheckBox, ThemeContext } from 'grommet'

import colors from 'shared/constants/colors'

const CustomToggle = ({ value, onChange, ...props }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const themeContextValue = {
    checkBox: {
      toggle: {
        size: '38px',
        knob: {
          extend: ({ checked }) => `
            top: 2px;
            left: ${checked ? '16px' : '2px'} !important;
            width: 16px;
            height: 16px;
            background: ${checked ? colors.WHITE : colors.WHITE} !important;
            border: ${checked ? `0px solid ${colors.AQUA_MARINE}` : `2px solid ${colors.LIGHT_NAVY_BRIGHT}`};
          `
        },
        extend: () => `
          height: 24px;
          background: ${checked ? colors.AQUA_MARINE : colors.WHITE} !important;
          border: 2px solid ${checked ? colors.AQUA_MARINE : colors.LIGHT_NAVY_BRIGHT} !important;
        `
      }
    }
  }

  return (
    <Box align='center' justify='center' flex={{ shrink: 0 }}>
      <ThemeContext.Extend value={themeContextValue}>
        <CheckBox
          {...props}
          checked={checked}
          onChange={event => {
            onChange(event.target.checked)
            setChecked(event.target.checked)
          }}
          toggle
        />
      </ThemeContext.Extend>
    </Box>
  )
}

export default CustomToggle
