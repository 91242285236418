import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import Document from 'shared/documents/components/Document'
import { getSizeF } from 'shared/documents/utils/dynamicSize'
import Comments from 'shared/documents/components/estimate/Comments'
import { renderHeader, renderItems, renderGeneralExclusions } from 'shared/documents/components/estimate/Table'
import { renderQuestons } from 'shared/documents/components/estimate/Questions'
import Total from 'shared/documents/components/estimate/Total'
import { renderSheduleOfPayments } from 'shared/documents/components/estimate/ScheduleOfPayments'
import DocHeader from './components/DocHeader'
import { toTimestamp } from 'shared/utils/date'
import { getName, getAddressLine2, getAddress } from 'shared/utils/stringUtils'
import { formatNational } from 'shared/utils/phone'
import DocContacts from 'shared/documents/components/DocContacts'
import DocTableHeader from 'shared/documents/components/DocTableHeader'
import { moneyWithSymbolAbbr } from 'shared/utils/money'
import { StyledText } from 'shared/documents/components/StyledComponents'
import GcSubSignature from 'shared/documents/components/GcSubSignature'
import { renderCover } from 'shared/documents/components/companyProfile/cover'
import { renderAboutUs } from 'shared/documents/components/companyProfile/aboutUs'
import { renderOurServices } from 'shared/documents/components/companyProfile/ourServices'
import { renderTeammates } from 'shared/documents/components/companyProfile/teammates'
import Break from 'shared/documents/components/Break'
import { A4K } from 'shared/constants/documents'
import { renderCustomContract } from 'shared/documents/components/customContract/CustomContractContent'
import { getDiscountAmount, getItemsTotal } from 'shared/utils/bid'
import amountType from 'shared/constants/amountType'

class Estimate extends Component {
  getContactsList = profile => {
    return [
      getName(profile),
      _.get(profile, 'address.name', ''),
      getAddressLine2(_.get(profile, 'address')),
      _.get(profile, 'email', ''),
      formatNational(_.get(profile, 'phone', ''))
    ]
  }

  renderContacts = getSize => {
    const { doc } = this.props
    // console.log('doc', doc)
    const preparedFor = this.getContactsList(doc.client)
    const preparedBy = this.getContactsList(doc.owner)
    const projectAddress = getAddress(_.get(doc, 'projectAddress'))
    return (
      <DocContacts
        getSize={getSize}
        preparedFor={preparedFor}
        preparedBy={preparedBy}
        projectAddress={projectAddress}
      />
    )
  }

  renderSignature = getSize => {
    const { doc, ssr } = this.props
    if (_.get(doc, 'needsGCSig')) {
      const gcSig = _.get(doc, 'gcSignature')
      const sig = _.get(doc, 'signatures')
      if (!_.isEmpty(gcSig)) {
        _.set(sig, ['gc.name'], _.get(gcSig, 'userName'))
        _.set(sig, ['gc.date'], _.get(gcSig, 'date'))
        _.set(sig, ['gc.url'], _.get(gcSig, 'sigUrl.url'))
      }
      return <GcSubSignature getSize={getSize} ssr={ssr} sig={sig} />
    }
  }

  renderCompanyProfile = getSize => {
    const { pageWidth, pageHeight, ssr, doc, paddingHorizontalK, paddingVerticalK } = this.props
    const width = pageWidth * (1 - paddingHorizontalK * 2)
    let height = pageHeight * (1 - paddingVerticalK * 2)
    if (ssr) height = pageWidth * A4K * 0.9 // default margin for pdf generation is 0.05
    const res = []
    const proposalTemplate = _.get(doc, 'owner.settings.proposalTemplate')
    if (_.get(proposalTemplate, 'cover', false))
      res.push(renderCover(getSize, width, height, ssr, doc.typeOfWork, doc.owner, doc.projectAddress, false))
    if (_.has(doc, 'owner.about') && _.get(proposalTemplate, 'about', false))
      res.push(renderAboutUs(getSize, width, height, ssr, doc.owner))
    if (!_.isEmpty(doc.serviceTypeGroups) && _.get(proposalTemplate, 'services', false))
      res.push(renderOurServices(getSize, width, height, ssr, doc.serviceTypeGroups))
    if (!_.isEmpty(doc.teammates) && _.get(proposalTemplate, 'teammates', false))
      res.push(renderTeammates(getSize, width, height, ssr, doc.teammates))
    if (!_.isEmpty(res)) res.push(<Break key='BREAK' break ssr={ssr} />)
    return res
  }

  renderProgressPayments = getSize => {
    const { doc } = this.props
    const depositValue = _.get(doc, 'deposit.value', 0)
    const balanceDue = _.get(doc, 'total', 0) - depositValue
    const progressPayments = _.get(doc, 'progressPayments', {})
    if (!_.isEmpty(progressPayments)) {
      const strBalance = moneyWithSymbolAbbr(balanceDue)
      return [
        <StyledText key='remaningBalance' fontSize={getSize(12)}>
          {`Remaining balance of ${strBalance} will be fulfilled as progress payments according to the schedule below.`}
        </StyledText>,
        <DocTableHeader
          key='scheduleTableHeader'
          getSize={getSize}
          title='Schedule of Payments'
          customStyle={`padding-top: ${getSize(20)}px;`}
        />,
        ...renderSheduleOfPayments(getSize, doc)
      ]
    } else {
      return []
    }
  }

  customContract = getSize => {
    const { doc, ssr } = this.props
    const cc = _.get(doc, 'cc')
    // console.log('Estimate: cc ', cc)
    if (!_.isEmpty(cc)) {
      return [<Break key='BREAK2' break ssr={ssr} />, ...renderCustomContract(getSize, cc, doc)]
    } else {
      return []
    }
  }

  render () {
    const {
      pageWidth,
      pageHeight,
      ssr,
      pageProps,
      pageNum,
      onPageClick,
      activeOpacity,
      doc,
      invoice,
      paddingHorizontalK,
      paddingVerticalK
    } = this.props

    console.log('DOC', doc)
    const viewport = {
      width: pageWidth,
      height: pageHeight
    }
    const getSize = getSizeF(viewport, paddingHorizontalK, ssr)
    const comments = invoice ? _.get(doc, 'comments', '') : _.get(doc, 'comment', '')
    let createdAt = toTimestamp(_.get(doc, 'timestamp'))
    if (_.isEmpty(createdAt)) createdAt = new Date().getTime()
    const total = doc.total
    const deposit = _.get(doc, 'deposit', 0)
    const depositValue = getDiscountAmount(deposit, total)

    const itemsTotal = getItemsTotal(doc.items)
    const unpricedAmount = _.get(doc, 'unpricedAmount', 0)
    const subTotal = unpricedAmount + itemsTotal

    const timeZone = _.get(doc, 'projectAddress.timeZone')
    const date = moment.tz(date, timeZone).format('L')

    return (
      <Document
        paddingHorizontal={viewport.width * paddingHorizontalK}
        paddingVertical={viewport.height * paddingVerticalK}
        pageWidth={viewport.width}
        pageHeight={viewport.height}
        ssr={ssr}
        pageProps={pageProps}
        pageNum={pageNum}
        onPageClick={onPageClick}
        activeOpacity={activeOpacity}
      >
        {[...this.renderCompanyProfile(getSize)]}
        <DocHeader
          getSize={getSize}
          companyLogo={_.get(doc, 'owner.avatar', _.get(doc, 'owner.avatarSmall'))}
          companyName={_.get(doc, 'owner.name')}
          title={_.get(doc, 'docTitle', invoice ? 'INVOICE' : 'ESTIMATE')}
          docDate={date}
        />
        {this.renderContacts(getSize)}
        <DocTableHeader getSize={getSize} title='WORK DESCRIPTION' customStyle='justify-content: center;' />

        {renderHeader(getSize)}
        {renderItems(getSize, doc.items, doc.scope, doc.sections, doc.id, true, true)}

        <Total title={'Subtotal'} getSize={getSize} total={subTotal} />
        <Total
          title={'Discount'}
          getSize={getSize}
          total={getDiscountAmount(doc.discount, subTotal)}
          hideBorder
          perc={_.get(doc, 'discount.type') === amountType.PERCENTAGE ? _.get(doc, 'discount.value') : undefined}
        />
        <Total title={'Total'} getSize={getSize} total={total} hideBorder />
        <Total
          title={'Deposit'}
          getSize={getSize}
          total={depositValue}
          hideBorder
          customStyle={`padding-bottom: ${getSize(40)}px;`}
          perc={_.get(doc, 'deposit.type') === amountType.PERCENTAGE ? _.get(doc, 'deposit.value') : undefined}
        />

        {depositValue > 0 && (
          <StyledText key='deposit upon contract' fontSize={getSize(12)}>
            {`Deposit upon signing of contract is ${moneyWithSymbolAbbr(depositValue)}.`}
          </StyledText>
        )}

        {[...this.renderProgressPayments(getSize)]}

        {renderGeneralExclusions(getSize, doc.items, doc.scope, doc.sections)}

        {renderQuestons(getSize, doc.items, doc.scope, doc.sections, ssr)}

        {!_.isEmpty(comments) ? <Comments key='commments' comments={comments} getSize={getSize} /> : null}

        {[...this.customContract(getSize)]}

        {this.renderSignature(getSize)}
      </Document>
    )
  }
}

Estimate.defaultProps = {
  ssr: false,
  pageProps: {},
  invoice: false,
  doc: {
    docTitle: 'Estimate',
    total: 0,
    items: []
  },
  paddingHorizontalK: 0.06,
  paddingVerticalK: 0.05
}

Estimate.propTypes = {
  ssr: PropTypes.bool,
  paddingHorizontalK: PropTypes.number,
  paddingVerticalK: PropTypes.number,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  pageProps: PropTypes.object,
  pageNum: PropTypes.number,
  onPageClick: PropTypes.func,
  activeOpacity: PropTypes.number,
  invoice: PropTypes.bool,
  doc: PropTypes.shape({
    owner: PropTypes.object,
    client: PropTypes.object,
    documentNum: PropTypes.string,
    companyName: PropTypes.string,
    docTitle: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    total: PropTypes.number,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    comments: PropTypes.string,
    cc: PropTypes.object
  })
}

export default Estimate
