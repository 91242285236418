export const PENDING = 'PENDING'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'
export const PAID = 'PAID'

export default {
  PENDING,
  APPROVED,
  REJECTED,
  PAID
}
