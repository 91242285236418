import { db, auth, fieldValue } from 'constants/firebase'
import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

const convertAnnotationsToChannels = docs => {
  console.log('convertAnnotationsToChannels, amount', _.size(docs))
  const res = {}
  _.forEach(docs, doc => {
    const an = doc.data()
    const targetSeletor = _.get(an, 'target.selector')
    try {
      if (_.isString(targetSeletor) && !_.isEmpty(targetSeletor)) {
        _.set(an, 'target.selector', JSON.parse(targetSeletor))
      }
      _.set(res, [an.accountId, an.id], an)
      return res
    } catch (e) {
      console.warn('cannot parse', targetSeletor)
    }
  })
  return res
}

export const listenToAnnotations = ({ accountId, callback, fileId, woAccountId, workOrderId }) => {
  console.log(
    'listenToAnnotations',
    'accountId',
    accountId,
    'fileId',
    fileId,
    'woAccountId',
    woAccountId,
    'workOrderId',
    workOrderId
  )
  if (accountId === woAccountId) {
    return db
      .collection('annotations')
      .doc(workOrderId)
      .collection('messagesList')
      .where('accounts', 'array-contains', accountId)
      .where('fileId', '==', fileId)
      .onSnapshot(
        sn => callback(convertAnnotationsToChannels(sn.docs)),
        error => {
          console.error(`annotations same account error onSnapshot error: ${error}`)
          Sentry.captureException(error)
        }
      )
  } else {
    return db
      .collection('annotations')
      .doc(workOrderId)
      .collection('messagesList')
      .where('fileId', '==', fileId)
      .where('accountId', 'in', [woAccountId, accountId])
      .onSnapshot(
        sn => callback(convertAnnotationsToChannels(sn.docs)),
        error => {
          console.error(`annotations different account onSnapshot error: ${error}`)
          Sentry.captureException(error)
        }
      )
  }
}

export const updateAnnotation = (id, workOrderId, params) => {
  console.log('updateAnnotation', id, params)
  const targetSeletor = _.get(params, 'target.selector')
  const upd = { ...params }
  if (!_.isEmpty(targetSeletor)) {
    _.set(upd, 'target.selector', JSON.stringify(targetSeletor))
  }
  db.collection('annotations')
    .doc(workOrderId)
    .collection('messagesList')
    .doc(id)
    .update(upd)
}

export const saveAnnotation = (workOrderId, fileId, accountId, accounts, anRaw, creatorAccountId) => {
  const userId = auth.currentUser.uid
  const an = {
    ...anRaw,
    accounts,
    timestamp: fieldValue.serverTimestamp(),
    userId,
    fileId,
    workOrderId,
    accountId, // this is a channel id
    creatorAccountId
  }
  console.log('saveAnnotation workOrderId, fileId, accountId, an', workOrderId, fileId, accountId, an)
  const targetSeletor = _.get(an, 'target.selector')
  if (!_.isEmpty(targetSeletor)) {
    _.set(an, 'target.selector', JSON.stringify(targetSeletor))
  }
  db.collection('annotations')
    .doc(workOrderId)
    .collection('messagesList')
    .doc(an.id)
    .set(an, { merge: true })
}

export const deleteAnnotations = (ids, workOrderId) => {
  console.log('delete annotations from db', ids)
  const batch = db.batch()
  for (const id of ids) {
    batch.delete(
      db
        .collection('annotations')
        .doc(workOrderId)
        .collection('messagesList')
        .doc(id)
    )
  }
  batch.commit()
}
