import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { auth, db, fieldValue } from 'constants/firebase'
import { addListener, offListener } from 'controllers/listeners'
import { receiveProfiles, receiveMasonAdmins } from 'model/actions/profilesAC'
import { receiveAccountsProfiles } from 'model/actions/accountsProfilesAC'
import store from 'model/store'
import { saveFile } from 'shared/controllers/storage'
import { updateProfile } from 'controllers/data'
import { setSentryUserProfile, setSentryCompanyProfile } from 'controllers/sentry'

export const fetchUsersProfiles = (accountId, userId) => {
  try {
    console.log('fetchUsersProfiles, accountId:', accountId)
    const unsubscribe = db
      .collection('usersProfiles')
      .where('accounts', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const profiles = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.unset(p, 'accounts')
            if (doc.id === userId) setSentryUserProfile(p)
            _.set(profiles, doc.id, p)
          })
          console.log('fetchUsersProfiles:', _.size(profiles))
          store.dispatch(receiveProfiles(profiles))
        },
        err => {
          console.log(`fetch usersProfiles error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('usersProfiles', unsubscribe)
  } catch (e) {
    console.log('usersProfiles error', e)
    Sentry.captureException(e)
  }
}

export const fetchAccountsProfiles = accountId => {
  try {
    console.log('fetchAccountsProfiles, accountId:', accountId)
    const unsubscribe = db
      .collection('accountsProfiles')
      .where('accounts', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const profiles = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.unset(p, 'accounts')
            if (doc.id === accountId) setSentryCompanyProfile(p)
            _.set(profiles, doc.id, p)
          })
          console.log('fetchAccountsProfiles:', _.size(profiles))
          store.dispatch(receiveAccountsProfiles(profiles))
        },
        err => {
          console.log(`fetchAccountsProfiles error: ${err}`)
        }
      )
    addListener('accountsProfiles', unsubscribe)
  } catch (e) {
    console.log('fetchAccountsProfiles error', e)
    Sentry.captureException(e)
  }
}

export const fetchAdminsProfiles = async () => {
  try {
    console.log('fetchAdminsProfiles')
    const profilesSN = await db
      .collection('usersProfiles')
      .where('isMasonAdmin', '==', true)
      .get()
    return _.map(profilesSN.docs, doc => doc.data())
  } catch (e) {
    console.log('fetchAdminsProfiles error', e)
    Sentry.captureException(e)
  }
}

export const fetchOwnAccountsProfiles = user => {
  try {
    offListener('ownAccountsProfiles', user)
    const accountsId = _.get(user, 'adminOfAccounts', [])
    console.log('fetchOwnAccountsProfiles, user.adminOfAccounts:', accountsId)
    if (!_.isEmpty(accountsId)) {
      const chunks = _.chunk(accountsId, 10)
      for (const i in chunks) {
        const ids = chunks[i]
        const unsubscribe = db
          .collection('accountsProfiles')
          .where('id', 'in', ids)
          .onSnapshot(
            sn => {
              const profiles = {}
              sn.forEach(doc => {
                const p = doc.data()
                _.unset(p, 'accounts')
                _.set(profiles, doc.id, p)
              })
              console.log('fetchOwnAccountsProfiles:', _.size(profiles))
              store.dispatch(receiveAccountsProfiles(profiles))
            },
            err => {
              console.log(`fetchOwnAccountsProfiles error: ${err}`)
            }
          )
        addListener(`ownAccountsProfiles${i}`, unsubscribe)
      }
    } else {
      console.warn('fetchOwnAccountsProfiles, the list is empty', user.id)
    }
  } catch (e) {
    console.log('fetchOwnAccountsProfiles error', e)
    Sentry.captureException(e)
  }
}

export function saveCompanyProfile (profile) {
  return async function (dispatch, getState) {
    try {
      console.log('saveCompanyProfile', profile)
      const state = getState()
      const accountId = _.get(state, 'account.id')
      const curAvatar = _.get(state, ['accountsProfiles', accountId, 'avatar'])
      if (!_.isNil(profile.avatar) && curAvatar !== profile.avatar) {
        console.log('saveCompanyProfile, upload avatars, accountId', accountId)
        const newAvatarRes = await saveFile(`account/${accountId}/avatar`, profile.avatar)
        const newAvatarSmallRes = await saveFile(`account/${accountId}/avatarSmall`, profile.avatarSmall)
        console.log('avatars uploaded')
        const profileWithNewAvatars = {
          ...profile,
          avatar: newAvatarRes.url,
          avatarSmall: newAvatarSmallRes.url
        }
        console.log('update account profile', profileWithNewAvatars)
        await db
          .collection('accountsProfiles')
          .doc(accountId)
          .update(_.omitBy(profileWithNewAvatars, _.isNil))
      } else {
        await db
          .collection('accountsProfiles')
          .doc(accountId)
          .update(_.omitBy(profile, _.isNil))
      }
    } catch (e) {
      console.log('saveCompanyProfile error', e)
      Sentry.captureException(e)
    }
  }
}

export function saveUserProfile (profile, updateAuth = true) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const userId = auth.currentUser.uid
      const curAvatar = _.get(state, ['profiles', userId, 'avatar'])
      if (!_.isNil(profile.avatar) && curAvatar !== profile.avatar) {
        const newAvatarRes = await saveFile(`user/${userId}/avatar`, profile.avatar)
        const newAvatarSmallRes = await saveFile(`user/${userId}/avatarSmall`, profile.avatarSmall)
        const profileWithNewAvatars = {
          avatar: newAvatarRes.url,
          avatarSmall: newAvatarSmallRes.url
        }
        if (updateAuth) await auth.currentUser.updateProfile({ photoURL: profile.avatar })
        await db
          .collection('usersProfiles')
          .doc(userId)
          .update(_.omitBy(profileWithNewAvatars, _.isNil))
      }
      await updateProfile(profile)
    } catch (e) {
      console.log('saveUserProfile error', e)
      Sentry.captureException(e)
    }
  }
}

export async function fetchAccountProfile (accountId) {
  const accountSn = await db
    .collection('accountsProfiles')
    .doc(accountId)
    .get()
  return accountSn.data()
}

export async function fetchUserProfile (userId) {
  const accountSn = await db
    .collection('usersProfiles')
    .doc(userId)
    .get()
  return accountSn.data()
}

export const updateUserProfileAsync = async (userId, params) => {
  console.log('updateUserProfieAsync', userId, params)
  try {
    await db
      .collection('usersProfiles')
      .doc(userId)
      .update(params)
  } catch (error) {
    console.log('Error while updating user profile: ', error.message)
  }
}

export function updateUserProfile (params) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const id = _.get(state, 'user.id')
      await updateUserProfileAsync(id, params)
    } catch (error) {
      console.log('Error while updating user profile: ', error.message)
    }
  }
}

export function updateAccountProfile (params) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const id = _.get(state, 'user.currentAccountId')
      await db
        .collection('accountsProfiles')
        .doc(id)
        .update(params)
    } catch (error) {
      console.log('Error while updating account profile: ', error.message)
    }
  }
}

export function removeAvatarFromUser () {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const id = _.get(state, 'user.id')
      await db
        .collection('usersProfiles')
        .doc(id)
        .update({
          avatar: fieldValue.delete(),
          avatarSmall: fieldValue.delete()
        })
    } catch (error) {
      console.log('error removing avatar from user: ', error.message)
    }
  }
}

export function removeAvatarFromAccount () {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const id = _.get(state, 'user.currentAccountId')
      await db
        .collection('accountsProfiles')
        .doc(id)
        .update({
          avatar: fieldValue.delete(),
          avatarSmall: fieldValue.delete()
        })
    } catch (error) {
      console.log('error removing avatar from account: ', error.message)
    }
  }
}

export function saveAccountAvatar (profile) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const accountId = _.get(state, 'user.currentAccountId')
      if (!_.isNil(profile.avatar)) {
        const newAvatarRes = await saveFile(`account/${accountId}/avatar`, profile.avatar)
        const newAvatarSmallRes = await saveFile(`account/${accountId}/avatarSmall`, profile.avatarSmall)
        const profileWithNewAvatars = {
          avatar: newAvatarRes.url,
          avatarSmall: newAvatarSmallRes.url
        }
        await db
          .collection('accountsProfiles')
          .doc(accountId)
          .update(_.omitBy(profileWithNewAvatars, _.isNil))
      }
    } catch (e) {
      console.log('saveAccountAvatar error', e)
      Sentry.captureException(e)
    }
  }
}

export function fetchMasonAdmins () {
  try {
    const unsubscribe = db
      .collection('usersProfiles')
      .where('isMasonAdmin', '==', true)
      .onSnapshot(
        sn => {
          const profiles = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.unset(p, 'accounts')
            _.set(profiles, doc.id, p)
          })
          console.log('fetchMasonAdmins:', _.size(profiles))
          store.dispatch(receiveMasonAdmins(profiles))
        },
        err => {
          console.log(`fetchMasonAdmins error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('masonAdmins', unsubscribe)
  } catch (e) {
    console.log('fetchMasonAdmins error', e)
    Sentry.captureException(e)
  }
}
