import React, { useState } from 'react'
import { Box, Text, ThemeContext, FormField, Button, TextInput, Anchor } from 'grommet'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'
import _ from 'lodash'

const ValidatePhoneNumber = ({ handleSubmit, phone, changePhone, loading, handleResend, error }) => {
  const [code, setCode] = useState('')

  const handleChange = e => {
    const value = e.target.value
    if (_.size(value) < 5) {
      setCode(value)
    }
  }

  return (
    <Box pad='medium' align='start' fill>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box fill='horizontal' align='center' flex={false} pad='medium'>
        <Box flex={false} width='600px' gap='medium'>
          <Box align='start' gap='xsmall'>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              2-Step Verification
            </Text>
          </Box>

          <Box align='start'>
            <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>Enter the 2-step verification code sent to {phone}</Text>
          </Box>

          <Box>
            <ThemeContext.Extend
              value={{
                formField: {
                  round: '5px',
                  border: {
                    color: error ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                  },
                  focus: {
                    border: {
                      color: error ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                    }
                  }
                }
              }}
            >
              <FormField label={<Text>Enter four digit verification code</Text>} width='medium'>
                <TextInput disabled={loading} value={code} onChange={handleChange} />
              </FormField>
              {error && <Text size='small' color={colors.CORAL_TWO}>{error}</Text>}
            </ThemeContext.Extend>
          </Box>
          <Box width='medium' height='45px'>
            <Button
              fill
              primary
              color={colors.AQUA_MARINE}
              label='Continue'
              onClick={() => handleSubmit(code)}
              disabled={_.size(code) < 4 || loading}
              size='large'
            />
          </Box>

          <Box gap='small'>
            <Box direction='row' align='center' gap='xxsmall'>
              <Text>Code sent to {phone}. Prefer to use a different number?</Text>
              <Anchor label='Change phone' color={colors.LINK} onClick={changePhone} />
            </Box>
            <Box direction='row' align='center' gap='xxsmall'>
              <Text>Haven’t received a code?</Text>
              <Anchor label='Resend' color={colors.LINK} onClick={handleResend} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ValidatePhoneNumber
