import React from 'react'
import { Box, Text, DropButton, Tip } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import Toggle from 'components/Toggle'
import DropDownMenu from 'components/DropDownMenu'
import levelingTableMode from 'constants/levelingTableMode'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import levelingTableSettings from 'constants/levelingTableSettings'
import { changeSettings, resetSettings } from 'model/actions/customizeSettingsAC'

class CustomizePanel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpened: false
    }
  }

  open = () =>
    this.setState({
      isOpened: true
    })

  onChange = type => value => {
    const { dispatch, settings } = this.props
    if (_.isEqual(type, levelingTableSettings.COMPACT_VIEW) && _.get(settings, levelingTableSettings.SHOW_VALUES, false)) {
      dispatch(changeSettings(levelingTableSettings.SHOW_VALUES, false))
    }
    if (_.isEqual(type, levelingTableSettings.SHOW_VALUES) && _.get(settings, levelingTableSettings.COMPACT_VIEW, false)) {
      dispatch(changeSettings(levelingTableSettings.COMPACT_VIEW, false))
    }
    dispatch(changeSettings(type, value))
  }

  close = () => this.setState({ isOpened: false })

  handleReset = () => {
    const { dispatch } = this.props
    dispatch(resetSettings())
    this.close()
  }

  renderHeader = () => {
    const { settings } = this.props
    const settingsChanged = _.size(_.filter(settings, (v, name) => {
      if (_.includes([levelingTableSettings.SHOW_SECTIONS, levelingTableSettings.SHOW_DECLINED], name)) {
        return !v
      }
      return v
    }))

    return (
      <Box flex pad={{ vertical: 'small', horizontal: 'medium' }} width='medium' align='center' direction='row' justify='between' border='bottom'>
        <Text size='medium' color={colors.TEXT_PRIMARY} weight={400}>
          Customize table
        </Text>
        {settingsChanged > 0 && (
          <Box onClick={this.handleReset}>
            <Text size='medium' color={colors.CLEAR_BLUE}>Reset</Text>
          </Box>
        )}
      </Box>
    )
  }

  onChangeModeClick = id => {
    const { mode, onChangeMode } = this.props
    if (id !== mode) {
      onChangeMode(id)
      this.close()
    }
  }

  viewMode = mode => (
    <Box direction='row' pad={{ left: 'small', top: 'small' }} align='center' justify='between'>
      <Text>View</Text>
      <DropDownMenu
        options={[
          { id: levelingTableMode.SCOPE_OF_WORK, label: 'Leveling table' },
          { id: levelingTableMode.GENERAL_EXLUSIONS, label: 'Compare General Exclusions' }
        ]}
        dropContentProps={{ boxProps: { width: { min: '220px' } } }}
        dropAlign={{ top: 'bottom', right: 'right' }}
        selectedId={mode}
        onOptionClick={this.onChangeModeClick}
      >
        <Box
          direction='row'
          border='all'
          round='xsmall'
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          gap='xsmall'
          align='center'
        >
          <Text color={mode === levelingTableMode.SCOPE_OF_WORK ? colors.LIGHT_NAVY_BRIGHT : colors.CORAL_TWO}>
            {mode === levelingTableMode.SCOPE_OF_WORK ? 'Leveling table' : 'General Exclusions'}
          </Text>
          <FontAwesomeIcon icon={faChevronDown} size={14} />
        </Box>
      </DropDownMenu>
    </Box>
  )

  renderContent = () => {
    const { mode, settings } = this.props
    const isGE = _.isEqual(mode, levelingTableMode.GENERAL_EXLUSIONS)
    const labelColor = isGE ? colors.MEDIUM_GREY : colors.VERY_LIGHT_PINK
    const titleColor = isGE ? colors.VERY_LIGHT_PINK : colors.BLACK
    return (
      <Box pad='small' gap='medium' flex={false}>
        {mode && this.viewMode(mode)}
        <Box>
          <Box pad='xxsmall'>
            <Text color={labelColor}>Table cells</Text>
          </Box>
          <Box direction='row' pad={{ horizontal: 'small', top: 'xsmall', bottom: 'small' }} justify='between' align='center'>
            <Text weight='bold' color={titleColor}>Show values</Text>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.SHOW_VALUES, false)}
              onChange={this.onChange(levelingTableSettings.SHOW_VALUES)}
            />
          </Box>
          <Box direction='row' pad='small' justify='between' align='center'>
            <Box>
              <Text weight='bold' color={titleColor}>Show sections</Text>
              <Text size='xsmall' color={colors.ANOTHER_GREY}>
                Display the sections in your scope along with Section Subtotals
              </Text>
            </Box>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.SHOW_SECTIONS, false)}
              onChange={this.onChange(levelingTableSettings.SHOW_SECTIONS)}
            />
          </Box>
          <Box direction='row' pad='small' justify='between' align='center'>
            <Box>
              <Text weight='bold' color={titleColor}>Compact view</Text>
              <Text size='xsmall' color={colors.ANOTHER_GREY}>
                Fewer details in each cell. Hide text responses, plugs, alternates.
              </Text>
            </Box>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.COMPACT_VIEW, false)}
              onChange={this.onChange(levelingTableSettings.COMPACT_VIEW)}
            />
          </Box>
          <Box direction='row' pad='small' justify='between' align='center'>
            <Box>
              <Text weight='bold' color={titleColor}>Show declined bids</Text>
            </Box>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.SHOW_DECLINED, false)}
              onChange={this.onChange(levelingTableSettings.SHOW_DECLINED)}
            />
          </Box>
        </Box>
        <Box>
          <Box pad='xxsmall'>
            <Text color={labelColor}>Columns</Text>
          </Box>
          <Box direction='row' pad={{ horizontal: 'small', top: 'xsmall', bottom: 'small' }} justify='between' align='center'>
            <Text weight='bold' color={titleColor}>References</Text>
            <Tip content='Coming soon'>
              <Box>
                <Toggle
                  disabled
                  value={_.get(settings, levelingTableSettings.SHOW_REFERENCES, false)}
                  onChange={this.onChange(levelingTableSettings.SHOW_REFERENCES)}
                />
              </Box>
            </Tip>
          </Box>
          <Box direction='row' pad='small' justify='between' align='center'>
            <Text weight='bold' color={titleColor}>Scope quantities</Text>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.SHOW_QUANTITIES, false)}
              onChange={this.onChange(levelingTableSettings.SHOW_QUANTITIES)}
            />
          </Box>
          <Box direction='row' pad='small' justify='between' align='center'>
            <Text weight='bold' color={titleColor}>Hide project budget</Text>
            <Toggle
              disabled={isGE}
              value={_.get(settings, levelingTableSettings.HIDE_PROJECT_BUDGET, false)}
              onChange={this.onChange(levelingTableSettings.HIDE_PROJECT_BUDGET)}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  dropContent = () => (
    <Box className={IGNORE_OUTSIDE_CLICK_CLASS} fill pad={{ bottom: 'small' }}>
      {this.renderHeader()}
      <Box>{this.renderContent()}</Box>
    </Box>
  )

  render () {
    const { dropButtonLabel, dropButtonProps } = this.props
    const { isOpened } = this.state
    return (
      <DropButton
        label={dropButtonLabel}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={this.dropContent()}
        open={isOpened}
        onOpen={this.open}
        onClose={this.close}
        {...dropButtonProps}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(CustomizePanel)
