import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'

import { FIRST_PART_LENGTH, PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import { getInvitationData, acceptInvitation } from 'controllers/invitations'
import Loading from 'shared/pages/Loading'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
// import { userIdByPhone } from 'controllers/data'
import SetupProfile from 'webPages/inviteTeammate/SetupProfile'
import ValidatePhoneNumber from 'webPages/inviteTeammate/ValidatePhoneNumber'
import { checkVerificationCode, requestSmsCode } from 'controllers/auth'
import { navigateToHomePage } from 'controllers/init'

const getInvId = search => {
  const searchParams = new URLSearchParams(search)
  return searchParams.get('id').replace(' ', '+')
}

const InviteTeammateResolve = ({ user, location, dispatch }) => {
  const [inviteData, setInviteData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [isError, setError] = useState(false)
  const [step, setStep] = useState('profile')
  const [validationLoading, setValidationLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const aYT = new AsYouType('US')

  useEffect(() => {
    async function checkInvite () {
      const id = getInvId(location.search)
      setInviteData(await getInvitationData(id))
      setLoading(false)
    }
    checkInvite()
  }, [location.search])

  useEffect(() => {
    const checkAndAcceptInvite = async () => {
      const id = getInvId(location.search)
      const invite = await getInvitationData(id)
      if (invite) {
        acceptInvitation(invite)
      } else {
        dispatch(navigateToHomePage())
      }
    }

    if (!loading) {
      if (_.isNil(inviteData) || !inviteData) {
        if (_.isNil(user)) {
          universalNavigation.navigate(screens.AUTH)
        } else {
          setLoading(true)
          dispatch(navigateToHomePage())
        }
      } else if (!_.isNil(user)) {
        setLoading(true)
        checkAndAcceptInvite()
      }
    }
  }, [loading, inviteData, dispatch, user, location.search])

  if (loading) return <Loading />

  const handlePhoneChange = async event => {
    const v = event.target.value
    if (v && v.length <= FIRST_PART_LENGTH) {
      setPhone(v)
    } else if (v.length <= PHONE_NUMBER_LENGTH) {
      const formattedPhone = aYT.input(v)
      setPhone(formattedPhone)
    }
    setError(false)
    // setPresented(false)
  }

  const handleProfileSubmit = async () => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    const phoneNum = phoneNumber.number
    const isSent = await requestSmsCode(phoneNum)
    if (isSent) {
      setStep('validation')
    }
  }

  const onVerificationRes = res => {
    const er = _.get(res, 'error')
    if (er) {
      setValidationError(er)
      setValidationLoading(false)
    } else {
      dispatch(navigateToHomePage())
    }
  }

  const handleValidationSubmit = async code => {
    const email = _.get(inviteData, 'email')
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    setValidationLoading(true)
    const res = await checkVerificationCode(phoneNumber.number, code, email, name, true, _.get(inviteData, 'id', null))
    onVerificationRes(res)
  }

  const handleResend = async () => {
    setValidationLoading(true)
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    const phoneNum = phoneNumber.number
    await requestSmsCode(phoneNum)
    setValidationLoading(false)
  }

  if (step === 'profile') {
    return (
      <SetupProfile
        inviteData={inviteData}
        name={name}
        setName={setName}
        phone={phone}
        handlePhoneChange={handlePhoneChange}
        handleSubmit={handleProfileSubmit}
        isError={isError}
        phoneNumberComplete={_.isEqual(phone.length, PHONE_NUMBER_LENGTH)}
      />
    )
  } else if (step === 'validation') {
    return (
      <ValidatePhoneNumber
        handleSubmit={handleValidationSubmit}
        phone={phone}
        changePhone={() => setStep('profile')}
        loading={validationLoading}
        handleResend={handleResend}
        error={validationError}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user
})

export default connect(mapStateToProps)(InviteTeammateResolve)
