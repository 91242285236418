import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { db, fieldValue } from 'constants/firebase'
import store from 'model/store'
import { receivePrivateWorkOrders } from 'model/actions/privateWorkOrdersAC'
import { addListener } from 'controllers/listeners'

export const fetchPrivateWorkOrders = accountId => {
  try {
    console.log('fetch fetchPrivateWorkOrders, accountId:', accountId)
    const unsubscribe = db
      .collection('privateWorkOrders')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => {
            const wo = doc.data()
            _.set(res, doc.id, wo)
          })
          console.log('fetchPrivateWorkOrders received', _.size(res))
          store.dispatch(receivePrivateWorkOrders(res))
        },
        err => {
          console.log(`fetchPrivateWorkOrders error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('privateWorkOrders', unsubscribe)
  } catch (e) {
    console.log('fetchWorkOrders error', e)
    Sentry.captureException(e)
  }
}

export const updateAlternatesConf = (workOrderId, alternatesConf) => {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const privateWorkOrderExists = _.has(state, ['privateWorkOrders', workOrderId])
    // console.log('privateWorkOrderExists', privateWorkOrderExists)
    if (!privateWorkOrderExists) {
      await db
        .collection('privateWorkOrders')
        .doc(workOrderId)
        .set({ accountId }, { merge: true })
    }
    await db
      .collection('privateWorkOrders')
      .doc(workOrderId)
      .update({ alternatesConf })
  }
}

export const updateMerging = (workOrderId, merging) => {
  return async function (dispatch, getState) {
    console.log('update merging', workOrderId, merging)
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const privateWorkOrderExists = _.has(state, ['privateWorkOrders', workOrderId])
    // console.log('privateWorkOrderExists', privateWorkOrderExists)
    if (!privateWorkOrderExists) {
      await db
        .collection('privateWorkOrders')
        .doc(workOrderId)
        .set({ accountId }, { merge: true })
    }
    await db
      .collection('privateWorkOrders')
      .doc(workOrderId)
      .update({ merging })
  }
}

export const updatePlugs = (workOrderId, itemId, subId, plug) => {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const privateWorkOrderExists = _.has(state, ['privateWorkOrders', workOrderId])
    // console.log('privateWorkOrderExists', privateWorkOrderExists)
    if (!privateWorkOrderExists) {
      await db
        .collection('privateWorkOrders')
        .doc(workOrderId)
        .set({ accountId }, { merge: true })
    }
    const upd = {
      [`plugs.${accountId}.${itemId}.${subId}`]: plug
    }
    await db
      .collection('privateWorkOrders')
      .doc(workOrderId)
      .update(upd)
  }
}

export const removePlug = (workOrderId, itemId, subId) => {
  return async function (dispatch, getState) {
    const accountId = _.get(getState(), 'account.id')
    await db
      .collection('privateWorkOrders')
      .doc(workOrderId)
      .update({
        [`plugs.${accountId}.${itemId}.${subId}`]: fieldValue.delete()
      })
  }
}
