import React from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import styled from 'sc'

import { StyledText } from 'shared/documents/components/StyledComponents'

const InfoDetails = styled(View)`
  padding-top: ${props => props.getSize(6)}px;
`

const DocDate = ({ getSize, date }) => {
  return (
    <InfoDetails getSize={getSize}>
      <StyledText fontSize={getSize(12)} textAlign='right'>
        {date}
      </StyledText>
    </InfoDetails>
  )
}

export default DocDate
