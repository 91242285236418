import React from 'react'
import { Text, Platform, View } from 'react-native'
import styled from 'sc'
import _ from 'lodash'

const getFontFamily = isBold => {
  // if (Platform.OS === 'web') {
  //   return 'DejaVu'
  // } else {
  //   return isBold ? 'DejaVuBold' : 'DejaVu'
  // }
  return 'Lato'
}

export const StyledText = styled(Text)`
  /* flex: 1; */
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  font-family: ${props => getFontFamily(props.bold)};
  font-weight: ${props => (props.bold && Platform.OS === 'web' ? 'bold' : 'normal')};
  ${props => (props.italic ? 'font-style: italic;' : '')}
  ${props => (props.underline ? 'text-decoration: underline;' : '')}
  text-align: ${props => props.textAlign || 'left'};
  color: ${props => props.color || 'black'};
  line-height: 140%;
  /* line-height: ${props => (!_.isNil(props.fontSize) ? `${props.fontSize * 1.1}px` : '19.2px')}; */
  /* letter-spacing: ${props => (props.fontSize ? `${props.fontSize / 20}px` : '0.8px')};; */
  flex-wrap: nowrap;
  ${props => props.customstyle};
`

export const StyledView = styled(View)`
  ${props => props.customStyle || ''}
`

export const SpaceBetweenFlexDiv = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  ${props => props.customStyle || ''}
`
