import * as types from 'model/actionTypes'

export function logoutUser () {
  return {
    type: types.LOGOUT
  }
}

export function receiveUser (user) {
  return {
    type: types.RECEIVE_USER,
    user
  }
}

export function clear (user) {
  console.log('CLEAR CLEAR CLEAR CLEAR CLEAR CLEAR CLEAR')
  return {
    type: types.CLEAR
  }
}
