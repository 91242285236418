import React from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'

const LineItemAlternates = ({ li, parentLi, setShow, addAlternate }) => {
  const isNamed = _.size(_.filter(li.alternates, alt => alt.name === '')) === 0
  const amount = _.size(_.get(li, 'alternates'))
  const canCreate = !parentLi && isNamed
  return (
    <Box
      align='center'
      justify='center'
      border={['bottom', 'right']}
      onClick={() => {
        if (canCreate) addAlternate(li, setShow)
      }}
    >
      <Box
        customStyle={`
          position: relative;
          cursor: ${canCreate ? 'pointer' : 'not-allowed'};
          #amount {
            position: absolute;
            display: ${amount > 0 ? 'flex' : 'none'};
            left: -4px;
          }
          :hover {
            * {
              color: ${!canCreate ? colors.ANOTHER_GREY : colors.AQUA_MARINE};
              fill: ${!canCreate ? colors.ANOTHER_GREY : colors.AQUA_MARINE};
            };
            #amount {
              display: ${amount >= 0 && !parentLi ? 'flex' : 'none'}
            }
          }
          `}
        direction='row'
        align='center'
        gap='xxsmall'
      >
        <Text size='xsmall' id='amount' color={colors.ANOTHER_GREY}>
          {amount > 0 ? amount : '+'}
        </Text>
        <FontAwesomeIcon icon={faProjectDiagram} color={colors.ANOTHER_GREY} />
      </Box>
    </Box>
  )
}

export default LineItemAlternates
