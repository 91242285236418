import React, { useContext } from 'react'
import { Box, Text, CheckBox } from 'grommet'

import CreateBidContext from 'webPages/createBid/CreateBidContext'

const ElectronicalSign = () => {
  const { getField, setField } = useContext(CreateBidContext)

  const onChange = event => {
    setField('needsGCSig')(event.target.checked)
  }

  const renderSwitch = () => {
    return <CheckBox toggle checked={getField('needsGCSig', false)} onChange={onChange} />
  }

  return (
    <Box
      pad={{ horizontal: 'medium', top: 'small' }}
      direction='row'
      align='center'
      justify='between'
      height={{ min: '46px' }}
    >
      <Text>Needs to be electronically signed</Text>
      {renderSwitch()}
    </Box>
  )
}

export default ElectronicalSign
