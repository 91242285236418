import React, { useState, useContext } from 'react'
import { Box, Text, Button, ResponsiveContext } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import Logo from 'components/logos/LogoOnLight'
import colors from 'shared/constants/colors'
import CreateProjectForm from 'webPages/createProject/CreateProjectForm'
import screens from 'constants/screens'
import { saveProject } from 'controllers/project'
import PreconCreateProjectMobile from 'webPages/precon/mobile/PreconCreateProjectMobile'
import PreconContext from 'webPages/precon/PreconContext'

const PreconCreateProject = ({ projects, projectId, accountId, userId, queryParams }) => {
  const size = useContext(ResponsiveContext)
  const { preconConf, goToNextScreen, skipProjectCreation } = useContext(PreconContext)

  const [params, setParams] = useState({
    address: null,
    apartment: null,
    projectType: null,
    typeOfWork: null,
    size: null,
    attachments: null,
    startDate: null,
    timeZone: null,
    description: null,
    ..._.get(projects, projectId, {})
  })

  const onChange = k => v => {
    setParams({ ...params, [k]: v })
  }

  const goNext = async () => {
    const project = {
      id: projectId,
      accountId,
      createdAt: _.now(),
      createdBy: userId,
      ...params,
      preconConfId: preconConf.id
    }
    console.log('save project', project)
    await saveProject(project, [])
    goToNextScreen(screens.PRECON_PROJECT)
  }

  const disabled = !_.has(params, 'address.timeZone')

  // const skip = () => {
  //   console.log('skip')
  //   skipProjectCreation(screens.PRECON_PROJECT)
  // }

  if (size === 'small') {
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='28px' />
        </Box>
        <PreconCreateProjectMobile
          projectId={projectId}
          params={params}
          onChange={onChange}
          goNext={goNext}
          queryParams={queryParams}
        />
      </Box>
    )
  } else {
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='32px' />
        </Box>
        <Box flex={false} pad='medium' alignSelf='center' width='large'>
          <Box align='start' pad={{ bottom: 'medium' }}>
            <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
              Start your project
            </Text>
          </Box>
          <CreateProjectForm isNew projectId={projectId} params={params} onChange={onChange} />
          <Box pad={{ top: 'medium', horizontal: 'xlarge' }}>
            <Button size='large' primary label='Next' color={colors.AQUA_MARINE} disabled={disabled} onClick={goNext} />
          </Box>
          {/* <Box pad={{ top: 'small', horizontal: 'xlarge' }}>
            <Button size='large' secondary label='Skip' color={colors.LIGHT_NAVY_BRIGHT} onClick={skip} />
          </Box> */}
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  accountId: _.get(state, 'account.id'),
  userId: _.get(state, 'user.id')
})

export default connect(mapStateToProps)(PreconCreateProject)
