import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Box, Button } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import ScopeOfWork from 'webPages/workOrder/ScopeOfWork'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { saveScopeOfWork } from 'controllers/workOrder'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { fetchMessagesWeb } from 'controllers/chat'
import LayoutContext from 'webPages/layout/LayoutContext'

const WorkOrder = ({ projectId, workOrderId, workOrder, dispatch, accountProfile, account }) => {
  const [sow, _setSow] = useState(_.get(workOrder, 'scope', {}))
  const [sections, _setSections] = useState(_.get(workOrder, 'sections', []))
  const [hasChanges, setHasChanges] = useState(false)
  const { openConfirmationModal, closeConfirmationModal } = useContext(LayoutContext)
  const accountId = _.get(account, 'id')

  const setSow = v => {
    _setSow(v)
    setHasChanges(true)
  }

  const setSections = v => {
    _setSections(v)
    setHasChanges(true)
  }

  const showConfirmation = useCallback(async () => {
    if (!hasChanges) {
      console.log('no changes, return true')
      return true
    }
    const res = await new Promise((resolve, reject) => {
      const onDiscard = () => {
        closeConfirmationModal()
        resolve(true)
      }
      const onSave = () => {
        closeConfirmationModal()
        dispatch(saveScopeOfWork(workOrderId, sow, sections))
        resolve(true)
      }
      const renderConfirmationButtons = () => {
        return (
          <Box direction='row' align='center' justify='between' flex gap='medium' pad={{ top: 'small' }}>
            <Button
              customStyle='flex: 1'
              primary
              color={colors.CORAL_TWO}
              label='Discard changes'
              onClick={onDiscard}
            />
            <Button customStyle='flex: 1' primary color={colors.AQUA_MARINE} label='Save to scope' onClick={onSave} />
          </Box>
        )
      }

      openConfirmationModal({
        title: 'You have unsaved changes',
        renderButtons: renderConfirmationButtons
      })
    })
    console.log('promise res', res)
    return res
  }, [workOrderId, sow, sections, openConfirmationModal, closeConfirmationModal, dispatch, hasChanges])

  useEffect(() => {
    universalNavigation.setBlock(showConfirmation)
    return () => {
      universalNavigation.resetBlock()
    }
  }, [showConfirmation])

  useEffect(() => {
    if (!_.isNil(workOrderId)) fetchMessagesWeb(workOrderId, accountId)
  }, [workOrderId, accountId])

  const onDoneClick = useCallback(() => {
    console.log('onDoneClick', sow)
    universalNavigation.resetBlock()
    dispatch(saveScopeOfWork(workOrderId, sow, sections))
    universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, { projectId, workOrderId })
  }, [dispatch, projectId, sections, sow, workOrderId])

  return (
    <Box background={colors.WHITE} height='100%'>
      <ScopeOfWork
        workOrderId={workOrderId}
        sow={sow}
        setSow={setSow}
        gcProfile={accountProfile}
        sectionsDB={sections}
        setSections={setSections}
        onDoneClick={onDoneClick}
        projectId={projectId}
      />
    </Box>
  )
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrder(state, props),
  accountProfile: getCurrentAccountProfile(state),
  account: state.account
})

export default connect(mapStateToProps)(WorkOrder)
