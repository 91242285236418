import { createSelector } from 'reselect'
import _ from 'lodash'

import bidStatus from 'shared/constants/bidStatus'
import requestType from 'shared/constants/requestType'
import { getOwnProjectsInfo, getProjectsInfo, getProjectsIdsDict } from 'model/selectors/projectsInfoSelector'
import { getName } from 'shared/utils/stringUtils'
import { getBidStatusType } from 'shared/utils/bid'
import {
  getBids,
  getWorkOrders,
  getAccountsProfiles,
  getProfiles,
  getAccountId,
  getUserId,
  getProjectId
} from 'model/selectors/base'
import { toTimestamp } from 'shared/utils/date'
import lineItemType from 'shared/constants/lineItemType'

export const getPendingBids = createSelector(
  [getBids, getUserId, getOwnProjectsInfo, getWorkOrders, getAccountsProfiles, getProfiles],
  (bids, userId, ownProjects, workOrders, accountsProfiles, profiles) => {
    const res = {
      byProject: {},
      byProjectPendingAmout: 0,
      byWorkOrder: {},
      byProjectAccountId: {},
      pending: []
    }
    _.forEach(bids, bid => {
      const hasAccess = _.has(ownProjects, bid.projectId)
      if (hasAccess) {
        const profile = _.get(accountsProfiles, bid.accountId, _.get(profiles, bid.accountId))
        const avatar = _.get(profile, 'avatarSmall')
        const wo = _.get(workOrders, bid.workOrderId)
        const status = getBidStatusType(bid, wo)
        const seen = _.has(bid, ['seenBy', userId])
        const timestamp = toTimestamp(_.get(bid, 'timestamp', 0))
        const r = {
          id: `bid_${bid.id}`,
          bidId: bid.id,
          type: requestType.BID,
          subName: getName(profile),
          address: _.get(wo, 'projectAddress.structured.main'),
          workOrderId: bid.workOrderId,
          accountId: bid.accountId,
          timestamp,
          total: _.get(bid, 'total', null),
          status,
          avatar,
          seen,
          needsAction: status === bidStatus.NEW,
          isPending: !seen || status === bidStatus.NEW
        }
        _.set(res, ['byProject', bid.projectId, bid.id], r)
        _.set(res, ['byWorkOrder', bid.workOrderId, bid.id], r)
        _.set(res, ['byProjectAccountId', bid.projectId, bid.accountId, bid.id], r)
        if (!r.seen || r.needsAction) {
          _.set(
            res,
            ['byProjectPendingAmount', bid.projectId],
            _.get(res, ['byProjectPendingAmount', bid.projectId], 0) + 1
          )
        }
        if (r.isPending) {
          res.pending.push(r)
        }
      }
    })
    return res
  }
)

export const getBidsByWorkOrders = createSelector(
  [getBids, getProjectsIdsDict, getWorkOrders],
  (bids, projectsIdsDict, workOrders) => {
    const res = {}
    const sortedBids = _.reverse(_.sortBy(bids, b => toTimestamp(b.timestamp)))
    const uniqBids = _.uniqBy(sortedBids, b => `${b.workOrderId}_${b.accountId}`)
    // console.log('uniqBids', uniqBids)
    // console.log(
    //   'getBidsByWorkOrders, all bids amount',
    //   _.size(bids),
    //   'sortedBidsAmount',
    //   _.size(sortedBids),
    //   'uniqBidsAmount',
    //   _.size(uniqBids)
    // )
    _.forEach(uniqBids, bid => {
      const wo = _.get(workOrders, bid.workOrderId)
      const invitations = _.get(wo, 'invitations', {})
      const projectId = _.get(workOrders, [bid.workOrderId, 'projectId'])
      if (_.has(projectsIdsDict, projectId) && _.has(invitations, bid.accountId)) {
        _.set(res, [bid.workOrderId, bid.id], bid)
      }
    })
    return res
  }
)

export const getBidsByWorkOrdersAccounts = createSelector(
  [getBids, getProjectsInfo, getWorkOrders],
  (bids, projects, workOrders) => {
    const res = {}
    _.forEach(bids, bid => {
      const wo = _.get(workOrders, bid.workOrderId)
      const invitations = _.get(wo, 'invitations', {})
      const projectId = _.get(workOrders, [bid.workOrderId, 'projectId'])
      if (_.has(projects, projectId) && _.has(invitations, bid.accountId)) {
        _.set(res, [bid.workOrderId, bid.accountId], bid)
      }
    })
    return res
  }
)

export const getMyBidsByWorkOrders = createSelector(
  [getBids, getAccountId, getWorkOrders],
  (bids, accountId, workOrders) => {
    const res = {}
    _.forEach(bids, bid => {
      const wo = _.get(workOrders, bid.workOrderId)
      const invitations = _.get(wo, 'invitations', {})
      if (bid.accountId === accountId && _.has(invitations, bid.accountId)) {
        _.set(res, bid.workOrderId, bid)
      }
    })
    return res
  }
)

export const getBidsByProject = createSelector([getPendingBids, getProjectId], (pendingBids, projectId) => {
  return _.get(pendingBids, ['byProject', projectId], {})
})

export const getBidsByProjectSub = createSelector([getBidsByProject], projectBids => {
  return _.reduce(
    projectBids,
    (res, bid) => {
      _.set(res, [bid.accountId, bid.id], bid)
      return res
    },
    {}
  )
})

export const getAllAccountsItems = createSelector(
  [getBids, getWorkOrders, getAccountId],
  (bids, workOrders, accountId) => {
    const res = {}
    const namesList = []
    _.forEach(bids, bid => {
      if (bid.accountId === accountId) {
        _.forEach(_.get(bid, 'items'), item => {
          const id = _.get(item, 'id')
          const name = _.get(item, 'name', '')
          const labor = _.get(item, 'labor', false)
          const required = _.get(item, 'required', false)
          const type = _.get(item, 'type', lineItemType.DEFAULT)
          const desc = _.get(item, 'desc', '')
          if (!_.has(res, id) && !_.includes(namesList, name) && name.trim() !== '') {
            namesList.push(name)
            res[id] = { id, name, desc, labor, type, required }
          }
        })
      }
    })
    _.forEach(workOrders, wo => {
      if (wo.accountId === accountId) {
        _.forEach(_.get(wo, 'scope'), item => {
          const id = _.get(item, 'id')
          const name = _.get(item, 'name', '')
          const labor = _.get(item, 'labor', false)
          const required = _.get(item, 'required', false)
          const type = _.get(item, 'type', lineItemType.DEFAULT)
          const desc = _.get(item, 'desc', '')
          if (!_.has(res, id) && !_.includes(namesList, name) && name.trim() !== '') {
            namesList.push(name)
            res[id] = { id, name, desc, labor, type, required }
          }
        })
      }
    })
    return res
  }
)
