import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { auth, db, firestore } from 'constants/firebase'
import { addListener } from 'controllers/listeners'
import { receiveBids } from 'model/actions/bidsAC'
import store from 'model/store'
import { addSystemMessage, sendDocMessage } from 'shared/controllers/chat'
import { makeChannelKey } from 'shared/utils/chatUtils'
import messageType from 'shared/constants/messageType'
import bidStatus from 'shared/constants/bidStatus'
import { getBidsByWorkOrders } from 'model/selectors/bids'
import { getBidStatusType } from 'shared/utils/bid'
import { saveImageBase64DataUrl, deleteFile } from 'shared/controllers/storage'
import { addTradeId } from 'controllers/account'
import {
  getFormattedTimeObject,
  formatProfile,
  getWorkOrderSendingData,
  arrayToSentence
} from 'shared/utils/stringUtils'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import courierEventsIds from 'shared/constants/courierEvents'
import sendTypes from 'shared/constants/inviteStatus'

export const fetchBids = accountId => {
  try {
    console.log('fetchBids, accountId:', accountId)
    const state = store.getState()
    const isGC = _.get(state, 'account.isGC', false)
    const showArchived = _.get(state, 'settings.showArchived', false) || !isGC
    const allBidsRef = db.collection('bids').where('accounts', 'array-contains', accountId)
    const activeBidsRef = db
      .collection('bids')
      .where('accounts', 'array-contains', accountId)
      .where('deleted', '==', 0)
    const ref = showArchived ? allBidsRef : activeBidsRef
    const unsubscribe = ref.onSnapshot(
      sn => {
        const res = {}
        sn.forEach(doc => {
          const bid = doc.data()
          _.set(res, doc.id, bid)
        })
        console.log('fetchBids:', _.size(res))
        store.dispatch(receiveBids(res))
      },
      err => {
        Sentry.captureException(err)
        console.log(`fetchBids error: ${err}`)
      }
    )
    addListener('bids', unsubscribe)
  } catch (e) {
    console.log('fetchBids error', e)
    Sentry.captureException(e)
  }
}

export function sendBid (bid) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    if (!_.isEmpty(bid) && !_.isNil(userId)) {
      try {
        const state = getState()
        const accountId = _.get(state, 'account.id')
        const isEdit = _.has(state, ['bids', bid.id])
        const isSubEdit = accountId === bid.accountId
        bid.timestamp = firestore.FieldValue.serverTimestamp()
        if (isEdit) {
          const bidPrevState = _.get(state, ['bids', bid.id])
          dispatch(sendUpdateBidMessage(bidPrevState, bid))
          delete bid.removedItems

          const bidTimestamp = _.get(state, ['bids', bid.id, 'timestamp'])
          const bidCreator = _.get(state, ['bids', bid.id, 'createdBy'])
          await db
            .collection('bids')
            .doc(bid.id)
            .update({
              ...bid,
              timestamp: bidTimestamp,
              createdBy: bidCreator,
              updates: firestore.FieldValue.arrayUnion({
                createdBy: userId,
                timestamp: _.now()
              })
            })
        } else {
          await db
            .collection('bids')
            .doc(bid.id)
            .set({ ...bid, deleted: 0 })
        }
        const userUpd = { [`quoteDrafts.${bid.workOrderId}`]: firestore.FieldValue.delete() }
        await db
          .collection('users')
          .doc(userId)
          .update(userUpd)

        if (isSubEdit) {
          const doc = {
            type: messageType.BID,
            bidId: bid.id
          }
          const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, bid.accountId)
          const strAddress = `${_.get(bid, 'projectAddress.structured.main')}, ${_.get(bid, 'projectAddress.city')}`
          const action = isEdit ? 'edited' : 'created'
          const message = `Bid ${action} for the ${bid.typeOfWork} project in ${strAddress}`
          sendDocMessage(channelKey, message, doc)
        } else {
          console.log('skip adding messages to chat if GC creates the bid instead of Sub')
        }
        const workOrder = _.get(state, ['workOrders', bid.workOrderId])
        const isSubProposal = _.get(workOrder, 'isSubProposal', false)
        if (isSubProposal && _.has(bid, 'tradeId') && isSubEdit) {
          dispatch(addTradeId(bid.tradeId))
        }
        const acceptedBy = _.keys(_.get(workOrder, 'acceptedBy'))
        if (!_.includes(acceptedBy, bid.accountId) && isSubEdit) {
          console.log('bid created but invite is not accepted, adding now')
          await db
            .collection('workOrders')
            .doc(bid.workOrderId)
            .update({
              [`acceptedBy.${bid.accountId}`]: {
                userId: _.get(state, 'user.id'),
                timestamp: _.now()
              }
            })
        }
      } catch (e) {
        console.warn('sendBid: bid was not sent: ', e)
        Sentry.captureException(e)
      }
    }
  }
}

const sendUpdateBidMessage = (prevBid, newBid) => {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      const contractorTypes = _.get(state, 'references.contractorTypes')

      const prevItems = _.values(_.get(prevBid, 'items'))
      const newItems = _.values(_.get(newBid, 'items'))

      const getUserProfile = id => _.get(state, ['profiles', id])
      const getAccountProfile = id => _.get(state, ['accountsProfiles', id])

      const fieldsToCompare = ['quantity', 'quantityType', 'total', 'cost', 'name']
      const getChanges = (li, prevLi) => {
        const changes = _.reduce(
          _.omitBy(li, _.isNil),
          (result, value, key) =>
            !prevLi
              ? result
              : !_.isEqual(value, prevLi[key]) && _.includes(fieldsToCompare, key)
                ? result.concat(key)
                : result,
          []
        )
        return arrayToSentence(changes)
      }

      const subUserProfile = formatProfile(_.get(state, ['profiles', auth.currentUser.uid]))

      const gcAccountId = _.get(newBid, 'workOrderAccountId')
      const gcAccountProfile = formatProfile(getAccountProfile(gcAccountId))

      const subAccountId = _.get(newBid, 'accountId')
      const subAccountProfile = formatProfile(getAccountProfile(subAccountId))

      const workOrderId = _.get(newBid, 'workOrderId')
      const workOrderData = _.get(state, ['workOrders', workOrderId])
      const projectId = _.get(workOrderData, 'projectId')
      const workOrder = getWorkOrderSendingData(workOrderData, contractorTypes)

      const addedLineItems = _.filter(
        _.differenceBy(newItems, prevItems, 'id'),
        li => !_.includes(_.keys(_.get(newBid, 'scope')), li.id)
      )
      const removedLineItems = _.values(_.get(newBid, 'removedItems'))
      const updatedLineItems = _.filter(
        _.differenceBy(newItems, prevItems, 'updatedAt'),
        li => !_.includes(addedLineItems, li)
      )
      if (_.size(removedLineItems) > 0 || _.size(addedLineItems) > 0 || _.size(updatedLineItems) > 0) {
        const added = _.map(addedLineItems, li => ({
          id: li.id,
          name: _.get(li, 'name', ''),
          addedAt: getFormattedTimeObject(_.get(li, 'createdAt')),
          addedBy: formatProfile(getUserProfile(_.get(li, 'addedBy')))
        }))
        const removed = _.map(removedLineItems, li => ({
          id: li.id,
          name: _.get(li, 'name', ''),
          removedAt: getFormattedTimeObject(_.get(li, 'removedAt', _.now())),
          removedBy: formatProfile(getUserProfile(_.get(li, 'removedBy')))
        }))
        const updated = _.map(updatedLineItems, li => ({
          id: li.id,
          name: _.get(li, 'name', _.get(newBid, ['scope', li.id, 'name'])),
          change: getChanges(li, _.get(prevBid, ['items', li.id])),
          updatedAt: getFormattedTimeObject(_.get(li, 'updatedAt', _.now())),
          updatedBy: formatProfile(getUserProfile(_.get(li, 'updatedBy')))
        }))
        const gcUrl = `${config.gcHost}/sub/project/${projectId}/workorder/${workOrderId}/leveling`

        const sendDataGC = {
          event: courierEventsIds.bid_updated_gc,
          list: `${gcAccountId}_account`,
          data: {
            items: {
              added,
              removed,
              updated
            },
            url: gcUrl,
            workOrder,
            accountsProfiles: {
              gc: gcAccountProfile,
              sub: subAccountProfile
            },
            usersProfiles: {
              gc: subUserProfile
            }
          }
        }

        const functionUrl = `${config.dynamicUrl}/proto/sendMessageFromClient`
        const currentUser = auth.currentUser
        const authToken = await currentUser.getIdToken()
        const body = {
          authToken,
          sendDataGC
        }
        const response = await fetch(functionUrl, {
          method: 'post',
          headers: headers,
          body: JSON.stringify(body)
        })
        if (response.status !== 200) {
          console.log('sendResolveLineItemMessage response status', response.status)
        }
      }
    } catch (error) {
      console.log('sendUpdateBidMessage error: ', error.message)
    }
  }
}

export function sendBidByGC (bid) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    if (!_.isEmpty(bid) && !_.isNil(userId)) {
      try {
        const state = getState()
        const accountId = _.get(state, 'account.id')
        if (_.isEqual(_.get(bid, 'accountId'), accountId)) {
          const workOrderId = _.get(bid, 'workOrderId')
          await db
            .collection('workOrders')
            .doc(workOrderId)
            .update({
              [`invitations.${accountId}`]: {
                sendType: sendTypes.SILENT,
                subAccountId: accountId,
                timestamp: _.now()
              }
            })
        }
        const isEdit = _.has(state, ['bids', bid.id])
        bid.timestamp = firestore.FieldValue.serverTimestamp()
        if (isEdit) {
          const bidTimestamp = _.get(state, ['bids', bid.id, 'timestamp'])
          const bidCreator = _.get(state, ['bids', bid.id, 'createdBy'])
          await db
            .collection('bids')
            .doc(bid.id)
            .update({
              ...bid,
              timestamp: bidTimestamp,
              createdBy: bidCreator,
              updates: firestore.FieldValue.arrayUnion({
                createdBy: userId,
                timestamp: _.now()
              })
            })
        } else {
          await db
            .collection('bids')
            .doc(bid.id)
            .set(bid)
        }
      } catch (e) {
        console.warn('sendBid: bid was not sent: ', e)
        Sentry.captureException(e)
      }
    }
  }
}

export function deleteBid (bidId) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const bid = _.get(state, ['bids', bidId])
      const workOrder = _.get(state, ['workOrders', _.get(bid, 'workOrderId')])
      const bidStatusType = getBidStatusType(bid, workOrder)
      if (bidId && bidStatusType === bidStatus.NEW) {
        await db
          .collection('bids')
          .doc(bidId)
          .delete()
      } else {
        console.warn('deleteBid: no bidId was passed')
      }
    } catch (e) {
      Sentry.captureException(e)
      console.warn('deleteBid: bid was not deleted: ', e)
    }
  }
}

export function declineBid (bid) {
  return async function (dispatch, getState) {
    try {
      console.log('decline bid', bid)
      const uid = auth.currentUser.uid
      const status = {
        type: bidStatus.DECLINED,
        userId: uid,
        timestamp: firestore.FieldValue.serverTimestamp()
      }
      const text = 'The bid is declined'
      const doc = { type: messageType.BID, bidId: bid.id }
      const upd = {
        status,
        seenBy: { uid: _.now() }
      }
      await db
        .collection('bids')
        .doc(bid.id)
        .update(upd)
      const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, bid.accountId)
      sendDocMessage(channelKey, text, doc)
    } catch (e) {
      Sentry.captureException(e)
      console.log('declineBid error:', e.message)
    }
  }
}

export function hideBid (bid) {
  return async function (dispatch, getState) {
    try {
      await db
        .collection('bids')
        .doc(bid.id)
        .update({
          [`status.hidden`]: true
        })
    } catch (e) {
      Sentry.captureException(e)
      console.log('hideBid error:', e.message)
    }
  }
}

export function approveBid (bid) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const uid = auth.currentUser.uid
      const status = {
        type: bidStatus.ACCEPTED,
        userId: uid,
        timestamp: firestore.FieldValue.serverTimestamp()
      }
      const upd = {
        status,
        seenBy: { [uid]: _.now() }
      }
      await db
        .collection('bids')
        .doc(bid.id)
        .update(upd)
      await db
        .collection('workOrders')
        .doc(bid.workOrderId)
        .update({ approvedBidId: bid.id })
      const doc = { type: messageType.BID, bidId: bid.id }
      const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, bid.accountId)
      sendDocMessage(channelKey, 'The bid is approved', doc)

      const wo = _.get(state, ['workOrders', bid.workOrderId])
      const members = [..._.get(wo, 'accounts', [])]
      _.remove(members, id => _.get(state, 'account.id'))
      _.remove(members, id => _.get(state, bid.accountId))
      const bidsByWorkOrders = getBidsByWorkOrders(state)
      const woBids = _.get(bidsByWorkOrders, bid.workOrderId, {})
      const bidsByAccountId = {}
      _.forEach(woBids, b => _.set(bidsByAccountId, b.accountId, b))
      for (const mId of members) {
        const memberBid = _.get(bidsByAccountId, mId)
        // ? const bid = _.get(members, [mId, 'bid'])
        if (_.isNil(memberBid)) {
          const text = `The work offer '${wo.label}' is expired.`
          const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, mId)
          addSystemMessage(channelKey, text)
        } else {
          const text = 'The bid is declined'
          const doc = {
            type: messageType.BID,
            bidId: memberBid.id
          }
          const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, mId)
          sendDocMessage(channelKey, text, doc)
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log('approveBid error:', e.message)
    }
  }
}

export async function removeApprovedBid (bid) {
  try {
    await db
      .collection('workOrders')
      .doc(bid.workOrderId)
      .update({ approvedBidId: firestore.FieldValue.delete() })
    await db
      .collection('bids')
      .doc(bid.id)
      .update({ status: firestore.FieldValue.delete() })
  } catch (e) {
    console.log('removeApprovedBid', e.message)
  }
}

export async function removeBid (bidId) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const functionUrl = `${config.dynamicUrl}/proto/removeBid`
    const body = {
      authToken,
      bidId
    }
    const response = await window.fetch(functionUrl, {
      method: 'post',
      headers,
      body: JSON.stringify(body)
    })
    if (response.status !== 200) {
      console.log('removeBid response status', response.status)
    }
  } catch (e) {
    console.log('removeBid error', e.message)
  }
}

export function setBidSeen (bidId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = { [`seenBy.${userId}`]: _.now() }
    await db
      .collection('bids')
      .doc(bidId)
      .update(upd)
  }
}

export const updateBid = async (bidId, upd) => {
  await db
    .collection('bids')
    .doc(bidId)
    .update(upd)
}

export const updateBidFiles = async (bidId, files) => {
  await db
    .collection('bids')
    .doc(bidId)
    .update({ files })
}

export const saveBidSignature = async (base64str, bidId, onProgress) => {
  const path = `bidsSignature/${bidId}`
  const res = await saveImageBase64DataUrl(path, base64str, onProgress)
  return res
}

export function saveBidSignatureInfo (sigInfo, bid) {
  return async function (dispatch, getState) {
    try {
      console.log('save bid signature info:', sigInfo, 'bidId:', bid.id)
      const upd = {
        'signatures.gc': sigInfo
      }
      console.log('upd', upd)
      await db
        .collection('bids')
        .doc(bid.id)
        .update(upd)
      dispatch(approveBid(bid))
    } catch (e) {
      console.log('saveBidSignatureInfo error:', e)
      Sentry.captureException(e)
    }
  }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const downloadEstimatePdf = async bid => {
  try {
    const url = `${config.dynamicUrl}/proto/downloadEstimatePdf`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ bid })
    })
    const res = await response.json()
    const base64String = _.get(res, 'base64')
    // console.log('downloadEstimatePdf answer', base64String)
    if (!_.isNil(base64String)) {
      const blob = b64toBlob(base64String, 'application/pdf')
      const fileUrl = URL.createObjectURL(blob)
      window.open(fileUrl)
      return null
    } else {
      return null
    }
  } catch (e) {
    console.log('downloadEstimatePdf error:', e)
    Sentry.captureException(e)
    return null
  }
}

export const removeBidFile = async (workOrderId, bidId, fileId, needToRemoveFromStorage = true) => {
  try {
    await db
      .collection('bids')
      .doc(bidId)
      .update({
        [`files.${fileId}`]: firestore.FieldValue.delete()
      })
    if (needToRemoveFromStorage) {
      const storagePath = `workOrders/${workOrderId}/bid/${bidId}/${fileId}`
      deleteFile(storagePath)
    }
  } catch (error) {
    console.log(error.message)
  }
}
