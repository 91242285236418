import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { DropButton, Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import SubsListModeContent from 'components/leveling/createBidManuallyDrop/SubsListModeContent'
import colors from 'shared/constants/colors'
import InviteSubDrop from 'components/workOrder/InviteSubDrop'

const inviteSubDropLabel = (
  <Box direction='row' gap='small'>
    <Box direction='row'>
      <FontAwesomeIcon icon={faUserPlus} size={18} />
    </Box>
    <Text>Invite Subs</Text>
  </Box>
)

class CreateBidManuallyDrop extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpened: false,
      subsListMode: true
    }
  }

  open = () =>
    this.setState({
      isOpened: true,
      subsListMode: !_.isEmpty(this.props.invitationsWithNoBids)
    })

  close = () => this.setState({ isOpened: false })

  onNewClick = () => {
    this.setState({ subsListMode: false })
  }

  onSubClick = subId => {
    console.log('onSubClick', subId)
    const { onOpen } = this.props
    this.close()
    onOpen(subId)
  }

  dropContent = () => {
    const { subsListMode } = this.state
    const { workOrderId, invitationsWithNoBids, invitations } = this.props
    if (subsListMode) {
      return (
        <SubsListModeContent
          workOrderId={workOrderId}
          invitationsWithNoBids={invitationsWithNoBids}
          onNewClick={this.onNewClick}
          onSubClick={this.onSubClick}
          invitations={invitations}
        />
      )
    } else {
      return (
        <InviteSubDrop
          key='inviteSubDrop'
          dropButtonProps={{ color: colors.ASANA_GRAY_TEXT_HOVERED, size: 'small' }}
          dropButtonLabel={inviteSubDropLabel}
          workOrderId={workOrderId}
          fromManual
          onSelectCallback={this.close}
        />
      )
    }
  }

  render () {
    const { dropButtonLabel, dropButtonProps } = this.props
    const { isOpened } = this.state
    return (
      <DropButton
        label={dropButtonLabel}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={this.dropContent()}
        open={isOpened}
        onOpen={this.open}
        onClose={this.close}
        {...dropButtonProps}
      />
    )
  }
}

CreateBidManuallyDrop.defaultProps = {}

CreateBidManuallyDrop.propTypes = {
  invitationsWithNoBids: PropTypes.array,
  invitations: PropTypes.array,
  dropButtonLabel: PropTypes.node,
  workOrderId: PropTypes.string,
  dropButtonProps: PropTypes.object,
  onOpen: PropTypes.func
}

const mapsStateToProps = state => ({
  accountsProfiles: state.accountsProfiles
})

export default connect(mapsStateToProps)(CreateBidManuallyDrop)
