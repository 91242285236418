import React from 'react'
import _ from 'lodash'
import { View, Image } from 'react-native'
import styled from 'sc'

import lineItemType from 'shared/constants/lineItemType'
import { StyledText } from 'shared/documents/components/StyledComponents'
import DocTableHeader from 'shared/documents/components/DocTableHeader'
import colors from 'shared/constants/colors'
import lineItemSection from 'shared/constants/lineItemSection'

const LineItemRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.getSize(3)}px;
  padding-bottom: ${props => props.getSize(3)}px;
  min-height: ${props => props.getSize(24)}px;
  ${props => props.customStyle || ''}
`

const Cell = styled(View)`
  display: flex;
  flex: ${props => props.flex || 1};
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  ${props => props.customStyle || ''}
`

const AnswerImage = styled(Image)`
  width: ${props => props.getSize(12)}px;
  height: ${props => props.getSize(12)}px;
  margin-right: ${props => props.getSize(6)}px;
`
const AnswerContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const answerYesImg =
  'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2FhandUp.png?alt=media&token=504b4d28-5774-4a5f-baf9-17e40c9288e0'
const answerNoImg =
  'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2FhandDown.png?alt=media&token=b60319f3-a77a-4237-99f7-719db2258109'

const renderAnswer = (getSize, item, ssr) => {
  const itemType = _.get(item, 'type', lineItemType.DEFAULT)
  if (_.isNil(item.answer)) {
    return null
  } else if (itemType === lineItemType.QUESTION) {
    return (
      <StyledText fontSize={getSize(12)} textAlign='center' color={colors.LIGHT_NAVY_BRIGHT} italic>
        {item.answer}
      </StyledText>
    )
  } else {
    const imgUrl = item.answer ? answerYesImg : answerNoImg
    return (
      <AnswerContainer>
        <AnswerImage
          getSize={getSize}
          source={{ uri: imgUrl }}
          defaultSource={ssr ? { uri: imgUrl } : undefined}
          resizeMode='contain'
        />
        <StyledText fontSize={getSize(12)} color={colors.LIGHT_NAVY_BRIGHT}>
          {item.answer ? 'Yes' : 'No'}
        </StyledText>
      </AnswerContainer>
    )
  }
}

const renderItemRow = (getSize, item, ssr, keyPrefix) => {
  // console.log('renderItemRow', item)
  return (
    <LineItemRow getSize={getSize} key={`${keyPrefix}_${item.id}`}>
      <Cell>
        <StyledText fontSize={getSize(12)}>{item.name}</StyledText>
      </Cell>
      <Cell
        customStyle={`
          justify-content: center;
          padding-left: ${getSize(12)}px;
          padding-right: ${getSize(12)}px
        `}
      >
        {renderAnswer(getSize, item, ssr)}
      </Cell>
    </LineItemRow>
  )
}

const renderTableHeader = (getSize, keyPrefix) => (
  <DocTableHeader
    key={`${keyPrefix}_questionsTableHeader`}
    getSize={getSize}
    title='Questions and Answers'
    customStyle={`padding-top: ${getSize(20)}px;`}
  />
)

export const renderQuestons = (getSize, items, scope, sections, ssr, keyPrefix = '') => {
  const allIds = _.sortBy(_.uniq([..._.keys(scope), ..._.keys(items)]))
  const geSection = _.find(sections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
  const geItems = _.get(geSection, 'items', [])
  const idsWithNoGE = _.difference(allIds, geItems)
  const res = _.reduce(
    idsWithNoGE,
    (res, itemId) => {
      const item = {
        ..._.get(items, itemId, {}),
        ..._.get(scope, itemId, {})
      }
      const itemType = _.get(item, 'type', lineItemType.DEFAULT)
      if (itemType !== lineItemType.DEFAULT && !_.isNil(item, 'answer')) {
        return [...res, renderItemRow(getSize, item, ssr, keyPrefix)]
      } else {
        return res
      }
    },
    []
  )
  if (!_.isEmpty(res)) {
    return [renderTableHeader(getSize, keyPrefix), ...res]
  } else {
    return []
  }
}
