import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, TextInput, ThemeContext, Keyboard } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import DropDownMenu from 'shared/components/DropDownMenu'
import lineItemSection from 'shared/constants/lineItemSection'
import { generateId } from 'constants/firebase'
import { useStateWithCallbackLazy } from 'shared/utils/hooks'

const SectionsSelector = ({ sections, selectedSection, setSelectedSection, containerProps }) => {
  const [createMode, setCreateMode] = useStateWithCallbackLazy(false)
  const inputRef = useRef()

  const sowSection = _.find(sections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)

  const onMenuOptionClick = v => {
    console.log('onMenuOptionClick', v)
    if (_.isNil(v)) {
      setSelectedSection({ id: generateId(), title: '' })
      setCreateMode(true, () => inputRef.current.focus())
    } else {
      const s = _.find(sections, s => s.id === v)
      console.log('setSelectedSection', s)
      setSelectedSection(s)
    }
  }

  const exitCreateMode = () => {
    setCreateMode(false)
  }

  const createSectionOption = {
    label: (
      <Box
        // border='top'
        direction='row'
        align='center'
        flex
        // pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='xsmall'
      >
        <FontAwesomeIcon icon={faPlusCircle} size='1x' color={colors.CLEAR_BLUE} />
        <Text weight={500} color={colors.CLEAR_BLUE}>
          Create a new Section
        </Text>
      </Box>
    ),
    id: null
  }

  const onSectionNameChange = e => {
    const v = e.target.value
    if (_.isEmpty(v)) {
      setSelectedSection(sowSection)
      setCreateMode(false)
    } else {
      setSelectedSection({ ...selectedSection, title: v })
    }
  }

  const menuOptions = [..._.map(sections, s => ({ id: s.id, label: s.title })), createSectionOption]

  const textInputThemeValue = {}

  const renderInputOrSelect = () => {
    if (createMode) {
      return (
        <Box direction='row' width={{ min: '330px' }} height='36px' border='all' round='xsmall' align='center'>
          <ThemeContext.Extend value={textInputThemeValue}>
            <Keyboard onEnter={exitCreateMode}>
              <TextInput
                border='none'
                placeholder='Create a new section title...'
                value={_.get(selectedSection, 'title', '')}
                onChange={onSectionNameChange}
                plain
                pad='none'
                margin='none'
                size='small'
                ref={inputRef}
                onBlur={exitCreateMode}
              />
            </Keyboard>
          </ThemeContext.Extend>
        </Box>
      )
    } else {
      return (
        <DropDownMenu
          options={menuOptions}
          dropContentProps={{ boxProps: { width: { min: '160px' } } }}
          dropAlign={{ top: 'bottom', left: 'left' }}
          onOptionClick={onMenuOptionClick}
        >
          <Box
            direction='row'
            align='center'
            width={{ min: '330px' }}
            gap='small'
            justify='between'
            border='all'
            round='xsmall'
            height='36px'
            pad={{ horizontal: 'small' }}
          >
            <Text size='small'>{_.get(selectedSection, 'title')}</Text>
            <FontAwesomeIcon icon={faChevronDown} size='xs' />
          </Box>
        </DropDownMenu>
      )
    }
  }

  return (
    <Box direction='row' pad={{ vertical: 'medium', left: 'medium' }} gap='medium' align='center' { ...containerProps }>
      <Text size='small' color={colors.ANOTHER_GREY}>
        Add selected line item(s) to section
      </Text>
      {renderInputOrSelect()}
    </Box>
  )
}

SectionsSelector.propTypes = {
  sections: PropTypes.array,
  selectedSection: PropTypes.object,
  setSelectedSection: PropTypes.func
}

export default SectionsSelector
