import React from 'react'
import _ from 'lodash'

import Layout from 'webPages/layout/Layout'
import Settings from 'pages/settings/Settings.web'

const SettingsLayout = ({ params }) => {
  const redirectActiveIndex = _.get(params, 'activeIndex', 0)
  return (
    <Layout>
      <Settings redirectActiveIndex={redirectActiveIndex} />
    </Layout>
  )
}

export default SettingsLayout
