import * as types from 'model/actionTypes'

const initialState = null

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_ACCOUNT:
      return action.account
    case types.ADD_NEW_ACCOUNT_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.contact.id]: action.contact
        }
      }
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
