import React from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import lineItemType from 'shared/constants/lineItemType'
import lineItemSection from 'shared/constants/lineItemSection'

const Scope = ({ scope, sections }) => {
  const renderSection = section => {
    return (
      <Box flex={{ shrink: 0, grow: 1 }} pad='small'>
        <Box pad='small'>
          <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
            {_.get(section, 'title')}
          </Text>
        </Box>
        {_.map(_.get(section, 'items', []), renderItem)}
      </Box>
    )
  }

  const renderItem = id => {
    const item = _.get(scope, id)
    const alts = _.get(item, 'alternates')
    const type = _.get(item, 'type')
    const required = _.get(item, 'required', false)
    const isQuestion = _.includes([lineItemType.QUESTION, lineItemType.QUESTION_BOOL], type)
    if (isQuestion) {
      const label = type === lineItemType.QUESTION_BOOL ? 'Yes / No' : type === lineItemType.QUESTION ? 'Text' : null
      return (
        <Box flex={{ shrink: 0, grow: 1 }} pad={{ left: 'small' }}>
          <Box pad='small' direction='row' justify='between' align='center' border='all' margin={{ top: '-1px' }} >
            <Box gap='small'>
              <Text color={colors.TEXT_PRIMARY} size='small'>
                {_.get(item, 'name')}
              </Text>
              {_.size(_.get(item, 'desc')) > 0 && (
                <Text color={colors.TEXT} size='xsmall'>
                  {_.get(item, 'desc')}
                </Text>
              )}
            </Box>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>{label}</Text>
          </Box>
          {_.size(alts) > 0 && _.map(_.values(alts), (alt, index) => renderAlternate(alt, _.size(alts) - 1 === index))}
        </Box>
      )
    }
    return (
      <Box flex={{ shrink: 0, grow: 1 }} pad={{ left: 'small' }} customStyle='position: relative'>
        <Box pad='small' border='all' margin={{ top: '-1px' }} gap='small'>
          <Text color={colors.TEXT_PRIMARY} size='small'>
            {_.get(item, 'name')}
          </Text>
          {_.size(_.get(item, 'desc')) > 0 && (
            <Text color={colors.TEXT} size='xsmall'>
              {_.get(item, 'desc')}
            </Text>
          )}
        </Box>
        {required && (
          <Box pad={{ horizontal: 'medium' }} customStyle='position: absolute; top: 0; right: 0;' border={{ side: 'all', color: colors.CORAL_TWO }}>
            <Text color={colors.CORAL_TWO} size='small'>Required</Text>
          </Box>
        )}
        {_.size(alts) > 0 && _.map(_.values(alts), (alt, index) => renderAlternate(alt, _.size(alts) - 1 === index))}
      </Box>
    )
  }

  const renderAlternate = (item, isLast) => {
    return (
      <Box className={isLast ? 'line-last' : 'line'}>
        <Box className='arrow' />
        <Box pad={{ vertical: 'small', horizontal: 'large' }} border='all' margin={{ top: '-1px' }} gap='small' customStyle='position: relative;'>
          <Box customStyle='position: absolute; top: 2px; left: 25px;'>
            <Text size='small' color={colors.YELLOW}>Alternate</Text>
          </Box>
          <Text color={colors.TEXT_PRIMARY} size='small'>
            {_.get(item, 'name')}
          </Text>
          {_.size(_.get(item, 'desc')) > 0 && (
            <Text color={colors.TEXT} size='xsmall'>
              {_.get(item, 'desc')}
            </Text>
          )}
        </Box>
      </Box>
    )
  }

  const sectionsToRender = _.size(sections) === 0 && _.size(scope) > 0 ? [
    {
      id: lineItemSection.SCOPE_OF_WORK_ID,
      title: 'Scope of Work',
      items: _.keys(scope)
    }
  ] : sections
  return (
    <Box flex pad={{ horizontal: 'medium', top: 'medium', bottom: 'small' }}>
      <Box margin={{ bottom: 'small' }}>
        <Text size='xlarge' color={colors.BLACK}>Scope of work</Text>
      </Box>
      <Box flex>
        {_.map(sectionsToRender, renderSection)}
      </Box>
    </Box>
  )
}

export default Scope
