import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import colors from 'shared/constants/colors'

import { StyledText } from 'shared/documents/components/StyledComponents'

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* padding-top: ${props => props.getSize(60)}px; */
  padding-bottom: ${props => props.getSize(10)}px;
  ${props => props.customStyle}
`

const DocTableHeader = ({ getSize, title, customStyle, color }) => {
  return (
    <Container getSize={getSize} customStyle={customStyle}>
      <StyledText fontSize={getSize(16)} bold color={color}>
        {title}
      </StyledText>
    </Container>
  )
}

DocTableHeader.defaultProps = {
  color: colors.LIGHT_NAVY_BRIGHT
}

DocTableHeader.propTypes = {
  getSize: PropTypes.func,
  title: PropTypes.string,
  customStyle: PropTypes.string,
  color: PropTypes.string
}

export default DocTableHeader
