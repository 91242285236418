import React from 'react'
import _ from 'lodash'
import { Box, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  faFilePdf,
  faFileExcel,
  faFile,
  faFileImage,
  faCloudDownload,
  faExternalLink
} from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'

import colors from 'shared/constants/colors'
import contentTypes from 'shared/constants/contentTypes'

const ICON_SIZE = 50

const Files = ({ files }) => {
  const renderFileIcon = contentType => {
    if (_.has(contentTypes.xls, contentType)) {
      return <FontAwesomeIcon icon={faFileExcel} color={colors.AQUA_MARINE} size={ICON_SIZE} />
    } else if (_.has(contentTypes.pdf, contentType)) {
      return <FontAwesomeIcon icon={faFilePdf} color={colors.CORAL_TWO} size={ICON_SIZE} />
    } else if (_.isEqual(_.get(_.split(contentType, '/'), 0), 'image')) {
      return <FontAwesomeIcon icon={faFileImage} color={colors.LIGHT_NAVY_BRIGHT} size={ICON_SIZE} />
    } else {
      return <FontAwesomeIcon icon={faFile} color={colors.ANOTHER_GREY} size={ICON_SIZE} />
    }
  }

  const handleAdditionalClick = type => e => {
    e.stopPropagation()
    console.log(type)
  }

  const renderFileExtension = name => {
    const split = _.split(name, '.')
    if (_.size(split) >= 2) {
      return (
        <Text color={colors.DARK_GRAY_TWO} size='xsmall'>
          {`.${_.last(split)}`}
        </Text>
      )
    } else {
      return null
    }
  }

  const renderFile = file => (
    <Box
      width='small'
      height='small'
      justify='center'
      align='center'
      gap='small'
      onClick={() => console.log('file clicked')}
      margin={{ bottom: 'small' }}
      customStyle={`
        border-radius: 4px;
        border: 0.5px solid ${colors.ANOTHER_GREY};
        position: relative;
        :hover {
          border: 0.5px solid ${colors.AQUA_MARINE};
        }
        .file-menu {
          display: none;
          position: absolute;
          top: 10px;
          right: 10px;
        }
        :hover .file-menu {
          display: flex
        }
      `}
    >
      <Box direction='row' className='file-menu' gap='small'>
        <Button
          plain
          color={colors.AQUA_MARINE}
          label={<FontAwesomeIcon icon={faCloudDownload} size={18} />}
          onClick={handleAdditionalClick('download')}
        />
        <Button
          plain
          color={colors.AQUA_MARINE}
          label={<FontAwesomeIcon icon={faExternalLink} color={colors.AQUA_MARINE} size={14} />}
          onClick={handleAdditionalClick('external')}
        />
      </Box>
      <Box align='center' gap='xxsmall'>
        {renderFileIcon(_.get(file, 'contentType'))}
        {renderFileExtension(_.get(file, 'name'))}
      </Box>
      <Box align='center'>
        <Text color={colors.TEXT_PRIMARY} size='medium' weight={600}>
          {_.truncate(_.get(file, 'name'), { length: 20 })}
        </Text>
        <Text color={colors.DARK_GRAY_TWO} size='small'>
          modified {moment(_.get(file, 'createdAt')).format('MMM D[,] YY')}
        </Text>
      </Box>
    </Box>
  )

  return (
    <Box pad='medium'>
      <Box justify='between' direction='row' flex={false} margin={{ bottom: 'small', right: 'medium' }}>
        <Text size='xlarge' color={colors.BLACK}>
          Plan files
        </Text>
        <Button
          label={
            <Box direction='row' gap='xsmall'>
              <FontAwesomeIcon icon={faCloudDownload} color={colors.LIGHT_NAVY_BRIGHT} size={18} />
              <Text size='medium'>Download all</Text>
            </Box>
          }
          color={colors.LIGHT_NAVY_BRIGHT}
          onClick={() => null}
        />
      </Box>
      <Box customStyle='display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))'>
        {_.map(_.sortBy(files, 'createdAt'), renderFile)}
      </Box>
    </Box>
  )
}

export default Files
