import React from 'react'
import { Box, Text } from 'grommet'
import PropTypes from 'prop-types'

import DropContentContainer from 'components/DropContentContainer'
import colors from 'shared/constants/colors'

const RemoveLineItemDropContent = ({ onAction, companyName, dropContentProps }) => {
  return (
    <DropContentContainer
      boxProps={{ pad: { top: 'xsmall', bottom: 'small' }, width: '420px' }}
      // marginX={-6}
      {...dropContentProps}
    >
      <Box>
        <Text margin={{ vertical: 'small', horizontal: 'small' }} color={colors.ANOTHER_GREY}>
          {`${companyName} has requested for you to remove this line item`}
        </Text>
      </Box>
      <Box pad='small' onClick={() => onAction(true)} hoverIndicator={{ color: colors.TABLE_HOVER }}>
        <Text margin={{ left: 'small' }} size='small'>
          Remove line item
        </Text>
      </Box>
      <Box pad='small' onClick={() => onAction(false)} hoverIndicator={{ color: colors.TABLE_HOVER }}>
        <Text margin={{ left: 'small' }} size='small'>
          Keep line item
        </Text>
      </Box>
    </DropContentContainer>
  )
}

RemoveLineItemDropContent.propTypes = {
  onAction: PropTypes.func.isRequired,
  companyName: PropTypes.string
}

export default RemoveLineItemDropContent
