import React from 'react'
import { Box } from 'grommet'
import ReactResizeDetector from 'react-resize-detector'

import colors from 'shared/constants/colors'
import DocPanelEstimatePreview from 'shared/components/DocPanelEstimatePreview'

const ProposalPreview = ({ bid }) => {
  return (
    <Box background={colors.ALMOST_WHITE} flex pad='small' justify='center' align='center'>
      <Box width={{ max: '80%' }} fill>
        <ReactResizeDetector handleWidth>
          {({ width }) => (
            <Box fill>{width > 0 && <DocPanelEstimatePreview viewport={{ width, height: width * 2 }} doc={bid} />}</Box>
          )}
        </ReactResizeDetector>
      </Box>
    </Box>
  )
}

export default ProposalPreview
