import React, { Component } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import { getCurrentUserProfile, getCurrentAccountProfile } from 'model/selectors/profiles'
import { getName } from 'shared/utils/stringUtils'
import { listenToAnnotations, deleteAnnotations, saveAnnotation, updateAnnotation } from 'controllers/annotations'
import config from 'shared/config'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { toTimestamp } from 'shared/utils/date'
import { isGCHost } from 'constants/index'

const divId = 'pdf-file-editor'
const idSeparator = '+++'

const colors = [
  '#b80000',
  '#fccb00',
  '#006B76',
  '#c4def6',
  '#fad0c3',
  '#1273De',
  '#8b1ea0',
  '#5300eb',
  '#eb9694',
  '#fef3bd',
  '#004dcf',
  '#c1e1c5',
  '#bedadc',
  '#bed3f3',
  '#008b02',
  '#d4c4fb',
  '#cccccc'
]

const annotationManagerConfig = {
  showToolbar: true,
  showCommentsPanel: true,
  downloadWithAnnotations: false,
  printWithAnnotations: false,
  showToolsOnTextSelection: true
}

const previewFileConf = {
  showPageControls: true,
  dockPageControls: true,
  showAnnotationTools: true,
  showLeftHandPanel: true,
  defaultViewMode: 'FIT_PAGE',
  enableFormFilling: false,
  showDownloadPDF: true,
  showPrintPDF: false,
  enableAnnotationAPIs: true
}

class PdfEditor extends Component {
  constructor (props) {
    super(props)
    this.annotations = {}
    this.makeColors()
    this.inProgress = false
  }

  makeColors = () => {
    const { workOrder } = this.props
    const invitations = _.map(_.get(workOrder, 'invitations'), (inv, invId) => ({ ...inv, id: invId }))
    const sortedInvitations = _.sortBy(invitations, inv => toTimestamp(inv.timestamp))
    console.log('sortedInvitations', sortedInvitations)
    this.colorById = { [workOrder.accountId]: '#008b02' }
    _.forEach(sortedInvitations, (inv, i) => {
      const color = i > _.size(colors) - 1 ? '#bb3399' : colors[i]
      _.set(this.colorById, inv.id, color)
    })
    console.log('this.colorById', this.colorById)
  }

  componentWillUnmount = () => {
    if (_.isFunction(this.unsubscribe)) this.unsubscribe()
  }

  componentDidMount = async () => {
    const { file, selectAnnotationId } = this.props
    this.selectedAnnotation = selectAnnotationId
    const apiKey = isGCHost ? config.adobeClientIdBidlevel : config.adobeClientIdMason
    this.adobeDCView = new window.AdobeDC.View({
      clientId: process.env.NODE_ENV === 'development' ? '8c0cd670273d451cbc9b351b11d22318' : apiKey,
      divId
      // backgroundColor: colors.PALE_GREY
    })
    this.setUserProfile()
    /* Invoke the file preview API on Adobe DC View object */
    const adobeViewer = await this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: file.url
          }
        },
        metaData: {
          fileName: file.name,
          id: file.id
        }
      },
      previewFileConf
    )
    // console.log('adobeViewer', adobeViewer)
    const annotationManager = await adobeViewer.getAnnotationManager()
    annotationManager.setConfig(annotationManagerConfig)
    const anEv = window.AdobeDC.View.Enum.AnnotationEvents
    const eventOptions = {
      listenOn: [
        anEv.ANNOTATION_ADDED,
        anEv.ANNOTATION_UPDATED,
        anEv.ANNOTATION_DELETED,
        anEv.ANNOTATION_SELECTED,
        anEv.ANNOTATION_UNSELECTED
        // anEv.ANNOTATION_CLICKED
      ]
    }
    // console.log('annotation events options', eventOptions)
    annotationManager.registerEventListener(this.onAnnotationEvent, eventOptions)
    // console.log('annotationManager', annotationManager)
    this.annotationManager = annotationManager
    this.adobeViewer = adobeViewer
    this.initAnnotations()
  }

  setUserProfile = () => {
    const { profile, accountProfile } = this.props
    const userProfile = {
      name: `${getName(profile)} (${getName(accountProfile)})`,
      firstName: _.get(profile, 'firstName'),
      lastName: _.get(profile, 'lastName'),
      email: JSON.stringify({ userId: _.get(profile, 'id'), accountId: accountProfile.id })
    }
    this.adobeDCView.registerCallback(window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API, async () => ({
      code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
      data: { userProfile }
    }))
  }

  initAnnotations = () => {
    const { file, workOrderId, workOrder, account } = this.props
    const params = {
      woAccountId: _.get(workOrder, 'accountId'),
      fileId: file.id,
      callback: this.onAnnotationsReceived,
      workOrderId,
      accountId: _.get(account, 'id')
    }
    console.log('%c----->init annotations: subscribe', 'color: yellow;')
    this.unsubscribe = listenToAnnotations(params)
  }

  hasChanges = (an1, an2) => {
    const a1 = { ...an1 }
    const a2 = { ...an2 }
    _.unset(a1, 'modified')
    _.unset(a2, 'modified')
    _.unset(a1, 'chId')
    _.unset(a2, 'chId')
    // console.log('a1, a2', a1, a2, 'isequal', _.isEqual(a1, a2))
    return !_.isEqual(a1, a2)
  }

  setColorToAn = (an, chId) => {
    const color = _.get(this.colorById, chId, '#1b92ff')
    const newAn = { ...an }
    _.set(newAn, 'colllllorrrr', color)
    _.set(newAn, 'target.selector.strokeColor', color)
    return newAn
  }

  onAnnotationsReceived = async annotations => {
    console.log('%cannotations received', 'color: orange;', annotations)
    const { account, workOrder } = this.props
    if (this.inProgress) {
      console.log('onAnnotationsReceived is in Progress, return')
      return null
    }
    this.inProgress = true
    try {
      const accountId = _.get(account, 'id')
      const woAccountId = _.get(workOrder, 'accountId')
      const isGC = woAccountId === accountId
      console.log('new annotations are equal to previews', _.isEqual(this.dbAnnotations, annotations))
      this.dbAnnotations = annotations
      let res = {}
      const gcRootAnnotations = {}
      const hiddenIds = {}
      if (isGC) {
        const generalCh = _.get(annotations, accountId)
        res = { ...generalCh }
        _.forEach(annotations, (ch, chId) => {
          if (chId !== accountId) {
            console.log('process subId', chId)
            const chAnnotations = _.map(ch, an => {
              const parentId = _.get(an, 'target.source')
              if (an.deleted) _.set(hiddenIds, an.id, true)
              const isVirtual = _.size(_.split(an.id, idSeparator)) > 1
              if (_.has(generalCh, parentId) && !isVirtual) {
                const gcSubMessageId = `${chId}${idSeparator}${parentId}`
                const parentMessage = { ..._.get(generalCh, parentId) }
                // console.log('parent message found for message', an, 'parent', parentMessage)
                const newParentMessage = { ...parentMessage, id: gcSubMessageId }
                _.set(gcRootAnnotations, gcSubMessageId, newParentMessage)
                _.set(hiddenIds, parentId, true)
                // we also have to remove GC children annotations from general channel
                _.forEach(generalCh, (genAn, genAnId) => {
                  const genAnParentId = _.get(genAn, 'target.source')
                  if (genAnParentId === parentId) {
                    const newId = `${chId}${idSeparator}${genAnId}`
                    const newGenAn = { ...genAn, id: newId, target: { ...genAn.target, source: gcSubMessageId } }
                    _.set(gcRootAnnotations, newId, newGenAn)
                    _.set(hiddenIds, genAnId, true)
                  }
                })
                return { ...an, chId, target: { ...an.target, source: gcSubMessageId } }
              } else {
                console.log('an is virtual', an)
                return { ...an, chId }
              }
            })
            res = { ...res, ..._.keyBy(chAnnotations, 'id') }
          }
        })
      } else {
        const generalCh = _.get(annotations, woAccountId)
        const subCh = _.get(annotations, accountId)
        res = { ...generalCh, ...subCh }
      }
      console.log('after processing with hidden', res)
      const withNoHidden = _.reduce(
        { ...gcRootAnnotations, ...res },
        (r, anRaw, anId) => {
          const isHidden = _.has(hiddenIds, anId)
          const idAr = _.split(anRaw.id, idSeparator)
          const isVirtual = _.size(idAr) > 1
          let an = { ...anRaw }
          if (!isHidden) {
            if (isGC && an.motivation === 'commenting') {
              const createdBy = JSON.parse(_.get(an, 'creator.id'))
              const accId = isVirtual ? idAr[0] : _.get(createdBy, 'accountId')
              an = this.setColorToAn(an, accId)
            }
            if (!isGC && isVirtual) {
              _.set(r, idAr[1], { ...an, id: idAr[1] })
            } else {
              _.set(r, anId, an)
            }
          }
          return r
        },
        {}
      )
      console.log('onAnnotatonsReceived => replace annotattions')
      this.replaceAnnotations(withNoHidden)
    } catch (e) {
      console.log('onAnnotatonsReceived error', e)
      this.inProgress = false
    }
  }

  onAnnotationCreated = an => {
    const { userId, workOrderId, account, file, workOrder } = this.props
    const woAccountId = _.get(workOrder, 'accountId')
    const woAccounts = _.get(workOrder, 'accounts', [])
    const creatorAccountId = _.get(account, 'id')
    const isGC = woAccountId === creatorAccountId
    console.log('onAnnotationCreated', an)
    const createdBy = JSON.parse(_.get(an, 'creator.id'))
    console.log('userId', userId, 'createdById', createdBy)
    if (userId === _.get(createdBy, 'userId')) {
      console.log('added by the current user, isGC', isGC)
      if (isGC) {
        const motivation = _.get(an, 'motivation')
        if (motivation === 'commenting') {
          console.log('GC adds root comment')
          saveAnnotation(workOrderId, file.id, account.id, woAccounts, an, creatorAccountId)
        } else {
          console.log('GC replies to a comment', an)
          const parentId = _.get(an, 'target.source')
          console.log('GC replies to a comment', parentId)
          const idsAr = _.split(parentId, idSeparator)
          console.log('idsAr', idsAr)
          if (_.size(idsAr) > 1) {
            console.log('GC virtual root message commenting')
            const subId = idsAr[0]
            const rootAnId = idsAr[1]
            const cleanAn = { ...an, target: { source: rootAnId } }
            console.log('cleanAn', cleanAn)
            saveAnnotation(workOrderId, file.id, subId, [subId, workOrder.accountId], cleanAn, creatorAccountId)
          } else {
            console.log('GC root message commenting')
            const parentMessage = _.get(this.annotations, parentId)
            console.log('parentMessage', parentMessage)
            const parentMessageCreator = JSON.parse(_.get(parentMessage, 'creator.id'))
            const parentMessageAccountId = _.get(parentMessageCreator, 'accountId')
            console.log('parentMessageCreator', parentMessageCreator, 'parentMessageAccountId', parentMessageAccountId)
            const accounts = parentMessageAccountId === woAccountId ? woAccounts : [woAccountId, parentMessageAccountId]
            saveAnnotation(workOrderId, file.id, parentMessageAccountId, accounts, an, creatorAccountId)
          }
        }
      } else {
        const motivation = _.get(an, 'motivation')
        if (motivation === 'commenting') {
          console.log('SUB adds root comment')
          saveAnnotation(workOrderId, file.id, account.id, [woAccountId, account.id], an, creatorAccountId)
        } else {
          console.log('Sub replies to a comment')
          saveAnnotation(workOrderId, file.id, account.id, [woAccountId, account.id], an, creatorAccountId)
        }
      }
      this.selectedAnnotation = an.id
    } else {
      console.log('added by somebody else')
    }
  }

  replaceAnnotations = async newAnnotations => {
    console.log('replaceAnnotations start, newAnnotations', newAnnotations)
    try {
      this.annotations = {}
      const curAnnotations = await this.annotationManager.getAnnotations()
      console.log('curAnnotations', curAnnotations)
      // const ids = _.map(curAnnotations, an => an.id)
      // console.log('ids', ids)
      if (!_.isEmpty(curAnnotations)) await this.annotationManager.deleteAnnotations()
      console.log('newAnnotations', newAnnotations)
      // remove annotations wthno parent
      const validAnnotations = _.filter(
        newAnnotations,
        an => an.motivation === 'commenting' || _.has(newAnnotations, _.get(an, 'target.source'))
      )
      console.log('after removing annotations withno parent', validAnnotations)
      const sortedAddedAnnotations = _.sortBy(validAnnotations, an => moment(an.created).valueOf())
      this.annotations = newAnnotations
      if (!_.isEmpty(sortedAddedAnnotations)) await this.annotationManager.addAnnotations(sortedAddedAnnotations)
      if (!_.isNil(this.selectedAnnotation)) {
        console.log('select annotation', this.selectedAnnotation)
        try {
          await this.annotationManager.selectAnnotation(this.selectedAnnotation)
        } catch (e) {
          console.log('select an error', e)
        }
      }
    } catch (e) {
      console.log('replaceAnnotations error', e)
    }
    this.inProgress = false
  }

  updateAnnotations = async newAnnotations => {
    console.log('updateAnnotations', newAnnotations)
    const curAnnotations = await this.annotationManager.getAnnotations()
    // console.log('curAnnotations', curAnnotations)
    const newIds = _.map(newAnnotations, an => an.id)
    const curIds = _.map(curAnnotations, an => an.id)
    const addIds = _.difference(newIds, curIds)
    const delIds = _.difference(curIds, newIds)
    const updIds = _.intersection(newIds, curIds)
    const prevAnnotations = { ...this.annotations }
    this.annotations = newAnnotations
    console.log('addIds', addIds, 'delIds', delIds, 'updIds', updIds)
    if (!_.isEmpty(addIds)) {
      const addedAnnotations = _.map(addIds, id => _.get(newAnnotations, id))
      const sortedAddedAnnotations = _.sortBy(addedAnnotations, an => moment(an.created).valueOf())
      await this.annotationManager.addAnnotations(sortedAddedAnnotations)
    }
    _.forEach(updIds, async id => {
      if (this.hasChanges(_.get(prevAnnotations, id), _.get(newAnnotations, id))) {
        await this.annotationManager.updateAnnotation(_.get(newAnnotations, id))
      } else {
        // console.log(id, 'id will not be updated, no changes')
      }
    })

    if (!_.isEmpty(delIds)) {
      try {
        for (const id of delIds) {
          await this.annotationManager.deleteAnnotations({ annotationIds: [id] })
        }
      } catch (e) {
        console.warn('delete annotations error', e)
      }
    }
    // this.annotationManager.(_.values(annotations))
  }

  onAnnotationDeleted = async an => {
    const { workOrderId, userId } = this.props
    console.log('onAnnotationDeleted', an)
    const createdBy = JSON.parse(_.get(an, 'creator.id'))
    console.log('an userId', _.get(createdBy, 'userId'), 'userId', userId)
    const isOwn = _.get(createdBy, 'userId') === userId
    if (isOwn) {
      if (an.motivation === 'replying') {
        // somebody deletes own reply
        const parentId = _.get(an, 'target.source')
        const idsAr = _.split(parentId, idSeparator)
        if (_.size(idsAr) > 1) {
          console.log('this is a virtual chain', idsAr)
          // this.annotationManager.addAnnotations([an])
          const anIdsAr = _.split(an.id)
          if (_.size(anIdsAr) > 1) {
            console.log('the annot is vitual, set deleted: true')
            updateAnnotation(an.id, workOrderId, { deleted: true })
          } else {
            console.log('the annot is real, removing it')
            deleteAnnotations([an.id], workOrderId)
          }
        } else {
          console.log('this is a main chain', idsAr)
          const parentAn = _.get(this.annotations, idsAr[0])
          console.log('parentAn', parentAn)
          const parentCreator = JSON.parse(_.get(parentAn, 'creator.id'))
          const chId = _.get(parentCreator, 'accountId')
          console.log('chId', chId)
          deleteAnnotations([an.id], workOrderId)
        }
      } else {
        console.log('remove annotations chain')
        const idsAr = _.split(an.id, idSeparator)
        console.log('idAr', idsAr)
        const childrenIds = _.reduce(
          this.annotations,
          (res, existingAn, exId) => {
            if (_.get(existingAn, 'target.source') === an.id) res.push(existingAn.id)
            return res
          },
          []
        )
        console.log('this is a virtual chain, children', idsAr, childrenIds)
        deleteAnnotations([...childrenIds, an.id], workOrderId)
      }
    } else {
      console.log('deleting not own annots is not allowed')
      this.replaceAnnotations(this.annotations)
    }
  }

  onAnnotationUpdated = async an => {
    const { workOrderId, userId, account } = this.props
    const createdBy = JSON.parse(_.get(an, 'creator.id'))
    console.log('an userId', _.get(createdBy, 'userId'), 'userId', userId)
    const isOwn = _.get(createdBy, 'userId') === userId
    if (isOwn) {
      if (an.motivation === 'replying') {
        console.log('reply: own: onAnnotationUpdated , replying, simply saving')
        this.onAnnotationCreated(an)
      } else {
        console.log('update annotation', an)
        updateAnnotation(an.id, workOrderId, an)
      }
    } else {
      console.log('editing not own annots is not allowed')
      this.replaceAnnotations(this.annotations)
    }
  }

  refresh = async () => {
    const curAnnotations = await this.annotationManager.getAnnotations()
    console.log('curAnnotations', curAnnotations)
    const sortedCurAnnotation = _.sortBy(curAnnotations => an => -moment(an.created).valueOf())
    const ids = _.map(sortedCurAnnotation, an => an.id)
    console.log('ids', ids)
    if (!_.isEmpty(ids)) await this.annotationManager.deleteAnnotations({ annotationIds: ids })
    console.log('this.annotations', this.annotations)
    const sortedAddedAnnotations = _.sortBy(this.annotations, an => moment(an.created).valueOf())
    await this.annotationManager.addAnnotations(sortedAddedAnnotations)
  }

  onAnnotationEvent = async event => {
    const { userId } = this.props
    const anEv = window.AdobeDC.View.Enum.AnnotationEvents
    console.log('onAnnotationEvent', event)
    const an = _.get(event, 'data')
    const curAn = _.get(this.annotations, an.id)
    switch (event.type) {
      case anEv.ANNOTATION_ADDED:
        if (_.isEmpty(curAn)) {
          this.onAnnotationCreated(an)
        } else {
          console.log('skip adding, already exists')
        }
        break
      case anEv.ANNOTATION_UPDATED:
        if (this.hasChanges(curAn, an)) {
          try {
            this.onAnnotationUpdated(an)
          } catch (e) {
            console.log('ANNOTATION_UPDATED error', e)
          }
        }
        break
      case anEv.ANNOTATION_DELETED:
        // const id = an.id
        const createdBy = JSON.parse(_.get(an, 'creator.id'))
        console.log('an userId', _.get(createdBy, 'userId'), 'userId', userId)
        if (_.isEmpty(curAn)) {
          console.log('nothing to do, the annotation does not exist in DB')
        } else {
          await this.onAnnotationDeleted(an)
        }
        break

      case anEv.ANNOTATION_SELECTED:
        this.selectedAnnotation = an.id
        break

      // case anEv.ANNOTATION_UNSELECTED:
      //   this.selectedAnnotation = null
      //   break
    }
  }

  render () {
    return <Box fill background='lightgrey' id={divId} />
  }
}

PdfEditor.propTypes = {
  isGC: PropTypes.bool,
  file: PropTypes.object,
  workOrderId: PropTypes.string
}

const mapStateToProps = (state, props) => ({
  userId: _.get(state, 'user.id'),
  profile: getCurrentUserProfile(state),
  accountProfile: getCurrentAccountProfile(state),
  account: state.account,
  workOrder: getWorkOrder(state, props)
})

export default connect(mapStateToProps)(PdfEditor)
