import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { LayoutContext } from 'shared/documents/components/LayoutContext'

// class Block extends Component {
//   onLayout = e => {
//     const { index, debugParams } = this.props
//     // console.log(index, e.nativeEvent.layout, _.get(debugParams, 't'))
//     const isBreak = _.get(this.props.children, 'props.break', false)
//     this.context.onLayout(index, e.nativeEvent.layout, isBreak)
//   }

//   render () {
//     return (
//       <View onLayout={this.onLayout} style={{ borderWidth: 0, borderColor: 'grey', height: 'auto', width: '100%' }}>
//         {this.props.children}
//       </View>
//     )
//   }
// }

// Block.contextType = LayoutContext

// Block.propTypes = {
//   index: PropTypes.number.isRequired
// }

// export default Block

const Block = ({ children }) => {
  // onLayout = e => {
  //   const { index, debugParams } = this.props
  //   // console.log(index, e.nativeEvent.layout, _.get(debugParams, 't'))
  //   const isBreak = _.get(this.props.children, 'props.break', false)
  //   this.context.onLayout(index, e.nativeEvent.layout, isBreak)
  // }

  const isBreak = _.get(children, 'props.break', false)

  if (isBreak) return <View style={{ height: 0, width: 0 }} />

  return (
    <View
      // id={isBreak ? 'asdfas fdsa fdsa fdsa' : 'ss'}
      style={{ borderWidth: 0, borderColor: isBreak ? 'red' : 'grey', height: 'auto', width: '100%' }}
    >
      {children}
    </View>
  )
}

// Block.contextType = LayoutContext

Block.propTypes = {
  // index: PropTypes.number.isRequired
}

export default Block
