import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Box, ThemeContext, TextInput, Stack, Keyboard, Text, DropButton, CheckBox } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { faAsterisk, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { css } from 'styled-components'
import _ from 'lodash'
import generate from 'firebase-auto-ids'

import colors from 'shared/constants/colors'
import lineItemType from 'shared/constants/lineItemType'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import RemoveLineItemDropContent from 'components/createBid/RemoveLineItemDropContent'
import { getName } from 'shared/utils/stringUtils'
import { saveTemplate, updateTemplate } from 'controllers/account'
import { getAllAccountsItems } from 'model/selectors/bids'
import { useStateWithCallbackLazy } from 'shared/utils/hooks'

const LineItemTitleCell = ({
  li,
  parentLi,
  show,
  handleShowChange,
  updateItem,
  removeLineItem,
  editable,
  selectRow,
  unselectRow,
  removalRequest,
  gcProfile,
  onRemoveAction,
  focusNextElement,
  account,
  items,
  dispatch,
  hideBorder,
  cannotAddToTemplate,
  disabled
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [name, setName] = useStateWithCallbackLazy(_.get(li, 'name', ''))
  const [focused, setFocused] = useState(false)
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [update, setUpdate] = useState(false)
  const inputRef = useRef(null)
  const [dropOpen, setDropOpen] = useState(false)
  const onDropOpen = () => setDropOpen(true)
  const onDropClose = () => setDropOpen(false)
  const isAlternate = !_.isNil(parentLi)
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false)

  useEffect(() => {
    if (_.isEmpty(_.toString(li.name))) {
      inputRef.current.focus()
    } else {
      if (li.name !== name) {
        console.log('current name', name, 'new name', li.name)
        setName(li.name)
        console.log('name updated')
      }
    }
  }, [li.name])

  const lineItems = _.get(account, 'lineItems', {})

  // FIXME: this calculates for each line item, but it does not depend on a line item and can be moved to the top level
  useEffect(() => {
    setSuggestions(
      _.map(_.uniqBy(_.concat(_.values(_.get(account, 'lineItems')), _.values(items)), 'name'), renderSuggestion)
    )
    console.log('lineItems & suggestions are updated')
  }, [items, account])

  useEffect(() => {
    // FIXME: this can be replaced with useMemo instead of storing in a state, will work faster and excludes the additional rerender
    const templates = _.get(account, 'templates')
    if (searchValue === '') {
      setSelectedTemplates(templates)
    } else {
      setSelectedTemplates(_.filter(templates, template => _.includes(template.name, searchValue)))
    }
  }, [account, searchValue])

  const renderSuggestion = value => ({
    label: (
      <Box onMouseDown={e => e.preventDefault()} pad='small'>
        <Text>{value.name}</Text>
      </Box>
    ),
    name: value.name,
    value
  })

  const handleCreateTemplate = () => {
    const id = generate(_.now())
    const valueName = name === '' || !name ? li.name : name
    const lineItemId = _.findKey(lineItems, li => li.name === valueName)
    dispatch(saveTemplate(id, { id, name: searchValue, values: [lineItemId] }, lineItemId, valueName))
    setSearchValue('')
    setOpen(false)
  }

  const dropContent = (
    <Box width='small' flex={false}>
      <Box flex={false} fill='horizontal'>
        <TextInput
          border='none'
          placeholder='Search templates'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </Box>
      {_.size(searchValue) > 0 && _.size(selectedTemplates) === 0 && (
        <Box height='small'>
          <Box flex={false} pad='small'>
            <Button
              label='Create template'
              primary
              color={colors.AQUA_MARINE}
              onClick={handleCreateTemplate}
              disabled={searchValue.length <= 0}
            />
          </Box>
        </Box>
      )}
      {(_.size(selectedTemplates) > 0 || _.size(searchValue) === 0) && (
        <Box height='small' overflow='auto'>
          <ThemeContext.Extend
            value={{
              checkBox: {
                size: '15px',
                color: colors.AQUA_MARINE,
                hover: {
                  border: {
                    color: colors.AQUA_MARINE
                  }
                }
              }
            }}
          >
            {_.map(selectedTemplates, template => {
              const valueName = name === '' || !name ? li.name : name
              const desc = _.get(li, 'desc')
              const labor = _.get(li, 'labor', false)
              const required = _.get(li, 'required', false)
              const type = _.get(li, 'type')
              const lineItemId = _.findKey(lineItems, li => li.name === valueName) // FIXME: it iterates over lineItems for each template
              return (
                <Box direction='row' flex={false} key={template.id} align='center' justify='between' pad='small'>
                  <CheckBox
                    label={template.name}
                    checked={_.includes(template.values, lineItemId)}
                    onChange={e => {
                      const isNewInTemplate = e.target.checked
                      dispatch(
                        updateTemplate(
                          template.id,
                          _.omitBy(
                            {
                              name: valueName,
                              desc,
                              labor,
                              required,
                              type
                            },
                            _.isNil
                          ),
                          isNewInTemplate,
                          lineItemId
                        )
                      )
                    }}
                  />
                </Box>
              )
            })}
          </ThemeContext.Extend>
        </Box>
      )}
    </Box>
  )

  const textInputThemeValue = {
    global: {
      control: { border: { color: 'transparent', radius: '0px' } },
      colors: {
        placeholder: colors.VERY_LIGHT_PINK
      }
    },
    textInput: {
      // extend: css`
      //   -webkit-text-fill-color: ${colors.TEXT_PRIMARY};
      //   color: ${colors.TEXT_PRIMARY};
      // `,
      disabled: { opacity: 0.8 },
      container: {
        extend: css`
          height: 100%;
          input {
            height: 100%;
          }
          input:focus {
            background-color: ${colors.WHITE};
            border: 1px ${colors.AQUA_MARINE} solid;
          }
        `
      }
    }
  }

  const onChange = event => {
    setUpdate(true)
    const value = event.target.value
    setName(value)
  }

  const onBlur = e => {
    setFocused(false)
    unselectRow()
    // console.log('LineItemTitleCell, onBlur, title', name, 'li.title', li.name, 'size', _.size(li.name))
    let value
    if (!_.isNil(name)) {
      value = name
    } else if (!_.isNil(li.name)) {
      value = li.name
    }
    if (_.size(value) === 0) {
      removeLineItem(li.id, parentLi)
    } else if (value !== li.name) {
      updateItem(li.id, { name }, parentLi)
      setName(name)
    }
  }

  const setAnswer = v => () => {
    updateItem(li.id, { answer: v })
  }

  const renderBoolOptions = () => {
    if (li.type === lineItemType.QUESTION_BOOL) {
      const answerYes = _.has(li, 'answer') && li.answer
      const answerNo = _.has(li, 'answer') && !li.answer
      return (
        <ThemeContext.Extend value={{ button: { primary: { padding: { horizontal: 'small' } } } }}>
          <Box direction='row' gap='xsmall' margin={{ right: 'xsmall' }}>
            <Button
              size='small'
              secondary={!answerYes}
              pad='none'
              primary={answerYes}
              color={colors.AQUA_MARINE}
              hoverTextColor={colors.WHITE}
              onClick={setAnswer(true)}
              label='Yes'
              disabled={answerYes || disabled}
            />
            <Button
              size='small'
              pad='none'
              secondary={!answerNo}
              primary={answerNo}
              color={colors.CORAL_TWO}
              hoverTextColor={colors.WHITE}
              onClick={setAnswer(false)}
              disabled={answerNo || disabled}
              label='No'
            />
          </Box>
        </ThemeContext.Extend>
      )
    }
  }

  const renderCaret = () => {
    if (!_.isEmpty(li.alternates)) {
      return (
        <Box align='start' justify='center' onClick={handleShowChange}>
          <FontAwesomeIcon icon={show ? faCaretDown : faCaretRight} color={colors.LIGHT_NAVY} size={16} />
        </Box>
      )
    }
  }

  const renderRequiredIcon = () => {
    if (_.isEmpty(parentLi) && li.required) {
      return (
        <Box margin={{ left: '15px' }} align='start' justify='center'>
          <FontAwesomeIcon icon={faAsterisk} color={colors.CORAL_TWO} size={10} />
        </Box>
      )
    }
  }

  const onAction = isRemoving => {
    console.log('onRemoveAction', isRemoving)
    onDropClose()
    onRemoveAction(li, isRemoving)
  }

  const renderRemoval = () => {
    const removalLabel = (
      <Box direction='row' align='center' gap='xsmall' width={{ min: '150px' }}>
        <Text color={colors.CORAL_TWO}>
          <i>Removal requested</i>
        </Text>
        <FontAwesomeIcon icon={faChevronDown} color={colors.CORAL_TWO} size={14} />
      </Box>
    )

    const removalDropdowm = (
      <DropButton
        plain
        onClose={onDropClose}
        onOpen={onDropOpen}
        open={dropOpen}
        label={removalLabel}
        dropAlign={{ top: 'bottom', left: 'left' }}
        // dropProps={{ style: { overflow: 'visible' } }}
        disabled={disabled}
        dropContent={
          <RemoveLineItemDropContent
            onAction={onAction}
            companyName={getName(gcProfile)}
            // dropContentProps={{ width: 'large' }}
          />
        }
      />
    )

    if (!_.isNil(removalRequest)) {
      if (removalRequest.declined) {
        return (
          <Box pad={{ bottom: 'xsmall', left: 'small' }} direction='row' align='center' gap='medium'>
            <Text color={colors.VERY_LIGHT_PINK}>
              <i>Removal request denied</i>
            </Text>
            <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
              {`Comment: ${_.get(removalRequest, 'comment')}`}
            </Text>
          </Box>
        )
      } else {
        return (
          <Box pad={{ bottom: 'xsmall', left: 'small' }} direction='row' align='center' gap='medium'>
            {removalDropdowm}
            <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
              {`Comment: ${_.get(removalRequest, 'comment')}`}
            </Text>
          </Box>
        )
      }
    } else {
      return null
    }
  }

  const handleMouseDown = e => {
    e.preventDefault()
  }

  const handleOpenChange = e => {
    inputRef.current.blur()
    setOpen(!open)
  }

  const currentSuggestions = useMemo(() => {
    if (!update) {
      return []
    }
    let value = ''
    if (name) {
      value = name
    } else if (li.name) {
      value = li.name
    }
    if (value !== '') {
      return _.filter(suggestions, s => _.includes(_.lowerCase(s.name), _.lowerCase(value)) && s.name !== value)
    } else {
      return []
    }
  }, [li.name, suggestions, name, update])

  const handleSuggestionSelect = (item) => {
    console.log('handleSuggestionSelect item', item)
    // setName(item.name)
    // console.log('setName', item.name)
    // updateItem(li.id, { name: item.name }, parentLi)
    console.log('inputRef.current', inputRef.current)
    setName('', () => {
      updateItem(li.id, { ...item, id: li.id }, parentLi)
      onEnter()
    })
  }

  const onEnter = () => {
    if (!isSuggestionOpen) inputRef.current.blur()
  }

  const renderTitle = () => {
    const onFocus = () => {
      selectRow()
      setFocused(true)
    }

    return (
      <Keyboard onEnter={onEnter} onTab={e => focusNextElement('title', e)}>
        <Box direction='row' fill>
          <TextInput
            value={name || ''}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSuggestionSelect={e => {
              e.preventDefault()
              handleSuggestionSelect(e.suggestion.value)
            }}
            id={`${li.id}_title`}
            disabled={!editable || disabled}
            ref={inputRef}
            placeholder={isAlternate ? 'Write alternate name...' : 'Write line item name...'}
            height='100%'
            suggestions={currentSuggestions}
            size='small'
            onSuggestionsOpen={() => setIsSuggestionOpen(true)}
            onSuggestionsClose={() => setIsSuggestionOpen(false)}
          />
          {(focused || open) && _.size(name) > 0 && !cannotAddToTemplate && (
            <Box
              width='xxsmall'
              fill='vertical'
              customStyle={`
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                * svg {
                  margin: auto
                }
              `}
            >
              <DropButton
                open={open}
                onClose={handleOpenChange}
                dropContent={dropContent}
                dropAlign={{ top: 'bottom', right: 'left' }}
                fill
              >
                <Box fill onMouseDown={handleMouseDown} onClick={handleOpenChange}>
                  <FontAwesomeIcon icon={faPlus} size={18} color={colors.BLACK} />
                </Box>
              </DropButton>
            </Box>
          )}
        </Box>
      </Keyboard>
    )
  }

  return (
    <Box plain border={hideBorder ? [] : ['bottom', 'right']}>
      <ThemeContext.Extend value={textInputThemeValue}>
        <Stack anchor='left' fill='vertical'>
          <Box
            direction='row'
            fill
            justify='between'
            align='center'
            pad={{ left: _.isNil(parentLi) ? 'small' : 'medium' }}
          >
            <Box flex height='100%' pad={{ left: 'small' }}>
              {renderTitle()}
              {renderRemoval()}
            </Box>
            {renderBoolOptions()}
          </Box>
          {renderRequiredIcon()}
          {renderCaret()}
        </Stack>
      </ThemeContext.Extend>
    </Box>
  )
}

LineItemTitleCell.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  scope: PropTypes.object,
  show: PropTypes.bool,
  handleShowChange: PropTypes.func,
  updateTitle: PropTypes.func,
  removeLineItem: PropTypes.func,
  editable: PropTypes.bool,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  gcProfile: PropTypes.object,
  onRemoveAction: PropTypes.func,
  hideBorder: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    account: state.account,
    items: getAllAccountsItems(state)
  }
}

export default connect(mapStateToProps)(LineItemTitleCell)
