import * as types from 'model/actionTypes'

export function receiveOutgoingInvitations (invitations) {
  return {
    type: types.RECEIVE_OUTGOING_INVITATIONS,
    invitations
  }
}

export function receiveIncomingInvitations (invitations) {
  return {
    type: types.RECEIVE_INCOMING_INVITATIONS,
    invitations
  }
}
