import * as Device from 'expo-device'
import headers from 'shared/controllers/headers'
import _ from 'lodash'

import { getName } from 'shared/utils/stringUtils'
import config from 'shared/config'
import { isWeb } from 'shared/constants/index'

const sendForDev = false
let onboardingId = _.now()

export const setOnboardingId = () => {
  onboardingId = _.now()
}

const sendEvent = async (url, body) => {
  console.log('Zapier send event:', body)
  if (!config.isDev || sendForDev) {
    const response = await fetch(url, {
      method: 'post',
      headers: isWeb ? {} : headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('zaper send event response', answer)
  } else {
    console.log('skip sending for dev')
  }
}

const stringOfDeviceType = dt => {
  switch (dt) {
    case Device.DeviceType.PHONE:
      return 'phone'
    case Device.DeviceType.PHTABLETONE:
      return 'tablet'
    case Device.DeviceType.DESKTOP:
      return 'desktop'
    case Device.DeviceType.TV:
      return 'TV'
    default:
      return 'unknown'
  }
}

const getDeviceInfo = async () => {
  const deviceType = await Device.getDeviceTypeAsync()
  return {
    isDevice: Device.isDevice,
    brand: Device.brand,
    manufacturer: Device.manufacturer,
    modelName: Device.modelName,
    modelId: Device.modelId,
    osName: Device.osName,
    osVersion: Device.osVersion,
    osBuildId: Device.osBuildId,
    deviceName: Device.deviceName,
    deviceType: stringOfDeviceType(deviceType)
  }
}

export const trackStripeExpressConnectStart = () => {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const user = state.user
      const userProfile = _.get(state, ['profiles', user.id])
      const accountProfile = _.get(state, ['accountsProfiles', user.currentAccountId])
      const body = {
        userId: user.id,
        userName: getName(userProfile),
        userPhone: _.get(userProfile, 'phone'),
        companyName: _.get(accountProfile, 'name', user.currentAccountId),
        accountId: user.currentAccountId,
        accountUrl: `${config.adminUrl}/account/${user.currentAccountId}`
      }
      await sendEvent(config.zapierStripeExpressConnectStart, body)
    } catch (e) {
      console.log('trackStripeExpressConnectStart error', e)
    }
  }
}

export const trackStripeExpressConnectExit = () => {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const user = state.user
      const userProfile = _.get(state, ['profiles', user.id])
      const accountProfile = _.get(state, ['accountsProfiles', user.currentAccountId])
      const body = {
        userId: user.id,
        userName: getName(userProfile),
        userPhone: _.get(userProfile, 'phone'),
        companyName: _.get(accountProfile, 'name', user.currentAccountId),
        accountId: user.currentAccountId,
        accountUrl: `${config.adminUrl}/account/${user.currentAccountId}`
      }
      await sendEvent(config.zapierStripeExpressConnectExit, body)
    } catch (e) {
      console.log('trackStripeExpressConnectExit error', e)
    }
  }
}

export const trackAppInitialization = (profile, accountId, accountProfile) => {
  return async function (dispatch, getState) {
    try {
      const deviceInfo = await getDeviceInfo()
      const body = {
        userId: profile.id,
        userName: getName(profile),
        userPhone: _.get(profile, 'phone'),
        companyName: _.get(accountProfile, 'name'),
        accountId: accountId,
        deviceInfo,
        isDev: config.isDev
      }
      await sendEvent(config.zapierAppInitialized, body)
    } catch (e) {
      console.log('trackAppInitialization error', e)
    }
  }
}

export const trackOnboardingStarted = (profile, accountId, accountProfile) => {
  return async function (dispatch, getState) {
    try {
      const deviceInfo = await getDeviceInfo()
      const body = {
        id: onboardingId,
        userId: profile.id,
        userName: getName(profile),
        userPhone: _.get(profile, 'phone'),
        companyName: _.get(accountProfile, 'name'),
        accountId: accountId,
        deviceInfo,
        isDev: config.isDev
      }
      await sendEvent(config.zapierOnboardingStarted, body)
    } catch (e) {
      console.log('trackOnboardingStarted error', e)
    }
  }
}

export const trackOnboardingStep = (step, trackParams = {}, isFinal = false, stepNum, isUserOnboarding = false) => {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const user = state.user
      const profile = _.get(state, ['profiles', user.id])
      const accountProfile = _.get(state, ['accountsProfiles', user.currentAccountId])
      const strParams = _.map(trackParams, (v, k) => {
        return `${k}: \`${v}\``
      })
      const body = {
        id: onboardingId,
        userId: profile.id,
        userName: getName(profile),
        userEmail: _.get(profile, 'email'),
        userPhone: _.get(profile, 'phone'),
        companyName: _.get(accountProfile, 'name'),
        companyEmail: _.get(accountProfile, 'email'),
        companyPhone: _.get(accountProfile, 'phone'),
        accountId: user.currentAccountId,
        isDev: config.isDev,
        step,
        strParams: _.join(strParams, '\n'),
        isFinal,
        rawParams: trackParams
      }
      await sendEvent(config.zapierOnboardingStep, body)
    } catch (e) {
      console.log('trackOnboardingStep error', e)
    }
  }
}
