import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Layer, ThemeContext, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'
import { Line } from 'rc-progress'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { getInboxMessages, hasMoreInbox } from 'model/selectors/inbox'
import InboxMessage from 'components/chat/InboxMessage'
import messageType from 'constants/messageType'
import { loadMoreInbox } from 'controllers/inbox'
import { getProject } from 'model/selectors/projectsInfoSelector'

class TakeoffsPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  open = () => {
    this.setState({ visible: true })
  }

  close = () => this.setState({ visible: false })

  renderHeader = () => (
    <Box pad={{ horizontal: 'small', top: 'small', bottom: 'xsmall' }} direction='row' align='center' justify='between'>
      <Box justify='center'>
        <Text size='large' weight={600} color={colors.TEXT}>
          Takeoffs order
        </Text>
      </Box>
      <Box onClick={this.close}>
        <FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.TEXT} />
      </Box>
    </Box>
  )

  renderSubHeader = () => {
    const { project } = this.props
    const address = _.get(project, 'address.name')
    return (
      <Box pad={{ horizontal: 'small' }}>
        <Text size='small' color={colors.ANOTHER_GREY} italic>{`${address} quantity takeoffs order`}</Text>
      </Box>
    )
  }

  progressIndicator = () => {
    return (
      <Box pad={{ top: 'xsmall' }}>
        <Line
          percent={10}
          strokeWidth={1}
          strokeColor={colors.AQUA_MARINE}
          trailColor={colors.WHITE}
          strokeLinecap='square'
        />
      </Box>
    )
  }

  renderContent = () => {
    return <Text>adsf asdf</Text>
  }

  render () {
    const { visible } = this.state
    if (visible) {
      return (
        <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
          <Layer position='right' full='vertical' modal onClickOutside={this.close} onEsc={this.close}>
            <Box
              width='400px'
              fill='vertical'
              border='left'
              customStyle='box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)'
            >
              <Box fill='vertical' height='100%' justify='start'>
                {this.renderHeader()}
                {this.renderSubHeader()}
                {this.progressIndicator()}
                {this.renderContent()}
              </Box>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, props) => ({
  project: getProject(state, props),
  inboxMessages: getInboxMessages(state),
  account: state.account,
  workOrders: state.workOrders,
  hasMore: hasMoreInbox(state)
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(TakeoffsPanel)
