import React, { useState, useEffect } from 'react'
import { Box, Text, Button, Layer } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { removeUserFromAccount } from 'controllers/auth'

const RemoveModal = ({ user, setUser, dispatch }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(!_.isNil(user))
  }, [user])

  const handleRemove = () => {
    dispatch(removeUserFromAccount(user.id))
    setUser(null)
  }

  if (!open) return null
  return (
    <Layer margin='large' onEsc={() => setUser(null)} onClickOutside={() => setUser(null)} animate={false}>
      <Box width='medium' height='small'>
        <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
          <Text color={colors.BLACK} size='medium'>
            Removing{' '}
            <Text size='medium' color={colors.BLACK} weight='bold'>
              {_.get(user, 'name')}
            </Text>{' '}
            from company
          </Text>
        </Box>
        <Box pad='small' fill='horizontal' align='center' direction='row' gap='small'>
          <Text size='medium' color={colors.BLACK}>
            Are you sure?
          </Text>
        </Box>
        <Box direction='row' justify='end' gap='small' margin={{ top: 'auto' }} pad='small'>
          <Box>
            <Button primary color={colors.AQUA_MARINE} onClick={handleRemove} label='Confirm' />
          </Box>
          <Box>
            <Button primary color={colors.CORAL_TWO} onClick={() => setUser(null)} label='Cancel' />
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}

export default RemoveModal
