import { db } from 'constants/firebase'
import _ from 'lodash'
import store from 'model/store'
import Sentry from 'shared/utils/sentry'
import moment from 'moment'

import { receiveInboxFirstMessage, receiveMessagesInbox, receiveInboxFirstNote } from 'model/actions/messagesAC'
import { addListener } from 'controllers/listeners'
import { INBOX_MESSAGES_LIMIT } from 'constants/index'
import { toTimestamp } from 'shared/utils/date'
import messageType from 'constants/messageType'

export const fetchInbox = async (userId, accountId) => {
  console.log('--------------------- fetch inbox:', userId)
  try {
    const firstMsgSN = await db
      .collectionGroup('messagesList')
      .where('incomingForUsers', 'array-contains', userId)
      .orderBy('timestamp', 'asc')
      .limit(1)
      .get()
    const firstMessage = _.get(
      _.map(firstMsgSN.docs, doc => doc.data()),
      0
    )
    console.log('first message', firstMessage)
    store.dispatch(receiveInboxFirstMessage(firstMessage))
    if (firstMessage.type === messageType.NOTE) {
      store.dispatch(receiveInboxFirstNote(firstMessage))
    } else {
      const firstNoteSN = await db
        .collectionGroup('messagesList')
        .where('incomingForUsers', 'array-contains', userId)
        .where('type', '==', messageType.NOTE)
        .orderBy('timestamp', 'asc')
        .limit(1)
        .get()
      const firstNote = _.get(
        _.map(firstNoteSN.docs, doc => doc.data()),
        0
      )
      store.dispatch(receiveInboxFirstNote(firstNote))
    }
    const unsubscribe = db
      .collectionGroup('messagesList')
      .where('incomingForUsers', 'array-contains', userId)
      .orderBy('timestamp', 'desc')
      .limit(INBOX_MESSAGES_LIMIT)
      .onSnapshot(
        sn => {
          const annotations = _.map(sn.docs, doc => doc.data())
          console.log('inbox messages', annotations)
          store.dispatch(receiveMessagesInbox(_.keyBy(annotations, 'id')))
        },
        error => {
          Sentry.captureException(error)
          console.log(`fetchAnnotationsInbox  onSnapshot error: ${error}`)
        }
      )
    addListener('annotationsInbox', unsubscribe)
  } catch (e) {
    console.log('fetchInbox error')
    console.warn(e)
  }
}

export const fetchInboxNotes = (userId, callback, lastMessage = null) => {
  try {
    const notesQuery = !_.isNil(lastMessage)
      ? db
        .collectionGroup('messagesList')
        .where('incomingForUsers', 'array-contains', userId)
        .where('type', '==', messageType.NOTE)
        .orderBy('timestamp', 'desc')
        .startAfter(moment(lastMessage.timestamp).toDate())
        .limit(INBOX_MESSAGES_LIMIT)
      : db
        .collectionGroup('messagesList')
        .where('incomingForUsers', 'array-contains', userId)
        .where('type', '==', messageType.NOTE)
        .orderBy('timestamp', 'desc')
        .limit(INBOX_MESSAGES_LIMIT)
    const unsubscribe = notesQuery.onSnapshot(
      sn => {
        const notes = _.map(sn.docs, m => m.data())
        callback(notes)
      },
      error => {
        Sentry.captureException(error)
        console.log(`fetchInboxNotes onSnapshot error: ${error}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('fetchInboxNotes error')
    console.warn(e)
  }
}

export const loadMoreInbox = async () => {
  try {
    const state = store.getState()
    const userId = _.get(state, 'user.id')
    // const accountId = _.get(state, 'account.id')
    const inbox = _.get(state, 'inbox')
    const sortedMessages = _.sortBy(inbox.messages, m => toTimestamp(m.timestamp))
    const lastMessage = _.get(sortedMessages, 0)
    console.log('lastInboxMessage', lastMessage)
    if (!_.isNil(lastMessage)) {
      const newMessagesSN = await db
        .collectionGroup('messagesList')
        .where('incomingForUsers', 'array-contains', userId)
        .orderBy('timestamp', 'desc')
        .startAfter(lastMessage.timestamp)
        .limit(INBOX_MESSAGES_LIMIT)
        .get()
      const newMessages = _.keyBy(
        _.map(newMessagesSN.docs, doc => ({ ...doc.data(), id: doc.id })),
        'id'
      )
      console.log('newMessages', newMessages)
      store.dispatch(receiveMessagesInbox(newMessages))
    }
  } catch (e) {
    console.log('load more inbox error', e)
    Sentry.captureException(e)
  }
}
