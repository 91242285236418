import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, Text } from 'grommet'
import numeral from 'numeral'

import InputWithSelect from 'components/InputWithSelect'
import lineItemType from 'shared/constants/lineItemType'
import colors from 'shared/constants/colors'

const LineItemQuantityField = ({
  li,
  parentLi,
  setQuantity,
  selectRow,
  unselectRow,
  updateItem,
  placeholder,
  focusNextElement,
  disabled,
  highlighted
}) => {
  const [value, setValue] = useState(null)
  const inputRef = useRef()

  useEffect(() => {
    setValue(null)
  }, [li])

  const onChangeType = ({ option }) => {
    if (_.get(li, 'quantityType') !== option) {
      updateItem(li.id, { quantityType: option }, parentLi)
    }
  }

  const onChange = event => {
    const newValue = event.target.value.replace(/[^0-9.]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(value).value()
      if (newV === curV || _.isNil(value)) {
        setValue(newValue)
      } else {
        setValue(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setValue('')
    }
  }

  let curValue = _.get(li, 'quantity')
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format('0,0.[00]')
  }
  if (!_.isNil(value)) curValue = value
  if (_.isNil(curValue)) curValue = ''

  const onBlur = () => {
    inputRef.current.blur()
    unselectRow()
    if (!_.isNil(value)) {
      const quantity = _.size(value) > 0 ? numeral(value).value() : null
      setQuantity(quantity)
    }
  }

  const liType = _.get(li, 'type', lineItemType.DEFAULT)
  const enabled = liType === lineItemType.DEFAULT

  const inputWithSelect = (
    <InputWithSelect
      onChange={onChange}
      onFocus={selectRow}
      onBlur={onBlur}
      onSelectChange={onChangeType}
      value={curValue}
      selectValue={li.quantityType}
      options={['sf', 'cy', 'lf', 'ls', 'ea', 'cf', 'lbs', 'ft']}
      placeholder={placeholder}
      inputRef={inputRef}
      id={`${li.id}_quantity`}
      keyboardListeners={{
        onTab: e => focusNextElement('quantity', e)
      }}
      disabled={disabled}
      highlighted={highlighted}
    />
  )

  return (
    <Box plain size='150px' border={['bottom', 'right']}>
      {enabled ? (
        inputWithSelect
      ) : (
        <Box fill align='center' justify='center'>
          <Text textAlign='center' size='small' color={colors.ANOTHER_GREY}>
            -
          </Text>
        </Box>
      )}
    </Box>
  )
}

LineItemQuantityField.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  updateItem: PropTypes.func,
  setQuantity: PropTypes.func,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  placeholder: PropTypes.string
}

export default LineItemQuantityField
