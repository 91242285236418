import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'

import OutsideClickHandlerWrapper from 'components/OutsideClickHandlerWrapper'

class SlidingLeftPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
  }

  open = () => {
    this.setState({ show: true })
  }

  close = () => {
    this.setState({ show: false })
    this.props.onClose()
  }

  handleClickOutside = evt => {
    console.log('handleClickOutside', evt, this.props.id)
    this.close()
  }

  render () {
    const { show } = this.state
    const { children } = this.props
    return (
      <Collapsible direction='horizontal' open={show} customStyle='z-index: 3;'>
        <OutsideClickHandlerWrapper onClick={this.handleClickOutside} enabled={show}>
          {children}
        </OutsideClickHandlerWrapper>
      </Collapsible>
    )
  }
}

SlidingLeftPanel.defaultProps = {
  onClose: () => null
}

SlidingLeftPanel.propTypes = {
  onClose: PropTypes.func
}

export default SlidingLeftPanel
