import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { TextInput, Text, Box, ThemeContext } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlusCircle, faAngleLeft, faFile } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'

import { getName } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import AddressAutocompleteGrommet from 'components/AddressAutocompleteGrommet'
import DropDownMenu from 'components/DropDownMenu'
import { getUsersProfilesByAccountId } from 'model/selectors/profiles'

const menuActions = {
  EDIT_PROJECT: 'EDIT_PROJECT',
  MESSAGE_GC: 'MESSAGE_GC',
  INVITE_TEAMMATE: 'INVITE_TEAMMATE',
  ARCHIVE: 'ARCHIVE',
  DELETE: 'DELETE'
}

const Tab = styled.div`
  margin-right: 16px;
  border-bottom: 2px solid ${props => (props.selected ? colors.AQUA_MARINE : 'transparent')};
  cursor: pointer;
  ${props =>
    !props.selected &&
    `:hover span {
        color: ${colors.ASANA_GRAY_TEXT_HOVERED};
    }`}
`

const getColor = (index, activeIndex) => {
  if (index === activeIndex) {
    return colors.AQUA_MARINE
  } else {
    return colors.ANOTHER_GREY
  }
}

const CreateBidNavBarLeftPart = ({
  accountId,
  accountsProfiles,
  usersProfilesByAccountId,
  mode,
  activeTab,
  changeTab,
  files,
  goBack,
  mobile
}) => {
  const [value, setValue] = useState('')
  const gcInputRef = useRef()
  const addressInputRef = useRef()
  const {
    gcAccountId,
    setGCAccountId,
    address,
    setAddress,
    openCreateClientModal,
    getGCAccountProfile,
    openCommentsPanel
  } = useContext(CreateBidContext)

  useEffect(() => {
    if (!_.isNil(gcAccountId)) {
      setValue(getGCAccountProfile())
      if (_.has(addressInputRef, 'current.focus')) {
        addressInputRef.current.focus()
      }
    }
  }, [gcAccountId, getGCAccountProfile])

  useEffect(() => {
    if (_.isNil(gcAccountId)) {
      gcInputRef.current.focus()
    }
  }, [])

  const gcProfile = getGCAccountProfile()
  const gcName = getName(gcProfile)
  const isSetup = !_.isNil(address) && !_.isNil(gcAccountId)

  const onSuggestionSelect = event => {
    const sugValue = _.get(event, 'suggestion.value')
    console.log('onSuggestionSelect', sugValue)
    if (_.isObject(sugValue)) {
      setGCAccountId(sugValue.accId)
    } else {
      setGCAccountId(null)
      gcInputRef.current.blur()
      openCreateClientModal(value)
      setValue('')
    }
  }

  const onChange = event => {
    const v = _.get(event, 'target.value', '')
    if (_.size(v) === 0) {
      setGCAccountId(null)
      setValue('')
    } else {
      setValue(v)
    }
  }

  const renderCompanyOptionLabel = (accName, userName) => {
    return (
      <Box
        border='top'
        direction='row'
        align='center'
        justify='between'
        flex
        pad={{ vertical: 'small', horizontal: 'medium' }}
        width={{ min: '450px' }}
      >
        <Text weight={500} color={colors.TEXT}>
          {accName}
        </Text>
        <Text weight={500} color={colors.LIGHT_NAVY_BRIGHT}>
          {userName}
        </Text>
      </Box>
    )
  }

  const suggestions = useMemo(() => {
    const res = []
    const reg = new RegExp(_.escapeRegExp(value), 'gi')
    _.forEach(accountsProfiles, (accountProfile, accId) => {
      const accName = getName(accountProfile)
      const profiles = _.get(usersProfilesByAccountId, accId)
      _.forEach(profiles, p => {
        const userName = getName(p)
        if (accId !== accountId && (accName.match(reg) || userName.match(reg)) && accId !== gcAccountId) {
          res.push({
            label: renderCompanyOptionLabel(accName, userName),
            value: { accId, userId: p.id }
          })
        }
      })
    })
    return res
  }, [accountsProfiles, value, accountId, gcAccountId, usersProfilesByAccountId])

  const createClientOption = {
    label: (
      <Box
        border='top'
        direction='row'
        align='center'
        flex
        pad={{ vertical: 'small', horizontal: 'medium' }}
        gap='xsmall'
      >
        <FontAwesomeIcon icon={faPlusCircle} size={20} color={colors.CLEAR_BLUE} />
        <Text weight={500} color={colors.CLEAR_BLUE}>
          Create a Client
        </Text>
      </Box>
    ),
    value: null
  }

  const renderAddressInput = () => {
    if (_.isNil(address) && !_.isNil(gcAccountId)) {
      return (
        <Box width={{ min: '300px' }}>
          <AddressAutocompleteGrommet
            onSelect={setAddress}
            value={_.get(address, 'description')}
            placeholder='enter a project address'
            textInputProps={{
              dropProps: {
                width: '380px'
              },
              ref: addressInputRef
            }}
          />
        </Box>
      )
    } else if (isSetup) {
      return (
        <Text size='large' weight={500} truncate>
          {_.get(address, 'name')}
        </Text>
      )
    }
  }

  const renderPlaceholder = () => {
    if (_.isNil(gcAccountId)) {
      return (
        <Text color={colors.VERY_LIGHT_PINK} size='large' weight={500}>
          Enter client name
        </Text>
      )
    }
  }

  const renderGCInput = () => {
    if (_.isNil(gcAccountId)) {
      return (
        <TextInput
          placeholder={renderPlaceholder()}
          value={value}
          onChange={onChange}
          plain
          suggestions={!_.isEmpty(value) ? [..._.take(suggestions, 10), createClientOption] : []}
          onSuggestionSelect={onSuggestionSelect}
          size='large'
          ref={gcInputRef}
        />
      )
    } else {
      return (
        <Box direction='row' align='center' gap='small' pad={{ right: 'small' }}>
          <Text size='large' weight={500} truncate>
            {getName(gcProfile)}
          </Text>
          <Text size='large' weight={500}>
            -
          </Text>
        </Box>
      )
    }
  }

  const renderChevron = () => {
    if (isSetup) {
      return (
        <Box pad={{ left: 'small' }}>
          <FontAwesomeIcon icon={faChevronDown} size={18} color={colors.TEXT} />
        </Box>
      )
    }
  }

  const renderFile = ({ name }, id) => (
    <Tab onClick={() => changeTab(id)} selected={id === activeTab}>
      <Text color={getColor(id, activeTab)} truncate customStyle='white-space: nowrap;'>
        {name}
      </Text>
    </Tab>
  )

  const menuOptions = [
    { id: menuActions.EDIT_PROJECT, label: 'Edit project details' },
    { id: menuActions.MESSAGE_GC, label: `Message ${gcName}` },
    { id: menuActions.INVITE_TEAMMATE, label: 'Invite teammates', disabled: true },
    { id: menuActions.ARCHIVE, label: 'Archive', disabled: true },
    { id: menuActions.DELETE, label: 'Delete bid', disabled: true, color: colors.CORAL_TWO }
  ]

  const onMenuOptionClick = optionId => {
    console.log('onMenuOptionClick', optionId)
    switch (optionId) {
      case menuActions.MESSAGE_GC:
        openCommentsPanel()
        break
    }
  }

  const mainColor = activeTab === 'scope' ? colors.AQUA_MARINE : 'transparent'

  return (
    <ThemeContext.Extend value={{ global: { input: { padding: 0 } } }}>
      <Box gap='xsmall' pad={{ vertical: 'xsmall' }} margin={{ left: 'xsmall' }}>
        <Box direction='row' align='center'>
          <Box pad={{ horizontal: 'small' }} onClick={goBack}>
            <FontAwesomeIcon icon={faAngleLeft} size={24} color={colors.BLACK} />
          </Box>
          <Box>
            <DropDownMenu
              options={menuOptions}
              dropContentProps={{ boxProps: { width: { min: '160px' } } }}
              dropAlign={{ top: 'bottom', left: 'right' }}
              onOptionClick={onMenuOptionClick}
              disabled={!isSetup}
            >
              <Box direction='row' align='center'>
                {renderGCInput()}
                {renderAddressInput()}
                {renderChevron()}
              </Box>
            </DropDownMenu>
          </Box>
        </Box>
        {!mobile && (
          <Box pad={{ horizontal: 'small' }} overflow='auto' direction='row'>
            <Box
              direction='row'
              pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
              align='center'
              gap='xxsmall'
              flex={{ shrink: 0 }}
              onClick={() => changeTab('scope')}
              customStyle={`border-bottom: 2px solid ${mainColor};`}
            >
              <FontAwesomeIcon icon={faFile} size={'18px'} color={getColor('scope', activeTab)} />
              <Text color={getColor('scope', activeTab)} size='small'>
                {mode === 'create' ? 'Create bid' : 'Scope'}
              </Text>
            </Box>
            <Box margin={{ left: 'medium' }} border={['left']} pad={{ left: 'medium' }} direction='row' overflow='auto'>
              {_.map(files, renderFile)}
            </Box>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  )
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles,
  accountId: _.get(state, 'account.id'),
  usersProfilesByAccountId: getUsersProfilesByAccountId(state)
})

export default connect(mapStateToProps)(CreateBidNavBarLeftPart)
