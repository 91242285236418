import React, { useRef, useLayoutEffect } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import Comment from 'components/leveling/levelingPanel/Comment'

export const renderHeader = (name, backgroundColor) => (
  <Box
    direction='row'
    justify='between'
    pad={{ vertical: 'medium', horizontal: 'small' }}
    align='center'
    gap='medium'
    background={backgroundColor || colors.WHITE}
  >
    <Text size='medium' weight={600} color={colors.BLACK}>
      Comments
    </Text>
    <Text size='small' weight={400} color={colors.ANOTHER_GREY}>
      Only {name} can see these comments
    </Text>
  </Box>
)

export const MessagesList = ({ messages, messagesEndRef, workOrder }) => {
  const firstRender = useRef(true)
  const messagesAmount = _.size(messages)
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      messagesEndRef.current.scrollIntoView(false)
    }
  }, [messagesAmount, messagesEndRef])
  return (
    <Box
      customStyle={`
        * {
          scrollbar-width: thin;
          scrollbar-color: #9ca6af #003456;
        }

        /* Works on Chrome/Edge/Safari */
        *::-webkit-scrollbar {
          width: 12px;
        }
        *::-webkit-scrollbar-track {
          background: ${colors.PALE_GREY};
        }
        *::-webkit-scrollbar-thumb {
          background-color: #9ca6af;
          border-radius: 20px;
          border: 3px solid ${colors.PALE_GREY};
        }
            `}
    >
      <Box overflow='auto' align='start' height='100%'>
        {_.map(messages, (msg, index) => {
          return <Comment workOrder={workOrder} key={msg.id} msg={msg} index={index} prevMessage={_.get(messages, index - 1)} />
        })}
        <Box flex={{ grow: 1 }} justify='end' ref={messagesEndRef} />
      </Box>
    </Box>
  )
}
