import * as types from 'model/actionTypes'

const initialState = null

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_MESSAGES:
      return {
        ...state,
        [action.channelKey]: action.messages
      }
    case types.CLEAR_MESSAGES:
      // const newState = { ...state }
      // delete newState[action.channelKey]
      // return newState
      return state
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
