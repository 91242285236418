import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, ThemeContext, TextInput, Stack, Keyboard, DropButton, Button, Text, CheckBox } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { css } from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import generate from 'firebase-auto-ids'

import colors from 'shared/constants/colors'
import { getAllAccountsItems } from 'model/selectors/bids'
import { saveTemplate, updateTemplate } from 'controllers/account'
import lineItemType from 'shared/constants/lineItemType'

const LineItemTitleCell = ({
  li,
  parentLi,
  show,
  handleShowChange,
  updateItem,
  removeLineItem,
  selectRow,
  unselectRow,
  account,
  items,
  dispatch,
  hideBorder,
  hideAlternates,
  isQuestion = false
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [lineItems, setLineItems] = useState({})
  const [name, setName] = useState(null)
  const [open, setOpen] = useState(false)
  const [suggestionsOpen, setSuggestionsOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const [changed, setChanged] = useState(false)
  const inputRef = useRef(null)
  const isAlternate = !_.isNil(parentLi)

  useEffect(() => {
    if (_.isEmpty(_.toString(li.name))) {
      inputRef.current.focus()
    }
  }, [li.name])

  useEffect(() => {
    setLineItems(_.get(account, 'lineItems', {}))
    setSuggestions(
      _.map(_.uniqBy(_.concat(_.values(_.get(account, 'lineItems')), _.values(items)), 'name'), renderSuggestion)
    )
  }, [items, account])

  useEffect(() => {
    const templates = _.get(account, 'templates')
    if (searchValue === '') {
      setSelectedTemplates(templates)
    } else {
      setSelectedTemplates(_.filter(templates, template => _.includes(template.name, searchValue)))
    }
  }, [account, searchValue])

  const renderSuggestion = value => ({
    label: (
      <Box onMouseDown={e => e.preventDefault()} pad='small'>
        <Text>{value.name}</Text>
      </Box>
    ),
    name: value.name,
    value
  })

  const handleCreateTemplate = () => {
    const id = generate(_.now())
    const valueName = name === '' || !name ? li.name : name
    const lineItemId = _.findKey(lineItems, li => li.name === valueName)
    dispatch(saveTemplate(id, { id, name: searchValue, values: [lineItemId] }, lineItemId, valueName))
    setSearchValue('')
    setOpen(false)
  }

  const dropContent = (
    <Box width='small' flex={false}>
      <Box flex={false} fill='horizontal'>
        <TextInput
          border='none'
          placeholder='Search templates'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </Box>
      {_.size(searchValue) > 0 && _.size(selectedTemplates) === 0 && (
        <Box height='small'>
          <Box flex={false} pad='small'>
            <Button
              label='Create template'
              primary
              color={colors.AQUA_MARINE}
              onClick={handleCreateTemplate}
              disabled={searchValue.length <= 0}
            />
          </Box>
        </Box>
      )}
      {(_.size(selectedTemplates) > 0 || _.size(searchValue) === 0) && (
        <Box height='small' overflow='auto'>
          <ThemeContext.Extend
            value={{
              checkBox: {
                size: '15px',
                color: colors.AQUA_MARINE,
                hover: {
                  border: {
                    color: colors.AQUA_MARINE
                  }
                }
              }
            }}
          >
            {_.map(selectedTemplates, template => {
              const valueName = name === '' || !name ? li.name : name
              const desc = _.get(li, 'desc')
              const labor = _.get(li, 'labor', false)
              const required = _.get(li, 'required', false)
              const type = _.get(li, 'type')
              const lineItemId = _.findKey(lineItems, li => li.name === valueName)
              return (
                <Box direction='row' flex={false} key={template.id} align='center' justify='between' pad='small'>
                  <CheckBox
                    label={template.name}
                    checked={_.includes(template.values, lineItemId)}
                    onChange={e => {
                      const isNewInTemplate = e.target.checked
                      dispatch(
                        updateTemplate(
                          template.id,
                          _.omitBy(
                            {
                              name: valueName,
                              desc,
                              labor,
                              required,
                              type
                            },
                            _.isNil
                          ),
                          isNewInTemplate,
                          lineItemId
                        )
                      )
                    }}
                  />
                </Box>
              )
            })}
          </ThemeContext.Extend>
        </Box>
      )}
    </Box>
  )

  const textInputThemeValue = {
    global: {
      control: { border: { color: 'transparent', radius: '0px' } }
    },
    textInput: {
      disabled: { opacity: 0.9 },
      container: {
        extend: css`
          height: 100%;
          input {
            height: 100%;
          }
          input:focus {
            background-color: ${colors.WHITE};
            border: 1px ${colors.AQUA_MARINE} solid;
          }
        `
      }
    }
  }

  const filterByType = type => (
    isQuestion ? _.includes([lineItemType.QUESTION, lineItemType.QUESTION_BOOL], type)
      : !_.includes([lineItemType.QUESTION, lineItemType.QUESTION_BOOL], type)
  )

  const selectSuggestions = () => {
    if (!changed) {
      return []
    }
    let value
    if (!_.isNil(name)) {
      value = name
    } else if (!_.isNil(li.name)) {
      value = li.name
    }
    if (value !== '') {
      const filteredSuggestions = _.filter(
        suggestions,
        s => _.includes(_.lowerCase(s.name), _.lowerCase(value)) && s.name !== value && filterByType(_.get(s, 'value.type', lineItemType.DEFAULT))
      )
      return _.map(filteredSuggestions, v => ({
        label: (
          <Box pad='small' onMouseDown={e => e.preventDefault()}>
            <Text>{v.name}</Text>
          </Box>
        ),
        value: v
      }))
    } else {
      return []
    }
  }

  const onChange = event => {
    if (!changed) setChanged(true)
    setName(event.target.value)
  }

  const onBlur = () => {
    if (suggestionsOpen) {
      return null
    }
    setFocused(false)
    inputRef.current.blur()
    unselectRow()
    let value
    if (!_.isNil(name)) {
      value = name
    } else if (!_.isNil(li.name)) {
      value = li.name
    }
    if (_.size(value) === 0) {
      removeLineItem(li.id, parentLi)
    } else if (value !== li.name) {
      updateItem(li.id, { name }, parentLi)
      setName(null)
    }
  }

  const renderCaret = () => {
    if (!_.isEmpty(li.alternates) && !hideAlternates) {
      return (
        <Box align='start' justify='center' pad='xsmall' onClick={handleShowChange}>
          <FontAwesomeIcon icon={show ? faCaretDown : faCaretRight} color={colors.LIGHT_NAVY} size={16} />
        </Box>
      )
    }
  }

  const handleSuggestionSelect = item => {
    delete item.id
    const fields = [
      {
        label: 'type',
        default: lineItemType.DEFAULT
      },
      {
        label: 'desc',
        default: ''
      },
      {
        label: 'labor',
        default: false
      },
      {
        label: 'required',
        default: false
      }
    ]
    for (const field of fields) {
      if (!_.has(item, field.label)) {
        item[field.label] = field.default
      }
    }
    inputRef.current.blur()
    updateItem(li.id, { ...item }, parentLi)
  }

  const renderTitle = () => {
    let value
    if (!_.isNil(name)) {
      value = name
    } else if (!_.isNil(li.name)) {
      value = li.name
    }

    const handleMouseDown = e => {
      e.preventDefault()
    }

    const handleOpenChange = e => {
      inputRef.current.blur()
      setOpen(!open)
    }

    const onFocus = () => {
      selectRow()
      setFocused(true)
    }

    return (
      <Keyboard
        onEnter={e => {
          if (!suggestionsOpen) {
            e.preventDefault()
            onBlur()
          }
        }}
      >
        <Box direction='row' fill>
          <TextInput
            value={value || ''}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSuggestionSelect={e => {
              handleSuggestionSelect(e.suggestion.value.value)
            }}
            id={`name_${li.id}`}
            ref={inputRef}
            placeholder={isAlternate ? 'Write alternate name...' : 'Write line item name...'}
            height='100%'
            suggestions={selectSuggestions()}
            onSuggestionsOpen={() => setSuggestionsOpen(true)}
            onSuggestionsClose={() => setSuggestionsOpen(false)}
          />
          {(focused || open) && _.size(value) > 0 && (
            <Box
              width='xxsmall'
              fill='vertical'
              customStyle={`
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                * svg {
                  margin: auto
                }
              `}
            >
              <DropButton
                open={open}
                onClose={handleOpenChange}
                dropContent={dropContent}
                dropAlign={{ top: 'bottom', right: 'left' }}
                fill
              >
                <Box fill onMouseDown={handleMouseDown} onClick={handleOpenChange}>
                  <FontAwesomeIcon icon={faPlus} size={18} color={colors.BLACK} />
                </Box>
              </DropButton>
            </Box>
          )}
        </Box>
      </Keyboard>
    )
  }

  return (
    <Box plain border={hideBorder ? [] : ['bottom', 'right']}>
      <ThemeContext.Extend value={textInputThemeValue}>
        <Stack anchor='left' fill='vertical'>
          <Box
            direction='row'
            fill
            justify='between'
            align='center'
            pad={{ left: _.isNil(parentLi) ? 'small' : 'medium' }}
          >
            <Box flex height='100%' pad={{ left: 'small' }}>
              {renderTitle()}
            </Box>
          </Box>
          {renderCaret()}
        </Stack>
      </ThemeContext.Extend>
    </Box>
  )
}

LineItemTitleCell.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  show: PropTypes.bool,
  handleShowChange: PropTypes.func,
  updateItem: PropTypes.func,
  removeLineItem: PropTypes.func,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  account: PropTypes.object,
  items: PropTypes.object,
  hideBorder: PropTypes.bool,
  hideAlternates: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    account: state.account,
    items: getAllAccountsItems(state)
  }
}

export default connect(mapStateToProps)(LineItemTitleCell)
