import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { auth, db, fieldValue } from 'constants/firebase'
import { receiveProposals } from 'model/actions/proposalsAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { isLocalFileUrl } from 'shared/utils/url'
import { saveFile } from 'shared/controllers/storage'
import headers from 'shared/controllers/headers'
import config from 'shared/config'

export const fetchProposals = accountId => {
  try {
    console.log('fetch proposals, accountId:', accountId)
    const unsubscribe = db
      .collection('proposals')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('proposals received', _.size(res))
          store.dispatch(receiveProposals(res))
        },
        err => {
          console.log(`fetchProposals error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('proposals', unsubscribe)
  } catch (e) {
    console.log('fetchProposals error', e)
    Sentry.captureException(e)
  }
}

const sendToHomeowner = async proposal => {
  const endpoint = '/proto/sendProposal'
  try {
    const currentUser = auth.currentUser
    const body = {
      proposalId: proposal.id,
      accountId: proposal.accountId
    }
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      body.authToken = authToken
    }
    const url = `${config.dynamicUrl}/${endpoint}`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      return await response.json()
    } else {
      console.warn('sendToHomeowner failed response: ', response)
      return { error: response.statusText }
    }
  } catch (e) {
    console.warn('getContractSignUrl error: ', e)
    return { error: e.message }
  }
}

export async function saveProposal (proposal) {
  try {
    console.log('save proposal', proposal)
    const agreements = _.get(proposal, 'agreements', [])
    const paperAgreements = _.get(proposal, 'paperAgreements')
    if (_.isEmpty(agreements) && !_.isEmpty(paperAgreements)) {
      const uploadedAgreements = await Promise.all(
        _.map(paperAgreements, async agr => {
          if (isLocalFileUrl(agr.url)) {
            const { url, size } = await saveFile(
              `account/${proposal.accountId}/documents/${proposal.id}/${agr.id}`,
              agr.url
            )
            return {
              ...agr,
              url,
              size
            }
          } else {
            return agr
          }
        })
      )
      _.set(proposal, 'paperAgreements', _.keyBy(uploadedAgreements, 'id'))
    }

    console.log('proposal after saving agreements', proposal)
    // reset current proposal signing progress
    const dbProposal = {
      ...proposal,
      createdAt: _.now(),
      accepted: fieldValue.delete(),
      signedByHic: fieldValue.delete(),
      signedByHomeowner: fieldValue.delete(),
      signatureRequestId: fieldValue.delete(),
      proposalSignature: fieldValue.delete(),
      status: fieldValue.delete()
    }
    await db
      .collection('proposals')
      .doc(proposal.id)
      .set(dbProposal, { merge: true })
    const appointmentId = _.get(proposal, 'appointmentId')
    if (!_.isNil(appointmentId)) {
      await db
        .collection('appointments')
        .doc(appointmentId)
        .update({ proposalId: proposal.id })
    }
    const canBecomeProject = _.isEmpty(_.get(proposal, 'agreements', []))
    if (!canBecomeProject) {
      await sendToHomeowner(proposal)
    }
    return null
  } catch (e) {
    Sentry.captureException(e)
    console.log('save proposal error:', e.message)
    return null
  }
}

export async function getContractSignUrl (proposalId) {
  const endpoint = '/proto/contractSignUrl'
  try {
    const currentUser = auth.currentUser
    const body = { proposalId }
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      body.authToken = authToken
    }
    const url = `${config.dynamicUrl}/${endpoint}`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    if (response.status === 200) {
      return await response.json()
    } else {
      console.warn('getContractSignUrl failed response: ', response)
      return { error: response.statusText }
    }
  } catch (e) {
    console.warn('getContractSignUrl error: ', e)
    return { error: e.message }
  }
}
