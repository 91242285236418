import React, { useContext, useEffect } from 'react'
import { Box, Text, Button, ResponsiveContext } from 'grommet'
import _ from 'lodash'

import Logo from 'components/logos/LogoOnLight'
import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import history from 'shared/utils/history'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import { calendlyUrl } from 'shared/config'
import PreconContext from 'webPages/precon/PreconContext'

const PreconBookACall = () => {
  const size = useContext(ResponsiveContext)
  const { goToNextScreen } = useContext(PreconContext)
  const goBack = () => {
    universalNavigation.goBack()
  }

  useEffect(() => {
    window.Calendly.initInlineWidget({
      url: calendlyUrl,
      parentElement: document.getElementById('calendly_widget'),
      prefill: {},
      utm: {}
    })
  }, [size])

  const goNext = () => {
    goToNextScreen(screens.PRECON_BOOK_A_CALL)
  }

  if (size === 'small') {
    return (
      <Box align='start'>
        <Box
          pad='medium'
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='28px' />
        </Box>
        <Box flex={false} pad={{ horizontal: 'medium', vertical: 'medium' }} alignSelf='center' width='large'>
          <Box pad={{ bottom: 'medium' }} direction='row' align='center' gap='small'>
            <Button plain icon={<FontAwesomeIcon icon={faArrowLeft} onClick={goBack} />} />
            <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start' weight={600}>
              Book kickoff call
            </Text>
          </Box>
        </Box>
        <Box id='calendly_widget' width='xlarge' height='1120px' className='calendly-inline-widget' />
        <Box pad={{ vertical: 'large', horizontal: 'small' }} fill={'horizontal'}>
          <Button size='large' primary label='Next' color={colors.AQUA_MARINE} onClick={goNext} />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='32px' />
        </Box>
        <Box flex={false} alignSelf='center'>
          <Box pad={{ bottom: 'medium', horizontal: '5%' }} direction='row' align='center' justify='between'>
            <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
              Book a kickoff call with your Precon Manager
            </Text>
            <Button secondary label='Skip' onClick={goNext} />
          </Box>
          <Box id='calendly_widget' width='xlarge' height='800px' className='calendly-inline-widget' />
          {_.get(history, 'length', 0) > 0 && (
            <Box pad={{ top: 'medium', horizontal: 'xlarge' }} direction='row' justify='center'>
              <Button
                size='large'
                icon={<FontAwesomeIcon icon={faArrowLeft} size={14} />}
                label='Back'
                color={colors.LINK}
                onClick={goBack}
              />
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

export default PreconBookACall
