import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Layer, ThemeContext, Text, Button, Accordion, AccordionPanel } from 'grommet'
import _ from 'lodash'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowToRight, faCalendarAlt, faCalendarPlus } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import DatePicker from 'components/DatePicker'

const MOCK_UP_DATA = [
  {
    date: 1610883554,
    accountId: 'PcavgvhoSkUktSrCRJI9men8FQs2',
    subId: '1OwEbrwHjiaxZXuJlBxT0bvN9Ao1',
    description: 'Please meet the general contractor names William at the corner of the building.'
  },
  {
    date: 1611315554,
    accountId: '1OwEbrwHjiaxZXuJlBxT0bvN9Ao1',
    subId: 'PcavgvhoSkUktSrCRJI9men8FQs2',
    description: 'Please meet the general contractor names William at the corner of the building.'
  }
]

const CalendarSidepanel = ({ open, setOpen, appointments = MOCK_UP_DATA, accountsProfiles, type }) => {
  const [activeIndex, setActiveIndex] = useState([0, 1])

  const [jobWalkStartDate, setJobWalkStartDate] = useState(null)
  const [rfiDueDate, setRfiDueDate] = useState(null)
  const [bidsDueDate, setBidsDueDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [timeZone, setTimeZone] = useState(null)

  if (!open) return null

  const handleClose = () => {
    setOpen(false)
  }

  const renderHeader = () => (
    <Box pad={{ horizontal: 'medium', vertical: 'large' }} direction='row' align='center' justify='between'>
      <Box justify='center'>
        <Text size='large' color={colors.TEXT}>Dates</Text>
      </Box>
      <Box onClick={handleClose}>
        <FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.TEXT} />
      </Box>
    </Box>
  )

  const renderContent = () => (
    <Box overflow='scroll' pad='xxsmall' fill>
      <Accordion animate multiple activeIndex={activeIndex} onActive={value => setActiveIndex(value)}>
        <AccordionPanel label={renderAccordionLabel('Important dates')}>
          {renderDates()}
        </AccordionPanel>
        <AccordionPanel label={renderAccordionLabel('Appointments')}>
          {renderAppointments()}
        </AccordionPanel>
      </Accordion>
    </Box>
  )

  const renderAccordionLabel = label => (
    <Box pad='small'>
      <Text color={colors.BROWN_GREY_TWO} size='large'>{label}</Text>
    </Box>
  )

  const renderDates = () => (
    <Box>
      <Box direction='row' gap='small' pad='small'>
        <Box>
          <DatePicker
            title='Job Walk Date'
            value={jobWalkStartDate}
            timeZone={timeZone}
            onChange={value => setJobWalkStartDate(value)}
          />
        </Box>
        <Box>
          <DatePicker
            title='RFI Due Date'
            value={rfiDueDate}
            timeZone={timeZone}
            onChange={value => setRfiDueDate(value)}
          />
        </Box>
      </Box>

      <Box direction='row' gap='small' pad='small'>
        <Box>
          <DatePicker
            title='Bids Due Date'
            value={bidsDueDate}
            timeZone={timeZone}
            onChange={value => setBidsDueDate(value)}
          />
        </Box>
        <Box>
          <DatePicker
            title='Expected Start Date'
            value={startDate}
            timeZone={timeZone}
            onChange={value => setStartDate(value)}
          />
        </Box>
      </Box>
    </Box>
  )

  const renderAppointments = () => (
    <Box>
      {_.map(appointments, renderAppointment)}
      <Box border='top'>
        <Box
          width='small'
          pad={{ top: 'xsmall' }}
          customStyle={`
            :hover * {
              color: ${colors.LIGHT_NAVY_DARK};
              fill: ${colors.LIGHT_NAVY_DARK}
            }
          `}
        >
          <Button label='Create appointment' icon={<FontAwesomeIcon icon={faCalendarPlus} color={colors.VERY_LIGHT_PINK} size={14} />} />
        </Box>
      </Box>
    </Box>
  )

  const renderAppointment = (value, index) => {
    const accountName = type === 'gc' ? _.get(accountsProfiles, [value.subId, 'name']) : _.get(accountsProfiles, [value.accountId, 'name'])
    return (
      <Box key={index} fill pad='small' border='top' direction='row' gap='medium' align='center' onClick={() => null}>
        <Box>
          <FontAwesomeIcon icon={faCalendarAlt} size={18} color={colors.VERY_LIGHT_PINK} />
        </Box>
        <Box>
          <Text color={colors.LIGHT_NAVY_DARK}>{moment.unix(value.date).format('MMM D, h:mma')} appointment with {accountName}</Text>
          <Text size='small' color={colors.ANOTHER_GREY}>{value.description}</Text>
        </Box>
      </Box>
    )
  }

  return (
    <ThemeContext.Extend value={{ layer: { overlay: { background: 'transparent' } } }}>
      <Layer position='right' full='vertical' modal onClickOutside={handleClose} onEsc={handleClose}>
        <Box width='400px' fill='vertical' border='left' customStyle='box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)'>
          <Box fill justify='start'>
            {renderHeader()}
            {renderContent()}
          </Box>
        </Box>
      </Layer>
    </ThemeContext.Extend>
  )
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(CalendarSidepanel)
