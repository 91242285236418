import React from 'react'
import { Box } from 'grommet'
import _ from 'lodash'

import Header from 'webPages/invitation/mobile/Header'
import NavBar from 'webPages/invitation/mobile/NavBar'
import Content from 'webPages/invitation/mobile/Content'
import Footer from 'webPages/invitation/mobile/Footer'

const ResolveInviteMobile = ({
  userId,
  address,
  files,
  scope,
  sections,
  workOrderId,
  projectName,
  gcName,
  bidsDue,
  mode,
  invId,
  invitedBy,
  projectId,
  handleResolve,
  hasInAccepted,
  hasInDeclined,
  avatar
}) => {
  const isResolved = hasInAccepted || hasInDeclined
  return (
    <Box fill>
      <Header mode={mode} />
      <Content
        mode={mode}
        scope={scope}
        sections={sections}
        activeIndex={mode}
        files={files}
        workOrderId={workOrderId}
        userId={userId}
        invitedBy={invitedBy}
        isEmptySow={_.size(scope) === 0}
        gcName={gcName}
        bidsDue={bidsDue}
        projectName={projectName}
        address={address}
        invId={invId}
        projectId={projectId}
        avatar={avatar}
      />
      <Footer
        handleResolve={handleResolve}
        isResolved={isResolved}
      />
    </Box>
  )
}

export default ResolveInviteMobile
