import _ from 'lodash'
import jobOfferStatus from 'shared/constants/jobOfferStatus'
import bidStatus from 'shared/constants/bidStatus'

export const getJobOfferStatus = (wo, myBid, accountId, userId) => {
  let status = jobOfferStatus.NEW
  const isDeclinedBySub = _.get(wo, ['declinedBy', accountId])
  const isAcceptedBySub = _.get(wo, ['acceptedBy', accountId])
  let seen = _.has(wo, ['seenBy', userId])
  const approvedBidId = _.get(wo, 'approvedBidId')
  if (_.get(wo, 'deleted') > 0) {
    status = jobOfferStatus.ARCHIVED
  } else if (!_.isNil(approvedBidId)) {
    if (approvedBidId === _.get(myBid, 'id')) {
      status = jobOfferStatus.ACCEPTED
      seen = _.has(myBid, ['seenBy', userId])
    } else if (!_.isNil(myBid)) {
      status = jobOfferStatus.DECLINED
      seen = _.has(myBid, ['seenBy', userId])
    } else if (_.isNil(myBid)) {
      status = jobOfferStatus.LOST
    }
  } else {
    if (isDeclinedBySub) {
      status = jobOfferStatus.DECLINED_BY_SUB
    } else if (!_.isNil(myBid)) {
      const isDeclined = _.get(myBid, 'status.type') === bidStatus.DECLINED
      status = isDeclined ? jobOfferStatus.DECLINED : jobOfferStatus.PENDING
      seen = _.has(myBid, ['seenBy', userId])
    } else if (isAcceptedBySub) {
      status = jobOfferStatus.ACCEPTED_BY_SUB
    }
  }
  return {
    status,
    seen
  }
}
