import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'grommet'
import colors from 'shared/constants/colors'
import { FormClose } from 'grommet-icons'

const Tag = ({ label, removeTag, neededMargin, noNeedRemoveTag }) => {
  return (
    <Box
      direction='row'
      justify='between'
      align='center'
      pad={{ vertical: noNeedRemoveTag ? 'xxsmall' : 'xsmall', horizontal: noNeedRemoveTag ? 'medium' : 'xsmall' }}
      background={colors.AQUA_MARINE}
      round='large'
      gap='small'
      margin={{ vertical: neededMargin ? 'xxsmall' : 'none' }}
    >
      {!noNeedRemoveTag && <FormClose color={colors.AQUA_MARINE} style={{ width: '15px', height: '15px' }} />}
      <Text size={noNeedRemoveTag ? 'xsmall' : 'small'} color={colors.WHITE} truncate>
        {label}
      </Text>
      {!noNeedRemoveTag && (
        <Box round='full' onClick={removeTag}>
          <FormClose color={colors.WHITE} size='small' style={{ width: '15px', height: '15px' }} />
        </Box>
      )}
    </Box>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  removeTag: PropTypes.func
}

export default Tag
