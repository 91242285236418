import React, { useContext } from 'react'
import { Box, Text, Button } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import DiscountInput from 'components/createBid/DiscountInput'
import UnpricedInput from 'components/createBid/UnpricedInput'
import amountType from 'shared/constants/amountType'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import numeral from 'numeral'

const CreateBidBottomMenu = ({ mode, goBack, onNextClick, setFieldParent, getFieldParent, nextComponent }) => {
  const {
    getField,
    canSendBid,
    setField
  } = useContext(CreateBidContext)

  const setDiscountType = v => {
    handleSetField('discount')({ type: v, value: 0 })
  }

  const handleSetField = type => value => {
    if (setFieldParent) {
      setFieldParent(type)(value)
    } else {
      setField(type)(value)
    }
  }

  const handleGetField = (type, defaultValue = {}) => {
    if (getFieldParent) {
      return getFieldParent(type, defaultValue)
    } else {
      return getField(type, defaultValue)
    }
  }

  const setDiscountValue = v => {
    const discount = handleGetField('discount')
    const type = _.get(discount, 'type', amountType.FLAT_AMOUNT)
    let value = v
    if (type === amountType.FLAT_AMOUNT) {
      value = _.max([0, value])
    } else {
      value = _.max([0, value])
      value = _.min([100, value])
    }
    handleSetField('discount')({ type, value })
  }

  const renderDiscounts = () => {
    const discount = handleGetField('discount')
    const type = _.get(discount, 'type', amountType.FLAT_AMOUNT)
    const value = _.get(discount, 'value', 0)
    return (
      <DiscountInput
        discountType={type}
        discountValue={value}
        setDiscountType={setDiscountType}
        setDiscountValue={setDiscountValue}
      />
    )
  }

  const renderUnpricedItems = () => {
    return (
      <UnpricedInput
        initialValue={handleGetField('unpricedAmount', 0)}
        save={handleSetField('unpricedAmount')}
      />
    )
  }

  const renderTotal = () => {
    const total = handleGetField('total', 0)
    return (
      <Box direction='row' align='center' pad={{ left: 'small' }}>
        <Text size='large' alignSelf='center' color={colors.LIGHT_NAVY_BRIGHT}>
          Total:
        </Text>
        <Box pad={{ left: 'medium' }} width={{ min: '260px' }}>
          <Text size='xlarge' color={colors.LIGHT_NAVY} weight={600}>
            {numeral(total).format('$ 0,0.[00]')}
          </Text>
        </Box>
      </Box>
    )
  }

  if (mode && mode !== 'scope') {
    return (
      <Box
        height='60px'
        fill='horizontal'
        background={colors.LIGHT_NAVY_BRIGHT}
        pad={{ vertical: 'small', horizontal: 'medium' }}
        customStyle={`opacity: 95%`}
      >
        <Box width='small' height='100%' justify='center'>
          <Button
            plain
            label={(
              <Box direction='row' justify='start' align='center' gap='small'>
                <FontAwesomeIcon icon={faArrowLeft} color={colors.WHITE} />
                <Text color={colors.WHITE} size='large'>Back to create bid</Text>
              </Box>
            )}
            onClick={goBack}
          />
        </Box>
      </Box>
    )
  }

  const renderNext = () => {
    if (nextComponent) {
      return nextComponent()
    }
    return (
      <Box width='150px' height='70%' margin={{ right: 'medium' }}>
        <Button
          fill
          label={
            <Text size='large' color={colors.WHITE}>Next</Text>
          }
          primary
          color={colors.AQUA_MARINE}
          onClick={onNextClick}
          disabled={!canSendBid()}
        />
      </Box>
    )
  }

  return (
    <Box
      height='65px'
      width='100%'
      background={colors.PALE_GREY}
      direction='row'
      align='center'
      pad={{ horizontal: 'small' }}
      gap='xxsmall'
      justify='end'
    >
      {renderDiscounts()}
      {renderUnpricedItems()}
      {renderTotal()}
      {renderNext()}
    </Box>
  )
}

export default CreateBidBottomMenu
