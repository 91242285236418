import _ from 'lodash'

let listeners = {}

export function addListener (key, l) {
  const existingUnsubscribe = _.get(listeners, key)
  if (existingUnsubscribe) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key) {
  return _.has(listeners, key)
}

export function clearListeners () {
  console.log('clear listeners:')
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = listeners[key]
    // console.log('off:', l)
    // l.off()
    l()
  }

  listeners = {}
}

export function offListener (key) {
  const l = _.get(listeners, key)
  if (l) {
    // l.off()
    l()
    delete listeners[key]
  }
}

let userUnsubscribe

export function setUserUnsubscribe (unsubscribe) {
  userUnsubscribe = unsubscribe
}

export function clearUserListener () {
  if (userUnsubscribe) userUnsubscribe()
}
