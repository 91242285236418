import React from 'react'
import { View, Image } from 'react-native'
import styled from 'sc'
import _ from 'lodash'
import { StyledText, SpaceBetweenFlexDiv } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import DocDate from 'shared/documents/components/DocDate'

const LogoImageContainer = styled(View)`
  display: flex;
  /* max-width: ${props => props.getSize(100)}px; */
  width: ${props => props.getSize(40)}px;
  height: ${props => props.getSize(40)}px;
  margin-right: ${props => props.getSize(10)}px;
`
const LogoImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const CompanyContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DocHeader = ({ getSize, companyName, companyLogo, title, docDate }) => {
  const companyTitle = (
    <StyledText fontSize={getSize(16)} color={colors.BLACK} bold>
      {companyName}
    </StyledText>
  )

  const renderCompanyBlock = () => {
    if (!_.isNil(companyLogo)) {
      return (
        <CompanyContainer>
          <LogoImageContainer getSize={getSize}>
            <LogoImage
              getSize={getSize}
              source={{ uri: companyLogo }}
              defaultSource={{ uri: companyLogo }}
              resizeMode='contain'
            />
          </LogoImageContainer>
          {companyTitle}
        </CompanyContainer>
      )
    } else {
      return companyTitle
    }
  }

  return (
    <SpaceBetweenFlexDiv>
      {renderCompanyBlock()}
      <View>
        <StyledText fontSize={getSize(16)} color={colors.LIGHT_NAVY_BRIGHT} bold>
          {title}
        </StyledText>
        <DocDate getSize={getSize} date={docDate} />
      </View>
    </SpaceBetweenFlexDiv>
  )
}

export default DocHeader
