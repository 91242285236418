import _ from 'lodash'
import Sentry from 'shared/utils/sentry'
import generate from 'firebase-auto-ids'
import { saveAs } from 'file-saver'

import { auth, db, fieldValue, firestore } from 'constants/firebase'
import store from 'model/store'
import { receiveWorkOrders } from 'model/actions/workOrdersAC'
import { addListener } from 'controllers/listeners'
import { updateProjectAdmins } from 'controllers/project'
import { saveFile } from 'shared/controllers/storage'
import { makeChannelKey } from 'shared/utils/chatUtils'
import { formatProfile, getWorkOrderSendingData } from 'shared/utils/stringUtils'
import messageType from 'shared/constants/messageType'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import courierEventsIds from 'shared/constants/courierEvents'
import sendTypes from 'shared/constants/inviteStatus'
import { isSilentBid } from 'shared/utils/bid'

export function setWorkOrderSeen (workOrderId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = { [`seenBy.${userId}`]: _.now() }
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(upd)
  }
}

export function acceptJobOffer (workOrderId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const currentAccountId = _.get(getState(), 'user.currentAccountId')
    const info = {
      userId,
      timestamp: _.now()
    }
    const upd = {
      [`acceptedBy.${currentAccountId}`]: info,
      seenBy: { [userId]: _.now() }
    }
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(upd)
  }
}

export const fetchWorkOrders = (accountId, isGC) => {
  try {
    console.log('%c fetch workOrders, accountId:', 'color: lightgreen', accountId, 'isGC', isGC)
    const allWosRef = db.collection('workOrders').where('accounts', 'array-contains', accountId)
    const activeWosRef = db
      .collection('workOrders')
      .where('accounts', 'array-contains', accountId)
      .where('deleted', '==', 0)
    const ref = !isGC ? allWosRef : activeWosRef
    const unsubscribe = ref.onSnapshot(
      sn => {
        const res = {}
        sn.forEach(doc => {
          const wo = doc.data()
          _.set(res, doc.id, wo)
        })
        console.log('work orders received', _.size(res))
        store.dispatch(receiveWorkOrders(res))
      },
      err => {
        console.log(`fetchWorkOrders error: ${err}`)
        Sentry.captureException(err)
      }
    )
    addListener('workOrders', unsubscribe)
  } catch (e) {
    console.log('fetchWorkOrders error', e)
    Sentry.captureException(e)
  }
}

export const getArchivedWorkOrders = projectId => async (dispatch, getState) => {
  const accountId = _.get(getState(), 'account.id')
  const workOrdersSN = await db
    .collection('workOrders')
    .where('accounts', 'array-contains', accountId)
    .where('deleted', '>', 0)
    .where('projectId', '==', projectId)
    .get()
  const workOrders = _.keyBy(
    _.map(workOrdersSN.docs, doc => doc.data()),
    'id'
  )
  console.log('receive archived work orders amount', _.size(workOrders))
  dispatch(receiveWorkOrders(workOrders))
}

export const fetchWorkOrderAsync = async id => {
  try {
    const sn = await db
      .collection('workOrders')
      .doc(id)
      .get()
    return sn.data()
  } catch (e) {
    console.log('fetchWorkOrders error', e)
    Sentry.captureException(e)
    return null
  }
}

export function removeAccountFromWorkOrder (workOrder) {
  return async function (dispatch, getState) {
    try {
      const currentAccountId = _.get(getState(), 'user.currentAccountId')
      const currentUserId = _.get(getState(), 'user.id')
      const workOrderId = _.get(workOrder, 'id')
      const workOrderAccountId = _.get(workOrder, 'accountId')
      const projectId = _.get(workOrder, 'projectId')
      const projectAddressName = _.get(workOrder, 'projectAddress.name')
      console.log('removeAccountFromWorkOrder', workOrderId)
      await db
        .collection('workOrders')
        .doc(workOrderId)
        .update({
          [`declinedBy.${currentAccountId}`]: {
            userId: currentUserId,
            accountId: currentAccountId,
            timestamp: firestore.FieldValue.serverTimestamp()
          },
          seenBy: { [currentUserId]: _.now() }
        })
      const channelId = makeChannelKey(workOrderAccountId, projectId, currentAccountId)
      const messageId = generate(_.now())
      const msg = {
        id: messageId,
        userId: currentUserId,
        type: messageType.TEXT,
        text: `Job offer at ${projectAddressName} was declined`,
        timestamp: fieldValue.serverTimestamp()
      }
      await db
        .collection('channels')
        .doc(channelId)
        .collection('messages')
        .doc(messageId)
        .set(_.omitBy(msg, _.isNil))
    } catch (e) {
      console.warn('Error removeAccountFromWorkOrder', e)
    }
  }
}

export function saveWorkOrder (wo) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const accountId = _.get(state, 'user.currentAccountId')
      const project = _.get(state, ['projects', wo.projectId])
      if (accountId) {
        const dbwo = {
          id: wo.id,
          accounts: [accountId],
          projectId: wo.projectId,
          accountId: accountId,
          createdAt: firestore.FieldValue.serverTimestamp(),
          deleted: 0,
          createdBy: auth.currentUser.uid,
          label: _.get(wo, 'label', null),
          desc: _.get(wo, 'desc', null),
          projectAddress: _.get(project, 'address', null),
          items: _.get(wo, 'items', {}),
          internal: wo.internal || null
        }
        await db
          .collection('workOrders')
          .doc(wo.id)
          .set(dbwo)

        const files = _.get(wo, 'files', {})
        for (const fileId in files) {
          const f = files[fileId]
          const storagePath = `workOrders/${wo.id}/${f.id}`
          const fileRes = await saveFile(storagePath, f.url)
          const storagePathThumb = `workOrders/${wo.id}/${f.id}_thumb`
          const thumbRes = await saveFile(storagePathThumb, f.thumbUrl)
          const fileInfo = {
            id: fileId,
            url: fileRes.url,
            size: fileRes.size,
            thumbUrl: thumbRes.url
          }
          db.collection('workOrders')
            .doc(wo.id)
            .update({ [`files.${f.id}`]: fileInfo })
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log('saveWorkOrder error:', e.message)
    }
  }
}

export const saveFileToWorkOrder = async (woId, file) => {
  try {
    await db
      .collection('workOrders')
      .doc(woId)
      .update({ [`files.${file.id}`]: file })
  } catch (error) {
    console.log(error)
  }
}

export const saveFilesToWorkOrder = async (projectId, woId, files) => {
  console.log('save files to workOrder', woId, files)
  const updWo = {}
  const updProject = {}
  _.forEach(files, file => {
    _.set(updWo, [`files.${file.id}`], file)
    _.set(updProject, [`attachments.${file.id}`], file)
  })
  console.log('updWo', updWo)
  console.log('updProject', updWo)
  try {
    const batch = db.batch()
    batch.update(db.collection('workOrders').doc(woId), updWo)
    batch.update(db.collection('projects').doc(projectId), updProject)
    await batch.commit()
  } catch (error) {
    console.log(error)
  }
}

export const deleteWorkOrderAttachment = async (woId, file) => {
  // const storagePath = `workOrders/${woId}/${file.id}`
  // deleteFile(storagePath)
  // if (_.has(file, 'thumbUrl')) {
  //   const storagePathThumb = `workOrders/${woId}/${file.id}_thumb`
  //   deleteFile(storagePathThumb)
  // }
  await db
    .collection('workOrders')
    .doc(woId)
    .update({ [`files.${file.id}`]: fieldValue.delete() })
}

export const updateBidInvite = async params => {
  try {
    const dbwo = _.mapValues(params, value => (_.isNil(value) ? firestore.FieldValue.delete() : value))
    console.log('updating bid invite', dbwo)
    await db
      .collection('workOrders')
      .doc(params.id)
      .update(dbwo)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateBidInvite error:', e.message)
  }
}

export const saveBidInvite = async (params, isUpdate) => {
  try {
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const userId = _.get(state, 'user.id')
    const project = _.get(state, ['projects', params.projectId])
    let dbwo = {
      id: params.id,
      deleted: 0,
      accounts: [accountId],
      projectId: params.projectId,
      accountId: accountId,
      createdAt: firestore.FieldValue.serverTimestamp(),
      createdBy: userId,
      seenBy: {
        [userId]: _.now()
      },
      desc: _.get(params, 'description', null),
      projectAddress: _.get(project, 'address', null),
      projectApartment: _.get(project, 'apartment', null),
      tradeId: params.tradeId,
      requestType: params.requestType,
      bidsDueDate: params.bidsDueDate,
      jobWalkStartDate: params.jobWalkStartDate,
      jobWalkEndDate: params.jobWalkEndDate,
      startDate: params.startDate,
      endDate: params.endDate,
      teamLeadId: params.teamLeadId,
      jobWalkDateType: params.jobWalkDateType,
      files: _.get(params, 'files'),
      label: _.get(params, 'label', null)
    }
    dbwo = _.omitBy(dbwo, _.isNil)
    console.log('saving bid invite', dbwo)
    await db
      .collection('workOrders')
      .doc(params.id)
      .set(dbwo)
    store.dispatch(updateProjectAdmins(params.projectId, params.admins))
  } catch (e) {
    Sentry.captureException(e)
    console.log('saveBidInvite error:', e.message)
  }
}

export const removeSubInvitation = (accountId, workOrderId, invIds) => {
  return async (dispatch, getState) => {
    console.log('remove sub invitation', accountId)
    const woUpdate = {
      accounts: fieldValue.arrayRemove(accountId),
      [`invitations.${accountId}`]: fieldValue.delete(),
      [`acceptedBy.${accountId}`]: fieldValue.delete(),
      [`declinedBy.${accountId}`]: fieldValue.delete()
    }
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(woUpdate)
    _.forEach(invIds, async invId => {
      await db
        .collection('subInvitations')
        .doc(invId)
        .delete()
    })
  }
}

export const searchSubs = async (workOrderId, externally = false, params = {}) => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    workOrderId,
    ...params
  }
  console.log('body', body)
  const url = `${config.dynamicUrl}/proto/${externally ? 'searchSubsExternally' : 'searchSubs'}`
  console.log('post to url ', url, 'body', body)
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    // console.log('searchSubs response json', res)
    return res
  } else {
    console.log('searchSubs response status', response.status)
    return { error: `Server response status: ${response.status}` }
  }
}

export const inviteExistingSub = async (subAccountId, workOrderId) => {
  console.error('inviteExistingSub: the method is deprecated')
  // try {
  //   console.log('call inviteExistingSub', subAccountId, workOrderId)
  //   const state = store.getState()
  //   const accountId = _.get(state, 'user.currentAccountId')
  //   const currentUser = auth.currentUser
  //   const authToken = await currentUser.getIdToken()
  //   const body = {
  //     authToken,
  //     accountId,
  //     workOrderId,
  //     subAccountId
  //   }
  //   const url = `${config.dynamicUrl}/proto/inviteExistingSub`
  //   const response = await fetch(url, {
  //     method: 'post',
  //     headers: headers,
  //     body: JSON.stringify(body)
  //   })
  //   const answer = await response.json()
  //   console.log('aswer', answer)
  // } catch (e) {
  //   Sentry.captureException(e)
  //   console.log('inviteExistingSub error:', e.message)
  // }
}

const sendInviteSubRequest = async (accountId, workOrderId, invId, sendType) => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    accountId,
    workOrderId,
    invId,
    sendType
  }
  const url = `${config.dynamicUrl}/proto/inviteSub`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  const answer = await response.json()
  console.log('inviteSub', answer)
}

export const sendDelayedInvite = async (accountId, workOrderId, invId) => {
  try {
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update({
        [`invitations.${invId}.sendType`]: fieldValue.delete(),
        [`invitations.${invId}.timestamp`]: fieldValue.serverTimestamp()
      })
    await sendInviteSubRequest(accountId, workOrderId, invId, sendTypes.IMMEDIATELY)
  } catch (error) {
    console.log('sendDelayedInvite error', error.message)
  }
}

export const inviteSub = async (subAccountId, companyId, sendTo, workOrderId, sendType = sendTypes.IMMEDIATELY) => {
  try {
    console.log('call inviteExistingSub', subAccountId, companyId, sendTo, workOrderId)
    const state = store.getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const workOrder = _.get(state, ['workOrders', workOrderId])
    const subInvited = _.has(workOrder, ['invitatioins', subAccountId])
    const companyInvited = _.has(workOrder, ['invitatioins', companyId])
    if (subInvited || companyInvited) {
      console.log('inviteSub, SKIP: already invited, subInvited', subInvited, 'companyInvited', companyInvited)
      return false
    } else {
      const subAccountProfile = _.get(state, ['accountsProfiles', subAccountId])
      const contactCompany = _.get(state, ['contacts', 'companies', companyId])
      const invId = subAccountId ?? companyId
      const invParams = {
        companyName: _.get(subAccountProfile, 'name', _.get(contactCompany, 'name', null)),
        sendTo,
        id: invId,
        subAccountId,
        companyId,
        invitedBy: _.get(state, 'user.id'),
        timestamp: fieldValue.serverTimestamp(),
        sendType
      }
      const updWorkOrder = {
        [`invitations.${invId}`]: _.omitBy(invParams, 'isNil')
      }
      await db
        .collection('workOrders')
        .doc(workOrderId)
        .update(updWorkOrder)
      if (_.isEqual(sendType, sendTypes.IMMEDIATELY)) {
        sendInviteSubRequest(accountId, workOrderId, invId, sendType)
      }
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('inviteExistingSub error:', e.message)
  }
}

export const sendReminder = async (invId, workOrderId) => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      workOrderId,
      invId
    }
    const url = `${config.dynamicUrl}/proto/sendReminder`
    const response = await window.fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('sendReminder: ', answer)
    if (answer) {
      await db
        .collection('workOrders')
        .doc(workOrderId)
        .update({
          [`invitations.${invId}.reminders`]: fieldValue.arrayUnion({
            timestamp: _.now(),
            createdBy: _.get(currentUser, 'uid')
          })
        })
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('sendReminder error:', e.message)
  }
}

export const inviteExternalSub = async (sub, workOrderId) => {
  console.error('inviteExternalSub: the method is deprecated')
  // try {
  //   const state = store.getState()
  //   const accountId = _.get(state, 'user.currentAccountId')
  //   const currentUser = auth.currentUser
  //   const authToken = await currentUser.getIdToken()
  //   const body = {
  //     authToken,
  //     accountId,
  //     workOrderId,
  //     sub
  //   }
  //   const url = `${config.dynamicUrl}/proto/inviteExternalSub`
  //   const response = await fetch(url, {
  //     method: 'post',
  //     headers: headers,
  //     body: JSON.stringify(body)
  //   })
  //   const answer = await response.json()
  //   console.log('aswer', answer)
  //   return answer
  // } catch (e) {
  //   Sentry.captureException(e)
  //   console.log('inviteExistingSub error:', e.message)
  // }
}

export const updateWorkOrderTeamLeadId = async (workOrderId, userId) => {
  await db
    .collection('workOrders')
    .doc(workOrderId)
    .update({ teamLeadId: userId })
}

export const updateWorkOrderAsync = async (workOrderId, params) => {
  console.log('updateWorkOrder async params', params)
  const upd = _.reduce(
    params,
    (res, v, k) => {
      if (_.isNil(v)) {
        res[k] = fieldValue.delete()
        return res
      } else {
        res[k] = v
        return res
      }
    },
    {}
  )
  console.log('updateWorkOrder upd', upd)
  await db
    .collection('workOrders')
    .doc(workOrderId)
    .update(upd)
}

export const updateWorkOrder = (workOrderId, params) => {
  return async (dispatch, getState) => {
    console.log('updateWorkOrder params', params)
    await updateWorkOrderAsync(workOrderId, params)
  }
}

export const getSubsProfiles = async workOrderId => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    workOrderId
  }
  console.log('body', body)
  const url = `${config.dynamicUrl}/proto/getSubsProfiles`
  console.log('post to url ', url, 'body', body)
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    // console.log('getSubsProfiles response json', res)
    return res
  } else {
    console.log('getSubsProfiles response status', response.status)
    return { error: `Server response status: ${response.status}` }
  }
}

export const getSubProfile = async subId => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    accountId: subId
  }
  console.log('body', body)
  const url = `${config.dynamicUrl}/proto/getSubProfile`
  console.log('post to url ', url, 'body', body)
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    // console.log('getSubsProfile response json', res)
    return res
  } else {
    console.log('getSubProfile response status', response.status)
    return { error: `Server response status: ${response.status}` }
  }
}

export const saveScopeOfWork = (workOrderId, scope, sections) => {
  return async (dispatch, getState) => {
    console.log('saveScopeOfWork', workOrderId, scope, sections)
    const upd = {
      scope
    }
    if (!_.isNil(sections)) upd.sections = sections
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(upd)
  }
}

export const updateLineItem = async (workOrderId, itemId, item, altId) => {
  console.log('updateLineItem', workOrderId, itemId, item, altId)
  const key = altId ? `scope.${itemId}.alternates.${altId}` : `scope.${itemId}`
  const upd = {
    [key]: { ...item }
  }
  await db
    .collection('workOrders')
    .doc(workOrderId)
    .update(upd)
}

export const downloadFile = async (path, name) => {
  try {
    const request = await window.fetch(path)
    const response = await request.blob()
    const status = _.get(request, 'status')
    if (status === 200) {
      saveAs(response, name)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('downloadFile error', error)
  }
}

export const updateSeenStatus = async (id, userId, workOrderId) => {
  await db
    .collection('workOrders')
    .doc(workOrderId)
    .update({
      [`invitations.${id}.seen.${userId}`]: _.toString(_.now())
    })
}

export const createSubProposalWorkOrder = async wo => {
  try {
    const state = store.getState()
    const uid = auth.currentUser.uid
    const accountId = _.get(state, 'user.currentAccountId')
    if (accountId) {
      const dbwo = {
        ...wo,
        accounts: [accountId],
        invitations: {
          [accountId]: {
            userId: uid,
            timestamp: fieldValue.serverTimestamp()
          }
        },
        acceptedBy: {
          [accountId]: {
            userId: uid,
            timestamp: fieldValue.serverTimestamp()
          }
        },
        createdAt: firestore.FieldValue.serverTimestamp(),
        isSubProposal: true
      }
      const clearDbwo = _.omitBy(dbwo, _.isNil)
      console.log('save work order', clearDbwo)
      await db
        .collection('workOrders')
        .doc(wo.id)
        .set(clearDbwo)
    }
  } catch (e) {
    Sentry.captureException(e)
    console.warn('saveWorkOrder error:', e.message)
  }
}

export const sendPlanFiles = (subAccountId, workOrder, status) => {
  return async (dispatch, getState) => {
    try {
      const silentBid = isSilentBid(workOrder, subAccountId)
      if (!silentBid) {
        const state = getState()
        const contractorTypes = _.get(state, 'references.contractorTypes')
        const gcUserProfile = _.get(state, ['profiles', auth.currentUser.uid])
        const subAccountProfile = _.get(state, ['accountsProfiles', subAccountId])
        const gcAccountId = _.get(state, 'user.currentAccountId')
        const gcAccountProfile = _.get(state, ['accountsProfiles', gcAccountId])
        const subAccountProfileFormatted = formatProfile(subAccountProfile)
        const gcUserProfileFormatted = formatProfile(gcUserProfile)
        const gcAccountProfileFormatted = formatProfile(gcAccountProfile)
        const workOrderFormatted = getWorkOrderSendingData(workOrder, contractorTypes)
        const projectId = _.get(workOrder, 'projectId')
        const workOrderId = _.get(workOrder, 'id')
        const subUrl = `${config.subHost}/sub/project/${projectId}/workorder/${workOrderId}/bid`
        const sendDataSub = {
          event: courierEventsIds.send_files,
          list: `${subAccountId}_account`,
          data: {
            status,
            url: subUrl,
            workOrder: workOrderFormatted,
            accountsProfiles: {
              gc: gcAccountProfileFormatted,
              sub: subAccountProfileFormatted
            },
            usersProfiles: {
              gc: gcUserProfileFormatted
            }
          }
        }

        const functionUrl = `${config.dynamicUrl}/proto/sendMessageFromClient`
        const currentUser = auth.currentUser
        const authToken = await currentUser.getIdToken()
        const body = {
          authToken,
          sendDataSub
        }
        const response = await window.fetch(functionUrl, {
          method: 'post',
          headers: headers,
          body: JSON.stringify(body)
        })
        if (response.status !== 200) {
          console.log('sendResolveLineItemMessage response status', response.status)
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const sendResolveLineItemMessage = (subAccountId, workOrder, item, status) => {
  return async (dispatch, getState) => {
    try {
      const silentBid = isSilentBid(workOrder, subAccountId)
      if (!silentBid) {
        const gcUserId = auth.currentUser.uid
        const workOrderId = _.get(workOrder, 'id')

        const functionUrl = `${config.dynamicUrl}/proto/sendMessages`
        const currentUser = auth.currentUser
        const authToken = await currentUser.getIdToken()
        const body = {
          authToken,
          gcUserId,
          subAccountId,
          workOrderId,
          item,
          status,
          types: {
            sub: courierEventsIds.line_item_resolved_sub,
            gc: courierEventsIds.line_item_resolved_sub
          }
        }
        const response = await window.fetch(functionUrl, {
          method: 'post',
          headers: headers,
          body: JSON.stringify(body)
        })
        if (response.status !== 200) {
          console.log('sendResolveLineItemMessage response status', response.status)
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const sendResolveRemovalMessage = (subAccountId, workOrderId, items, status, multiple) => {
  return async (dispatch, getState) => {
    try {
      const gcUserId = auth.currentUser.uid
      const functionUrl = `${config.dynamicUrl}/proto/sendMessages`
      const currentUser = auth.currentUser
      const authToken = await currentUser.getIdToken()
      const body = {
        authToken,
        gcUserId,
        subAccountId,
        workOrderId,
        items,
        multiple,
        status,
        types: {
          sub: courierEventsIds.line_item_removal_resolved_sub,
          gc: courierEventsIds.line_item_removal_resolved_gc
        }
      }
      const response = await window.fetch(functionUrl, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      if (response.status !== 200) {
        console.log('sendResolveLineItemMessage response status', response.status)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const sendRequestPriceMessage = (workOrder, item, subAccountId) => {
  return async (dispatch, getState) => {
    try {
      const workOrderId = _.get(workOrder, 'id')
      const gcUserId = auth.currentUser.uid
      const functionUrl = `${config.dynamicUrl}/proto/sendMessages`
      const currentUser = auth.currentUser
      const authToken = await currentUser.getIdToken()
      const body = {
        authToken,
        gcUserId,
        subAccountId,
        workOrderId,
        item,
        status,
        types: {
          sub: courierEventsIds.price_requested_sub
        }
      }
      const response = await window.fetch(functionUrl, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      if (response.status !== 200) {
        console.log('sendResolveLineItemMessage response status', response.status)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const sendOptionalLiDeclinedMessage = (subAccountId, workOrder, item) => {
  return async (dispatch, getState) => {
    try {
      const workOrderId = _.get(workOrder, 'id')
      const gcUserId = auth.currentUser.uid
      const functionUrl = `${config.dynamicUrl}/proto/sendMessages`
      const currentUser = auth.currentUser
      const authToken = await currentUser.getIdToken()
      const body = {
        authToken,
        gcUserId,
        subAccountId,
        workOrderId,
        item,
        status,
        types: {
          sub: courierEventsIds.optional_line_item_declined_sub
        }
      }
      const response = await window.fetch(functionUrl, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      if (response.status !== 200) {
        console.log('sendResolveLineItemMessage response status', response.status)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const getMessageStatus = async data => {
  const { workOrderId, invId, cId, messageId } = data
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    workOrderId,
    invId,
    cId,
    messageId
  }
  const url = `${config.dynamicUrl}/proto/getMessageStatus`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const cancelRemindersBidInviteMessages = async invId => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    cancelationToken: `invite_sub/${invId}`
  }
  const url = `${config.dynamicUrl}/proto/cancelReminder`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const changeArchiveWorkOrderStatus = async (workOrderId, status) => {
  const state = store.getState()
  const deleted = status ? _.now() : 0
  const batch = db.batch()
  console.log('changeArchiveWorkOrderStatus', workOrderId, deleted)
  const wo = _.get(state, ['workOrders', workOrderId], {})
  if (!_.isEmpty(wo)) {
    // optimistic redux update, this is needed when we archive a work order,
    // but it will never updated in redux by default, because onSnapshot listens deleted == 0
    store.dispatch(
      receiveWorkOrders({
        [workOrderId]: {
          ...wo,
          deleted
        }
      })
    )
  }
  batch.update(db.collection('workOrders').doc(workOrderId), { deleted })
  for (const bidId in state.bids) {
    const bid = state.bids[bidId]
    if (bid.workOrderId === workOrderId) {
      batch.update(db.collection('bids').doc(bid.id), { deleted })
    }
  }
  await batch.commit()
}

export async function acceptOrDeclineInvite (accId, workOrderId, isAccepted) {
  try {
    const userId = auth.currentUser.uid
    const update = {
      [`${isAccepted ? 'acceptedBy' : 'declinedBy'}.${accId}`]: {
        userId,
        timestamp: _.now()
      },
      [`${isAccepted ? 'declinedBy' : 'acceptedBy'}.${accId}`]: fieldValue.delete()
    }
    await db
      .collection('workOrders')
      .doc(workOrderId)
      .update(update)
  } catch (e) {
    Sentry.captureException(e)
    console.log('acceptOrDeclineInvite error:', e.message)
  }
}

export const scheduleReminder = async params => {
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = { authToken, ...params }
  const url = `${config.dynamicUrl}/proto/scheduleReminder`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const cancelReminder = async (workOrderId, invId, reminderId) => {
  console.log(workOrderId, invId, reminderId)
  const currentUser = auth.currentUser
  const authToken = await currentUser.getIdToken()
  const body = {
    authToken,
    cancelationToken: `invite_sub/${reminderId}`
  }
  const url = `${config.dynamicUrl}/proto/cancelReminder`
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(body)
  })
  await db
    .collection('workOrders')
    .doc(workOrderId)
    .update({
      [`invitations.${invId}.scheduledReminders.${reminderId}`]: fieldValue.delete()
    })
  if (response.status === 200) {
    const res = await response.json()
    return res
  } else {
    return { error: `Server response status: ${response.status}` }
  }
}

export const removeLineItemsFromWorkOrders = async lineItems => {
  try {
    const batch = db.batch()
    const state = store.getState()
    _.forEach(lineItems, (workOrders, liId) => {
      _.forEach(workOrders, woId => {
        const wo = _.get(state, ['workOrders', woId])
        const scope = _.get(wo, 'scope')
        delete scope[liId]
        let sections = _.get(wo, 'sections')
        const sectionIndex = _.findIndex(sections, s => _.includes(_.get(s, 'items'), liId))
        const section = sections[sectionIndex]
        const itemIndex = _.findIndex(_.get(section, 'items'), i => i === liId)
        if (itemIndex > 0) {
          _.pullAt(section.items, itemIndex)
          sections[sectionIndex] = section
        }
        batch.update(db.collection('workOrders').doc(woId), { sections, scope })
      })
    })
    await batch.commit()
  } catch (e) {
    console.log('removeLineItemsFromWorkOrders error', e)
  }
}
