import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import LayoutContext from 'webPages/layout/LayoutContext'
import ProjectModal from 'webPages/ProjectModal'
import BidInviteModal from 'webPages/BidInviteModal'
import ConfirmationModal from 'components/panels/ConfirmationModal'
import BidPreviewModal from 'webPages/createBid/BidPreviewModal'
import LevelingPanel from 'webPages/leveling/LevelingPanel'
import InviteSubPanel from 'webPages/leveling/InviteSubPanel'
import CommentsInbox from 'components/chat/CommentsInbox'
import Toast from 'components/Toast'
import ScheduledReminderModal from 'webPages/leveling/ScheduledReminderModal'
import LeaveAccountModal from 'webPages/settings/LeaveAccountModal'
import ImportCsvModal from 'webPages/dashboard/ImportCsvModal'
import HubspotLiveChat from 'components/leveling/levelingPanel/HubspotLiveChat'

class LayoutDataProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openProjectModal: this.openProjectModal,
      openBidInviteModal: this.openBidInviteModal,
      openConfirmationModal: this.openConfirmationModal,
      closeConfirmationModal: this.closeConfirmationModal,
      openBidPreviewModal: this.openBidPreviewModal,
      openSubInfo: this.openSubInfo,
      openCellInfo: this.openCellInfo,
      openCommentsInbox: this.openCommentsInbox,
      closeCommentsInbox: this.closeCommentsInbox,
      activeMenuItem: null,
      setActiveMenuItem: this.setActiveMenuItem,
      sideMenuOpen: false,
      toggleSideMenu: this.toggleSideMenu,
      openInviteSubPanel: this.openInviteSubPanel,
      openToast: this.openToast,
      openSchedulerReminder: this.openSchedulerReminder,
      openLeaveAccountModal: this.openLeaveAccountModal,
      openImportCsvModal: this.openImportCsvModal
    }
    this.commentsInboxRef = React.createRef()
    this.projectModalRef = React.createRef()
    this.bidInviteModalRef = React.createRef()
    this.confirmationModalRef = React.createRef()
    this.bidPreviewModalRef = React.createRef()
    this.sidePanelRef = React.createRef()
    this.inviteSubPanelRef = React.createRef()
    this.toastRef = React.createRef()
    this.scheduledReminderRef = React.createRef()
    this.leaveAccountModalRef = React.createRef()
    this.importCsvModalRef = React.createRef()
  }

  toggleSideMenu = v => {
    // console.log('%ctoggleSideMenu', 'font-weight: 600; color: red;', v)
    const { sideMenuOpen } = this.state
    if (v !== sideMenuOpen) {
      // console.log('%ctoggleSideMenu - CALL', 'font-weight: 600; color: red;', v)
      this.setState({ sideMenuOpen: v })
    }
  }

  openProjectModal = (projectId, tabIndex = 0) => {
    this.projectModalRef.current.open(projectId, tabIndex)
  }

  openImportCsvModal = (projectId) => {
    this.importCsvModalRef.current.open(projectId)
  }

  openSchedulerReminder = (workOrderId, invId, reminderId) => {
    const { user, workOrders } = this.props
    const reminder = _.get(workOrders, [workOrderId, 'invitations', invId, 'scheduledReminders', reminderId], null)
    this.scheduledReminderRef.current.open(workOrderId, invId, user.id, reminder)
  }

  openLeaveAccountModal = () => {
    console.log('open account leave confirmation modal', this.leaveAccountModalRef)
    if (_.has(this.leaveAccountModalRef, 'current.open')) this.leaveAccountModalRef.current.open()
  }

  openBidInviteModal = projectId => {
    console.log('openBidInviteModal', projectId)
    this.bidInviteModalRef.current.open({ projectId })
  }

  openInviteSubPanel = (suggestion, workOrderId, value) => {
    this.inviteSubPanelRef.current.open(suggestion, workOrderId, value)
  }

  openConfirmationModal = params => {
    this.confirmationModalRef.current.open(params)
  }

  closeConfirmationModal = () => {
    this.confirmationModalRef.current.close()
  }

  openBidPreviewModal = params => {
    this.bidPreviewModalRef.current.open(params)
  }

  openSubInfo = params => {
    this.sidePanelRef.current.openSubInfo(params)
  }

  openCellInfo = params => {
    this.sidePanelRef.current.openCellInfo(params)
  }

  openCommentsInbox = () => {
    console.log('open comments inbox', this.commentsInboxRef)
    if (_.has(this.commentsInboxRef, 'current.open')) this.commentsInboxRef.current.open()
  }

  closeCommentsInbox = () => {
    if (_.has(this.commentsInboxRef, 'current.close')) this.commentsInboxRef.current.close()
  }

  openToast = content => {
    if (_.has(this.toastRef, 'current.open')) this.toastRef.current.open(content)
  }

  setActiveMenuItem = v => this.setState({ activeMenuItem: v })

  render () {
    return (
      <LayoutContext.Provider value={{ ...this.state }}>
        {this.props.children}
        <ProjectModal ref={this.projectModalRef} />
        <BidInviteModal ref={this.bidInviteModalRef} />
        <ConfirmationModal ref={this.confirmationModalRef} />
        <BidPreviewModal ref={this.bidPreviewModalRef} />
        <LevelingPanel ref={this.sidePanelRef} />
        <InviteSubPanel inviteSubPanelRef={this.inviteSubPanelRef} />
        <CommentsInbox ref={this.commentsInboxRef} />
        <Toast ref={this.toastRef} />
        <ScheduledReminderModal ref={this.scheduledReminderRef} />
        <LeaveAccountModal ref={this.leaveAccountModalRef} />
        <ImportCsvModal ref={this.importCsvModalRef} />
        <HubspotLiveChat />
      </LayoutContext.Provider>
    )
  }
}

LayoutDataProvider.contextType = LayoutContext

const mapStateToProps = state => ({
  user: state.user,
  workOrders: state.workOrders
})

export default connect(mapStateToProps)(LayoutDataProvider)
