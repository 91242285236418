import React, { useState, useEffect } from 'react'
import { Box, TextInput, FormField, ThemeContext, Text, Avatar, MaskedInput, Keyboard, Select } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { connect } from 'react-redux'
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as googleApis from 'shared/utils/googleApis'
import uuidv4 from 'uuid/v4'
import { FormClose } from 'grommet-icons'

import colors from 'shared/constants/colors'
import { userIdByPhone } from 'controllers/data'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { updateAccountProfile, removeAvatarFromAccount, saveAccountAvatar } from 'controllers/profiles'
import { UNFORMATTED_PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import { browseFiles } from 'shared/controllers/files'
import { updateAccount } from 'controllers/account'
import { getAddress, getDomesticAddress } from 'shared/utils/stringUtils'
import { emailMask, phoneMask } from 'utils/inputMask'
import AccountSwitch from './settingsAccount/AccountSwitch'

const SESSION_TOKEN = uuidv4()
const MIN_SEARCH_LENGTH = 2

const formatPhone = phone =>
  phone.length === UNFORMATTED_PHONE_NUMBER_LENGTH
    ? `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 12)}`
    : ''

const Account = ({ profile, dispatch, isGC, account, projectTypes }) => {
  const [name, setName] = useState('')
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [address, setAddress] = useState('')
  const [selectedProjectTypes, setProjectTypes] = useState([])
  const projectTypesFormatted = _.values(_.mapValues(projectTypes, pt => ({ value: pt.id, label: pt.name })))

  useEffect(() => {
    if (!_.isNil(profile)) {
      setName(_.get(profile, 'name', ''))
      setAvatarUrl(_.get(profile, 'avatarSmall', _.get(profile, 'avatar', null)))
      setPhone(formatPhone(_.get(profile, 'phone', '')))
      setEmail(_.get(profile, 'email', ''))
      setWebsite(_.get(profile, 'website', ''))
      setAddress(_.get(profile, 'address', {}))
    }
  }, [profile])

  useEffect(() => {
    if (!_.isNil(account)) {
      setProjectTypes(_.map(_.get(account, 'projectTypes', []), pt => _.indexOf(_.keys(projectTypes), pt)))
    }
  }, [account])

  const renderAddress = () => {
    const [searchInput, setSearchInput] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [currentSelect, setCurrentSelect] = useState('')
    let searchingRequests = []

    useEffect(() => {
      setSearchInput(getAddress(address))
    }, [address])

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (searchInput.length >= MIN_SEARCH_LENGTH && searchInput !== currentSelect) {
          abortSearchingRequests()
          try {
            const request = googleApis.autocomplete(searchInput, SESSION_TOKEN, responseJSON => {
              const results = _.get(responseJSON, 'predictions', [])
              if (results && results.length > 0) {
                updateSearchResults(results)
              } else {
                updateSearchResults([])
              }
            })
            searchingRequests.push(request)
          } catch (error) {
            console.log(error)
          }
        } else {
          updateSearchResults([])
        }
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }, [searchInput])

    const abortSearchingRequests = () => {
      if (searchingRequests) {
        searchingRequests.forEach(request => {
          request.abort()
        })
        searchingRequests = []
      }
    }

    const getTextForSearchResult = searchResult => ({
      label: getDomesticAddress(searchResult),
      value: searchResult
    })

    const updateSearchResults = searchResults => {
      const res = _.map(searchResults, getTextForSearchResult)
      setSuggestions(res)
    }

    const setValue = ({ suggestion }) => {
      setCurrentSelect(suggestion.label)
      setSearchInput(suggestion.label)
      onPressSearchResult(suggestion.value)
    }

    const onPressSearchResult = async item => {
      const placeId = item.place_id
      const structured = {
        main: _.get(item, 'structured_formatting.main_text', null),
        secondary: _.get(item, 'structured_formatting.secondary_text', null)
      }
      const res = await googleApis.getPlaceDetailsFormatted(placeId, structured)
      dispatch(updateAccountProfile({ address: { ...res, placeId } }))
    }

    return (
      <FormField label='Company address'>
        <TextInput
          onChange={e => setSearchInput(e.target.value)}
          value={searchInput}
          suggestions={suggestions}
          onSelect={setValue}
        />
      </FormField>
    )
  }

  const renderAvatar = () => {
    const removeAvatar = () => {
      dispatch(removeAvatarFromAccount())
    }

    const handleUpload = async () => {
      const f = await browseFiles('images', 50)
      if (!_.isNil(f)) {
        const avatar = _.get(f, 'url')
        const avatarSmall = _.get(f, 'thumbUrl')
        dispatch(saveAccountAvatar({ avatar, avatarSmall }))
      }
    }

    if (_.isNil(avatarUrl)) {
      return (
        <Box flex={false} direction='row' align='center' width='small' onClick={handleUpload}>
          <Box
            round='full'
            width='30px'
            height='30px'
            align='center'
            justify='center'
            margin={{ right: 'small' }}
            border={{ color: colors.VERY_LIGHT_PINK, size: 'xsmall', style: 'dashed', side: 'all' }}
          >
            <FontAwesomeIcon icon={faUserCircle} color={colors.VERY_LIGHT_PINK} size={12} />
          </Box>
          <Text color={colors.VERY_LIGHT_PINK}>Upload logo</Text>
        </Box>
      )
    } else {
      return (
        <Box flex={false} direction='row' align='center' onClick={removeAvatar} width='small'>
          <Box round='full' width='30px' height='30px' align='center' justify='center' margin={{ right: 'small' }}>
            <Avatar src={avatarUrl} />
          </Box>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>Remove logo</Text>
        </Box>
      )
    }
  }

  const removeFocus = () => {
    document.activeElement.blur()
  }

  const submitField = async type => {
    switch (type) {
      case 'name': {
        dispatch(updateAccountProfile({ name }))
        break
      }
      case 'email': {
        dispatch(updateAccountProfile({ email }))
        break
      }
      case 'phone': {
        const phoneNumber = parsePhoneNumberFromString(phone, 'US')
        const existingUid = await userIdByPhone(phoneNumber.number)
        if (_.isNil(existingUid)) {
          dispatch(updateAccountProfile({ phone: phoneNumber.number }))
        } else {
          setPhone(formatPhone(_.get(profile, 'phone', '')))
        }
        break
      }
      case 'website': {
        dispatch(updateAccountProfile({ website }))
        break
      }
      default:
        break
    }
  }

  const renderPtSelect = () => (
    <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={false}>
      <Box fill>
        <FormField label='Trades offered' flex={false}>
          <Select
            multiple
            value={
              <Box wrap direction='row' fill='horizontal'>
                {selectedProjectTypes && selectedProjectTypes.length ? (
                  _.map(selectedProjectTypes, index => renderProjectType(_.keys(projectTypes)[index], index))
                ) : (
                  <Box pad={{ vertical: 'xsmall', horizontal: 'small' }} margin='xsmall'>
                    Select trades
                  </Box>
                )}
              </Box>
            }
            options={projectTypesFormatted}
            selected={selectedProjectTypes}
            onChange={({ selected: nextSelected }) => {
              setProjectTypes([...nextSelected].sort())
              const res = _.map(nextSelected, index => projectTypesFormatted[index].value)
              dispatch(updateAccount({ projectTypes: res }))
            }}
          >
            {renderOption}
          </Select>
        </FormField>
      </Box>
    </Box>
  )

  const renderProjectType = (pt, index) => (
    <Box
      key={`pt_${pt}`}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        const nextSelected = _.filter(selectedProjectTypes, ptSelected => index !== ptSelected)
        setProjectTypes(nextSelected)
        const res = _.map(nextSelected, index => projectTypesFormatted[index].value)
        dispatch(updateAccount({ projectTypes: res }))
      }}
    >
      <Box
        align='center'
        direction='row'
        gap='xsmall'
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        margin='xsmall'
        background={colors.LIGHT_NAVY_BRIGHT}
        round='large'
      >
        <Text size='small' color='white'>
          {_.get(projectTypes, [pt, 'name'], '')}
        </Text>
        <Box background='white' round='full' margin={{ left: 'xsmall' }}>
          <FormClose size='small' style={{ width: '12px', height: '12px' }} />
        </Box>
      </Box>
    </Box>
  )

  const renderOption = option => {
    const isActive = _.includes(_.get(account, 'projectTypes', []), option.value)
    return (
      <Box pad='small' background={isActive ? 'active' : undefined}>
        {option.label}
      </Box>
    )
  }

  return (
    <ThemeContext.Extend value={{ formField: { round: '5px' } }}>
      <Keyboard onEnter={removeFocus}>
        <Box
          fill
          flex={{ shrink: 0 }}
          gap='medium'
          overflow='scroll'
          pad={{ horizontal: 'xlarge', top: 'xxsmall', bottom: 'medium' }}
        >
          <Box gap='xsmall' flex={false}>
            <Text size='medium'>Company logo</Text>
            {renderAvatar()}
          </Box>
          {!isGC && renderPtSelect()}
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill>
              <FormField label='Company name'>
                <TextInput value={name} onChange={e => setName(e.target.value)} onBlur={() => submitField('name')} />
              </FormField>
            </Box>
            <Box fill>{renderAddress()}</Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Company email' flex={false}>
                  <MaskedInput
                    mask={emailMask}
                    value={email || ''}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => submitField('email')}
                  />
                </FormField>
              </Box>
            </Box>
            <Box fill gap='xsmall'>
              <Box>
                <FormField label='Company phone' flex={false}>
                  <MaskedInput
                    mask={phoneMask}
                    value={phone || ''}
                    onChange={e => setPhone(e.target.value)}
                    onBlur={() => submitField('phone')}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
          <Box direction='row' justify='between' gap='xlarge' fill='horizontal' flex={{ shrink: 0 }}>
            <Box fill gap='xsmall' basis='1/2' pad={{ right: 'large' }}>
              <Box>
                <FormField label='Company website' flex={false}>
                  <TextInput
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                    onBlur={() => submitField('website')}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
          <Box direction='row' flex={{ shrink: 0 }}>
            {/* <Box gap='xsmall' pad={{ right: 'large' }} flex={{ shrink: 0 }}> */}
            <AccountSwitch />
            {/* </Box> */}
          </Box>
        </Box>
      </Keyboard>
    </ThemeContext.Extend>
  )
}

const mapStateToProps = (state, props) => ({
  profile: getCurrentAccountProfile(state),
  account: state.account,
  projectTypes: _.get(state, 'references.projectTypes', {}),
  isGC: _.get(state, 'account.isGC', false)
})

export default connect(mapStateToProps)(Account)
