const DECLINE = 'DECLINE'
const APPROVE_FOR_ALL = 'APPROVE_FOR_ALL'
const APPROVE_FOR_SUB = 'APPROVE_FOR_SUB'
const CANCEL_REMOVAL_REQUEST = 'CANCEL_REMOVAL_REQUEST'

export const actionTitle = (action, companyName) => {
  switch (action) {
    case DECLINE:
      return 'Decline line item'
    case APPROVE_FOR_ALL:
      return 'Approve for all subs '
    case APPROVE_FOR_SUB:
      return `Approve only for ${companyName}`
    case CANCEL_REMOVAL_REQUEST:
      return 'Cancel removal request'
  }
}

export default {
  DECLINE,
  APPROVE_FOR_ALL,
  APPROVE_FOR_SUB,
  CANCEL_REMOVAL_REQUEST,
  actions: [APPROVE_FOR_ALL, APPROVE_FOR_SUB, DECLINE]
}
