import { createSelector } from 'reselect'
import _ from 'lodash'

import requestType from 'shared/constants/requestType'
import { getWorkOrdersByProject, getOwnProjectsInfo } from 'model/selectors/projectsInfoSelector'
import { getPendingBids } from 'model/selectors/bids'
import {
  getProjectId,
  getWorkOrderId,
  getUserId,
  getWorkOrders as getAllWorkOrders,
  getProjects
} from 'model/selectors/base'
import workOrderStatus from 'shared/constants/workOrderStatus'
import { toTimestamp } from 'shared/utils/date'
import { getTradesDict } from 'model/selectors/references'

const getAccountId = state => _.get(state, 'account.id')
const getBids = state => state.bids

export const getWorkOrder = createSelector(
  [getAllWorkOrders, getWorkOrderId, getTradesDict],
  (workOrders, workOrderId, contractorTypes) => {
    const wo = _.get(workOrders, workOrderId)
    if (_.isNil(wo)) return null
    const label = wo.label || _.get(contractorTypes, [wo.tradeId, 'name'])
    return { ...wo, label }
  }
)

export const getWorkOrders = createSelector(
  [getWorkOrdersByProject, getProjectId],
  (workOrdersByProject, projectId) => {
    return _.get(workOrdersByProject, projectId, {})
  }
)

const createWorkOrdersTitles = (workOrders, contractorTypes) => {
  const woSortedAr = _.sortBy(_.values(workOrders), wo => toTimestamp(_.get(wo, 'createdAt')))
  const tradesAmountWithnoLabel = {}
  return _.map(woSortedAr, wo => {
    const label = _.get(wo, 'label')
    const tradeId = _.get(wo, 'tradeId')
    let tradeName = _.get(contractorTypes, [tradeId, 'name'])
    if (_.isNil(label) || label === '') {
      const existingAmount = _.get(tradesAmountWithnoLabel, tradeId, 0)
      if (existingAmount > 0) tradeName = `${tradeName} (${existingAmount})`
      _.set(tradesAmountWithnoLabel, tradeId, existingAmount + 1)
    }
    return { ...wo, title: _.get(wo, 'label', tradeName) }
  })
}

export const getWorkOrdersWithTitles = createSelector(
  [getWorkOrdersByProject, getProjectId, getTradesDict],
  (workOrdersByProject, projectId, contractorTypes) => {
    const workOrders = _.get(workOrdersByProject, projectId, {})
    return createWorkOrdersTitles(workOrders, contractorTypes)
  }
)

export const getWorkOrdersWithTitlesDict = createSelector(
  [getWorkOrdersByProject, getProjects, getTradesDict],
  (workOrdersByProject, projects, contractorTypes) => {
    const res = {}
    _.forEach(projects, project => {
      const workOrders = _.get(workOrdersByProject, project.id, {})
      // console.log('projectId', project.id, 'work orders', workOrders)
      const woWithTitles = createWorkOrdersTitles(workOrders, contractorTypes)
      // console.log('woWithTitles', woWithTitles)
      _.forEach(woWithTitles, wo => _.set(res, wo.id, wo))
    })
    return res
  }
)

export const getWorkOrderWithTitle = createSelector(
  [getWorkOrdersWithTitlesDict, getWorkOrderId],
  (workOrdersDict, workOrderId) => {
    return _.get(workOrdersDict, workOrderId)
  }
)

export const getWorkOrderIdsByLineItemId = createSelector(
  [getWorkOrdersByProject, getProjectId],
  (workOrdersByProject, projectId) => {
    const workOrders = _.get(workOrdersByProject, projectId, {})
    const res = {}
    _.forEach(workOrders, wo => {
      _.forEach(wo.scope, (li, liId) => {
        if (!_.has(res, liId)) _.set(res, liId, [])
        res[liId].push(wo.id)
      })
    })
    return res
  }
)

export const getOwnWorkOrdersUnassigned = createSelector(
  [getAllWorkOrders, getOwnProjectsInfo, getPendingBids, getUserId, getTradesDict],
  (workOrders, projectsInfo, pendingBids, userId, contractorTypes) => {
    const res = []
    _.forEach(workOrders, wo => {
      if (_.has(projectsInfo, wo.projectId) && !_.has(wo, 'approvedBidId')) {
        // console.log('process wo', wo)
        const timestamp = toTimestamp(wo.createdAt)
        const seen = _.has(wo, ['seenBy', userId])
        const invitedSubsAmount = _.size(wo.invitations)
        const bids = _.get(pendingBids, ['byWorkOrder', wo.id])
        const projectId = _.get(wo, 'projectId')
        // console.log('bids:', bids)
        const bidsAmount = _.size(bids)
        const unseenBids = _.filter(bids, bid => !bid.seen)
        // console.log('unseen bids', unseenBids)
        // console.log('wo seen', wo.id, seen)
        let status = workOrderStatus.INVITE_SUBS
        if (invitedSubsAmount > 0) status = workOrderStatus.BIDDING
        if (bidsAmount > 0) status = workOrderStatus.BIDS_RECEIVED
        const label = _.get(contractorTypes, [wo.tradeId, 'name'], wo.label || 'no trade')
        const r = {
          id: wo.id,
          key: `wo_${wo.id}`,
          type: requestType.WORK_ORDER,
          address: _.get(wo, 'projectAddress.structured.main'),
          timestamp,
          invitedSubsAmount,
          invitedAccounts: _.keys(wo.invitations),
          bidsAmount,
          status,
          label,
          seen,
          isHighlighted: _.size(unseenBids) > 0 || !seen,
          needsAction: status === workOrderStatus.INVITE_SUBS,
          isPending: !seen || status === workOrderStatus.INVITE_SUBS,
          projectId
        }
        // console.log('r', r)
        res.push(r)
      }
    })
    // console.log('getOwnWorkOrdersUnassigned res', res)
    return res
  }
)

export const getWorkOrdersForProjectWithMembers = createSelector([getWorkOrders], workOrders => {
  const res = {}
  for (const woId in workOrders) {
    const wo = workOrders[woId]
    if (_.has(wo, 'members')) {
      _.set(res, woId, wo)
    }
  }
  return res
})

export const getWorkOrdersForProjectWithMembersUnassigned = createSelector([getWorkOrders], workOrders => {
  const res = {}
  for (const woId in workOrders) {
    const wo = workOrders[woId]
    const accounts = _.get(wo, 'accounts', [])
    const hasApprovedBid = _.has(wo, 'approvedBidId')
    if (accounts.length > 1 && !hasApprovedBid) {
      _.set(res, woId, wo)
    }
  }
  return res
})

export const assignedPendingWorkOrdersByProject = createSelector(
  [getAllWorkOrders, getAccountId, getBids],
  (workOrders, accountId, bids) => {
    const res = {}
    _.forEach(workOrders, (wo, woId) => {
      const approvedBidAccountId = _.get(bids, [_.get(wo, 'approvedBidId'), 'accountId'])
      if (approvedBidAccountId === accountId) {
        _.set(res, [wo.projectId, 'assigned', woId], wo)
      } else {
        _.set(res, [wo.projectId, 'pending', woId], wo)
      }
    })
    // console.log('assignedPendingWorkOrdersByProject', res)
    return res
  }
)

export const getOwnWorkOrdersUnassignedByProject = createSelector(
  [getOwnWorkOrdersUnassigned, getProjectId],
  (workOrders, projectId) => {
    return _.filter(workOrders, wo => wo.projectId === projectId)
  }
)

export const getWorkOrdersByProjectPartner = createSelector(
  [getWorkOrdersByProject, getAccountId, getProjectId],
  (workOrdersByProject, accountId, projectId) => {
    const wos = _.get(workOrdersByProject, projectId, {})
    const res = {}
    _.forEach(wos, wo => {
      if (wo.accountId === accountId) {
        // my bid invite
        const invitations = _.get(wo, 'invitations')
        _.forEach(invitations, (inv, subId) => {
          _.set(res, [subId, wo.id], wo)
        })
      } else {
        // incoming
        _.set(res, [wo.accountId, wo.id], wo)
      }
    })
    return res
  }
)
