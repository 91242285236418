import React from 'react'
import { Box, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const ProjectDetailsButton = ({ projectId, workOrderId, mode }) => {
  if (mode === 'info') {
    return null
  }
  return (
    <Button
      plain
      onClick={() => universalNavigation.push(screens.SUB_CREATE_BID, { projectId, workOrderId }, { mode: 'info' })}
      label={(
        <Box direction='row' justify='between' align='center' pad='medium' background={colors.PALE_GREY}>
          <Text weight={600} size='large' color={colors.LIGHT_NAVY_BRIGHT}>Project details</Text>
          <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faAngleRight} size={20} />
        </Box>
      )}
    />
  )
}

export default ProjectDetailsButton
