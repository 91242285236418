import React, { Component } from 'react'
import { Box, Button, Layer, Text, Select, CheckBox, CheckBoxGroup, TextInput, Keyboard, ThemeContext, Grommet } from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'
import { connect } from 'react-redux'
import { addNewLineItem, deleteTemplate, deleteLineItem, createTemplate, addImportLineItems } from 'controllers/account'
import { generateId } from 'constants/firebase'
import lineItemType from 'shared/constants/lineItemType'
import SectionsSelector from 'shared/pages/createBid/addLineItemFromTemplate/SectionsSelector'
import lineItemSection from 'shared/constants/lineItemSection'
import { Importer, ImporterField } from 'react-csv-importer'

class AddLineItemFromTemplate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      template: { label: 'All', id: 'all' },
      templates: [
        { label: 'All', id: 'all' },
        { label: 'Create template', id: 'create' },
        { label: 'Import from .csv', id: 'import' }
      ],
      selectAll: false,
      showNewLineItem: false,
      newLineItem: '',
      lineItems: [],
      showCreateTemplate: false,
      showImportTemplate: false,
      newTemplate: '',
      deleteTemplateShow: false,
      selectedSection: {
        id: lineItemSection.SCOPE_OF_WORK_ID,
        title: 'Scope of Work'
      },
      importStep: 0,
      csvTemplate: { label: 'All', id: 'all' },
      csvTemplates: [
        { label: 'All', id: 'all' }
      ],
      selectAllCSV: false,
      csvLineItems: [],
      selectedCSVLineItems: []
    }
  }

  setSelectedSection = v => this.setState({ selectedSection: v })

  open = templatesBuffer => {
    this.handleShowChange()
  }

  updateData = () => {
    const { templatesProps, lineItemsProps } = this.props
    const templates = [{ label: 'All', id: 'all' }]
    const csvTemplates = [{ label: 'All', id: 'all' }]
    const lineItems = _.values(lineItemsProps)
    _.forEach(templatesProps, v => {
      const values = _.map(_.get(v, 'values', []), val => {
        return { id: val, name: _.get(lineItemsProps, [val, 'name']) }
      })
      templates.push({ label: v.name, id: v.id, values })
      csvTemplates.push({ label: v.name, id: v.id, values })
    })
    templates.push({ label: 'Create template', id: 'create' })
    templates.push({ label: 'Import from .csv', id: 'import' })
    this.setState({ templates, lineItems, csvTemplates })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const prevAccount = _.get(prevProps, 'account')
    const account = _.get(this.props, 'account')
    const prevShow = _.get(prevState, 'show')
    const show = _.get(this.state, 'show')
    if (!_.isEqual(prevAccount, account) || !_.isEqual(prevShow, show)) {
      this.updateData()
    }
  }

  handleShowChange = () => {
    this.setState({
      show: !this.state.show,
      selectedLineItems: [],
      selectAll: false,
      template: { label: 'All', id: 'all' },
      selectedSection: {
        id: lineItemSection.SCOPE_OF_WORK_ID,
        title: 'Scope of Work'
      }
    })
  }

  handleTemplateChange = ({ option }) => {
    const { id } = option
    if (id === 'create') {
      this.handleShowCreateTemplate()
    } else if (id === 'import') {
      this.handleImportTemplate()
    } else {
      this.setState({ template: option, selectedLineItems: [], selectAll: false })
    }
  }

  handleCSVTemplateChange = ({ option }) => {
    this.setState({ csvTemplate: option })
  }

  handleSelectAll = ({ target }) => {
    const value = target.checked
    let currentLineItems = []
    if (value) {
      const { lineItems, template } = this.state
      const { templatesProps } = this.props
      currentLineItems =
        template.id === 'all'
          ? _.map(lineItems, li => li.id)
          : _.get(_.find(templatesProps, el => el.id === template.id), 'values')
    }
    this.setState({ selectAll: value, selectedLineItems: currentLineItems })
  }

  handleSelectAllCSV = ({ target }) => {
    const value = target.checked
    let currentLineItems = []
    if (value) {
      currentLineItems = _.map(this.state.csvLineItems, li => li.id)
    }
    this.setState({ selectAllCSV: value, selectedCSVLineItems: currentLineItems })
  }

  handleSelectChange = ({ value }) => {
    this.setState({ selectedLineItems: value })
  }

  handleCSVSelectChange = ({ value }) => {
    this.setState({ selectedCSVLineItems: value })
  }

  handleAdding = () => {
    const { selectedLineItems, selectedSection } = this.state
    const { addNewLineItem, lineItemsProps } = this.props
    const items = {}
    _.forEach(selectedLineItems, li => {
      const id = generateId()
      const lineItem = _.get(lineItemsProps, li)
      const type = _.get(lineItem, 'type', lineItemType.DEFAULT)
      const item = { ...lineItem, type, id }
      items[id] = item
    })
    addNewLineItem(items, selectedSection)
    this.handleShowChange()
  }

  handleNewLineItemShow = () => {
    this.setState({
      showNewLineItem: !this.state.showNewLineItem,
      newLineItem: ''
    })
  }

  handleNewLineItemChange = e => {
    this.setState({
      newLineItem: e.target.value
    })
  }

  handleNewLineItemSave = () => {
    const { newLineItem, template } = this.state
    if (!newLineItem || newLineItem === '') {
      this.handleNewLineItemShow()
    } else {
      const templateId = template.id === 'all' ? null : template.id
      this.props.dispatch(addNewLineItem(newLineItem, templateId))
      this.handleNewLineItemShow()
    }
  }

  handleDeleteTemplate = () => {
    const { template } = this.state
    const templateId = _.get(template, 'id')
    this.setState(
      {
        template: { label: 'All', id: 'all' },
        deleteTemplateShow: false
      },
      () => this.props.dispatch(deleteTemplate(templateId))
    )
  }

  handleDeleteLineItem = id => {
    const { templatesProps } = this.props
    const templatesIds = _.map(
      _.filter(templatesProps, template => _.includes(template.values, id)),
      v => v.id
    )
    this.props.dispatch(deleteLineItem(id, templatesIds))
  }

  handleDeleteCSVLineItem = id => {
    const csvLineItems = _.filter(this.state.csvLineItems, item => item.id !== id)
    const selectedCSVLineItems = _.filter(this.state.selectedCSVLineItems, item => item !== id)
    this.setState({ csvLineItems, selectedCSVLineItems })
  }

  addToTemplate = () => {
    const { csvLineItems, selectedCSVLineItems, csvTemplate } = this.state
    const templateId = csvTemplate.id === 'all' ? null : csvTemplate.id
    _.forEach(selectedCSVLineItems, li => {
      const lineItem = _.first(_.filter(csvLineItems, item => item.id === li))
      if (!_.isEmpty(lineItem)) {
        const type = _.get(lineItem, 'type', lineItemType.DEFAULT)
        const item = { ...lineItem, type }
        this.props.dispatch(addImportLineItems(item, templateId))
      }
    })
    this.handleImportTemplate()
  }

  renderLineItem = li => {
    return {
      label: (
        <Box
          direction='row'
          fill='horizontal'
          align='center'
          gap='small'
          customStyle={`
            button {
              visibility: hidden
            }
            :hover {
              button {
                visibility: visible
              }
            }
          `}
        >
          <Text>{li.name}</Text>
          <Button
            plain
            icon={<FontAwesomeIcon icon={faTrashAlt} size={14} />}
            onClick={() => this.handleDeleteLineItem(li.id)}
          />
        </Box>
      ),
      value: li.id
    }
  }

  renderCSVLineItem = li => {
    return {
      label: (
        <Box
          direction='row'
          fill='horizontal'
          align='center'
          gap='small'
          customStyle={`
            button {
              visibility: hidden
            }
            :hover {
              button {
                visibility: visible
              }
            }
          `}
        >
          <Text>{li.name}</Text>
          <Button
            plain
            icon={<FontAwesomeIcon icon={faTrashAlt} size={14} />}
            onClick={() => this.handleDeleteCSVLineItem(li.id)}
          />
        </Box>
      ),
      value: li.id
    }
  }

  handleShowCreateTemplate = () => {
    this.setState({ showCreateTemplate: !this.state.showCreateTemplate })
  }

  handleImportTemplate = () => {
    this.setState({
      showImportTemplate: !this.state.showImportTemplate,
      importStep: 0,
      csvTemplate: { label: 'All', id: 'all' },
      selectAllCSV: false,
      csvLineItems: [],
      selectedCSVLineItems: []
    })
  }

  handleNewTemplateChange = e => {
    this.setState({ newTemplate: e.target.value })
  }

  handleCreateTemplate = async () => {
    const { account } = this.props
    const { newTemplate } = this.state
    const id = generateId()
    await createTemplate(account.id, id, newTemplate)
    this.setState({
      template: { label: newTemplate, id },
      showCreateTemplate: false,
      newTemplate: ''
    })
  }

  handleDeleteTemplateShow = () => {
    this.setState({
      deleteTemplateShow: !this.state.deleteTemplateShow
    })
  }

  renderSectionsBlock = () => {
    const { sections } = this.props
    const { selectedSection } = this.state
    return (
      <SectionsSelector
        sections={sections}
        setSelectedSection={this.setSelectedSection}
        selectedSection={selectedSection}
      />
    )
  }

  renderOption = ({ id, label }) => {
    let style = {}
    switch (id) {
      case 'create':
        style = {
          fontWeight: 600,
          color: colors.CLEAR_BLUE
        }
        break
      case 'import':
        style = {
          fontWeight: 600,
          color: colors.LIGHT_NAVY_BRIGHT
        }
        break
      default:
        style = {
          fontWeight: 400,
          color: colors.CONTACT_LABEL
        }
        break
    }
    return (
      <Box pad='small'>
        <Text style={style}>{label}</Text>
      </Box>
    )
  }

  render () {
    const {
      show,
      templates,
      template,
      lineItems,
      selectAll,
      selectAllCSV,
      selectedLineItems,
      showNewLineItem,
      newLineItem,
      newTemplate,
      importStep,
      csvTemplates,
      csvTemplate,
      csvLineItems,
      selectedCSVLineItems
    } = this.state
    if (!show) return null
    const currentLineItems =
      template.id === 'all'
        ? _.map(lineItems, this.renderLineItem)
        : _.map(_.get(_.find(templates, el => el.id === template.id), 'values'), this.renderLineItem)
    const currentCSVLineItems = _.map(csvLineItems, this.renderCSVLineItem)
    return (
      <Layer onEsc={this.handleShowChange} onClickOutside={this.handleShowChange}>
        <ThemeContext.Extend
          value={{
            checkBox: {
              size: '15px',
              color: colors.AQUA_MARINE,
              hover: {
                border: {
                  color: colors.AQUA_MARINE
                }
              }
            }
          }}
        >
          <Box width='large' height='750px'>
            <Box
              pad='medium'
              fill='horizontal'
              align='center'
              justify='between'
              direction='row'
              border='bottom'
              flex={false}
            >
              <Text color={colors.BLACK} size='xlarge'>
                Line Item Templates
              </Text>
              <Box direction='row' align='center' gap='small'>
                <Text>Template</Text>
                <Select
                  labelKey='label'
                  valueKey='id'
                  options={templates}
                  value={template}
                  onChange={this.handleTemplateChange}
                >
                  {this.renderOption}
                </Select>
              </Box>
            </Box>
            <Box flex={false} overflow='auto' height={{ max: '70%' }}>
              {this.renderSectionsBlock()}
              <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
                <CheckBox checked={selectAll} label='Select all' onChange={this.handleSelectAll} />
              </Box>
              <Box pad={{ vertical: 'small', horizontal: 'medium' }} fill='horizontal'>
                <CheckBoxGroup
                  options={currentLineItems}
                  value={selectedLineItems}
                  onChange={this.handleSelectChange}
                  labelKey='label'
                  valueKey='value'
                  fill='horizontal'
                />
              </Box>
            </Box>
            {showNewLineItem && (
              <Box flex={false} pad={{ vertical: 'small', horizontal: 'medium' }} width='medium'>
                <Keyboard onEnter={this.handleNewLineItemSave}>
                  <TextInput
                    value={newLineItem}
                    onChange={this.handleNewLineItemChange}
                    onBlur={this.handleNewLineItemSave}
                    placeholder='New line item name'
                    autoFocus
                  />
                </Keyboard>
              </Box>
            )}
            {!showNewLineItem && (
              <Box flex={false} pad={{ vertical: 'small', horizontal: 'medium' }} width='small'>
                <Button label='Add line item...' onClick={this.handleNewLineItemShow} />
              </Box>
            )}
            <Box
              flex={false}
              direction='row'
              margin={{ top: 'auto' }}
              fill='horizontal'
              pad={{ horizontal: 'medium', bottom: 'small' }}
              gap='small'
            >
              <Box width='small' margin={{ right: 'auto' }}>
                {template.id !== 'all' && (
                  <Button color={colors.CORAL_TWO} label='Delete template' onClick={this.handleDeleteTemplateShow} />
                )}
              </Box>
              <Box width='xsmall'>
                <Button
                  fill
                  secondary
                  label='Close'
                  onClick={() => {
                    // this.handleAdding()
                    this.handleShowChange()
                  }}
                />
              </Box>
              <Box width='xsmall'>
                <Button fill primary color={colors.AQUA_MARINE} label='Add' onClick={this.handleAdding} />
              </Box>
            </Box>
          </Box>
        </ThemeContext.Extend>
        {this.state.showCreateTemplate && (
          <Layer onEsc={this.handleShowCreateTemplate} onClickOutside={this.handleShowCreateTemplate}>
            <Box height='small' width='medium'>
              <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
                <Text color={colors.BLACK} size='medium'>
                  Create template
                </Text>
              </Box>
              <Box pad='small' fill='horizontal' align='center' direction='row' gap='small'>
                <Text>Template</Text>
                <TextInput value={newTemplate} onChange={this.handleNewTemplateChange} />
              </Box>
              <Box direction='row' margin={{ top: 'auto', left: 'auto' }} pad='small' gap='small'>
                <Button primary color={colors.CORAL_TWO} label='Cancel' onClick={this.handleShowCreateTemplate} />
                <Button
                  primary
                  disabled={newTemplate === ''}
                  color={colors.AQUA_MARINE}
                  label='Create'
                  onClick={this.handleCreateTemplate}
                />
              </Box>
            </Box>
          </Layer>
        )}
        {this.state.showImportTemplate && (
          <Layer onEsc={this.handleImportTemplate} onClickOutside={this.handleImportTemplate}>
            <ThemeContext.Extend
              value={{
                checkBox: {
                  size: '15px',
                  color: colors.AQUA_MARINE,
                  hover: {
                    border: {
                      color: colors.AQUA_MARINE
                    }
                  }
                }
              }}
            >
              <Box width='large' height='750px'>
                <Box
                  pad='medium'
                  fill='horizontal'
                  align='center'
                  justify='between'
                  direction='row'
                  border='bottom'
                  flex={false}
                >
                  <Text color={colors.BLACK} size='xlarge'>
                    Import Line Items from .csv
                  </Text>
                </Box>
                <Box pad='small' fill='horizontal' direction='row' gap='small'>
                  {importStep === 0 && (
                    <Box flex pad='small'>
                      <Importer
                        chunkSize={10000}
                        assumeNoHeaders={false}
                        restartable={false}
                        onStart={({ file, fields }) => {}}
                        processChunk={async (rows) => {
                          let csvLineItems = [...this.state.csvLineItems]
                          _.map(rows, row => {
                            const newRow = { ...row }
                            newRow.id = generateId()
                            newRow.required = newRow.required === 'true'
                            newRow.labor = newRow.labor === 'true'
                            csvLineItems.push(newRow)
                          })
                          this.setState({ csvLineItems })
                        }}
                        onComplete={async ({ file, fields }) => {}}
                        onClose={() => {
                          this.setState({ importStep: 1 })
                        }}
                      >
                        <ImporterField name='name' label='Line item name' />
                        <ImporterField name='type' label='Line item type' optional />
                        <ImporterField name='desc' label='Line item description' optional />
                        <ImporterField name='required' label='Required' optional />
                        <ImporterField name='labor' label='Labor only' optional />
                      </Importer>
                    </Box>
                  )}
                  {importStep > 0 && (
                    <Box flex={false} overflow='auto' fill='horizontal'>
                      <Box pad={{ vertical: 'small', horizontal: 'small' }}>
                        <CheckBox checked={selectAllCSV} label='Select all' onChange={this.handleSelectAllCSV} />
                      </Box>
                      <Box pad={{ vertical: 'small', horizontal: 'small' }} fill='horizontal'>
                        <CheckBoxGroup
                          options={currentCSVLineItems}
                          value={selectedCSVLineItems}
                          onChange={this.handleCSVSelectChange}
                          labelKey='label'
                          valueKey='value'
                          fill='horizontal'
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  flex={false}
                  direction='row-reverse'
                  margin={{ top: 'auto' }}
                  fill='horizontal'
                  pad={{ horizontal: 'medium', bottom: 'small' }}
                  gap='small'
                >
                  {importStep > 0 && (
                    <Select
                      labelKey='label'
                      valueKey='id'
                      options={csvTemplates}
                      value={csvTemplate}
                      onChange={this.handleCSVTemplateChange}
                      valueLabel={
                        <Box
                          width='small'
                          overflow='hidden'
                          pad={{ left: 'small', vertical: 'xsmall' }}
                        >
                          <Grommet>
                            <Text size='small' truncate>{csvTemplate.label}</Text>
                          </Grommet>
                        </Box>
                      }
                    >
                      {this.renderOption}
                    </Select>
                  )}
                  {importStep > 0 && (<Button primary color={colors.AQUA_MARINE} label='Add to template' onClick={this.addToTemplate} />)}
                  <Button
                    secondary
                    label='Cancel Import'
                    onClick={this.handleImportTemplate}
                  />
                </Box>
              </Box>
            </ThemeContext.Extend>
          </Layer>
        )}
        {this.state.deleteTemplateShow && (
          <Layer onEsc={this.handleDeleteTemplateShow} onClickOutside={this.handleDeleteTemplateShow}>
            <Box height='small' width='medium'>
              <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
                <Text color={colors.BLACK} size='medium'>
                  Delete template
                </Text>
              </Box>
              <Box pad='small' fill='horizontal' align='center' direction='row' gap='small' justify='center'>
                <Text size='large'>Are you sure?</Text>
              </Box>
              <Box direction='row' margin={{ top: 'auto', left: 'auto' }} pad='small' gap='small'>
                <Button
                  primary
                  color={colors.LIGHT_NAVY_BRIGHT}
                  label='Cancel'
                  onClick={this.handleDeleteTemplateShow}
                />
                <Button primary color={colors.CORAL_TWO} label='Confirm' onClick={this.handleDeleteTemplate} />
              </Box>
            </Box>
          </Layer>
        )}
      </Layer>
    )
  }
}

AddLineItemFromTemplate.propTypes = {
  sections: PropTypes.array,
  addNewLineItem: PropTypes.func
}

const mapStateToProps = state => ({
  account: state.account,
  lineItemsProps: _.get(state, 'account.lineItems', {}),
  templatesProps: _.values(_.get(state, 'account.templates', []))
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(AddLineItemFromTemplate)
