import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveSuppliers } from 'model/actions/suppliersAC'
import { db } from 'constants/firebase'
import suppliersAccountIds from 'shared/constants/suppliersAccountIds'

export function fetchSuppliers (accountId) {
  try {
    const unsubscribe = db
      .collection('suppliers')
      .where('accountId', 'in', [accountId, suppliersAccountIds.PLATFORM])
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          store.dispatch(receiveSuppliers(res))
        },
        err => {
          console.log(`init suppliers error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('suppliers', unsubscribe)
  } catch (e) {
    console.log('init suppliers error', e)
    Sentry.captureException(e)
  }
}

export async function saveGooglePlaceSupplier (gpSupplier) {
  if (!_.isEmpty(gpSupplier) && _.has(gpSupplier, 'id')) {
    try {
      await db
        .collection('suppliers')
        .doc(gpSupplier.id)
        .set(gpSupplier)
    } catch (e) {
      console.log('saveSupplier error: ', e)
      Sentry.captureException(e)
    }
  } else {
    console.log('saveSupplier: you passed empty supplier')
  }
}
