import React from 'react'
import { connect } from 'react-redux'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import colors from 'shared/constants/colors'

const NavBarSubSettings = () => {
  const handleRedirect = () => universalNavigation.push(screens.SUB_APP)
  return (
    <Box
      direction='row'
      align='center'
      justify='between'
      fixed
      fill
      border='bottom'
      pad={{ vertical: 'small', horizontal: 'medium' }}
    >
      <Box>
        <Text size='large' weight={500}>Settings</Text>
      </Box>
      <Box onClick={handleRedirect}>
        <FontAwesomeIcon icon={faTimes} size={30} color={colors.BLACK} />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: getCurrentUserProfile(state)
})

export default connect(mapStateToProps)(NavBarSubSettings)
