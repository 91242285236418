import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Tip } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faComment } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'

const LineItemComments = ({ li, parentLi, setCommentId, messagesAmount }) => {
  const id = _.get(parentLi, 'id', li.id)
  const handleClick = () => {
    if (_.isFunction(setCommentId)) setCommentId(id)
  }
  return (
    <Box align='center' justify='center' border={['bottom', 'right']}>
      <Tip content={'Add comment'}>
        <Box
          className={IGNORE_OUTSIDE_CLICK_CLASS}
          direction='row'
          align='center'
          gap='xxsmall'
          customStyle={`
            :hover {
              * {
                color: ${colors.AQUA_MARINE};
                fill: ${colors.AQUA_MARINE};
              };
            }
          `}
          onClick={handleClick}
        >
          <Text size='xsmall' className='comments_amount' color={colors.ANOTHER_GREY}>
            {messagesAmount > 0 ? messagesAmount : ''}
          </Text>
          <FontAwesomeIcon icon={faComment} color={colors.ANOTHER_GREY} />
        </Box>
      </Tip>
    </Box>
  )
}

LineItemComments.defatultProps = {
  messagesAmount: 0
}

LineItemComments.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  setCommentId: PropTypes.func,
  messagesAmount: PropTypes.number
}

export default LineItemComments
