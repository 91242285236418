import React from 'react'
import { View, Image } from 'react-native'
import styled from 'sc'
import PropTypes from 'prop-types'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { StyledText } from 'shared/documents/components/StyledComponents'

const StyledView = styled(View)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledImage = styled(Image)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  overflow: hidden;
`

const DocAvatar = ({ ssr, url, initials, size, color, customStyle, icon, textColor }) => {
  if (url) {
    return (
      <StyledImage
        size={size}
        source={{ uri: url, cache: 'force-cache' }}
        defaultSource={ ssr ? { uri: url } : undefined}
        resizeMode='contain'
      />
    )
  } else {
    return (
      <StyledView color={color} size={size} customStyle={customStyle}>
        {icon || <StyledText fontSize={size / 2.4} color={textColor}>{_.upperCase(initials)}</StyledText>}
      </StyledView>
    )
  }
}

DocAvatar.defaultProps = {
  color: colors.LIGHT_NAVY,
  textColor: colors.WHITE,
  customStyle: ''
}

DocAvatar.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.number.isRequired,
  url: PropTypes.string,
  initials: PropTypes.string,
  customStyle: PropTypes.string,
  getSize: PropTypes.func
}

export default DocAvatar
