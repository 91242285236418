import React from 'react'
import { Box, Text } from 'grommet'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import { _ } from 'core-js'

const SectionTotal = ({ total }) => {
  if (_.isNull(total)) {
    return <Box />
  } else {
    return (
      <Box align='center' justify='center' gap='xsmall' direction='row'>
        <Text size='small' color={colors.MEDIUM_GREY}>
          SUBTOTAL
        </Text>
        <Text size='small' color={colors.TEXT}>
          {numeral(total).format('$0,0.00')}
        </Text>
      </Box>
    )
  }
}

export default SectionTotal
