import React, { Component } from 'react'
import { Text, Button, Box, Heading } from 'grommet'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Sentry from '@sentry/react'
import ReactCodeInput from 'react-verification-code-input'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
// import screens from 'constants/screens'
import Logo from 'components/logos/LogoOnLight'

import { smsCodeLen } from 'constants/index'
import { checkVerificationCode, requestSmsCode } from 'controllers/auth'
import SignInLogo from 'assets/icons/signin_logo.svg'

const StyledCodeInput = styled(ReactCodeInput)`
  input {
    color: ${colors.TEXT} !important;
    font-size: 24px !important;
    border-color: ${colors.ANOTHER_GREY} !important;
  }
  input:focus {
    border-color: ${colors.NICE_BLUE} !important;
  }
`

class EnterCode extends Component {
  constructor (props) {
    super(props)
    this.state = {
      codeError: null,
      isLoading: false
    }
  }

  componentWillUnmount () {
    this.onVerificationRes = () => null
    this.onResponse = () => null
  }

  onVerificationRes = res => {
    const er = _.get(res, 'error')
    const resend = _.get(res, 'resend', false)
    console.log('onVerification res resend', resend)
    if (er) {
      this.setState({
        codeError: er,
        isLoading: false,
        codeInputKey: _.now()
      })
      if (resend) {
        this.goBack()
      }
    }
  }

  handleSubmit = async code => {
    try {
      const { phone, email } = this.props
      this.setState({ isLoading: true })
      const res = await checkVerificationCode(phone, code, email)
      this.onVerificationRes(res)
    } catch (e) {
      console.log('EnterCode, onSubmit, error:', e)
      this.setState({ isLoading: false, codeInputKey: _.now() })
      Sentry.captureException(e)
    }
  }

  handleSuccess = () => {
    this.setState({ isLoading: false })
  }

  handleError = error => {
    this.setState({
      isLoading: false,
      codeError: error
    })
  }

  goBack = () => {
    universalNavigation.goBack()
  }

  onResponse = () => this.setState({ isLoading: false, codeInputKey: _.now() })

  handleResendCode = async () => {
    try {
      const { phone } = this.props
      this.setState({ isLoading: true, codeError: null })
      await requestSmsCode(phone)
      this.onResponse()
    } catch (e) {
      this.onResponse()
      Sentry.captureException(e)
      console.log('requestSmsCode error', e)
    }
  }

  renderError = () => {
    const { codeError } = this.state
    if (!_.isEmpty(codeError)) {
      return (
        <Text margin={{ top: 'xsmall' }} color={colors.CORAL_TWO}>
          {codeError}
        </Text>
      )
    }
  }

  render () {
    const { isLoading, codeInputKey } = this.state
    const { phone } = this.props
    const phoneNumber = parsePhoneNumberFromString(phone || '')
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='32px' />
        </Box>
        <Box flex={false} pad='medium' alignSelf='center'>
          <Box align='center'>
            <SignInLogo />
            <Heading level={2} color={colors.LIGHT_NAVY} margin={{ top: 'small' }} textAlign='center'>
              Enter validation code
            </Heading>

            <Text margin={{ top: 'small' }} color={colors.ANOTHER_GREY} textAlign='center'>
              Validation code was sent to your number
            </Text>
            <Button
              plain
              icon={<FontAwesomeIcon icon={faPencil} color={colors.ANOTHER_GREY} />}
              label={<Text color={colors.ANOTHER_GREY}>{phoneNumber.formatNational()}</Text>}
              onClick={this.goBack}
              margin={{ bottom: 'large' }}
            />
            <StyledCodeInput
              key={codeInputKey}
              fields={smsCodeLen}
              type='number'
              autoFocus
              onComplete={this.handleSubmit}
              disabled={isLoading}
            />

            {this.renderError()}

            <Button
              plain
              label={
                <Text color={colors.ANOTHER_GREY}>
                  <u>Haven't received a code? Resend</u>
                </Text>
              }
              onClick={this.handleResendCode}
              margin={{ top: 'large' }}
            />
          </Box>
        </Box>
      </Box>
    )
  }
}

export default EnterCode
