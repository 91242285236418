import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'

import ProjectDetails from 'webPages/invitation/mobile/ProjectDetails'
import ProjectDetailsButton from 'webPages/invitation/mobile/ProjectDetailsButton'
import ScopeAndFiles from 'webPages/invitation/mobile/ScopeAndFiles'
import { getInvitationInfo } from 'controllers/auth'

const Content = ({ workOrderId, mode, avatar, gcName, invitedBy, projectName, address, bidsDue, isEmptySow, scope, sections, invId, files, projectId }) => {
  const [invInfo, setInvInfo] = useState(null)
  useEffect(() => {
    const getInfo = async () => {
      setInvInfo(await getInvitationInfo(invId))
    }
    if (invId) getInfo()
  }, [invId])

  const getPage = () => {
    switch (mode) {
      case 'scope': {
        return (
          <Box>
            <ScopeAndFiles scope={scope} sections={sections} invId={invId} files={files} />
          </Box>
        )
      }
      case 'info': {
        return (
          <ProjectDetails
            avatar={avatar}
            gcName={gcName}
            invitedBy={invitedBy}
            projectName={projectName}
            address={address}
            bidsDue={bidsDue}
            isEmptySow={isEmptySow}
            invId={invId}
            {...invInfo}
          />
        )
      }
      default: {
        return (
          <Box>
            <ScopeAndFiles scope={scope} sections={sections} invId={invId} files={files} />
          </Box>
        )
      }
    }
  }
  return (
    <Box flex justify='start'>
      {mode === 'scope' && <ProjectDetailsButton projectId={projectId} workOrderId={workOrderId} mode={mode} />}
      {getPage()}
    </Box>
  )
}

export default Content
