import React from 'react'
import ClickAwayListener from 'react-click-away-listener'
import styled, { css } from 'styled-components'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'

const containerStyle = css`
  background-color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px ${colors.BLACK20};
  z-index: 3;
  ${props => props.customstyle}
`

const StyleClickAwayListener = styled(ClickAwayListener)`
  ${containerStyle}
`
const Container = styled.div`
  ${containerStyle}
`

const OutsideClickHandlerWrapper = ({ children, onClick, enabled, customStyle }) => {
  const onClickAway = evt => {
    console.log('onClickAway', evt)
    let ignore = false
    _.forEach(evt.path, pathElt => {
      if (_.indexOf(pathElt.classList, IGNORE_OUTSIDE_CLICK_CLASS) >= 0) {
        ignore = true
        return false
      }
    })
    console.log('ignore', ignore)
    if (!ignore) onClick(evt)
  }
  if (enabled) {
    return (
      <StyleClickAwayListener onClickAway={onClickAway} customstyle={customStyle}>
        {children}
      </StyleClickAwayListener>
    )
  } else {
    return <Container customstyle={customStyle}>{children}</Container>
  }
}

export default OutsideClickHandlerWrapper
