import React, { useState, useRef, useEffect } from 'react'
import { Box, ThemeContext } from 'grommet'
import numeral from 'numeral'
import _ from 'lodash'

import InputWithSelect from 'components/InputWithSelect'

const LineItemQuantity = ({ li, parentLi, handleUpdate, selectRow, unselectRow, textInputThemeValue }) => {
  const [value, setValue] = useState(null)
  const [type, setType] = useState(li.quantityType)
  const inputRef = useRef()

  useEffect(() => {
    const quantity = _.get(li, 'quantity')
    if (!_.isNil(quantity) && quantity !== '') {
      setValue(numeral(quantity).format('0,0.[00]'))
    }
  }, [li])

  const onChangeType = ({ option }) => {
    setType(option)
    handleUpdate({ quantityType: option })
  }

  const onChange = event => {
    const newValue = event.target.value.replace(/[^0-9.]/g, '')
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(value).value()
      if (newV === curV || _.isNil(value)) {
        setValue(newValue)
      } else {
        setValue(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setValue('')
    }
  }

  let curValue = _.get(li, 'quantity')
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format('0,0.[00]')
  }
  if (!_.isNil(value)) curValue = value
  if (_.isNil(curValue)) curValue = ''

  const onBlur = () => {
    inputRef.current.blur()
    unselectRow()
    const quantity = _.size(value) > 0 ? numeral(value).value() : null
    if (li.quantity !== quantity || li.quantityType !== type) {
      handleUpdate({ quantity: numeral(quantity).value() })
    }
  }

  return (
    <Box align='center' justify='center' border={['bottom', 'right']}>
      <ThemeContext.Extend value={textInputThemeValue}>
        <InputWithSelect
          inputRef={inputRef}
          onChange={onChange}
          onFocus={selectRow}
          onBlur={onBlur}
          onSelectChange={onChangeType}
          value={curValue}
          id={`${li.id}_quantity`}
          selectValue={type}
          options={['sf', 'cy', 'lf', 'ls', 'ea', 'cf', 'lbs', 'ft']}
        />
      </ThemeContext.Extend>
    </Box>
  )
}

export default LineItemQuantity
