import React, { Component } from 'react'
import { Box, Anchor, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import AuthLayout from 'pages/auth/AuthLayout'
import signInMode from 'constants/signInMode'
import { getName } from 'shared/utils/stringUtils'
import { requestAccess, signIn, setOpenPageOnAuth } from 'controllers/auth'

class RequestAccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  renderFooter = () => {
    const { authToken } = this.props
    return (
      <Box
        alignSelf='start'
        pad={{ top: 'medium' }}
        border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
        margin={{ top: 'large' }}
        width='medium'
        direction='row'
        gap='xsmall'
        align='center'
      >
        <Text size='medium' color={colors.TEXT}>
          Not your company?
        </Text>
        <Anchor
          label='Setup new company'
          color={colors.LINK}
          onClick={() => universalNavigation.navigate(screens.ENTER_EMAIL, { mode: signInMode.SIGN_UP, authToken })}
        />
      </Box>
    )
  }

  submit = async () => {
    console.log('submit', this.props)
    const { authToken, subAccountId, invInfo } = this.props
    this.setState({ isLoading: true })
    if (authToken) {
      const authData = await signIn(authToken)
      console.log('%cEnterEmail authData', 'color: antiquewhite;', authData)
      if (_.has(authData, 'user.uid')) {
        await setOpenPageOnAuth(screens.SUB_CREATE_BID, {
          projectId: invInfo.projectId,
          workOrderId: invInfo.workOrderId
        })
        const res = await requestAccess(subAccountId, invInfo.projectId)
        console.log('res', res)
      }
    }
  }

  render () {
    const { isLoading } = this.state
    const { accProfile } = this.props
    return (
      <AuthLayout
        header={`An account for ${getName(accProfile)} exists`}
        error={null}
        subheader={
          <Text size='xlarge' margin={{ top: 'small' }} color={colors.LIGHT_NAVY_BRIGHT}>
            Click here to request access
          </Text>
        }
        isValid={!isLoading}
        placeholder='name@company.com'
        hideInputField
        handleSubmit={this.submit}
        footer={this.renderFooter()}
        submitName='Join team'
      />
    )
  }
}

export default RequestAccess
