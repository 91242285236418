import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'

const Container = styled(View)`
  padding-top: ${props => props.getSize(20)}px;
  padding-bottom: ${props => props.getSize(50)}px;
  ${props => props.customStyle}
`

const DataContainer = styled(View)`
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-left-width: 2px;
  border-left-color: ${colors.TWILIGHT_BLUE};
`

const ContactsBlock = styled(View)`
  display: flex;
  flex-direction: row;
  padding-top: ${props => props.getSize(5)}px;
  padding-bottom: ${props => props.getSize(5)}px;
`
const ClientInfoContainer = styled(View)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${props => props.getSize(10)}px;
`
const ContractorInfoContainer = styled(View)`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ProjectAddressContainer = styled(View)`
  display: flex;
  flex-direction: row;
  padding-left: ${props => props.getSize(10)}px;
  padding-bottom: ${props => props.getSize(6)}px;
`

const DocContacts = ({
  getSize,
  preparedFor,
  preparedBy,
  titleLeft = 'Prepared for:',
  titleRight = 'Prepared by:',
  customStyle,
  projectAddress
}) => {
  const renderProjectAddress = () => {
    if (!_.isNil(projectAddress)) {
      return (
        <ProjectAddressContainer getSize={getSize}>
          <StyledText fontSize={getSize(12)} bold customstyle={`margin-right: ${getSize(12)}px;`}>
            Project Address:
          </StyledText>
          <StyledText fontSize={getSize(12)} color={colors.LIGHT_NAVY_BRIGHT}>
            {projectAddress}
          </StyledText>
        </ProjectAddressContainer>
      )
    }
  }

  return (
    <Container getSize={getSize} customStyle={customStyle}>
      <DataContainer>
        {renderProjectAddress()}
        <ContactsBlock getSize={getSize}>
          <ClientInfoContainer getSize={getSize}>
            <StyledText fontSize={getSize(12)} bold>
              {titleLeft}
            </StyledText>
            {_.map(preparedFor, (t, i) => (
              <StyledText key={i} fontSize={getSize(12)}>
                {t}
              </StyledText>
            ))}
          </ClientInfoContainer>
          <ContractorInfoContainer getSize={getSize}>
            <StyledText fontSize={getSize(12)} bold>
              {titleRight}
            </StyledText>
            {_.map(preparedBy, (t, i) => (
              <StyledText key={i} fontSize={getSize(12)}>
                {t}
              </StyledText>
            ))}
          </ContractorInfoContainer>
        </ContactsBlock>
      </DataContainer>
    </Container>
  )
}

DocContacts.propTypes = {
  getSize: PropTypes.func.isRequired,
  preparedFor: PropTypes.array,
  preparedBy: PropTypes.array,
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
  customStyle: PropTypes.string
}

export default DocContacts
