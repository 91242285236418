import React from 'react'
import { Box, Button, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPaperclip, faSpinner } from '@fortawesome/pro-light-svg-icons'

import universalNavigation from 'utils/universalNavigation'
import colors from 'shared/constants/colors'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'

const ShareViewNavBar = ({
  children,
  fill,
  border,
  pad,
  onDownloadClick,
  onCopyUrlClick,
  onShareClick,
  onSaveReportClick,
  shareButtonDisabled,
  shareButtonLoading,
  settingsChanged,
  updatingReport
}) => {
  const goBack = () => {
    universalNavigation.goBack()
  }

  return (
    <Box direction='row' align='center' justify='between' fixed fill={fill} border={border} pad={pad}>
      <Box align='center' direction='row' gap='small'>
        <Box direction='row' align='center' gap='small'>
          <Button
            plain
            icon={<FontAwesomeIcon icon={faChevronLeft} size={'1x'} />}
            color={colors.BLACK}
            onClick={goBack}
          />
          {children}
        </Box>
      </Box>
      <Box direction='row' align='center'>
        <Box direction='row' gap='medium' pad={{ right: 'medium' }} border={'right'}>
          {settingsChanged && <Text size='small' color={colors.VERY_LIGHT_PINK}>Unsaved changes</Text>}
          <Button
            plain
            icon={<FontAwesomeIcon icon={faDownload} size={'1x'} />}
            color={colors.LIGHT_NAVY_BRIGHT}
            onClick={onDownloadClick}
            disabled={updatingReport}
          />
        </Box>
        <Box direction='row' align='center' pad={{ left: 'medium' }} gap='medium'>
          <Button label='Save changes' primary color={colors.AQUA_MARINE} disabled={!settingsChanged || updatingReport} onClick={onSaveReportClick} />
          <Button
            secondary
            color={colors.LIGHT_NAVY_BRIGHT}
            icon={<FontAwesomeIcon icon={faPaperclip} size={'1x'} />}
            label='Copy link'
            onClick={onCopyUrlClick}
            disabled={updatingReport}
          />
          <Button
            primary
            color={colors.AQUA_MARINE}
            disabled={shareButtonLoading || shareButtonDisabled || updatingReport}
            label='Share'
            onClick={onShareClick}
            icon={shareButtonLoading ? <FontAwesomeIcon icon={faSpinner} color={colors.WHITE} spin /> : undefined}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ShareViewNavBar
