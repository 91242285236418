import _ from 'lodash'

const CURRENCY_SYMBOL = '$'

export function formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    console.log(e)
  }
};

export function moneyWithSymbolAbbr (value, symbol, abbr, decimalCount = 2) {
  const symb = symbol || CURRENCY_SYMBOL
  if (abbr) {
    return `${symb} ${formatMoney(value, decimalCount)} ${abbr}`
  } else {
    return `${symb} ${formatMoney(value, decimalCount)}`
  }
}

export const getMoneyInputValue = (v, maxValue) => {
  if (v !== '') {
    let res = 0
    let amount = parseInt(v)
    if (_.isNaN(amount)) amount = 0
    if (amount > maxValue) {
      res = maxValue
    } else if (amount < 0) {
      res = 0
    } else {
      res = amount
    }
    return res
  } else {
    return 0
  }
}
