import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { Box, Text } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import LayoutContext from 'webPages/layout/LayoutContext'
import LevelingContext from 'webPages/leveling/LevelingContext'
import NavBar from 'webPages/layout/NavBar'
import NavBarContainer from 'components/web/NavBarContainer'
import DropDownMenu from 'components/DropDownMenu'
import { getAllProjectsInfoList, getWorkOrdersByProjectWithTitles } from 'model/selectors/projectsInfoSelector'
import Submenu from 'webPages/gcProjectWrapper/Submenu'
import { changeArchiveProjectStatus, updateProjectDetails, fetchImportedLi } from 'controllers/project'
import { navigateToHomePage } from 'controllers/init'
import reportType from 'shared/constants/reportType'
import { arrayMove } from 'shared/utils/array'
import TakeoffsPanel from 'components/takeoffs/TakeoffsPanel'
import { publishReport } from 'controllers/reports'
import { updateTitle } from 'utils/meta'
import { offListener } from 'controllers/listeners'

const EDIT_PROJECT = 'EDIT_PROJECT'
const PROJECT_FILES = 'PROJECT_FILES'
const CREATE_REPORT = 'CREATE_REPORT'
const CREATE_BID_INVITATION = 'CREATE_BID_INVITATION'
const INVITE_TEAMMATES = 'INVITE_TEAMMATES'
const SHARE = 'SHARE'
const TOGGLE_ARCHIVED = 'TOGGLE_ARCHIVED'
const ARCHIVE = 'ARCHIVE'
const PUBLISH = 'PUBLISH'

const GCProjectWrapper = ({ projectId, project, workOrders, workOrderId, children, canPublish, dispatch }) => {
  const { openProjectModal, openBidInviteModal, toggleSideMenu } = useContext(LayoutContext)
  const { archivedVisible, setArchivedVisible } = useContext(LevelingContext)
  const [woo, setWoo] = useState(_.get(project, 'workOrdersOrder', []))
  const takeoffsPanelRef = useRef()
  const workOrdersOrderDict = useMemo(() => {
    return _.reduce(
      woo,
      (res, v, k) => {
        return { ...res, [v]: k }
      },
      {}
    )
  }, [woo])
  const projectTitle = _.get(project, 'label', _.get(project, 'address.name'))
  const workOrder = _.find(workOrders, wo => wo.id === workOrderId)
  const workOrderTitle = _.get(workOrder, 'title')
  const getTitle = () => !_.isNil(workOrderTitle) ? `${projectTitle} - ${workOrderTitle}` : projectTitle

  useEffect(() => {
    if (_.isEmpty(project)) {
      dispatch(navigateToHomePage())
    }
  }, [])

  useEffect(() => {
    updateTitle(getTitle())
    return () => updateTitle()
  }, [workOrderId, projectId])

  useEffect(() => {
    fetchImportedLi(projectId)
    return () => offListener('importedLi')
  }, [projectId])

  useEffect(() => {
    setTimeout(() => toggleSideMenu(true), 700)
  }, [toggleSideMenu])

  const projectWorkOrdersOrder = _.get(project, 'workOrdersOrder', [])

  useEffect(() => {
    if (!_.isEqual(projectWorkOrdersOrder, woo)) {
      setWoo(projectWorkOrdersOrder)
    }
  }, [projectWorkOrdersOrder])

  const openTakeoffsPanel = () => {
    takeoffsPanelRef.current.open()
  }

  const onMenuOptionClick = optionId => {
    console.log('onMenuOptionClick', optionId)
    switch (optionId) {
      case EDIT_PROJECT:
      case PROJECT_FILES:
        openProjectModal(projectId)
        break
      case CREATE_BID_INVITATION:
        openBidInviteModal()
        break
      case INVITE_TEAMMATES:
        universalNavigation.push(screens.SETTINGS, { activeIndex: 2 })
        break
      case TOGGLE_ARCHIVED:
        setArchivedVisible(!archivedVisible)
        break
      case ARCHIVE:
        const isArchived = _.get(project, 'deleted', 0) > 0
        if (!isArchived) {
          changeArchiveProjectStatus(projectId, true)
          dispatch(navigateToHomePage())
        }
        break
      case SHARE:
        universalNavigation.push(screens.GC_PROJECT_SHARE, { projectId, reportType: reportType.SELECTED_BIDS })
        break
      case PUBLISH:
        publishReport(projectId)
        break
    }
  }

  const menuOptions = [
    { id: EDIT_PROJECT, label: 'Edit project details' },
    { id: PROJECT_FILES, label: 'Project files' },
    { id: CREATE_REPORT, label: 'Create report', disabled: true },
    { id: CREATE_BID_INVITATION, label: 'Create Bid Invitation' },
    { id: INVITE_TEAMMATES, label: 'Invite teammates' },
    { id: SHARE, label: 'Share' },
    { id: PUBLISH, label: 'Publish', disabled: !canPublish },
    { id: TOGGLE_ARCHIVED, label: archivedVisible ? 'Hide archived bid invitations' : 'Show archived bid invitations' },
    { id: ARCHIVE, label: 'Archive', color: colors.CORAL_TWO }
  ]

  const menuItems = useMemo(() => {
    console.log('=> recreate menu items')
    const res = _.reduce(
      workOrders,
      (res, wo) => {
        if (wo.id === '-MXf4wbK9kE_m42Uzk0s') {
          console.log('wo', wo)
        }
        if (wo.deleted > 0 && !archivedVisible) {
          return res
        } else {
          const label = wo.deleted > 0 ? `${wo.title} (archived)` : wo.title
          const option = { id: wo.id, label, archived: wo.deleted > 0 }
          return [...res, option]
        }
      },
      []
    )
    return _.sortBy(res, item => _.get(workOrdersOrderDict, item.id, 0))
  }, [workOrders, archivedVisible, workOrdersOrderDict])

  console.log('menuItems', menuItems)

  const onOrderChange = useCallback(
    (from, to) => {
      const currentOrder = _.map(menuItems, item => item.id)
      const newOrder = arrayMove(currentOrder, from, to)
      setWoo(newOrder)
      updateProjectDetails({ workOrdersOrder: newOrder }, projectId)
    },
    [menuItems, projectId]
  )

  return (
    <Box background={colors.WHITE} height='100%'>
      <NavBarContainer border={false}>
        <NavBar
          fill='horizontal'
          pad={{ vertical: 'small', horizontal: 'medium' }}
          border={false}
          projectId={projectId}
          openTakeoffsPanel={openTakeoffsPanel}
        >
          <DropDownMenu
            options={menuOptions}
            selectedId={projectId}
            dropContentProps={{ boxProps: { width: { min: '260px' } } }}
            dropAlign={{ top: 'bottom', left: 'left' }}
            onOptionClick={onMenuOptionClick}
          >
            <Box direction='row' align='center'>
              <Text size='large' margin={{ right: 'small' }} color='dark-1'>
                {projectTitle}
              </Text>
              <FontAwesomeIcon icon={faChevronDown} size={16} color={colors.TEXT} />
            </Box>
          </DropDownMenu>
        </NavBar>
      </NavBarContainer>
      <Submenu workOrderId={workOrderId} menuItems={menuItems} projectId={projectId} onOrderChange={onOrderChange} />
      {children}
      <TakeoffsPanel ref={takeoffsPanelRef} projectId={projectId} />
    </Box>
  )
}
const mapStateToProps = (state, props) => {
  const projectId = _.get(props, 'projectId')
  const projectOrderDetails = _.get(state.projects, [projectId, 'orderDetails'])
  const isLead = _.get(state, ['accountsProfiles', _.get(state, 'user.currentAccountId'), 'lead'], true)
  return {
    workOrderId: _.get(props, 'workOrderId'),
    projectId,
    project: _.get(state.projects, projectId),
    projects: getAllProjectsInfoList(state),
    workOrders: _.get(getWorkOrdersByProjectWithTitles(state), projectId),
    canPublish: !isLead && !_.isEmpty(projectOrderDetails)
  }
}

export default connect(mapStateToProps)(GCProjectWrapper)
