import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Box, Button, TextInput, ThemeContext, Text, Keyboard } from 'grommet'
import { faGripVertical, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import DropDownMenu from 'components/DropDownMenu'
import lineItemSection from 'shared/constants/lineItemSection'

const GridRow = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 26px 2fr 140px 4fr 200px;
  ${props =>
    !props.isDraggingOver &&
    `:hover {
      #drag_icon path {
        fill: ${colors.BROWN_GREY_TWO};
      }
      #section_buttons {
        display: flex;
      }
    }
  `}
`
const ButtonsContainer = styled(Box)`
  display: none;
`
const TitleBox = styled(Box)`
  cursor: text;
  ${props => (props.visible ? 'display: flex' : 'display: none')}
`

const menuActions = {
  RENAME: 'RENAME',
  DELETE: 'DELETE'
}

const SectionRow = ({
  li,
  dragHandleProps,
  isDraggingOver,
  removeSection,
  updateSection,
  index,
  isDragging,
  expanded,
  toggle,
  disabled,
  sectionTotal,
  canAddItem,
  canRename,
  canDelete
}) => {
  const [title, setTitle] = useState('')
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef(null)

  const isGESection = li.id === lineItemSection.GENERAL_EXCLUSIONS_ID

  useEffect(() => {
    if (_.isEmpty(_.toString(li.title))) {
      setTitle('')
      setEditMode(true)
    } else {
      setTitle(li.title)
    }
  }, [li.title])

  useEffect(() => {
    if (editMode) {
      inputRef.current.focus()
    }
  }, [editMode])

  const renderDragIcon = () => {
    if (!_.isEmpty(dragHandleProps) && !isGESection && !disabled) {
      return (
        <Box align='end' justify='center' {...dragHandleProps}>
          <FontAwesomeIcon
            icon={faGripVertical}
            color={isDragging ? colors.BROWN_GREY_TWO : 'transparent'}
            size={18}
            id='drag_icon'
          />
        </Box>
      )
    } else if ((!_.isNil(expanded) && !_.isNil(toggle) && !disabled) || (isGESection && disabled)) {
      return (
        <Box align='center' justify='center' onClick={toggle}>
          <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} color={colors.LIGHT_NAVY} size={20} />
        </Box>
      )
    } else {
      return <Box {...dragHandleProps} />
    }
  }

  const onAddItemClick = () => {
    const btnId = `add_li_button_${index}`
    const elt = document.getElementById(btnId)
    if (elt) {
      elt.click()
    }
  }

  const renderAddLineItemIcon = () => {
    if (canAddItem) {
      return <Button icon={<FontAwesomeIcon icon={faPlus} />} onClick={onAddItemClick} disabled={disabled} />
    }
  }

  const handleDropDownClick = id => {
    switch (id) {
      case menuActions.RENAME: {
        console.log('rename')
        setEditMode(true)
        break
      }
      case menuActions.DELETE: {
        console.log('DELETE')
        removeSection(li.id)
        break
      }
    }
  }

  const renderDropButton = () => {
    const menuOptions = [
      { id: menuActions.RENAME, label: 'Rename section', disabled: !canRename },
      { id: menuActions.DELETE, label: 'Delete section', disabled: !canDelete }
    ]
    return (
      <DropDownMenu
        options={menuOptions}
        dropContentProps={{ boxProps: { width: { min: '260px' } } }}
        dropAlign={{ top: 'bottom', left: 'left' }}
        onOptionClick={handleDropDownClick}
        disabled={disabled}
      >
        <Box>
          <FontAwesomeIcon icon={faEllipsisH} size={18} color={colors.TEXT} />
        </Box>
      </DropDownMenu>
    )
  }

  const onChange = event => {
    // setUpdate(true)
    const value = event.target.value
    setTitle(value)
  }

  const onBlur = () => {
    let value
    if (!_.isNil(title)) {
      value = title
    } else if (!_.isNil(li.title)) {
      value = li.title
    }
    if (_.size(value) === 0) {
      removeSection(li.id)
    } else if (value !== li.title) {
      updateSection(li.id, { title })
    }
    setEditMode(false)
  }

  const onEnter = () => {
    inputRef.current.blur()
  }

  const textInputThemeValue = {
    global: {
      control: { border: { color: 'transparent', radius: '0px' } },
      colors: {
        placeholder: colors.VERY_LIGHT_PINK
      }
    },
    textInput: {
      // extend: css`
      //   -webkit-text-fill-color: ${colors.TEXT_PRIMARY};
      //   color: ${colors.TEXT_PRIMARY};
      // `,
      disabled: { opacity: 1 },
      container: {
        extend: css`
          height: 100%;
          input {
            height: 100%;
          }
          /* input:focus { */
          background-color: ${colors.WHITE};
          border: 1px ${colors.VERY_LIGHT_GREY_TWO} solid;
          border-radius: 6px;
          /* } */
        `
      }
    }
  }

  const onTitleClick = () => {
    if (!disabled) setEditMode(true)
  }

  const renderTitleField = () => {
    let titleColor
    if (isGESection) titleColor = colors.CORAL_TWO
    return (
      <ThemeContext.Extend value={textInputThemeValue}>
        <Box direction='row'>
          <TitleBox onClick={!(canRename || disabled) ? undefined : onTitleClick} visible={!editMode}>
            <Text size='large' color={titleColor}>
              {li.title}
            </Text>
          </TitleBox>
          <TitleBox direction='row' width='medium' visible={editMode}>
            <Keyboard onEnter={onEnter}>
              <TextInput
                width='large'
                value={title}
                onChange={onChange}
                onBlur={onBlur}
                id={`${li.id}_title`}
                disabled={!canRename || disabled}
                ref={inputRef}
                placeholder={'New section'}
                height='100%'
                size='large'
              />
            </Keyboard>
          </TitleBox>
        </Box>
      </ThemeContext.Extend>
    )
  }

  const renderButtons = () => {
    if (!editMode) {
      return (
        <ButtonsContainer id='section_buttons' direction='row' gap='xsmall' display={'none'}>
          {renderAddLineItemIcon()}
          {(canDelete || canRename) && renderDropButton()}
        </ButtonsContainer>
      )
    }
  }

  const border = isGESection ? undefined : 'bottom'

  const renderSectionTotal = () => {
    if (!sectionTotal) {
      return <Box border={border} />
    }
    return (
      <Box border={border} justify='center' align='end' pad={{ right: 'large' }}>
        <Box direction='row' gap='small'>
          <Text color={colors.MEDIUM_GREY}>SUM</Text>
          <Text color={colors.TEXT}>{numeral(sectionTotal).format('$0,0.00')}</Text>
        </Box>
      </Box>
    )
  }
  return (
    <GridRow key={li.id} id={li.id} isDraggingOver={isDraggingOver}>
      {renderDragIcon()}
      <Box
        height={{ min: '51px' }}
        align='center'
        direction='row'
        pad={{ left: 'xsmall', vertical: 'xsmall' }}
        gap='xsmall'
        border={border}
      >
        {renderTitleField()}
        {renderButtons()}
      </Box>
      <Box border={border} />
      <Box border={border} />
      {renderSectionTotal()}
    </GridRow>
  )
}

SectionRow.defaultProps = {
  canAddItem: true,
  canDelete: true,
  canRename: true
}

SectionRow.propTypes = {
  li: PropTypes.object,
  dragHandleProps: PropTypes.object,
  isDraggingOver: PropTypes.bool,
  removeSection: PropTypes.func,
  updateSection: PropTypes.func,
  isDragging: PropTypes.bool,
  toggle: PropTypes.func,
  canAddItem: PropTypes.bool,
  canRename: PropTypes.bool,
  canDelete: PropTypes.bool
}

export default SectionRow
