import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import { StyledText } from 'shared/components/StyledComponents'

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Loading = ({ message }) => {
  return (
    <Container>
      <StyledText>{message}</StyledText>
    </Container>
  )
}

Loading.defaultProps = {
  message: 'Loading...'
}

Loading.propTypes = {
  message: PropTypes.string
}

export default Loading
