import _ from 'lodash'
import Sentry from 'shared/utils/sentry'
import generate from 'firebase-auto-ids'

import { auth, db, firestore } from 'constants/firebase'
import { receiveProjects } from 'model/actions/projectsAC'
import { receiveImportedLi } from 'model/actions/importedLiAC'
import { addListener } from 'controllers/listeners'
import { deleteFile } from 'shared/controllers/storage'
import config from 'shared/config'
import headers from 'shared/controllers/headers'
import store from 'model/store'
import { getWorkOrdersByProject } from 'model/selectors/projectsInfoSelector'

export const fetchProjects = accountId => {
  try {
    console.log('fetch projects, accountId:', accountId)
    const unsubscribe = db
      .collection('projects')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.set(res, doc.id, p)
          })
          console.log('projects received, amount', _.size(res))
          store.dispatch(receiveProjects(res))
        },
        err => {
          console.log(`fetchProjects error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('projects', unsubscribe)
  } catch (e) {
    console.log('fetchProjects error', e)
    Sentry.captureException(e)
  }
}

export function updateProjectAdmins (projectId, admins = []) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const accountId = _.get(state, 'account.id')
      console.log('updateProjectAdmins', projectId, admins)
      // const upd = { [`projectsAdmins.${projectId}`]: firestore.FieldValue.arrayUnion(...admins) }
      const upd = { [`projectsAdmins.${projectId}`]: admins }
      await db
        .collection('accounts')
        .doc(accountId)
        .update(upd)
    } catch (e) {
      Sentry.captureException(e)
      console.log('updateProjectAdmins error:', e.message)
    }
  }
}

export function updateAgreements (agreements, paperAgreements) {
  return async function (dispatch, getState) {
    throw new Error('updateAgreements, fix me')
    // try {
    //   const state = getState()
    //   const accountId = _.get(state, 'user.currentAccountId')
    //   const curProject = _.get(state, 'project', {})
    //   const curProjectAgreements = _.get(curProject, 'agreements', [])
    //   const curProjectPaperAgreements = _.get(curProject, 'paperAgreements', [])
    //   const addedAgreements = agreements.filter(el => !curProjectAgreements.includes(el))
    //   const addedPaperAgreements = paperAgreements.filter(el => !curProjectPaperAgreements.map(el => el.id).includes(el.id))
    //   const changeAgreements = async () => ref.child(`projects/${accountId}/${curProject.id}/agreements`).set(agreements)
    //   const changePaperAgreements = async () => ref.child(`projects/${accountId}/${curProject.id}/paperAgreements`).set(paperAgreements)
    //   try {
    //     await Promise.all([changeAgreements(), changePaperAgreements()])
    //     if (addedPaperAgreements.length || addedAgreements.length) {
    //       try {
    //         sendAgreements(accountId, curProject.id, addedAgreements, addedPaperAgreements)
    //       } catch (e) {
    //         Sentry.captureException(e)
    //         console.log('updateAgreements: Sending agreements failed: ', e.message)
    //       }
    //     }
    //   } catch (e) {
    //     Sentry.captureException(e)
    //     console.log('updateAgreements: Updating agreements failed: ', e.message)
    //   }
    // } catch (e) {
    //   Sentry.captureException(e)
    //   console.log('updateAgreements error:', e.message)
    // }
  }
}

export async function sendAgreements (accountId, projectId, agreements, paperAgreements) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      accountId,
      projectId,
      agreements,
      paperAgreements
    }
    const url = `${config.dynamicUrl}/proto/sendAgreements`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    return await response.json()
  } catch (e) {
    Sentry.captureException(e)
    console.log('send agreements error:', e.message)
  }
  return null
}

export const inviteSubsAsync = async (contacts, workOrderId) => {
  console.error('the method is deprecated')
  // try {
  //   const state = store.getState()
  //   const accountId = _.get(state, 'user.currentAccountId')
  //   const newContacts = await Promise.all(
  //     _.map(contacts, async c => {
  //       if (_.has(c, 'image') && _.has(c, 'imageRaw')) {
  //         const avatarUrl = await saveFile(`user/${c.id}/avatar`, c.imageRaw.uri)
  //         const avatarSmallUrl = await saveFile(`user/${c.id}/avatarSmall`, c.image.uri)
  //         const contact = {
  //           ...c,
  //           avatarUrl: avatarUrl.url,
  //           avatarSmallUrl: avatarSmallUrl.url
  //         }
  //         return _.omitBy(contact, _.isNil)
  //       }
  //       return c
  //     })
  //   )

  //   const currentUser = auth.currentUser
  //   const authToken = await currentUser.getIdToken()
  //   const body = {
  //     authToken,
  //     contacts: newContacts,
  //     accountId,
  //     workOrderId
  //   }
  //   const url = `${config.dynamicUrl}/proto/inviteSubs`
  //   const response = await fetch(url, {
  //     method: 'post',
  //     headers: headers,
  //     body: JSON.stringify(body)
  //   })
  //   const answer = await response.json()
  //   console.log('aswer', answer)
  //   return answer
  // } catch (e) {
  //   Sentry.captureException(e)
  //   console.log('inviteSubs error:', e.message)
  // }
}

export function inviteSubs (contacts, workOrderId) {
  return async function (dispatch, getState) {
    await inviteSubsAsync(contacts, workOrderId)
  }
}

export async function downloadContractPdf (projectId, accountId) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      projectId,
      accountId
    }
    const url = `${config.dynamicUrl}/proto/downloadContractPdf`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    return await response.json()
  } catch (e) {
    Sentry.captureException(e)
    console.log('send agreements error:', e.message)
  }
  return null
}

export const updateProjectFiles = async (attachments, projectId) => {
  const updProject = {}
  _.forEach(attachments, file => {
    _.set(updProject, [`attachments.${file.id}`], file)
  })
  await db
    .collection('projects')
    .doc(projectId)
    .update(updProject)
}

export const removeProjectFile = async (fileId, projectId) => {
  console.log('delete project file', fileId, projectId)
  try {
    await db
      .collection('projects')
      .doc(projectId)
      .update({
        [`attachments.${fileId}`]: firestore.FieldValue.delete()
      })
    const storagePath = `projects/${projectId}/${fileId}`
    deleteFile(storagePath)
  } catch (error) {
    console.log(error.message)
  }
}

export const updateProjectDetails = async (params, projectId) => {
  await db
    .collection('projects')
    .doc(projectId)
    .update(params)
}

export const saveProject = async (params, projectAdmins) => {
  const p = {
    ..._.omitBy(params, _.isNil),
    deleted: 0
  }
  if (_.isNil(_.get(params, 'label'))) {
    _.set(p, 'label', firestore.FieldValue.delete())
  }
  console.log('save project', p)
  const state = store.getState()
  if (_.has(state, ['projects', p.id])) {
    await db
      .collection('projects')
      .doc(p.id)
      .update(p)
  } else {
    await db
      .collection('projects')
      .doc(p.id)
      .set(p, { merge: true })
  }
  if (!_.isEmpty(projectAdmins)) {
    const upd = { [`projectsAdmins.${p.id}`]: projectAdmins }
    await db
      .collection('accounts')
      .doc(p.accountId)
      .update(upd)
  }
  return p.id
}

export const changeArchiveProjectStatus = async (projectId, isArchiving) => {
  const state = store.getState()
  const deleted = isArchiving ? _.now() : 0
  const batch = db.batch()
  batch.update(db.collection('projects').doc(projectId), { deleted })
  const workOrders = _.filter(_.get(state, 'workOrders'), wo => wo.projectId === projectId)
  for (const workOrder of workOrders) {
    batch.update(db.collection('workOrders').doc(workOrder.id), { deleted })
    const bids = _.filter(_.get(state, 'bids'), bid => bid.workOrderId === workOrder.id)
    for (const bid of bids) {
      batch.update(db.collection('bids').doc(bid.id), { deleted })
    }
  }
  await batch.commit()
}

export const importLineItems = async (projectId, lineItems, filename) => {
  try {
    const state = store.getState()
    const importedLi = state.importedLi
    const timestamp = _.now()
    const fileId = generate(_.now())
    const upd = {
      [`files.${fileId}`]: {
        filename,
        id: fileId,
        timestamp
      }
    }

    // const batch = db.batch()
    _.forEach(lineItems, li => {
      upd[`lineItems.${li.id}`] = { ...li, fileId }
    })

    console.log('upd', upd)
    if (_.isNil(importedLi)) {
      await db
        .collection('importedLi')
        .doc(projectId)
        .set({ id: projectId })
    }
    await db
      .collection('importedLi')
      .doc(projectId)
      .update(upd)
    // if (needToRemoveOld) {
    //   const sn = await db.collection('importedLi').doc(projectId).get()
    //   const data = sn.data()
    //
    //   const workOrders = _.get(getWorkOrdersByProject(state), projectId)
    //   _.forEach(lineItems, ({ id, ...rest }) => {
    //     _.forEach(data, ({ id: fileId, lineItems: currentLineItems }) => {
    //       const newLineItems = [ ...currentLineItems || [] ]
    //       const duplicateIndex = _.findIndex(currentLineItems, ({ id: liId }) => _.isEqual(id, liId))
    //       if (duplicateIndex >= 0) {
    //         _.forEach(workOrders, ({ id: woId, scope }) => {
    //           if (_.has(scope, id)) {
    //             batch.update(db.collection('workOrders').doc(woId), { [`scope.${id}`]: { ...rest, id } })
    //           }
    //         })
    //         _.pullAt(newLineItems, duplicateIndex)
    //         _.set(data, fileId, { ...data[fileId], lineItems: newLineItems })
    //         if (_.size(newLineItems) === 0) {
    //           _.set(data, fileId, firestore.FieldValue.delete())
    //         }
    //       }
    //     })
    //   })
    //   upd = { ...data, ...upd }
    // }
    // batch.set(db.collection('importedLi').doc(projectId), upd, { merge: true })
    // await batch.commit()
    return null
  } catch (e) {
    console.log('importLineItems error', e)
    return null
  }
}

export const fetchImportedLi = async projectId => {
  try {
    const unsubscribe = db
      .collection('importedLi')
      .doc(projectId)
      .onSnapshot(
        doc => {
          const res = doc.data()
          store.dispatch(receiveImportedLi(res))
        },
        err => {
          console.log(`fetchImportedLi error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('importedLi', unsubscribe)
  } catch (e) {
    console.log('fetchImportedLi error', e)
    Sentry.captureException(e)
  }
}

export const updateImportedLi = async (importedLi, projectId) => {
  try {
    const ref = db.collection('importedLi').doc(projectId)
    const update = { ...importedLi }
    _.forEach(importedLi, (value, id) => {
      if (_.isEmpty(_.get(value, 'lineItems', []))) {
        _.set(update, id, firestore.FieldValue.delete())
      }
    })
    await ref.update(update)
  } catch (e) {
    console.log('updateImportedLi error', e)
  }
}
