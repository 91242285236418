import React from 'react'
import numeral from 'numeral'
import { View } from 'react-native'
import styled from 'sc'

import { SpaceBetweenFlexDiv, StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import _ from 'lodash'
import reportWorkOrderOption from 'shared/constants/reportWorkOrderOption'
import reportCostPlusOrMarkup from 'shared/constants/reportCostPlusOrMarkup'
import amountType from 'shared/constants/amountType'
import reportSetting from 'shared/constants/reportSetting'
import { getName } from 'shared/utils/stringUtils'
import { getSelectedBidOption } from 'shared/utils/reports'

const BadgeContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${props => props.getSize(14)}px;
  border-radius: ${props => props.getSize(14)}px;
  padding-left: ${props => props.getSize(14)}px;
  padding-right: ${props => props.getSize(14)}px;
  ${props => `background-color : ${props.color || colors.AQUA_MARINE}`}
`

const renderHeader = getSize => {
  return (
    <SpaceBetweenFlexDiv
      key='project_summary_table_header'
      customStyle={`
        border: 0;
        border-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: ${colors.LIGHT_NAVY_BRIGHT};
        padding-bottom: ${getSize(12)}px;
      `}
    >
      <StyledText fontSize={getSize(12)} bold>
        Trade
      </StyledText>
      <StyledText fontSize={getSize(12)} bold>
        Amount
      </StyledText>
    </SpaceBetweenFlexDiv>
  )
}

const renderRow = (getSize, key, title, amount, t, bidsAmount, invitationsAmount, reportSettings, subName) => {
  const showBidsTotals = reportSetting.getValue(reportSettings, reportSetting.SHOW_BIDS_TOTALS)
  const showNumberOfBids = reportSetting.getValue(reportSettings, reportSetting.SHOW_NUMBER_OF_BIDS)
  const showNumberOfInvited = reportSetting.getValue(reportSettings, reportSetting.SHOW_NUMBER_OF_INVITED)
  const showSubName = true
  // reportSetting.getValue(reportSettings, reportSetting.SHOW_SUB_NAME)
  const renderValue = () => {
    if (t === reportWorkOrderOption.EXCLUDED) {
      return (
        <BadgeContainer getSize={getSize} color={colors.CORAL_TWO}>
          <StyledText fontSize={getSize(8)} color={colors.WHITE}>
            Excluded
          </StyledText>
        </BadgeContainer>
      )
    } else if (t === reportWorkOrderOption.PENDING) {
      return (
        <BadgeContainer getSize={getSize} color={colors.COOL_GREY}>
          <StyledText fontSize={getSize(8)} color={colors.WHITE}>
            Pending
          </StyledText>
        </BadgeContainer>
      )
    } else if (showBidsTotals && ((t !== reportWorkOrderOption.INCLUDED && !_.isArray(t)) || (_.size(t) >= 1 && _.isArray(t)))) {
      return <StyledText fontSize={getSize(12)}>{amount}</StyledText>
    } else {
      return (
        <BadgeContainer getSize={getSize} color={colors.AQUA_MARINE}>
          <StyledText fontSize={getSize(8)} color={colors.WHITE}>
            Included
          </StyledText>
        </BadgeContainer>
      )
    }
  }

  const renderDesc = () => {
    if (showNumberOfBids && showNumberOfInvited) {
      return `${bidsAmount} total bids (${invitationsAmount} invited)`
    } else if (showNumberOfBids) {
      return `${bidsAmount} total bids`
    } else if (showNumberOfInvited) {
      return `${invitationsAmount} invited`
    } else {
      return null
    }
  }

  const renderSubName = () => {
    if (showSubName) {
      return (
        <View>
          <StyledText fontSize={getSize(12)} color={colors.LIGHT_NAVY_BRIGHT} italic>
            {subName}
          </StyledText>
        </View>
      )
    } else {
      return <View />
    }
  }

  return (
    <SpaceBetweenFlexDiv
      key={key}
      customStyle={`padding-top: ${getSize(6)}px; padding-bottom: ${getSize(6)}px; align-items: center;`}
    >
      <View style={{ flex: 1 }}>
        <StyledText fontSize={getSize(12)}>{title}</StyledText>
        {(showNumberOfBids || showNumberOfInvited) && (
          <View style={{ marginLeft: getSize(12) }}>
            <StyledText fontSize={getSize(10)} italic color={colors.TEXT}>
              {renderDesc()}
            </StyledText>
          </View>
        )}
      </View>
      <SpaceBetweenFlexDiv customStyle='flex: 1; align-items: center;'>
        {renderSubName()}
        {renderValue()}
      </SpaceBetweenFlexDiv>
    </SpaceBetweenFlexDiv>
  )
}

const renderSubtotal = (v, hasMultiple) => {
  if (hasMultiple) {
    return `${numeral(v.min).format('$0,0.00')} – ${numeral(v.max).format('$0,0.00')}`
  } else {
    return numeral(v).format('$0,0.00')
  }
}

const renderTotals = params => {
  const { getSize, reportSettings, subtotal } = params
  const cpom = reportSetting.getValue(reportSettings, reportSetting.COST_PLUS_OR_MARKUP)
  let finalSubtotal = subtotal
  const v = _.get(reportSettings, [reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, 'value'], 0)
  const type = _.get(reportSettings, [reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, 'type'], amountType.FLAT_AMOUNT)
  const costPlusValue =
    cpom === reportCostPlusOrMarkup.COST_PLUS
      ? type === amountType.FLAT_AMOUNT
          ? v
          : _.get(subtotal, 'hasMultipleBids')
            ? { min: _.round((_.get(subtotal, 'min') * v) / 100, 2), max: _.round((_.get(subtotal, 'max') * v) / 100, 2) }
            : _.round((subtotal * v) / 100, 2)
      : _.get(subtotal, 'hasMultipleBids')
        ? { min: 0, max: 0 }
        : 0
  if (cpom === reportCostPlusOrMarkup.MARKUP) {
    if (type === amountType.FLAT_AMOUNT) {
      if (_.get(subtotal, 'hasMultipleBids')) {
        finalSubtotal = { min: v + _.get(subtotal, 'min'), max: v + _.get(subtotal, 'max'), hasMultipleBids: true }
      } else {
        finalSubtotal = v + subtotal
      }
    } else {
      if (_.get(subtotal, 'hasMultipleBids')) {
        finalSubtotal = { min: _.round(_.get(subtotal, 'min') * (1 + v / 100), 2), max: _.round(_.get(subtotal, 'max') * (1 + v / 100), 2) }
      } else {
        finalSubtotal = _.round(subtotal * (1 + v / 100), 2)
      }
    }
  }
  let total = 0
  if (_.get(subtotal, 'hasMultipleBids')) {
    const min = _.get(finalSubtotal, 'min', 0) + _.get(costPlusValue, 'min', costPlusValue)
    const max = _.get(finalSubtotal, 'max', 0) + _.get(costPlusValue, 'max', costPlusValue)
    total = `${numeral(min).format('$0,0.00')} – ${numeral(max).format('$0,0.00')}`
  } else {
    total = numeral((finalSubtotal || 0) + (costPlusValue || 0)).format('$0,0.00')
  }
  return (
    <SpaceBetweenFlexDiv
      key='project_summary_total'
      customStyle={`
        border: 0;
        border-style: solid;
        border-top-width: 1px;
        border-top-color: ${colors.LIGHT_NAVY_BRIGHT};
      `}
    >
      <View style={{ flex: 4 }} />
      <View style={{ flex: 3 }}>
        <SpaceBetweenFlexDiv customStyle={`padding-top: ${getSize(10)}px`}>
          <StyledText fontSize={getSize(12)} bold>
            Subtotal
          </StyledText>
          <StyledText fontSize={getSize(12)} bold>
            {renderSubtotal(finalSubtotal, _.get(subtotal, 'hasMultipleBids'))}
          </StyledText>
        </SpaceBetweenFlexDiv>
        {cpom === reportCostPlusOrMarkup.COST_PLUS && v > 0 && (
          <SpaceBetweenFlexDiv customStyle={`padding-top: ${getSize(10)}px`}>
            <StyledText fontSize={getSize(12)} bold>
              {type === amountType.PERCENTAGE ? `Cost Plus (${v}%)` : 'Cost Plus'}
            </StyledText>
            <StyledText fontSize={getSize(12)} bold>
              {_.get(subtotal, 'hasMultipleBids') && _.has(costPlusValue, 'max')
                ? `${numeral(costPlusValue.min).format('$0,0.00')} – ${numeral(costPlusValue.max).format('$0,0.00')}`
                : numeral(costPlusValue).format('$0,0.00')}
            </StyledText>
          </SpaceBetweenFlexDiv>
        )}
        <SpaceBetweenFlexDiv customStyle={`padding-top: ${getSize(10)}px`}>
          <StyledText fontSize={getSize(12)} bold>
            Total
          </StyledText>
          <StyledText fontSize={getSize(12)} bold>
            {total}
          </StyledText>
        </SpaceBetweenFlexDiv>
      </View>
    </SpaceBetweenFlexDiv>
  )
}

const renderWorkOrders = params => {
  const {
    getSize,
    reportSettings,
    workOrders,
    bidsByWorkOrders,
    contractorTypes,
    woTotalPerc,
    accountsProfiles
  } = params
  const showTradesWithNoBids = reportSetting.getValue(reportSettings, reportSetting.SHOW_TRADES_WITH_NO_BIDS)
  const res = []
  _.forEach(workOrders, wo => {
    const tradeName = _.get(contractorTypes, [wo.tradeId, 'name'])
    const label = _.get(wo, 'label', tradeName)
    const bids = _.get(bidsByWorkOrders, wo.id)
    const t = getSelectedBidOption(reportSettings, wo.id, bids)
    const bidsAmount = _.size(bids)
    if (bidsAmount === 0 && !showTradesWithNoBids) {
      return
    }
    const invitationsAmount = _.size(_.get(wo, 'invitations'))
    if (t === reportWorkOrderOption.EXCLUDED || t === reportWorkOrderOption.INCLUDED) {
      res.push(renderRow(getSize, wo.id, label, null, t, bidsAmount, invitationsAmount, reportSettings, ''))
    } else if (t !== reportWorkOrderOption.HIDE) {
      let total = null
      if (_.isArray(t) && _.size(t) > 1) {
        const bids = _.map(t, id => _.get(bidsByWorkOrders, [wo.id, id, 'total']))
        const min = numeral(_.round(_.min(bids) * (1 + _.get(woTotalPerc, 'min', woTotalPerc)))).format('$0,0.00')
        const max = numeral(_.round(_.max(bids) * (1 + _.get(woTotalPerc, 'max', woTotalPerc)))).format('$0,0.00')
        if (!_.isEqual(min, max)) {
          total = `${min} – ${max}`
        } else {
          total = min
        }
      } else {
        const bidTotal = _.get(bidsByWorkOrders, [wo.id, t, 'total'])
        total = numeral(_.round(bidTotal * (1 + woTotalPerc), 2)).format('$0,0.00')
      }
      const subId = _.get(bidsByWorkOrders, [wo.id, t, 'accountId'])
      const subName = _.isArray(t) && _.size(t) > 1 ? 'Multiple bids' : getName(_.get(accountsProfiles, subId))
      res.push(renderRow(getSize, wo.id, label, total, t, bidsAmount, invitationsAmount, reportSettings, subName))
    }
  })
  return res
}

const getSubtotal = (reportSettings, workOrders, bidsByWorkOrders) => {
  const hasMultipleBids = _.size(_.filter(workOrders, wo => {
    const bids = _.get(bidsByWorkOrders, wo.id)
    const t = getSelectedBidOption(reportSettings, wo.id, bids)
    return _.isArray(t) && _.size(t) > 1
  })) > 0
  if (hasMultipleBids) {
    let min = 0
    let max = 0
    _.forEach(workOrders, wo => {
      const bids = _.get(bidsByWorkOrders, wo.id)
      const t = getSelectedBidOption(reportSettings, wo.id, bids)
      if (!_.isArray(t) && t !== reportWorkOrderOption.HIDE && t !== reportWorkOrderOption.EXCLUDED && t !== reportWorkOrderOption.INCLUDED) {
        min += _.get(bidsByWorkOrders, [wo.id, t, 'total'])
        max += _.get(bidsByWorkOrders, [wo.id, t, 'total'])
      } else if (_.isArray(t)) {
        const bids = _.map(t, id => _.get(bidsByWorkOrders, [wo.id, id, 'total'], 0))
        if (_.size(bids) > 0) {
          min += _.min(bids)
          max += _.max(bids)
        }
      }
    })
    return { min, max, hasMultipleBids }
  } else {
    return _.sumBy(workOrders, wo => {
      const bids = _.get(bidsByWorkOrders, wo.id)
      const t = getSelectedBidOption(reportSettings, wo.id, bids)
      if (t !== reportWorkOrderOption.HIDE) {
        return _.get(bidsByWorkOrders, [wo.id, t, 'total'])
      } else {
        return 0
      }
    })
  }
}

const renderProjectSummary = params => {
  const { getSize, reportSettings, workOrders, bidsByWorkOrders } = params
  let woTotalPerc = 0
  const subtotal = getSubtotal(reportSettings, workOrders, bidsByWorkOrders)
  const cpom = reportSetting.getValue(reportSettings, reportSetting.COST_PLUS_OR_MARKUP)
  if (cpom === reportCostPlusOrMarkup.MARKUP) {
    const v = _.get(reportSettings, [reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, 'value'])
    const type = _.get(reportSettings, [reportSetting.COST_PLUS_OR_MARKUP_AMOUNT, 'type'], amountType.FLAT_AMOUNT)
    if (v > 0 && type === amountType.FLAT_AMOUNT) {
      if (_.get(subtotal, 'hasMultipleBids')) {
        woTotalPerc = { min: v / _.get(subtotal, 'min'), max: v / _.get(subtotal, 'max') }
      } else {
        woTotalPerc = v / subtotal
      }
    } else if (v > 0) {
      woTotalPerc = v / 100
    }
  }
  return [renderHeader(getSize), ...renderWorkOrders({ ...params, woTotalPerc }), renderTotals({ ...params, subtotal })]
}

export default renderProjectSummary
