import * as types from 'model/actionTypes'

const initialState = {
  supplyOrder: {}
}

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.CHANGE_TEMP_SUPPLY_ORDER:
      return {
        ...state,
        supplyOrder: {
          ...state.supplyOrder,
          ...action.payload
        }
      }
    case types.DROP_TEMP_SUPPLY_ORDER:
      return {
        ...state,
        supplyOrder: {}
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
