import React from 'react'
import styled from 'sc'
import { View, Image } from 'react-native'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import Break from 'shared/documents/components/Break'

const Container = styled(View)`
  width: ${props => props.pageWidth}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-bottom: ${props => props.getSize(40)}px;
  /* background-color: lightyellow; */
`
const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
`

const BackgroundOveraly = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
  background-color: rgba(51, 51, 51, 0.73);
`
const ContentContainer = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${props => props.getSize(20)}px;
`
const LeftColumn = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: ${props => props.getSize(20)}px;
`
const RightColumn = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: ${props => props.getSize(20)}px;
  padding-top: ${props => props.getSize(10)}px;
`
const ServiceRow = styled(View)`
  margin-bottom: ${props => props.getSize(10)}px;
  width: ${props => props.pageWidth / 3 - 1}px;
  display: flex;
  flex-direction: row;
`
const HeaderContainer = styled(View)`
  position: relative;
`


const defaultImageUri = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Four_services_header.png?alt=media&token=bf9accf1-067d-49b7-af08-9ee4945bbb85'

export const renderOurServices = (getSize, width, height, ssr, serviceTypeGroups) => {
  const renderServiceRow = (ctg, key) => {
    return (
      <ServiceRow getSize={getSize} key={key} pageWidth={width}>
        <StyledText
          fontSize={getSize(12)}
          color={colors.AQUA_MARINE}
          customstyle={`margin-right: ${getSize(12)}px`}
          bold
        >
          {'\u2022'}
        </StyledText>
        <StyledText
          key={key}
          fontSize={getSize(12)}
          textAlign='center'
          textAlign='left'
        >
          {ctg.name}
        </StyledText>
      </ServiceRow>
    )
  }
  return (
    <Container pageWidth={width} pageHeight={height - 1} getSize={getSize} key={'ourService'}>
      {/* <BackgroundImage
        source={{ uri: defaultImageUri }}
        defaultSource={ ssr ? { uri: defaultImageUri } : undefined}
        resizeMode='cover'
        getSize={getSize}
      />

      <BackgroundOveraly getSize={getSize} />
 */}

      <HeaderContainer getSize={getSize}>
        <StyledText
          // color={colors.WHITE}
          fontSize={getSize(32)}
          textAlign='center'
          bold
        >
          Our Services
        </StyledText>
      </HeaderContainer>

      <ContentContainer getSize={getSize}>
        {_.map(serviceTypeGroups, renderServiceRow)}
      </ContentContainer>
    </Container>
  )
}
