import React, { useContext, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faUsers, faInbox, faCommentAltLines } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import HamburgerBack from 'assets/icons/hamburgerBack.svg'
import { getAllProjectsInfoList } from 'model/selectors/projectsInfoSelector'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import LayoutContext from 'webPages/layout/LayoutContext'
import AccountSelector from 'webPages/layout/AccountSelector'
import { scrollBarStyleOnDark } from 'shared/constants/index'

const SideBarGC = ({ projects, params, inviteTeammatesRef }) => {
  // console.log('SIDEBAR - GC')
  const selectedProjectId = _.get(params, 'projectId')
  const { openProjectModal, toggleSideMenu } = useContext(LayoutContext)

  const onProjectClick = useCallback(
    projectId => {
      if (projectId !== selectedProjectId) {
        // toggleSideMenu(false)
        universalNavigation.push(screens.GC_PROJECT, { projectId })
      }
    },
    [selectedProjectId]
  )

  const onClose = () => toggleSideMenu(false)

  const onContactsClick = () => {
    // universalNavigation.navigate(screens.SETTINGS, { activeIndex: 4 })
    universalNavigation.navigate(screens.GC_CONTACTS)
  }

  const onLineItemsClick = () => {
    console.log('onLineItemsClick')
  }

  const onInviteTeammatesClick = () => {
    inviteTeammatesRef.current.open()
  }

  const onHelpAndSupportClick = () => {
    window.open('http://help.bidlevel.co/en/')
  }

  // const onSettingsClick = () => {
  //   // close()
  //   universalNavigation.navigate(screens.SETTINGS)
  // }

  const renderProjects = useMemo(() => {
    if (_.isEmpty(projects)) {
      return <Box flex />
    } else {
      const renderProjectLine = p => {
        const isSelected = p.id === selectedProjectId
        return (
          <Box
            direction='row'
            align='center'
            pad={{ left: 'xsmall' }}
            flex={{ shrink: 0 }}
            key={p.id}
            background={isSelected ? colors.WHITE25 : undefined}
            customStyle={`
              :hover {
                background-color: rgba(255, 255, 255, 0.1);
              }
            `}
          >
            <Box
              round='xxsmall'
              onClick={() => onProjectClick(p.id)}
              align='start'
              direction='row'
              flex
              height='100%'
              pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
              customStyle={`
                color: ${colors.VERY_LIGHT_GREY_THREE};
                :hover {
                  color: ${colors.WHITE};
                };
              `}
            >
              <Text>{_.get(p, 'label', _.get(p, 'address.name'))}</Text>
            </Box>
          </Box>
        )
      }

      return (
        <Box flex>
          <Box pad={{ left: 'small' }} flex={false}>
            <Text color={colors.BOULDER} size='small' margin={{ top: 'medium', right: 'xsmall', left: 'xsmall' }}>
              PROJECTS
            </Text>
          </Box>
          <Box flex overflow='auto' customStyle={scrollBarStyleOnDark}>
            {_.map(projects, renderProjectLine)}
          </Box>
        </Box>
      )
    }
  }, [projects, selectedProjectId, onProjectClick])

  return (
    <Box direction='column' justify='between' flex>
      <Box>
        <Box pad={{ top: 'xxsmall' }} flex={false}>
          <Box
            // height='xxsmall'
            direction='row'
            align='center'
            justify='between'
            pad={{ left: 'small', right: 'xsmall', top: 'small' }}
          >
            <Box direction='row' align='center' gap='xsmall' flex>
              <AccountSelector />
            </Box>
            <Box height='27px' align='center' justify='center'>
              <Button onClick={onClose} color={colors.WHITE} hoverColor={colors.VERY_LIGHT_PINK_TWO}>
                <HamburgerBack />
              </Button>
            </Box>
          </Box>
        </Box>

        <Box pad={{ right: 'medium', top: 'medium', left: 'small' }} flex={false}>
          <Button
            gap='xsmall'
            margin={{ right: 'xsmall', left: 'xsmall' }}
            label='New Project'
            color={colors.WHITE}
            hoverTextColor={colors.LIGHT_NAVY_DARK}
            icon={<FontAwesomeIcon icon={faPlus} size={12} />}
            secondary
            inverse
            onClick={() => openProjectModal()}
            // white
          />
        </Box>
        <Box direction='row' pad={{ top: 'medium', left: 'xxsmall' }} flex={{ shrink: 0 }}>
          <Button
            gap='xsmall'
            label='Contacts'
            color={colors.WHITE}
            hoverColor={colors.LIGHT_NAVY_BRIGHT}
            hoverTextColor={colors.WHITE}
            icon={<FontAwesomeIcon icon={faUsers} size={17} />}
            onClick={onContactsClick}
          />
        </Box>
        <Box direction='row' pad={{ top: 'xsmall', left: 'xxsmall' }} flex={{ shrink: 0 }}>
          <Button
            gap='xsmall'
            label='Line items'
            color={colors.WHITE}
            hoverColor={colors.LIGHT_NAVY_BRIGHT}
            hoverTextColor={colors.WHITE}
            icon={<FontAwesomeIcon icon={faInbox} size={17} />}
            onClick={onLineItemsClick}
            tip='Coming Soon - Manage your saved Line items'
          />
        </Box>
        {renderProjects}
      </Box>

      <Box flex={false} pad={{ right: 'xsmall', left: 'xsmall', top: 'small', bottom: 'small' }}>
        <Box direction='row'>
          <Button
            gap='xsmall'
            label='Invite Teammates'
            color={colors.WHITE}
            hoverColor={colors.LIGHT_NAVY_BRIGHT}
            hoverTextColor={colors.WHITE}
            icon={<FontAwesomeIcon icon={faUsers} size={17} />}
            onClick={onInviteTeammatesClick}
          />
        </Box>
        <Box direction='row' pad={{ top: 'xsmall' }}>
          <Button
            gap='xsmall'
            label='Help & Feedback'
            color={colors.WHITE}
            hoverColor={colors.LIGHT_NAVY_BRIGHT}
            hoverTextColor={colors.WHITE}
            icon={<FontAwesomeIcon icon={faCommentAltLines} size={17} />}
            onClick={onHelpAndSupportClick}
          />
        </Box>
      </Box>
    </Box>
  )
}

SideBarGC.defaultProps = {
  projects: []
}

SideBarGC.propTypes = {
  projects: PropTypes.array
}

const mapStateToProps = state => ({
  projects: getAllProjectsInfoList(state)
})

export default connect(mapStateToProps)(SideBarGC)
