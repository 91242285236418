import React, { useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { Box, Main, ResponsiveContext } from 'grommet'
import _ from 'lodash'

import SideBarGC from 'webPages/layout/SideBarGC'
// import SideBarSub from 'webPages/layout/SideBarSub'
import LayoutDataProvider from 'webPages/layout/LayoutDataProvider'
import SideBarWrapper from 'webPages/layout/SideBarWrapper'
import { scrollBarStyleOnWhite } from 'shared/constants/index'
import TeammatesModal from 'webPages/settings/TeammatesModal'

const Layout = ({ children, params, account }) => {
  const windowSize = useContext(ResponsiveContext)
  const inviteTeammatesRef = useRef(null)
  const isGC = _.get(account, 'isGC', false)
  // const SideBar = isGC ? SideBarGC : SideBarSub
  const isMobile = windowSize === 'small'
  return (
    <LayoutDataProvider>
      <Main
        pad='none'
        direction='row'
        width={isMobile ? { min: 'small', max: 'full' } : { min: 'medium', max: 'full' }}
        customStyle={scrollBarStyleOnWhite}
      >
        {isGC && (
          <SideBarWrapper>
            <SideBarGC params={params} inviteTeammatesRef={inviteTeammatesRef} />
          </SideBarWrapper>
        )}
        <Box
          width={isMobile ? { min: 'small', max: 'full' } : { min: 'medium', max: 'full' }}
          alignSelf='stretch'
          flex
          // pad={{ top: 'xsmall' }}
        >
          {children}
        </Box>
        <TeammatesModal ref={inviteTeammatesRef} />
      </Main>
    </LayoutDataProvider>
  )
}

const mapStateToProps = (state, props) => ({
  account: state.account
})

export default connect(mapStateToProps)(Layout)
