import React, { useState, useEffect } from 'react'
import { Box, Text, Tabs, Tab, ThemeContext } from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'

import colors from 'shared/constants/colors'
import Profile from 'pages/settings/SettingsProfile'
import Account from 'pages/settings/SettingsAccount'
import Teammates from 'pages/settings/SettingsTeammates'
import Notifications from 'pages/settings/SettingsNotifications'
import Tip from 'components/Tip'
import NavBarGC from 'webPages/layout/NavBar'
import NavBarSubSettings from 'webPages/layout/NavBarSubSettings'
import NavBarContainer from 'components/web/NavBarContainer'
import SettingsBilling from 'pages/settings/SettingsBilling'
import config from 'shared/config'

const TabTitle = ({ label, active, disabled }) =>
  disabled ? (
    <Tip message='Coming Soon'>
      <Box width='xsmall'>
        <Text textAlign='center' color={active ? colors.AQUA_MARINE : colors.ANOTHER_GREY}>
          {label}
        </Text>
      </Box>
    </Tip>
  ) : (
    <Box width='xsmall'>
      <Text textAlign='center' color={active ? colors.AQUA_MARINE : colors.ANOTHER_GREY}>
        {label}
      </Text>
    </Box>
  )

const Settings = ({ account, redirectActiveIndex }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isGC = _.get(account, 'isGC', false)

  useEffect(() => {
    setActiveIndex(redirectActiveIndex)
  }, [redirectActiveIndex])

  return (
    <Box pad='none'>
      <NavBarContainer border={false} pad='none'>
        {isGC && <NavBarGC fill='horizontal' pad={{ vertical: 'small', horizontal: 'medium' }} border={false} />}
        {!isGC && <NavBarSubSettings />}
      </NavBarContainer>
      <Box margin={{ top: 'small' }} pad={{ top: 'medium' }}>
        <ThemeContext.Extend
          value={{
            tab: {
              border: { hover: { color: colors.ANOTHER_GREY } },
              active: { background: 'transparent' },
              hover: { background: 'transparent' }
            }
          }}
        >
          <Tabs
            justify='center'
            activeIndex={activeIndex}
            onActive={index => {
              setActiveIndex(index)
            }}
            gap='large'
            border={false}
          >
            <Tab title={<TabTitle label='Profile' active={activeIndex === 0} />}>
              <Profile />
            </Tab>
            <Tab title={<TabTitle label='Account' active={activeIndex === 1} />}>
              <Account />
            </Tab>
            <Tab title={<TabTitle label='Team' active={activeIndex === 2} />}>
              <Teammates />
            </Tab>
            <Tab title={<TabTitle label='Notifications' active={activeIndex === 3} />}>
              <Notifications />
            </Tab>
            {account.isGC && (
              <Tab title={<TabTitle label='Subscription' active={activeIndex === 4} />}>
                <SettingsBilling />
              </Tab>
            )}
            <Tab title={<TabTitle label='Integrations' active={activeIndex === 5} disabled />} disabled>
              <Text>redirect to stripe todo</Text>
            </Tab>
          </Tabs>
        </ThemeContext.Extend>
      </Box>
      {!config.isDev && (
        <Box customStyle='position: fixed; bottom: 0px; right: 0px;' pad='xsmall'>
          <Text size='small' color={colors.ANOTHER_GREY}>
            {config.internalVersion}
          </Text>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = state => ({
  account: state.account
})

export default connect(mapStateToProps)(Settings)
