import { saveAs } from 'file-saver'

export const downloadFile = async (path, name) => {
  try {
    const request = await window.fetch(path)
    const response = await request.blob()
    const status = _.get(request, 'status')
    if (status === 200) {
      saveAs(response, name)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('downloadFile error', error)
  }
}

export const getFile = async path => {
  try {
    const request = await window.fetch(path)
    if (request.status === 200) return true
    return false
  } catch (error) {
    console.log('getFile error', error.message)
  }
}
