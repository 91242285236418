import React from 'react'
import { Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faFilePlus, faUsers, faPaperclip } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import ButtonCard from 'components/web/ButtonCard'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import InviteSubDrop from 'components/workOrder/InviteSubDrop'

const NoScopeNoSubs = ({ workOrderId, projectId, openFilesModal }) => {
  const toScopeOfWork = () => {
    universalNavigation.push(screens.GC_PROJECT_WORKORDER, { workOrderId, projectId })
  }

  const inviteSubDropLabel = (
    <ButtonCard
      icon={<FontAwesomeIcon icon={faUsers} size={48} color={colors.LIGHT_NAVY_BRIGHT} />}
      title='Invite subcontractors'
      description='Invite subcontractors to bid'
    />
  )

  return (
    <Box fill align='center' justify='center'>
      <Box direction='row' gap='large'>
        <InviteSubDrop
          key='inviteSubDrop-submenu'
          dropButtonProps={{
            // color: colors.ASANA_GRAY_TEXT_HOVERED,
            height: '250px',
            width: '250px',
            customStyle: 'padding: 0; :hover { border-radius: 0};',
            hoverTextColor: colors.LIGHT_NAVY_BRIGHT,
            hoverIndicator: { color: 'rgba(0, 64, 105, 0.14)' },
            dropAlign: { bottom: 'bottom', right: 'right' }
          }}
          dropButtonLabel={inviteSubDropLabel}
          workOrderId={workOrderId}
        />
        <ButtonCard
          icon={<FontAwesomeIcon icon={faPaperclip} size={48} color={colors.LIGHT_NAVY_BRIGHT} />}
          title='Upload plans'
          description='Upload plans for your subcontractors to use to create their bids'
          onClick={openFilesModal}
        />
        <ButtonCard
          icon={<FontAwesomeIcon icon={faFilePlus} size={48} color={colors.LIGHT_NAVY_BRIGHT} />}
          title='Create scope template'
          description='Add line items to your bid invite for subcontractors to bid'
          onClick={toScopeOfWork}
        />
      </Box>
    </Box>
  )
}

export default NoScopeNoSubs
