import React from 'react'
import { Box, Text, Avatar } from 'grommet'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { getName, getInitials } from 'shared/utils/stringUtils'
import messageType from 'constants/messageType'

const InboxMessage = ({ index, onClick, isNew, title, timestamp, text, profile, strAction, itemName, type }) => {
  return (
    <Box
      pad={{ horizontal: 'small', top: 'small', bottom: 'medium' }}
      flex={false}
      key={index}
      border={index === 0 ? 'horizontal' : 'bottom'}
      width='100%'
      background={isNew ? colors.AQUA_MARINE10 : colors.WHITE}
      onClick={onClick}
    >
      <Box pad={{ bottom: 'small' }} align='center' direction='row' justify='start' gap='small'>
        <Text size='small' color={colors.LIGHT_NAVY_BRIGHT}>
          {_.truncate(itemName, { length: 30 })}
        </Text>
        <FontAwesomeIcon icon={faCircle} size={6} color={colors.TEXT} />
        <Text size='small' color={colors.DARK_GRAY_TWO}>
          {title}
        </Text>
      </Box>
      <Box direction='row' flex={false} fill='horizontal'>
        <Box flex={false} pad={{ left: '5px', right: 'xsmall' }}>
          <Avatar size='32px' src={_.get(profile, 'avatarSmall')} background={colors.LIGHT_NAVY_BRIGHT}>
            <Text color={colors.WHITE}>{getInitials(profile)}</Text>
          </Avatar>
        </Box>
        <Box fill='horizontal'>
          <Box
            fill='horizontal'
            direction='row'
            align='center'
            justify='between'
            pad={{ left: '10px', right: 'small', bottom: 'xxsmall' }}
          >
            <Box gap='xxsmall' direction='row'>
              <Text size='small' weight={600} color={colors.LIGHT_NAVY_BRIGHT}>
                {getName(profile)}
              </Text>
              <Text size='small' color={colors.TEXT}>
                {strAction}
              </Text>
            </Box>
            <Box gap='small' direction='row'>
              {type === messageType.NOTE && <Text size='xsmall' color={colors.ANOTHER_GREY} weight={600}>Internal note</Text>}
              <Text size='xsmall' color={colors.ANOTHER_GREY}>
                {moment(timestamp).fromNow()}
              </Text>
            </Box>
          </Box>
          <Box pad={{ left: '10px', right: 'small' }}>
            <Text size='small' color={colors.ANOTHER_GREY}>
              {_.truncate(text, { length: 100 })}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

InboxMessage.propTypes = {
  onClick: PropTypes.func,
  isNew: PropTypes.bool,
  title: PropTypes.string,
  timestamp: PropTypes.number,
  text: PropTypes.string,
  profile: PropTypes.object,
  strAction: PropTypes.oneOf(['commented', 'annotated', 'mentioned you']),
  itemName: PropTypes.string
}

export default InboxMessage
