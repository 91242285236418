import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { auth, db, firestore, fieldValue, generateId } from 'constants/firebase'
import { addListener } from 'controllers/listeners'
import { clearMessages, receiveMessages, receiveMessagesWeb } from 'model/actions/messagesAC'
import { MESSAGES_PER_REQUEST, PRIVATE_CHAT_CHANNEL } from 'constants/index'
import store from 'model/store'
import { receiveChannels, receiveChannel } from 'model/actions/channelsAC'
import deliveryStatus from 'shared/constants/deliveryStatus'
import { toTimestamp } from 'shared/utils/date'
import messageType from 'constants/messageType'

export const fetchChannels = accountId => {
  try {
    console.log('fetch channels, accountId:', accountId)
    const unsubscribe = db
      .collection('channels')
      .where('accounts', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const channels = {}
          const changes = sn.docChanges()
          const userId = auth.currentUser.uid
          const updateReceiveTime = []
          sn.forEach(doc => {
            const channel = doc.data()
            const lastMessageTime = toTimestamp(_.get(channel, 'lastMessage.timestamp'))
            const receiveTime = toTimestamp(_.get(channel, ['receivedBy', userId]))
            if (lastMessageTime && (!receiveTime || receiveTime < lastMessageTime)) {
              updateReceiveTime.push({
                channelKey: doc.id,
                lastMessageTime
              })
            }
            const creatorAccountId = _.get(channel, 'creatorAccountId')
            const invitedAccountId = _.get(channel, 'invitedAccountId')
            // console.log('channel received', channel)
            let partnerAccountId = creatorAccountId !== accountId ? creatorAccountId : invitedAccountId
            if (_.isNil(partnerAccountId)) partnerAccountId = PRIVATE_CHAT_CHANNEL
            _.set(channel, 'id', doc.id)
            _.set(channel, 'partnerAccountId', partnerAccountId)
            // console.log('res channel', channel)
            _.set(channels, doc.id, channel)
          })

          let receiveTimeChanges = false

          for (const change of changes) {
            // console.log(`Change of ${change.doc.id}, type: ${change.type}.`)
            if (change.type === 'modified') {
              const state = store.getState()
              const receiveTimePrev = toTimestamp(_.get(state, ['channels', change.doc.id, 'receivedBy', userId]))
              const receiveTimeNow = toTimestamp(_.get(change.doc.data(), ['receivedBy', userId]))
              const isChanged = receiveTimePrev !== receiveTimeNow
              receiveTimeChanges = receiveTimeChanges || isChanged
              // console.log('channel received time changed', isChanged, change.doc.id, receiveTimePrev, '->', receiveTimeNow)
            }
          }
          // console.log('updateReceiveTime', updateReceiveTime)
          // console.log('my receive time chanegs', receiveTimeChanges)
          // console.log('channels', channels)
          store.dispatch(receiveChannels(channels))
          if (!receiveTimeChanges) {
            updateChannelsReceiveTime(updateReceiveTime)
          }
        },
        err => {
          console.log(`fetch channel error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('channels', unsubscribe)
  } catch (e) {
    console.log('fetch channels error', e)
    Sentry.captureException(e)
  }
}

export const updateMessagesDeliveryStatus = (messages, channelKey) => {
  const userId = auth.currentUser.uid
  const state = store.getState()
  const receivedBy = _.get(state, ['channels', channelKey, 'receivedBy'], {})
  for (const m of messages) {
    const messageTime = m.timestamp
    for (const uid in receivedBy) {
      if (uid !== userId) {
        const t = toTimestamp(receivedBy[uid])
        if (t >= messageTime) {
          // console.log('set status DELIVERED to message', m)
          _.set(m, 'status', deliveryStatus.DELIVERED)
        }
      }
    }
  }
}

const updateChannelsReceiveTime = updateReceiveTime => {
  // console.log('updateChannelsReceiveTime', updateReceiveTime)
  const userId = auth.currentUser.uid
  for (const updInfo of updateReceiveTime) {
    // if (updInfo.channelKey === 'vP6cv6mIKHX1Opo5BjWKoKwI8bl2_-LuhGlqd_83HwwTExxQZ_QitsY97iu3TLrymnV5jN4KIsrQQ2') {
    const upd = { [`receivedBy.${userId}`]: firestore.Timestamp.fromMillis(updInfo.lastMessageTime) }
    // console.log('update the channel time', upd)
    db.collection('channels')
      .doc(updInfo.channelKey)
      .update(upd)
    // }
  }
}

export function startTyping (channelKey) {
  return async function (dispatch, getState) {
    try {
      // console.log('startTyping', channelKey)
      const upd = { [`typing.${auth.currentUser.uid}`]: fieldValue.serverTimestamp() }
      await db
        .collection('channels')
        .doc(channelKey)
        .update(upd)
    } catch (e) {
      console.log('startTyping, error', e)
    }
  }
}

export function fetchChat (channelKey) {
  return async function (dispatch, getState) {
    try {
      // console.log('fetch chat', channelKey)

      // offListener(key)
      // const messagesPath = `channels/${projectId}/${channelId}/messages`
      const state = getState()
      const currentMessages = _.get(state, ['messages', channelKey], [])
      const channel = _.get(state, ['channels', channelKey], {})
      const currentAmount = currentMessages.length
      const amount = MESSAGES_PER_REQUEST + currentAmount
      // if (currentAmount > 0 && currentAmount < MESSAGES_PER_REQUEST) {
      //   console.log('skip fetching messages')
      //   return null
      // }
      console.log('fetchChat', channelKey, 'currentAmount', currentAmount)
      const unsubscribe = db
        .collection('channels')
        .doc(channelKey)
        .collection('messages')
        .orderBy('timestamp', 'desc')
        .limit(amount)
        .onSnapshot(
          sn => {
            const res = []
            sn.forEach(doc => {
              const message = doc.data()
              if (_.isNil(message.timestamp)) message.timestamp = _.now()
              message.timestamp = toTimestamp(message.timestamp)
              res.push(message)
            })
            // console.log('channel messages:', accountId, projectId, channelId)
            // console.log('receive channel messages: channelKey', channelKey, 'amount:', res.length)
            // if (isWeb) _.reverse(res)
            dispatch(receiveMessages(res, channelKey))
          },
          err => console.log('receive channel messages error', err)
        )
      const unsubscribeKey = _.get(channel, 'accounts', []).length === 1 ? 'chat_private' : 'chat'
      addListener(unsubscribeKey, unsubscribe)
    } catch (e) {
      Sentry.captureException(e)
      console.log('fetchProject error:', e.message)
    }
  }
}

export function clearChat (channelKey) {
  return function (dispatch) {
    dispatch(clearMessages(channelKey))
  }
}

export function setReadByTime (channelKey, time) {
  return async function (dispatch, getState) {
    try {
      const userId = auth.currentUser.uid
      console.log('update read at time', channelKey, userId, 'time', time)
      const upd = { [`readBy.${userId}`]: firestore.Timestamp.fromMillis(time) }
      await db
        .collection('channels')
        .doc(channelKey)
        .update(upd)
    } catch (e) {
      console.log('setReadByTime error', e)
    }
  }
}

export const getChannelImages = async channelKey => {
  const messagesSN = await db
    .collection(`channels/${channelKey}/messages`)
    .where('type', '==', messageType.IMAGE)
    .get()
  return _.map(messagesSN.docs, doc => doc.data())
}

export const getChannelFiles = async channelKey => {
  const messagesSN = await db
    .collection(`channels/${channelKey}/messages`)
    .where('type', '==', messageType.FILE)
    .get()
  return _.map(messagesSN.docs, doc => doc.data())
}

export const sendMessage = params => {
  const workOrderId = _.get(params, 'workOrderId')
  if (_.isNil(workOrderId)) {
    console.warn('message does not contain workOrderId', params)
    return null
  }
  const id = generateId()
  const m = { ...params, timestamp: fieldValue.serverTimestamp(), id }
  db.collection('messages')
    .doc(workOrderId)
    .collection('messagesList')
    .doc(id)
    .set(m)
}

export const fetchChannelWeb = userId => {
  const unsubscribe = db
    .collection('messagesStatus')
    .doc(userId)
    .onSnapshot(
      sn => store.dispatch(receiveChannel(sn.data() || null)),
      err => {
        console.log('fetchChannelWeb onSnapshot error: ', err)
        Sentry.captureException(err)
      }
    )
  addListener('channelWeb', unsubscribe)
  return unsubscribe
}

export const fetchMessagesWeb = (workOrderId, accountId) => {
  const unsubscribe = db
    .collection('messages')
    .doc(workOrderId)
    .collection('messagesList')
    .where('accounts', 'array-contains', accountId)
    .onSnapshot(
      sn => {
        const messages = _.map(sn.docs, doc => ({ ...doc.data(), id: doc.id }))
        store.dispatch(receiveMessagesWeb(messages))
      },
      err => {
        console.log('workOrderMessages onSnapshot error: ', err)
        Sentry.captureException(err)
      }
    )
  addListener('workOrderMessages', unsubscribe)
}

export const fetchMessagesWebDashboard = async (projectId, accountId) => {
  const unsubscribe = db
    .collectionGroup('messagesList')
    .where('projectId', '==', projectId)
    .where('accounts', 'array-contains', accountId)
    .where('type', '==', 'sub')
    .onSnapshot(
      sn => {
        const data = _.map(sn.docs, doc => ({ ...doc.data(), id: doc.id }))
        store.dispatch(receiveMessagesWeb(data))
      },
      err => {
        console.log('fetchMessagesWebDashboard onSnapshot error: ', err)
        Sentry.captureException(err)
      }
    )
  addListener('messagesDashboard', unsubscribe)
}

export const updateAnnotationSeenStatus = channelKey => {
  const userId = auth.currentUser.uid
  db.collection('messagesStatus')
    .doc(userId)
    .set({ [channelKey]: fieldValue.serverTimestamp() }, { merge: true })
}

export const updateSeenStatus = (channelKey, lastMessage) => (dispatch, getState) => {
  const state = getState()
  const curValue = toTimestamp(_.get(state, ['channelWeb', channelKey]))
  const lastMessageTime = toTimestamp(_.get(lastMessage, 'timestamp'))
  const lastMessageUserId = _.get(lastMessage, 'userId')
  const userId = _.get(auth, 'currentUser.uid')
  if (_.isEmpty(lastMessage)) {
    console.log('SKIP updateSeenStatus, last message is not passed')
  } else if (userId !== lastMessageUserId && curValue < lastMessageTime) {
    console.log('update seen status', channelKey)
    console.log(
      'lastMessageUserId',
      lastMessageUserId,
      'last message seen',
      curValue < lastMessageTime,
      'curValue',
      curValue,
      'lastMessageTime',
      lastMessageTime,
      'lastMessage',
      lastMessage
    )
    db.collection('messagesStatus')
      .doc(userId)
      .set({ [channelKey]: fieldValue.serverTimestamp() }, { merge: true })
  } else {
    console.log(
      'SKIP updateSeenStatus, lastMessage is mine',
      userId === lastMessageUserId,
      'last message seen',
      curValue < lastMessageTime,
      'curValue',
      curValue,
      'lastMessageTime',
      lastMessageTime,
      'lastMessage',
      lastMessage
    )
  }
}

export const getInvitedUserProfile = (msg, workOrder) => {
  const accountId = _.get(msg, 'accountId')
  const invId = _.get(msg, 'userId')
  const c = _.find(_.get(workOrder, ['invitations', accountId, 'sendTo']), user => user.invId === invId)
  return { name: _.get(c, 'name') }
}

export const getInvitedAccountProfile = (msg, workOrder) => {
  const accountId = _.get(msg, 'accountId')
  const name = _.get(workOrder, ['invitations', accountId, 'companyName'])
  return { name }
}
