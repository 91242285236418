const BID = 'BID'
const IMAGE = 'IMAGE'
const TEXT = 'TEXT'
const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
const GC_APPOINTMENT = 'GC_APPOINTMENT'
const BID_INVITE = 'BID_INVITE'
const FILE = 'FILE'

export default {
  BID,
  PAYMENT_REQUEST,
  IMAGE,
  TEXT,
  GC_APPOINTMENT,
  BID_INVITE,
  FILE
}
