import React from 'react'
const Ctx = React.createContext({
  onCreateProject: () => null,
  openConfirmationModal: () => null,
  closeConfirmationModal: () => null,
  openBidPreviewModal: () => null,
  commentsInboxRef: null,
  openCommentsInbox: () => null,
  closeCommentsInbox: () => null,
  activeMenuItem: null,
  setActiveMenuItem: () => null,
  sideMenuOpen: false,
  toggleSideMenu: () => null,
  openInviteSubPanel: () => null,
  openBidInviteModal: () => null,
  openSubInfo: () => null,
  openInvoiceModal: () => null,
  openLeaveAccountModal: () => null
})

export default Ctx
