import React, { useContext, useState } from 'react'
import { Box, Text, Accordion, AccordionPanel } from 'grommet'

// import ClientName from 'webPages/createBid/proposalPreviewPanel/ClientName'
import Trade from 'webPages/createBid/proposalPreviewPanel/Trade'
import Deposit from 'webPages/createBid/proposalPreviewPanel/Deposit'
import ContractTemplate from 'webPages/createBid/proposalPreviewPanel/ContractTemplate'
import ElectronicalSign from 'webPages/createBid/proposalPreviewPanel/ElectronicalSign'
import ScheduleOfPayments from 'webPages/createBid/proposalPreviewPanel/ScheduleOfPayments'
import Attachments from 'webPages/createBid/proposalPreviewPanel/Attachments'
import ImportantDates from 'webPages/createBid/proposalPreviewPanel/ImportantDates'
import SendTo from 'webPages/createBid/proposalPreviewPanel/SendTo'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import { getName } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'

const ProposalPreviewPanel = () => {
  const { getGCAccountProfile } = useContext(CreateBidContext)
  const [activeIndex, setActiveIndex] = useState([0, 1, 2, 3])

  const gcProfile = getGCAccountProfile()

  const renderPanelLabel = label => {
    return (
      <Text margin={{ left: 'small' }} size='large' color={colors.BROWN_GREY_TWO} weight={600}>
        {label}
      </Text>
    )
  }

  const header = (
    <Box
      border='bottom'
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      height='60px'
      align='center'
      justify='between'
      flex={{ shrink: 0 }}
      direction='row'
    >
      <Box>
        <Text size='large' weight={600}>
          Bid details
        </Text>
        <Text color={colors.ANOTHER_GREY} size='small'>{`prepared for ${getName(gcProfile)}`}</Text>
      </Box>
      <Trade />
    </Box>
  )
  return (
    <Box fill='vertical' width='400px' border='start' customStyle={`box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)`}>
      {header}
      <Box overflow='auto'>
        <SendTo />
        <Accordion flex={{ shrink: 0 }} multiple activeIndex={activeIndex} onActive={setActiveIndex}>
          <AccordionPanel label={renderPanelLabel('Attachments')} pad={{ top: 'medium' }}>
            <Attachments />
          </AccordionPanel>
          <AccordionPanel label={renderPanelLabel('Dates')} pad={{ top: 'medium' }}>
            <ImportantDates />
          </AccordionPanel>
          <AccordionPanel label={renderPanelLabel('Contract')} pad={{ top: 'medium' }}>
            <ContractTemplate />
            <ElectronicalSign />
          </AccordionPanel>
          <AccordionPanel label={renderPanelLabel('Payments')} pad={{ top: 'medium' }}>
            <Deposit />
            <ScheduleOfPayments />
          </AccordionPanel>
        </Accordion>
      </Box>
    </Box>
  )
}

export default ProposalPreviewPanel
