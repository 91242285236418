import {
  LEFT_PANE_WIDTH_K,
  SIDE_MENU_WIDTH_K
} from 'shared/constants/size'

export const getViewports = (state, props) => {
  const sideMenuWidth = state.viewport.width / SIDE_MENU_WIDTH_K
  const leftPaneWidth = state.viewport.width / LEFT_PANE_WIDTH_K
  const rightPaneWidth = state.viewport.width - sideMenuWidth - leftPaneWidth
  // console.log('sideMenuWidth', sideMenuWidth)
  // console.log('leftPaneWidth', leftPaneWidth)
  // console.log('rightPaneWidth', rightPaneWidth)
  return (
    props.viewport
    ? {
      screenViewport: state.viewport,
      viewport: props.viewport,
      sideMenuWidth,
      leftPaneWidth,
      rightPaneViewport: {
        ...state.viewport,
        width: rightPaneWidth
      }
    }
    : {
      screenViewport: state.viewport,
      viewport: state.viewport,
      sideMenuWidth,
      leftPaneWidth,
      rightPaneViewport: {
        ...state.viewport,
        width: rightPaneWidth
      }
    }
    )
}
