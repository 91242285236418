import _ from 'lodash'
import generate from 'firebase-auto-ids'
import Sentry from 'shared/utils/sentry'

import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveSupplyOrders } from 'model/actions/supplyOrdersAC'
import { auth, db } from 'constants/firebase'
import { saveGooglePlaceSupplier } from 'controllers/suppliers'

export function setSupplyOrderSeen (supplyOrderId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = { [`seenBy.${userId}`]: _.now() }
    await db
      .collection('supplyOrders')
      .doc(supplyOrderId)
      .update(upd)
  }
}

export const fetchSupplyOrders = accountId => {
  try {
    console.log('fetch supply orders, accountId:', accountId)
    const unsubscribe = db
      .collection('supplyOrders')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('supply orders received:', _.size(res))
          store.dispatch(receiveSupplyOrders(res))
        },
        err => {
          console.log('supplyOrdersError', err)
          Sentry.captureException(err)
        }
      )
    addListener('supplyOrders', unsubscribe)
  } catch (e) {
    console.log('fetchSupplyOrders error', e)
    Sentry.captureException(e)
  }
}

function getDbGooglePlace (suppliers, placeId) {
  return _.find(suppliers, sup => {
    const dbSupPlaceId = _.get(sup, 'address.placeId')
    const isGooglePlace = _.get(sup, 'googlePlace')
    if (isGooglePlace && dbSupPlaceId && placeId) {
      return dbSupPlaceId === placeId
    }
    return false
  })
}

export function saveSupplyOrder (so) {
  return async function (dispatch, getState) {
    try {
      const state = getState()
      const userId = auth.currentUser.uid
      const accountId = _.get(state, 'user.currentAccountId')
      const projectId = _.get(so, 'project.id')
      const enteredAddress = _.get(so, 'enteredAddress')
      const orderNumber = _.get(so, 'orderNumber')
      const supplier = _.get(so, 'supplier', {})
      const suppliers = _.get(state, 'suppliers', {})
      if (accountId && (projectId || enteredAddress) && so) {
        const dbso = {
          createdAt: _.now(),
          id: generate(_.now()),
          accountId: accountId,
          termId: _.get(so, 'term.id'),
          supplier: supplier.id,
          createdBy: userId
        }
        if (enteredAddress) {
          dbso.addressInfo = enteredAddress
        }
        if (projectId && !enteredAddress) {
          dbso.projectId = projectId
        }
        if (orderNumber) {
          dbso.orderNumber = orderNumber
        }
        if (supplier.googlePlace) {
          const supPlaceId = _.get(supplier, 'address.placeId')
          const dbSupplier = getDbGooglePlace(suppliers, supPlaceId)
          let supplierId
          if (dbSupplier) {
            supplierId = _.get(dbSupplier, 'id')
          } else {
            supplierId = generate(_.now())
            await saveGooglePlaceSupplier({ accountId, id: supplierId, ...supplier })
          }
          dbso.supplier = supplierId
        }
        console.log('DBSO: ', dbso)
        await db
          .collection('supplyOrders')
          .doc(dbso.id)
          .set(dbso)
      }
    } catch (e) {
      console.log('saveSupplyOrder error:', e.message)
    }
  }
}
