import React from 'react'
import { Box, Button, Text } from 'grommet'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'
import { setInvId } from 'controllers/auth'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import signInMode from 'constants/signInMode'

const ResolveInvitePlaceholder = ({
  address,
  filesAmount,
  gcName,
  invitedBy,
  tradeName,
  desc,
  invId
}) => {
  const onHandleAccept = async v => {
    await setInvId(invId)
    universalNavigation.push(screens.ENTER_PHONE, { mode: signInMode.BID_INVITE })
  }

  const renderRow = (key, value) => {
    return (
      <Box direction='row' align='center' justify='between' fill border='bottom' pad={{ vertical: 'medium' }}>
        <Text size='16px' color={colors.ANOTHER_GREY}>
          {key}
        </Text>
        <Text size='large' weight={600} color={colors.TEXT}>
          {value}
        </Text>
      </Box>
    )
  }

  const renderButtons = () => {
    return (
      <Box
        fill
        align='center'
        direction='row'
        gap='medium'
        margin={{ top: 'large', bottom: 'large' }}
        height={{ min: '48px' }}
      >
        <Box align='center' height={{ min: '48px' }} fill>
          <Button fill primary color={colors.AQUA_MARINE} onClick={() => onHandleAccept()} label='Continue' />
        </Box>
      </Box>
    )
  }

  return (
    <Box pad='medium' align='center'>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box
        align='center'
        margin={{ top: 'medium' }}
        pad={{ top: 'medium' }}
        width={{ max: '520px' }}
        flex={{ grow: 1, shrink: 0 }}
      >
        <Box direction='row' align='center' pad={{ bottom: 'large' }} gap='xsmall'>
          <Text color={colors.LIGHT_NAVY_BRIGHT} weight={700} size='xxlarge' textAlign='center'>
            You have been invited to bid!
          </Text>
        </Box>
        {renderRow('Contractor', gcName)}
        {renderRow('Project location', address)}
        {renderRow('Type of work', tradeName)}
        {renderRow('Invited by', invitedBy)}
        <Box
          align='start'
          width='100%'
          border='bottom'
          pad={{ vertical: 'medium' }}
          gap='medium'
          flex={{ grow: 0, shrink: 0 }}
        >
          <Text size='16px' color={colors.ANOTHER_GREY}>
            Description of work
          </Text>
          <Text size='large' weight={600} color={colors.TEXT}>
            {desc}
          </Text>
        </Box>
        {filesAmount > 0 && renderRow('Files', `${filesAmount} plan file(s) attached`)}
        {renderButtons()}
      </Box>
    </Box>
  )
}

export default ResolveInvitePlaceholder
