import React from 'react'
import { Box, Text } from 'grommet'
import PropTypes from 'prop-types'
// import _ from 'lodash'

import DropContentContainer from 'components/DropContentContainer'
import colors from 'shared/constants/colors'
import newLineItemAction, { actionTitle } from 'shared/constants/newLineItemAction'

const CancelRemovingRequestDropContent = ({ onAction, companyName, dropContentProps }) => {
  return (
    <DropContentContainer
      boxProps={{ pad: { top: 'xsmall', bottom: 'small' }, width: { min: '300px' }, flex: true }}
      {...dropContentProps}
    >
      <Box>
        <Text margin={{ vertical: 'small', left: 'small' }} color={colors.ANOTHER_GREY}>
          {`You have requested the item removal from ${companyName}`}
        </Text>
      </Box>
      <Box
        pad='small'
        onClick={() => onAction(newLineItemAction.CANCEL_REMOVAL_REQUEST)}
        hoverIndicator={{ color: colors.TABLE_HOVER }}
      >
        <Text margin={{ left: 'small' }} size='small'>
          {actionTitle(newLineItemAction.CANCEL_REMOVAL_REQUEST, companyName)}
        </Text>
      </Box>
    </DropContentContainer>
  )
}

CancelRemovingRequestDropContent.propTypes = {
  onAction: PropTypes.func.isRequired,
  companyName: PropTypes.string
}

export default CancelRemovingRequestDropContent
