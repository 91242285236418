import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Avatar, Box, Text, ResponsiveContext } from 'grommet'

import Logo from 'components/logos/LogoOnLight'
import universalNavigation from 'utils/universalNavigation'
import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import HamburgerButton from 'webPages/layout/HamburgerButton.web'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { getInitials } from 'shared/utils/stringUtils'
import { signOut } from 'controllers/auth'
import CommentsInboxButton from 'components/chat/CommentsInboxButton'
import { createCustomerPortalSession } from 'controllers/account'
import DropDownMenu from 'components/DropDownMenu'
// import TakeoffsButton from 'components/takeoffs/TakeoffsButton'
// import ScopesButton from 'components/scopes/ScopesButton'
// import PlaceOrderButton from 'components/scopes/PlaceOrderButton'

const menuActionId = {
  SETTINGS: 'SETTINGS',
  INVITE_TEAMMATES: 'INVITE_TEAMMATES',
  HELP: 'HELP',
  SIGN_OUT: 'SIGN_OUT',
  BILLING: 'BILLING'
}
const menuActions = [
  { id: menuActionId.SETTINGS, label: 'Settings' },
  { id: menuActionId.BILLING, label: 'Subscription and billing' },
  { id: menuActionId.INVITE_TEAMMATES, label: 'Invite Teammates' },
  { id: menuActionId.HELP, label: 'Help & Support' },
  { id: menuActionId.SIGN_OUT, label: 'Sign out' }
]

const NavBarGC = ({ children, fill, border, pad, projectId, openTakeoffsPanel }) => {
  const size = useContext(ResponsiveContext)
  const userProfile = useSelector(getCurrentUserProfile)
  const account = useSelector(state => state.account)
  const avatar = _.get(userProfile, 'avatarSmall')
  const subscription = _.get(account, 'subscription')
  // const project = useSelector(state => _.get(state, ['projects', projectId]))

  // const scopesOrdered = _.has(project, 'orderDetails.scopes.paid')
  // const takeoffsOrdered = _.has(project, 'orderDetails.takeoffs.paid')
  // const orderPlaced = scopesOrdered || takeoffsOrdered

  const toCustomerPortal = async () => {
    const res = await createCustomerPortalSession()
    if (_.has(res, 'sessionUrl')) {
      window.open(res.sessionUrl)
    }
  }

  const onMenuAction = actionId => {
    console.log('onMenuAction', actionId)
    switch (actionId) {
      case menuActionId.SETTINGS:
        universalNavigation.navigate(screens.SETTINGS)
        break
      case menuActionId.INVITE_TEAMMATES:
        universalNavigation.navigate(screens.SETTINGS, { activeIndex: 2 })
        break
      case menuActionId.SIGN_OUT:
        signOut()
        break
      case menuActionId.HELP:
        window.open('http://help.bidlevel.co/en/')
        break
      case menuActionId.BILLING: {
        const status = _.get(subscription, 'status')
        if (status === 'active' || status === 'trialing') {
          toCustomerPortal()
        } else {
          universalNavigation.navigate(screens.SETTINGS, { activeIndex: 4 })
        }
        break
      }
    }
  }

  // const renderPlaceOrderButton = () => {
  //   if (projectId && !orderPlaced) {
  //     return <PlaceOrderButton projectId={projectId} />
  //   }
  // }

  // const renderTakeoffsButton = () => {
  //   if (projectId && orderPlaced) {
  //     return <TakeoffsButton projectId={projectId} onClick={openTakeoffsPanel} />
  //   }
  // }

  // const renderScopesButton = () => {
  //   if (projectId && orderPlaced) {
  //     return <ScopesButton projectId={projectId} />
  //   }
  // }

  if (size === 'small') {
    return (
      <Box pad='medium'>
        <Logo height='26px' />
      </Box>
    )
  } else {
    return (
      <Box direction='row' align='center' justify='between' fixed fill={fill} border={border} pad={pad}>
        <Box align='center'>
          <HamburgerButton />
        </Box>
        <Box direction='row' fill={fill}>
          {children}
          <Box direction='row' gap='medium' margin={{ left: 'auto' }} align='center'>
            {/* {renderPlaceOrderButton()} */}
            {/* {renderScopesButton()} */}
            {/* {renderTakeoffsButton()} */}
            <CommentsInboxButton />
            <DropDownMenu
              options={menuActions}
              dropContentProps={{ boxProps: { width: { min: '200px' } }, optionLabelProps: { textAlign: 'center' } }}
              dropAlign={{ top: 'bottom', right: 'right' }}
              onOptionClick={onMenuAction}
              dropProps={{ margin: { top: 'small' } }}
            >
              <Box direction='row' align='center'>
                <Avatar size='29px' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
                  <Text color={colors.WHITE}>{getInitials(userProfile)}</Text>
                </Avatar>
              </Box>
            </DropDownMenu>
          </Box>
        </Box>
      </Box>
    )
  }
}

NavBarGC.propTypes = {
  projectId: PropTypes.string,
  openTakeoffsPanel: PropTypes.func
}

export default NavBarGC
