export const EXCLUDED = 'EXCLUDED'
export const INCLUDED = 'INCLUDED'
export const PENDING = 'PENDING'
export const HIDE = null

export default {
  EXCLUDED,
  INCLUDED,
  PENDING,
  HIDE
}
