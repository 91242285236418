import React, { useContext } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTrashAlt, faCloudDownload as faCloudDownloadAltLight } from '@fortawesome/pro-light-svg-icons'
import { faCloudDownloadAlt as faCloudDownloadAltSolid } from '@fortawesome/pro-solid-svg-icons'
import { List, Text, Box } from 'grommet'
import moment from 'moment'

import colors from 'shared/constants/colors'
import { toTimestamp, stringDiff } from 'shared/utils/date'
import FilesPickerButton from 'components/web/FilesPickerButton'
import { deleteFile } from 'shared/controllers/storage'
import { downloadFile } from 'controllers/workOrder'
import LayoutContext from 'webPages/layout/LayoutContext'
import Radio from 'components/Radio'

const CreateBidInviteFiles = ({ files, setFiles, project, workOrderId }) => {
  const { openToast } = useContext(LayoutContext)
  const projectFiles = _.get(project, 'attachments', {})

  const toggleFile = f => e => {
    console.log('toggle file', f.id)
    if (_.has(files, f.id)) {
      const newFiles = { ...files }
      _.unset(newFiles, f.id)
      console.log('files', files, 'newFiles', newFiles)
      setFiles(newFiles)
    } else {
      setFiles({
        ...files,
        [f.id]: f
      })
    }
    e.preventDefault()
  }

  const renderRadio = f => {
    return <Radio checked={_.has(files, f.id)} onChange={toggleFile(f)} />
  }

  const onDeleteFilePress = fId => () => {
    const p = `/workOrders/${workOrderId}/${fId}`
    deleteFile(p)
    const newFiles = { ...files }
    _.unset(newFiles, fId)
    setFiles(newFiles)
  }

  const renderTrashButton = f => {
    return (
      <Box
        onClick={onDeleteFilePress(f.id)}
        customStyle={`
          > svg + svg,
          :hover > svg {
            display: none;
          }
          :hover > svg + svg {
            display: inherit;
          }
        `}
      >
        <FontAwesomeIcon icon={faTrashAlt} size={18} color={colors.VERY_LIGHT_PINK} />
        <FontAwesomeIcon icon={faTrashAlt} size={18} color={colors.LIGHT_NAVY_BRIGHT} />
      </Box>
    )
  }

  const handleDownload = async file => {
    const res = await downloadFile(file.url, file.name)
    if (!res) {
      openToast(
        <Box background={colors.CORAL_TWO} justify='center' align='center' pad='small' margin='small'>
          <Text color={colors.TEXT}>Error: file not found</Text>
        </Box>
      )
    }
  }

  const renderDownloadButton = f => {
    return (
      <Box
        onClick={() => handleDownload(f)}
        customStyle={`
          > svg + svg,
          :hover > svg {
            display: none;
          }
          :hover > svg + svg {
            display: inherit;
          }
        `}
      >
        <FontAwesomeIcon icon={faCloudDownloadAltLight} size={16} color={colors.LIGHT_NAVY_BRIGHT} />
        <FontAwesomeIcon icon={faCloudDownloadAltSolid} size={16} color={colors.LIGHT_NAVY_BRIGHT} />
      </Box>
    )
  }

  const renderFileRow = (f, index) => {
    return (
      <Box direction='row' align='center' key={index}>
        <Box width='360px'>
          <Text size='small' color='#243659'>
            {f.name}
          </Text>
        </Box>
        <Box width='180px' margin={{ left: 'medium' }}>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>
            {`uploaded ${stringDiff(moment(), moment(f.createdAt))}`}
          </Text>
        </Box>
        <Box width='80px' margin={{ left: 'medium' }}>
          <Text size='small'>{numeral(f.size).format('0.0 b')}</Text>
        </Box>
        <Box flex direction='row' justify='end' margin={{ left: 'medium' }}>
          {renderDownloadButton(f)}
        </Box>
        <Box flex direction='row' justify='end' margin={{ left: 'medium' }}>
          {_.has(projectFiles, f.id) ? renderRadio(f) : renderTrashButton(f)}
        </Box>
      </Box>
    )
  }

  const onFilesPicked = newFiles => {
    console.log('onFilesPicked', newFiles)
    setFiles({
      ...files,
      ..._.keyBy(newFiles, 'id')
    })
  }

  let data = _.values(projectFiles)
  _.forEach(files, f => {
    if (!_.has(projectFiles, f.id)) data.push(f)
  })
  const sortedData = _.sortBy(data, f => toTimestamp(_.get(f, 'createdAt')))

  return (
    <React.Fragment>
      <List margin={{ top: 'small' }} pad='small' children={renderFileRow} data={sortedData} />
      <FilesPickerButton onComplete={onFilesPicked} storagePath={`/workOrders/${workOrderId}/`} />
    </React.Fragment>
  )
}

CreateBidInviteFiles.propTypes = {
  workOrderId: PropTypes.string
}

export default CreateBidInviteFiles
