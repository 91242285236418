import React, { Component } from 'react'
import { Layer, Button, Box, Text, TextArea, Card, CardHeader, CardBody, CardFooter } from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'

import colors from 'shared/constants/colors'

class DeclineLineItemModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      invId: undefined,
      item: undefined,
      companyName: undefined,
      comment: ''
    }
  }

  onCommentChange = event => this.setState({ comment: event.target.value })

  open = (invId, item, companyName) => {
    this.setState({ invId, item, companyName, visible: true, comment: '' })
  }

  close = () => {
    this.setState({ visible: false })
  }

  removeButtonClick = () => {
    const { comment, item, invId } = this.state
    const { onDecline } = this.props
    onDecline(invId, item, comment)
    this.close()
  }

  render () {
    const { visible, companyName, item } = this.state

    let headerTitle = `Line item to be removed from ${companyName}`
    let headerSubtitle
    let textAreaPlaceholder = 'Add comment (optional)'
    if (!_.get(item, 'optional')) {
      headerTitle = `Are you sure you would like to request removal of the following line item from ${companyName}?`
      headerSubtitle = `${companyName} bid price will be updated upon their approval of this removal.`
      textAreaPlaceholder = 'Add message (optional)'
    }

    // console.log('projectAddress', address, 'timeZone', timeZone)
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card height='medium' width='large'>
            <CardHeader pad='medium'>
              <Box gap='small'>
                <Text size='xlarge' color={colors.BLACK} weight={600}>
                  {headerTitle}
                </Text>
                {headerSubtitle && <Text color={colors.DARK_GRAY_TWO}>{headerSubtitle}</Text>}
              </Box>
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium' }} overflow='scroll' gap='small'>
              <Text margin={{ top: 'small' }} color={colors.LIGHT_NAVY_BRIGHT}>
                {_.get(item, 'name')}
              </Text>
              <TextArea placeholder={textAreaPlaceholder} resize='vertical' fill onChange={this.onCommentChange} />
            </CardBody>
            <CardFooter align='center' justify='end' pad='medium'>
              <Button plain label={<Text color={colors.ANOTHER_GREY}>Cancel</Text>} onClick={this.close} />
              <Button primary label='Remove' color={colors.CORAL_TWO} onClick={this.removeButtonClick} />
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

DeclineLineItemModal.propTypes = {
  onDecline: PropTypes.func
}

export default DeclineLineItemModal
