import * as types from 'model/actionTypes'
import _ from 'lodash'
import suppliersAccountIds from 'shared/constants/suppliersAccountIds'

const initialState = null

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_SUPPLIERS:
      return { ...state, ..._.get(action, 'suppliers', {}) }
    case types.CLEAR:
      return _.omitBy(state, s => _.get(s, 'accountId') !== suppliersAccountIds.PLATFORM)
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
