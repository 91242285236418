import React from 'react'
import { connect } from 'react-redux'
import { Box, Text, Layer, Button, CheckBox, FormField, ThemeContext } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'

import colors from 'shared/constants/colors'
import Loading from 'shared/components/Loading'
import { importLineItems } from 'controllers/project'

const DEFAULT_FIELDS = [
  { name: 'name', label: 'Name', required: true },
  { name: 'id', label: 'ID', required: true },
  { name: 'type', label: 'Line item type', required: false },
  { name: 'description', label: 'Line item description', required: false },
  { name: 'required', label: 'Required', required: false },
  { name: 'labor', label: 'Labor Only', required: false },
  { name: 'quantity', label: 'QTY', required: false },
  { name: 'quantityType', label: 'Unit', required: false }
]
const DEFAULT_FIELDS_NAMES = ['name', 'id', 'type', 'description', 'required', 'labor', 'quantity', 'quantityType']
// const BOOL_VALUES = ['true', 'false']

class ImportCsvModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      importedRows: {},
      duplicates: [],
      filename: []
    }
  }

  open = projectId => this.setState({ show: true, projectId, duplicates: [], upd: [], filename: null })

  close = () => this.setState({ show: false, projectId: null, duplicates: [], upd: [], filename: null })

  // setSize = size => this.setState({ size })

  // setDuplicates = (duplicates, upd, filename) => this.setState({ duplicates, upd, filename })

  // handleDuplicatatesSubmit = async value => {
  //   const { duplicates, upd, filename, projectId } = this.state
  //   const keepIds = []
  //   const replaceIds = []
  //   _.forEach(duplicates, (item, index) => {
  //     const isKeeping = !_.get(value, index, true)
  //     if (isKeeping) {
  //       keepIds.push(_.get(item, 'id'))
  //     } else {
  //       replaceIds.push(_.get(item, 'id'))
  //     }
  //   })
  //   const newUpd = _.filter(upd, ({ id }) => !_.includes(keepIds, id))
  //   await importLineItems(projectId, newUpd, filename, _.size(replaceIds) > 0)
  //   this.close()
  // }

  renderDuplicate = id => {
    const { fileIdByLiId, importedLi } = this.props
    const { importedRows, filename } = this.state
    const fileId = _.get(fileIdByLiId, id)
    const previous = {
      filename: _.get(importedLi, [fileId, 'filename']),
      name: _.get(importedLi, [fileId, 'lineItems', id, 'name']),
      timestamp: _.get(importedLi, [fileId, 'timestamp'])
    }
    const added = {
      filename,
      name: _.get(importedRows, [id, 'name'])
    }
    return (
      <FormField key={id} focusIndicator={false} flex={{ shrink: 0 }}>
        <Box direction='row' justify='between' flex={{ shrink: 0 }} fill='horizontal'>
          <Box width='40%'>
            <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>
              {previous.name}
            </Text>
            <Box direction='row' gap='small'>
              <Text customStyle='white-space: pre' size='xsmall'>
                {previous.filename}
                {'\n'}
                {moment(previous.timestamp).format('lll')}
              </Text>
            </Box>
          </Box>
          <Box width='20%' align='center' justify='center'>
            <CheckBox toggle name={_.toString(id)} defaultChecked />
          </Box>
          <Box width='40%'>
            <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>
              {added.name}
            </Text>
            <Box direction='row' gap='small'>
              <Text customStyle='white-space: pre' size='xsmall'>
                {added.filename}
                {'\n'}
              </Text>
            </Box>
          </Box>
        </Box>
      </FormField>
    )
  }

  apply = async replace => {
    console.log('apply', replace)
    const { importedRows, projectId, filename, duplicates } = this.state
    if (replace) {
      await importLineItems(projectId, importedRows, filename)
    } else {
      const newImportedLi = _.omitBy(importedRows, li => _.includes(duplicates, li.id))
      console.log('newImportedLi', newImportedLi)
      await importLineItems(projectId, newImportedLi, filename)
    }
    this.close()
  }

  renderResult = (isComplete, isError) => {
    const { importedRows, duplicates } = this.state
    if (isComplete && !isError) {
      if (_.size(duplicates) > 0) {
        return (
          <ThemeContext.Extend
            value={{
              formField: {
                border: {
                  color: 'transparent'
                },
                extend: {
                  border: 'none'
                }
              }
            }}
          >
            <Box pad={{ top: 'large', horizontal: 'large' }} flex gap='small' align='center'>
              <Text textAlign='center' size='3xl' color={colors.LIGHT_NAVY_BRIGHT}>
                Duplicated line items
              </Text>
              <Text textAlign='center' size='medium' color={colors.LIGHT_NAVY_BRIGHT} customStyle='white-space: pre'>
                {_.size(duplicates)} Line items with same ID found. {'\n'} Please, choose which line items you want to
                keep
              </Text>
              <Box direction='row' gap='medium' pad={{ bottom: 'large', top: 'medium' }}>
                <Button
                  label='Keep existing'
                  primary
                  color={colors.LIGHT_NAVY_BRIGHT}
                  onClick={() => this.apply(false)}
                />
                <Button label='Replace with new' primary color={colors.AQUA_MARINE} onClick={() => this.apply(true)} />
              </Box>
            </Box>
          </ThemeContext.Extend>
        )
        // return (
        //   <ThemeContext.Extend
        //     value={{
        //       formField: {
        //         border: {
        //           color: 'transparent'
        //         },
        //         extend: {
        //           border: 'none'
        //         }
        //       }
        //     }}
        //   >
        //     <Box pad={{ top: 'large', horizontal: 'large' }} flex gap='small' align='center'>
        //       <Text textAlign='center' size='3xl' color={colors.LIGHT_NAVY_BRIGHT}>
        //         Duplicated line items
        //       </Text>
        //       <Text textAlign='center' size='medium' color={colors.LIGHT_NAVY_BRIGHT} customStyle='white-space: pre'>
        //         Line items with same ID found. {'\n'} Please, choose which line items you want to keep
        //       </Text>
        //       <Form onSubmit={({ value }) => this.handleDuplicatatesSubmit(value)} width='100%'>
        //         <Box height={{ max: 'small' }} overflow='auto' gap='small' flex={{ shrink: 0 }}>
        //           {_.map(duplicates, this.renderDuplicate)}
        //         </Box>
        //         <Box fill='horizontal' margin={{ top: 'small', bottom: 'small' }} align='center'>
        //           <Button type='submit' color={colors.AQUA_MARINE} label='Save' primary />
        //         </Box>
        //       </Form>
        //     </Box>
        //   </ThemeContext.Extend>
        // )
      } else {
        return (
          <Box margin={{ horizontal: 'large', vertical: 'xlarge' }} flex={false} gap='small' align='center'>
            <Text textAlign='center' size='3xl' color={colors.LIGHT_NAVY_BRIGHT}>
              {_.size(importedRows)}
            </Text>
            <Text textAlign='center' size='medium' color={colors.LIGHT_NAVY_BRIGHT} customStyle='white-space: pre'>
              line items {'\n'} have been successfully imported!
            </Text>
            <Box width='small' margin={{ top: 'medium' }}>
              <Button color={colors.AQUA_MARINE} label='Continue' primary onClick={this.close} />
            </Box>
          </Box>
        )
      }
    } else if (!isComplete && !isError) {
      return (
        <Box margin={{ horizontal: 'large', vertical: 'xlarge' }} flex={false} gap='small' align='center'>
          <Loading />
        </Box>
      )
    } else {
      return (
        <Box margin={{ horizontal: 'large', vertical: 'xlarge' }} flex={false} gap='small' align='center'>
          <Text textAlign='center' size='3xl' color={colors.CORAL_TWO}>
            Error
          </Text>
          <Text textAlign='center' size='medium' color={colors.CORAL_TWO}>
            Something went wrong
          </Text>
          <Box width='small' margin={{ top: 'medium' }}>
            <Button color={colors.CORAL_TWO} label='Close' primary onClick={this.close} />
          </Box>
        </Box>
      )
    }
  }

  processRows = async rows => {
    console.log('processRow', rows)
    const res = {}
    _.forEach(rows, row => {
      if (!_.isEmpty(row.id)) {
        const id = `li_${row.id}`
        const r = { ...row }
        _.forOwn(r, (v, f) => {
          if (_.includes(f, '.')) {
            delete r[f]
            f = f.replace('.', '')
          }
          r[f] = v
        })
        r.id = id
        if (!_.isNil(r.quantity)) {
          r.quantity = _.toNumber(_.replace(r.quantity, ',', '.'))
        }
        r.labor = Boolean(r.labor)
        r.required = Boolean(r.required)
        r.quantityType = _.lowerCase(r.quantityType)
        _.set(res, id, r)
      }
    })
    console.log('neededRows', res)
    this.setState({
      importedRows: {
        ...this.state.importedRows,
        ...res
      }
    })
  }

  onImportComplete = async ({ file, columnFields }) => {
    const { projectId, importedRows } = this.state
    const { importedLi } = this.props
    console.log('onImportComplete', importedRows)
    const filename = _.get(file, 'name')
    const duplicates = _.reduce(
      importedRows,
      (res, li) => {
        if (_.has(importedLi, ['lineItems', li.id])) res.push(li.id)
        return res
      },
      []
    )
    console.log('duplicates', duplicates)
    if (!_.isEmpty(duplicates)) {
      this.setState({ duplicates, filename })
    } else {
      await importLineItems(projectId, importedRows, filename)
    }
  }

  renderImporter = () => {
    return (
      <Importer
        chunkSize={10000}
        assumeNoHeaders={false}
        restartable={false}
        processChunk={async (rows, { startIndex }) => {
          // required, receives a list of parsed objects based on defined fields and user column mapping;
          // may be called several times if file is large
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          await this.processRows(rows)
        }}
        onComplete={this.onImportComplete}
        onClose={this.close}
        CustomImporterFrame={({ isComplete, isError }) => (
          <Box flex={false}>{this.renderResult(isComplete, isError)}</Box>
        )}
      >
        {({ preview } = {}) => {
          let newFields = []
          if (preview) {
            newFields = _.reduce(
              preview.columns,
              (res, c) => {
                const headerRaw = _.get(c, 'header')
                if (!headerRaw) return res
                const header = _.toLower(headerRaw)
                if (!_.includes(res, header) && !_.includes(DEFAULT_FIELDS_NAMES, header)) {
                  res.push({
                    name: header,
                    label: headerRaw,
                    required: false
                  })
                }
                return res
              },
              []
            )
          }
          const fields = _.concat(DEFAULT_FIELDS, newFields)
          return _.map(fields, ({ name, label, required }) => (
            <ImporterField key={`field_${name}`} name={name} label={label} optional={!required} />
          ))
        }}
      </Importer>
    )
  }

  render () {
    const { show } = this.state
    if (!show) return null
    return (
      <Layer
        position='center'
        onClickOutside={this.close}
        onEsc={this.close}
        margin={{ horizontal: 'xlarge', vertical: 'medium' }}
      >
        <Box
          width='large'
          customStyle={`
            .CSVImporter_FileSelector {
              border: 0.25em dashed ${colors.VERY_LIGHT_PINK};
              background: ${colors.WHITE};
              margin: 7em 4em;
              border-radius: 0;
            };
            .CSVImporter_ImporterFrame {
              border-radius: 0;
              border: none;
            };
            form {
              width: 100%;
            };
          `}
        >
          <Box
            direction='row'
            pad='small'
            fill='horizontal'
            justify='between'
            border={{ side: 'bottom', size: '1px', color: colors.ANOTHER_GREY }}
          >
            <Text color={colors.TEXT} size='large'>
              Import line items
            </Text>
            <Button
              onClick={this.close}
              icon={<FontAwesomeIcon icon={faTimes} color={colors.BLACK} size={24} />}
              plain
            />
          </Box>
          <Box overflow={{ vertical: 'auto' }}>{this.renderImporter()}</Box>
        </Box>
      </Layer>
    )
  }
}

const mapStateToProps = state => ({
  importedLi: state.importedLi
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(ImportCsvModal)
