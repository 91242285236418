import React from 'react'
import { Box, Text } from 'grommet'
import numeral from 'numeral'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faPlug } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const LineItemPlug = ({ plug, compact }) => {
  if (!_.isNil(plug)) {
    if (compact) {
      return (
        <Box pad='xsmall'><FontAwesomeIcon icon={faPlug} size={16} color={colors.LIGHT_NAVY_BRIGHT} /></Box>
      )
    }
    const value = _.get(plug, 'total')
    return (
      <Box direction='row' align='center' gap='xsmall'>
        <Text color={colors.VERY_LIGHT_PINK} size='small' weight={600}>
          PLUG
        </Text>
        <Text color={colors.BROWN_GREY_TWO} size='small' weight={600}>
          {numeral(value).format('$0,0.[00]')}
        </Text>
      </Box>
    )
  } else {
    return (
      <Box
        hoverIndicator
        onClick={() => (false)}
        direction='row'
        gap='xxsmall'
        align='center'
      >
        <FontAwesomeIcon icon={faPlus} size={12} color={colors.VERY_LIGHT_PINK} />
        <Text color={colors.VERY_LIGHT_PINK} size='small' weight={600}>
          ADD PLUG
        </Text>
      </Box>
    )
  }
}

export default LineItemPlug
