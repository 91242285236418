import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, ThemeContext } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import { fieldValue } from 'constants/firebase'

import colors from 'shared/constants/colors'
import newLineItemAction from 'shared/constants/newLineItemAction'
import { getName } from 'shared/utils/stringUtils'
import {
  saveScopeOfWork,
  sendResolveLineItemMessage,
  sendRequestPriceMessage,
  sendOptionalLiDeclinedMessage
} from 'controllers/workOrder'
import DeclineLineItemModal from 'webPages/leveling/DeclineLineItemModal'
import { updateBid } from 'controllers/bids'
import { updateAlternatesConf, updateMerging } from 'controllers/privateWorkOrder'
import LevelingPanel from 'webPages/leveling/LevelingPanel'
import LevelingBottomPanel from 'webPages/leveling/LevelingBottomPanel'
import MergingModal from 'webPages/leveling/MergingModal'
import { getLevelingTableMessages } from 'model/selectors/channels'
import GeneralExclusionsTable from 'webPages/leveling/levelingTable/GeneralExclusionsTable'
import ScopeOfWorkTable from 'webPages/leveling/levelingTable/ScopeOfWorkTable'
import levelingTableMode from 'constants/levelingTableMode'

const LevelingTable = ({
  accountsProfiles,
  invitations,
  scope,
  scopeSections,
  workOrder,
  dispatch,
  openCreateBidManuallyModal,
  userId,
  cellOpen,
  cellOpenTimestamp,
  panelRef,
  mode
}) => {
  const [selectedCell, setSelectedCell] = useState(null)
  const [selectedLineItems, setSelectedLineItems] = useState({})

  const merging = _.get(workOrder, 'merging')
  const workOrderId = _.get(workOrder, 'id')

  const declineLineItemModalRef = useRef()
  const mergingModalRef = useRef()

  const openSubDetails = useCallback(
    accId => {
      const panelProps = {
        invId: accId,
        openCreateBidManuallyModal
      }
      panelRef.current.openSubInfo(panelProps)
      setSelectedCell(accId)
    },
    [openCreateBidManuallyModal, panelRef]
  )

  const openCellDetails = useCallback(
    (invId, itemId) => {
      const panelProps = {
        subId: invId,
        workOrderId,
        itemId
      }
      panelRef.current.openCellInfo(panelProps)
      setSelectedCell(`${invId}_${itemId}`)
    },
    [panelRef, workOrderId]
  )

  useEffect(() => {
    if (!_.isNil(cellOpen)) {
      if (_.has(cellOpen, 'subId') && _.has(cellOpen, 'itemId')) {
        const panelProps = {
          subId: cellOpen.subId,
          workOrderId,
          itemId: cellOpen.itemId
        }
        panelRef.current.openCellInfo(panelProps)
        setSelectedCell(`${cellOpen.subId}_${cellOpen.itemId}`)
      } else if (_.has(cellOpen, 'subId')) {
        const panelProps = {
          invId: cellOpen.subId,
          openCreateBidManuallyModal
        }
        panelRef.current.openSubInfo(panelProps)
        setSelectedCell(cellOpen.subId)
      }
    }
  }, [cellOpen, cellOpenTimestamp, openCreateBidManuallyModal, workOrderId, panelRef])

  const invitationsDict = useMemo(() => _.keyBy(invitations, 'id'), [invitations])

  const setScope = useCallback(
    newScope => {
      dispatch(saveScopeOfWork(workOrderId, newScope))
    },
    [workOrderId, dispatch]
  )

  const selectedAlternates = _.get(workOrder, 'alternatesConf', {})

  const openMergingModal = () => {
    const itemId = _.get(_.keys(selectedLineItems), 0)
    const invId = _.get(selectedLineItems, itemId)
    mergingModalRef.current.open({ itemId, invId, itemsAvailableForMerging })
  }

  const mergingScope = useMemo(() => {
    if (_.isEmpty(merging)) {
      return {}
    } else {
      const res = {}
      _.forEach(invitations, inv => {
        const items = _.get(inv, 'bid.items', {})
        _.forEach(items, (item, itemId) => {
          if (_.has(merging, itemId)) {
            const scopeItemId = _.get(merging, itemId)
            _.set(res, [inv.id, scopeItemId], itemId)
          }
        })
      })
      return res
    }
  }, [invitations, merging])

  const setSelectedAlternates = useCallback(
    newAlternates => {
      dispatch(updateAlternatesConf(workOrderId, newAlternates))
    },
    [workOrderId, dispatch]
  )

  const itemsAvailableForMergingDict = useMemo(() => {
    const res = {}
    _.forEach(invitations, inv => {
      const busyScopeItems = _.get(mergingScope, inv.id, {})
      // console.log('busyScopeItems ', busyScopeItems)
      if (!_.isEmpty(inv.bid)) {
        _.forEach(_.values(scope), (scopeItem, index) => {
          // console.log('process scope item', itemId)
          const itemId = scopeItem.id
          const bidItem = _.get(inv, ['bid', 'items', itemId])
          const priceRequested = _.get(scopeItem, 'priceRequested', [])
          if (
            _.isEmpty(bidItem) &&
            !scopeItem.approveAllForAll &&
            !_.isEmpty(priceRequested) &&
            !_.includes(priceRequested, inv.id) &&
            !_.has(busyScopeItems, itemId)
          ) {
            _.set(res, [inv.id, itemId], { ...scopeItem, num: index + 1 })
          }
        })
      }
    })
    return res
  }, [invitations, scope, mergingScope])

  // console.log('itemsAvailableForMergingDict', itemsAvailableForMergingDict)

  const itemsAvailableForMerging = useMemo(() => {
    // console.log('selectedLineItems', selectedLineItems)
    const invIds = _.map(selectedLineItems, inv => inv.id)
    const uniqInvIds = _.uniq(invIds)
    // console.log('invIds', invIds, 'uniqInvIds', uniqInvIds)
    if (_.size(invIds) === _.size(uniqInvIds)) {
      let itemById = {}
      const dict = _.map(selectedLineItems, (inv, itemId) => {
        // console.log('process selected Item', itemId, inv)
        const availableItems = _.get(itemsAvailableForMergingDict, inv.id, {})
        // console.log('available items', availableItems)
        itemById = { ...itemById, ...availableItems }
        return _.keys(availableItems)
      })
      // console.log('dict', dict)
      const apprItemsIds = _.intersection(...dict)
      return _.map(apprItemsIds, itemId => _.get(itemById, itemId))
    }
  }, [itemsAvailableForMergingDict, selectedLineItems])

  const onAlternateSelected = useCallback(
    (invId, itemId, altId) => {
      // console.log('alternateSelected', invId, itemId, altId)
      const newAlternates = { ...selectedAlternates }
      _.set(newAlternates, [invId, itemId], altId)
      setSelectedAlternates(newAlternates)
    },
    [selectedAlternates, setSelectedAlternates]
  )

  const cleanupItemForScope = item => {
    const newItem = { ...item }
    _.unset(newItem, 'total')
    _.unset(newItem, 'cost')
    _.unset(newItem, 'desc')
    _.unset(newItem, 'excluded')
    _.unset(newItem, 'optional')
    _.unset(newItem, 'answer')
    return newItem
  }

  const onNewLineItemAction = useCallback(
    (action, invId, itemId) => {
      console.log('onNewLineItemAction', action, invId, itemId)
      const item = _.get(invitationsDict, [invId, 'bid', 'items', itemId])
      // console.log('invitations', invitationsDict)
      console.log('item', item)
      const newItem = cleanupItemForScope(item)
      console.log('newItem', newItem)
      switch (action) {
        case newLineItemAction.APPROVE_FOR_ALL:
          console.log('newItem', newItem)
          if (_.has(scope, itemId)) {
            console.log('the item is already in scope of work, remove priceRequested')
            const scopeItem = { ..._.get(scope, itemId, {}) }
            console.log('scopeItem', scopeItem)
            _.unset(scopeItem, 'priceRequested')
            console.log('scopeItem after priceRequested removal', scopeItem)
            const newScope = { ...scope, [itemId]: scopeItem }
            console.log('newScope', newScope)
            dispatch(sendResolveLineItemMessage(invId, workOrder, scopeItem, 'approved'))
            setScope(newScope)
          } else {
            const newScope = { ...scope, [itemId]: newItem }
            // console.log('newScope', newScope)
            dispatch(sendResolveLineItemMessage(invId, workOrder, newItem, 'approved'))
            setScope(newScope)
          }
          break

        case newLineItemAction.APPROVE_FOR_SUB:
          _.set(newItem, 'priceRequested', [invId])
          setScope({ ...scope, [itemId]: newItem })
          dispatch(sendResolveLineItemMessage(invId, workOrder, newItem, 'approved'))
          break

        case newLineItemAction.DECLINE: {
          const companyName = getName(_.get(accountsProfiles, invId))
          declineLineItemModalRef.current.open(invId, item, companyName)
          break
        }
        case newLineItemAction.CANCEL_REMOVAL_REQUEST: {
          const bidId = _.get(invitationsDict, [invId, 'bid', 'id'])
          const upd = {
            [`removalRequests.${itemId}`]: fieldValue.delete()
          }
          updateBid(bidId, upd)
          break
        }
      }
      resetSelectedLineItems()
    },
    [accountsProfiles, invitationsDict, scope, setScope, workOrder, dispatch]
  )

  const declineLineItem = (invId, item, comment) => {
    // console.log('declineLineItem', invId, item, comment)
    const bid = _.get(invitationsDict, [invId, 'bid'])
    const bidId = _.get(bid, 'id')
    // console.log('bidId', bidId)
    const bidTotal = _.get(bid, 'total')
    const itemTotal = _.get(item, 'total')
    const total = bidTotal && itemTotal ? bidTotal - itemTotal : null
    if (_.get(item, 'optional')) {
      const upd = {
        [`items.${item.id}`]: fieldValue.delete()
      }
      if (!_.isNil(total)) upd.total = total
      dispatch(sendOptionalLiDeclinedMessage(invId, workOrder, item))
      updateBid(bidId, upd)
    } else {
      const upd = {
        [`removalRequests.${item.id}`]: {
          comment,
          createdAt: _.now(),
          createdBy: userId
        }
      }
      dispatch(sendResolveLineItemMessage(invId, workOrder, item, 'declined'))
      updateBid(bidId, upd)
    }
  }

  const declineSelectedLineItems = () => {
    // console.log('declineSelectedLineItems', selectedLineItems)
    const bidTotals = {}
    const upd = {}
    _.forEach(selectedLineItems, (inv, itemId) => {
      const item = _.get(inv, ['bid', 'items', itemId])
      if (_.isEmpty(item)) {
        console.warn('declineSelectedLineItems, items does not exist, itemId', itemId, 'inv', inv)
      } else {
        // console.log('item', item)
        const bidId = _.get(inv, ['bid', 'id'])
        if (item.optional) {
          const bidTotal = _.get(bidTotals, bidId, _.get(inv, 'bid.total'))
          const itemTotal = _.get(inv, ['bid', 'items', itemId, 'total'])
          // console.log('itemId', itemId, 'bidTotal', bidTotal, 'itemTotal', itemTotal)
          const newTotal = bidTotal && itemTotal ? bidTotal - itemTotal : bidTotal
          _.set(bidTotals, bidId, newTotal)
          _.set(upd, [bidId, `items.${item.id}`], fieldValue.delete())
        } else {
          _.set(upd, [bidId, `removalRequests.${item.id}`], { createdAt: _.now(), createdBy: userId })
        }
      }
    })
    _.forEach(upd, (params, bidId) => {
      const totalUpdate = _.get(bidTotals, bidId)
      // console.log('bidId', bidId, 'totalUpdate', totalUpdate, 'params', params)
      if (totalUpdate) {
        params.total = totalUpdate
      }
      updateBid(bidId, params)
    })
    resetSelectedLineItems()
  }

  const approveSelectedLineItems = () => {
    const upd = {}
    _.forEach(selectedLineItems, (inv, itemId) => {
      const item = _.get(inv, ['bid', 'items', itemId])
      const newItem = cleanupItemForScope(item)
      _.set(upd, itemId, newItem)
    })
    // console.log('approveSelectedLineItems, upd', upd)
    setScope({ ...scope, ...upd })
    resetSelectedLineItems()
  }

  const requestPrice = useCallback(
    (invId, itemId) => {
      // console.log('requestPrice', invId, itemId)
      const item = _.get(scope, itemId)
      const priceRequested = _.get(item, 'priceRequested', [])
      const newPriceRequested = _.uniq([...priceRequested, invId])
      dispatch(sendRequestPriceMessage(workOrder, item, invId))
      setScope({
        ...scope,
        [itemId]: {
          ...item,
          priceRequested: newPriceRequested
        }
      })
    },
    [scope, setScope, workOrder, dispatch]
  )

  const removePriceRequest = useCallback(
    (invId, itemId) => {
      // console.log('removePriceRequest', invId, itemId)
      const item = _.get(scope, itemId)
      const priceRequested = _.get(item, 'priceRequested', [])
      const newPriceRequested = [...priceRequested]
      _.remove(newPriceRequested, id => id === invId)
      setScope({
        ...scope,
        [itemId]: {
          ...item,
          priceRequested: newPriceRequested
        }
      })
    },
    [scope, setScope]
  )

  const undoMerge = useCallback(
    (approvedItemId, unApprovedLineId) => {
      // console.log('undoMerge approvedItemId', approvedItemId, 'unApprovedLineId', unApprovedLineId, 'merging', merging)
      const newMerging = _.reduce(
        merging,
        (res, apprId, unapprId) => {
          if (unapprId !== unApprovedLineId) {
            _.set(res, unapprId, apprId)
          }
          return res
        },
        {}
      )
      // console.log('curMerging', newMerging)
      dispatch(updateMerging(workOrderId, newMerging))
    },
    [dispatch, merging, workOrderId]
  )

  const merge = approvedItemId => {
    // console.log('merge approvedItemId', approvedItemId)
    const curMerging = _.get(workOrder, 'merging', {})
    const newMerging = { ...curMerging }
    _.forEach(selectedLineItems, (inv, unapprId) => _.set(newMerging, unapprId, approvedItemId))
    console.log('curMerging', curMerging, 'newMerging', newMerging)
    dispatch(updateMerging(workOrderId, newMerging))
    resetSelectedLineItems()
  }

  const toggleItemSelected = useCallback(
    (itemId, invId) => {
      console.log('toggleItemSelected', itemId)
      if (_.has(selectedLineItems, itemId)) {
        const selectedLineItemsCopy = { ...selectedLineItems }
        _.unset(selectedLineItemsCopy, itemId)
        setSelectedLineItems(selectedLineItemsCopy)
      } else {
        const inv = _.get(invitationsDict, invId)
        setSelectedLineItems({ ...selectedLineItems, [itemId]: inv })
      }
    },
    [invitationsDict, selectedLineItems]
  )

  const openLineItemDetails = useCallback(
    itemId => {
      let itemName = _.get(scope, [itemId, 'name'], false)
      let item = _.get(scope, itemId)
      if (!itemName) {
        const inv = _.find(invitations, inv => _.has(inv, ['bid', 'items', itemId]))
        itemName = _.get(inv, ['bid', 'items', itemId, 'name'])
        item = _.get(inv, ['bid', 'items', itemId])
      }
      const canApproveForAll = !_.isNil(_.get(scope, [itemId, 'priceRequested']))
      const panelProps = {
        workOrderId,
        itemId,
        itemName,
        canApproveForAll,
        item
      }
      setSelectedCell(`${itemId}`)
      panelRef.current.openLiInfo(panelProps)
    },
    [invitations, panelRef, scope, workOrderId]
  )

  const resetSelectedLineItems = () => setSelectedLineItems({})

  const resetSelectedCell = useCallback(() => {
    setSelectedCell(null)
  }, [])

  // console.log('unapproved line items', unapprovedLineItems)
  // console.log('data', data)
  // console.log('unapprovedLineItems', unapprovedLineItems)

  return (
    <ThemeContext.Extend value={{ table: { body: { border: null } } }}>
      <Box fill background={colors.PALE_GREY}>
        <Box height='100%' direction='row' justify='between' wrap={false}>
          {/* <ReactResizeDetector handleHeight>
            {({ width, height }) => {
              console.log('width, height', width, height)
              return ( */}
          <Box overflow='auto' height='100%' flex>
            {/* <Box width={`${contentWidth + 1}px`}> */}
            {mode === levelingTableMode.SCOPE_OF_WORK ? (
              <ScopeOfWorkTable
                scope={scope}
                scopeSections={scopeSections}
                invitations={invitations}
                workOrder={workOrder}
                selectedAlternates={selectedAlternates}
                selectedCell={selectedCell}
                openSubDetails={openSubDetails}
                merging={merging}
                onNewLineItemAction={onNewLineItemAction}
                toggleItemSelected={toggleItemSelected}
                selectedLineItems={selectedLineItems}
                openLineItemDetails={openLineItemDetails}
                onAlternateSelected={onAlternateSelected}
                requestPrice={requestPrice}
                openCellDetails={openCellDetails}
                removePriceRequest={removePriceRequest}
                undoMerge={undoMerge}
              />
            ) : (
              <GeneralExclusionsTable
                scope={scope}
                invitations={invitations}
                workOrder={workOrder}
                selectedAlternates={selectedAlternates}
                accountsProfiles={accountsProfiles}
                selectedCell={selectedCell}
                openSubDetails={openSubDetails}
              />
            )}
          </Box>
          {/* )
            }}
          </ReactResizeDetector> */}
          <LevelingPanel ref={panelRef} onClose={resetSelectedCell} workOrderId={workOrderId} />
          <DeclineLineItemModal ref={declineLineItemModalRef} onDecline={declineLineItem} />
        </Box>
        <LevelingBottomPanel
          isOpen={!_.isEmpty(selectedLineItems)}
          unselectAll={resetSelectedLineItems}
          approveAllForAll={approveSelectedLineItems}
          declineAllLineItems={declineSelectedLineItems}
          openMergingModal={openMergingModal}
          canMerge={!_.isEmpty(itemsAvailableForMerging)}
        />
        <MergingModal ref={mergingModalRef} merge={merge} />
      </Box>
    </ThemeContext.Extend>
  )
}

LevelingTable.propTypes = {
  scope: PropTypes.object,
  workOrder: PropTypes.object,
  invitations: PropTypes.array,
  openCreateBidManuallyModal: PropTypes.func
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles,
  userId: _.get(state, 'user.id'),
  channel: state.channelWeb,
  profiles: state.profiles,
  messages: getLevelingTableMessages(state)
})

export default connect(mapStateToProps)(LevelingTable)
