import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SlidingLeftPanel from 'components/SlidingLeftPanel.web'
import SubInfoPanelContent from 'components/leveling/levelingPanel/SubInfoPanelContent'
import CellInfoPanelContent from 'components/leveling/levelingPanel/CellInfoPanelContent'
import LineItemPanelContent from 'components/leveling/levelingPanel/LineItemPanelContent'

const SUB = 'SUB'
const CELL = 'CELL'
const LINE_ITEM = 'LINE_ITEM'

class LevelingPanel extends Component {
  constructor (props) {
    super(props)
    this.panelRef = React.createRef()
    this.state = {
      mode: undefined,
      panelProps: undefined
    }
  }

  _open = (mode, params) => {
    this.setState({ mode, panelProps: params }, this.panelRef.current.open)
  }

  openSubInfo = params => this._open(SUB, params)

  openCellInfo = params => this._open(CELL, params)

  openLiInfo = params => this._open(LINE_ITEM, params)

  close = () => this.panelRef.current.close()

  getContentComponent = () => {
    const { mode } = this.state
    switch (mode) {
      case SUB:
        return SubInfoPanelContent
      case CELL:
        return CellInfoPanelContent
      case LINE_ITEM:
        return LineItemPanelContent
      default:
        return null
    }
  }

  render () {
    const { onClose, workOrderId } = this.props
    const { panelProps } = this.state
    const ContentComponent = this.getContentComponent()
    // console.log('panelProps', panelProps)
    return (
      <SlidingLeftPanel ref={this.panelRef} onClose={onClose}>
        {ContentComponent && <ContentComponent onClose={this.close} {...panelProps} workOrderId={workOrderId} />}
      </SlidingLeftPanel>
    )
  }
}

LevelingPanel.propTypes = {
  onClose: PropTypes.func,
  workOrderId: PropTypes.string
}

export default React.memo(LevelingPanel)
