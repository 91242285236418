import React, { useState, useRef } from 'react'
import { Box, Text, Button, Keyboard, Avatar } from 'grommet'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { MentionsInput, Mention } from 'react-mentions'

import colors from 'shared/constants/colors'
import { getInitials } from 'shared/utils/stringUtils'
import { getAccountAdmins } from 'model/selectors/base'

const User = (admin, search, highlightedDisplay, index, selected) => {
  const email = _.get(admin, 'email')
  const phone = _.get(admin, 'phone') || ''
  const phoneNumber = phone ? parsePhoneNumberFromString(phone) : null
  const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
  return (
    <Box
      key={_.get(admin, 'id')}
      pad='small'
      flex={{ shrink: 0 }}
      direction='row'
      align='center'
      gap='xsmall'
      customStyle={`
        cursor: pointer;
        background: ${selected ? colors.AQUA_MARINE10 : colors.WHITE};
      `}
    >
      <Avatar src={_.get(admin, 'avatarSmall', _.get(admin, 'avatar'))} background={colors.LIGHT_NAVY_BRIGHT} size='small'>
        <Text size='xsmall' color={colors.WHITE}>{getInitials(admin)}</Text>
      </Avatar>
      <Box gap='xsmall'>
        <Text color={colors.TEXT} size='medium'>{_.get(admin, 'name')}</Text>
        <Text color={colors.ANOTHER_GREY} size='xsmall'>{contacts}</Text>
      </Box>
    </Box>
  )
}

const ChatInputField = ({ placeholder, descriptions, onNewMessage, admins }) => {
  const [focused, setFocused] = useState(false)

  const [value, setValue] = useState('')
  const inputRef = useRef()

  const onChange = e => {
    const v = _.get(e, 'target.value')
    setValue(v)
  }

  const onFocus = () => setFocused(true)

  const onBlur = e => {
    if (
      _.get(e, 'relatedTarget.id') !== 'chat_send_button'
    ) {
      setFocused(false)
    } else {
      e.preventDefault()
    }
  }

  const onSend = () => {
    console.log('on send', value)
    const v = _.trim(value)
    console.log('on send, after trim', v)
    onNewMessage(v)
    setValue('')
    inputRef.current.blur()
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
        onSend()
      } else {
        setValue(value + '\n')
      }
    }
  }

  const textarea = (
    <Box fill customStyle={{ position: 'initial' }}>
      <Keyboard onKeyDown={handleKeyDown}>
        <MentionsInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={focused ? 5 : 1}
          inputRef={inputRef}
          className='mentions'
          style={{
            input: {
              overflow: 'auto',
              border: 'none',
              padding: 7
            },
            suggestions: {
              list: {
                maxHeight: 400,
                overflow: 'auto'
              },
              zIndex: 30
            }
          }}
        >
          <Mention
            trigger='@'
            data={_.map(admins, admin => ({ ...admin, display: _.get(admin, 'name') }))}
            renderSuggestion={User}
            appendSpaceOnAdd
          />
        </MentionsInput>
      </Keyboard>
    </Box>
  )

  const renderContent = () => {
    if (focused) {
      return (
        <Box
          direction='row'
          align='center'
          justify='between'
          pad={{ bottom: 'xsmall', horizontal: 'xsmall' }}
          gap='small'
          flex={{ shrink: 0 }}
        >
          <Box flex>
            {_.map(descriptions, (description, i) => (
              <Text italic size='small' color={colors.ANOTHER_GREY} key={i} textAlign='end'>
                {description}
              </Text>
            ))}
          </Box>
          <Button primary label='Send' color={colors.AQUA_MARINE} onClick={onSend} id='chat_send_button' />
        </Box>
      )
    } else {
      return (
        <Button
          color={colors.LIGHT_BLUE_GREY}
          secondary
          label='Send'
          disabled
          margin={{ vertical: 'xsmall', horizontal: 'xsmall' }}
        />
      )
    }
  }

  return (
    <Box border='all' direction={focused ? 'column' : 'row'} round='xxsmall' flex={{ shrink: 0 }} customStyle={{ position: 'relative' }}>
      {textarea}
      {renderContent()}
    </Box>
  )
}

ChatInputField.defaultProps = {
  placeholder: 'Write a note...',
  descriptions: '',
  onNewMessage: () => null
}

ChatInputField.propTypes = {
  placeholder: PropTypes.string,
  descriptions: PropTypes.array,
  onNewMessage: PropTypes.func
}

const mapStateToProps = state => ({
  admins: _.map(
    _.filter(
      getAccountAdmins(state), admin => admin.id !== _.get(state, 'user.id')
    ), admin => ({ ...admin, ..._.get(state, ['profiles', admin.id]) }))
})

export default connect(mapStateToProps)(ChatInputField)
