import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Text, Box, Keyboard, TextInput, ThemeContext } from 'grommet'
import numeral from 'numeral'
import { css } from 'styled-components'

import colors from 'shared/constants/colors'

const UnpricedInput = ({ initialValue, save, ...rest }) => {
  const [value, setValue] = useState(null)
  const inputRef = useRef()
  useEffect(() => {
    setValue(null)
  }, [initialValue])

  const onChange = event => {
    const newValue = event.target.value.replace(/[^0-9.,]/g, '')
    // console.log('valueRaw', valueRaw, 'newValue', newValue)
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(value).value()
      // console.log('newV', newV, 'curV', curV)
      if (newV === curV || _.isNil(value)) {
        setValue(newValue)
      } else {
        setValue(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setValue('')
    }
  }

  const onSubmit = () => {
    console.log('onSubmit')
    if (!_.isNil(value)) {
      save(numeral(value).value())
    } else {
      console.log('value was not changed, save is not called')
    }
  }

  const onEnter = () => {
    inputRef.current.blur()
  }

  let curValue = initialValue
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format('0,0.[00]')
  }
  if (!_.isNil(value)) curValue = value
  if (_.isNil(curValue)) curValue = ''

  return (
    <Box border='right' direction='row' gap='small' pad={{ left: 'small', right: 'small' }} align='center'>
      <Box width='130px'>
        <Text color={colors.LIGHT_NAVY_BRIGHT} textAlign='end'>
          Unpriced Inclusions:
        </Text>
      </Box>
      <ThemeContext.Extend
        value={{
          textInput: {
            extend: css`
              text-align: right;
              padding: 2px;
              font-weight: 600;
            `,
            container: {
              extend: css`
                display: flex;
                width: 100%;
              `
            }
          }
        }}
      >
        <Box
          background={colors.WHITE}
          direction='row'
          border={{ side: 'all', color: colors.LIGHT_NAVY_BRIGHT }}
          round='xsmall'
          height={'38px'}
          pad={{ right: 'xsmall' }}
          width={'200px'}
        >
          <Keyboard onEnter={onEnter}>
            <TextInput
              value={_.toString(curValue)}
              onChange={onChange}
              plain
              icon={<Text color={colors.LIGHT_NAVY_BRIGHT}>{'$'}</Text>}
              size='large'
              placeholder='0'
              onBlur={onSubmit}
              ref={inputRef}
              {...rest}
            />
          </Keyboard>
        </Box>
      </ThemeContext.Extend>
    </Box>
  )
}

UnpricedInput.propTypes = {
  initialValue: PropTypes.number,
  save: PropTypes.func
}

export default UnpricedInput
