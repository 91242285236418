import React from 'react'
import styled from 'styled-components/native'
import { connect } from 'react-redux'
import { ActivityIndicator, Image, Switch, Text as RNText, View } from 'react-native'

import { fontSize, getHeight } from 'shared/utils/dynamicSize'
import colors, { AQUA_MARINE } from 'shared/constants/colors'
import { BOLD_FONT, ITALIC_FONT, MEDIUM_FONT, REGULAR_FONT } from 'constants/index'
import { getViewports } from 'shared/model/selectors/viewportSelector'

const mapViewportToProps = (state, props) => ({ ...getViewports(state, props) })

const Text = props => <RNText {...props} keyboardType='numeric' allowFontScaling={false} />

const styledTextFontFamily = props => {
  if (props.bold) {
    return BOLD_FONT
  } else if (props.medium) {
    return MEDIUM_FONT
  } else if (props.italic) {
    return ITALIC_FONT
  } else {
    return REGULAR_FONT
  }
}

const StyledTextComp = styled(
  ({
    customStyle,
    textAlign,
    screenViewport,
    dispatch,
    fontSize,
    viewport,
    letterSpacing,
    bold,
    medium,
    italic,
    regular,
    onTextLayout,
    className,
    sideMenuWidth,
    leftPaneWidth,
    rightPaneViewport,
    ...props
  }) => <Text selectable {...props} />
)`
  font-size: ${props => fontSize(props.fontSize || 16, props.viewport)}px;
  font-family: ${props => styledTextFontFamily(props)};
  text-align: ${props => props.textAlign || 'left'};
  color: ${props => props.color || colors.DARK};
  ${props => (props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px;` : '')}
  ${props => props.customStyle || ''}
`
export const StyledText = connect(mapViewportToProps)(StyledTextComp)

const StyledActivityIndicator = styled(ActivityIndicator)`
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
`

export const Spinner = ({ color = AQUA_MARINE }) => <StyledActivityIndicator color={color} />

const LoadingWrapper = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #140f2643;
  ${props => props.customStyle || ''}
`
export const Loading = ({ customStyle, spinnerProps }) => (
  <LoadingWrapper customStyle={customStyle}>
    <Spinner color='white' {...spinnerProps} />
  </LoadingWrapper>
)

export const DevIndicatorContainer = styled(View)`
  position: absolute;
  right: 10px;
  bottom: 5px;
  z-index: 10;
  flex-direction: row;
  align-items: center;
`
export const DevIndicator = styled(View)`
  width: 10px;
  height: 10px;
  border-width: 1px;
  border-radius: 5px;
  background-color: red;
  margin-right: 4px;
`

const StyledSwitchComp = styled(Switch)`
  transform: ${props => `scale(${fontSize(0.8, props.viewport)})`};
`
export const StyledSwitch = connect(mapViewportToProps)(StyledSwitchComp)

export const Dot = styled(View)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  background-color: ${props => props.color};
  ${props => props.customStyle || ''}
`

export const Badge = styled(View)`
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  border-radius: ${props => props.size}px;
  background-color: ${props => props.color || 'black'};
  align-items: center;
  justify-content: center;
  ${props => props.customStyle || ''}
`

export const StyledImage = styled(Image)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  ${props => props.customStyle}
`

export const ModalContentWrapper = styled(View)`
  flex: 1;
  background-color: ${props => props.color};
`

export const Row = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.customStyle}
`

export const MapContainer = styled(View)`
  width: ${props => props.viewport.width}px;
  height: ${props => props.viewport.height * 0.25}px;
`

export const AvatarContainer = styled(View)`
  align-items: center;
  justify-content: center;
  padding-top: ${props => getHeight(16, props.viewport)}px;
`

export const HamburgerButton = () => <div id='hamburger_button' />
