export const xls = {
  'application/vnd.ms-excel': 'application/vnd.ms-excel',
  'application/msexcel': 'application/msexcel',
  'application/x-msexcel': 'application/x-msexcel',
  'application/x-ms-excel': 'application/x-ms-excel',
  'application/x-excel': 'application/x-excel',
  'application/x-dos_ms_excel': 'application/x-dos_ms_excel',
  'application/xls': 'application/xls',
  'application/x-xls': 'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const pdf = {
  'application/pdf': 'application/pdf'
}

export default { xls, pdf }
