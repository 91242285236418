export const SHOW_VALUES = 'values'
export const SHOW_REFERENCES = 'references'
export const SHOW_QUANTITIES = 'quantities'
export const COMPACT_VIEW = 'compact'
export const ORDER_BY = 'orderBy'
export const SHOW_DECLINED = 'declined'
export const SHOW_SECTIONS = 'sections'
export const HIDE_PROJECT_BUDGET = 'projectBudget'

export default {
  SHOW_VALUES,
  SHOW_REFERENCES,
  SHOW_QUANTITIES,
  COMPACT_VIEW,
  ORDER_BY,
  SHOW_DECLINED,
  SHOW_SECTIONS,
  HIDE_PROJECT_BUDGET
}
