import React from 'react'
import { Box, Text } from 'grommet'
import colors from 'shared/constants/colors'

const StatusPending = () => {
  return (
    <Box background={colors.WHITE_GREY} width='63px' align='center' justify='center'>
      <Text color={colors.VERY_LIGHT_GREY} size='xsmall' weight={600} italic>
        Pending
      </Text>
    </Box>
  )
}

export default React.memo(StatusPending)
