import { createSelector } from 'reselect'
import _ from 'lodash'

import { getFromProps, getProjectId, getWorkOrders } from 'model/selectors/base'
import { getViewAllProjectPermission, getAdminsWhoCanViewAllProjects } from 'model/selectors/permissionsSelector'
import { toTimestamp } from 'shared/utils/date'
import { getTradesDict } from 'model/selectors/references'

const getUserId = state => _.get(state, 'user.id')
const getAccountId = state => _.get(state, 'account.id')
const getProjects = state => _.get(state, 'projects', {})
const getBids = state => _.get(state, 'bids')

const getActiveProjectAdmins = (state, projectAdmins) => {
  const res = []
  for (const uid of projectAdmins) {
    const adm = _.get(state, ['account', 'admins', uid])
    if (adm) {
      res.push(uid)
    }
  }
  return res
}

export const getWorkOrdersByProjectWithTitles = createSelector(
  [getWorkOrders, getTradesDict],
  (workOrders, contractorTypes) => {
    const byProject = {}
    _.forEach(workOrders, wo => _.set(byProject, [wo.projectId, wo.id], wo))
    const res = {}
    _.forEach(byProject, (wos, projectId) => {
      const woSortedAr = _.sortBy(_.values(wos), wo => toTimestamp(_.get(wo, 'createdAt')))
      const tradesAmountWithnoLabel = {}
      const wosWithTitles = _.map(woSortedAr, wo => {
        const label = _.get(wo, 'label')
        const tradeId = _.get(wo, 'tradeId')
        let tradeName = _.get(contractorTypes, [tradeId, 'name'])
        if (_.isNil(label) || label === '') {
          const existingAmount = _.get(tradesAmountWithnoLabel, tradeId, 0)
          if (existingAmount > 0) tradeName = `${tradeName} (${existingAmount})`
          _.set(tradesAmountWithnoLabel, tradeId, existingAmount + 1)
        }
        return { ...wo, title: _.get(wo, 'label', tradeName) }
      })
      _.set(res, projectId, wosWithTitles)
    })
    return res
  }
)

export const getWorkOrdersByProject = createSelector([getWorkOrders], workOrders => {
  const res = {}
  _.forEach(workOrders, wo => _.set(res, [wo.projectId, wo.id], wo))
  return res
})

export const getWorkOrdersByProjectSorted = createSelector([getWorkOrdersByProject], workOrdersByProject => {
  const res = {}
  _.forEach(workOrdersByProject, (wos, projectId) => {
    _.set(
      res,
      projectId,
      _.sortBy(_.values(wos), wo => toTimestamp(_.get(wo, 'createdAt')))
    )
  })
  return res
})

export const getProjectsAdmins = state => {
  const projectsAdmins = _.get(state, 'account.projectsAdmins')
  const res = {}
  for (const projectId in projectsAdmins) {
    const projectAdmins = _.get(projectsAdmins, projectId, [])
    _.set(res, projectId, getActiveProjectAdmins(state, projectAdmins))
  }
  return res
}

export const getProjectAdmins = (state, props) => {
  const projectAdmins = _.get(state, ['account', 'projectsAdmins', getFromProps(props, 'projectId')], [])
  return getActiveProjectAdmins(state, projectAdmins)
}

export const getOwnProjectsInfo = createSelector(
  [getUserId, getProjects, getProjectsAdmins, getViewAllProjectPermission],
  (userId, projects, projectsAdmins, canViewAllProjects) => {
    if (canViewAllProjects) {
      return projects
    } else {
      const res = {}
      for (const projectId in projects) {
        const project = projects[projectId]
        const projectAdmins = _.get(projectsAdmins, projectId, [])
        if (_.includes(projectAdmins, userId)) {
          _.set(res, projectId, project)
        }
      }
      return res
    }
  }
)

const createProjectInfoFromWorkOrder = wo => {
  return {
    accountId: wo.accountId,
    address: wo.projectAddress,
    id: wo.projectId,
    incoming: true,
    projectCost: 0
  }
}

export const getIncomingProjectsInfo = createSelector(
  [getUserId, getAccountId, getWorkOrders, getProjectsAdmins, getViewAllProjectPermission, getBids],
  (userId, accountId, workOrders, projectsAdmins, canViewAllProjects, bids) => {
    const res = {}
    _.forEach(workOrders, (wo, woId) => {
      const approvedBid = _.get(bids, [_.get(wo, 'approvedBidId')])
      const approvedBidAccountId = _.get(approvedBid, 'accountId')
      const myBidApproved = approvedBidAccountId === accountId
      if (wo.accountId !== accountId) {
        // incoming & my bid approved
        let isAdmin = false
        if (canViewAllProjects || _.has(res, wo.projectId)) {
          isAdmin = true
        } else {
          const projectAdmins = _.get(projectsAdmins, wo.projectId, [])
          isAdmin = _.includes(projectAdmins, userId)
        }
        if (isAdmin) {
          if (!_.has(res, wo.projectId)) {
            _.set(res, wo.projectId, createProjectInfoFromWorkOrder(wo))
            if (myBidApproved) {
              _.set(res, [wo.projectId, 'lineItems'], _.get(approvedBid, 'items', {}))
            }
          }
          if (myBidApproved) {
            const curProjectCost = _.get(res, [wo.projectId, 'projectCost'], 0)
            _.set(res, [wo.projectId, 'projectCost'], curProjectCost + _.get(approvedBid, 'total', 0))
            _.set(res, [wo.projectId, 'myBidApproved'], true)
          }
        }
      }
    })
    return res
  }
)

export const getIncomingApprovedProjectsInfo = createSelector([getIncomingProjectsInfo], projects => {
  return _.omitBy(projects, p => !_.get(p, 'myBidApproved', false))
})

export const getAllProjectsInfo = createSelector(
  [getOwnProjectsInfo, getIncomingProjectsInfo],
  (ownProjects, incomingProjects) => {
    return {
      ...ownProjects,
      ...incomingProjects
    }
  }
)

export const getAllProjectsInfoList = createSelector([getOwnProjectsInfo], allProjects => {
  const notArchivedProjects = _.filter(allProjects, p => p.deleted <= 0)
  return _.orderBy(notArchivedProjects, ['createdAt'], ['desc'])
})

export const getAllProjectsInfoWithArchived = createSelector([getOwnProjectsInfo], allProjects => {
  return _.orderBy(_.values(allProjects), ['createdAt'], ['desc'])
})

export const getProjectsInfo = createSelector(
  [getOwnProjectsInfo, getIncomingApprovedProjectsInfo],
  (ownProjects, incomingProjects) => {
    return {
      ...ownProjects,
      ...incomingProjects
    }
  }
)

export const getProjectsIdsDict = createSelector([getProjectsInfo], projectsInfo => {
  return _.keyBy(_.keys(projectsInfo))
})

export const getProject = createSelector(
  [getOwnProjectsInfo, getIncomingProjectsInfo, getProjectId],
  (ownProjects, incomingProjects, projectId) => {
    return _.get(ownProjects, projectId, _.get(incomingProjects, projectId))
  }
)

export const getProjectsWithInvitedSubs = createSelector([getProjects, getWorkOrders], (projects, workOrders) => {
  const res = {}
  _.forEach(workOrders, wo => {
    const accounts = _.get(wo, 'accounts', [])
    const projectId = _.get(wo, 'projectId')
    if (accounts.length > 1 && _.has(projects, projectId)) {
      _.set(res, projectId, projects[projectId])
    }
  })
  return res
})

// FIXME:
// 1. the result is array, it can contain duplications
// 2. there should be only projects the current user has access to
export const getProjectsWithAssignedWorkOrders = createSelector(
  [getProjects, getWorkOrders],
  (projects, workOrders) => {
    const res = []
    for (const woId in workOrders) {
      const wo = workOrders[woId]
      if (_.get(wo, 'approvedBidId') && _.has(projects, _.get(wo, 'projectId'))) {
        res.push(_.get(projects, wo.projectId))
      }
    }
    return res
  }
)

export const getProjectsWithPaymentPlanAndWorkOrders = createSelector(
  [getOwnProjectsInfo, getWorkOrdersByProject],
  (projects, workOrdersByProjects) => {
    const res = {}
    _.forEach(projects, project => {
      const workOrders = _.get(workOrdersByProjects, project.id)
      if (_.has(project, 'plan') && !_.isEmpty(workOrders)) {
        _.set(res, project.id, project)
      }
    })
    return res
  }
)

export const getAllProjectAdmins = createSelector(
  [getProjectAdmins, getAdminsWhoCanViewAllProjects],
  (projectAdmins, adminsWhoCanViewAllProjects) => {
    return _.union(projectAdmins, adminsWhoCanViewAllProjects)
  }
)
