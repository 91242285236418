import * as types from 'model/actionTypes'
import _ from 'lodash'

const initialState = {}

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_INBOX_FIRST_MESSAGE:
      return {
        ...state,
        firstMessage: action.firstMessage
      }
    case types.RECEIVE_INBOX_FIRST_NOTE:
      return {
        ...state,
        firstNote: action.firstNote
      }
    case types.RECEIVE_MESSAGES_INBOX:
      return {
        ...state,
        messages: {
          ..._.get(state, 'messages', {}),
          ...action.messages
        }
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
