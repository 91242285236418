import React from 'react'
import { Platform, Linking } from 'react-native'
import EmptyState from 'shared/pages/EmptyState'
import PrimaryButton from 'shared/components/buttons/PrimaryButton'
import { INITIAL_VIEWPORT } from 'shared/constants/size'
import { getHeight } from 'shared/utils/dynamicSize'
import { isWeb } from 'shared/constants'

const UpdateApp = () => {
  return (
    <EmptyState title='Update app!' description='You have an old version of the application, update it!'>
      <PrimaryButton
        title='Update it!'
        onPress={() => {
          if (isWeb) return window.location.reload()
          const link = Platform.select({
            ios: 'itms-apps://itunes.apple.com/us/app/id1479550016?mt=8',
            android: 'market://details?id=com.usemason.hic'
          })
          Linking.openURL(link)
        }}
        customStyle={`margin-top: ${getHeight(15, INITIAL_VIEWPORT)}px`}
      />
    </EmptyState>
  )
}

export default UpdateApp
