import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'
import moment from 'moment-timezone'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import { moneyWithSymbolAbbr } from 'shared/utils/money'
import colors from 'shared/constants/colors'
import { getProgressPaymentsSum } from 'shared/utils/proposal'
import { getDiscountAmount } from 'shared/utils/bid'
import amountType from 'shared/constants/amountType'

const LineItemRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.getSize(3)}px;
  padding-bottom: ${props => props.getSize(3)}px;
  min-height: ${props => props.getSize(24)}px;
  ${props => props.customStyle || ''}
`
const LineItemRowHeader = styled(LineItemRow)`
  /* padding-top: ${props => props.getSize(14)}px; */
  /* padding-bottom: ${props => props.getSize(14)}px; */
`

const Cell = styled(View)`
  display: flex;
  flex: ${props => props.flex || 1};
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  ${props => props.customStyle || ''}
`

export const renderSheduleOfPayments = (getSize, doc) => {
  const total = _.get(doc, 'total', 0)
  const depositValue = getDiscountAmount(doc.deposit, total)
  const balanceDue = total - depositValue
  if (balanceDue > 0) {
    const progressPayments = _.get(doc, 'progressPayments', {})
    const progressPaymentsSum = getProgressPaymentsSum(progressPayments, balanceDue)
    const items = _.values(progressPayments)
    const sortedItems = _.sortBy(items, item => (item.date ? moment(item.date).valueOf() : Number.MAX_VALUE))
    // console.log('items', items)
    // console.log('sorted items', sortedItems)
    const defaultItemCost = balanceDue - progressPaymentsSum
    if (defaultItemCost) {
      sortedItems.push({
        id: 'default',
        name: 'completion of all work',
        cost: { value: defaultItemCost, type: amountType.FLAT_AMOUNT },
        date: null
      })
    }
    // console.log('items:', sortedItems)
    return [
      <LineItemRowHeader key={'payments_schedule'} getSize={getSize}>
        <Cell>
          <StyledText fontSize={getSize(12)} bold>
            Amount
          </StyledText>
        </Cell>
        <Cell flex={4}>
          <StyledText fontSize={getSize(12)} bold>
            Description
          </StyledText>
        </Cell>
        <Cell customStyle='justify-content: flex-end;'>
          <StyledText fontSize={getSize(12)} bold>
            Date
          </StyledText>
        </Cell>
      </LineItemRowHeader>,
      ..._.map(sortedItems, (item, i) => (
        <LineItemRow
          key={item.id}
          getSize={getSize}
          // customStyle={i === items.length
          //   ? `
          //     border: 0;
          //     border-style: solid;
          //     border-bottom-width: 1px;
          //     border-bottom-color: ${colors.TWILIGHT_BLUE};
          //     padding-bottom: ${getSize(10)}px;`
          //   : ''
          // }
        >
          <Cell>
            <StyledText fontSize={getSize(12)}>
              {moneyWithSymbolAbbr(getDiscountAmount(item.cost, balanceDue))}
            </StyledText>
          </Cell>
          <Cell flex={4}>
            <StyledText fontSize={getSize(12)}>{item.name}</StyledText>
          </Cell>
          <Cell customStyle='justify-content: flex-end;'>
            <StyledText fontSize={getSize(12)}>
              {item.date ? moment.tz(item.date, _.get(doc, 'projectAddress.timeZone')).format('MM/DD/YYYY') : ' '}
            </StyledText>
          </Cell>
        </LineItemRow>
      ))
    ]
  } else {
    return []
  }
}

export default {
  renderSheduleOfPayments
}
