import React, { useContext } from 'react'
import { Box, Button, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faExternalLink, faSlidersHSquare, faPaperclip } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import CustomizePanel from 'components/leveling/CustomizePanel'
import LayoutContext from 'webPages/layout/LayoutContext'

const DashboardSubmenu = ({ addBidInvite, generateReport, settingsChanged, projectId }) => {
  const { openProjectModal, openImportCsvModal } = useContext(LayoutContext)
  const addBidInviteButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPlus} />}
      label='Add Bid Invitation'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addBidInvite}
    />
  )

  const planRoomButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPaperclip} />}
      label='Plan Room'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={() => openProjectModal(projectId)}
    />
  )

  const importCsvButton = (
    <Button
      icon={<FontAwesomeIcon icon={faPaperclip} />}
      label='Import CSV'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={() => openImportCsvModal(projectId)}
    />
  )

  const generateReportButton = (
    <Button
      icon={<FontAwesomeIcon icon={faExternalLink} />}
      label='Generate Report'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={generateReport}
    />
  )

  const customizeLabel = () => {
    if (settingsChanged === 0) {
      return (
        <Box direction='row' gap='xsmall' align='center'>
          <FontAwesomeIcon icon={faSlidersHSquare} size={14} />
          <Text>Views</Text>
        </Box>
      )
    } else {
      return (
        <Box direction='row' gap='xsmall' align='center'>
          <FontAwesomeIcon icon={faSlidersHSquare} size={14} />
          <Text>
            {settingsChanged} customization{settingsChanged > 1 ? 's' : ''}
          </Text>
        </Box>
      )
    }
  }

  const viewsButton = (
    <CustomizePanel
      dropButtonLabel={customizeLabel()}
      dropButtonProps={{ color: colors.ASANA_GRAY_TEXT_HOVERED }}
    />
  )

  return (
    <Box
      fill='horizontal'
      direction='row'
      height='40px'
      background={colors.WHITE_GREY}
      align='center'
      pad={{ horizontal: 'small' }}
      flex={false}
      justify='between'
    >
      <Box direction='row' align='center' gap='small'>
        {addBidInviteButton}
        {importCsvButton}
        {planRoomButton}
        {generateReportButton}
      </Box>
      <Box>
        {viewsButton}
      </Box>
    </Box>
  )
}

export default React.memo(DashboardSubmenu)
