import _ from 'lodash'
import Sentry from 'shared/utils/sentry'
import { saveAs } from 'file-saver'

import { auth, db, fieldValue } from 'constants/firebase'
import config from 'shared/config'
import headers from 'shared/controllers/headers'

export const updateReportSettingBidInclude = async (projectId, reportType, workOrderId, bidIds) => {
  const upd = {
    [`reports.${reportType}.settings.includedBids.${workOrderId}`]: _.isUndefined(bidIds) ? fieldValue.delete() : bidIds
  }
  console.log('upd', upd)
  await db
    .collection('projects')
    .doc(projectId)
    .update(upd)
}

export const updateAllReportSettings = async (projectId, reportType, settings) => {
  const upd = {
    [`reports.${reportType}.settings`]: settings
  }
  console.log('upd', upd)
  await db
    .collection('projects')
    .doc(projectId)
    .update(upd)
  const url = `${config.dynamicUrl}/publicApi/generateReport/${projectId}/${reportType}`
  await window.fetch(url)
}

export const openReportPdf = async (projectId, reportType, address) => {
  const url = `${config.dynamicUrl}/publicApi/getReportData/${projectId}/${reportType}`
  const request = await window.fetch(url)
  const { downloadUrl } = await request.json()
  const requestPdf = await window.fetch(downloadUrl)
  const responsePdf = await requestPdf.blob()
  saveAs(responsePdf, address)
}

export const shareReport = async (projectId, reportType, sendTo) => {
  try {
    console.log('shareReport', projectId, reportType, sendTo)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await window.fetch(`${config.dynamicUrl}/proto/shareReport`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ projectId, reportType, sendTo, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('shareReport error', e)
  }
}

export const updateReportSetting = async (projectId, reportType, key, value) => {
  const upd = {
    [`reports.${reportType}.settings.${key}`]: _.isUndefined(value) ? fieldValue.delete() : value
  }
  console.log('upd', upd)
  await db
    .collection('projects')
    .doc(projectId)
    .update(upd)
}

export const showAllBidsInReport = async (projectId, reportType, includedBids) => {
  const upd = {
    [`reports.${reportType}.settings.includedBids`]: includedBids
  }
  await db
    .collection('projects')
    .doc(projectId)
    .update(upd)
}

export const excludeAllBids = async (projectId, reportType, excludedBids) => {
  const upd = {
    [`reports.${reportType}.settings.includedBids`]: excludedBids
  }
  await db
    .collection('projects')
    .doc(projectId)
    .update(upd)
}

export const publishReport = async projectId => {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await window.fetch(`${config.dynamicUrl}/proto/publishScopeOfWorkToDropbox`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ projectId, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('shareReport error', e)
  }
}
