import _ from 'lodash'
import React from 'react'

import colors from 'shared/constants/colors'
import DocTableHeader from 'shared/documents/components/DocTableHeader'
import { renderHeader, renderScope, renderGeneralExclusions } from 'shared/documents/components/estimate/Table'
import { renderQuestons } from 'shared/documents/components/estimate/Questions'
import { StyledView } from 'shared/documents/components/StyledComponents'

const renderWorkOrderScope = params => {
  const { getSize, wo, contractorTypes } = params
  const tradeName = _.get(contractorTypes, [wo.tradeId, 'name'])
  const trade = _.get(wo, 'label', tradeName)
  const label = _.upperCase(trade)
  return [
    <DocTableHeader
      key={wo.id}
      getSize={getSize}
      title={label}
      color={colors.LIGHT_NAVY_BRIGHT}
      customStyle={`
        justify-content: flex-start;
        padding-top: ${getSize(80)}px
      `}
    />,
    renderHeader(getSize, `table_header_${wo.id}`, false),
    ...renderScope(getSize, wo.scope, wo.sections, wo.id, false, false),
    <StyledView
      key={wo.id}
      customStyle={`
        border: 0;
        border-style: solid;
        border-top-width: 1px;
        border-top-color: ${colors.TWILIGHT_BLUE}; width: 100%;
      `}
    />,
    renderGeneralExclusions(getSize, {}, wo.scope, wo.sections, wo.id),
    renderQuestons(getSize, {}, wo.scope, wo.sections, wo.id)
  ]
}

export default renderWorkOrderScope
