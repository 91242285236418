export default {
  AUTH: '/auth',
  ENTER_PHONE: '/auth/enter-phone',
  ENTER_CODE: '/auth/enter-code',
  ENTER_EMAIL: '/auth/enter-email',
  REQUEST_ACCESS: '/auth/requestaccess',
  SIGN_UP: '/auth/sign-up',
  SIGN_IN: '/auth/sign-in',
  // ADDITIONAL_INFO: '/auth/additional',
  ONBOARDING: '/onboarding',

  SUPPLY_ORDERS: '/supply-orders',
  SUPPLY_ORDERS_PROJECT_ADDRESS: '/supply-orders/create',
  SUPPLY_ORDER_INFO: '/supply-orders/:supplyOrderId',
  RESET: '/reset',
  WEB_MOBILE_PLACEHOLDER: '/web-mobile-placeholder',
  NOT_FOUND: '/404',
  HANDLE_HIC_INVITE: '/handleHicInvite/:inviteId',
  UPDATE_APP: '/update',

  APP: '/',
  PROPOSALS: '/proposals',
  PROJECTS: '/projects',
  REQUESTS: '/requests',

  ONBOARDING_INITIAL: '/onboarding/initial',
  ONBOARDING_PROFILE: '/onboarding/profile',
  ONBOARDING_WORK_TYPE: '/onboarding/typeofwork',
  ONBOARDING_LICENSE_TYPE: '/onboarding/licensetype',
  ONBOARDING_SERVICE_AREA: '/onboarding/servicearea',
  ONBOARDING_SERVICE_TYPES: '/onboarding/servicetypes',
  ONBOARDING_ONLINE_CONTACTS: '/onboarding/social',
  ONBOARDING_STRIPE: '/onboarding/stripe',
  ONBOARDING_USERS: '/onboarding/users',
  ONBOARDING_INVITE_USER: '/onboarding/inviteuser',
  ONBOARDING_ROLES: '/onboarding/roles',
  ONBOARDING_EDIT_ROLE: '/onboarding/editrole',
  ONBOARDING_CREATE_ROLE: '/onboarding/createrole',
  ONBOARDING_REFERRAL_AGREEMENT: '/onboarding/referralagreement',
  USER_ONBOARDING: '/useronboarding',
  USER_ONBOARDING_PROFILE: '/useronboarding/profile',
  USER_ONBOARDING_SERVICE_AREA: '/useronboarding/servicearea',
  USER_ONBOARDING_SERVICE_TYPES: '/useronboarding/servicetypes',

  // SETTINGS:
  SETTINGS: '/settings',
  COMPANY_PROFILE: '/settings/companyprofile',
  PROFILES: '/settings/profile',
  SETTINGS_SERVICE_AREA: '/settings/servicearea',
  SETTINGS_USER_SERVICE_AREA: '/settings/userservicearea',
  SETTINGS_CONTRACTOR_TYPE: '/settings/contractortype',
  SETTINGS_COMPANY_ADDRESS: '/settings/companyaddress',
  SETTINGS_PAYMENT_REQUESTS_APPROVE: '/settings/paymentrequests',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_NOTIFICATIONS_TOGGLES: '/settings/notifications/toggles',
  VERIFY_SALESPERSON: '/settings/verify-salesperson',
  ROLES: '/settings/roles',
  CREATE_ROLE: '/settings/createrole',
  EDIT_ROLE: '/settings/editrole/:roleId',
  USERS: '/settings/users',
  INVITE_USER: '/settings/userinvite',
  VERIFY_LICENSE: '/settings/verifylicense',
  PLAID_CONNECT: '/settings/plaidconnect',
  STRIPE_CONNECT: '/settings/stripeconnect',
  SETTINGS_SERVICE_TYPES: '/settings/servicetypes',
  SETTINGS_USER_SERVICE_TYPES: '/settings/userservicetypes',
  SETTINGS_PROPOSAL_TEMPLATE: '/settings/proposal',

  REQUESTS_SIGN_CONTRACT: '/requests/signcontract/:proposalId',
  REQUESTS_REFERRAL_AGREEMENT: '/requests/viewbid/referralagreement/sign',
  REQUESTS_VIEW_BID: '/requests/viewbid/:bidId',
  REQUESTS_VIEW_PAYMENT_REQUEST: '/requests/viewpaymentrequest/:requestId',
  REQUESTS_SUPPLY_ORDER_INFO: '/requests/supplyorder/:supplyOrderId',
  VIEW_WORK_ORDER: '/requests/joboffers/:workOrderId',

  PROJECT: '/project/:projectId',
  PROJECT_VIEW_PAYMENT_REQUEST: '/project/:projectId/viewpaymentrequest/:requestId',
  PROJECT_VIEW_BID: '/project/:projectId/viewbid/:bidId',
  PROJECT_REFERRAL_AGREEMENT: '/project/:projectId/viewbid/referralagreement/sign',
  PROJECT_VIEW_REIMBURSEMENT_REQUEST: '/project/:projectId/reimbursementrequest/:reimbursementRequestId',
  PROJECT_SUPPLY_ORDER_INFO: '/project/:projectId/supplyorder/:supplyOrderId',

  CREATE_WORK_ORDER: '/createworkorder/:projectId',
  WORK_ORDER_LINE_ITEM: '/createworkorder/:projectId/lineitem',
  CREATE_WORK_ORDER_INVITED_SUBS: '/createworkorder/:projectId/invitedSubs',

  WORK_ORDERS: '/workOrders',
  WORK_ORDER_INVITES: '/workOrders/:workOrderId',
  INVITED_SUB_CHAT: '/workOrders/:workOrderId/chat/:channelKey',
  INVITED_SUBS: '/workOrders/:workOrderId/invitedSubs',

  PARTNERS: '/partners/:projectId/:partnerAccountId',
  ACTIVE_SUB_CHAT: '/partners/:projectId/:partnerAccountId/channels/:channelKey',
  ACTIVE_SUB_WORK_ORDER: '/partners/:projectId/:partnerAccountId/workOrders/:workOrderId',

  PROPOSAL: '/proposal/:proposalId',
  EDIT_PROPOSAL: '/proposal/:proposalId',
  PROPOSAL_PROJECT_ADDRESS: '/proposal/:proposalId/address',
  PROPOSAL_MAILING_ADDRESS: '/proposal/:proposalId/mailingaddress',
  PROPOSAL_CREATE_LINE_ITEM: '/proposal/:proposalId/lineitem',
  EDIT_DEPOSIT: '/proposal/:proposalId/deposit',
  SET_PAYMENT_PLAN: '/proposal/:proposalId/schedule',
  SCHEDULE_OF_PROGRESS_PAYMENTS: '/proposal/:proposalId/editschedule',
  PAYMENTS_PLAN_OPTIONS: '/proposal/:proposalId/scheduleoptions',
  PROGRESS_PAYMENTS_PHASE: '/proposal/:proposalId/schedulephase',
  PROPOSAL_AGREEMENTS: '/proposal/:proposalId/agreements',

  CHATS: '/channels',
  CHAT: '/channels/:channelKey',

  LEADS: '/leads',
  LEADS_NEW: '/leads/new',
  APPOINTMENT_OFFER: '/leads/new/:appointmentId',
  APPOINTMENT_OFFER_PROPERTY_DATA: '/leads/new/:appointmentId/property',
  LEADS_SCHEDULED: '/leads/scheduled',
  LEADS_PAST: '/leads/past',
  APPOINTMENT_SCHEDULED: '/leads/scheduled/:appointmentId',
  APPOINTMENT_PAST: '/leads/past/:appointmentId',
  REFERRAL_AGREEMENT: 'referralagreement/sign',
  CREATE_QUOTE: '/createquote/:workOrderId',
  CREATE_QUOTE_EDIT_DEPOSIT: '/createquote/:workOrderId/deposit',
  CREATE_QUOTE_CREATE_LINE_ITEM: '/createquote/:workOrderId/lineitem',
  CREATE_QUOTE_PROGRESS_PAYMENT_PHASE: '/createquote/:workOrderId/paymentphase',
  CREATE_QUOTE_VIEW_ATTACHMENT: '/createquote/:workOrderId/attachment',
  CREATE_QUOTE_COMMENTS: '/createquote/:workOrderId/comments',
  SHARE_LINK: '/createquote/:workOrderId/sharelink',

  // redesign 4.0
  GC_APP: '/gc',
  GC_PROJECT: '/gc/project/:projectId',
  GC_PROJECT_WORKORDER: '/gc/project/:projectId/workorder/:workOrderId',
  GC_PROJECT_WORKORDER_LEVELING: '/gc/project/:projectId/workorder/:workOrderId/leveling',
  GC_PROJECT_WORKORDER_NO_SCOPE_NO_SUBS: '/gc/project/:projectId/workorder/:workOrderId/noscope',
  GC_PROJECT_WORKORDER_SELECT_OPTION: '/gc/project/:projectId/workorder/:workOrderId/options',
  GC_VIEW_BID: '/gc/bid/:bidId',
  GC_CONTACTS: '/gc/contacts',
  SUB_APP: '/sub',
  SUB_PROJECT: '/sub/project/:projectId',
  SUB_CREATE_BID: '/sub/project/:projectId/workorder/:workOrderId/bid',
  SUB_CREATE_BID_PREVIEW: '/sub/project/:projectId/workorder/:workOrderId/bidpreview',
  SUB_CREATE_PROPOSAL: '/sub/createproposal',
  INVITATION: '/invitation',
  PROPOSAL_TO_GC: '/subproposal',
  WORKORDER_FILE: '/workorderfile/:workOrderId/:fileId',
  ACCEPT_INVITE: '/acceptinvite',
  GC_PROJECT_SHARE: '/gc/project/:projectId/share/:reportType',
  PRECON: '/precon/:projectId',
  PRECON_PROJECT: '/precon/:projectId/project',
  PRECON_ORDER_DETAILS: '/precon/:projectId/orderdetails',
  PRECON_PAYMENT_PLAN: '/precon/:projectId/plan',
  PRECON_TEAMMATES: '/precon/:projectId/teammates',
  PRECON_BOOK_A_CALL: '/precon/:projectId/bookacall'
}
