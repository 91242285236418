import React from 'react'
import { View, Image } from 'react-native'
import styled from 'sc'
import _ from 'lodash'
// import Dash from 'react-native-dash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'

const Wrapper = styled(View)`
  width: 100%;
  padding-top: ${props => props.getSize(40)}px;
`

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-width: 1px;
  border-top-color: ${colors.BLACK20};
  padding-top: ${props => props.getSize(10)}px;
  border-style: solid;
`
const SignaturesContainer = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
`
const SignatureBlock = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const ImageContainer = styled(View)`
  width: ${props => props.getSize(100)}px;
  height: ${props => props.getSize(50)}px;
  margin-right: ${props => props.getSize(50)}px;
  /* background-color: yellow; */
`
const SignatureImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const Signature = styled(View)`
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Field = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 85%;
  height: ${props => props.getSize(28)}px;
`
const FieldTitle = styled(View)`
  flex: 1;
`
const FieldValue = styled(View)`
  flex: 2.8;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.BLACK40};
  border-style: solid;
`
// const StyledDash = styled(Dash)`
//   width: 85%;
//   height: 1px;
// `
const StyledDash = styled(View)`
  width: 85%;
  height: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.BLACK40};
  border-style: solid;
`

const GcSubSignature = ({ getSize, sig, ssr }) => {
  console.log('render GC&Sub signature', sig, ssr)

  const renderField = (k, v) => {
    return (
      <Field getSize={getSize}>
        <FieldTitle>
          <StyledText fontSize={getSize(12)}>{k}</StyledText>
        </FieldTitle>
        <FieldValue>
          <StyledText fontSize={getSize(12)} customstyle={`margin-left: ${getSize(10)}px`}>
            {v}
          </StyledText>
        </FieldValue>
      </Field>
    )
  }

  const renderDashLine = () => {
    return (
      <StyledDash dashColor={colors.BLACK40} dashLength={getSize(6)} dashThickness={getSize(1)} dashGap={getSize(2)} />
    )
  }

  const gcUrl = _.get(sig, 'gc.url')
  const subUrl = _.get(sig, 'sub.url')
  return (
    <Wrapper getSize={getSize}>
      <Container getSize={getSize}>
        <StyledText fontSize={getSize(14)} color={colors.BLACK70}>
          SIGNATURES
        </StyledText>
        <SignaturesContainer getSize={getSize}>
          <SignatureBlock>
            <Signature>
              <StyledText fontSize={getSize(12)} customstyle={`margin-top: ${getSize(15)}px`}>
                Contractor:
              </StyledText>
              <ImageContainer getSize={getSize}>
                {gcUrl && (
                  <SignatureImage
                    getSize={getSize}
                    source={{ uri: gcUrl }}
                    defaultSource={ssr ? { uri: gcUrl } : undefined}
                    resizeMode='contain'
                  />
                )}
              </ImageContainer>
            </Signature>
            {renderDashLine()}
            {renderField('Name:', _.get(sig, 'gc.name'))}
            {renderField('Date:', _.get(sig, 'gc.date'))}
            {renderField('License no.:', _.get(sig, 'gc.license'))}
          </SignatureBlock>
          <SignatureBlock>
            <Signature>
              <StyledText fontSize={getSize(12)} customstyle={`margin-top: ${getSize(15)}px`}>
                Subcontractor:
              </StyledText>
              <ImageContainer getSize={getSize}>
                {subUrl && (
                  <SignatureImage
                    getSize={getSize}
                    source={{ uri: subUrl }}
                    defaultSource={ssr ? { uri: subUrl } : undefined}
                    resizeMode='contain'
                  />
                )}
              </ImageContainer>
            </Signature>
            {renderDashLine()}
            {renderField('Name:', _.get(sig, 'sub.name'))}
            {renderField('Date:', _.get(sig, 'sub.date'))}
          </SignatureBlock>
        </SignaturesContainer>
      </Container>
    </Wrapper>
  )
}

export default GcSubSignature
