import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import ElevatedView from 'react-native-elevated-view'
import { View, ActivityIndicator, TouchableOpacity } from 'react-native'

import { StyledText } from 'shared/components/StyledComponents'
import { getHeight, getWidth } from 'shared/utils/dynamicSize'
import colors, { WHITE, LIGHT_NAVY, LIGHT_GREY_BLUE } from 'shared/constants/colors'
import { ACTIVE_OPACITY } from 'constants/index'

const StyledTouchableOpacity = styled(TouchableOpacity)`
  box-shadow: ${props => (props.disabled ? 'none' : `0px 2px 2px ${colors.BLACK40}`)};
  height: ${props => getHeight(props.height, props.viewport)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.borderRadius}px;
  elevation: ${props => (props.disabled ? 0 : 3)};
  background-color: ${props => props.color || LIGHT_NAVY};
  ${props => props.customStyle || ''}
`

const Spinner = styled(ActivityIndicator)`
  margin-left: ${props => getWidth(10, props.viewport)}px;
`
const TextWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: ${props => getWidth(16, props.viewport)}px;
  padding-right: ${props => getWidth(16, props.viewport)}px;
`

const PrimaryButton = ({
  viewport,
  onPress,
  onDisabledPress,
  title,
  loading,
  textColor,
  color,
  colorDisabled,
  disabled,
  textCustomStyle,
  textProps,
  noteText,
  customStyle,
  borderRadius,
  height,
  minWidth
}) => {
  let handlePress = onPress
  if (loading) handlePress = () => null
  if (disabled) handlePress = onDisabledPress
  return (
    <StyledTouchableOpacity
      activeOpacity={disabled ? 1 : ACTIVE_OPACITY}
      onPress={handlePress}
      color={disabled ? colorDisabled : color}
      borderRadius={borderRadius}
      viewport={viewport}
      height={height}
      customStyle={customStyle}
      disabled={disabled}
    >
      <TextWrapper viewport={viewport}>
        <StyledText color={textColor} fontSize={16} {...textProps} customStyle={textCustomStyle}>
          {title}
        </StyledText>
        {loading ? <Spinner color={WHITE} viewport={viewport} /> : null}
      </TextWrapper>
      {noteText && (
        <StyledText
          fontSize={11}
          color={LIGHT_GREY_BLUE}
          customStyle={`
                position: absolute;
                right: ${getWidth(10, viewport)}px;
                bottom: ${getHeight(5, viewport)}px;
              `}
        >
          {noteText}
        </StyledText>
      )}
    </StyledTouchableOpacity>
  )
}

PrimaryButton.defaultProps = {
  onPress: () => null,
  loading: false,
  color: LIGHT_NAVY,
  colorDisabled: colors.DISABLED,
  textColor: WHITE,
  disabled: false,
  textProps: {},
  borderRadius: 4,
  height: 50,
  minWidth: 160
}

PrimaryButton.propTypes = {
  viewport: PropTypes.object.isRequired,
  onPress: PropTypes.func,
  loading: PropTypes.bool,
  customStyle: PropTypes.string,
  textColor: PropTypes.string,
  color: PropTypes.string,
  colorDisabled: PropTypes.string,
  disabled: PropTypes.bool,
  textCustomStyle: PropTypes.string,
  textProps: PropTypes.object,
  borderRadius: PropTypes.number,
  height: PropTypes.number,
  minWidth: PropTypes.number
}

export default connect(state => ({ viewport: state.viewport }))(PrimaryButton)
