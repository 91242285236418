import React, { useContext } from 'react'
import { Box, Button, Avatar, Text } from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faDownload, faAngleLeft, faComments, faBell } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'

import colors from 'shared/constants/colors'
// import universalNavigation from 'utils/universalNavigation'
import LayoutContext from 'webPages/layout/LayoutContext'
import NavBarContainer from 'components/web/NavBarContainer'
import BidLevelIcon from 'assets/icons/bidLevelIcon.svg'
import { downloadFile } from 'controllers/workOrder'
import { getName, getInitials } from 'shared/utils/stringUtils'
import { navigateToHomePage } from 'controllers/init'
import { toTimestamp } from 'shared/utils/date'

const GcViewBidNavBar = ({ bid, accountsProfiles, dispatch }) => {
  const goBack = () => {
    // universalNavigation.goBack()
    dispatch(navigateToHomePage())
  }

  const subProfile = _.get(accountsProfiles, bid.accountId)

  const { openCommentsInbox, openToast } = useContext(LayoutContext)

  const onDownloadClick = async () => {
    const addressName = _.get(bid, 'projectAddress.name')
    const filename = `${getName(subProfile)} bid at ${addressName}.pdf`
    const res = await downloadFile(bid.pdfUrl, filename)

    if (!res) {
      openToast(
        <Box background={colors.CORAL_TWO} justify='center' align='center' pad='small' margin='small'>
          <Text color={colors.TEXT}>Error: file not found</Text>
        </Box>
      )
    }
  }

  const renderRightPart = () => {
    return (
      <Box direction='row' align='center' pad='none'>
        <Button
          onClick={onDownloadClick}
          icon={<FontAwesomeIcon icon={faDownload} size={20} />}
          color={colors.LIGHT_NAVY}
        />
        <Button
          onClick={openCommentsInbox}
          icon={<FontAwesomeIcon icon={faBell} size={20} />}
          color={colors.LIGHT_NAVY}
        />
        <Box border='left' pad={{ left: 'small' }} direction='row' align='center' gap='small'>
          <Button
            label='Compare and level your bids'
            secondary
            color={colors.LIGHT_NAVY}
            hoverTextColor={colors.WHITE}
            icon={<BidLevelIcon />}
            // onClick={goBack}
          />
        </Box>
      </Box>
    )
  }

  const renderAvatar = () => {
    const avatarUrl = _.get(subProfile, 'avatarSmall')
    return (
      <Box pad={{ right: 'small' }} flex={false}>
        <Avatar size='32px' src={avatarUrl} background={colors.LIGHT_NAVY_BRIGHT}>
          <Text color={colors.WHITE}>{getInitials(subProfile)}</Text>
        </Avatar>
      </Box>
    )
  }

  const openCommentsPanel = () => {
    console.log('openCommentsPanel')
  }

  const renderChatButton = () => {
    return (
      <Box direction='row'>
        <Box direction='row' gap='xsmall' align='center' onClick={() => openCommentsPanel()}>
          <FontAwesomeIcon icon={faComments} size={14} color={colors.MEDIUM_GREY} />
          <Text size='small' color={colors.MEDIUM_GREY}>{`Message ${getName(subProfile)}`}</Text>
        </Box>
      </Box>
    )
  }

  const renderLeftPart = () => {
    const addressName = _.get(bid, 'projectAddress.name')
    const bidCreateTime = toTimestamp(bid.timestamp)
    return (
      <Box direction='row' align='center'>
        {renderAvatar()}
        <Box>
          <Box direction='row' align='center' gap='small' pad={{ right: 'small' }}>
            <Text size='large' weight={500}>
              {getName(subProfile)}
            </Text>
            <Text size='large' weight={500}>
              -
            </Text>
            <Text size='large' weight={500}>
              {addressName}
            </Text>
            <Text size='xsmall' color={colors.VERY_LIGHT_PINK} margin={{ left: 'medium' }}>{`Bid created ${moment(
              bidCreateTime
            ).fromNow()}`}</Text>
          </Box>
          {renderChatButton()}
        </Box>
      </Box>
    )
  }

  const renderNavBar = () => {
    return (
      <NavBarContainer>
        <Box direction='row' align='center' pad='none'>
          <Box pad={{ horizontal: 'small' }} onClick={goBack}>
            <FontAwesomeIcon icon={faAngleLeft} size={24} color={colors.BLACK} />
          </Box>
          {renderLeftPart()}
        </Box>
        {renderRightPart()}
      </NavBarContainer>
    )
  }

  return renderNavBar()
}

GcViewBidNavBar.propTypes = {
  onNextClick: PropTypes.func,
  nextDisabled: PropTypes.bool
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: state.accountsProfiles,
  workOrders: state.workOrders
})

export default connect(mapStateToProps)(GcViewBidNavBar)
