import React, { Component } from 'react'
import { Layer, Button, Box, Text, Card, CardHeader, CardBody, CardFooter, ThemeContext, List } from 'grommet'
import _ from 'lodash'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import { getDiscountAmount } from 'shared/utils/bid'
import { getProgressPaymentsSum } from 'shared/utils/proposal'
import { timeZone as defaultTimeZone } from 'shared/constants/index'
import { toTimestamp } from 'shared/utils/date'
import { generateId } from 'constants/firebase'
import InputPhaseName from 'webPages/createBid/scheduleOfPaymentsModal/InputPhaseName'
import InputPhaseDate from 'webPages/createBid/scheduleOfPaymentsModal/InputPhaseDate'
import InputCostPercOrFlat from 'webPages/createBid/scheduleOfPaymentsModal/InputCostPercOrFlat'
import DiscountInput from 'components/createBid/DiscountInput.web'
import amountType from 'shared/constants/amountType'

class ScheduleOfPaymentsModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {},
      focusedRows: {}
    }
  }

  open = () => {
    const { getField } = this.context
    const progressPayments = getField('progressPayments', {})
    this.setState({
      visible: true,
      progressPayments,
      highlightIncomlete: false
    })
  }

  getBalanceDue = () => {
    const { getField } = this.context
    const total = getField('total')
    const deposit = getField('deposit')
    const depositValue = getDiscountAmount(deposit, total)
    return total - depositValue
  }

  close = () => {
    this.setState({ visible: false })
  }

  save = () => {
    console.log('save')
    if (this.hasIncompleteFields()) {
      this.setState({ highlightIncomlete: true })
    } else {
      const { setField } = this.context
      const { progressPayments } = this.state
      setField('progressPayments')(progressPayments)
      this.close()
    }
  }

  renderDepositInput = () => {
    const { getField, setDepositValue, setDepositType } = this.context
    const deposit = getField('deposit')
    const type = _.get(deposit, 'type', amountType.FLAT_AMOUNT)
    const value = _.get(deposit, 'value')
    return (
      <DiscountInput
        title=''
        discountType={type}
        discountValue={value}
        setDiscountType={setDepositType}
        setDiscountValue={setDepositValue}
        boxProps={{ border: undefined, gap: undefined, pad: undefined }}
        inputBoxProps={{
          customStyle: `color: ${colors.TEXT_PRIMARY};`,
          width: '100%',
          height: '40px'
        }}
        inputProps={{
          size: 'xlarge',
          customStyle: 'padding: 0px 2px; font-weight: 600;',
          placeholder: '-'
        }}
        titleProps={{ color: colors.TEXT }}
      />
    )
  }

  renderHeader = () => {
    const balanceDue = this.getBalanceDue()
    return (
      <React.Fragment>
        <Box direction='row' align='center' justify='center' gap='medium'>
          <Box basis='1/2' direction='row' align='center' justify='end'>
            <Text size='large' color={colors.BLACK}>
              Contract balance due:
            </Text>
          </Box>
          <Box basis='1/2'>
            <Box width='195px' height='38px' align='center' justify='center' border round='xsmall'>
              <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT} weight={600}>
                {numeral(balanceDue).format('$ 0,0.00')}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box direction='row' align='center' justify='center' gap='medium' pad={{ top: 'small' }}>
          <Box basis='1/2' direction='row' align='center' justify='end'>
            <Text size='large' color={colors.BLACK}>
              Deposit:
            </Text>
          </Box>
          <Box basis='1/2'>
            <Box width='195px' height='38px' align='center' justify='center' round='xsmall'>
              {this.renderDepositInput()}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    )
  }

  renderTableHeader = () => {
    return (
      <Box pad={{ top: 'medium' }}>
        <Text color={colors.BROWN_GREY_TWO} size='small'>
          Progress Payments
        </Text>
      </Box>
    )
  }

  onChangePhase = (id, key, value) => {
    const { progressPayments } = this.state
    this.setState({
      progressPayments: {
        ...progressPayments,
        [id]: {
          ..._.get(progressPayments, id, {}),
          [key]: value
        }
      }
    })
  }

  deletePhase = phaseId => () => {
    const { progressPayments } = this.state
    const newProgressPayments = { ...progressPayments }
    _.unset(newProgressPayments, phaseId)
    this.setState({ progressPayments: newProgressPayments })
  }

  setRowFocused = (phaseId, v) => {
    const { focusedRows } = this.state
    console.log('set row focused', phaseId, v)
    this.setState({
      focusedRows: { ...focusedRows, [phaseId]: v },
      highlightIncomlete: false
    })
  }

  isPhaseIncomplete = pp => {
    return _.isEmpty(_.toString(pp.name)) || _.isEmpty(_.toString(pp.date)) || _.get(pp, 'cost.value', 0) <= 0
  }

  renderPhase = phase => {
    // const border = { side: 'all', color: 'red' }
    const { address } = this.context
    const balanceDue = this.getBalanceDue()
    const { focusedRows, highlightIncomlete, progressPayments } = this.state
    const timeZone = _.get(address, 'timeZone', defaultTimeZone)
    const progressPaymentsSum = getProgressPaymentsSum(progressPayments, balanceDue)
    const defaultPhaseValue = balanceDue - progressPaymentsSum
    const phaseMaxValue = _.max([0, defaultPhaseValue + getDiscountAmount(phase.cost, balanceDue)])
    return (
      <Box
        direction='row'
        key={phase.id}
        background={_.get(focusedRows, phase.id, false) ? colors.AQUA_MARINE_LIGHT : undefined}
        // border={
        //   highlightIncomlete
        //     ? { style: 'inset', side: 'horizontal', color: phaseIncomlete ? colors.CORAL_TWO : undefined }
        //     : undefined
        // }
      >
        <Box basis='full'>
          <InputPhaseName
            name={_.get(phase, 'name', '')}
            onChangePhase={this.onChangePhase}
            phaseId={phase.id}
            onFocus={() => this.setRowFocused(phase.id, true)}
            onBlur={() => this.setRowFocused(phase.id, false)}
            highlightIncomlete={highlightIncomlete}
          />
        </Box>
        <Box align='center' justify='center' direction='row' width='120px' flex={{ shrink: 0 }}>
          <InputPhaseDate
            date={_.get(phase, 'date')}
            onChangePhase={this.onChangePhase}
            phaseId={phase.id}
            timeZone={timeZone}
            onFocus={() => this.setRowFocused(phase.id, true)}
            onBlur={() => this.setRowFocused(phase.id, false)}
            highlightIncomlete={highlightIncomlete}
          />
        </Box>
        <Box align='center' justify='center' width='220px' flex={{ shrink: 0 }}>
          <InputCostPercOrFlat
            cost={_.get(phase, 'cost')}
            onChangePhase={this.onChangePhase}
            phaseId={phase.id}
            onFocus={() => this.setRowFocused(phase.id, true)}
            onBlur={() => this.setRowFocused(phase.id, false)}
            highlightIncomlete={highlightIncomlete}
            maxValue={phaseMaxValue}
            balanceDue={balanceDue}
          />
        </Box>
        <Box align='end' justify='center' width='40px' flex={{ shrink: 0 }}>
          <Button
            icon={<FontAwesomeIcon icon={faTrashAlt} size={18} />}
            color={colors.VERY_LIGHT_PINK}
            onClick={this.deletePhase(phase.id)}
          />
        </Box>
      </Box>
    )
  }

  hasIncompleteFields = () => {
    const { progressPayments } = this.state
    return _.reduce(
      progressPayments,
      (res, pp) => {
        return res || this.isPhaseIncomplete(pp)
      },
      false
    )
  }

  addPhase = () => {
    const incomplete = this.hasIncompleteFields()
    if (incomplete) {
      this.setState({ highlightIncomlete: true })
    } else {
      const phaseId = generateId()
      const ph = { id: phaseId }
      // console.log('add phase', ph)
      this.setState({ progressPayments: { ...this.state.progressPayments, [phaseId]: ph } })
    }
  }

  renderAddPhaseButton = () => {
    const balanceDue = this.getBalanceDue()
    const { progressPayments } = this.state
    const progressPaymentsSum = getProgressPaymentsSum(progressPayments, balanceDue)
    const defaultPhaseValue = balanceDue - progressPaymentsSum
    if (defaultPhaseValue > 0) {
      return (
        <Box direction='row' pad={{ top: 'small' }} key='add' flex={{ shrink: 0 }}>
          <Button icon={<FontAwesomeIcon icon={faPlus} />} label='Add phase of work' onClick={this.addPhase} />
        </Box>
      )
    }
  }

  renderDefaultPhase = () => {
    const balanceDue = this.getBalanceDue()
    const { progressPayments } = this.state
    const progressPaymentsSum = getProgressPaymentsSum(progressPayments, balanceDue)
    console.log('balanceDue', balanceDue, 'progressPaymentsSum', progressPaymentsSum)
    const defaultPhaseValue = balanceDue - progressPaymentsSum
    if (defaultPhaseValue > 0) {
      return (
        <Box
          direction='row'
          background={colors.LIGHT_NAVY_BRIGHT}
          height={{ min: '48px' }}
          round={{ size: 'xsmall', corner: 'top' }}
        >
          <Box basis={'3/4'} direction='row' align='center' pad={{ left: 'small' }}>
            <Text weight={600} color={colors.WHITE}>
              Completion of all work
            </Text>
          </Box>
          <Box basis={'1/4'} />
          <Box basis={'1/4'} align='center' direction='row' pad={{ left: 'small' }}>
            <Text weight={600} color={colors.WHITE}>
              {numeral(defaultPhaseValue).format('$0,0.00')}
            </Text>
          </Box>
          <Box width={{ min: '40px' }} />
        </Box>
      )
    }
  }

  renderProgressPayments = () => {
    const { progressPayments } = this.state
    const data = _.map(progressPayments, (pp, ppId) => {
      return {
        ...pp,
        timestamp: pp.date ? toTimestamp(pp.date) : Number.MAX_VALUE
      }
    })
    return (
      <Box pad={{ top: 'small' }}>
        <Box overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
          {this.renderDefaultPhase()}
          <List data={_.sortBy(data, 'timestamp')} children={this.renderPhase} pad='none' />
        </Box>
        {this.renderAddPhaseButton()}
      </Box>
    )
    // return res
  }

  render () {
    const { visible } = this.state
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card>
            <CardHeader
              pad='medium'
              border={{
                color: 'border',
                size: '1px',
                side: 'bottom'
              }}
            >
              <Text size='xlarge' color={colors.BLACK} weight={600}>
                Schedule of payments
              </Text>
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium', top: 'medium' }} wrap={false} width='large'>
              <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
                {this.renderHeader()}
                {this.renderTableHeader()}
                <Box>{this.renderProgressPayments()}</Box>
              </ThemeContext.Extend>
            </CardBody>
            <CardFooter align='center' justify='end'>
              <Button primary margin='medium' label={'Save'} onClick={this.save} customStyle='border-radius: 4px' />
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

ScheduleOfPaymentsModal.contextType = CreateBidContext

ScheduleOfPaymentsModal.propTypes = {}

export default ScheduleOfPaymentsModal
