import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react'
import { Box, Text, List, Button } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import NavBar from 'webPages/layout/NavBar'
import LayoutContext from 'webPages/layout/LayoutContext'
import NavBarContainer from 'components/web/NavBarContainer'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { getAllProjectsInfoList, getAllProjectsInfoWithArchived } from 'model/selectors/projectsInfoSelector'
import AddProjectRow from 'webPages/projectsList/AddProjectRow'
import ProjectRow from 'webPages/projectsList/ProjectRow'

const ProjectsList = ({ projects, projectsWithArchived }) => {
  const { openProjectModal, toggleSideMenu } = useContext(LayoutContext)
  const [archivedVisible, setArchivedVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => toggleSideMenu(true), 700)
  }, [toggleSideMenu])

  const data = useMemo(() => {
    const lst = archivedVisible ? projectsWithArchived : projects
    return [{ id: null }, ...lst]
  }, [projects, projectsWithArchived, archivedVisible])

  const onProjectClick = useCallback(projectId => {
    universalNavigation.push(screens.GC_PROJECT, { projectId })
  }, [])

  const renderRow = useCallback(
    item => {
      const id = _.get(item, 'id')
      if (_.isNil(id)) {
        return <AddProjectRow onAddProjectClick={openProjectModal} />
      } else {
        return <ProjectRow onClick={onProjectClick} project={item} onEditProjectClick={openProjectModal} />
      }
    },
    [openProjectModal, onProjectClick]
  )

  const renderArchivedDisplayMode = () => {
    const label = archivedVisible ? 'Hide archived bid invitations' : 'Show archived bid invitations'
    return (
      <Box direction='row' flex={{ shrink: 0 }}>
        <Button label={label} color={colors.VERY_LIGHT_PINK} onClick={() => setArchivedVisible(!archivedVisible)} />
      </Box>
    )
  }

  return (
    <Box>
      <NavBarContainer>
        <NavBar fill='horizontal' pad={{ vertical: 'small', horizontal: 'medium' }} border={false}>
          <Text weight={600} size='large'>
            Projects
          </Text>
        </NavBar>
      </NavBarContainer>
      <Box fill align='center'>
        <Box gap='small' pad={{ vertical: 'xlarge' }}>
          <Box flex={{ shrink: 0 }}>
            <Text color={colors.BLACK} size='large' weight={700}>
              Projects
            </Text>
          </Box>
          <Box width='500px' overflow={{ horizontal: 'hidden', vertical: 'auto' }}>
            <List data={data} primaryKey='id' children={renderRow} pad='none' />
          </Box>
          {renderArchivedDisplayMode()}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  projects: getAllProjectsInfoList(state),
  projectsWithArchived: getAllProjectsInfoWithArchived(state)
})

export default connect(mapStateToProps)(ProjectsList)
