import React, { useState } from 'react'
import { Box, Text, DropButton, Button } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown, faArrowToRight, faComments } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import DropContent from 'components/DropContent'
import Subheader from 'webPages/comments/Subheader'
import { cutString } from 'shared/utils/stringUtils'

const Header = ({ name, items, id, scope, setCommentId, accountId }) => {
  const [show, setShow] = useState(false)
  const label = _.get(items, [id, 'name'], _.get(scope, [id, 'name']))

  const handleSelect = e => {
    if (_.isFunction(setCommentId)) setCommentId(e)
    setShow(false)
  }

  const DropLabel = (
    <Box direction='row' align='center'>
      <Text size='large' margin={{ right: 'small' }} color={colors.TEXT_PRIMARY} weight={700}>
        {cutString(label, 120)}
      </Text>
      <FontAwesomeIcon icon={faChevronDown} size={16} color={colors.LIGHT_NAVY_BRIGHT} />
    </Box>
  )

  const dropContent = (
    <DropContent
      boxProps={{
        width: '100%'
      }}
      onOptionClick={handleSelect}
      title='Select item:'
      selectedId={id}
      options={_.map(items, item => ({
        id: item.id,
        label: _.get(items, [item.id, 'name'], _.get(scope, [item.id, 'name']))
      }))}
    />
  )

  return (
    <Box height='150px' flex={{ shrink: 0 }}>
      <Box pad='small' border='bottom' direction='row' align='center' justify='between'>
        <Box justify='start' align='start'>
          <DropButton
            plain
            label={DropLabel}
            open={show}
            onOpen={() => setShow(true)}
            onClose={() => setShow(false)}
            dropContent={dropContent}
            dropProps={{ align: { top: 'bottom' } }}
            dropAlign={{ top: 'bottom', right: 'right' }}
          />
          <Box direction='row'>
            <Button
              label={<i>{name}</i>}
              reverse
              icon={<FontAwesomeIcon icon={faComments} size={14} />}
              onClick={() => {
                if (_.isFunction(setCommentId)) setCommentId(accountId)
              }}
            />
          </Box>
        </Box>
        <Box onClick={() => {
          if (_.isFunction(setCommentId)) setCommentId(null)
        }}>
          <FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.ANOTHER_GREY} />
        </Box>
      </Box>
      <Subheader name={name} />
    </Box>
  )
}

export default Header
