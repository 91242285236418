import { useEffect, useState } from 'react'

import config from 'shared/config'

const HubspotLiveChat = () => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    const script = document.createElement('script')
    const appendChat = () => {
      setActive(true)
      script.type = 'text/javascript'
      script.id = 'hs-script-loader'
      script.async = true
      script.defer = true
      script.src = config.hubspotChatSrc
      document.body.appendChild(script)
    }
    if (!active) {
      appendChat()
    }
  }, [active])
  return null
}

export default HubspotLiveChat
