import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, FormField, TextInput, ThemeContext, Select, TextArea } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import ProjectFiles from 'webPages/projectModal/ProjectFiles'
import DatePicker from 'components/DatePicker'
import AddressAutocompleteGrommet from 'components/AddressAutocompleteGrommet'
import { getAddress } from 'shared/utils/stringUtils'
import { defaultTimeZone } from 'shared/constants/index'
import projectTypes from 'shared/constants/projectTypes'
import workTypes from 'shared/constants/workTypes'

const CreateProjectForm = ({ isNew, projectId, params, onChange }) => {
  const {
    address,
    apartment,
    projectType,
    typeOfWork,
    size,
    attachments,
    startDate,
    timeZone,
    description,
    orderDetails,
    sizeMason
  } = params
  const [addressFocused, setAddressFocused] = useState(false)

  const canChangeSize =
    _.isNil(sizeMason) && !_.has(orderDetails, 'scopes.paid') && !_.has(orderDetails, 'takeoffs.paid')

  const onFocusAddress = () => setAddressFocused(true)
  const onBlurAddress = () => setAddressFocused(false)
  const onChangeApartment = e => onChange('apartment')(_.get(e, 'target.value'))
  const onChangeProjectType = ({ value }) => {
    console.log('onChangeProjectType', value)
    const projectTypeId = _.get(value, 'id')
    if (!_.isEmpty(projectTypeId)) onChange('projectType')(projectTypeId)
  }
  const onChangeTypeOfWork = ({ value }) => {
    console.log('onChangeTypeOfWork', value)
    const typeOfWorkId = _.get(value, 'id')
    if (!_.isEmpty(typeOfWorkId)) onChange('typeOfWork')(typeOfWorkId)
  }
  const onChangeSize = e => onChange('size')(_.get(e, 'target.value'))

  const projectTypesOptions = useMemo(() => {
    return _.map(projectTypes.list, k => ({ id: k, title: projectTypes.stringOfType(k) }))
  }, [])

  const workTypesOptions = useMemo(() => {
    return _.map(workTypes.list, k => ({ id: k, title: workTypes.stringOfType(k) }))
  }, [])

  const renderOptionLabel = (title, selected) => {
    return (
      <Text size={'medium'} color={selected ? colors.LIGHT_NAVY : colors.TEXT} weight={selected ? 600 : 400}>
        {title}
      </Text>
    )
  }

  const renderOption = (option, index, options, { active, disabled, selected }) => {
    // console.log('renderOption', option, index, options, active, disabled, selected)
    return (
      <Box direction='row' pad={{ vertical: 'xsmall', horizontal: 'small' }}>
        {renderOptionLabel(option.title, selected)}
      </Box>
    )
  }

  const projectTypeValue = _.find(projectTypesOptions, option => option.id === projectType)
  const typeOfWorkValue = _.find(workTypesOptions, option => option.id === typeOfWork)

  return (
    <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
      <Box flex={false} direction='row' margin={{ top: 'medium' }}>
        <FormField
          label='Project address'
          basis='2/3'
          round='small'
          margin={{ right: 'small' }}
          error={
            !addressFocused && !_.has(address, 'timeZone') && !_.isEmpty(address) && 'Please enter a valid address'
          }
        >
          <AddressAutocompleteGrommet
            onSelect={onChange('address')}
            value={getAddress(address)}
            placeholder='Type an address...'
            disabled={!isNew}
            onFocus={onFocusAddress}
            onBlur={onBlurAddress}
          />
        </FormField>
        <FormField label='Apt, suite, etc. (optional)' margin={{ left: 'small' }} flex basis='1/3' round='small'>
          <TextInput
            color='dark-1'
            maxLength={20}
            value={_.toString(apartment)}
            onChange={onChangeApartment}
            plain
            disabled={!isNew}
          />
        </FormField>
      </Box>

      <Box flex={false} direction='row' gap='small' margin={{ top: 'medium' }}>
        <FormField label='Project type' basis='1/3' round='small'>
          <Select
            placeholder='optional'
            color='dark-1'
            value={projectTypeValue}
            onChange={onChangeProjectType}
            options={projectTypesOptions}
            icon={false}
            children={renderOption}
            labelKey='title'
          />
        </FormField>
        <FormField label='Commercial or residential' basis='1/3' round='small'>
          <Select
            placeholder='optional'
            color='dark-1'
            value={typeOfWorkValue}
            onChange={onChangeTypeOfWork}
            options={workTypesOptions}
            icon={false}
            children={renderOption}
            labelKey='title'
          />
        </FormField>
        <FormField label='Project square feet' basis='1/3' round='small'>
          <TextInput
            type='number'
            placeholder='project size'
            color='dark-1'
            value={sizeMason || size || ''}
            onChange={onChangeSize}
            disabled={!canChangeSize}
          />
        </FormField>
      </Box>

      <Text size='small' margin={{ top: 'medium' }} color={colors.BROWN_GREY_TWO}>
        Files
      </Text>
      <ProjectFiles files={attachments} setFiles={onChange('attachments')} storagePath={`/projects/${projectId}/`} />

      <Text size='small' margin={{ top: 'large' }} color={colors.BROWN_GREY_TWO}>
        Project timeline
      </Text>
      <Box direction='row' margin={{ vertical: 'small' }} height={{ min: 'xxsmall' }}>
        <DatePicker
          title='Expected Start Date'
          value={startDate}
          timeZone={timeZone || defaultTimeZone}
          onChange={onChange('startDate')}
        />
      </Box>

      <Text size='small' margin={{ top: 'small' }} color={colors.BROWN_GREY_TWO}>
        Notes
      </Text>
      <Box margin={{ vertical: 'small' }} height={{ min: 'xsmall' }}>
        <TextArea
          placeholder='optional'
          value={description || ''}
          onChange={e => onChange('description')(e.target.value)}
          fill
          resize={false}
        />
      </Box>
    </ThemeContext.Extend>
  )
}

CreateProjectForm.propTypes = {
  isNew: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default CreateProjectForm
