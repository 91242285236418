import * as types from 'model/actionTypes'

export function receiveReferences (references) {
  return {
    type: types.RECEIVE_REFERENCES,
    references
  }
}

export function receiveJobTypes (jobTypes) {
  return {
    type: types.RECEIVE_JOB_TYPES,
    jobTypes
  }
}

export function receiveContractorTypes (contractorTypes) {
  return {
    type: types.RECEIVE_CONTRACTOR_TYPES,
    contractorTypes
  }
}

export function receiveProjectTypes (projectTypes) {
  return {
    type: types.RECEIVE_PROJECT_TYPES,
    projectTypes
  }
}

export function receiveProjectTypeGroups (projectTypeGroups) {
  return {
    type: types.RECEIVE_PROJECT_TYPE_GROUPS,
    projectTypeGroups
  }
}

export function receiveTerritories (territories) {
  return {
    type: types.RECEIVE_TERRITORIES,
    territories
  }
}

export function receiveDivisions (divisions) {
  return {
    type: types.RECEIVE_DIVISIONS,
    divisions
  }
}

export function receiveDivisionsSubgroups (subgroups) {
  return {
    type: types.RECEIVE_DIVISIONS_SUBGROUPS,
    subgroups
  }
}

export function receivePricingPlansGroups (pricingPlansGroups) {
  return {
    type: types.RECEIVE_PRICING_PLANS_GROUPS,
    pricingPlansGroups
  }
}

export function receivePricingPlans (pricingPlans) {
  return {
    type: types.RECEIVE_PRICING_PLANS,
    pricingPlans
  }
}
