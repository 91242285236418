import _ from 'lodash'
import config from 'shared/config'

const sendRequest = (api, query, onResult, needComponents = true) => {
  const request = new XMLHttpRequest()
  request.timeout = 5000
  request.onreadystatechange = () => {
    if (request.readyState !== 4) {
      return
    }
    if (request.status === 200) {
      const responseJSON = JSON.parse(request.responseText)
      onResult(responseJSON)
    }
  }
  request.onerror = e => {
    console.log('sendRequest error', e)
    onResult(null)
  }
  const paramsObj = {
    ...query,
    key: config.googleMapsApiKey
  }
  if (needComponents) paramsObj.components = 'country:us' // components=country:us|country:pr|c
  const params = Object.keys(paramsObj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(paramsObj[k])}`)
    .join('&')
  const url = `${api}/json?${params}`
  request.open('GET', url)
  request.send()
  return request
}

export const autocomplete = (input, sessiontoken, onResult) => {
  const options = {
    input,
    sessiontoken,
    bounds: {
      north: 49.332257,
      south: 28.08164,
      west: -125.860872,
      east: -65.392122
    }
  }

  const service = new google.maps.places.AutocompleteService()
  service.getQueryPredictions(options, predictions => onResult({ predictions }))

  return { abort: () => {} }
}

export const getPlaceDetails = (placeId, onResult) => {
  const options = {
    placeId,
    fields: ['address_components', 'geometry', 'formatted_address', 'name']
  }
  const service = new google.maps.places.PlacesService(document.createElement('div'))
  return service.getDetails(options, result => onResult({ result, status: 'OK' }))
}

export const getTimezone = location => {
  return new Promise((resolve, reject) => {
    const options = {
      location: `${location.lat},${location.lng}`,
      timestamp: _.ceil(_.now() / 1000)
    }
    console.log('getTimezone for location', location)
    console.log('options', options)
    return sendRequest('https://maps.googleapis.com/maps/api/timezone', options, resolve)
  })
}

export const getPlaceDetailsFormatted = (placeId, structured = null) =>
  new Promise((resolve, reject) => {
    getPlaceDetails(placeId, async response => {
      const status = _.get(response, 'status')
      if (status === 'OK') {
        const details = _.get(response, 'result', {})
        const address = _.get(details, 'address_components', [])
        // console.log('place details address', address)
        const res = {
          structured,
          description: _.get(details, 'formatted_address', null),
          location: {},
          name: _.get(details, 'name', null)
        }

        const location = _.get(details, 'geometry.location', null)
        if (location) {
          res.location.lat = location.lat()
          res.location.lng = location.lng()
        }

        for (const component of address) {
          const t = _.get(component, ['types', 0])
          switch (t) {
            case 'locality':
              _.set(res, 'city', _.get(component, 'long_name', null))
              break
            case 'administrative_area_level_2':
              _.set(res, 'county', _.get(component, 'long_name', null))
              break
            case 'administrative_area_level_1':
              _.set(res, 'state', _.get(component, 'long_name', null))
              _.set(res, 'stateAbbr', _.get(component, 'short_name', null))
              break
            case 'postal_code':
              _.set(res, 'zipcode', _.get(component, 'long_name', null))
              break
          }
        }
        const timezoneInfo = await getTimezone(res.location)
        const timezoneId = _.get(timezoneInfo, 'timeZoneId')
        if (!_.isEmpty(timezoneId)) res.timeZone = timezoneId
        resolve(res)
      } else {
        reject(new Error('google api error, status', status))
      }
    })
  })

export const getCoordsByAddress = (address, onResult) => {
  const options = {
    address
  }
  const geocoder = new google.maps.Geocoder()
  return geocoder.geocode(options, onResult)
}
