import * as types from 'model/actionTypes'

const initialState = {}

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_REFERENCES:
      return {
        ...state,
        ...action.references
      }
    case types.RECEIVE_JOB_TYPES:
      return {
        ...state,
        jobTypes: action.jobTypes
      }
    case types.RECEIVE_CONTRACTOR_TYPES:
      return {
        ...state,
        contractorTypes: action.contractorTypes
      }
    case types.RECEIVE_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: action.projectTypes
      }
    case types.RECEIVE_PROJECT_TYPE_GROUPS:
      return {
        ...state,
        projectTypeGroups: action.projectTypeGroups
      }
    case types.RECEIVE_TERRITORIES:
      return {
        ...state,
        territories: action.territories
      }
    case types.RECEIVE_DIVISIONS:
      return {
        ...state,
        divisions: action.divisions
      }
    case types.RECEIVE_DIVISIONS_SUBGROUPS:
      return {
        ...state,
        divisionsSubgroups: action.subgroups
      }
    case types.RECEIVE_PRICING_PLANS:
      return {
        ...state,
        pricingPlans: action.pricingPlans
      }
    case types.RECEIVE_PRICING_PLANS_GROUPS:
      return {
        ...state,
        pricingPlansGroups: action.pricingPlansGroups
      }
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
