export default {
  APP: 'APP',
  AUTH: 'AUTH',
  LOADING: 'LOADING',
  UPDATE_APP: 'UPDATE_APP',
  INIT_SCREEN: 'INIT_SCREEN',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  REQUESTS: 'REQUESTS',
  PROJECTS: 'PROJECTS',
  PROPOSALS: 'PROPOSALS',
  CHATS: 'CHATS',
  CREATE_PROPOSAL: 'CREATE_PROPOSAL',
  EDIT_PROPOSAL: 'EDIT_PROPOSAL',
  SETTINGS: 'SETTINGS',
  USERS: 'USERS',
  INVITE_USER: 'INVITE_USER',
  ROLES: 'ROLES',
  COMPANY_PROFILE: 'COMPANY_PROFILE',
  PROFILES: 'PROFILES',
  EDIT_ROLE: 'EDIT_ROLE',
  CREATE_ROLE: 'CREATE_ROLE',
  CREATE_LINE_ITEM: 'CREATE_LINE_ITEM',
  SET_PAYMENT_PLAN: 'SET_PAYMENT_PLAN',
  PAYMENTS_PLAN_OPTIONS: 'PAYMENTS_PLAN_OPTIONS',
  EDIT_DEPOSIT: 'EDIT_DEPOSIT',
  PROJECT_ADDRESS: 'PROJECT_ADDRESS',
  MAILING_ADDRESS: 'MAILING_ADDRESS',
  AMOUNT_TO_FINANCE: 'AMOUNT_TO_FINANCE',
  SCHEDULE_OF_PROGRESS_PAYMENTS: 'SCHEDULE_OF_PROGRESS_PAYMENTS',
  PROGRESS_PAYMENTS_PHASE: 'PROGRESS_PAYMENTS_PHASE',
  AGREEMENTS: 'AGREEMENTS',
  PROPOSAL_SIGNATURE: 'PROPOSAL_SIGNATURE',
  REVIEW_PROPOSAL: 'REVIEW_PROPOSAL',
  PREVIEW_PROPOSAL: 'PREVIEW_PROPOSAL',
  PROJECT: 'PROJECT',
  PROJECT_ADMINS: 'PROJECT_ADMINS',
  CREATE_WORK_ORDER: 'CREATE_WORK_ORDER',
  WORK_ORDER_LINE_ITEM: 'WORK_ORDER_LINE_ITEM',
  INVITED_SUBS: 'INVITED_SUBS',
  WORK_ORDER_INVITES: 'WORK_ORDER_INVITES',
  CHAT: 'CHAT',
  INVITED_SUB_CHAT: 'INVITED_SUB_CHAT',
  ACTIVE_SUB: 'ACTIVE_SUB',
  ACTIVE_SUB_CHAT: 'ACTIVE_SUB_CHAT',
  ACTIVE_SUB_WORK_ORDER: 'ACTIVE_SUB_WORK_ORDER',
  WORK_ORDER_INFO: 'WORK_ORDER_INFO',
  VERIFY_LICENSE: 'VERIFY_LICENSE',
  VERIFY_SALESPERSON: 'VERIFY_SALESPERSON',
  VERIFY_SUBCONTRACTOR: 'VERIFY_SUBCONTRACTOR',
  STRIPE_CONNECT: 'STRIPE_CONNECT',
  SIGN_CONTRACT: 'SIGN_CONTRACT',
  SUPPLY_ORDERS: 'SUPPLY_ORDERS',
  SUPPLY_ORDERS_PROJECT_ADDRESS: 'SUPPLY_ORDERS_PROJECT_ADDRESS',
  SUPPLY_ORDERS_SELECT_SUPPLIER: 'SUPPLY_ORDERS_SELECT_SUPPLIER',
  SUPPLY_ORDERS_DETAILS: 'SUPPLY_ORDERS_DETAILS',
  SUPPLY_ORDERS_SELECT_TERM: 'SUPPLY_ORDERS_SELECT_TERM',
  SUPPLY_ORDERS_REVIEW_ORDER: 'SUPPLY_ORDERS_REVIEW_ORDER',
  SUPPLY_ORDER_INFO: 'SUPPLY_ORDER_INFO',
  PROJECT_SUPPLY_ORDER_INFO: 'PROJECT_SUPPLY_ORDER_INFO',
  ENTER_CODE: 'ENTER_CODE',
  ENTER_PHONE: 'ENTER_PHONE',
  ENTER_EMAIL: 'ENTER_EMAIL',
  REQUEST_ACCESS: 'REQUEST_ACCESS',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  SUPPLY_ORDERS_INFO: 'SUPPLY_ORDERS_INFO',
  PROFILE: 'PROFILE',
  HOME_STACK: 'HOME_STACK',
  HOME_TAB: 'HOME_TAB',
  APPOINTMENTS_TAB: 'APPOINTMENTS_TAB',
  CREATE: 'CREATE',
  VIEW_AGREEMENT: 'VIEW_AGREEMENT',
  VIEW_WORK_ORDER: 'VIEW_WORK_ORDER',
  CREATE_QUOTE: 'CREATE_QUOTE',
  VIEW_QUOTE: 'VIEW_QUOTE',
  APPOINTMENT_SCHEDULED: 'APPOINTMENT_SCHEDULED',
  APPOINTMENT_PAST: 'APPOINTMENT_PAST',
  VIEW_BID: 'VIEW_BID',
  VIEW_REIMBURSEMENT_REQUEST: 'VIEW_REIMBURSEMENT_REQUEST',
  PRIVATE_CHAT: 'PRIVATE_CHAT',
  PLAID_CONNECT: 'PLAID_CONNECT',
  LEADS: 'LEADS',
  LEADS_NEW: 'LEADS_NEW',
  LEADS_SCHEDULED: 'LEADS_SCHEDULED',
  LEADS_PAST: 'LEADS_PAST',
  APPOINTMENT_OFFER: 'APPOINTMENT_OFFER',
  PROPERTY_DATA: 'PROPERTY_DATA',
  APPOINTMENT_OFFER_PROPERTY_DATA: 'APPOINTMENT_OFFER_PROPERTY_DATA',
  ONBOARDING: 'ONBOARDING',
  ONBOARDING_INITIAL: 'ONBOARDING_INITIAL',
  ONBOARDING_PROFILE: 'ONBOARDING_PROFILE',
  ONBOARDING_WORK_TYPE: 'ONBOARDING_WORK_TYPE',
  ONBOARDING_LICENSE_TYPE: 'ONBOARDING_LICENSE_TYPE',
  ONBOARDING_SERVICE_AREA: 'ONBOARDING_SERVICE_AREA',
  ONBOARDING_SERVICE_TYPES: 'ONBOARDING_SERVICE_TYPES',
  ONBOARDING_ONLINE_CONTACTS: 'ONBOARDING_ONLINE_CONTACTS',
  ONBOARDING_STRIPE: 'ONBOARDING_STRIPE',
  ONBOARDING_USERS: 'ONBOARDING_USERS',
  ONBOARDING_INVITE_USER: 'ONBOARDING_INVITE_USER',
  ONBOARDING_ROLES: 'ONBOARDING_ROLES',
  ONBOARDING_EDIT_ROLE: 'ONBOARDING_EDIT_ROLE',
  ONBOARDING_CREATE_ROLE: 'ONBOARDING_CREATE_ROLE',
  ONBOARDING_REFERRAL_AGREEMENT: 'ONBOARDING_REFERRAL_AGREEMENT',
  USER_ONBOARDING: 'USER_ONBOARDING',
  USER_ONBOARDING_PROFILE: 'USER_ONBOARDING_PROFILE',
  USER_ONBOARDING_SERVICE_AREA: 'USER_ONBOARDING_SERVICE_AREA',
  USER_ONBOARDING_SERVICE_TYPES: 'USER_ONBOARDING_SERVICE_TYPES',
  SETTINGS_SERVICE_AREA: 'SETTINGS_SERVICE_AREA',
  SETTINGS_USER_SERVICE_AREA: 'SETTINGS_USER_SERVICE_AREA',
  SETTINGS_CONTRACTOR_TYPE: 'SETTINGS_CONTRACTOR_TYPE',
  SETTINGS_COMPANY_ADDRESS: 'SETTINGS_COMPANY_ADDRESS',
  SETTINGS_NOTIFICATIONS: 'SETTINGS_NOTIFICATIONS',
  SETTINGS_NOTIFICATIONS_TOGGLES: 'SETTINGS_NOTIFICATIONS_TOGGLES',
  SETTINGS_PROPOSAL_TEMPLATE: 'SETTINGS_PROPOSAL_TEMPLATE',
  REQUESTS_SIGN_CONTRACT: 'REQUESTS_SIGN_CONTRACT',
  REQUESTS_VIEW_BID: 'REQUESTS_VIEW_BID',
  REQUESTS_VIEW_PAYMENT_REQUEST: 'REQUESTS_VIEW_PAYMENT_REQUEST',
  REQUESTS_SUPPLY_ORDER_INFO: 'REQUESTS_SUPPLY_ORDER_INFO',
  PROJECT_VIEW_PAYMENT_REQUEST: 'PROJECT_VIEW_PAYMENT_REQUEST',
  PROJECT_VIEW_BID: 'PROJECT_VIEW_BID',
  PROJECT_VIEW_REIMBURSEMENT_REQUEST: 'PROJECT_VIEW_REIMBURSEMENT_REQUEST',
  PROPOSAL_PROJECT_ADDRESS: 'PROPOSAL_PROJECT_ADDRESS',
  PROPOSAL_MAILING_ADDRESS: 'PROPOSAL_MAILING_ADDRESS',
  PROPOSAL_CREATE_LINE_ITEM: 'PROPOSAL_CREATE_LINE_ITEM',
  PROPOSAL_AGREEMENTS: 'PROPOSAL_AGREEMENTS',
  CREATE_WORK_ORDER_INVITED_SUBS: 'CREATE_WORK_ORDER_INVITED_SUBS',
  SETTINGS_SERVICE_TYPES: 'SETTINGS_SERVICE_TYPES',
  SETTINGS_USER_SERVICE_TYPES: 'SETTINGS_USER_SERVICE_TYPES',
  PROJECT_INFO: 'PROJECT_INFO',
  SETTINGS_PAYMENT_REQUESTS_APPROVE: 'SETTINGS_PAYMENT_REQUESTS_APPROVE',
  PREBUILD: 'PREBUILD',
  PROJECT_ACTIVITIES: 'PROJECT_ACTIVITIES', // TODO: implement for web
  REQUESTS_CREATE_PAYMENT_REQUEST_SUB: 'REQUESTS_CREATE_PAYMENT_REQUEST_SUB', // TODO: implement for web
  REQUESTS_CREATE_PAYMENT_REQUEST_HIC: 'REQUESTS_CREATE_PAYMENT_REQUEST_HIC', // TODO: implement for web
  CREATE_REIMBURSEMENT_REQUEST: 'CREATE_REIMBURSEMENT_REQUEST', // TODO: implement for web
  BID_INVITE: 'BID_INVITE', // TODO: implement for web
  CREATE_PROJECT: 'CREATE_PROJECT', // TODO: implement for web
  BID_INVITE_SELECT_PROJECT: 'BID_INVITE_SELECT_PROJECT', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT: 'BID_INVITE_CREATE_PROJECT', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT_ADDRESS: 'BID_INVITE_CREATE_PROJECT_ADDRESS', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT_MAILING_ADDRESS: 'BID_INVITE_CREATE_PROJECT_MAILING_ADDRESS', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT_TRADES: 'BID_INVITE_CREATE_PROJECT_TRADES', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT_ADMINS: 'BID_INVITE_CREATE_PROJECT_ADMINS', // TODO: implement for web
  BID_INVITE_SERVICE_TYPE: 'BID_INVITE_SERVICE_TYPE', // TODO: implement for web
  BID_INVITE_ADD_TRADE: 'BID_INVITE_ADD_TRADE', // TODO: implement for web
  BID_INVITE_REQUEST_TYPE: 'BID_INVITE_REQUEST_TYPE', // TODO: implement for web
  BID_INVITE_FILES: 'BID_INVITE_FILES', // TODO: implement for web
  BID_INVITE_CREATE_PROJECT_VIEW_ATTACHMENT: 'BID_INVITE_CREATE_PROJECT_VIEW_ATTACHMENT', // TODO: implement for web
  BID_INVITE_VIEW_ATTACHMENT: 'BID_INVITE_VIEW_ATTACHMENT', // TODO: implement for web,
  BID_INVITE_DATES: 'BID_INVITE_DATES', // TODO: implement for web
  BID_INVITE_USERS: 'BID_INVITE_USERS', // TODO: implement for web
  BID_INVITE_BIDDERS_INITIAL: 'BID_INVITE_BIDDERS_INITIAL', // TODO: implement for web
  BID_INVITE_ADD_USER: 'BID_INVITE_ADD_USER', // TODO: implement for web
  BID_INVITE_SUB_MANUALLY: 'BID_INVITE_SUB_MANUALLY', // TODO: implement for web
  BID_INVITE_SUB_CONTACTS: 'BID_INVITE_SUB_CONTACTS', // TODO: implement for web
  VIEW_BID_PACKAGE: 'VIEW_BID_PACKAGE', // TODO: implement for web
  INVITE_SUBS_WITH_TABS: 'INVITE_SUBS_WITH_TABS', // TODO: implement for web
  VIEW_BID_PACKAGE_VIEW_ATTACHMENT: 'VIEW_BID_PACKAGE_VIEW_ATTACHMENT',
  BID_INVITE_VIEW_SUB_PROFILE: 'BID_INVITE_VIEW_SUB_PROFILE',
  VIEW_BID_PACKAGE_VIEW_SUB_PROFILE: 'VIEW_BID_PACKAGE_VIEW_SUB_PROFILE',
  BID_INVITE_VIEW_EXTERNAL_SUB_PROFILE: 'BID_INVITE_VIEW_EXTERNAL_SUB_PROFILE',
  VIEW_BID_PACKAGE_VIEW_EXTERNAL_SUB_PROFILE: 'VIEW_BID_PACKAGE_VIEW_EXTERNAL_SUB_PROFILE',
  VIEW_BID_PACKAGE_ADD_USER: 'VIEW_BID_PACKAGE_ADD_USER', // TODO: implement for web
  VIEW_BID_PACKAGE_EDIT: 'VIEW_BID_PACKAGE_EDIT', // TODO: implement for web
  BID_INVITE_SUB_SEARCH: 'BID_INVITE_SUB_SEARCH', // TODO: implement for web
  CREATE_QUOTE_VIEW_ATTACHMENT: 'CREATE_QUOTE_VIEW_ATTACHMENT', // TODO: implement for web
  CREATE_QUOTE_CREATE_LINE_ITEM: 'CREATE_QUOTE_CREATE_LINE_ITEM',
  VIEW_BID_PACKAGE_VIEW_BID: 'VIEW_BID_PACKAGE_VIEW_BID',
  VIEW_ATTACHMENT: 'VIEW_ATTACHMENT',
  CREATE_GC_APPOINTMENT: 'CREATE_GC_APPOINTMENT',
  CREATE_GC_APPOINTMENT_ADDRESS: 'CREATE_GC_APPOINTMENT_ADDRESS',
  REFERRAL_AGREEMENT: 'REFERRAL_AGREEMENT',
  CREATE_QUOTE_EDIT_DEPOSIT: 'CREATE_QUOTE_EDIT_DEPOSIT',
  CREATE_QUOTE_PROGRESS_PAYMENT_PHASE: 'CREATE_QUOTE_PROGRESS_PAYMENT_PHASE',
  CREATE_QUOTE_COMMENTS: 'CREATE_QUOTE_COMMENTS',
  VIEW_SUB_PROFILE: 'VIEW_SUB_PROFILE',
  SETTINGS_COMPANY_PROFILE_PREVIEW: 'SETTINGS_COMPANY_PROFILE_PREVIEW',
  SETTINGS_COMPANY_ABOUT: 'SETTINGS_COMPANY_ABOUT',
  VIEW_BID_ATTACHMENTS: 'VIEW_BID_ATTACHMENTS',
  PROJECT_BIDS: 'PROJECT_BIDS',
  CHAT_DETAILS: 'CHAT_DETAILS',
  PROJECT_SUB_JOB_OFFERS: 'PROJECT_SUB_JOB_OFFERS',
  PROJECT_BID_INVITES: 'PROJECT_BID_INVITES',
  PROJECT_APPOINTMENTS: 'PROJECT_APPOINTMENTS',
  DOCUMENTS: 'DOCUMENTS',
  SETTINGS_COMPANY_PROFILE_SETTINGS: 'SETTINGS_COMPANY_PROFILE_SETTINGS',
  SHARE_LINK: 'SHARE_LINK',
  // redesign 4.0 screens:
  GC_APP: 'GC_APP',
  GC_PROJECT: 'GC_PROJECT',
  GC_PROJECT_WORKORDER: 'GC_PROJECT_WORKORDER',
  GC_PROJECT_WORKORDER_LEVELING: 'GC_PROJECT_WORKORDER_LEVELING',
  GC_PROJECT_WORKORDER_NO_SCOPE_NO_SUBS: 'GC_PROJECT_WORKORDER_NO_SCOPE_NO_SUBS',
  GC_PROJECT_WORKORDER_SELECT_OPTION: 'GC_PROJECT_WORKORDER_SELECT_OPTION',
  GC_VIEW_BID: 'GC_VIEW_BID',
  GC_CONTACTS: 'GC_CONTACTS',
  SUB_APP: 'SUB_APP',
  SUB_PROJECT: 'SUB_PROJECT',
  SUB_CREATE_BID: 'SUB_CREATE_BID',
  WORKORDER_FILE: 'WORKORDER_FILE',
  SUB_CREATE_PROPOSAL: 'SUB_CREATE_PROPOSAL',
  SUB_CREATE_BID_PREVIEW: 'SUB_CREATE_BID_PREVIEW',
  ACCEPT_INVITE: 'ACCEPT_INVITE',
  GC_PROJECT_SHARE: 'GC_PROJECT_SHARE',
  PRECON: 'PRECON',
  PRECON_PROJECT: 'PRECON_PROJECT',
  PRECON_ORDER_DETAILS: 'PRECON_ORDER_DETAILS',
  PRECON_PAYMENT_PLAN: 'PRECON_PAYMENT_PLAN',
  INVITATION: 'INVITATION',
  PRECON_TEAMMATES: 'PRECON_TEAMMATES',
  PRECON_BOOK_A_CALL: 'PRECON_BOOK_A_CALL'
}
