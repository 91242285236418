import React from 'react'
import { Box, Text } from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
import CreateBidNavBar from 'webPages/createBid/CreateBidNavBar'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'

const Header = ({ mode }) => {
  if (mode === 'info') {
    return (
      <Box
        direction='row'
        justify='start'
        align='center'
        height={{ min: '60px' }}
        pad={{ right: 'small', left: 'medium', vertical: 'none' }}
        gap='small'
        onClick={() => universalNavigation.goBack()}
      >
        <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faAngleLeft} size={20} />
        <Text size='large' weight={500} truncate>
          Back
        </Text>
      </Box>
    )
  }
  return null
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrder(state, props)
})

export default connect(mapStateToProps)(Header)
