import React from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import styled from 'sc'
import numeral from 'numeral'
import PropTypes from 'prop-types'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'

const LineItemRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.getSize(3)}px;
  padding-bottom: ${props => props.getSize(3)}px;
  /* min-height: ${props => props.getSize(24)}px; */
  ${props => props.customStyle || ''}
`
const Cell = styled(View)`
  display: flex;
  flex: ${props => props.flex || 1};
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  ${props => props.customStyle || ''}
`
const TitleContainer = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BadgeContainer = styled(View)`
  border-radius: ${props => props.getSize(30)}px;
  padding-top: ${props => props.getSize(1)}px;
  padding-bottom: ${props => props.getSize(1)}px;
  padding-left: ${props => props.getSize(6)}px;
  padding-right: ${props => props.getSize(6)}px;
  align-items: center;
  justify-content: center;
`

const AlternateBadgeContainer = styled(BadgeContainer)`
  background-color: ${colors.BADGE_BG};
  margin-left: ${props => props.getSize(5)}px;
  margin-right: ${props => props.getSize(5)}px;
`

const IncludedBadgeContainer = styled(BadgeContainer)`
  background-color: ${colors.AQUA_MARINE};
`
const ExcludedBadgeContainer = styled(BadgeContainer)`
  background-color: ${colors.CORAL_TWO};
`

const Line = styled(View)`
  background-color: ${props => props.color};
  height: 1px;
  width: ${props => props.getSize(12)}px;
`

const LineItem = ({ getSize, item, itemId, isCalculated, isAlternate, showBidsTotals, showLineWhenEmpty = true }) => {
  const quantity = _.get(item, 'quantity', '')
  const quantityType = _.get(item, 'quantityType', '')
  const strQuantity = `${_.isEmpty(_.toString(quantity)) ? '' : quantity} ${quantityType}`
  const cost = _.get(item, 'cost', null)
  const total = _.get(item, 'total', null)
  // console.log('render line item', item, isCalculated)

  const color = isAlternate ? colors.ANOTHER_GREY : colors.BLACK

  const renderAlternateBadge = () => {
    if (isAlternate) {
      return (
        <AlternateBadgeContainer getSize={getSize}>
          <StyledText fontSize={getSize(8)} color={colors.WHITE}>
            Alternate
          </StyledText>
        </AlternateBadgeContainer>
      )
    }
  }

  const badgeIncluded = (
    <IncludedBadgeContainer getSize={getSize}>
      <StyledText color={colors.WHITE} fontSize={getSize(8)}>
        Included
      </StyledText>
    </IncludedBadgeContainer>
  )

  const badgeExcluded = (
    <ExcludedBadgeContainer getSize={getSize}>
      <StyledText color={colors.WHITE} fontSize={getSize(8)}>
        Excluded
      </StyledText>
    </ExcludedBadgeContainer>
  )

  const renderQuantity = () => {
    if (!_.isEmpty(_.toString(quantity))) {
      return (
        <StyledText fontSize={getSize(12)} color={color} italic={isAlternate}>
          {strQuantity}
        </StyledText>
      )
    } else {
      return showLineWhenEmpty ? <Line getSize={getSize} color={color} /> : <View />
    }
  }

  const renderPrice = () => {
    if (!_.isEmpty(_.toString(cost)) && showBidsTotals) {
      return (
        <StyledText fontSize={getSize(12)} color={color} italic={isAlternate}>
          {numeral(cost).format('$0,0.00')}
        </StyledText>
      )
    } else {
      return <Line getSize={getSize} color={color} />
    }
  }

  const renderTotal = () => {
    if (item.excluded) {
      return badgeExcluded
    } else if (!_.isEmpty(_.toString(total)) && showBidsTotals) {
      return (
        <StyledText fontSize={getSize(12)} color={color} italic={isAlternate}>
          {numeral(total).format('$0,0.00')}
        </StyledText>
      )
    } else if (!isAlternate || !showBidsTotals) {
      return badgeIncluded
    } else {
      return <Line getSize={getSize} color={color} />
    }
  }

  if (isCalculated) {
    return (
      <LineItemRow key={itemId} getSize={getSize}>
        <Cell flex={4}>
          {renderAlternateBadge()}
          <TitleContainer>
            <StyledText fontSize={getSize(12)} color={color} italic={isAlternate}>
              {item.name}
            </StyledText>
            {item.desc
              ? (
                <StyledText
                  fontSize={getSize(10)}
                  color={isAlternate ? colors.ANOTHER_GREY : colors.TEXT}
                  customstyle={`margin-left: ${isAlternate ? 0 : getSize(5)}px`}
                  italic
                >
                  {item.desc}
                </StyledText>
                )
              : null}
          </TitleContainer>
        </Cell>
        <Cell customStyle='justify-content: center;'>{renderQuantity()}</Cell>
        <Cell customStyle='justify-content: center;'>{renderPrice()}</Cell>
        <Cell customStyle='justify-content: flex-end;'>{renderTotal()}</Cell>
      </LineItemRow>
    )
  } else {
    return (
      <LineItemRow key={itemId} getSize={getSize}>
        <Cell flex={4}>
          {renderAlternateBadge()}
          <TitleContainer>
            <StyledText fontSize={getSize(12)}>{item.name}</StyledText>
            {item.desc
              ? (
                <StyledText
                  fontSize={getSize(10)}
                  color={isAlternate ? colors.ANOTHER_GREY : colors.TEXT}
                  customstyle={`margin-left: ${isAlternate ? 0 : getSize(5)}px`}
                  italic
                >
                  {item.desc}
                </StyledText>
                )
              : null}
          </TitleContainer>
        </Cell>
        <Cell customStyle='justify-content: flex-end;'>{renderQuantity()}</Cell>
      </LineItemRow>
    )
  }
}

LineItem.propTypes = {
  getSize: PropTypes.func,
  item: PropTypes.object,
  itemId: PropTypes.string,
  isCalculated: PropTypes.bool,
  isAlternate: PropTypes.bool,
  showBidsTotals: PropTypes.bool
}

export default React.memo(LineItem)
