import React, { Component } from 'react'
import { connect } from 'react-redux'
import LevelingContext from 'webPages/leveling/LevelingContext'
import { getArchivedWorkOrders } from 'controllers/workOrder'

class LevelingDataProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      archivedVisible: false,
      setArchivedVisible: this.setArchivedVisible,
      values: false,
      compact: false,
      references: false,
      quantities: false,
      declined: false,
      sections: false,
      handleViewChange: this.handleViewChange
    }
  }

  setArchivedVisible = v => {
    const { dispatch, projectId } = this.props
    this.setState({ archivedVisible: v })
    if (v) {
      dispatch(getArchivedWorkOrders(projectId))
    }
  }

  handleViewChange = (key, value) => {
    this.setState({ [key]: value })
  }

  render () {
    return (
      <LevelingContext.Provider value={{ ...this.state, commentsInboxRef: this.commentsInboxRef }}>
        {this.props.children}
      </LevelingContext.Provider>
    )
  }
}

LevelingDataProvider.contextType = LevelingContext

export default connect()(LevelingDataProvider)
