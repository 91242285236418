import { createSelector } from 'reselect'
import _ from 'lodash'
import {
  getWorkOrders,
  getAccountsProfiles as getAllAccountsProfiles,
  getProfiles,
  getUserId,
  getAccountId
} from 'model/selectors/base'

export const getCurrentUserProfile = createSelector([getUserId, getProfiles], (userId, profiles) => {
  console.log('getCurrentUserProfile, userId', userId)
  return _.get(profiles, userId, {})
})

export const getCurrentAccountProfile = createSelector(
  [getAccountId, getAllAccountsProfiles],
  (accountId, profiles) => {
    return _.get(profiles, accountId, {})
  }
)

export const getAccountsProfiles = createSelector(
  [getAllAccountsProfiles, getWorkOrders],
  (accountsProfiles, workOrders) => {
    const res = { ...accountsProfiles }
    _.forEach(workOrders, wo => {
      _.forEach(wo.invitations, (inv, invId) => {
        if (_.isEmpty(inv.subAccountId) && !_.has(accountsProfiles, invId)) {
          const emails = _.compact(_.flatten(_.map(inv.sendTo, st => st.emails)))
          const phones = _.compact(_.flatten(_.map(inv.sendTo, st => st.phones)))
          const p = {
            name: _.get(inv, 'companyName'),
            emails,
            phones,
            email: _.get(emails, 0),
            phone: _.get(phones, 0)
          }
          _.set(res, invId, _.omitBy(p, _.isEmpty))
        }
      })
    })
    return res
  }
)

export const getUsersProfiles = createSelector([getProfiles, getWorkOrders], (profiles, workOrders) => {
  const res = { ...profiles }
  _.forEach(workOrders, wo => {
    _.forEach(wo.invitations, (inv, invId) => {
      _.forEach(inv.sendTo, (c, cId) => {
        const p = {
          id: cId,
          name: c.name,
          emails: c.emails,
          phones: c.phones,
          email: _.get(c.emails, 0),
          phone: _.get(c.phones, 0)
        }
        _.set(res, cId, _.omitBy(p, _.isEmpty))
      })
    })
  })
  return res
})

export const getUsersProfilesByAccountId = createSelector([getProfiles], profiles => {
  const res = {}
  _.forEach(profiles, (profile, userId) => _.set(res, [profile.currentAccountId, userId], profile))
  return res
})
