import React, { Component } from 'react'
import { Layer, Text, Card, CardHeader, CardBody, Box, Button, Tab, Tabs, Tip } from 'grommet'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faPaperclip, faTrashAlt, faTimes } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import DocPanelEstimatePreview from 'shared/components/DocPanelEstimatePreview'
import { downloadEstimatePdf, removeBidFile, updateBidFiles } from 'controllers/bids'
import SimplePdfPreview from 'components/SimplePdfPreview'
import { getName } from 'shared/utils/stringUtils'
import FilePreview from 'components/FilePreview'
import FilesPickerButton from 'components/web/FilesPickerButton'
import { getFile } from 'shared/utils/files'
import Loading from 'shared/pages/Loading'

const filePickerLabel = ({ onClick }) => (
  <Box direction='row' gap='xsmall' justify='start' align='center' onClick={onClick} pad={{ vertical: 'small', horizontal: 'medium' }}>
    <FontAwesomeIcon icon={faPaperclip} color={colors.VERY_LIGHT_PINK} size={14} />
    <Text size='medium' color={colors.VERY_LIGHT_PINK}>Upload file</Text>
  </Box>
)

class BidPreviewModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      isGeneratingPdf: false,
      activeIndex: 0
    }
  }

  open = bidId => {
    this.setState({
      activeIndex: 0,
      visible: true,
      bidId,
      loading: true
    }, async () => {
      const { bids } = this.props
      const bid = _.get(bids, bidId)
      const pdfUrl = _.get(bid, 'pdfUrl')
      const update = { loading: false }
      if (pdfUrl) {
        const pdf = await getFile(pdfUrl)
        _.set(update, 'pdf', pdf)
      }
      this.setState({ ...update })
    })
  }

  close = () => {
    this.setState({ visible: false, activeIndex: 0 })
  }

  onDownloadClick = async () => {
    const { bidId } = this.state
    const { bids } = this.props
    const bid = _.get(bids, bidId)
    this.setState({ isGeneratingPdf: true })
    await downloadEstimatePdf(bid)
    this.setState({ isGeneratingPdf: false })
  }

  handleNavigation = index => {
    this.setState({
      activeIndex: index
    })
  }

  handleRemoveFile = ({ id }, isActive) => e => {
    e.stopPropagation()
    const { bidId } = this.state
    const { bids } = this.props
    const bid = _.get(bids, bidId)
    const workOrderId = _.get(bid, 'workOrderId')
    if (isActive) {
      this.setState({
        activeIndex: 0
      })
    }
    removeBidFile(workOrderId, bidId, id, true)
  }

  renderBid = () => {
    const { bidId, isGeneratingPdf, pdf } = this.state
    const { bids } = this.props
    const bid = _.get(bids, bidId)
    if (_.has(bid, 'pdfUrl') && pdf) {
      const subName = getName(bid.owner)
      const gcName = getName(bid.client)
      const address = _.get(bid, 'projectAddress.name')
      const filename = _.snakeCase(`${subName} bid for ${gcName} at ${address}`) + '.pdf'
      return (
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <CardBody
            overflow='scroll'
            wrap={false}
            background={colors.PALE_GREY}
          >
            <SimplePdfPreview
              id={bid.id}
              url={bid.pdfUrl}
              name={filename}
              fileConfig={{
                showPageControls: false,
                dockPageControls: false,
                showAnnotationTools: false,
                showLeftHandPanel: false,
                defaultViewMode: 'FIT_WIDTH',
                enableFormFilling: false,
                showDownloadPDF: true,
                showPrintPDF: false,
                enableAnnotationAPIs: true
              }}
            />
          </CardBody>
        </Card>
      )
    }
    return (
      <Card height='100%' width='large' customStyle='border-radius: 0px'>
        <CardHeader
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
          border={{
            color: 'border',
            size: '1px',
            side: 'bottom'
          }}
        >
          <Text size='xlarge' color={colors.BLACK} weight={600}>
            Preview
          </Text>
          <Button
            color={colors.DEEP_SKY_BLUE}
            label='Download'
            onClick={this.onDownloadClick}
            icon={
              <FontAwesomeIcon
                icon={isGeneratingPdf ? faSpinner : faPaperclip}
                spin={isGeneratingPdf}
                size='md'
              />
            }
            disabled={isGeneratingPdf}
          />
        </CardHeader>
        <CardBody
          overflow='scroll'
          wrap={false}
          background={colors.PALE_GREY}
        >
          <ReactResizeDetector handleWidth>
            {({ width }) => (
              <Box fill>
                {width > 0 && (
                  <DocPanelEstimatePreview
                    viewport={{ width, height: width * 2 }}
                    doc={bid}
                    onPageClick={this.showZoomView}
                  />
                )}
              </Box>
            )}
          </ReactResizeDetector>
        </CardBody>
      </Card>
    )
  }

  renderElement = (el, index) => {
    const { activeIndex } = this.state
    const { profiles } = this.props
    const name = _.get(el, 'name')
    const timestamp = _.has(el, 'createdAt') ? moment(_.get(el, 'createdAt')).fromNow() : ''
    const createdBy = _.get(el, 'createdBy')
    const accountName = _.get(profiles, [createdBy, 'name'])
    const main = _.get(el, 'main', false)
    const itemIndex = main ? 0 : index + 1
    const isActive = _.isEqual(activeIndex, itemIndex)
    const color = isActive ? colors.RIPTIDE10 : colors.WHITE
    return (
      <Box
        hoverIndicator
        flex={{ shrink: 0 }}
        background={color}
        onClick={() => this.handleNavigation(itemIndex)}
        pad='small'
        gap='xsmall'
        border={main ? 'horizontal' : 'bottom'}
        customStyle={`
          position: relative;
          .delete-button {
            display: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            right: 10px;
          }
          .delete-button:hover * {
            fill: ${colors.LIGHT_NAVY_BRIGHT}
          }
          :hover .delete-button {
            display: flex;
          }
        `}
      >
        <Box>
          <Text color={colors.TEXT_PRIMARY}>{name}</Text>
        </Box>
        <Box direction='row' gap='xsmall'>
          <Text size='small' color={colors.VERY_LIGHT_PINK}>uploaded {timestamp}</Text>
          {createdBy && <Text size='small' color={colors.DARK_GREAY_TWO}>by {accountName}</Text>}
        </Box>
        {!main && (
          <Tip content='Remove file'>
            <Box className='delete-button'>
              <Button color={colors.VERY_LIGHT_PINK} icon={<FontAwesomeIcon icon={faTrashAlt} size={18} />} plain onClick={this.handleRemoveFile(el, isActive)} />
            </Box>
          </Tip>
        )}
      </Box>
    )
  }

  renderFile = file => {
    return (
      <Tab>
        <Card height='100%' width='large' customStyle='border-radius: 0px'>
          <FilePreview file={file} />
        </Card>
      </Tab>
    )
  }

  onBidFilesPicked = files => {
    const { bidId } = this.state
    const { bids } = this.props
    const bid = _.get(bids, bidId)
    const bidFiles = _.get(bid, 'files', {})
    const newFiles = { ...bidFiles }
    _.forEach(files, f => _.set(newFiles, f.id, f))
    updateBidFiles(_.get(bid, 'id'), newFiles)
  }

  render () {
    const { visible, bidId, activeIndex, loading } = this.state
    const { bids } = this.props

    if (loading) {
      return (
        <Loading />
      )
    }

    const bid = _.get(bids, bidId)
    const bidElement = {
      name: 'Bid document',
      createdAt: !_.isNil(_.get(bid, 'timestamp')) ? _.get(bid, 'timestamp').toMillis() : '',
      createdBy: _.get(bid, 'createdBy'),
      main: true
    }
    const bidFiles = _.orderBy(_.values(_.get(bid, 'files')), 'createdAt', 'desc')
    const workOrderId = _.get(bid, 'workOrderId')
    if (visible) {
      return (
        <Layer
          onEsc={this.close}
          onClickOutside={this.close}
          full='vertical'
          margin={{ vertical: 'small' }}
          animate={false}
        >
          <Box direction='row' fill>
            {_.size(bidFiles) > 0 && (
              <Box fill='vertical' width='250px' background={colors.PALE_GREY} overflow='auto'>
                <Box flex={false} pad='small' width='xxsmall' align='center'>
                  <Button plain icon={<FontAwesomeIcon icon={faTimes} color={colors.LIGHT_NAVY_BRIGHT} />} onClick={this.close} />
                </Box>
                <Box flex={{ shrink: 0 }}>
                  {this.renderElement(bidElement)}
                </Box>
                <Box pad='small' flex={{ shrink: 0 }}>
                  <Text>Attached files</Text>
                </Box>
                {_.map(bidFiles, this.renderElement)}
                <FilesPickerButton
                  ButtonComponent={filePickerLabel}
                  onComplete={this.onBidFilesPicked}
                  storagePath={`/workOrders/${workOrderId}/bid/${bidId}`}
                />
              </Box>
            )}
            <Box fill='vertical'>
              <Tabs flex activeIndex={activeIndex} customStyle={`> div:first-child { display: none }`}>
                <Tab title='Bid document'>
                  {this.renderBid()}
                </Tab>
                {_.map(bidFiles, this.renderFile)}
              </Tabs>
            </Box>
          </Box>
        </Layer>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  user: state.user,
  profiles: state.profiles,
  bids: state.bids
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(BidPreviewModal)
