import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import styled from 'styled-components'

import LineItemTableRow from 'components/workOrder/LineItemRow'
import LineItemQuestion from 'components/workOrder/LineItemQuestion'
import { generateId } from 'constants/firebase'
import lineItemSection from 'shared/constants/lineItemSection'
import lineItemType from 'shared/constants/lineItemType'
import _ from 'lodash'

const GridRow = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 26px 2fr 140px 4fr 200px;
`

const AddItemButton = ({
  sectionIndex,
  sectionId,
  createLineItem,
  selectRow,
  unselectRow,
  isDraggingOver,
  typeOfAddedLi,
  setTypeOfAddedLi,
  resetTypeOfAddedLi
}) => {
  const [lineItemMode, setLineItemMode] = useState(false)
  const [liId, setLiId] = useState()
  const isQuestion = sectionId === lineItemSection.QUESTIONS_ID

  const toCreateLineItemMode = () => {
    if (isQuestion && _.isEqual(typeOfAddedLi, lineItemType.DEFAULT)) {
      setTypeOfAddedLi(isQuestion ? lineItemType.QUESTION_BOOL : lineItemType.DEFAULT)
    }
    setLiId(generateId())
    setLineItemMode(true)
  }

  const removeLineItem = () => {
    setLineItemMode(false)
  }

  const updateItem = (itemId, params, parentLi) => {
    console.log('update line item', itemId, params, parentLi)
    const li = {
      id: liId,
      type: typeOfAddedLi,
      ...params
    }
    createLineItem(li, sectionIndex)
    setLineItemMode(false)
    resetTypeOfAddedLi()
  }

  const label = isQuestion ? 'Add question...' : 'Add line item...'
  const RowComponent = isQuestion ? LineItemQuestion : LineItemTableRow
  if (lineItemMode) {
    const li = {
      id: liId,
      type: typeOfAddedLi,
      name: ''
    }
    return (
      <RowComponent
        key={liId}
        rowId={liId}
        li={li}
        updateItem={updateItem}
        selectRow={selectRow(liId)}
        unselectRow={unselectRow(liId)}
        removeLineItem={removeLineItem}
        cannotAddToTemplate
        sectionId={sectionId}
        isQuestion={sectionId === lineItemSection.QUESTIONS_ID}
      />
    )
  } else {
    return (
      <GridRow key={`add_to_scope_button_${sectionIndex}`}>
        <Box />
        <Box pad={{ left: 'medium', top: 'xxsmall' }} height={'52px'} direction='row' align='start'>
          <Button
            label={label}
            onClick={toCreateLineItemMode}
            size='small'
            id={`add_li_button_${sectionIndex}`}
            disabled={isDraggingOver}
          />
        </Box>
        <Box />
        <Box />
        <Box />
      </GridRow>
    )
  }
}

export default AddItemButton
