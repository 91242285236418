import React, { Component } from 'react'
import {
  Box,
  Layer,
  Text,
  ThemeContext,
  MaskedInput,
  TextArea,
  Button,
  Avatar
} from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import validator from 'validator'

import { PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import colors from 'shared/constants/colors'
import { getAccountAdmins } from 'model/selectors/base'
import { sendInvite, resendInvite } from 'controllers/auth'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { emailMask, phoneMask } from 'utils/inputMask'
import { getManageUsersPermission } from 'model/selectors/permissionsSelector'
import RemoveUserModal from 'webPages/settings/RemoveUserModal'
import ActionButton from 'webPages/settings/ActionButton'
import Row from 'webPages/settings/Row'
import roles from 'shared/constants/roles'

const borderBottom = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'bottom'
}

class TeammatesModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      inviteModalOpen: false,
      message: '',
      removingUser: null,
      email: '',
      phone: ''
    }
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  close = () => {
    this.setState({
      open: false
    })
  }

  renderRole = user => {
    const { dispatch } = this.props
    if (user.invited) {
      return (
        <Button
          primary
          color={colors.AQUA_MARINE}
          label='Resend'
          onClick={() => dispatch(resendInvite(user.inviteId))}
        />
      )
    } else {
      return (
        <Text size='small' color={colors.VERY_LIGHT_PINK}>
          {_.upperFirst(user.role)}
        </Text>
      )
    }
  }

  renderUser = (user, index) => {
    const { currentUser, canManageUsers } = this.props
    const email = _.get(user, 'email')
    const phone = _.get(user, 'phone') || ''
    const phoneNumber = phone ? parsePhoneNumberFromString(phone) : null
    const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
    return (
      <Box
        flex={false}
        direction='row'
        align='center'
        key={user.id}
        pad='small'
        border={borderBottom}
        hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
        height='52px'
        onClick={() => null}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={user.avatarSmall}>
            <Text color={colors.WHITE}>{getInitials(user)}</Text>
          </Avatar>
        </Box>
        <Box fill margin={{ left: 'medium' }} align='start' justify='center' gap='xsmall'>
          <Text>{getName(user)}</Text>
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {contacts}
          </Text>
        </Box>
        <Box align='end' width='small' pad={{ horizontal: 'small' }}>
          {this.renderRole(user)}
        </Box>
        <Box width='xsmall' align='center'>
          <ActionButton user={user} setRemovingUser={this.setRemovingUser} currentUser={currentUser} canManageUsers={canManageUsers} />
        </Box>
      </Box>
    )
  }

  handleInviteModalOpen = () => {
    this.setState({
      inviteModalOpen: !this.state.inviteModalOpen
    })
  }

  handleFieldChange = (type, value) => {
    this.setState({
      [type]: value
    })
  }

  setRemovingUser = user => {
    this.setState({
      removingUser: user
    })
  }

  handleSubmit = () => {
    const { email, phone, message } = this.state
    const { dispatch } = this.props
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    dispatch(sendInvite(phoneNumber.number, roles.OWNER, null, null, email, message))
    this.setState({
      email: '',
      phone: '',
      message: '',
      inviteModalOpen: false
    })
  }

  render () {
    const { open, inviteModalOpen, removingUser, email, phone, message } = this.state
    if (!open) return null
    const { admins, invitations, dispatch } = this.props
    return (
      <Layer onEsc={this.close} onClickOutside={this.close} animate={false} background={colors.WHITE}>
        <Box fill flex={false} overflow='scroll'>
          <Box alignSelf='center' width='large' direction='column' flex={false}>
            <Box direction='row' align='center' border={borderBottom} justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
              <Text size='xlarge' color={colors.BLACK} weight={500} margin='small'>
                Teammates
              </Text>
              <Button
                plain
                label={<FontAwesomeIcon icon={faTimes} size={22} color={colors.BLACK} />}
                onClick={this.close}
              />
            </Box>
            <Box flex={false} fill='vertical' direction='column' pad={{ horizontal: 'medium', bottom: 'medium' }}>
              <Row onClick={this.handleInviteModalOpen} border={borderBottom}>
                <Box
                  width='32px'
                  height='32px'
                  border={{ color: colors.ANOTHER_GREY, style: 'dashed' }}
                  round='xxsmall'
                  align='center'
                  justify='center'
                  responsive={false}
                >
                  <FontAwesomeIcon icon={faPlus} size={20} color={colors.ANOTHER_GREY} />
                </Box>
                <Text margin={{ left: 'medium' }} color={colors.ANOTHER_GREY}>
                  Invite Teammate
                </Text>
              </Row>
              <Box overflow='auto' height='medium'>
                {_.map(_.concat(admins, invitations), this.renderUser)}
              </Box>
            </Box>
          </Box>
          <RemoveUserModal user={removingUser} setUser={this.setRemovingUser} dispatch={dispatch} />
          {inviteModalOpen && (
            <ThemeContext.Extend value={{ layer: { border: { radius: 'xsmall' } } }}>
              <Layer
                position='top'
                onClickOutside={this.handleInviteModalOpen}
                onEsc={this.handleInviteModalOpen}
                modal
                margin={{ top: 'large' }}
              >
                <Box width='medium' height='500px' gap='small' flex={false}>
                  <Box pad={{ top: 'medium', horizontal: 'medium' }} flex={false}>
                    <Text color={colors.TEXT} size='large'>
                      Invite teammate
                    </Text>
                  </Box>
                  <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                    <Text color={colors.TEXT}>Email</Text>
                    <MaskedInput mask={emailMask} value={email} onChange={({ target }) => this.handleFieldChange('email', target.value)} />
                  </Box>
                  <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                    <Text color={colors.TEXT}>Phone number:</Text>
                    <MaskedInput mask={phoneMask} value={phone} onChange={({ target }) => this.handleFieldChange('phone', target.value)} />
                  </Box>
                  <Box pad={{ horizontal: 'medium' }} height='100px' flex={false}>
                    <TextArea
                      resize={false}
                      fill
                      placeholder='Add message (optional)'
                      value={message}
                      onChange={({ target }) => this.handleFieldChange('message', target.value)}
                    />
                  </Box>
                  <Box
                    fill='horizontal'
                    margin={{ top: 'auto' }}
                    pad={{ vertical: 'small', horizontal: 'medium' }}
                    align='end'
                    border='top'
                    flex={false}
                  >
                    <Box width='xsmall'>
                      <Button
                        disabled={!validator.isEmail(email) || phone.length < PHONE_NUMBER_LENGTH}
                        primary
                        color={colors.AQUA_MARINE}
                        label='Send'
                        onClick={this.handleSubmit}
                      />
                    </Box>
                  </Box>
                </Box>
              </Layer>
            </ThemeContext.Extend>
          )}
        </Box>
      </Layer>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user,
  canManageUsers: getManageUsersPermission(state),
  admins: _.map(getAccountAdmins(state), admin => ({ ...admin, ..._.get(state, ['profiles', admin.id]) })),
  invitations: _.map(_.values(state.outgoingInvitations), inv => ({
    ...inv,
    ..._.get(state, ['profiles', inv.userId]),
    invited: true,
    inviteId: inv.id
  }))
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(TeammatesModal)
