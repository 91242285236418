import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'sc'

import { StyledText } from 'shared/documents/components/StyledComponents'

const Container = styled(View)`
  padding-top: ${props => props.getSize(20)}px;
  width: 100%;
`
const Comment = (props) => {
  const { comments, getSize } = props
  return (
    <Container getSize={getSize}>
      <StyledText fontSize={getSize(12)} bold>
        Notes:
      </StyledText>
      <StyledText fontSize={getSize(12)}>
        {comments}
      </StyledText>
    </Container>
  )
}

Comment.propTypes = {
  comment: PropTypes.string,
  getSize: PropTypes.func.isRequired
}

export default Comment
