import * as types from 'model/actionTypes'

export function changeSettings (key, value) {
  return {
    type: types.CHANGE_SETTINGS,
    key,
    value
  }
}

export function resetSettings () {
  return {
    type: types.RESET_SETTINGS
  }
}
