import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Box, Button, Text } from 'grommet'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'
import { getAddress, getName } from 'shared/utils/stringUtils'
import { formatNational } from 'shared/utils/phone'

const ProposalInfo = ({ invInfo, onNext }) => {
  const renderRow = (key, value) => {
    return (
      <Box direction='row' align='center' justify='between' fill border='bottom' pad={{ vertical: 'medium' }}>
        <Box basis='1/3' align='center' justify='start' direction='row'>
          <Text size='16px' color={colors.ANOTHER_GREY}>
            {key}
          </Text>
        </Box>
        <Box basis='2/3' align='center' justify='end' direction='row'>
          <Text size='large' weight={600} color={colors.TEXT} textAlign='end'>
            {value}
          </Text>
        </Box>
      </Box>
    )
  }

  const renderButtons = () => {
    return (
      <Box
        fill
        align='center'
        direction='row'
        gap='medium'
        margin={{ top: 'large', bottom: 'large' }}
        height={{ min: '48px' }}
      >
        <Box align='center' height={{ min: '48px' }} fill>
          <Button fill primary color={colors.AQUA_MARINE} onClick={onNext} label='Continue' />
        </Box>
      </Box>
    )
  }

  // const address = `${_.get(invInfo, 'address.structured.main')}, ${_.get(invInfo, 'address.structured.secondary')}`
  const address = getAddress(invInfo.address)

  return (
    <Box pad='medium' align='center'>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box direction='row' align='center' pad={{ top: 'large' }} gap='xsmall'>
        <Text color={colors.LIGHT_NAVY_BRIGHT} weight={700} size='xxlarge' textAlign='center'>
          {`You have received a bid from ${invInfo.subName}`}
        </Text>
      </Box>

      <Box
        align='center'
        margin={{ top: 'medium' }}
        pad={{ top: 'medium' }}
        fill={'horizontal'}
        width={{ max: '520px' }}
        flex={{ grow: 1, shrink: 0 }}
      >
        {renderRow('Contractor', invInfo.subName)}
        {renderRow('Created by', getName(invInfo.subUserProfile))}
        {renderRow(
          'Phone',
          _.has(invInfo, 'subUserProfile.phone') ? formatNational(invInfo.subUserProfile.phone) : '-'
        )}
        {renderRow('Email', _.get(invInfo, 'subUserProfile.email', '-'))}
        {renderRow('Project location', address)}
        {renderRow('Type of work', invInfo.tradeName)}
        <Box pad={{ top: 'large' }}>
          <Text color={colors.LIGHT_NAVY_BRIGHT}>
            {`We ask that you complete a brief signup to view ${invInfo.subName}’s bid.`}
          </Text>
        </Box>
        {renderButtons()}
      </Box>
    </Box>
  )
}

ProposalInfo.propTypes = {
  invInfo: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
}

export default ProposalInfo
