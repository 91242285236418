export default {
  invite_to_bid_sub: 'invite_to_bid_sub',
  invite_to_bid_gc: 'invite_to_bid_gc',
  bid_invite_resolved_sub: 'bid_invite_resolved_sub',
  bid_invite_resolved_gc: 'bid_invite_resolved_gc',
  bid_submitted_gc: 'bid_submitted_gc',
  bid_submitted_sub: 'bid_submitted_sub',
  bid_resolved_gc: 'bid_resolved_gc',
  bid_resolved_sub: 'bid_resolved_sub',
  annotation_created: 'annotation_created',
  teammate_invite_sub: 'teammate_invite_sub',
  teammate_invite_gc: 'teammate_invite_gc',
  plan_files_updated_sub: 'plan_files_updated_sub',
  line_item_resolved_sub: 'line_item_resolved_sub',
  line_item_resolved_gc: 'line_item_resolved_gc',
  line_item_removal_resolved_gc: 'line_item_removal_resolved_gc',
  line_item_removal_resolved_sub: 'line_item_removal_resolved_sub',
  price_requested_sub: 'price_requested_sub',
  optional_line_item_declined_sub: 'optional_line_item_declined_sub',
  bid_updated_gc: 'bid_updated_gc',
  message_created_sub: 'message_created_sub',
  message_created_gc: 'message_created_gc',
  '3_days_reminder_sub': '3_days_reminder_sub',
  '7_days_reminder_sub': '7_days_reminder_sub',
  bid_invite_created_gc: 'bid_invite_created_gc',
  share_report: 'share_report',
  send_files: 'send_files',
  takeoffs_request_for_quote: 'takeoffs_request_for_quote',
  takeoffs_quote_created: 'takeoffs_quote_created',
  takeoffs_quote_changed: 'takeoffs_quote_changed',
  takeoffs_quote_declined: 'takeoffs_quote_declined',
  takeoffs_quote_accepted: 'takeoffs_quote_accepted',
  takeoffs_plan_files_changed: 'takeoffs_plan_files_changed',
  takeoffs_quote_approved_tt: 'takeoffs_quote_approved_tt',
  takeoffs_upfront_paid: 'takeoffs_upfront_paid',
  takeoffs_review: 'takeoffs_review',
  takeoffs_delivered: 'takeoffs_delivered',
  revision_request_tt: 'revision_request_tt',
  takeoffs_accepted: 'takeoffs_accepted',
  takeoffs_declined: 'takeoffs_declined',
  takeoffs_assigned: 'takeoffs_assigned',
  scopes_request: 'scopes_request',
  scopes_request_gc: 'scopes_request_gc',
  scopes_assigned: 'scopes_assigned',
  scopes_assigned_gc: 'scopes_assigned_gc',
  scopes_payment_request: 'scopes_payment_request',
  scopes_delivered: 'scopes_delivered',
  scopes_approved: 'scopes_approved',
  scopes_revision_request: 'scopes_revision_request'
}
