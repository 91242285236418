import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor, Box, Text } from 'grommet'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { requestSmsCode } from 'controllers/auth'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { FIRST_PART_LENGTH, PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import { userIdByPhone } from 'controllers/data'
import AuthLayout from 'pages/auth/AuthLayout'
import { appName } from 'constants/index'
import signInMode from 'constants/signInMode'
import localStorage from 'controllers/localStorage'

class EnterPhone extends Component {
  constructor (props) {
    super(props)
    this.state = {
      phone: '',
      isValid: false,
      isLoading: false,
      errorMessage: null,
      isPrecon: false
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (props.mode !== state.prevMode) {
      return {
        errorMessage: null,
        isLoading: false,
        prevMode: props.mode
      }
    }
    return null
  }

  componentDidMount = async () => {
    const preconConfId = await localStorage.get('preconConfId')
    if (!_.isEmpty(preconConfId)) {
      this.setState({ isPrecon: true })
    }
  }

  componentWillUnmount = () => {
    this.onResponse = () => null
  }

  validatePhone (phone) {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    if (phoneNumber) {
      return phoneNumber.isValid()
    } else {
      return false
    }
  }

  onResponse = (phone, isSent) => {
    console.log('onResponse', isSent)
    const { mode, email } = this.props
    if (isSent) {
      universalNavigation.push(screens.ENTER_CODE, { phone, mode, email })
    } else {
      this.setState({
        errorMessage: 'We could not sent SMS to the phone number'
      })
    }
  }

  onSubmit = async () => {
    try {
      const { phone } = this.state
      const { mode } = this.props
      console.log('onSubmit', phone)
      this.setState({ isLoading: true })
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      const phoneNum = phoneNumber.number
      let error
      if (mode === signInMode.SIGN_IN || mode === signInMode.SIGN_UP) {
        const existingUid = await userIdByPhone(phoneNum)
        if (!existingUid && mode === signInMode.SIGN_IN) {
          error =
            'User does not exist - contact your company admin to invite you to this account or signup to create a new account'
        } else if (existingUid && mode === signInMode.SIGN_UP) {
          error = 'User alredy exists - sign in to continue'
        }
      }
      if (!_.isEmpty(error)) {
        this.setState({ errorMessage: error, isLoading: false })
      } else {
        const isSent = await requestSmsCode(phoneNum)
        this.setState({ isLoading: false })
        this.onResponse(phoneNum, isSent)
      }
    } catch (e) {
      this.setState({ isLoading: false })
      Sentry.captureException(e)
      console.log('requestSmsCode error', e)
    }
  }

  onPhoneChange = async event => {
    const v = event.target.value
    console.log('onPhoneCnahge', v)
    if (v && v.length <= FIRST_PART_LENGTH) {
      this.setState({ phone: v, errorMessage: null })
    } else if (v.length <= PHONE_NUMBER_LENGTH) {
      const aYT = new AsYouType('US')
      const formattedPhone = aYT.input(v)
      const phoneNumber = aYT.getNumber()
      const isValid = !_.isNil(phoneNumber) && phoneNumber.isValid()
      this.setState({
        phone: formattedPhone,
        isValid: isValid,
        errorMessage: null
      })
    }
  }

  renderError = () => {
    const { errorMessage } = this.state
    return (
      <Box width='medium' customStyle={`visibility: ${!_.isNil(errorMessage) ? 'visible' : 'hidden'}`}>
        <Text margin={{ top: 'xsmall', bottom: 'small' }} color={colors.CORAL_TWO}>
          {errorMessage}
        </Text>
      </Box>
    )
  }

  header = () => {
    const { mode } = this.props
    const { isPrecon } = this.state
    switch (mode) {
      case signInMode.SIGN_IN:
        return `Sign in to your ${appName} account`
      case signInMode.SIGN_UP:
        return isPrecon ? `Get started with ${appName}` : `Try ${appName} for free`
      case signInMode.SUB_PROPOSAL:
        return 'Verify it’s you'
      case signInMode.BID_INVITE:
        return 'Enter your phone number'
    }
  }

  renderFooter = (msg, label, onClick) => {
    return (
      <Box
        alignSelf='start'
        pad={{ top: 'medium' }}
        border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
        margin={{ top: 'medium' }}
        width='medium'
        direction='row'
        gap='xsmall'
        align='center'
      >
        <Text size='medium' color={colors.TEXT}>
          {msg}
        </Text>
        <Anchor label={label} color={colors.LINK} onClick={onClick} />
      </Box>
    )
  }

  signInFooter = () =>
    this.renderFooter('Don’t have an account?', 'Setup company account', () =>
      universalNavigation.push(screens.ENTER_EMAIL, { mode: signInMode.SIGN_UP })
    )

  signUpFooter = () =>
    this.renderFooter('Already have an account?', 'Sign in', () =>
      universalNavigation.push(screens.ENTER_PHONE, { mode: signInMode.SIGN_IN })
    )

  footer = () => {
    const { mode } = this.props
    switch (mode) {
      case signInMode.SIGN_IN:
        return this.signInFooter()
      case signInMode.SIGN_UP:
        return this.signUpFooter()
    }
  }

  submitButtonName = () => {
    const { mode } = this.props
    const { isPrecon } = this.state
    switch (mode) {
      case signInMode.SIGN_IN:
        return 'Log in'
      case signInMode.SIGN_UP:
        return isPrecon ? 'Continue' : 'Try for free'
      case signInMode.SUB_PROPOSAL:
        return 'Verify'
      case signInMode.BID_INVITE:
        return 'Log in'
    }
  }

  render () {
    const { phone, isValid, phonePresented, isLoading } = this.state
    const buttonDisabled = !isValid || (!_.isNil(phonePresented) && !phonePresented) || isLoading
    return (
      <AuthLayout
        header={this.header()}
        subheader={'We will text you a verification code to complete your registration'}
        error={this.renderError()}
        isError={!_.isNil(phonePresented) && !phonePresented && isValid}
        valueName='Phone number'
        value={phone}
        placeholder='(500) 555 0000'
        handleChange={this.onPhoneChange}
        handleSubmit={this.onSubmit}
        isValid={!buttonDisabled}
        footer={this.footer()}
        submitName={this.submitButtonName()}
      />
    )
  }
}

EnterPhone.defaultProps = {
  mode: signInMode.SIGN_IN
}

EnterPhone.propTypes = {
  mode: PropTypes.string
}

export default EnterPhone
