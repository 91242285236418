import _ from 'lodash'

export const makeChannelKey = (hicAccountId, projectId, subAccountId) => {
  return `${hicAccountId}_${projectId}_${subAccountId}`
}

export const getInvitedUserProfile = (msg, workOrder) => {
  const accountId = _.get(msg, 'accountId')
  const invId = _.get(msg, 'userId')
  const c = _.find(_.get(workOrder, ['invitations', accountId, 'sendTo']), user => user.invId === invId)
  return { name: _.get(c, 'name') }
}
