import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PixelRatio, Platform, Dimensions } from 'react-native'

const useDimensions = () => {
  const dims = Dimensions.get('window')
  let mounted = true
  const [dimensions, setDimensions] = useState(dims)

  const onChange = ({ window: { width, height, scale, fontScale } }) => {
    if (mounted) {
      setDimensions({ width, height, scale, fontScale })
    }
  }

  useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      mounted = false
      Dimensions.removeEventListener('change', onChange)
    }
  }, [])

  return dimensions
}

const isInInterval = (value, min, max) =>
  (min === undefined || value >= min) && (max === undefined || value <= max)

export const mediaQuery = ({
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  minAspectRatio,
  maxAspectRatio,
  orientation,
  platform,
  minPixelRatio,
  maxPixelRatio,
  condition
}, {
  width,
  height
}) => {
  const currentOrientation = width > height ? 'landscape' : 'portrait'
  return (
    isInInterval(width, minWidth, maxWidth) &&
    isInInterval(height, minHeight, maxHeight) &&
    isInInterval(width / height, minAspectRatio, maxAspectRatio) &&
    isInInterval(PixelRatio.get(), minPixelRatio, maxPixelRatio) &&
    (orientation === undefined || orientation === currentOrientation) &&
    (platform === undefined || platform === Platform.OS) &&
    (condition === undefined || condition)
  )
}

const MediaQuery = ({ children, ...props }) => {
  const { width, height } = useDimensions()
  const val = mediaQuery(props, { width, height })
  if (val) {
    return children
  }
  return null
}

MediaQuery.propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  minAspectRatio: PropTypes.number,
  maxAspectRatio: PropTypes.number,
  orientation: PropTypes.string,
  platform: PropTypes.string,
  minPixelRatio: PropTypes.number,
  maxPixelRatio: PropTypes.number,
  condition: PropTypes.bool
}

export default MediaQuery
