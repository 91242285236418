import React, { Component } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import config from 'shared/config'
import { isGCHost } from 'constants/index'

const divId = 'pdf-file-editor'

const previewFileConf = {
  showPageControls: true,
  dockPageControls: true,
  showAnnotationTools: false,
  showLeftHandPanel: true,
  defaultViewMode: 'FIT_WIDTH',
  enableFormFilling: false,
  showDownloadPDF: true,
  showPrintPDF: false,
  enableAnnotationAPIs: true
}

class SimplePdfPreview extends Component {
  componentDidMount = async () => {
    const { id, url, name, fileConfig } = this.props
    const apiKey = isGCHost ? config.adobeClientIdBidlevel : config.adobeClientIdMason
    const adobeDCView = new window.AdobeDC.View({
      clientId: process.env.NODE_ENV === 'development' ? '8c0cd670273d451cbc9b351b11d22318' : apiKey,
      divId
      // backgroundColor: colors.PALE_GREY
    })
    /* Invoke the file preview API on Adobe DC View object */
    await adobeDCView.previewFile(
      {
        content: {
          location: {
            url
          }
        },
        metaData: {
          fileName: name,
          id
        }
      },
      fileConfig || previewFileConf
    )
  }

  render () {
    return <Box fill background='lightgrey' id={divId} />
  }
}

SimplePdfPreview.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default SimplePdfPreview
