import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faInfoCircle, faCircle, faTrashAlt, faCloudDownload as faCloudDownloadAltLight, faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle, faCloudDownloadAlt as faCloudDownloadAltSolid } from '@fortawesome/pro-solid-svg-icons'
import { Text, Box, Tip } from 'grommet'
import moment from 'moment'

import colors from 'shared/constants/colors'
import { stringDiff } from 'shared/utils/date'
import { downloadFile } from 'controllers/workOrder'
import filesTypes from 'shared/constants/filesTypes'
import LayoutContext from 'webPages/layout/LayoutContext'

const FileRow = ({ type, title, file, onDelete, included, onToggle, onPreview, disabled = false }) => {
  const { openToast } = useContext(LayoutContext)
  const onToggleClick = event => {
    event.stopPropagation()
    onToggle()
  }

  const onDeleteButtonClick = event => {
    event.stopPropagation()
    if (onDelete) onDelete()
  }

  const onDownloadButtonClick = async event => {
    event.stopPropagation()
    const res = await downloadFile(file.url, file.name)
    if (!res) {
      openToast(
        <Box background={colors.CORAL_TWO} justify='center' align='center' pad='small' margin='small'>
          <Text color={colors.TEXT}>Error: file not found</Text>
        </Box>
      )
    }
  }

  const renderTrashButton = () => {
    if (onDelete) {
      return (
        <Box
          onClick={onDeleteButtonClick}
          customStyle={`
            > svg + svg,
            :hover > svg {
              display: none;
            }
            :hover > svg + svg {
              display: inherit;
            }
          `}
        >
          <FontAwesomeIcon icon={faTrashAlt} size={18} color={colors.VERY_LIGHT_PINK} />
          <FontAwesomeIcon icon={faTrashAlt} size={18} color={colors.LIGHT_NAVY_BRIGHT} />
        </Box>
      )
    }
  }

  const renderRadio = () => {
    if (onToggle) {
      return (
        <Box onClick={onToggleClick}>
          <FontAwesomeIcon
            icon={included ? faCheckCircle : faCircle}
            size={18}
            color={included ? colors.AQUA_MARINE : colors.LIGHT_NAVY_DARK}
          />
        </Box>
      )
    }
  }

  const renderDownloadButton = () => {
    return (
      <Box
        onClick={onDownloadButtonClick}
        customStyle={`
          > svg + svg,
          :hover > svg {
            display: none;
          }
          :hover > svg + svg {
            display: inherit;
          }
        `}
      >
        <FontAwesomeIcon icon={faCloudDownloadAltLight} size={18} color={colors.LIGHT_NAVY_BRIGHT} />
        <FontAwesomeIcon icon={faCloudDownloadAltSolid} size={18} color={colors.LIGHT_NAVY_BRIGHT} />
      </Box>
    )
  }

  const renderInfoIcon = () => (
    <Tip content='Preview is not available: file must be saved before it can be previewed'>
      <Box>
        <FontAwesomeIcon icon={faInfoCircle} size={20} />
      </Box>
    </Tip>
  )

  const renderActionButtons = () => {
    switch (type) {
      case filesTypes.BID: {
        return (
          <Box direction='row' align='center' justify='end' gap='small'>
            {disabled && renderInfoIcon()}
            {renderTrashButton()}
          </Box>
        )
      }
      case filesTypes.BID_INVITE: {
        return (
          <Box align='center' direction='row' className='hover-icon' gap='xsmall' onClick={onDeleteButtonClick}>
            <Text size='xsmall' color={colors.CORAL_TWO}>Remove from {title}</Text>
            <FontAwesomeIcon icon={faMinusCircle} size={20} color={colors.CORAL_TWO} />
          </Box>
        )
      }
      case filesTypes.PROJECT: {
        return (
          <Box direction='row'>
            <Box margin={{ right: 'medium' }} align='center' direction='row' className='hover-icon' gap='xsmall' onClick={onToggleClick}>
              <FontAwesomeIcon icon={faPlusCircle} size={20} color={colors.LIGHT_NAVY_BRIGHT} />
              <Text size='xsmall' color={colors.LIGHT_NAVY_BRIGHT}>Add to {title}</Text>
            </Box>
            {renderTrashButton()}
          </Box>
        )
      }
      default: {
        return (
          <Box direction='row' align='center' justify='end' gap='small'>
            {renderDownloadButton()}
            {renderTrashButton()}
            {renderRadio()}
          </Box>
        )
      }
    }
  }

  return (
    <Box
      direction='row'
      align='center'
      key={file.id}
      pad={{ left: 'medium', right: 'small', vertical: 'small' }}
      justify='between'
      hoverIndicator
      onClick={disabled ? () => null : onPreview}
      customStyle={`
        position: relative;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        .hover-icon {
          display: none;
          position: absolute;
          top: 35%;
          right: 5%;
          z-index: 10;
        }
        :hover .hover-icon {
          display: flex
        }
      `}
    >
      <Box>
        <Box>
          <Text size='small' color={colors.TEXT_PRIMARY}>
            {file.name}
          </Text>
        </Box>
        <Box direction='row' margin={{ top: 'small' }}>
          <Box width='150px'>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>
              {`uploaded ${stringDiff(moment(), moment(file.createdAt))}`}
            </Text>
          </Box>
          <Box margin={{ left: 'xsmall' }}>
            <Text size='small'>{numeral(file.size).format('0.0 b')}</Text>
          </Box>
        </Box>
      </Box>
      {renderActionButtons()}
    </Box>
  )
}

FileRow.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func,
  included: PropTypes.bool,
  onToggle: PropTypes.func,
  onPreview: PropTypes.func
}

export default FileRow
