import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Text, CheckBoxGroup, ThemeContext } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import notificationType from 'shared/constants/notificationType'
import { updateNotifications } from '../../controllers/user'

const Notifications = ({ user, dispatch }) => {
  const [notifications, setNotifications] = useState([])
  const [reminders, setReminders] = useState([])

  useEffect(() => {
    const smsDisabled = _.get(user, ['disabledNotifications', 'requests', notificationType.SMS], false)
    const emailDisabled = _.get(user, ['disabledNotifications', 'requests', notificationType.EMAIL], false)
    const pushDisabled = _.get(user, ['disabledNotifications', 'requests', notificationType.PUSH], false)
    const smsReminderDisabled = _.get(user, ['disabledNotifications', 'reminders', notificationType.SMS], false)

    const newNotifications = []
    const newReminders = []
    if (!smsDisabled) newNotifications.push(notificationType.SMS)
    if (!emailDisabled) newNotifications.push(notificationType.EMAIL)
    if (!pushDisabled) newNotifications.push(notificationType.PUSH)
    if (!smsReminderDisabled) newReminders.push(notificationType.SMS)
    setNotifications(newNotifications)
    setReminders(newReminders)
  }, [user])

  const handleChange = (type, value) => {
    if (type === 'requests') {
      setNotifications(value)
      const values = [notificationType.SMS, notificationType.EMAIL, notificationType.PUSH]
      const disabled = _.differenceWith(values, value, _.isEqual)
      const res = {}
      _.forEach(values, item => {
        res[item] = _.includes(disabled, item)
      })
      dispatch(updateNotifications(type, res))
    } else {
      setReminders(value)
      const values = [notificationType.SMS]
      const disabled = _.differenceWith(values, value, _.isEqual)
      const res = {}
      _.forEach(values, item => {
        res[item] = _.includes(disabled, item)
      })
      dispatch(updateNotifications(type, res))
    }
  }

  return (
    <ThemeContext.Extend value={{
      checkBox: {
        border: {
          color: colors.ANOTHER_GREY,
          radius: '4px',
          width: '1px'
        },
        size: '15px'
      }
    }}
    >
      <Box
        flex={false}
        margin={{ horizontal: 'xlarge' }}
      >
        <Box alignSelf='center' width='large' direction='column' flex={false} pad={{ horizontal: 'xlarge', vertical: 'medium' }}>
          <Box pad={{ bottom: 'medium' }} flex={false}>
            <Text size='large' color='dark-1' margin={{ left: 'small' }}>
              Notifications
            </Text>
          </Box>
          <Box
            flex={false}
            direction='column'
            margin={{ left: 'small', bottom: 'medium' }}
          >
            <Box margin={{ bottom: 'small' }}>
              <Text>Send me notifications via: </Text>
            </Box>
            <CheckBoxGroup
              value={notifications}
              valueKey='value'
              labelKey='label'
              options={[
                { label: 'Email', value: notificationType.EMAIL },
                { label: 'SMS', value: notificationType.SMS },
                { label: 'Push notification', value: notificationType.PUSH }
              ]}
              onChange={({ value }) => handleChange('requests', value)}
            />
          </Box>
          <Box
            flex={false}
            direction='column'
            margin={{ left: 'small' }}
          >
            <Box margin={{ bottom: 'small' }}>
              <Text>Unread chat reminders: </Text>
            </Box>
            <CheckBoxGroup
              value={reminders}
              valueKey='value'
              labelKey='label'
              options={[{ label: 'SMS', value: notificationType.SMS }]}
              onChange={({ value }) => handleChange('reminders', value)}
            />
          </Box>
        </Box>
      </Box>
    </ThemeContext.Extend>
  )
}

const mapStateToProps = (state, props) => ({
  user: state.user
})

export default connect(mapStateToProps)(Notifications)
