import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import config from 'shared/config'
// console.log('config', config)

firebase.initializeApp(config)
firebase.firestore.setLogLevel('error')

export const auth = firebase.auth()
export const storage = firebase.storage()
export const storageRef = storage.ref()
export const db = firebase.firestore()
export const ref = firebase.database().ref()
export const firestore = firebase.firestore
export const fieldValue = firebase.firestore.FieldValue

export const generateId = () => {
  return db.collection('tmp').doc().id
}
