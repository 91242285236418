import React, { useState, useMemo, useContext } from 'react'
import _ from 'lodash'
import { Box, Text, ResponsiveContext } from 'grommet'

import colors from 'shared/constants/colors'
import PlanCard from 'components/payments/PlanCard'

const PaymentPlans = ({ onPlanClick, pckg, prices, hideDesc = false }) => {
  const size = useContext(ResponsiveContext)
  const groupsHash = _.get(pckg, 'groups', {})
  const groups = _.orderBy(groupsHash, ['displayOrder'])
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)

  const onGroupClick = i => () => {
    setSelectedGroupIndex(i)
  }

  const renderTabs = () => {
    if (_.size(groups) <= 1) return null
    if (size === 'small') {
      return (
        <Box border='all' elevation={'small'} fill='horizontal'>
          <Box
            direction='row'
            fill
            // pad={{ vertical: 'xsmall' }}
            align='center'
            customStyle={'position: relative;'}
            wrap
          >
            {_.map(groups, (g, i) => (
              <Box
                height={{ min: '60px' }}
                width={{ max: '50%' }}
                key={g.id}
                fill
                align='center'
                direction='row'
                justify='center'
                border={i > 0 ? ['left', 'bottom'] : 'bottom'}
                onClick={onGroupClick(i)}
                background={selectedGroupIndex === i ? colors.LIGHT_NAVY_BRIGHT : colors.WHITE}
              >
                <Text textAlign='center' color={selectedGroupIndex === i ? colors.WHITE : colors.BLACK}>
                  {g.name}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      )
    } else {
      return (
        <Box height='60px' border='all' elevation={'small'} fill='horizontal' flex={{ shrink: 0 }}>
          <Box
            direction='row'
            fill
            pad={{ vertical: 'xsmall' }}
            align='center'
            customStyle={'position: relative;'}
            // wrap
          >
            {_.map(groups, (g, i) => (
              <Box
                key={g.id}
                fill
                align='center'
                direction='row'
                justify='center'
                border={i > 0 ? 'left' : undefined}
                onClick={onGroupClick(i)}
                // background={colors.WHITE}
                customStyle={'z-index: 1;'}
              >
                <Text
                  textAlign='center'
                  color={colors.BLACK}
                  // customStyle='mix-blend-mode: difference; filter:invert(100%)'
                >
                  {g.name}
                </Text>
              </Box>
            ))}
            <Box
              elevation='small'
              customStyle={`
                width: calc(${100 / _.size(groups)}% + 1px);
                height: 100%;
                background-color: ${colors.LIGHT_NAVY_BRIGHT};
                position: absolute;
                left: ${(100 / _.size(groups)) * selectedGroupIndex}%;
                z-index: 2;
                backdrop-filter: invert(100%);
                mix-blend-mode: color-dodge;
                transition:all 0.15s ease-in-out;
              `}
            />
          </Box>
        </Box>
      )
    }
  }

  const plans = useMemo(() => {
    const res = _.get(groups, [selectedGroupIndex, 'plans'])
    return _.orderBy(res, ['displayOrder'])
  }, [groups, selectedGroupIndex])

  const renderPlans = () => {
    return (
      <Box fill='horizontal' direction='row' gap='medium' pad={{ top: 'medium' }} wrap justify='center'>
        {_.map(plans, (p, i) => (
          <PlanCard key={i} plan={p} price={_.get(prices, p.stripePlanId)} onPlanClick={onPlanClick} />
        ))}
      </Box>
    )
  }

  const renderDesc = () => {
    if (hideDesc) return null
    if (size === 'small') {
      return (
        <Box pad={{ vertical: 'medium' }}>
          <Text color={colors.MASON_NAVY_500} textAlign='center'>
            Join hundreds of growing contractor businesses who trust Mason to get their preconstruction right. We’ll
            work with you to find the solution and price that fits your business.
          </Text>
        </Box>
      )
    } else {
      return (
        <Box direction='row' fill='horizontal' justify='center' pad={{ top: 'large' }}>
          <Box>
            <Text size='xxlarge' color={colors.MASON_NAVY_500} weight={700}>
              First project is free
            </Text>
            <Box alignSelf='end' background={colors.AQUA_MARINE} width='64px' height='6px' round='xsmall' />
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box width='xlarge' customStyle='display: block;'>
      {/* <Box pad={{ bottom: 'xsmall', top: 'small' }}>
        <Text color={colors.MASON_NAVY_800}>Annual projects up to:</Text>
      </Box> */}
      {renderTabs()}
      {renderDesc()}
      {renderPlans()}
    </Box>
  )
}

export default PaymentPlans
