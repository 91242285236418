import React from 'react'
import { Box, Text, ThemeContext, FormField, Button, TextInput, Anchor } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const SetupProfile = ({ inviteData, name, setName, phone, handlePhoneChange, handleSubmit, isError, renderError, phoneNumberComplete }) => {
  return (
    <Box pad='medium' align='start' fill>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box fill='horizontal' align='center' flex={false} pad='medium'>
        <Box flex={false} width='600px' gap='medium'>
          <Box align='start' gap='xsmall'>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              You have <Text size='xxlarge' color={colors.AQUA_MARINE}>accepted</Text>
            </Text>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              invitation to join <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} weight='bold'>{_.get(inviteData, 'companyName', '')}</Text>
            </Text>
          </Box>

          <Box align='start'>
            <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>Let’s setup your profile. It will only take a minute.</Text>
          </Box>

          <Box>
            <ThemeContext.Extend
              value={{
                formField: {
                  round: '5px'
                }
              }}
            >
              <FormField label={<Text>What’s your full name?</Text>} width='medium'>
                <TextInput value={name} onChange={e => setName(e.target.value)} />
              </FormField>
            </ThemeContext.Extend>

            <ThemeContext.Extend
              value={{
                formField: {
                  round: '5px',
                  border: {
                    color: isError ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                  },
                  focus: {
                    border: {
                      color: isError ? colors.CORAL_TWO : colors.VERY_LIGHT_GREY_TWO
                    }
                  }
                }
              }}
            >
              <Box>
                <FormField label={<Text>Phone number</Text>} width='medium'>
                  <TextInput value={phone} onChange={handlePhoneChange} />
                </FormField>
                {isError && <Text size='small' color={colors.CORAL_TWO}>{renderError()}</Text>}
              </Box>
            </ThemeContext.Extend>
          </Box>
          <Box width='medium' height='45px'>
            <Button
              fill
              primary
              color={colors.AQUA_MARINE}
              label='Continue'
              onClick={handleSubmit}
              disabled={isError || name === '' || !phoneNumberComplete}
              size='large'
            />
          </Box>

          <Box gap='small'>
            <Box><Text>You’re signing up as {_.get(inviteData, 'email') === '' ? _.get(inviteData, 'phone') : _.get(inviteData, 'email')}</Text></Box>
            <Box direction='row' align='center' gap='xxsmall'>
              <Text>Wrong account?</Text>
              <Anchor label='Sign in' color={colors.LINK} onClick={() => universalNavigation.push(screens.ENTER_PHONE, { mode: 'SIGN_IN' })} />
              <Text>instead</Text></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SetupProfile
