export const BID = 'BID'
export const JOB_OFFER = 'JOB_OFFER'
export const COMPLETION = 'COMPLETION'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const APPROVED = 'APPROVED'
export const SUPPLIER_BID = 'SUPPLIER_BID'
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const CREDIT_REQUEST = 'CREDIT_REQUEST'
export const REIMBURSEMENT_REQUEST = 'REIMBURSEMENT_REQUEST'
export const PROPOSAL = 'PROPOSAL'
export const APPOINTMENT = 'APPOINTMENT'
export const SUPPLY_ORDER = 'SUPPLY_ORDER'
export const APPOINTMENT_OFFER = 'APPOINTMENT_OFFER'
export const WORK_ORDER = 'WORK_ORDER'
export const GC_APPOINTMENT = 'GC_APPOINTMENT'
export const GC_INCOMING_APPOINTMENT = 'GC_INCOMING_APPOINTMENT'

export default {
  BID,
  JOB_OFFER,
  COMPLETION,
  CHANGE_ORDER,
  APPROVED,
  SUPPLIER_BID,
  PAYMENT_REQUEST,
  CREDIT_REQUEST,
  REIMBURSEMENT_REQUEST,
  PROPOSAL,
  APPOINTMENT,
  SUPPLY_ORDER,
  APPOINTMENT_OFFER,
  WORK_ORDER,
  GC_APPOINTMENT,
  GC_INCOMING_APPOINTMENT
}
