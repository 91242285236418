import React, { useMemo } from 'react'
import _ from 'lodash'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import DropDownMenu from 'components/DropDownMenu'
import reportType from 'shared/constants/reportType'
import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const ReportTypeRow = ({ currentType, projectId }) => {
  const reportTypesList = useMemo(
    () => [
      reportType.SELECTED_BIDS,
      reportType.SCOPES_ONLY,
      reportType.SUMMARY,
      reportType.ALL_BIDS,
      reportType.INVITATIONS
    ],
    []
  )

  const options = useMemo(() => {
    return _.map(reportTypesList, id => ({
      id,
      label: reportType.stringOfType(id),
      disabled: id !== reportType.SELECTED_BIDS && id !== reportType.SCOPES_ONLY
    }))
  }, [reportTypesList])

  const onOptionClick = optionId => {
    if (optionId !== currentType) {
      universalNavigation.navigate(screens.GC_PROJECT_SHARE, { projectId, reportType: optionId })
    }
  }

  return (
    <Box direction='row' align='center' justify='between'>
      <Text>Report type</Text>
      <DropDownMenu options={options} selectedId={currentType} onOptionClick={onOptionClick}>
        <Box
          width='175px'
          height='36px'
          align='center'
          justify='between'
          direction='row'
          border='all'
          round='xsmall'
          pad={{ horizontal: 'small' }}
        >
          <Text color={colors.TEXT_PRIMARY}>{reportType.stringOfType(currentType)}</Text>
          <FontAwesomeIcon icon={faAngleDown} color={colors.TEXT_PRIMARY} size={16} />
        </Box>
      </DropDownMenu>
    </Box>
  )
}

export default ReportTypeRow
