import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Button, DropButton, ThemeContext, Accordion, AccordionPanel, Stack, List } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import validator from 'validator'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle, faTimes } from '@fortawesome/pro-solid-svg-icons'
import {
  faEllipsisH,
  faArrowToRight,
  faEdit,
  faFileInvoice,
  faBellOn,
  faPlus,
  faCircle,
  faShareSquare,
  faAlarmClock
} from '@fortawesome/pro-light-svg-icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { getName, getAddressLine2 } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import LevelingPanelHeaderContainer from 'components/leveling/levelingPanel/LevelingPanelHeaderContainer'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'
import { formatNational } from 'shared/utils/phone'
import {
  getSubProfile,
  sendReminder,
  removeSubInvitation,
  inviteSub,
  sendDelayedInvite,
  sendPlanFiles,
  acceptOrDeclineInvite,
  updateWorkOrderAsync
} from 'controllers/workOrder'
import { getTradesDict } from 'model/selectors/references'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import { getAccountsProfiles, getCurrentUserProfile, getUsersProfilesByAccountId } from 'model/selectors/profiles'
import { approveBid, declineBid, hideBid, removeApprovedBid, removeBid } from 'controllers/bids'
import { updateSeenStatus } from 'controllers/chat'
import DropContent from 'components/DropContent'
import LayoutContext from 'webPages/layout/LayoutContext'
import { MessagesList, renderHeader as renderCommentsHeader } from 'components/leveling/levelingPanel/Comments'
import { getLevelingTableMessages } from 'model/selectors/channels'
import CreateContactModal from 'components/workOrder/inviteSubDrop/CreateContactModal'
import { updateAccountContact } from 'controllers/contacts'
import sendTypes from 'shared/constants/inviteStatus'
import { isSilentBid } from 'shared/utils/bid'
import bidStatus from 'shared/constants/bidStatus'
import Activity from 'components/leveling/levelingPanel/Activity'
import Notes from 'components/leveling/levelingPanel/subInfoPanelContent/Notes'
import Toast from 'components/Toast'
import SubInfoPanelContentFooter from 'components/leveling/levelingPanel/subInfoPanelContent/SubInfoPanelContentFooter'
import { fetchSubNotes } from 'controllers/notes'
import UserRow from 'components/leveling/levelingPanel/UserRow'

const borderTop = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'top'
}

const StyledA = styled.a`
  text-decoration: none;
`

const REMOVE_OR_DECLINE = 'REMOVE_OR_DECLINE'
const EDIT_BID = 'EDIT_BID'
const PREVIEW_BID = 'PREVIEW_BID'
const SEND_REMINDER = 'SEND_REMINDER'
const SEND_DELAYED = 'SEND_DELAYED'
const SEND_FILES = 'SEND_FILES'
const ACCEPT_INVITE = 'ACCEPT_INVITE'
const DECLINE_INVITE = 'DECLINE_INVITE'
const SCHEDULE_REMINDER = 'SCHEDULE_REMINDER'
const REMOVE_BID = 'REMOVE_BID'

class SubInfoPanelContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: [1, 2, 3],
      message: '',
      contactModalVisible: false,
      addMoreInvStatus: false
    }
    console.log('SubInfoPanelContent props', props)
    this.messagesEndRef = React.createRef()
    this.contactModalRef = React.createRef()
    this.toastRef = React.createRef()

    console.log('invitation', _.get(this.props.workOrder, ['invitations', props.invId]))
  }

  static getDerivedStateFromProps = (props, state) => {
    const subAccountId = _.get(props.workOrder, ['invitations', props.invId, 'subAccountId'])
    if (subAccountId) {
      const messages = _.concat(_.get(props, 'messages', []), _.get(state, 'notes', []))
      const lastMessage = _.last(messages)
      if (lastMessage && lastMessage.id !== state.lastMessageId) {
        const channelKey = `${props.workOrderId}_${subAccountId}`
        props.dispatch(updateSeenStatus(channelKey, lastMessage))
        return {
          lastMessageId: lastMessage.id
        }
      }
    }
    return null
  }

  getSubAccountId = () => {
    const { invId, workOrder } = this.props
    return _.get(workOrder, ['invitations', invId, 'subAccountId'], invId) || invId
  }

  getBid = () => {
    const { bidsByWorkOrderAccount, workOrderId, invId } = this.props
    return _.get(bidsByWorkOrderAccount, [workOrderId, invId])
  }

  isInviteResolved = () => {
    const { workOrder } = this.props
    const bid = this.getBid()
    const subAccountId = this.getSubAccountId()
    return (
      _.has(workOrder, ['acceptedBy', subAccountId]) || _.has(workOrder, ['declinedBy', subAccountId]) || !_.isNil(bid)
    )
  }

  isSilent = () => {
    const { invId, workOrder } = this.props
    isSilentBid(workOrder, invId)
  }

  fetchSubProfile = async () => {
    const subAccountId = this.getSubAccountId()
    if (subAccountId) {
      const subProfileRes = await getSubProfile(subAccountId)
      this.onSubProfileReceived(subProfileRes)
    } else {
      console.log('the sub has no an account yet')
    }
  }

  componentDidMount = async () => {
    this.fetchSubProfile()
    this.fetchNotes()
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.invId !== prevProps.invId) {
      await this.unsubscribeFromNotes()
      this.setState({
        sub: null
      })
      this.fetchSubProfile()
      this.fetchNotes()
    }
  }

  componentWillUnmount = () => {
    this.onSubProfileReceived = () => null
    this.unsubscribeFromNotes()
  }

  fetchNotes = () => {
    const { invId, workOrderId, account } = this.props
    const accountId = _.get(account, 'id')
    this.unsubscribeFromNotes = fetchSubNotes(accountId, workOrderId, invId, notes => {
      this.setState({ notes })
    })
  }

  onSubProfileReceived = subProfile => {
    const sub = _.get(subProfile, 'sub')
    this.setState({ sub })
  }

  renderHeader = () => {
    const { workOrder, accountsProfiles, invId, onClose, usersProfilesByAccountId } = this.props
    const resolvedInvite = this.isInviteResolved()
    const bid = this.getBid()
    const subAccountId = this.getSubAccountId()
    const profile = _.get(accountsProfiles, invId)
    const approvedBidId = _.get(workOrder, 'approvedBidId')
    const canApprove = _.isEmpty(approvedBidId) && !_.isEmpty(bid)
    const isWinning = !_.isEmpty(approvedBidId) && approvedBidId === _.get(bid, 'id')
    const name = getName(profile)
    const isDelayed = _.get(workOrder, ['invitations', invId, 'sendType'], sendTypes.IMMEDIATELY) === sendTypes.LATER
    const silent = this.isSilent()
    const accepted = _.has(workOrder, ['acceptedBy', subAccountId])
    const declined = _.has(workOrder, ['declinedBy', subAccountId])
    const hasFiles = _.size(_.get(workOrder, 'files', {})) > 0
    const usersProfilesWithEmails = _.filter(_.get(usersProfilesByAccountId, subAccountId), ({ email }) =>
      validator.isEmail(email || '')
    )
    const canSendPlanFiles = _.isEmpty(bid) && !isDelayed && !silent && resolvedInvite && hasFiles
    return (
      <LevelingPanelHeaderContainer border='top' onClose={onClose} align='start' skipCloseRender>
        <Box fill>
          <Box direction='row' pad={{ bottom: 'small' }}>
            <Box fill direction='row' gap='small' align='center'>
              <Text size='large' color={colors.TEXT_PRIMARY} weight={800}>
                {name}
              </Text>
            </Box>
            <Box onClick={onClose}>
              <FontAwesomeIcon icon={faArrowToRight} color={colors.ANOTHER_GREY} size={26} />
            </Box>
          </Box>
          <Box direction='row' gap='small' align='center' justify='between' fill='horizontal' height={{ min: '36px' }}>
            <Box direction='row' gap='small'>
              {canApprove && (
                <Button
                  secondary
                  size='small'
                  label='Mark Won'
                  color={colors.ASANA_TEXT}
                  onClick={this.handleApprove}
                  tip='Select as the winning bid.'
                />
              )}
              {isWinning && (
                <Button
                  secondary
                  size='small'
                  label='Unmark Won'
                  color={colors.ASANA_TEXT}
                  onClick={this.handleRemoveApprove}
                />
              )}
              {!_.isEmpty(bid) && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => this.handleActionClick(EDIT_BID)}
                  icon={<FontAwesomeIcon icon={faEdit} size={24} />}
                  tip='Edit bid'
                />
              )}
              {_.isEmpty(bid) && !isDelayed && !silent && !resolvedInvite && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => this.handleActionClick(SEND_REMINDER)}
                  tip='Send reminder'
                  icon={<FontAwesomeIcon icon={faBellOn} size={24} />}
                />
              )}
              {_.isEmpty(bid) && !isDelayed && !silent && !resolvedInvite && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => this.handleActionClick(SCHEDULE_REMINDER)}
                  tip='Schedule reminder'
                  icon={<FontAwesomeIcon icon={faAlarmClock} size={20} />}
                />
              )}
              {canSendPlanFiles && _.size(usersProfilesWithEmails) > 0 && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => this.handleActionClick(SEND_FILES, { usersProfilesWithEmails })}
                  tip='Resend plan file attachments'
                  icon={<FontAwesomeIcon icon={faShareSquare} size={24} />}
                />
              )}
              {canSendPlanFiles && _.size(usersProfilesWithEmails) === 0 && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => null}
                  tip='Please add an email address to at least one user being invited'
                  icon={<FontAwesomeIcon icon={faShareSquare} size={24} />}
                  customStyle='cursor: not-allowed'
                />
              )}
              {!_.isEmpty(bid) && (
                <Button
                  plain
                  color={colors.ASANA_TEXT}
                  onClick={() => this.handleActionClick(PREVIEW_BID)}
                  tip='View Bid Document'
                  icon={<FontAwesomeIcon icon={faFileInvoice} size={24} />}
                />
              )}
              {isDelayed && !silent && (
                <Button
                  primary
                  color={colors.AQUA_MARINE}
                  onClick={() => this.handleActionClick(SEND_DELAYED)}
                  tip='Send delayed message'
                  label='Send'
                />
              )}
            </Box>
            <Box align='end' justify='center' width='xxsmall'>
              <DropButton
                plain
                secondary
                color={colors.DARK_GRAY_TWO}
                hoverTextColor={colors.WHITE}
                customStyle={` > div {
                    justify-content: center;
                  }`}
                label={<FontAwesomeIcon icon={faEllipsisH} size={24} color={colors.TEXT} />}
                reverse
                size='large'
                dropContent={this.renderMoreActionsDropContent(isWinning, bid, accepted, declined)}
                dropProps={{ align: { top: 'bottom', right: 'left' } }}
              />
            </Box>
          </Box>
        </Box>
      </LevelingPanelHeaderContainer>
    )
  }

  handleApprove = () => {
    const { dispatch } = this.props
    const bid = this.getBid()
    dispatch(approveBid(bid))
  }

  handleRemoveApprove = () => {
    const bid = this.getBid()
    removeApprovedBid(bid)
  }

  handleActionClick = (id, params) => {
    const { workOrder, openCreateBidManuallyModal, invId, onClose, workOrderId, dispatch, account } = this.props
    const bid = this.getBid()
    const approvedBidId = _.get(workOrder, 'approvedBidId')
    const isWinning = !_.isEmpty(approvedBidId) && approvedBidId === _.get(bid, 'id')
    const isBidDeclined = _.isEqual(_.get(bid, 'status.type', ''), bidStatus.DECLINED)
    // const isAccepted = _.has(workOrder, ['acceptedBy', invId])
    // const isDeclined = _.has(workOrder, ['declinedBy', invId])
    switch (id) {
      case EDIT_BID:
        openCreateBidManuallyModal(invId)
        onClose()
        break
      case PREVIEW_BID:
        this.context.openBidPreviewModal(bid.id)
        onClose()
        break
      case SEND_REMINDER:
        sendReminder(invId, workOrderId)
        onClose()
        break
      case REMOVE_OR_DECLINE: {
        if (bid && isBidDeclined) {
          dispatch(hideBid(bid))
        } else if (bid && !isWinning) {
          dispatch(declineBid(bid))
        } else if (!isWinning) {
          const invIds = []
          _.forEach(_.get(workOrder, ['invitations', invId, 'sendTo']), ({ invId }) => {
            invIds.push(invId)
          })
          dispatch(removeSubInvitation(invId, workOrderId, invIds))
        }
        onClose()
        break
      }
      case SEND_DELAYED: {
        sendDelayedInvite(account.id, workOrderId, invId)
        break
      }
      case SEND_FILES: {
        const isAccepted = _.has(workOrder, ['acceptedBy', invId])
        const isDeclined = _.has(workOrder, ['declinedBy', invId])
        let status = 'pending'
        if (isAccepted) status = 'accepted'
        if (isDeclined) status = 'declined'
        // FIXME: this sends plan files from client, it has to be moved to FF
        dispatch(sendPlanFiles(invId, workOrder, status))
        const usersProfilesWithEmails = _.get(params, 'usersProfilesWithEmails', [])
        const content = (
          <Box
            elevation='small'
            pad='small'
            width='medium'
            gap='small'
            background={colors.PALE_GREY}
            margin='small'
            round='small'
          >
            <Box direction='row' justify='between' align='center'>
              <Text size='large' weight={600} color={colors.TEXT}>
                Plan files sent to:
              </Text>
              <Box onClick={() => this.toastRef.current.close()}>
                <FontAwesomeIcon icon={faTimes} color={colors.TEXT} />
              </Box>
            </Box>
            {_.map(usersProfilesWithEmails, this.renderUserCard)}
          </Box>
        )
        this.toastRef.current.open(content)
        break
      }
      case ACCEPT_INVITE: {
        acceptOrDeclineInvite(invId, workOrderId, true)
        onClose()
        break
      }
      case DECLINE_INVITE: {
        acceptOrDeclineInvite(invId, workOrderId, false)
        onClose()
        break
      }
      case SCHEDULE_REMINDER: {
        const { openSchedulerReminder } = this.context
        if (_.isFunction(openSchedulerReminder)) {
          openSchedulerReminder(workOrderId, invId)
        }
        onClose()
        break
      }
      case REMOVE_BID: {
        removeBid(_.get(bid, 'id'))
        onClose()
        break
      }
      default:
        break
    }
  }

  renderUserCard = ({ name, email, phone }) => (
    <Box gap='xsmall'>
      <Text size='large' color={colors.TEXT} weight={500}>
        {name}:
      </Text>
      <Box gap='xsmall' justify='center'>
        {email && (
          <Text size='small' color={colors.TEXT}>
            Email: {email}
          </Text>
        )}
        {phone && (
          <Text size='small' color={colors.TEXT}>
            Phone: {parsePhoneNumberFromString(phone, 'US').formatNational()}
          </Text>
        )}
      </Box>
    </Box>
  )

  renderRemoveDeclineLabel = bid => {
    return _.isNil(bid) ? 'Remove invite' : 'Decline bid'
  }

  renderMoreActionsDropContent = (isWinning, bid, accepted, declined) => {
    const options = [{ id: REMOVE_OR_DECLINE, label: this.renderRemoveDeclineLabel(bid), disabled: isWinning }]
    if (_.isNil(bid)) {
      if (!declined) {
        options.unshift({
          id: DECLINE_INVITE,
          label: (
            <Box direction='row' gap='xxsmall'>
              <Text color={colors.CONTACT_LABEL} size='medium'>
                Mark invite
              </Text>
              <Text color={colors.CORAL_TWO} size='medium'>
                declined
              </Text>
            </Box>
          )
        })
      }
      if (!accepted) {
        options.unshift({
          id: ACCEPT_INVITE,
          label: (
            <Box direction='row' gap='xxsmall'>
              <Text color={colors.CONTACT_LABEL} size='medium'>
                Mark invite
              </Text>
              <Text color={colors.AQUA_MARINE} size='medium'>
                accepted
              </Text>
            </Box>
          )
        })
      }
    } else {
      options.push({
        id: REMOVE_BID,
        label: (
          <Box direction='row' gap='xxsmall'>
            <Text color={colors.CONTACT_LABEL} size='medium'>
              Remove bid
            </Text>
          </Box>
        )
      })
    }

    return (
      <DropContent
        boxProps={{ className: IGNORE_OUTSIDE_CLICK_CLASS, width: '200px' }}
        onOptionClick={this.handleActionClick}
        options={options}
      />
    )
  }

  renderFooter = () => {
    const subAccountId = this.getSubAccountId()
    if (!this.isSilent() && subAccountId) {
      const { accountsProfiles, invId, profiles, userId, workOrder } = this.props
      const subCompanyName = getName(_.get(accountsProfiles, invId))
      const userProfile = _.get(profiles, userId)
      return (
        <SubInfoPanelContentFooter
          subCompanyName={subCompanyName}
          subAccountId={this.getSubAccountId()}
          userProfile={userProfile}
          messagesEndRef={this.messagesEndRef}
          workOrder={workOrder}
          userId={userId}
        />
      )
    }
  }

  renderContactModal = () => {
    const { accountsProfiles, invId } = this.props
    const profile = _.get(accountsProfiles, invId)
    return (
      <CreateContactModal
        ref={this.contactModalRef}
        visible={this.state.contactModalVisible}
        onClose={this.closeContactModal}
        companyName={getName(profile)}
        addContact={this.addContact}
        removeContact={this.removeContact}
      />
    )
  }

  handleActive = v => this.setState({ activeIndex: v })

  openCreateContactModal = c => {
    this.setState({ contactModalVisible: true })
    this.contactModalRef.current.init(c)
  }

  closeContactModal = () => {
    this.setState({ contactModalVisible: false })
  }

  addContact = async p => {
    const { invId, workOrderId, workOrder, userId } = this.props
    const inv = _.get(workOrder, ['invitations', invId])
    const companyId = _.get(inv, 'companyId')
    await updateAccountContact({ ...p, companyId })
    const upd = {
      [`invitations.${invId}.sendTo.${p.id}`]: { ...p, invitedBy: userId }
    }
    updateWorkOrderAsync(workOrderId, upd)
    close()
  }

  removeContact = pId => {}

  addContactToCompany = () => {
    this.openCreateContactModal()
  }

  // add not invited users & save to invite them

  addMoreInv = () => {
    const { addMoreInvStatus, companyUsers } = this.state
    if (addMoreInvStatus) {
      // done
      const { accId, accountsProfiles, workOrderId, workOrder } = this.props
      const profile = _.get(accountsProfiles, accId)
      const companyId = _.get(profile, 'id', '')
      const dbSendTo = {}
      const invitationId = _.get(profile, 'id', '')
      const sendTo = _.get(workOrder, `invitations.${invitationId}.sendTo`)
      _.map(companyUsers, to => {
        if (to.checked) {
          _.set(dbSendTo, to.id, _.pick(to, 'id', 'name', 'emails', 'phones'))
        }
      })
      const numbersToAdd = _.toArray(dbSendTo).length

      _.map(sendTo, to => {
        _.set(dbSendTo, to.id, { ...to, ignore: true })
      })

      if (numbersToAdd > 0) {
        inviteSub(accId, companyId, dbSendTo, workOrderId)
      }
    } else {
      // add
      let users = companyUsers
      _.map(users, user => {
        _.set(users, [user.id, 'checked'], false)
      })
      this.setState({ companyUsers: users })
    }
    this.setState({ addMoreInvStatus: !this.state.addMoreInvStatus })
  }

  renderContractorTypes = () => {
    const { sub } = this.state
    const { tradesDict } = this.props
    const ids = _.get(sub, 'contractorTypeIds')
    if (!_.isEmpty(ids)) {
      return (
        <Box direction='row' pad={{ top: 'small' }} wrap gap='small' height={{ min: '54px' }}>
          {_.map(ids, ctId => {
            return (
              <Box
                key={ctId}
                background={colors.WHITE}
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
                margin={{ top: 'small' }}
                round
                border={{ color: colors.VERY_LIGHT_PINK, size: 'xsmall', side: 'all' }}
              >
                <Text truncate color={colors.VERY_LIGHT_PINK}>
                  {_.get(tradesDict, [ctId, 'name'])}
                </Text>
              </Box>
            )
          })}
        </Box>
      )
    }
  }

  renderUser = (user, index) => {
    const { profiles, invId, workOrder } = this.props
    const userProfile = _.get(profiles, _.get(user, 'id'))
    const accInvId = _.get(workOrder, `invitations.${invId}.invId`)
    return <UserRow key={index} user={user} userProfile={userProfile} invId={accInvId} />
  }

  toggleUserId = userId => e => {
    e.stopPropagation()
    const newSendTo = { ...this.state.companyUsers }
    const curValue = _.get(newSendTo, [userId, 'checked'])
    _.set(newSendTo, [userId, 'checked'], !curValue)
    this.setState({ companyUsers: newSendTo })
  }

  renderRadio = (userId, checked) => {
    const size = 24
    const faIcon = checked ? faCheckCircle : faCircle
    return (
      <Box flex={{ shrink: 0 }} onClick={this.toggleUserId(userId)}>
        <FontAwesomeIcon icon={faIcon} size={size} color={colors.AQUA_MARINE} />
      </Box>
    )
  }

  renderContactRow = c => {
    const emails = _.get(c, 'emails', [])
    const phones = _.map(_.get(c, 'phones', []), formatNational)
    const desc = [...emails, ...phones].join(', ')
    const checked = _.get(c, 'checked', false)
    return (
      <Box direction='row' justify='between' align='center' pad={{ vertical: 'small' }} gap='medium' hoverIndicator>
        <Box gap='xxsmall' width={{ max: '300px' }}>
          <Text color={colors.TEXT_PRIMARY} size='small'>
            {getName(c)}
          </Text>
          <Text size='small' color={colors.VERY_LIGHT_PINK} truncate customStyle='white-space: nowrap;'>
            {desc}
          </Text>
        </Box>
        {this.renderRadio(c.id, checked)}
      </Box>
    )
  }

  renderCompanyUsers = () => {
    const { companyUsers } = this.state
    return (
      <Box overflow={{ vertical: 'auto', horizontal: 'hidden' }} margin={{ top: 'small' }} pad={{ bottom: 'medium' }}>
        <List children={this.renderContactRow} data={_.values(companyUsers)} pad='none' />
      </Box>
    )
  }

  renderCompanyDetails = () => {
    const { invId, accountsProfiles } = this.props
    const profile = _.get(accountsProfiles, invId)
    const address = _.get(profile, 'address')
    const addressLine1 = _.get(profile, 'address.structured.main')
    const addressLine2 = getAddressLine2(_.get(profile, 'address'))
    const email = _.get(profile, 'email')
    const phone = _.get(profile, 'phone')
    return (
      <Box pad={{ horizontal: 'small', bottom: 'small' }}>
        {!_.isEmpty(address) && (
          <>
            <Text margin={{ vertical: 'xsmall' }} color={colors.MEDIUM_GREY}>
              Company address
            </Text>
            <Text color={colors.TEXT_PRIMARY}>{addressLine1}</Text>
            <Text color={colors.TEXT_PRIMARY}>{addressLine2}</Text>
          </>
        )}
        {email && (
          <Box margin={{ top: 'small' }}>
            <StyledA href={`mailto:${email}`}>
              <Text color={colors.CLEAR_BLUE}>{email}</Text>
            </StyledA>
          </Box>
        )}
        {phone && (
          <Text margin={{ top: 'small' }} color={colors.TEXT_PRIMARY}>
            {formatNational(phone)}
          </Text>
        )}
        {this.renderContractorTypes()}
      </Box>
    )
  }

  renderPeopleSection = () => {
    const { invId, workOrder } = this.props
    const { addMoreInvStatus } = this.state
    // const resolvedInvite = this.isInviteResolved()
    // if (resolvedInvite) {
    //   const users = _.get(usersProfilesByAccountId, invId)
    //   return <Box pad='small'>{_.map(users, this.renderUser)}</Box>
    // }
    const sendTo = _.get(workOrder, `invitations.${invId}.sendTo`)
    const isWon = !_.isEmpty(_.get(workOrder, 'approvedBidId'))
    return (
      <Box pad={{ bottom: 'small' }}>
        <Box direction='row' justify='end' align='center' margin={{ top: 'small', bottom: 'xsmall' }} pad={{ horizontal: 'small' }}>
          <Box onClick={this.addMoreInv}>
            <Text color={colors.CLEAR_BLUE} style={{ fontWeight: 600 }}>
              {`${addMoreInvStatus ? 'Done' : 'Add'}`}
            </Text>
          </Box>
        </Box>
        {addMoreInvStatus ? this.renderCompanyUsers() : _.map(sendTo, this.renderUser)}
        {!isWon && !addMoreInvStatus && (
          <Box
            direction='row'
            align='center'
            border={!addMoreInvStatus ? borderTop : null}
            pad={{ top: !addMoreInvStatus ? 'small' : 'xxsmall', bottom: 'medium', horizontal: 'small' }}
            onClick={this.addContactToCompany}
          >
            <FontAwesomeIcon icon={faPlus} size={14} color={colors.VERY_LIGHT_PINK} />
            <Text color={colors.VERY_LIGHT_PINK} margin={{ left: 'xsmall' }}>
              Invite a new person to bid invitation
            </Text>
          </Box>
        )}
      </Box>
    )
  }

  renderTimeLineIconActive = () => {
    return (
      <Stack anchor='center' margin={{ top: '8px' }}>
        <Box width='16px' height='16px' background={colors.WHITE} round />
        <Box background={colors.AQUA_MARINE30} width='12px' height='12px' round />
        <Box background={colors.AQUA_MARINE} width='8px' height='8px' round />
      </Stack>
    )
  }

  renderTimeLineIconInactive = () => {
    return (
      <Stack anchor='center' margin={{ top: '8px' }}>
        <Box width='16px' height='16px' background={colors.WHITE} round />
        <Box background={colors.VERY_LIGHT_GREY_TWO} width='12px' height='12px' round />
        <Box background={colors.WHITE} width='10px' height='10px' round />
      </Stack>
    )
  }

  renderActivity = () => {
    const { workOrderId, invId } = this.props
    return (
      <Box>
        <Activity workOrderId={workOrderId} subId={invId} />
      </Box>
    )
  }

  renderComments = () => {
    const { profiles, messages, accountsProfiles, invId, workOrderId, workOrder } = this.props
    const silent = this.isSilent()
    const subAccountId = this.getSubAccountId()
    if (!silent && subAccountId) {
      const filteredMessages = _.filter(messages, msg => msg.workOrderId === workOrderId)
      const subAccount = _.get(accountsProfiles, invId)
      const name = getName(subAccount)
      return (
        <Box background={colors.PALE_GREY} flex={{ shrink: 0 }}>
          {renderCommentsHeader(name, colors.PALE_GREY)}
          <MessagesList workOrder={workOrder} messages={filteredMessages} messagesEndRef={this.messagesEndRef} profiles={profiles} />
        </Box>
      )
    }
  }

  renderNotes = () => {
    const { workOrderId, accountsProfiles } = this.props
    const { notes } = this.state
    const subAccountId = this.getSubAccountId()
    if (subAccountId) {
      const profile = _.get(accountsProfiles, subAccountId)
      const descriptions = ['Only your team can see the conversation.', `Not visible to ${getName(profile)}`]
      return <Notes workOrderId={workOrderId} accId={subAccountId} descriptions={descriptions} notes={notes} />
    }
  }

  renderContent = () => {
    const { activeIndex } = this.state
    const { accountsProfiles, invId } = this.props
    const resolvedInvite = this.isInviteResolved()
    const subAccount = _.get(accountsProfiles, invId)
    const name = getName(subAccount)
    const renderLabel = v => (
      <Text margin={{ vertical: 'small', left: 'small' }} weight={600} size='large' color={colors.BROWN_GREY_TWO}>
        {v}
      </Text>
    )
    const accordionTheme = {
      accordion: {
        panel: {
          border: {
            side: 'top'
          }
        }
      }
    }

    return (
      <ThemeContext.Extend value={accordionTheme}>
        <Accordion
          flex={{ shrink: 0 }}
          multiple
          activeIndex={activeIndex}
          onActive={this.handleActive}
          color={colors.BROWN_GREY_TWO}
          background={colors.WHITE}
        >
          <AccordionPanel label={renderLabel('Company details')}>{this.renderCompanyDetails()}</AccordionPanel>
          <AccordionPanel label={renderLabel(`${name} ${resolvedInvite ? 'users' : 'invitations'}`)}>
            {this.renderPeopleSection()}
          </AccordionPanel>
          <AccordionPanel label={renderLabel('Activity')}>{this.renderActivity()}</AccordionPanel>
          {this.renderNotes()}
        </Accordion>
      </ThemeContext.Extend>
    )
  }

  render () {
    const { isSilentBid } = this.state
    return (
      <Box height='100%' customStyle='* { transition: all 0s ease; }' className={IGNORE_OUTSIDE_CLICK_CLASS}>
        {this.renderHeader()}
        <Box
          fill
          className={IGNORE_OUTSIDE_CLICK_CLASS}
          overflow='auto'
          height='100%'
          customStyle='{ scroll-behavior: smooth; }'
          background={isSilentBid ? colors.WHITE : colors.PALE_GREY}
        >
          {this.renderContent()}
          {this.renderComments()}
        </Box>
        {this.renderFooter()}
        {this.renderContactModal()}
        <Toast ref={this.toastRef} />
      </Box>
    )
  }
}

SubInfoPanelContent.contextType = LayoutContext

SubInfoPanelContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  workOrderId: PropTypes.string.isRequired,
  // accId: PropTypes.string.isRequired,
  invId: PropTypes.string.isRequired,
  openCreateBidManuallyModal: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: getAccountsProfiles(state),
  tradesDict: getTradesDict(state),
  workOrder: getWorkOrder(state, props),
  bidsByWorkOrderAccount: getBidsByWorkOrdersAccounts(state),
  userProfile: getCurrentUserProfile(state),
  messages: _.get(getLevelingTableMessages(state), ['subs', props.invId], []),
  profiles: state.profiles,
  userId: _.get(state, 'user.id'),
  account: state.account,
  usersProfilesByAccountId: getUsersProfilesByAccountId(state)
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(SubInfoPanelContent)
