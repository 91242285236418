export const NEW = 'new'
export const ACCEPTED = 'accepted'
export const DECLINED = 'declined'
export const LOST = 'lost'

const stringOfStatus = s => {
  switch (s) {
    case NEW:
      return 'Pending'
    case ACCEPTED:
      return 'Accepted'
    case DECLINED:
      return 'Declined'
    case LOST:
      return 'Lost'
  }
}

export default {
  NEW,
  ACCEPTED,
  DECLINED,
  LOST,
  stringOfStatus
}
