import React from 'react'
import { Box, Text } from 'grommet'
import colors from 'shared/constants/colors'

const StatusPendingAnswer = () => {
  return (
    <Box background={colors.WHITE_GREY} width='81px' align='center' justify='center'>
      <Text color={colors.VERY_LIGHT_GREY} size='xsmall' weight={600} italic>
        Pending answer
      </Text>
    </Box>
  )
}

export default React.memo(StatusPendingAnswer)
