import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import { View, KeyboardAvoidingView, SafeAreaView } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import Constants from 'expo-constants'

import { isIos, majorVersionIOS } from 'constants/index'
import { getViewports } from 'shared/model/selectors/viewportSelector'
import { isMediumScreen } from 'shared/utils/breakpoints'
import CloseKeyboardButton from 'shared/components/buttons/CloseKeyboardButton'

const ContentContainer = styled(View)`
  /* height: 100%;
  width: 100%; */
  flex: 1;
  display: flex;
  background-color: ${props => props.backgroundColor};
  padding-top: ${props => (props.hasStatusBar ? 0 : Constants.statusBarHeight)}px;
  ${props => props.customStyle};
`

const FixBottomSafeArea = styled(View)`
  background-color: ${props => props.backgroundColor};
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 100px;
  z-index: -1000;
  ${props => props.customStyle}
`
const fixStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  height: Constants.statusBarHeight
}

const FixStatusBarColor = ({ color }) => (
  <View
    style={{
      ...fixStyle,
      backgroundColor: color
    }}
  />
)

const Page = ({
  children,
  backgroundColor,
  customStyle,
  statusBarStyle,
  statusBarColor,
  gradient,
  screenViewport,
  viewport,
  bottomSafeAreaCustomStyle,
  keyboardDidShow,
  hideCloseKeyboardButton,
  closeKeyboardButtonCustomStyle,
  bottomSafeAreaColor,
  hideStatusBar,
  ...rest
}) => {
  let forceInset
  const hasStatusBar = (isIos && majorVersionIOS >= 11) || isMediumScreen(screenViewport) || hideStatusBar
  if (isMediumScreen(screenViewport)) {
    forceInset = { top: 'never' }
  }
  let stBarStyle = 'auto'
  if (statusBarStyle === 'light-content') stBarStyle = 'light'
  if (statusBarStyle === 'dark-content') stBarStyle = 'dark'
  return (
    // <SafeAreaView forceInset={forceInset} style={{ flex: 1, backgroundColor: statusBarColor || backgroundColor }} key={rest.key} {...rest} >
    // <View style={{ paddingTop: insets.top, flex: 1 }}>
    // <SafeAreaProvider>
    <SafeAreaView
      forceInset={forceInset}
      style={{ flex: 1, backgroundColor: statusBarColor || backgroundColor }}
      key={rest.key}
      {...rest}
    >
      {!hideStatusBar && <StatusBar style={stBarStyle} backgroundColor={statusBarColor || 'transparent'} translucent />}
      <KeyboardAvoidingView behavior={isIos ? 'padding' : undefined} style={{ flex: 1 }}>
        <ContentContainer
          backgroundColor={backgroundColor}
          customStyle={customStyle}
          screenViewport={screenViewport}
          viewport={viewport}
          hasStatusBar={hasStatusBar}
        >
          {children}
        </ContentContainer>
        <CloseKeyboardButton
          keyboardDidShow={keyboardDidShow}
          viewport={viewport}
          backgroundColor={backgroundColor}
          hideCloseKeyboardButton={hideCloseKeyboardButton}
          customStyle={closeKeyboardButtonCustomStyle}
        />
      </KeyboardAvoidingView>
      {hasStatusBar || gradient ? null : <FixStatusBarColor color={statusBarColor} />}
      <FixBottomSafeArea
        backgroundColor={bottomSafeAreaColor || backgroundColor}
        customStyle={bottomSafeAreaCustomStyle}
      />
    </SafeAreaView>
    // </SafeAreaProvider>
  )
}

Page.defaultProps = {
  keyboardDidShow: () => null,
  backgroundColor: 'transparent',
  statusBarStyle: 'default',
  customStyle: '',
  gradient: false,
  closeKeyboardButtonCustomStyle: ''
}

Page.propTypes = {
  keyboardDidShow: PropTypes.func,
  backgroundColor: PropTypes.string,
  statusBarStyle: PropTypes.oneOf(['default', 'light-content', 'dark-content']),
  statusBarColor: PropTypes.string,
  customStyle: PropTypes.string,
  gradient: PropTypes.bool,
  bottomSafeAreaCustomStyle: PropTypes.string,
  hideCloseKeyboardButton: PropTypes.bool,
  closeKeyboardButtonCustomStyle: PropTypes.string,
  bottomSafeAreaColor: PropTypes.string,
  hideStatusBar: PropTypes.bool
}

const mapStateToProps = (state, props) => ({
  ...getViewports(state, props)
})

export default connect(mapStateToProps)(Page)
