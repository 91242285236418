import _ from 'lodash'
import reportCostPlusOrMarkup from 'shared/constants/reportCostPlusOrMarkup'

export const COST_PLUS_OR_MARKUP = 'costPlusOrMarkup'
export const COST_PLUS_OR_MARKUP_AMOUNT = 'costPlusOrMarkupAmount'
export const SHOW_SUMMARY = 'showSummary'
export const SHOW_BIDS_TOTALS = 'showBidsTotals'
export const SHOW_NUMBER_OF_BIDS = 'showNumberOfBids'
export const SHOW_NUMBER_OF_INVITED = 'showNumberOfInvited'
export const SHOW_TRADES_WITH_NO_BIDS = 'showTradesWithNoBids'
export const SHOW_SUB_NAME = 'showSubName'
export const SHOW_COMPANY_LOGO = 'showCompanyLogo'
export const SHOW_SECTION_SUBTOTALS = 'showSectionSubtotals'

export const getDefaultValue = t => {
  switch (t) {
    case SHOW_SUMMARY:
    case SHOW_NUMBER_OF_BIDS:
    case SHOW_TRADES_WITH_NO_BIDS:
    case SHOW_SUB_NAME:
      return true
    case COST_PLUS_OR_MARKUP:
      return reportCostPlusOrMarkup.NONE
    case COST_PLUS_OR_MARKUP_AMOUNT:
      return 0
    default:
      return false
  }
}

export const getValue = (reportsSettings, t) => {
  return _.get(reportsSettings, t, getDefaultValue(t))
}

export default {
  COST_PLUS_OR_MARKUP,
  COST_PLUS_OR_MARKUP_AMOUNT,
  SHOW_SUMMARY,
  SHOW_BIDS_TOTALS,
  SHOW_NUMBER_OF_BIDS,
  SHOW_NUMBER_OF_INVITED,
  SHOW_TRADES_WITH_NO_BIDS,
  SHOW_SUB_NAME,
  SHOW_COMPANY_LOGO,
  SHOW_SECTION_SUBTOTALS,
  getDefaultValue,
  getValue
}
