import React, { useCallback, useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Button, Keyboard } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faBars, faColumns, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import styled, { css } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useScroll, useMeasure } from 'react-use'
import AutosizeInput from 'react-input-autosize'
import { useStateWithCallbackLazy } from 'shared/utils/hooks'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import LayoutContext from 'webPages/layout/LayoutContext'
import { updateWorkOrderAsync } from 'controllers/workOrder'
import DropDownMenu from 'components/DropDownMenu'

const WorkOrdersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex: 1;
`
const WorkOrderTab = styled.div`
  margin-right: 16px;
  border-bottom: 2px solid ${props => (props.selected ? colors.AQUA_MARINE : 'transparent')};
  :hover {
    cursor: ${props => (props.selected ? 'text' : 'pointer')};
  }
  ${props =>
    !props.selected &&
    `:hover span {
        color: ${colors.ASANA_GRAY_TEXT_HOVERED};

    }`}
`
const scrollBarStyle = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Works on Chrome/Edge/Safari */
  ::-webkit-scrollbar {
    display: none;
  }
`

const Submenu = ({ workOrderId, menuItems, projectId, onOrderChange }) => {
  const { openBidInviteModal } = useContext(LayoutContext)
  const scrollRef = useRef(null)
  const { x } = useScroll(scrollRef)
  const [wrapperRef, wrapperParams] = useMeasure()
  const [itemsContainerRef, itemsContainerParams] = useMeasure()
  const [editMode, setEditMode] = useStateWithCallbackLazy(false)
  const [labelValue, setLabelValue] = useState(null)
  const inputRef = useRef()

  const onDashboardClick = () => {
    universalNavigation.push(screens.GC_PROJECT, { projectId })
  }

  const onWorkOrderClick = woId => e => {
    // console.log('onWorkOrderClick', woId, e)
    if (woId !== workOrderId) {
      universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, { projectId, workOrderId: woId })
      setLabelValue(null)
      setEditMode(false)
    } else {
      setLabelValue(null)
      setEditMode(true, () => {
        inputRef.current.focus()
      })
    }
  }

  const renderDashboardButton = () => {
    const color = _.isNil(workOrderId) ? colors.WHITE : colors.LIGHT_NAVY_BRIGHT
    const bgColor = !_.isNil(workOrderId) ? colors.WHITE : colors.LIGHT_NAVY_BRIGHT
    return (
      <Box
        background={bgColor}
        direction='row'
        pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
        align='center'
        gap='xxsmall'
        round='xxsmall'
        flex={{ shrink: 0 }}
        onClick={_.isNil(workOrderId) ? undefined : onDashboardClick}
      >
        <FontAwesomeIcon icon={faColumns} size={14} color={color} />
        <Text color={color} size='small'>
          Dashboard
        </Text>
      </Box>
    )
  }

  const onDragEnd = event => {
    // console.log('onDragEnd', event)
    onOrderChange(_.get(event, 'source.index'), _.get(event, 'destination.index'))
  }

  const onChangeWoLabel = e => {
    setLabelValue(e.target.value)
  }

  const onBlur = () => {
    // console.log('on blur')
    if (!_.isNil(labelValue)) {
      const params = { label: labelValue === '' ? null : labelValue }
      updateWorkOrderAsync(workOrderId, params)
    }
    setEditMode(false)
  }

  const onEnter = () => {
    // console.log('on enter')
    inputRef.current.blur()
  }

  const renderLabel = (item, color) => {
    if (editMode && item.id === workOrderId) {
      const value = _.isNil(labelValue) ? item.label : labelValue
      // console.log('value', value)
      return (
        <Keyboard onEnter={onEnter}>
          {/* <TextInput value={value} onChange={onChangeWoLabel} onBlur={onBlur} plain width='500px' /> */}
          <AutosizeInput
            color={color}
            value={value}
            onChange={onChangeWoLabel}
            onBlur={onBlur}
            ref={inputRef}
            extraWidth={0}
            inputStyle={{
              fontSize: 14,
              fontFamily: 'Lato',
              color: colors.LIGHT_NAVY_BRIGHT,
              border: 'none'
            }}
          />
        </Keyboard>
      )
    } else {
      const label = item.id === workOrderId && !_.isEmpty(labelValue) ? labelValue : item.label
      return (
        <Text color={color} truncate customStyle='white-space: nowrap;'>
          {label}
        </Text>
      )
    }
  }

  const renderWorkOrders = () => {
    return (
      <Droppable droppableId={'work_orders'} direction='horizontal'>
        {(provided, snapshot) => (
          <Box overflow={{ horizontal: 'auto' }} direction='row' ref={scrollRef} customStyle={scrollBarStyle}>
            <WorkOrdersContainer ref={provided.innerRef} {...provided.droppableProps}>
              <Box direction='row' gap='small' wrap={false} ref={itemsContainerRef}>
                {_.map(menuItems, (item, index) => {
                  const defaultColor = item.archived ? colors.BADGE_BG : colors.ANOTHER_GREY
                  const color = item.id === workOrderId ? colors.AQUA_MARINE : defaultColor
                  // const border = { side: 'bottom', color: colors.AQUA_MARINE, size: 'small', style: 'inset' }
                  return (
                    <Draggable draggableId={item.id} index={index} key={item.id}>
                      {(provided, snapshot) => (
                        <WorkOrderTab
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={item.id}
                          selected={item.id === workOrderId}
                          onClick={onWorkOrderClick(item.id)}
                        >
                          {renderLabel(item, color)}
                        </WorkOrderTab>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </Box>
            </WorkOrdersContainer>
          </Box>
        )}
      </Droppable>
    )
  }

  const onAddBidInviteClick = useCallback(() => {
    openBidInviteModal(projectId)
  }, [openBidInviteModal, projectId])

  const scrollLeft = () => {
    if (x > 0) {
      const toPosition = _.max([x - (wrapperParams.width / 3) * 2, 0])
      scrollRef.current.scrollTo({ left: toPosition, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    const toPosition = x + (wrapperParams.width / 3) * 2
    scrollRef.current.scrollTo({ left: toPosition, behavior: 'smooth' })
  }

  const onMenuOptionClick = woId => {
    universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, { projectId, workOrderId: woId })
    setLabelValue(null)
    setEditMode(false)
  }

  const renderIcons = () => {
    if (wrapperParams.width < itemsContainerParams.width) {
      return (
        <Box
          direction='row'
          align='center'
          pad={{ left: 'small', right: 'medium' }}
          flex={{ shrink: 0 }}
          border='left'
          gap='xsmall'
        >
          <Button icon={<FontAwesomeIcon icon={faPlus} onClick={onAddBidInviteClick} />} tip='Add a bid invitation' />
          <Button
            plain
            icon={<FontAwesomeIcon icon={faCaretLeft} size={20} />}
            disabled={x === 0}
            onClick={scrollLeft}
          />
          <Button
            plain
            icon={<FontAwesomeIcon icon={faCaretRight} size={20} />}
            disabled={itemsContainerParams.width - wrapperParams.width - x < 2}
            onClick={scrollRight}
          />
          <DropDownMenu
            options={menuItems}
            selectedId={workOrderId}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContentProps={{ boxProps: { width: { min: '220px' } } }}
            onOptionClick={onMenuOptionClick}
          >
            <FontAwesomeIcon icon={faBars} size={20} />
          </DropDownMenu>
        </Box>
      )
    } else {
      return (
        <Box direction='row' align='center' pad={{ left: 'small', right: 'medium' }} flex={{ shrink: 0 }}>
          <Button icon={<FontAwesomeIcon icon={faPlus} onClick={onAddBidInviteClick} />} tip='Add a bid invitation' />
        </Box>
      )
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box direction='row' pad={{ left: 'medium' }} align='center' height={{ min: '34px' }}>
        {renderDashboardButton()}
        <Box margin={{ left: 'medium' }} border='left' pad={{ left: 'medium' }} ref={wrapperRef}>
          {renderWorkOrders()}
        </Box>
        {renderIcons()}
      </Box>
    </DragDropContext>
  )
}

Submenu.propTypes = {
  workOrderId: PropTypes.string,
  menuItems: PropTypes.array
}

export default React.memo(Submenu)
