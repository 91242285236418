import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveGCAppointments } from 'model/actions/gcAppointmentsAC'
import { auth, db } from 'constants/firebase'
import { sendDocMessage } from 'shared/controllers/chat'
import { makeChannelKey } from 'shared/utils/chatUtils'
import messageType from 'shared/constants/messageType'

export const fetchGCAppointments = async accountId => {
  try {
    const unsubscribe = db
      .collection('gcAppointments')
      .where('accounts', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetchGCAppointments:', _.size(res))
          store.dispatch(receiveGCAppointments(res))
        },
        err => {
          console.log('fetchGCAppointments: onSnapshot error: ', err)
          Sentry.captureException(err)
        }
      )
    addListener('gcAppointments', unsubscribe)
  } catch (e) {
    console.log('fetchGCAppointments error', e)
    Sentry.captureException(e)
  }
}

export const createGCAppointment = (subId, gcAppt) => async (dispatch, getState) => {
  try {
    const dbRef = db.collection('gcAppointments').doc()
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const wo = _.get(state, ['workOrders', gcAppt.workOrderId])
    const projectId = _.get(wo, 'projectId')
    const userId = _.get(state, 'user.id')
    const appt = {
      id: dbRef.id,
      accountId,
      subId,
      accounts: [accountId, subId],
      createdBy: userId,
      createdAt: _.now(),
      seenBy: {
        [userId]: _.now()
      },
      ...gcAppt
    }
    console.log('saving GC appt:', appt)
    await dbRef.set(_.omitBy(appt, _.isNil))
    const channelKey = makeChannelKey(accountId, projectId, subId)
    const text = 'A new appointment is scheduled'
    const doc = {
      type: messageType.GC_APPOINTMENT,
      id: appt.id
    }
    console.log('add message to chat', channelKey, doc)
    sendDocMessage(channelKey, text, doc)
  } catch (e) {
    console.warn('createGCAppointment error: ', e.message)
    Sentry.captureException(e)
  }
}

export const updateGCAppointment = (prevGCAppt, gcAppt) => async (dispatch, getState) => {
  console.log('updateGCAppointment', prevGCAppt, 'gcAppt', gcAppt)
  const dateChanged = _.get(prevGCAppt, 'date') !== _.get(gcAppt, 'date')
  const newComments = _.get(gcAppt, 'comments')
  const commentsChanged = _.get(prevGCAppt, 'comments') !== newComments && !_.isEmpty(newComments)
  if (dateChanged || commentsChanged) {
    const state = getState()
    const accountId = _.get(state, 'account.id')
    const wo = _.get(state, ['workOrders', gcAppt.workOrderId])
    const projectId = _.get(wo, 'projectId')
    // const userId = _.get(state, 'user.id')

    const channelKey = makeChannelKey(accountId, projectId, prevGCAppt.subId)
    const text = 'An appointment was changed'
    const doc = {
      type: messageType.GC_APPOINTMENT,
      id: prevGCAppt.id
    }
    console.log('add message to chat', channelKey, doc)
    sendDocMessage(channelKey, text, doc)
  }
  db.collection('gcAppointments')
    .doc(prevGCAppt.id)
    .update(_.omitBy(gcAppt, _.isNil))
}

export const removeGCAppointment = apptId => async (dispatch, getState) => {
  db.collection('gcAppointments')
    .doc(apptId)
    .delete()
}

export function setGCAppoitmentSeen (gcApptId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = { [`seenBy.${userId}`]: _.now() }
    await db
      .collection('gcAppointments')
      .doc(gcApptId)
      .update(upd)
  }
}
