export const LOGOUT = 'LOGOUT'
export const CLEAR = 'CLEAR'
export const SWITCH_MODE = 'SWITCH_MODE'
export const RECEIVE_BIDS = 'RECEIVE_BIDS'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const RECEIVE_REFERENCES = 'RECEIVE_REFERENCES'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT'
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT'
export const UPDATE_KEYBOARD_HEIGHT = 'UPDATE_KEYBOARD_HEIGHT'
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
export const RECEIVE_WORK_ORDERS = 'RECEIVE_WORK_ORDERS'
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES'
export const RECEIVE_MESSAGES_WEB = 'RECEIVE_MESSAGES_WEB'
export const RECEIVE_MESSAGES_INBOX = 'RECEIVE_MESSAGES_INBOX'
export const RECEIVE_INBOX_FIRST_MESSAGE = 'RECEIVE_INBOX_FIRST_MESSAGE'
export const RECEIVE_INBOX_FIRST_NOTE = 'RECEIVE_INBOX_FIRST_NOTE'
export const RECEIVE_ANNOUNCEMENTS = 'RECEIVE_ANNOUNCEMENTS'
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const RECEIVE_PROFILE_CHANGE = 'RECEIVE_PROFILE_CHANGE'
export const RECEIVE_PROFILES = 'RECEIVE_PROFILES'
export const RECEIVE_ACCOUNTS_PROFILES = 'RECEIVE_ACCOUNTS_PROFILES'
export const RECEIVE_SUPPLY_ORDERS = 'RECEIVE_SUPPLY_ORDERS'
export const RECEIVE_SUPPLIERS = 'RECEIVE_SUPPLIERS'
export const ADD_NEW_ACCOUNT_CONTACT = 'ADD_NEW_ACCOUNT_CONTACT'
export const RECEIVE_PAYMENT_REQUESTS = 'RECEIVE_PAYMENT_REQUESTS'
export const RECEIVE_REIMBURSEMENTS_REQUESTS = 'RECEIVE_REIMBURSEMENTS_REQUESTS'
export const RECEIVE_PROPOSALS = 'RECEIVE_PROPOSALS'
export const CHANGE_TEMP_SUPPLY_ORDER = 'CHANGE_TEMP_SUPPLY_ORDER'
export const DROP_TEMP_SUPPLY_ORDER = 'DROP_TEMP_SUPPLY_ORDER'
export const RECEIVE_CHANNELS = 'RECEIVE_CHANNELS'
export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL'
export const RECEIVE_APPOINTMENTS = 'RECEIVE_APPOINTMENTS'
export const RECEIVE_APPOINTMENTS_OFFERS = 'RECEIVE_APPOINTMENTS_OFFERS'
export const RECEIVE_JOB_TYPES = 'RECEIVE_JOB_TYPES'
export const RECEIVE_CONTRACTOR_TYPES = 'RECEIVE_CONTRACTOR_TYPES'
export const RECEIVE_PROJECT_TYPES = 'RECEIVE_PROJECT_TYPES'
export const RECEIVE_PROJECT_TYPE_GROUPS = 'RECEIVE_PROJECT_TYPE_GROUPS'
export const RECEIVE_TERRITORIES = 'RECEIVE_TERRITORIES'
export const RECEIVE_DIVISIONS = 'RECEIVE_DIVISIONS'
export const RECEIVE_DIVISIONS_SUBGROUPS = 'RECEIVE_DIVISIONS_SUBGROUPS'
export const RECEIVE_OUTGOING_INVITATIONS = 'RECEIVE_OUTGOING_INVITATIONS'
export const RECEIVE_INCOMING_INVITATIONS = 'RECEIVE_INCOMING_INVITATIONS'
export const RECEIVE_GC_APPOINTMENTS = 'RECEIVE_GC_APPOINTMENTS'
export const RECEIVE_PRIVATE_WORK_ORDERS = 'RECEIVE_PRIVATE_WORK_ORDERS'
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA'
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS'
export const UPDATE_SHOW_ARCHIVED = 'UPDATE_SHOW_ARCHIVED'
export const CHANGE_SETTINGS = 'CHANGE_SETTINGS'
export const RESET_SETTINGS = 'RESET_SETTINGS'
export const RECEIVE_PRICING_PLANS_GROUPS = 'RECEIVE_PRICING_PLANS_GROUPS'
export const RECEIVE_PRICING_PLANS = 'RECEIVE_PRICING_PLANS'
export const RECEIVE_TAKEOFFS_QUOTES = 'RECEIVE_TAKEOFFS_QUOTES'
export const RECEIVE_MASON_ADMINS = 'RECEIVE_MASON_ADMINS'
export const RECEIVE_IMPORTED_LI = 'RECEIVE_IMPORTED_LI'
