import React from 'react'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import DocTableHeader from 'shared/documents/components/DocTableHeader'
import { renderHeader, renderItems, renderGeneralExclusions } from 'shared/documents/components/estimate/Table'
import Total from 'shared/documents/components/estimate/Total'
import { renderQuestons } from 'shared/documents/components/estimate/Questions'

const renderTradeBid = params => {
  const { getSize, bid, wo, contractorTypes, index, bidsAmount, showBidsTotals, subName, showSectionSubtotals } = params
  const tradeName = _.get(contractorTypes, [wo.tradeId, 'name'])
  const trade = _.get(wo, 'label', tradeName)
  let label = _.upperCase(trade)
  if (!_.isNil(subName)) label = `${label} - ${_.upperCase(subName)}`
  if (index) label = `${label} (${index} of ${bidsAmount})`
  return [
    <DocTableHeader
      key={bid.id}
      getSize={getSize}
      title={label}
      color={colors.LIGHT_NAVY_BRIGHT}
      customStyle={`
        justify-content: flex-start;
        padding-top: ${getSize(80)}px
      `}
    />,
    renderHeader(getSize, `table_header_${bid.id}`),
    ...renderItems(getSize, bid.items, bid.scope, bid.sections, bid.id, showBidsTotals, showSectionSubtotals),
    showBidsTotals
      ? (<Total key={`${bid.id}_total_row`} title={`${trade} Total`} getSize={getSize} total={bid.total} />)
      : (<Total key={`${bid.id}_total_row`} getSize={getSize} />),
    renderGeneralExclusions(getSize, bid.items, bid.scope, bid.sections, bid.id),
    renderQuestons(getSize, bid.items, bid.scope, bid.sections, bid.id)
  ]
}

export default renderTradeBid
