import { A4K } from 'shared/constants/documents'
import { isWeb } from 'shared/constants/index'

const STANDARD_WIDTH = 375
const STANDARD_HEIGHT = 812

const STANDARD_WEB_WIDTH = 1366
const STANDARD_WEB_HEIGHT = 1024

// iPhone Xs Max Width
const MAX_MOBILE_WIDTH = 414

export const getHeight = (h, viewport) => {
  if (viewport.width <= MAX_MOBILE_WIDTH) {
    return Math.round(viewport.height / STANDARD_HEIGHT * h)
  } else if (viewport.width <= STANDARD_WEB_WIDTH) {
    return Math.round(viewport.height / STANDARD_WEB_HEIGHT * h)
  } else {
    return h
  }
  // return viewport.width <= MAX_MOBILE_WIDTH ? Math.round(viewport.height / STANDARD_HEIGHT * h) : h
}

export const getWidth = (w, viewport) => {
  if (viewport.width <= MAX_MOBILE_WIDTH) {
    return Math.round(viewport.width / STANDARD_WIDTH * w)
  } else if (viewport.width <= STANDARD_WEB_WIDTH) {
    return Math.round(viewport.width / STANDARD_WEB_WIDTH * w)
  } else {
    return w
  }
  // return viewport.width <= MAX_MOBILE_WIDTH || !isWeb ? Math.round(viewport.width / STANDARD_WIDTH * w) : w
}

// export const fontSize = (fs, viewport) => {
//   const res = viewport.width < STANDARD_WIDTH ? getHeight(fs, viewport) : fs
//   return res
// }

export const fontSize = (fs, viewport) => {
  const curK = viewport.width / viewport.height
  const origK = STANDARD_WIDTH / STANDARD_HEIGHT
  const k = (curK / origK - 1) / 2 + 1
  // const res = viewport.width < MAX_MOBILE_WIDTH ? viewport.height / STANDARD_HEIGHT * fs * k : fs
  if (viewport.width <= MAX_MOBILE_WIDTH) {
    return viewport.height / STANDARD_HEIGHT * fs * k
  } else if (viewport.width <= STANDARD_WEB_WIDTH) {
    return Math.round(viewport.height / STANDARD_WEB_HEIGHT * fs)
  } else {
    return fs
  }
  // return res
}

export const getPageSize = (viewport) => {
  if (viewport.width < viewport.height) {
    return {
      width: viewport.width,
      height: viewport.width * A4K
    }
  }
  return {
    width: viewport.height / A4K,
    height: viewport.height
  }
}
