export const ITEM = 'item'
export const ANNONCEMENT = 'announcement'
export const SUB = 'sub'
export const ANNOTATION = 'Annotation'
export const NOTE = 'note'

export default {
  ITEM,
  ANNONCEMENT,
  SUB,
  ANNOTATION,
  NOTE
}
