import React, { Component } from 'react'
import { Layer, Button, Box, Text, Card, CardHeader, CardBody, CardFooter, DropButton } from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPaperclip, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import generate from 'firebase-auto-ids'

import colors from 'shared/constants/colors'
import { getName } from 'shared/utils/stringUtils'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import SubBiddingInterface from 'webPages/createBid/SubBiddingInterface'
import { getCurrentAccountProfile } from 'model/selectors/profiles'
import { getTotals, areRequiredFieldsDone, getUserScope } from 'shared/utils/bid'
import { getSubProfile } from 'controllers/workOrder'
import { getTradesDict } from 'model/selectors/references'
import { sendBidByGC } from 'controllers/bids'
import LayoutContext from 'webPages/layout/LayoutContext'
import FilesPickerButton from 'components/web/FilesPickerButton'
import { deleteFile } from 'shared/controllers/storage'
import FileRow from 'components/panels/filesPanel/FileRow'
import filesTypes from 'shared/constants/filesTypes'
import CreateBidBottomMenu from 'webPages/createBid/CreateBidBottomMenu'

class CreateBidManually extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addedFiles: [],
      isFilesOpen: false
    }
  }

  open = async ({ subId, workOrderId }) => {
    const { bidsByWorkOrdersAccounts } = this.props
    if (_.isEmpty(subId) || _.isEmpty(workOrderId)) {
      console.error('subId, workOrderId are required, current values are', subId, workOrderId)
    }
    const existingBid = _.get(bidsByWorkOrdersAccounts, [workOrderId, subId])
    this.setState({
      visible: true,
      workOrderId,
      subId,
      bid: existingBid,
      isNew: _.isEmpty(existingBid),
      hasChanges: false,
      bidId: _.get(existingBid, 'id', generate(_.now())),
      submittedFiles: _.get(existingBid, 'files', {})
    })
    const subProfileRes = await getSubProfile(subId)
    this.onSubProfileReceived(subProfileRes)
  }

  onSubProfileReceived = subProfile => {
    console.log('onSubProfileReceived', subProfile)
    const profileId = _.get(subProfile, 'sub.profile.id')
    const { subId } = this.state
    if (!_.isNil(profileId) && subId === profileId) {
      this.sub = !_.isNil(profileId) && subId === profileId ? subProfile.sub : null
    }
  }

  renderConfirmationButtons = () => {
    return (
      <Box direction='row' align='center' justify='between' flex gap='medium' pad={{ top: 'small' }}>
        <Button
          customStyle='flex: 1'
          primary
          color={colors.CORAL_TWO}
          label='Discard changes'
          onClick={this.onDiscardChangesClick}
        />
        <Button customStyle='flex: 1' primary label='Go back' onClick={this.context.closeConfirmationModal} />
      </Box>
    )
  }

  onDiscardChangesClick = () => {
    const { addedFiles } = this.state
    this.context.closeConfirmationModal()
    this.setState({ visible: false, addedFiles: [], isFilesOpen: false })
    Promise.all(_.map(addedFiles, this.deleteFile))
  }

  deleteFile = ({ id }) => {
    try {
      const { workOrderId, bidId } = this.state
      deleteFile(`/workOrders/${workOrderId}/bid/${bidId}/${id}`)
    } catch (error) {
      console.log('deleteFile error', error.message)
    }
  }

  close = () => {
    const { hasChanges } = this.state
    if (hasChanges) {
      this.context.openConfirmationModal({
        title: 'You have unsaved changes',
        renderButtons: this.renderConfirmationButtons
      })
    } else {
      this.setState({ visible: false, addedFiles: [], isFilesOpen: false })
    }
  }

  onBidFilesPicked = newFiles => {
    let { addedFiles } = this.state
    addedFiles = _.concat(addedFiles, newFiles)

    this.setState({ addedFiles, hasChanges: true })
  }

  handleFilesDropdownClick = () => this.setState({ isFilesOpen: !this.state.isFilesOpen })

  handleFilePreview = id => {
    const { workOrderId } = this.state
    const url = `${window.location.origin.toString()}/workorderfile/${workOrderId}/${id}`
    window.open(url, '_blank')
  }

  handleFileRemove = id => {
    const { submittedFiles } = this.state
    if (_.has(submittedFiles, id)) {
      const newFiles = { ...submittedFiles }
      _.unset(newFiles, id)
      this.setState({ submittedFiles: newFiles, hasChanges: true })
    } else {
      let { addedFiles } = this.state
      const index = _.findIndex(addedFiles, f => _.isEqual(f.id, id))
      _.pullAt(addedFiles, [index])
      this.setState({ addedFiles, hasChanges: true })
    }
  }

  renderFile = ({ id, ...rest }, disabled) => (
    <Box key={id} width='medium' customStyle='cursor: pointer'>
      <FileRow
        type={filesTypes.BID}
        file={rest}
        onDelete={() => this.handleFileRemove(id)}
        onPreview={() => this.handleFilePreview(id)}
        disabled={disabled}
      />
    </Box>
  )

  renderHeader = () => {
    const { subId, isNew, workOrderId, bidId, addedFiles, submittedFiles, isFilesOpen } = this.state
    const { accountsProfiles, workOrders } = this.props
    const workOrder = _.get(workOrders, workOrderId)
    const accountProfile = _.get(accountsProfiles, subId, {
      name: _.get(workOrder, ['invitations', subId, 'companyName'])
    })

    const bidFiles = _.orderBy(_.concat(addedFiles, _.values(submittedFiles)), 'createdAt', 'desc')
    const bidFilesSize = _.size(bidFiles)

    const dropdownLabel = (
      <Box direction='row' pad='xsmall' gap='xsmall' align='center'>
        <Text color={colors.DARK_GRAY_TWO} size='medium'>{`${bidFilesSize} ${bidFilesSize > 1 ? 'files' : 'file'} attached`}</Text>
        <FontAwesomeIcon icon={faChevronDown} color={colors.DARK_GRAY_TWO} size={12} />
      </Box>
    )

    const filePickerLabel = ({ onClick }) => (
      <Box pad='xsmall' direction='row' gap='xsmall' justify='between' align='center' onClick={onClick}>
        <FontAwesomeIcon icon={faPaperclip} color={colors.LIGHT_NAVY_BRIGHT} size={14} />
        <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT}>Attach file</Text>
      </Box>
    )

    return (
      <CardHeader
        pad={{ horizontal: 'medium', vertical: 'small' }}
        border={{
          color: 'border',
          size: '1px',
          side: 'bottom'
        }}
      >
        <Box>
          <Text size='xlarge' color={colors.BLACK} weight={600}>
            {`${isNew ? 'Create' : 'Edit'} bid for ${getName(accountProfile)}`}
          </Text>
          <Text>{`${_.get(workOrder, 'projectAddress.name', '')} - ${_.get(workOrder, 'title', '')}`}</Text>
        </Box>
        <Box direction='row' align='center' gap='small'>
          {bidFilesSize > 0 && (
            <DropButton
              plain
              label={dropdownLabel}
              open={isFilesOpen}
              onOpen={this.handleFilesDropdownClick}
              onClose={this.handleFilesDropdownClick}
              dropContent={<Box>{_.map(bidFiles, f => this.renderFile(f, !_.has(submittedFiles, f.id)))}</Box>}
              dropAlign={{ top: 'bottom', right: 'right' }}
            />
          )}
          <FilesPickerButton
            ButtonComponent={filePickerLabel}
            onComplete={this.onBidFilesPicked}
            storagePath={`/workOrders/${workOrderId}/bid/${bidId}`}
          />
        </Box>
      </CardHeader>
    )
  }

  getField = (fieldName, defaultValue = {}) => {
    const { bid, workOrderId } = this.state
    const { workOrders } = this.props
    const workOrder = _.get(workOrders, workOrderId)
    const res = _.get(bid, fieldName, defaultValue)
    if (fieldName === 'sections' && _.isEmpty(res)) {
      return _.get(workOrder, 'sections')
    } else {
      return res
    }
  }

  setField = fieldName => v => {
    const fieldsToCheck = ['items', 'discount', 'unpricedAmount', 'deposit']
    if (_.includes(fieldsToCheck, fieldName)) {
      // console.log('setField fieldName', fieldName, 'value', v)
      const prevValues = {
        total: this.getField('total', 0),
        discount: this.getField('discount'),
        unpricedAmount: this.getField('unpricedAmount', 0),
        items: this.getField('items'),
        deposit: this.getField('deposit')
      }
      const newValues = { ...prevValues, [fieldName]: v }
      const totals = getTotals(newValues)
      this.setParams(totals)
    } else {
      this.setParams({
        [fieldName]: v
      })
    }
  }

  setFields = params => {
    const fieldNames = _.keys(params)
    const fieldsToCheck = ['items', 'discount', 'unpricedAmount', 'deposit']
    const reacalcNeeded = _.size(_.intersection([fieldNames, fieldsToCheck])) > 0
    if (reacalcNeeded) {
      // console.log('setField fieldName', fieldName, 'value', v)
      const prevValues = {
        total: this.getField('total', 0),
        discount: this.getField('discount'),
        unpricedAmount: this.getField('unpricedAmount', 0),
        items: this.getField('items'),
        deposit: this.getField('deposit')
      }
      const newValues = { ...prevValues, ...params }
      const totals = getTotals(newValues)
      this.setParams(totals)
    } else {
      this.setParams(params)
    }
  }

  setParams = params => {
    const { bid } = this.state
    this.setState({
      bid: {
        ...bid,
        ...params
      },
      hasChanges: true
    })
  }

  getNewBid = () => {
    const { bid, subId, workOrderId, bidId, submittedFiles, addedFiles } = this.state
    const { accountsProfiles, accountProfile, user, contractorTypes, workOrders } = this.props
    const workOrder = _.get(workOrders, workOrderId)
    const subAccountProfile = _.get(accountsProfiles, subId, {
      name: _.get(workOrder, ['invitations', subId, 'companyName'])
    })
    const tradeId = _.get(workOrder, 'tradeId')
    const tradeName = _.get(contractorTypes, [tradeId, 'name'])
    const owner = _.pick(subAccountProfile, ['id', 'name', 'phone', 'email', 'address', 'apartment'])
    const scope = _.get(workOrder, 'scope')
    const userScope = getUserScope(scope, subId)
    const scopeItems = _.reduce(
      userScope,
      (res, item, itemId) => {
        _.set(res, itemId, {})
        return res
      },
      {}
    )
    const newBid = {
      id: bidId,
      ...(bid || {}),
      items: { ...scopeItems, ..._.get(bid, 'items', {}) },
      createdBy: user.id,
      workOrderId: workOrderId,
      scope: _.get(workOrder, 'scope'),
      owner: _.omitBy(owner, _.isNil),
      client: _.omitBy(_.pick(accountProfile, ['id', 'name', 'phone', 'email', 'address', 'apartment']), _.isNil),
      accountId: subId,
      projectId: workOrder.projectId,
      seenBy: {},
      status: null,
      workOrderAccountId: workOrder.accountId,
      accounts: [subId, workOrder.accountId],
      projectAddress: _.get(workOrder, 'projectAddress'),
      tradeId,
      typeOfWork: _.get(workOrder, 'label', tradeName),
      deleted: 0,
      files: _.merge(submittedFiles, _.keyBy(addedFiles, 'id'))
    }
    console.log('newBid', newBid)
    return _.omitBy(newBid, _.isNil)
  }

  canSendBid = () => {
    const { workOrderId, hasChanges, subId } = this.state
    const { workOrders } = this.props
    const workOrder = _.get(workOrders, workOrderId)
    const total = this.getField('total')
    const items = this.getField('items')
    const scope = _.get(workOrder, 'scope')
    const userScope = getUserScope(scope, subId)
    const requiredFieldDone = areRequiredFieldsDone(userScope, items)
    console.log('total', total, 'requiredFieldDone', requiredFieldDone)
    return total > 0 && requiredFieldDone && hasChanges
  }

  clearItemForScope = item => {
    const newItem = { ...item }
    _.unset(newItem, 'total')
    _.unset(newItem, 'cost')
    _.unset(newItem, 'desc')
    _.unset(newItem, 'optional')
    _.unset(newItem, 'excluded')
    return newItem
  }

  submit = () => {
    // const {
    // subId,
    // workOrderId
    // } = this.state
    const {
      // workOrders,
      dispatch
    } = this.props
    // const workOrder = _.get(workOrders, workOrderId)
    const bid = this.getNewBid()
    // const geSectionItems = _.get(
    //   _.find(_.get(bid, 'sections'), s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID),
    //   'items'
    // )
    // const scope = _.get(workOrder, 'scope', {})
    // const userScope = getUserScope(scope, subId)
    // const items = _.get(bid, 'items', {})
    // const scopeUpdate = {}
    // _.forEach(items, (item, itemId) => {
    //   if (!_.has(userScope, itemId) && !_.includes(geSectionItems, itemId)) {
    //     console.log('item', item, 'is new & not in general exclusions section, needs to be added to scope')
    //     let newItem = this.clearItemForScope(item)
    //     const alternates = _.get(item, 'alternates', {})
    //     const newAlternates = _.reduce(
    //       alternates,
    //       (res, item, itemId) => {
    //         _.set(res, itemId, this.clearItemForScope(item))
    //         return res
    //       },
    //       {}
    //     )
    //     if (!_.isEmpty(newAlternates)) newItem = { ...newItem, alternates: newAlternates }
    //     _.set(scopeUpdate, itemId, newItem)
    //   }
    // })
    // if (!_.isEmpty(scopeUpdate)) {
    //   const newScope = { ...scope, ...scopeUpdate }
    //   let workOrderSections = _.get(workOrder, 'sections')
    //   const bidSections = _.get(bid, 'sections')
    //   _.forEach(scopeUpdate, item => {
    //     const itemSection = _.find(bidSections, s => _.includes(s.items, item.id))
    //     const itemSectionId = _.get(itemSection, 'id')
    //     const workOrderSectionIndex = _.findIndex(workOrderSections, s => _.isEqual(s.id, itemSectionId))
    //     if (workOrderSectionIndex >= 0) {
    //       workOrderSections[workOrderSectionIndex].items.push(item.id)
    //     } else {
    //       workOrderSections.push({
    //         ...itemSection,
    //         items: [item.id]
    //       })
    //     }
    //   })
    //   console.log(scopeUpdate)
    //   // dispatch(saveScopeOfWork(workOrderId, newScope, workOrderSections))
    // }
    dispatch(sendBidByGC(bid))
    this.setState({ visible: false, addedFiles: [], isFilesOpen: false })
  }

  renderBody = () => {
    const { subId, workOrderId } = this.state
    const { workOrders, accountProfile } = this.props
    const workOrder = _.get(workOrders, workOrderId)
    const scope = _.get(workOrder, 'scope', {})
    const userScope = getUserScope(scope, subId)
    return (
      <CardBody>
        <SubBiddingInterface
          scope={userScope}
          // errorComments={{}}
          setError={() => {}}
          getField={this.getField}
          setField={this.setField}
          setParams={this.setParams}
          gcProfile={accountProfile}
          setFields={this.setFields}
          itemsDB={this.getField('items')}
          sectionsDB={this.getField('sections')}
        />
        <CreateBidBottomMenu mode={'scope'} nextComponent={this.renderNextButton} getFieldParent={this.getField} setFieldParent={this.setField} />
      </CardBody>
    )
  }

  renderNextButton = () => {
    const { isNew } = this.state
    const canSend = this.canSendBid()
    return (
      <CardFooter align='center' justify='end'>
        <Box direction='row' align='center'>
          <Button secondary label='Close' onClick={this.close} />
          <Button
            primary
            color={colors.AQUA_MARINE}
            margin='medium'
            label={isNew ? 'Add' : 'Update'}
            onClick={this.submit}
            disabled={!canSend}
          />
        </Box>
      </CardFooter>
    )
  }

  render () {
    const { visible } = this.state
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close} full margin={{ vertical: 'medium', horizontal: 'large' }}>
          <Card fill>
            {this.renderHeader()}
            {this.renderBody()}
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

CreateBidManually.contextType = LayoutContext

const mapStateToProps = (state, props) => ({
  profiles: state.profiles,
  user: state.user,
  bids: state.bids,
  accountsProfiles: state.accountsProfiles,
  accountProfile: getCurrentAccountProfile(state),
  workOrders: getWorkOrdersWithTitlesDict(state),
  bidsByWorkOrdersAccounts: getBidsByWorkOrdersAccounts(state),
  contractorTypes: getTradesDict(state),
  projectTypeGroups: _.get(state, 'references.projectTypeGroups')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(CreateBidManually)
