import { createRef } from 'react'
import _ from 'lodash'
import { CommonActions, StackActions } from '@react-navigation/native'

export const navigatorRef = createRef()

const getNavigator = () => {
  const navigator = _.get(navigatorRef, 'current')
  if (!_.isNil(navigator)) {
    return navigator
  } else {
    console.warn('getNavigator called when navigatorRef os not initialized yet')
    return {
      dispatch: p => console.log('_navigator is not initialized yet', p)
    }
  }
}

export let currentRoute

function setCurrentRoute (route) {
  console.log('Navigation service: setCurrentRoute', route)
  currentRoute = route
}

function navigate (routeName, params) {
  getNavigator().dispatch(
    CommonActions.navigate({
      name: routeName,
      params
    })
  )
}

function goBack () {
  getNavigator().dispatch(CommonActions.goBack())
}

function push (routeName, params) {
  const curRouteName = _.get(currentRoute, 'name')
  const curRouteParams = _.get(currentRoute, 'params')
  if (curRouteName !== routeName || !_.isEqual(curRouteParams, params)) {
    console.log('Stack actions push', routeName, params)
    const pushAction = StackActions.push(routeName, params)
    getNavigator().dispatch(pushAction)
  } else {
    console.log(
      'cant push the same rout name as current one, currentRoute',
      currentRoute,
      'routeName',
      routeName,
      'params',
      params
    )
  }
}

function pop (n = 1) {
  const popAction = StackActions.pop(n)
  getNavigator().dispatch(popAction)
}

function popToTop () {
  console.log('N service, popToTop', _.navigator)
  const popToTopAction = StackActions.popToTop()
  getNavigator().dispatch(popToTopAction)
}

function replace (routeName, params) {
  const replaceAction = StackActions.replace({ routeName, params })
  getNavigator().dispatch(replaceAction)
}

function getCurrentRoute () {
  return currentRoute
}

export default {
  navigate,
  goBack,
  push,
  pop,
  replace,
  getCurrentRoute,
  popToTop,
  setCurrentRoute,
  currentRoute
}
