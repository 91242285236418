import React, { useContext, useState } from 'react'
import { Box, Button, Avatar, Text, DropButton } from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faComment, faPalette, faArrowLeft } from '@fortawesome/pro-light-svg-icons'

import { getCurrentUserProfile } from 'model/selectors/profiles'
import colors from 'shared/constants/colors'
import universalNavigation from 'utils/universalNavigation'
import CreateBidNavBarLeftPart from 'webPages/createBid/CreateBidNavBarLeftPart'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import NavBarContainer from 'components/web/NavBarContainer'
import { navigateToHomePage } from 'controllers/init'
import { getUnseenAmount } from 'model/selectors/inbox'
import CommentsInboxButton from 'components/chat/CommentsInboxButton'
import { getInitials } from 'shared/utils/stringUtils'
import DropContent from 'components/DropContent'
import screens from 'constants/screens'
import { signOut } from 'controllers/auth'

const menuActionId = {
  SETTINGS: 'SETTINGS',
  INVITE_TEAMMATES: 'INVITE_TEAMMATES',
  HELP: 'HELP',
  SIGN_OUT: 'SIGN_OUT'
}

const menuActions = [
  { id: menuActionId.SETTINGS, label: 'Settings' },
  { id: menuActionId.INVITE_TEAMMATES, label: 'Invite Teammates' },
  { id: menuActionId.HELP, label: 'Help & Support' },
  { id: menuActionId.SIGN_OUT, label: 'Sign out' }
]

const CreateBidNavBar = ({
  onNextClick,
  nextDisabled,
  previewMode,
  dispatch,
  userProfile,
  mode,
  activeTab = 'scope',
  files,
  mobile = false,
  changeTab
}) => {
  const [selectOpened, setSelectOpened] = useState()
  const goBack = () => {
    if (previewMode) {
      universalNavigation.goBack()
    } else {
      dispatch(navigateToHomePage())
    }
  }

  const onMenuAction = actionId => {
    console.log('onMenuAction', actionId)
    setSelectOpened(false)
    switch (actionId) {
      case menuActionId.SETTINGS:
        universalNavigation.navigate(screens.SETTINGS)
        break
      case menuActionId.INVITE_TEAMMATES:
        universalNavigation.navigate(screens.SETTINGS, { activeIndex: 2 })
        break
      case menuActionId.SIGN_OUT:
        signOut()
        break
      case menuActionId.HELP:
        window.open('http://help.bidlevel.co/en/')
        break
    }
  }

  const { openCommentsInbox } = useContext(CreateBidContext)

  const avatar = _.get(userProfile, 'avatarSmall')

  const dropButtonLabel = (
    <Box direction='row' align='center'>
      <Avatar size='29px' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
        <Text color={colors.WHITE}>{getInitials(userProfile)}</Text>
      </Avatar>
    </Box>
  )

  const dropContent = (
    <DropContent
      onOptionClick={onMenuAction}
      options={menuActions}
      boxProps={{ width: '160px' }}
      optionLabelProps={{ textAlign: 'center' }}
    />
  )

  const renderRightPart = () => {
    if (previewMode) {
      return (
        <Box direction='row' align='center' pad='none'>
          <Button
            onClick={() => console.log('open Customize Proposal page')}
            icon={<FontAwesomeIcon icon={faPalette} size={20} />}
            color={colors.LIGHT_NAVY}
          />
          <Button
            onClick={openCommentsInbox}
            icon={<FontAwesomeIcon icon={faComment} size={20} />}
            color={colors.LIGHT_NAVY}
          />
          <Box border='left' pad={{ left: 'small' }} direction='row' align='center' gap='small'>
            <Button
              label='Edit'
              secondary
              color={colors.LIGHT_NAVY}
              hoverTextColor={colors.WHITE}
              icon={<FontAwesomeIcon icon={faArrowLeft} size={16} />}
              onClick={goBack}
            />
            <Button
              label='Submit bid'
              primary
              color={colors.AQUA_MARINE}
              onClick={onNextClick}
              disabled={nextDisabled}
            />
          </Box>
        </Box>
      )
    } else {
      return (
        <Box direction='row' gap='small'>
          <CommentsInboxButton />
          <DropButton
            plain
            label={dropButtonLabel}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={dropContent}
            open={selectOpened}
            onOpen={() => setSelectOpened(true)}
            onClose={() => setSelectOpened(false)}
            dropProps={{ margin: { top: 'small' } }}
          />
        </Box>
      )
    }
  }

  const renderNavBar = () => {
    return (
      <NavBarContainer mobile={mobile}>
        <Box direction='row' align='center' pad='none'>
          <CreateBidNavBarLeftPart mobile={mobile} activeTab={activeTab} mode={mode} files={files} goBack={goBack} changeTab={changeTab} />
        </Box>
        {!mobile && renderRightPart()}
      </NavBarContainer>
    )
  }

  return renderNavBar()
}

CreateBidNavBar.propTypes = {
  onNextClick: PropTypes.func,
  nextDisabled: PropTypes.bool
}

const mapStateToProps = (state, props) => ({
  accountsProfiles: state.accountsProfiles,
  workOrders: state.workOrders,
  unseenAmount: getUnseenAmount(state),
  userProfile: getCurrentUserProfile(state)
})

export default connect(mapStateToProps)(CreateBidNavBar)
