import React, { useState, useContext, useEffect, memo } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { Avatar, Box, Text, DropButton, ResponsiveContext, Select, CheckBox, ThemeContext } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSort, faSlidersV } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'

import universalNavigation from 'utils/universalNavigation'
import colors from 'shared/constants/colors'
import jobOfferStatus from 'shared/constants/jobOfferStatus'
import screens from 'constants/screens'
import { getPendingJobOffers } from 'model/selectors/jobOffers'
import { getAddress, initialsByName } from 'shared/utils/stringUtils'
import CalendarSidepanel from 'components/CalendarSidepanel'
import LayoutContext from 'webPages/layout/LayoutContext'
import subMenuItemId from 'constants/subMenuItemId'
import BidInvitesListNavBar from 'webPages/bidInvitesList/BidInvitesListNavBar'
import DropContent from 'components/DropContent'
import config from 'shared/config'

const menuActionId = {
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
  ACCOUNT_NAME: 'ACCOUNT_NAME',
  DUE_DATE: 'DUE_DATE'
}
const menuActions = [
  { id: menuActionId.CREATED_AT, label: 'Creation date' },
  { id: menuActionId.UPDATED_AT, label: 'Last update' },
  { id: menuActionId.ACCOUNT_NAME, label: 'Alphabetical (Company Name)' },
  { id: menuActionId.DUE_DATE, label: 'Bid Due Date' }
]

const onBidInviteClick = (projectId, workOrderId) => {
  universalNavigation.push(screens.SUB_CREATE_BID, { projectId, workOrderId })
}

const Option = memo(({ value, selected }) => (
  <Box direction='row' gap='small' align='center' pad='xsmall'>
    <CheckBox tabIndex='-1' checked={selected} onChange={() => {}} />
    {value}
  </Box>
))

const filterOptions = [
  { value: jobOfferStatus.NEW, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.NEW) },
  { value: jobOfferStatus.PENDING, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.PENDING) },
  { value: jobOfferStatus.ACCEPTED, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.ACCEPTED) },
  { value: jobOfferStatus.DECLINED, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.DECLINED) },
  { value: jobOfferStatus.LOST, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.LOST) },
  {
    value: jobOfferStatus.DECLINED_BY_SUB,
    label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.DECLINED_BY_SUB)
  },
  {
    value: jobOfferStatus.ACCEPTED_BY_SUB,
    label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.ACCEPTED_BY_SUB)
  },
  { value: jobOfferStatus.ARCHIVED, label: jobOfferStatus.stringOfJobOfferStatus(jobOfferStatus.ARCHIVED) }
]

const renderBidInvites = (bidInvites, isMobile) => (
  <Box
    width={isMobile ? '100%' : { min: '45vw', max: '80%' }}
    overflow='auto'
    // margin={{ bottom: 'large' }}
    responsive={false}
    customStyle='display: block;'
    pad={{ horizontal: 'xsmall' }}
    margin={{ bottom: 'medium' }}
  >
    {_.map(bidInvites, wo => {
      let onClick
      if (wo.invId) {
        onClick = () => {
          window.open(`${_.get(config, 'invitationUrl')}/i/${wo.invId}`, '_blank')
        }
      } else if (wo.status !== jobOfferStatus.DECLINED_BY_SUB && wo.status !== jobOfferStatus.LOST) {
        onClick = () => onBidInviteClick(wo.projectId, wo.id)
      }

      return (
        <Box
          key={wo.key}
          fill='horizontal'
          height={{ min: '54px', max: '100px' }}
          hoverIndicator={colors.TABLE_HOVER}
          onClick={onClick}
          border='bottom'
        >
          <Box direction='row' width='100%' align='center' pad='xsmall'>
            <Box pad={{ right: 'medium' }} flex={{ shrink: 0 }}>
              <Avatar src={wo.avatar} size='30px' background={colors.LIGHT_NAVY_BRIGHT}>
                <Text color={colors.WHITE}>{initialsByName(wo.companyName)}</Text>
              </Avatar>
            </Box>
            <Box fill justify='evenly'>
              <Box direction='row' justify='between'>
                <Box justify='center'>
                  <Text size='medium' color={colors.TEXT}>
                    {getAddress(_.get(wo, 'wo.projectAddress'))}
                  </Text>
                </Box>
                <Box justify='center'>
                  <Text size='xsmall' color={colors.ANOTHER_GREY}>
                    {`Bids due ${moment.tz(wo.bidsDueDate, wo.timeZone).format('MMMM Do')}`}
                  </Text>
                </Box>
              </Box>
              <Box direction='row' justify='between'>
                <Box justify='center'>
                  <Text size='medium' color={colors.ANOTHER_GREY}>
                    {`Bids due ${moment.tz(wo.bidsDueDate, wo.timeZone).format('MMMM Do')} - ${wo.companyName}`}
                  </Text>
                </Box>
                <Box justify='center'>
                  <Text size='xsmall' color={jobOfferStatus.jobOfferStatusColor(wo.status)}>
                    {jobOfferStatus.stringOfJobOfferStatus(wo.status, wo.companyName)}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    })}
  </Box>
)

const renderContentNoBidInvites = () => (
  <Box margin={{ top: 'medium' }}>
    <Text color={colors.ANOTHER_GREY}>You have not been invited</Text>
  </Box>
)

const renderContent = (bidInvites, isMobile) =>
  _.size(bidInvites) > 0 ? renderBidInvites(bidInvites, isMobile) : renderContentNoBidInvites()

const BidInvitesList = ({ bidInvitesRaw }) => {
  const [selectedFilter, setSelectedFilter] = useState([])
  const [bidInvites, setBidInvites] = useState([])
  const [sortOpened, setSortOpened] = useState()
  const [sortSelected, setSortSelected] = useState(menuActionId.CREATED_AT)
  const size = useContext(ResponsiveContext)
  const isMobile = size === 'small'

  const [calendarOpen, setCalendarOpen] = useState(false)
  const { setActiveMenuItem, toggleSideMenu } = useContext(LayoutContext)

  useEffect(() => {
    setTimeout(() => toggleSideMenu(true), 1)
    setActiveMenuItem(subMenuItemId.BIDS)
  }, [setActiveMenuItem, toggleSideMenu])

  useEffect(() => {
    if (_.size(selectedFilter) > 0) {
      const filterValues = _.map(selectedFilter, index => filterOptions[index].value)
      setBidInvites(_.filter(bidInvitesRaw, wo => _.includes(filterValues, wo.status)))
    } else {
      setBidInvites(_.filter(bidInvitesRaw, wo => !wo.archived))
    }
  }, [selectedFilter, bidInvitesRaw])

  useEffect(() => {
    switch (sortSelected) {
      case menuActionId.CREATED_AT:
        setBidInvites(b => _.orderBy(b, 'timestamp', 'desc'))
        break
      case menuActionId.UPDATED_AT:
        setBidInvites(b => _.orderBy(b, 'updatedAt', 'desc'))
        break
      case menuActionId.ACCOUNT_NAME:
        setBidInvites(b => _.sortBy(b, 'companyName'))
        break
      case menuActionId.DUE_DATE:
        setBidInvites(b => _.sortBy(b, 'bidsDueDate'))
        break
    }
  }, [sortSelected, selectedFilter])

  const handleFilterChange = ({ selected }) => {
    setSelectedFilter(selected)
  }

  const sortLabel = (
    <Box direction='row' align='center' gap='xsmall'>
      <FontAwesomeIcon icon={faSort} size={14} color={colors.ANOTHER_GREY} />
      <Text color={colors.ANOTHER_GREY} size='small'>
        Sort
      </Text>
    </Box>
  )

  const handleSortAction = actionId => {
    setSortOpened(false)
    setSortSelected(actionId)
  }

  const dropContent = (
    <DropContent
      onOptionClick={handleSortAction}
      options={menuActions}
      boxProps={{ width: '250px' }}
      optionLabelProps={{ textAlign: 'start', weight: 'normal' }}
      selectedId={sortSelected}
      selectedColor={colors.CONTACT_LABEL}
      selectedBackground={colors.VERY_LIGHT_GREY_TWO}
    />
  )

  const renderHeader = isMobile => (
    <Box
      direction='row'
      justify='between'
      width={isMobile ? '100%' : { min: '45vw', max: '80%' }}
      border='bottom'
      pad={{ bottom: 'medium', horizontal: 'small' }}
      flex={false}
    >
      <Box justify='center'>
        <Text size='large'>Bids</Text>
      </Box>
      <Box direction='row' gap='medium'>
        <DropButton
          plain
          label={sortLabel}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropContent={dropContent}
          open={sortOpened}
          onOpen={() => setSortOpened(true)}
          onClose={() => setSortOpened(false)}
          dropProps={{ margin: { top: 'small' } }}
        />
        <ThemeContext.Extend
          value={{ checkBox: { color: colors.AQUA_MARINE, hover: { border: { color: colors.AQUA_MARINE } } } }}
        >
          <Select
            clear={_.size(selectedFilter) > 0 ? { position: 'top', label: 'Clear filters' } : false}
            plain
            icon={false}
            options={filterOptions}
            multiple
            onChange={handleFilterChange}
            closeOnChange={false}
            labelKey='label'
            valueKey='value'
            selected={selectedFilter}
            dropProps={{
              width: 'small',
              justify: 'center'
            }}
            value={
              <Box direction='row' align='center' gap='xsmall'>
                <FontAwesomeIcon icon={faSlidersV} size={14} color={colors.ANOTHER_GREY} />
                <Text color={colors.ANOTHER_GREY} size='small'>
                  Filter
                </Text>
              </Box>
            }
          >
            {(option, index) => <Option value={option.label} selected={selectedFilter.indexOf(index) !== -1} />}
          </Select>
        </ThemeContext.Extend>
      </Box>
    </Box>
  )

  return (
    <Box height='100%'>
      <BidInvitesListNavBar />
      <Box fill align='center' margin={{ top: 'large' }}>
        {renderHeader(isMobile)}
        {renderContent(bidInvites, isMobile)}
      </Box>
      <CalendarSidepanel open={calendarOpen} setOpen={setCalendarOpen} type='sub' />
    </Box>
  )
}

BidInvitesList.propTypes = {
  bidInvites: PropTypes.array
}

const mapStateToProps = state => ({
  bidInvitesRaw: _.sortBy(_.get(getPendingJobOffers(state), 'all'), 'timestamp')
})

export default connect(mapStateToProps)(BidInvitesList)
