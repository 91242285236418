import * as types from 'model/actionTypes'

export function receiveAccount (account) {
  return {
    type: types.RECEIVE_ACCOUNT,
    account
  }
}

export function addNewAccountContact (contact) {
  return {
    type: types.ADD_NEW_ACCOUNT_CONTACT,
    contact
  }
}
