import React from 'react'
import { Box } from 'grommet'

import StyledCheckbox from 'components/StyledCheckbox'

const LineItemLabor = ({ li, parentLi, onChange }) => {
  return (
    <Box align='center' justify='center' border={['bottom', 'right']}>
      <StyledCheckbox checked={li.labor} onChange={onChange} />
    </Box>
  )
}

export default LineItemLabor
