import * as types from 'model/actionTypes'

export function receiveChannels (channels) {
  return {
    type: types.RECEIVE_CHANNELS,
    channels
  }
}

export function receiveChannel (channel) {
  return {
    type: types.RECEIVE_CHANNEL,
    channel
  }
}
