import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, Keyboard } from 'grommet'

import StyledCheckbox from 'components/StyledCheckbox'
import lineItemType from 'shared/constants/lineItemType'

const LineItemOptionalCell = ({ li, parentLi, updateItem, scope, selectRow, unselectRow, focusNextElement, disabled }) => {
  const [focused, setFocused] = useState(false)
  const onChange = event => {
    updateItem(li.id, { optional: event.target.checked }, parentLi)
  }
  const isQuestion = _.includes([lineItemType.QUESTION_BOOL, lineItemType.QUESTION], li.type)
  const isDisabled = isQuestion || !_.isNil(parentLi) || _.has(scope, li.id) || disabled

  const handleFocus = () => {
    setFocused(true)
    selectRow()
  }

  const handleBlur = () => {
    setFocused(false)
    unselectRow()
  }

  return (
    <Box plain scope='col' align='center' size='45px' pad='xxsmall' border={['bottom', 'right']}>
      <Box
        fill
        widht='45px'
        height='45px'
        align='center'
        justify='center'
        className={focused ? 'selected_cell' : ''}
        border={focused ? 'all' : undefined}
      >
        <Keyboard onTab={e => focusNextElement('optional', e)}>
          <StyledCheckbox
            disabled={isDisabled}
            checked={li.optional}
            onChange={onChange}
            type={isDisabled ? 'default' : 'success'}
            id={`${li.id}_optional`}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Keyboard>
      </Box>
    </Box>
  )
}

LineItemOptionalCell.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  updateItem: PropTypes.func,
  scope: PropTypes.object
}

export default LineItemOptionalCell
