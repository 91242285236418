import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { auth, db } from 'constants/firebase'
import store from 'model/store'
import { receivePaymentRequests } from 'model/actions/paymentRequestsAC'
import { addListener } from 'controllers/listeners'
import paymentRequestStatus from 'shared/constants/paymentRequestStatus'
import { saveFile, deleteFile } from 'shared/controllers/storage'
import messageType from 'shared/constants/messageType'
import paymentRequestType from 'shared/constants/paymentRequestType'
import { sendDocMessage } from 'shared/controllers/chat'
import bidStatus from 'shared/constants/bidStatus'
import { isLocalFileUrl } from 'shared/utils/url'
import { makeChannelKey } from 'shared/utils/chatUtils'

export const fetchPaymentRequests = accountId => {
  try {
    console.log('fetch payment requests, accountId:', accountId)
    const unsubscribe = db
      .collection('paymentRequests')
      .where('accounts', 'array-contains', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetch payment requests, amount', _.size(res))
          store.dispatch(receivePaymentRequests(res))
        },
        err => {
          console.log(`fetchPaymentRequests error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('paymentRequests', unsubscribe)
  } catch (e) {
    console.log('fetch payment requests error', e)
    Sentry.captureException(e)
  }
}

export function setPaymentRequestSeen (prId) {
  return async function (dispatch, getState) {
    console.log('set Payment request seen', prId)
    const currentUser = auth.currentUser
    const upd = { [`seenBy.${currentUser.uid}`]: _.now() }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function rejectPaymentRequest (prId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = {
      status: paymentRequestStatus.REJECTED,
      seenBy: {
        [userId]: _.now()
      }
    }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function setApproverDisabled (prId, approverId, v) {
  return async function (dispatch, getState) {
    const upd = { [`disabledApprovers.${approverId}`]: v }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function approvePaymentRequest (prId) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = {
      [`approvedBy.${userId}`]: _.now(),
      seenBy: {
        [userId]: _.now()
      }
    }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function setPaymentRequestStatus (prId, status) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = {
      status,
      seenBy: {
        // remove seenBy except the current user
        [userId]: _.now()
      }
    }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function setPaymentRequestFundingSource (prId, fundingSource) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = {
      fundingSource,
      status: paymentRequestStatus.PENDING,
      approvedBy: {},
      seenBy: {
        [userId]: _.now()
      }
    }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function setPaymentRequestApprovedAmount (prId, amount) {
  return async function (dispatch, getState) {
    const userId = auth.currentUser.uid
    const upd = {
      approvedAmount: amount,
      status: paymentRequestStatus.PENDING,
      approvedBy: {},
      seenBy: {
        [userId]: _.now()
      }
    }
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update(upd)
  }
}

export function createPaymentRequestForSub (pr) {
  return async function (dispatch, getState) {
    try {
      console.log('createPaymentRequestForSub', pr)
      const authUser = auth.currentUser
      const { files, ...rest } = pr
      const timeNow = _.now()

      const bid = {
        ...pr,
        status: {
          type: bidStatus.ACCEPTED,
          userId: authUser.uid,
          timestamp: timeNow
        }
      }
      // console.log('---> save bid', bid)
      await db
        .collection('bids')
        .doc(bid.id)
        .set(bid)
      // console.log('---> update work order approved bid Id')
      await db
        .collection('workOrders')
        .doc(bid.workOrderId)
        .update({ approvedBidId: bid.id })

      const bidDoc = {
        type: messageType.BID,
        bidId: bid.id
      }
      const channelKey = makeChannelKey(bid.workOrderAccountId, bid.projectId, bid.accountId)
      sendDocMessage(channelKey, 'Bid', bidDoc)

      const dbPR = {
        ...rest,
        prType: paymentRequestType.HIC_REQUEST
      }
      await db
        .collection('paymentRequests')
        .doc(dbPR.id)
        .set(dbPR)
      savePaymentRequestFiles(dbPR.id, files)
      const doc = {
        type: messageType.PAYMENT_REQUEST,
        prId: dbPR.id
      }
      sendDocMessage(channelKey, 'Payment request', doc)
    } catch (e) {
      console.log('createPaymentRequestForSub error: ', e)
      Sentry.captureException(e)
    }
  }
}

const savePaymentRequestFiles = async (prId, files) => {
  try {
    const uploadedFiles = await Promise.all(
      _.map(files, async f => {
        if (isLocalFileUrl(f.url)) {
          const { url, size } = await saveFile(`paymentRequests/${prId}/${f.id}`, f.url)
          const thumbRes = await saveFile(`paymentRequests/${prId}/${f.id}_thumb`, f.thumbUrl)
          return { ...f, url, size, thumbUrl: thumbRes.url }
        } else {
          return f
        }
      })
    )
    await db
      .collection('paymentRequests')
      .doc(prId)
      .update({ files: uploadedFiles })
  } catch (e) {
    console.log('savePaymentRequestFiles error: ', e)
    Sentry.captureException(e)
  }
}

export function sendPaymentRequest (pr) {
  return async function (dispatch, getState) {
    try {
      console.log('sendPaymentRequest', pr)
      const { files, ...rest } = pr
      const dbPR = { ...rest }
      console.log('sendPR Files', files)
      await db
        .collection('paymentRequests')
        .doc(dbPR.id)
        .set(dbPR)

      savePaymentRequestFiles(dbPR.id, files)

      const doc = {
        type: messageType.PAYMENT_REQUEST,
        prId: dbPR.id
      }
      const channelKey = makeChannelKey(pr.workOrderAccountId, pr.projectId, pr.accountId)
      sendDocMessage(channelKey, 'Payment request', doc)
    } catch (e) {
      console.log('sendPaymentRequest error: ', e)
      Sentry.captureException(e)
    }
  }
}

const deletePaymentRequestAttachment = (prId, fileId) => {
  try {
    deleteFile(`paymentRequests/${prId}/${fileId}`)
    deleteFile(`paymentRequests/${prId}/${fileId}_thumb`)
  } catch (e) {
    console.log('deletePaymentRequestAttachment error: ', e)
    Sentry.captureException(e)
  }
}

export async function deletePaymentRequest (prId) {
  try {
    await db
      .collection('paymentRequests')
      .doc(prId)
      .delete()
  } catch (e) {
    Sentry.captureException(e)
    console.log('deletePaymentRequest error: ', e)
  }
}

export function updatePaymentRequest (data, prevData) {
  return async function (dispatch) {
    try {
      console.log('update payment request: data', data)
      console.log('update payment request: prevData', prevData)
      const { files, ...dbPR } = data
      const prevFiles = _.get(prevData, 'files', {})
      const prId = _.get(data, 'id')
      console.log('dbPR', dbPR)

      await db
        .collection('paymentRequests')
        .doc(prId)
        .set(dbPR)

      _.forEach(prevFiles, fileId => (!_.has(files, fileId) ? deletePaymentRequestAttachment(prId, fileId) : null))
      savePaymentRequestFiles(prId, files)

      const doc = {
        type: messageType.PAYMENT_REQUEST,
        prId: dbPR.id
      }
      const channelKey = makeChannelKey(data.workOrderAccountId, data.projectId, data.accountId)
      sendDocMessage(channelKey, 'Payment request updated', doc)
    } catch (e) {
      Sentry.captureException(e)
      console.log('updatePaymentRequest error: ', e)
    }
  }
}
