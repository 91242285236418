import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, ThemeContext, TextInput, Text, Keyboard } from 'grommet'
import { css } from 'styled-components'
import numeral from 'numeral'

import colors from 'shared/constants/colors'

const CurrencyInputCell = ({
  initialValue,
  enabled,
  selectRow,
  unselectRow,
  save,
  placeholder,
  tableCellProps,
  focusNextElement,
  name,
  id,
  disabled,
  highlighted
}) => {
  const [value, setValue] = useState(null)
  const [focused, setFocused] = useState(false)
  const inputRef = useRef()

  useEffect(() => {
    setValue(null)
  }, [initialValue])

  const onChange = event => {
    const valueRaw = event.target.value.replace(/[^0-9.,$]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,]/g, '')
    // console.log('valueRaw', valueRaw, 'newValue', newValue)
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(value).value()
      // console.log('newV', newV, 'curV', curV)
      if (newV === curV || _.isNil(value)) {
        setValue(valueRaw)
      } else {
        setValue(numeral(newValue).format('$0,0.[00]'))
      }
    } else {
      setValue('')
    }
  }

  let curValue = initialValue
  // console.log('li cost', curValue)
  if (!_.isEmpty(_.toString(curValue))) {
    const format = focused ? '$0,0.[00]' : '$0,0.00'
    curValue = numeral(curValue).format(format)
  }
  // console.log('curValue after formatting', curValue)
  if (!_.isNil(value)) curValue = value
  if (_.isNil(curValue)) curValue = ''
  // console.log('final cur value', curValue)

  const onBlur = () => {
    // setValue(numeral(value).format('$0,0.00'))
    setFocused(false)
    unselectRow()
    if (!_.isNil(value)) {
      const cost = _.size(value) > 0 ? numeral(value).value() : null
      save(cost)
    }
  }

  const onFocus = () => {
    setFocused(true)
    selectRow()
  }

  const onEnter = () => {
    inputRef.current.blur()
  }

  const textInputThemeValue = {
    global: {
      control: { border: { color: 'transparent', radius: '0px' } }
    },
    textInput: {
      extend: css`
        height: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
      `,
      container: {
        extend: css`
          input {
            text-align: center;
            ${highlighted && !disabled ? `
              border: 1px ${colors.LINK} solid;
              border-radius: 4px;
            ` : ''}
          }
          input:focus {
            background-color: ${colors.WHITE};
            border: 1px ${colors.AQUA_MARINE} solid;
          }
        `
      }
    }
  }

  const currencyInput = (
    <ThemeContext.Extend value={textInputThemeValue}>
      <Box flex justify='center' pad='8px'>
        <Keyboard onEnter={onEnter} onTab={e => focusNextElement(name, e)}>
          <TextInput
            value={curValue}
            alignSelf='center'
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            ref={inputRef}
            id={`${id}_${name}`}
            disabled={disabled}
          />
        </Keyboard>
      </Box>
    </ThemeContext.Extend>
  )

  return (
    <Box plain size='120px' border={['bottom', 'right']} {...tableCellProps}>
      {enabled ? (
        currencyInput
      ) : (
        <Box fill align='center' justify='center'>
          <Text textAlign='center' size='small' color={colors.ANOTHER_GREY}>
            n/a
          </Text>
        </Box>
      )}
    </Box>
  )
}

CurrencyInputCell.defaultProps = {
  placeholder: 'optional',
  tableCellProps: {}
}

CurrencyInputCell.propTypes = {
  initialValue: PropTypes.number,
  enabled: PropTypes.bool,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  save: PropTypes.func,
  placeholder: PropTypes.string,
  tableCellProps: PropTypes.object
}

export default CurrencyInputCell
