import _ from 'lodash'
import { createSelector } from 'reselect'

import { toTimestamp } from 'shared/utils/date'
import { getUserId, getInbox, getAccountId, getProfiles, getChannel, getWorkOrders } from 'model/selectors/base'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'
import { getBidsByWorkOrdersAccounts } from 'model/selectors/bids'
import { getFilesDict } from 'model/selectors/files'
import messageType from 'constants/messageType'
import { wrapMentions } from 'shared/utils/stringUtils'

export const getInboxMessages = createSelector(
  [
    getInbox,
    getAccountId,
    getUserId,
    getWorkOrdersWithTitlesDict,
    getProfiles,
    getBidsByWorkOrdersAccounts,
    getFilesDict,
    getChannel,
    getWorkOrders
  ],
  (inbox, accountId, userId, workOrdersDict, profiles, bidsByWorkOrderAccount, filesDict, readAt, allWorkOrders) => {
    const messages = _.get(inbox, 'messages')
    console.log('messages', messages)
    const res = []
    _.forEach(messages, m => {
      const mType = _.get(m, 'type')
      const mUserId = _.get(m, 'userId')
      const workOrderId = m.workOrderId
      const wo = _.get(workOrdersDict, workOrderId, _.get(allWorkOrders, workOrderId))
      const accountId = _.get(m, 'accountId')
      if (_.isEmpty(wo)) {
        // console.log('workOrder not found', m.workOrderId, 'message', m.id)
      }
      let title = _.get(wo, 'projectAddress.name', '')
      const woTitle = _.get(wo, 'title')
      if (!_.isEmpty(woTitle)) title = `${title} - ${woTitle}`
      if (mUserId !== userId) {
        if (mType === messageType.ANNOTATION) {
          const file = _.get(filesDict, m.fileId)
          const itemName = _.get(file, 'name')
          const readTime = toTimestamp(_.get(readAt, m.fileId))
          const timestamp = toTimestamp(m.timestamp)
          const unseen = readTime < timestamp
          res.push({
            id: m.id,
            type: messageType.ANNOTATION,
            profile: _.get(profiles, mUserId),
            strAction: 'annotated',
            timestamp,
            text: _.get(m, 'bodyValue'),
            itemName,
            title,
            unseen,
            workOrderId,
            fileId: m.fileId
          })
        } else {
          let itemName = _.get(
            wo,
            ['scope', m.itemId, 'name'],
            _.get(bidsByWorkOrderAccount, [workOrderId, m.subId, 'items', m.itemId, 'name'])
          )
          let channelKey = workOrderId
          if (m.type === messageType.SUB) {
            channelKey = `${channelKey}_${m.subId}`
          } else if (m.type === messageType.ITEM) {
            channelKey = `${channelKey}_${m.subId}_${m.itemId}`
          } else if (m.type === messageType.ANNONCEMENT) {
            channelKey = `${channelKey}_${accountId}_${m.itemId}`
          } else if (m.type === messageType.NOTE) {
            channelKey = `${channelKey}_${m.subId}`
          }
          const readTime = toTimestamp(_.get(readAt, channelKey))
          const timestamp = toTimestamp(m.timestamp)
          const unseen = readTime < timestamp
          let strAction = 'commented'
          let text = _.get(m, 'text')
          if (mType === messageType.NOTE) {
            strAction = 'mentioned you'
            const regex = /(@\[.*?\]\(.*?\))/
            text = _.join(wrapMentions(text, regex), '')
          }
          if (mType === messageType.NOTE || mType === messageType.SUB) {
            itemName = _.get(
              wo,
              ['invitations', m.subId, 'companyName'],
              _.get(
                wo,
                ['invitaitons', m.subId, 'name']
              )
            )
          }
          res.push({
            id: m.id,
            type: m.type,
            profile: _.get(profiles, mUserId, { name: _.get(wo, ['invitations', accountId, 'companyName']) }),
            strAction,
            timestamp,
            text,
            itemName,
            unseen,
            title,
            itemId: m.itemId,
            subId: m.subId,
            workOrderId: workOrderId
          })
        }
      } else {
        // console.log('this is my message', m)
      }
    })
    // console.log('%cinbox messages amount', 'color: red', _.size(res))
    // console.log('%cinbox amount', 'color: pink', _.size(res), 'messages amount', _.size(messages))
    return _.orderBy(res, ['timestamp'], ['desc'])
  }
)

export const getUnseenAmount = createSelector([getInboxMessages], messages => {
  return _.sumBy(messages, m => (m.unseen ? 1 : 0))
})

export const hasMoreInbox = createSelector([getInbox], inbox => {
  console.log('inbox', inbox)
  const firstMessageId = _.get(inbox, 'firstMessage.id')
  console.log('firstMessageId', firstMessageId)
  return !_.isNil(firstMessageId) && !_.has(inbox.messages, firstMessageId)
})
