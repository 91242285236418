import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, Keyboard } from 'grommet'

import StyledCheckbox from 'components/StyledCheckbox'
import lineItemType from 'shared/constants/lineItemType'

const LineItemExcludedCell = ({ li, parentLi, updateItem, scope, focusNextElement, selectRow, unselectRow, disabled }) => {
  const [focused, setFocused] = useState(false)
  const onChange = event => {
    updateItem(li.id, { excluded: event.target.checked }, parentLi)
  }

  const handleFocus = () => {
    setFocused(true)
    selectRow()
  }

  const handleBlur = () => {
    setFocused(false)
    unselectRow()
  }

  const isQuestion = _.includes([lineItemType.QUESTION_BOOL, lineItemType.QUESTION], li.type)
  const isDisabled = isQuestion || !_.isNil(parentLi) || _.get(scope, [li.id, 'required']) || disabled
  return (
    <Box scope='col' plain align='center' size='45px' pad='xxsmall' border={['bottom', 'right']}>
      <Box
        fill
        // widht='45px'
        // height='45px'
        align='center'
        justify='center'
        className={focused ? 'selected_cell' : ''}
        border={focused ? 'all' : undefined}
      >
        <Keyboard onTab={e => focusNextElement('excluded', e)}>
          <StyledCheckbox
            disabled={isDisabled}
            checked={li.excluded}
            onChange={onChange}
            type={isDisabled ? 'default' : 'warning'}
            id={`${li.id}_excluded`}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Keyboard>
      </Box>
    </Box>
  )
}

LineItemExcludedCell.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object,
  updateItem: PropTypes.func,
  scope: PropTypes.object
}

export default LineItemExcludedCell
