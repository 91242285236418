import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Text, Box, Tip } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faComment } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { IGNORE_OUTSIDE_CLICK_CLASS } from 'constants/index'

const LineItemCommentsCell = ({ li, parentLi, setCommentId, messagesAmount, hasNewMessage, disabled }) => {
  const id = _.get(parentLi, 'id', li.id)
  const handleClick = () => {
    if (_.isFunction(setCommentId)) setCommentId(id)
  }
  return (
    <Box align='center' justify='center' size='45px' pad='xxsmall' border={['bottom', 'right']}>
      <Tip content='Add comment'>
        <Box
          customStyle={`
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            ${!disabled && `:hover {
              * {
                color: ${colors.AQUA_MARINE};
                fill: ${colors.AQUA_MARINE};
              };
            }
            `}`}
          direction='row'
          align='center'
          justify='center'
          gap='xxsmall'
          onClick={disabled ? () => null : handleClick}
          className={IGNORE_OUTSIDE_CLICK_CLASS}
        >
          <Text
            size='xsmall'
            className='comments_amount'
            color={hasNewMessage ? colors.AQUA_MARINE : colors.ANOTHER_GREY}
          >
            {messagesAmount > 0 ? messagesAmount : ''}
          </Text>
          <FontAwesomeIcon icon={faComment} color={hasNewMessage ? colors.AQUA_MARINE : colors.ANOTHER_GREY} />
        </Box>
      </Tip>
    </Box>
  )
}

LineItemCommentsCell.propTypes = {
  li: PropTypes.object,
  parentLi: PropTypes.object
}

export default LineItemCommentsCell
