import React, { useState, useContext } from 'react'
import {
  Avatar,
  Box,
  Button,
  Header,
  Layer,
  Select,
  Text,
  ThemeContext
} from 'grommet'
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { inviteMasonAdmins } from 'controllers/invitations'
import LayoutContext from 'webPages/layout/LayoutContext'

const borderBottom = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'bottom'
}

const InviteAdmin = ({ adminOpen, setAdminOpen, masonAdmins, accountId }) => {
  const [selected, setSelected] = useState([])
  const { openToast } = useContext(LayoutContext)

  const close = () => {
    setSelected([])
    setAdminOpen(false)
  }

  const send = async () => {
    const ids = _.map(selected, i => _.get(masonAdmins, [i, 'id']))
    close()
    const res = await inviteMasonAdmins(ids, accountId)
    const content = (
      <Box round='5px' background={res ? colors.AQUA_MARINE30 : colors.CORAL_TWO} justify='center' align='center' pad='small' margin='small'>
        <Text color={colors.TEXT}>{res ? 'Admins invited' : 'Error while inviting admins'}</Text>
      </Box>
    )
    openToast(content)
  }

  const onRemoveAdmin = (index) => {
    const res = [...selected]
    _.pull(res, index)
    setSelected(res)
  }

  const renderAdmin = index => {
    const user = masonAdmins[index]
    return (
      <Button
        key={`admin_${_.get(user, 'id')}`}
        href='#'
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onRemoveAdmin(index)
        }}
        onFocus={event => event.stopPropagation()}
      >
        <Box
          align='center'
          direction='row'
          gap='xsmall'
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          margin='xsmall'
          background={colors.AQUA_MARINE}
          round='large'
        >
          <Text size='small' color={colors.WHITE}>{getName(user)}</Text>
          <FontAwesomeIcon icon={faTimes} size={12} color={colors.WHITE} />
        </Box>
      </Button>
    )
  }

  const renderOption = (user, state) => {
    const active = _.includes(selected, state)
    const email = _.get(user, 'email')
    const phone = _.get(user, 'phone') || ''
    const phoneNumber = parsePhoneNumberFromString(phone)
    const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
    return (
      <Box
        flex={false}
        direction='row'
        align='center'
        key={user.id}
        pad='small'
        border={borderBottom}
        hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
        background={active ? colors.AQUA_MARINE30 : undefined}
        height='52px'
        onClick={() => null}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={user.avatarSmall}>
            <Text color={colors.WHITE}>{getInitials(user)}</Text>
          </Avatar>
        </Box>
        <Box fill margin={{ left: 'medium' }} align='start' justify='center' gap='xsmall'>
          <Text>{getName(user)}</Text>
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {contacts}
          </Text>
        </Box>
      </Box>
    )
  }

  if (!adminOpen) return null
  return (
    <ThemeContext.Extend value={{ layer: { border: { radius: 'xsmall' } } }}>
      <Layer
        position='top'
        onClickOutside={close}
        onEsc={close}
        modal
        margin={{ top: 'large' }}
        flex={false}
        animate={false}
      >
        <Box width='medium' gap='medium' flex pad='medium'>
          <Header justify='between'>
            <Text color={colors.TEXT}>Invite Mason Admins to Company</Text>
            <Button
              plain
              icon={<FontAwesomeIcon icon={faTimes} size={12} color={colors.TEXT} />}
              onClick={close}
            />
          </Header>
          <Select
            closeOnChange
            multiple
            value={
              <Box wrap direction='row' width='small'>
                {selected && selected.length ? (_.map(selected, renderAdmin))
                  : (
                    <Box
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                      margin='xsmall'
                    >
                      Select Admins
                    </Box>
                  )}
              </Box>
            }
            labelKey='name'
            options={masonAdmins}
            selected={selected}
            onChange={({ selected: nextSelected }) => {
              setSelected([...nextSelected].sort())
            }}
          >
            {renderOption}
          </Select>
          <Box>
            <Button primary color={colors.AQUA_MARINE} label='Invite' onClick={send} disabled={_.size(selected) === 0} />
          </Box>
        </Box>
      </Layer>
    </ThemeContext.Extend>
  )
}

export default InviteAdmin
