import Sentry from 'shared/utils/sentry'
import _ from 'lodash'

import { auth, db, firestore } from 'constants/firebase'
import store from 'model/store'
import { receiveReimbursementRequests } from 'model/actions/reimbursementsAC'
import { addListener } from 'controllers/listeners'
import { saveFile } from 'shared/controllers/storage'
import { isLocalFileUrl } from 'shared/utils/url'

export const fetchReimbursementRequests = accountId => {
  try {
    console.log('fetch initReimbursementRequests, accountId:', accountId)
    const unsubscribe = db
      .collection('reimbursementRequests')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('fetch reimbursementRequests, amount', _.size(res))
          store.dispatch(receiveReimbursementRequests(res))
        },
        err => {
          console.log(`fetchReimbursementRequests error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('reimbursementRequests', unsubscribe)
  } catch (e) {
    console.log('fetch v error', e)
    Sentry.captureException(e)
  }
}

export const saveReimbursementRequest = async reimbursementRequest => {
  try {
    const uid = auth.currentUser.uid
    const { files, ...rr } = reimbursementRequest
    const dbRR = {
      ...rr,
      createdBy: uid
    }
    await db
      .collection('reimbursementRequests')
      .doc(dbRR.id)
      .set(dbRR)
    const uploadedFiles = await Promise.all(
      _.map(files, async f => {
        if (isLocalFileUrl(f.url)) {
          const { url, size } = await saveFile(`reimbursementRequests/${dbRR.id}/${f.id}`, f.url)
          const thumbRes = await saveFile(`paymentRequests/${dbRR.id}/${f.id}_thumb`, f.thumbUrl)
          return { ...f, url, size, thumbUrl: thumbRes.url }
        } else {
          return f
        }
      })
    )
    await db
      .collection('reimbursementRequests')
      .doc(dbRR.id)
      .update({ files: uploadedFiles })
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error saving reimbursementRequest', e)
  }
}

export function setReimbursementRequestSeen (reimbursementRequestId) {
  console.log('setting reimbursement requeest to seen')
  return async function (dispatch, getState) {
    const currentUser = auth.currentUser
    await db
      .collection('reimbursementRequests')
      .doc(reimbursementRequestId)
      .update({ [`seenBy.${currentUser.uid}`]: firestore.FieldValue.serverTimestamp() })
  }
}
