import React from 'react'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import DropDownMenu from 'components/DropDownMenu'

const EDIT_SCOPE = 'EDIT_SCOPE'

const SectionTitle = ({ title, projectId, workOrderId }) => {
  const customStyle = `
   > button {
      display: none;
   }
   :hover {
    > button {
        display: flex;
      }
   }
  `

  const toEditScope = () => universalNavigation.push(screens.GC_PROJECT_WORKORDER, { projectId, workOrderId })

  const onMenuOptionClick = id => {
    switch (id) {
      case EDIT_SCOPE:
        toEditScope()
        break
      default:
        break
    }
  }

  return (
    <Box direction='row' align='center' pad={{ horizontal: 'medium' }} background={colors.PALE_GREY} customStyle={customStyle}>
      <Text size='large' color={colors.TEXT_PRIMARY}>{title}</Text>
      <DropDownMenu
        id={`${title}-dropmenu`}
        options={[
          { id: EDIT_SCOPE, label: 'Edit Scope' }
        ]}
        dropContentProps={{ boxProps: { width: { min: '160px' } } }}
        dropAlign={{ top: 'bottom', left: 'left' }}
        onOptionClick={onMenuOptionClick}
      >
        <Box
          direction='row'
          align='center'
          round='xsmall'
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          gap='small'
        >
          <FontAwesomeIcon icon={faEllipsisH} size={18} color={colors.TEXT} />
        </Box>
      </DropDownMenu>
    </Box>
  )
}

export default SectionTitle
