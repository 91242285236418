import React from 'react'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'

const HeaderFirstCell = () => {
  return (
    <Box
      flex
      pad={{ horizontal: 'small' }}
      align='center'
      justify='center'
      background={colors.WHITE}
      border={[
        {
          side: 'right',
          color: colors.VERY_LIGHT_GREY_TWO
        },
        {
          side: 'left',
          color: colors.VERY_LIGHT_GREY_TWO
        },
        {
          side: 'bottom',
          color: colors.VERY_LIGHT_GREY_TWO
        }
      ]}
    >
      <Text circular size='small' color={colors.ANOTHER_GREY} textAlign='center'>
        Project Budget
      </Text>
    </Box>
  )
}

export default React.memo(HeaderFirstCell)
