import React from 'react'
import { ThemeContext, CheckBox } from 'grommet'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const calculateColor = (type, checked, color) => {
  return type === 'success' ? colors.AQUA_MARINE
    : type === 'warning' ? colors.CORAL_TWO
      : checked ? color : colors.ANOTHER_GREY
}

const StyledCheckbox = ({ checked, onChange, color, type, ...props }) => {
  return (
    <ThemeContext.Extend
      value={{
        checkBox: {
          hover: {
            border: {
              color: calculateColor(type, checked, color)
            }
          },
          extend: props =>
            css`
              > div {
                background-color: ${props.checked ? calculateColor(type, true, color) : colors.WHITE};
                border-color: ${props.checked ? calculateColor(type, true, color) : colors.ANOTHER_GREY};
                border-radius: 4px;
                font-size: 10px;
                pointer-events: none;
              }
            `,
          size: '16px',
          color: calculateColor(type, checked, color),
          border: {
            color: calculateColor(type, checked, color),
            width: '1px'
          },
          icon: {
            size: '8px'
          },
          check: {
            radius: '4px',
            color: calculateColor(type, checked, color)
          },
          icons: {
            checked: props => <FontAwesomeIcon icon={faCheck} color={colors.WHITE} size={10} />
          }
        }
      }}
    >
      <CheckBox size='xsmall' checked={checked} onChange={onChange} {...props} />
    </ThemeContext.Extend>
  )
}

StyledCheckbox.defaultProps = {
  color: colors.AQUA_MARINE
}

StyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string
}

export default StyledCheckbox
