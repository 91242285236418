import React from 'react'
import { Box } from 'grommet'
import _ from 'lodash'

import Details from 'webPages/invitation/desktop/Details'
import Files from 'webPages/invitation/desktop/Files'
import Scope from 'webPages/invitation/desktop/Scope'
import colors from 'shared/constants/colors'
import ResolveInviteFooter from 'webPages/invitation/desktop/Footer'

const ResolveInviteDesktop = ({
  address,
  files,
  scope,
  sections,
  projectName,
  gcName,
  bidsDue,
  handleResolve
}) => {
  return (
    <Box flex={false}>
      <Details
        isEmptySow={_.size(scope) === 0}
        address={address}
        projectName={projectName}
        gcName={gcName}
        bidsDue={bidsDue}
        props={{
          background: colors.WHITE,
          border: 'bottom',
          customStyle: 'position: fixed; top: 0; left: 0; z-index: 100'
        }}
      />
      <Box margin={{ vertical: 'large' }}>
        {_.size(files) > 0 && <Files files={files} />}
        {_.size(scope) > 0 && <Scope scope={scope} sections={sections} />}
      </Box>
      <ResolveInviteFooter
        projectName={projectName}
        gcName={gcName}
        bidsDue={bidsDue}
        isEmptySow={_.size(scope) === 0}
        handleResolve={handleResolve}
        address={address}
      />
    </Box>
  )
}

export default ResolveInviteDesktop
