import React from 'react'
import styled from 'sc'
import { View, Image } from 'react-native'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import { getName, getAddress, getStateAbbr } from 'shared/utils/stringUtils'
import { formatNational } from 'shared/utils/phone'

const Container = styled(View)`
  width: ${props => props.pageWidth}px;
  height: ${props => props.pageHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`
const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const BackgroundOveraly = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.73);
  /* background-color: ${colors.BLACK70}; */
`
const TitleContainer = styled(View)`
  margin-top: ${props => props.getSize(200)}px;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
`
const CompanyNameContainer = styled(View)`
  margin-top: ${props => props.getSize(10)}px;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
`
const ProjectAddressContainer = styled(View)`
  margin-top: ${props => props.getSize(30)}px;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
`
const DetailsContainer = styled(View)`
  position: absolute;
  left: 5%;
  bottom: 4%;
  display: flex;
  /* background-color: yellow; */
  flex-direction: column;
`
const DetailsRow = styled(View)`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.getSize(10)}px;
  ${props => props.customStyle}
`
const DetailsTitle = styled(View)`
  width: ${props => props.getSize(60)}px;
`
const DetailsValue = styled(View)`
  width: ${props => props.getSize(260)}px;
`

const BottomRightContainer = styled(View)`
  position: absolute;
  right: 5%;
  bottom: 5%;
  display: flex;
  flex-direction: column;
`
const Circle = styled(View)`
  width: ${props => props.getSize(20)}px;
  height: ${props => props.getSize(20)}px;
  border-radius: ${props => props.getSize(10)}px;
  background-color: ${props => props.backgroundColor || colors.AQUA_MARINE};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.getSize(8)}px;
`
const CheckmarkContainer = styled(View)`
  width: ${props => props.getSize(8)}px;
  height: ${props => props.getSize(6)}px;
  position: relative;
`
const CheckmarkImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${props => props.getSize(8)}px;
  height: ${props => props.getSize(6)}px;
`
const PoweredByContainer = styled(View)`
  width: ${props => props.getSize(100)}px;
  height: ${props => props.getSize(16)}px;
  position: absolute;
  bottom: ${props => props.getSize(10)}px;
  right: ${props => props.getSize(10)}px;
`
const PoweredByImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const defaultImageUri = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Fcover.jpg?alt=media&token=8c5047e2-adcd-4364-82b3-72132480bf97'
const checkmarkUrl = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Fc.png?alt=media&token=2acbe79b-d610-4e64-a894-823053bb434c'
const masonLogoUrl = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Fbrand-mason-logo-w-icon-for-dark-bg-no-bg-on-exp%403x.png?alt=media&token=45ade7c6-a5de-44bd-9082-80eb57a63391'

export const renderCover = (getSize, width, height, ssr, typeOfWork, profile, projectAddress, isProfile) => {
  const renderDetailsRow = (title, value) => {
    if (_.isEmpty(value)) return null
    return (
      <DetailsRow getSize={getSize} key={title}>
        <DetailsTitle getSize={getSize}>
          <StyledText
            fontSize={getSize(12)}
            color={colors.WHITE}
            customStyle={`min-width: ${getSize(150)}px`}
          >
            {title}:
          </StyledText>
        </DetailsTitle>
        <DetailsValue getSize={getSize}>
          <StyledText
            fontSize={getSize(12)}
            color={colors.WHITE}
          >
            {value}
          </StyledText>
        </DetailsValue>
      </DetailsRow>
    )
  }

  const renderCheckmark = (color) => {
    return (
      <Circle getSize={getSize} backgroundColor={color}>
        <CheckmarkContainer getSize={getSize}>
          <CheckmarkImage
            getSize={getSize}
            source={{ uri: checkmarkUrl }}
            defaultSource={ssr ? { uri: checkmarkUrl } : undefined}
            resizeMode='contain'
          />
        </CheckmarkContainer>
      </Circle>
    )
  }

  const renderTitle = () => {
    const title = isProfile ? getName(profile) : (_.isEmpty(typeOfWork) ? 'Proposal' : `${typeOfWork} proposal`)
    return (
      <TitleContainer getSize={getSize}>
        <StyledText
          color={colors.WHITE}
          fontSize={getSize(36)}
          textAlign='center'
          bold
          customStyle='letter-spacing: 10px;'
        >
          {title}
        </StyledText>
      </TitleContainer>
    )
  }

  const renderSubTitle = () => {
    if (!isProfile) {
      return (
        <CompanyNameContainer getSize={getSize}>
          <StyledText
            color={colors.WHITE}
            fontSize={getSize(24)}
            textAlign='center'
          >
            {getName(profile)}
          </StyledText>
        </CompanyNameContainer>
      )
    }
  }

  const renderProjectAddress = () => {
    if (!_.isEmpty(projectAddress)) {
      return (
        <ProjectAddressContainer getSize={getSize}>
          <StyledText
            color={colors.WHITE}
            fontSize={getSize(14)}
            textAlign='center'
          >
            {_.get(projectAddress, 'structured.main', '')}
          </StyledText>
          <StyledText
            color={colors.WHITE}
            fontSize={getSize(14)}
            textAlign='center'
          >
            {`${_.get(projectAddress, 'city')}, ${getStateAbbr(projectAddress)} ${_.get(projectAddress, 'zipcode')}`}
          </StyledText>
        </ProjectAddressContainer>
      )
    }
  }

  const renderPoweredBy = () => {
    return (
      <PoweredByContainer getSize={getSize}>
        <PoweredByImage
          getSize={getSize}
          source={{ uri: masonLogoUrl }}
          defaultSource={ssr ? { uri: masonLogoUrl } : undefined}
          resizeMode='contain'
        />
      </PoweredByContainer>
    )
  }

  return (
    <Container pageWidth={width} pageHeight={height - 1} key='cover'>
      <BackgroundImage
        source={{ uri: defaultImageUri }}
        defaultSource={ssr ? { uri: defaultImageUri } : undefined}
        resizeMode='cover'

      />
      <BackgroundOveraly />
      {renderTitle()}
      {renderSubTitle()}
      {renderProjectAddress()}
      <DetailsContainer>
        {[
          renderDetailsRow('Phone', formatNational(_.get(profile, 'phone'))),
          renderDetailsRow('Email', _.get(profile, 'email')),
          renderDetailsRow('Address', getAddress(_.get(profile, 'address'))),
          renderDetailsRow('Website', _.get(profile, 'website')),
          renderDetailsRow('License', _.get(profile, 'contractorLicense.license'))
        ]}
      </DetailsContainer>

      <BottomRightContainer>
        <DetailsRow getSize={getSize} customStyle={`width: ${getSize(150)}px; align-items: center;`}>
          {renderCheckmark()}
          <StyledText fontSize={getSize(12)} color={colors.WHITE}>Verified license</StyledText>
        </DetailsRow>

        <DetailsRow getSize={getSize} customStyle={`width: ${getSize(150)}px; align-items: center;`}>
          {renderCheckmark()}
          <StyledText fontSize={getSize(12)} color={colors.WHITE}>No complaints found</StyledText>
        </DetailsRow>

      </BottomRightContainer>
      {renderPoweredBy()}
    </Container>
  )
}
