import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Box } from 'grommet'
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons'
import { faCommentsAlt as faCommentsAltSolid } from '@fortawesome/pro-solid-svg-icons'
import colors from 'shared/constants/colors'

const DEFAULT_CUSTOM_STYLE = 'position: absolute; right: 0px; bottom: 0px;'
const DEFAULT_PADDING = { right: 'xsmall', bottom: 'xsmall', left: 'xxsmall', top: 'xxsmall' }

const MessagesIcon = ({ hasNewMessage, size = 12, customStyle = DEFAULT_CUSTOM_STYLE, pad = DEFAULT_PADDING }) => {
  return (
    <Box
      pad={pad}
      round='xxsmall'
      customStyle={customStyle}
    >
      <FontAwesomeIcon
        icon={hasNewMessage ? faCommentsAltSolid : faCommentsAlt}
        color={hasNewMessage ? colors.AQUA_MARINE : colors.VERY_LIGHT_PINK}
        size={size}
      />
    </Box>
  )
}

export default MessagesIcon
