import React from 'react'
import { Box, Text, Button } from 'grommet'
import _ from 'lodash'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCloudDownload, faExternalLink } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { formatBytes } from 'shared/utils/stringUtils'
import lineItemSection from 'shared/constants/lineItemSection'
import lineItemType from 'shared/constants/lineItemType'

const ScopeAndFiles = ({ scope, sections, files }) => {
  const renderSection = section => {
    return (
      <Box key={_.get(section, 'id')}>
        <Box justify='start' align='center' gap='large' flex direction='row' pad={{ vertical: 'large', horizontal: 'medium' }} border='bottom'>
          <Text size='large' color={colors.LIGHT_NAVY_BRIGHT} weight={500}>{section.title}</Text>
        </Box>
        {_.map(_.get(section, 'items'), renderItem)}
      </Box>
    )
  }

  const renderItem = id => {
    const item = _.get(scope, id)
    const alts = _.get(item, 'alternates')
    const type = _.get(item, 'type')
    const required = _.get(item, 'required', false)
    const isQuestion = _.includes([lineItemType.QUESTION, lineItemType.QUESTION_BOOL], type)
    const statusColor = required ? colors.CORAL_TWO : colors.BROWN_GREY_TWO
    if (isQuestion) {
      const label = type === lineItemType.QUESTION_BOOL ? 'Yes / No' : type === lineItemType.QUESTION ? 'Text' : null
      return (
        <Box flex={{ shrink: 0, grow: 1 }}>
          <Box pad='small' direction='row' justify='between' align='center' border='bottom' >
            <Box gap='small' pad={{ left: 'large' }}>
              <Text color={colors.TEXT_PRIMARY} size='small'>
                {_.get(item, 'name')}
              </Text>
              {_.size(_.get(item, 'desc')) > 0 && (
                <Text color={colors.TEXT} size='xsmall'>
                  {_.get(item, 'desc')}
                </Text>
              )}
            </Box>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>{label}</Text>
          </Box>
          {_.size(alts) > 0 && _.map(_.values(alts), (alt, index) => renderAlternate(alt, _.size(alts) - 1 === index))}
        </Box>
      )
    }
    return (
      <Box flex={{ shrink: 0, grow: 1 }} customStyle='position: relative'>
        <Box pad={{ vertical: 'medium', right: 'small', left: 'large' }} border='bottom' gap='small'>
          <Text color={colors.TEXT_PRIMARY} size='small'>
            {_.get(item, 'name')}
          </Text>
          {_.size(_.get(item, 'desc')) > 0 && (
            <Text color={colors.TEXT} size='xsmall'>
              {_.get(item, 'desc')}
            </Text>
          )}
        </Box>
        <Box pad={{ horizontal: 'medium' }} customStyle='position: absolute; top: 0; right: 0;' border={{ side: 'all', color: statusColor }}>
          <Text color={statusColor} size='small'>{required ? 'Required' : 'Optional'}</Text>
        </Box>
        {_.size(alts) > 0 && _.map(_.values(alts), (alt, index) => renderAlternate(alt, _.size(alts) - 1 === index))}
      </Box>
    )
  }

  const renderAlternate = (item, isLast) => {
    return (
      <Box className={isLast ? 'line-last' : 'line'}>
        <Box className='arrow' />
        <Box pad={{ vertical: 'medium', horizontal: 'xlarge' }} border='bottom' gap='small' customStyle='position: relative;'>
          <Box customStyle='position: absolute; top: 2px; left: 25px;'>
            <Text size='small' color={colors.YELLOW}>Alternate</Text>
          </Box>
          <Text color={colors.TEXT_PRIMARY} size='small'>
            {_.get(item, 'name')}
          </Text>
          {_.size(_.get(item, 'desc')) > 0 && (
            <Text color={colors.TEXT} size='xsmall'>
              {_.get(item, 'desc')}
            </Text>
          )}
        </Box>
      </Box>
    )
  }

  const sectionsToRender = _.size(sections) === 0 && _.size(scope) > 0 ? [
    {
      id: lineItemSection.SCOPE_OF_WORK_ID,
      title: 'Scope of Work',
      items: _.keys(scope)
    }
  ] : sections

  const renderScope = () => (
    <Box margin={{ bottom: 'xlarge' }}>
      <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} flex={false}>
        <Text size='large' color={colors.BLACK}>Scope of Work</Text>
      </Box>
      {_.map(sectionsToRender, renderSection)}
    </Box>
  )

  const renderFile = ({ name, id, createdAt, size }) => {
    return (
      <Box key={id} direction='row' justify='between' pad='small' border='bottom'>
        <Box
          pad='medium'
          gap='medium'
          justify='center'
          onClick={() => null}
          width='100%'
          margin={{ right: 'medium' }}
        >
          <Text weight={600} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>{name}</Text>
          <Box direction='row' gap='xlarge' align='center' justify='between' width='100%'>
            <Text size='small' weight={300} color={colors.DARK_GREY_TWO}>{formatBytes(size)}</Text>
            <Text size='small' weight={400} color={colors.DARK_GREY_TWO}>uploaded {moment(createdAt).format('MMM D[,] YY')}</Text>
          </Box>
        </Box>
        <Box direction='row' justify='start' align='center' gap='medium' pad='medium' width='xsmall'>
          <Button
            plain
            color={colors.LIGHT_NAVY_BRIGHT}
            label={<FontAwesomeIcon icon={faCloudDownload} size={20} />}
          />
          <Button
            plain
            color={colors.VERY_LIGHT_PINK}
            label={<FontAwesomeIcon icon={faExternalLink} size={17} />}
          />
        </Box>
      </Box>
    )
  }

  const renderFiles = () => (
    <Box gap='xsmall' margin={{ bottom: 'xlarge' }}>
      <Box pad={{ horizontal: 'small', vertical: 'medium' }} flex={false} border='bottom'>
        <Text size='large' color={colors.BLACK}>Plan Files</Text>
      </Box>
      {_.map(files, renderFile)}
    </Box>
  )

  return (
    <Box flex={{ shrink: 0 }} justify='start' gap='xsmall'>
      {_.size(files) > 0 && renderFiles()}
      {_.size(sectionsToRender) > 0 && renderScope()}
    </Box>
  )
}

export default ScopeAndFiles
