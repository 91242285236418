import React from 'react'
import { Box, Text, Tip } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import numeral from 'numeral'
import { Droppable } from 'react-beautiful-dnd'

import colors from 'shared/constants/colors'

const ProjectBudget = ({ id, bid, removeProjectBudget, accountName, isLast }) => {
  const renderAccountName = name => {
    if (_.size(name) > 18) {
      return (
        <Tip content={accountName}>
          <Text weight='bold' truncate>
            {_.truncate(accountName, { length: 18 })}
          </Text>
        </Tip>
      )
    } else {
      return (
        <Text weight='bold' truncate>
          {accountName}
        </Text>
      )
    }
  }
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => {
        const isDraggingOver = snapshot.isDraggingOver
        let isDropAllowed = false
        if (isDraggingOver) {
          const sourceId = _.get(snapshot, 'draggingOverWith')
          const woId = _.split(sourceId, '_wo_')[1]
          isDropAllowed = _.isEqual(woId, id)
        }
        return (
          <React.Fragment>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Box
                background={isDraggingOver && isDropAllowed ? colors.AQUA_MARINE10 : isDraggingOver ? colors.CORAL_TWO10 : colors.WHITE}
                border={isLast ? ['left', 'right'] : ['left', 'bottom', 'right']}
                fill='vertical'
                align='center'
                justify='center'
              >
                {_.isNil(bid) ? (
                  <Box fill align='center' justify='center'>
                    <Box width='xsmall' pad={{ vertical: 'small' }} border={{ style: 'dashed', color: colors.ANOTHER_GREY }}>
                      <Text size='medium' textAlign='center' color={colors.ANOTHER_GREY} customStyle='cursor: pointer'>
                        Drag here
                      </Text>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    fill
                    customStyle={`
                      position: relative;
                      :hover {
                        > .remove_icon {
                          display: block;
                          top: 5%;
                          right: 5%;
                        }
                      }
                    `}
                    onClick={() => {}}
                    hoverIndicator={isDraggingOver ? { color: null } : { color: colors.WHITE_GREY }}
                  >
                    <Box
                      className='remove_icon'
                      customStyle='position: absolute; display: none;'
                      onClick={() => removeProjectBudget(id)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size={14} color={colors.LIGHT_NAVY_BRIGHT} />
                    </Box>
                    <Box
                      fill='vertical'
                      pad={{ vertical: 'small' }}
                      gap='xsmall'
                      className='bid_wrap'
                      align='center'
                      justify='between'
                    >
                      <Box flex={{ shrink: 0 }}>
                        {renderAccountName(accountName)}
                      </Box>
                      <Box>
                        <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
                          {numeral(bid.total).format('$0,0.00')}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </Box>
            </div>
          </React.Fragment>)
      }}
    </Droppable>
  )
}

export default ProjectBudget
