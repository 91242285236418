import React, { useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Box, DropButton, Text, ThemeContext, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment-timezone'
import _ from 'lodash'
import { DatePicker as RCDatePicker } from 'rc-datepicker'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

import colors from 'shared/constants/colors'
import DropContentContainer from 'components/DropContentContainer.web'

const DatePicker = ({
  pickerRef,
  title,
  value,
  timeZone,
  onChange,
  disabled,
  label,
  onFocus,
  onBlur,
  placeholder,
  type = 'date',
  ...rest
}) => {
  const [opened, _setOpened] = useState(false)

  const setOpened = v => {
    if (v) {
      onFocus()
    } else {
      onBlur()
    }
    _setOpened(v)
  }

  useImperativeHandle(pickerRef, () => ({
    open: () => setOpened(true),
    close: () => setOpened(false)
  }))

  const renderIcon = () => {
    const color = _.isNil(value)
      ? disabled
        ? colors.VERY_LIGHT_PINK
        : colors.ASANA_GRAY_BORDER
      : colors.LIGHT_NAVY_DARK
    return (
      <Box
        id='icon_container'
        round='full'
        width='30px'
        height='30px'
        align='center'
        justify='center'
        margin={{ right: 'small' }}
        border={{ color: color, size: 'xsmall', style: 'dashed', side: 'all' }}
      >
        <FontAwesomeIcon icon={faCalendar} color={color} size={12} />
      </Box>
    )
  }

  const titleColor = _.isNil(value) ? (disabled ? colors.VERY_LIGHT_PINK : colors.ASANA_TEXT) : 'text'

  const renderDate = () => {
    if (!_.isNil(value)) {
      return (
        <Text size='small' alignSelf='start' color={colors.LIGHT_NAVY}>
          {moment.tz(value, timeZone).format(type === 'time' ? 'LT' : 'll')}
        </Text>
      )
    } else if (!_.isEmpty(placeholder)) {
      return <Text color={colors.VERY_LIGHT_PINK}>{placeholder}</Text>
    }
  }

  const btn = (
    <Box
      onClick={() => (disabled ? null : setOpened(true))}
      direction='row'
      alignSelf='start'
      justify='center'
      align='center'
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      flex={{ shrink: 0 }}
      hoverIndicator={{
        color: disabled ? '' : colors.ASANA_GRAY_BACKGROUND
      }}
      customStyle={`
        border-width: 1px;
        border-color: transparent;
        border-style: solid;
        cursor: ${disabled ? 'inherit' : 'pointer'};
        border-radius: 4px;
        ${disabled ? '' : `:hover {
              border-width: 1px;
              border-color: ${colors.ASANA_GRAY_BORDER};
              border-style: solid;

              svg {
                fill: ${colors.AQUA_MARINE};
              };

              #title {
                color: ${colors.LIGHT_NAVY_DARK};
              };

              #icon_container {
                border-style: solid;
                border-color: ${colors.ASANA_GRAY_TEXT_HOVERED};

              }
            }`}
      `}
    >
      {renderIcon()}
      <Box>
        <Text size='small' id='title' color={titleColor}>
          {title}
        </Text>
        {renderDate()}
      </Box>
    </Box>
  )

  const onDateSelected = (v, keepOpen = false) => {
    // console.log('onDateSelected', v)
    if (!keepOpen) {
      setOpened(false)
    }
    // const m = moment.tz(v, timeZone)
    const m = moment.tz(v, timeZone)
    // console.log('onDateSelected m', m.valueOf())
    // const strDate = m.format('YYYY-MM-DD')
    // console.log('strDate', strDate)
    // const mWithTimezone = moment.tz(strDate, timeZone)
    const timestamp = m.valueOf()
    onChange(timestamp)
  }

  const resetDate = () => {
    setOpened(false)
    onChange(null)
  }

  const renderDropContent = () => {
    let date
    if (type === 'time') {
      if (!_.isNil(value)) {
        date = moment.tz(value, timeZone)
      }
      return (
        <DropContentContainer boxProps={{ width: 'fit-content' }}>
          <Datetime
            input={false}
            initialViewMode='time'
            onChange={v => onDateSelected(v.valueOf(), true)}
            initialValue={date}
            open
          />
        </DropContentContainer>
      )
    }
    if (!_.isNil(value)) date = moment.tz(value, timeZone).format('YYYY-MM-DD')
    return (
      <DropContentContainer boxProps={{ width: 'fit-content' }}>
        <RCDatePicker onChange={onDateSelected} value={date} />
        <Box round={{ corner: 'bottom', size: '4px' }} pad='xsmall' align='center' justify='center'>
          <Button plain align='center' onClick={resetDate}>
            <Text color={colors.LIGHT_NAVY} alignSelf='center' textAlign='center'>
              clear
            </Text>
          </Button>
        </Box>
      </DropContentContainer>
    )
  }

  return (
    <ThemeContext.Extend value={{ drop: { background: 'red' } }}>
      <DropButton
        onClose={() => {
          setOpened(false)
        }}
        plain
        open={opened}
        alignSelf='start'
        label={label || btn}
        dropContent={renderDropContent()}
        dropAlign={{ bottom: 'bottom', left: 'left' }}
        disabled={disabled}
        {...rest}
      />
    </ThemeContext.Extend>
  )
}

DatePicker.defaultProps = {
  onBlur: () => null,
  onFocus: () => null
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.number,
  timeZone: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}

export default DatePicker
