import React from 'react'
import { Box, Button, Text } from 'grommet'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEdit, faSlidersHSquare, faUserPlus, faPaperclip, faQuestionCircle, faFileImport } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import DropDownMenu from 'components/DropDownMenu'
import lineItemType from 'shared/constants/lineItemType'

const ScopeOfWorkSubmenu = ({
  addLineItem,
  addQuestion,
  addSection,
  addLineItemFromTemplate,
  onDone,
  openFilesModal,
  addImportedLi,
  importedLi
}) => {
  const addLineItemButton = (
    <Button
      icon={<FontAwesomeIcon icon={faEdit} />}
      label='Add line item'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addLineItem}
    />
  )

  const addQuestionButton = (
    <DropDownMenu
      options={[
        {
          id: 'lineItem_title_id',
          label: 'Question type',
          disabled: true
        },
        {
          id: lineItemType.QUESTION_BOOL,
          label: 'Yes / No response'
        },
        {
          id: lineItemType.QUESTION,
          label: 'Text response'
        }
      ]}
      dropContentProps={{ boxProps: { width: { min: 'small' } } }}
      dropAlign={{ top: 'bottom', left: 'left' }}
      onOptionClick={id => addQuestion(id)}
    >
      <Box direction='row' justify='between' align='center' gap='small'>
        <FontAwesomeIcon icon={faQuestionCircle} color={colors.ASANA_GRAY_TEXT_HOVERED} />
        <Text color={colors.ASANA_GRAY_TEXT_HOVERED}>Add question</Text>
      </Box>
    </DropDownMenu>
  )

  const addSectionButton = (
    <Button
      icon={<FontAwesomeIcon icon={faSlidersHSquare} />}
      label='Add section'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addSection}
    />
  )

  const addFromTemplateButton = (
    <Button
      icon={<FontAwesomeIcon icon={faUserPlus} />}
      label='Add from template'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addLineItemFromTemplate}
    />
  )

  const addImportedLiButton = (
    <Button
      icon={<FontAwesomeIcon icon={faFileImport} />}
      label='Add imported line items'
      color={colors.ASANA_GRAY_TEXT_HOVERED}
      onClick={addImportedLi}
      disabled={_.size(_.get(importedLi, 'files', {})) === 0}
    />
  )

  return (
    <Box
      fill='horizontal'
      direction='row'
      height='40px'
      background={colors.WHITE_GREY}
      align='center'
      justify='between'
      pad={{ left: 'small', right: 'small' }}
    >
      <Box direction='row' align='center' gap='small'>
        {addLineItemButton}
        {addQuestionButton}
        {addSectionButton}
        {addFromTemplateButton}
        {addImportedLiButton}
      </Box>
      <Box direction='row' align='center' gap='large'>
        <Button
          icon={<FontAwesomeIcon icon={faPaperclip} />}
          label='Plan room'
          color={colors.ASANA_GRAY_TEXT_HOVERED}
          onClick={openFilesModal}
          secondary
        />
        <Button label='Done' primary color={colors.AQUA_MARINE} onClick={onDone} />
      </Box>
    </Box>
  )
}

ScopeOfWorkSubmenu.propTypes = {
  addLineItem: PropTypes.func,
  addSection: PropTypes.func,
  addLineItemFromTemplate: PropTypes.func,
  onDone: PropTypes.func,
  addQuestion: PropTypes.func,
  openFilesModal: PropTypes.func
}

export default React.memo(ScopeOfWorkSubmenu)
