import * as types from 'model/actionTypes'

const initialState = {
  values: false,
  compact: false,
  references: false,
  quantities: false,
  declined: true,
  sections: true,
  projectBudget: false
}

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return {
        ...state,
        [action.key]: action.value
      }
    case types.RESET_SETTINGS:
      return initialState
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
