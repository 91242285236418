import React, { Component } from 'react'
import { Layer, Text, Card, CardBody } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'

class ConfirmationModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  renderButtons = () => null

  open = ({ title, renderButtons }) => {
    this.renderButtons = _.isFunction(renderButtons) ? renderButtons : () => null
    this.setState({
      visible: true,
      title
    })
  }

  close = () => {
    this.setState({ visible: false })
  }

  render () {
    const { visible, title } = this.state
    if (visible) {
      return (
        <Layer>
          <Card>
            <CardBody pad={{ horizontal: 'medium', vertical: 'medium' }} width='medium'>
              <Text size='large' margin={{ vertical: 'large' }} color={colors.LIGHT_NAVY_BRIGHT} textAlign='center'>
                {title}
              </Text>
              {this.renderButtons()}
            </CardBody>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

export default ConfirmationModal
