import React, { useCallback } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import lineItemType from 'shared/constants/lineItemType'
import AnswerBool from 'components/leveling/AnswerBool'
import AnswerText from 'components/leveling/AnswerText'
import PriceRequested from 'components/leveling/PriceRequestedCell'
import RequestPrice from 'components/leveling/RequestPrice'
import UnapprovedItemCell from 'components/leveling/UnapprovedItemCell'
import LineItemRegularCell from 'components/leveling/LineItemRegularCell'
import colors from 'shared/constants/colors'

const BidCell = ({
  item,
  scope,
  activeCell,
  inv,
  onAlternateSelected,
  selectedAlternate,
  onNewLineItemAction,
  requestPrice,
  accountsProfiles,
  removalRequests,
  selectCell,
  plug,
  removePriceRequest,
  selected,
  undoMerge,
  hasNewMessage,
  hasMessages,
  workOrderId,
  settings
}) => {
  const { values, compact } = settings
  const itemId = _.get(item, 'id')
  const subId = _.get(inv, 'id')

  const onPriceRequestClick = useCallback(() => {
    requestPrice(subId, itemId)
  }, [subId, itemId, requestPrice])

  const onRemovePriceRequestClick = () => {
    removePriceRequest(inv.id, itemId)
  }

  const _selectCell = useCallback(() => {
    selectCell(subId, itemId)
  }, [selectCell, subId, itemId])

  // const lineItem = _.get(scope, itemId)
  const removalRequest = _.get(removalRequests, itemId)
  const unapproved = _.get(item, 'unapproved', false)
  const cellId = { inv: inv.id, record: itemId }
  const bid = _.get(inv, 'bid')
  const lineItemBid = _.get(bid, ['items', itemId])
  const type = _.get(item, 'type')
  const priceRequested = _.get(item, 'priceRequested')

  const isTest = itemId === 'g1OkbqSakbY1akA5qhrP'

  if (isTest) {
    console.log('inv', inv, 'unapproved', unapproved, 'lineItemBid', lineItemBid)
  }

  if (_.isEmpty(bid)) {
    if (isTest) console.log('return emptybid')
    return <Box />
  } else if (!_.isEmpty(bid) && _.isNil(lineItemBid) && unapproved) {
    if (isTest) console.log('return grey')
    return <Box fill background={colors.PALE_GREY} />
  } else if (type === lineItemType.QUESTION_BOOL) {
    if (isTest) console.log('return AnswerBool')
    return (
      <AnswerBool
        answer={_.get(lineItemBid, 'answer')}
        onClick={_selectCell}
        selected={selected}
        hasNewMessage={hasNewMessage}
        hasMessages={hasMessages}
        compact={compact}
      />
    )
  } else if (type === lineItemType.QUESTION) {
    if (isTest) console.log('return AnswerText')
    return (
      <AnswerText
        answer={_.get(lineItemBid, 'answer')}
        onClick={_selectCell}
        selected={selected}
        hasNewMessage={hasNewMessage}
        hasMessages={hasMessages}
        compact={compact}
      />
    )
  } else if (!_.isEmpty(bid) && _.isEmpty(lineItemBid) && !unapproved && !_.isNil(priceRequested)) {
    const requested = _.includes(priceRequested, inv.id)
    if (requested) {
      if (isTest) console.log('return PriceRequested')
      return (
        <PriceRequested
          onClick={_selectCell}
          removePriceRequest={onRemovePriceRequestClick}
          selected={selected}
          hasNewMessage={hasNewMessage}
          hasMessages={hasMessages}
          compact={compact}
        />
      )
    } else {
      if (isTest) console.log('return RequestPrice')
      return <RequestPrice onPriceRequestClick={onPriceRequestClick} />
    }
  } else if (unapproved) {
    if (isTest) console.log('return UnapprovedItemCell')
    return (
      <UnapprovedItemCell
        lineItemBid={lineItemBid}
        item={item}
        activeCell={activeCell}
        inv={inv}
        selectedAlternate={selectedAlternate}
        onNewLineItemAction={onNewLineItemAction}
        removalRequest={removalRequest}
        onClick={_selectCell}
        id={cellId}
        plug={plug}
        selected={selected}
        hasNewMessage={hasNewMessage}
        hasMessages={hasMessages}
        compact={compact}
      />
    )
  } else {
    if (isTest) console.log('return LineItemRegularCell')
    return (
      <LineItemRegularCell
        lineItemBid={lineItemBid}
        item={item}
        scope={scope}
        inv={inv}
        onAlternateSelected={onAlternateSelected}
        selectedAlternate={selectedAlternate}
        onClick={_selectCell}
        id={cellId}
        plug={plug}
        selected={selected}
        undoMerge={undoMerge}
        hasNewMessage={hasNewMessage}
        hasMessages={hasMessages}
        workOrderId={workOrderId}
        showValues={values}
        compact={compact}
      />
    )
  }
}

BidCell.propTypes = {
  lineItemBid: PropTypes.object,
  record: PropTypes.object,
  scope: PropTypes.object,
  activeCell: PropTypes.string,
  inv: PropTypes.object,
  onAlternateSelected: PropTypes.func,
  selectedAlternate: PropTypes.string,
  onNewLineItemAction: PropTypes.func,
  requestPrice: PropTypes.func,
  removalRequests: PropTypes.object,
  selectCell: PropTypes.func,
  plug: PropTypes.object,
  removePriceRequest: PropTypes.func,
  selected: PropTypes.bool
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles,
  settings: state.customizeSettings
})

export default connect(mapStateToProps)(React.memo(BidCell))
