import React, { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { Box, Text, List, Button } from 'grommet'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircle, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'
import { getName, cutString } from 'shared/utils/stringUtils'
import { getEmailsPhonesString } from 'shared/utils/profile'
import AddUserToCompanyModal from 'webPages/createBid/AddUserToCompanyModal'

const ShareReportWith = ({ account, profiles, userId, sendTo, setSendTo, contacts, setContacts }) => {
  const addUserModalRef = useRef()

  const teammates = useMemo(() => {
    const res = []
    _.forEach(account.admins, (adm, admId) => {
      if (userId !== admId) {
        const p = _.get(profiles, admId)
        if (p) res.push(p)
      }
    })
    return res
  }, [account.admins, profiles, userId])

  // console.log('teammates', teammates)

  const toggleUserId = userId => e => {
    console.log('toggleUserId', userId, e)
    e.stopPropagation()
    const curValue = _.get(sendTo, userId, false)
    setSendTo({ ...sendTo, [userId]: !curValue })
  }

  const renderRadio = userId => {
    const checked = _.get(sendTo, userId, false)
    const size = 24
    const faIcon = checked ? faCheckCircle : faCircle
    return (
      <Box onClick={toggleUserId(userId)} flex={{ shrink: 0 }}>
        <FontAwesomeIcon icon={faIcon} size={size} color={colors.AQUA_MARINE} />
      </Box>
    )
  }

  const renderTeammateRow = p => {
    const desc = getEmailsPhonesString(p)
    return (
      <Box direction='row' align='center' justify='between' pad={{ horizontal: 'medium' }} height='65px'>
        <Box gap='xsmall'>
          <Text size='small' color={colors.TEXT_PRIMARY}>
            {getName(p)}
          </Text>
          <Text color={colors.VERY_LIGHT_PINK} size='small'>
            {cutString(desc, 52)}
          </Text>
        </Box>
        {renderRadio(_.get(p, 'id'))}
      </Box>
    )
  }

  const openAddUserModal = () => {
    addUserModalRef.current.open()
  }

  const addDestinationButton = (
    <Box direction='row' pad={{ left: 'small' }}>
      <Button label='Add someone else...' icon={<FontAwesomeIcon icon={faPlus} />} onClick={openAddUserModal} />
    </Box>
  )

  const onAddUser = p => {
    setContacts({ ...contacts, [p.id]: p })
  }

  const onRemoveUser = uid => {
    console.log('onRemoveUser', uid)
  }

  const data = [...teammates, ..._.values(contacts)]

  return (
    <Box flex={{ shrink: 0 }} gap='xsmall'>
      <Box pad={{ left: 'medium' }}>
        <Text color={colors.ASANA_GRAY_TEXT_HOVERED}>Share report with</Text>
      </Box>
      <List data={data} children={renderTeammateRow} pad='none' />
      {addDestinationButton}
      <AddUserToCompanyModal
        ref={addUserModalRef}
        onUserCreated={onAddUser}
        removeUserFromSentTo={onRemoveUser}
        title='Contact'
        buttonLabel='Save'
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  profiles: state.profiles,
  userId: _.get(state, 'user.id')
})

export default connect(mapStateToProps)(ShareReportWith)
