import React, { Component } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  FormField,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ThemeContext
} from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import generate from 'firebase-auto-ids'

import colors from 'shared/constants/colors'
import { getAddress } from 'shared/utils/stringUtils'
import TradesSelect from 'webPages/bidInviteModal/TradeSelect'
import BidInviteFiles from 'webPages/bidInviteModal/BidInviteFiles'
import DatePicker from 'components/DatePicker'
// import jobWalkDateTypes from 'shared/constants/jobWalkDateTypes'
import { saveBidInvite, updateBidInvite, changeArchiveWorkOrderStatus } from 'controllers/workOrder'
import { getTradesDict } from 'model/selectors/references'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const DEFAULT_STATE = {
  visible: false,
  tradeId: null,
  files: {},
  workOrderId: null,
  bidsDueDate: null,
  startDate: null,
  rfiDueDate: null,
  jobWalkStartDate: null,
  label: '',
  update: false
}

class BidInviteModal extends Component {
  constructor (props) {
    super(props)
    this.state = DEFAULT_STATE
  }

  setFiles = files => {
    console.log('setFiles', files)
    this.setState({ files })
  }

  open = ({ projectId, workOrderId }) => {
    const { workOrders } = this.props
    const wo = _.get(workOrders, workOrderId, {})
    const update = !!workOrderId
    console.log('open, projectId, workOrderId, rest', projectId, workOrderId)
    this.setState({
      visible: true,
      projectId,
      workOrderId: workOrderId || generate(_.now()),
      update,
      ...wo
    })
  }

  close = () => {
    this.setState(DEFAULT_STATE)
  }

  onSelectTradeId = tradeId => this.setState({ tradeId })

  onChange = kind => v => {
    this.setState({ [kind]: v })
  }

  saveWorkOrder = async () => {
    const { user } = this.props
    const { workOrderId, projectId, tradeId, files, bidsDueDate, startDate, rfiDueDate, label, update } = this.state
    const params = {
      id: workOrderId,
      projectId,
      tradeId,
      files,
      bidsDueDate,
      startDate,
      rfiDueDate,
      teamLeadId: _.get(user, 'id', null)
    }
    params.label = label === '' ? null : label
    // console.log('save bid invite', workOrderId)
    this.close()
    if (update) {
      await updateBidInvite(params)
    } else {
      await saveBidInvite(params)
      universalNavigation.push(screens.GC_PROJECT_WORKORDER_LEVELING, { projectId, workOrderId })
    }
  }

  handleArchiveClick = () => {
    const { workOrderId, deleted } = this.state
    changeArchiveWorkOrderStatus(workOrderId, deleted === 0)
    this.close()
  }

  render () {
    const { projects, contractorTypes } = this.props
    const {
      visible,
      projectId,
      tradeId,
      files,
      workOrderId,
      bidsDueDate,
      startDate,
      rfiDueDate,
      jobWalkStartDate,
      label,
      update,
      deleted
    } = this.state
    const project = _.get(projects, projectId)
    const address = _.get(project, 'address')
    // console.log('project address', address)
    const timeZone = _.get(address, 'timeZone')

    let tradeName = _.has(contractorTypes, tradeId) ? ` - ${_.get(contractorTypes, [tradeId, 'name'])}` : ''
    if (!_.isEmpty(label)) tradeName = ` - ${label}`

    const disabled = _.isNil(tradeId)
    // console.log('projectAddress', address, 'timeZone', timeZone)
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close} background={colors.WHITE}>
          <Card>
            <CardHeader
              pad='medium'
              border={{
                color: 'border',
                size: '1px',
                side: 'bottom'
              }}
            >
              <Text size='xlarge' color={colors.BLACK} weight={600}>
                {!update && 'Add bid invitation - '}
                {`${getAddress(address)}`}
              </Text>
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium' }} overflow='auto' wrap={false}>
              <Box flex={false} direction='row' margin={{ top: 'medium' }}>
                <TradesSelect tradeId={tradeId} onSelect={this.onSelectTradeId} disabled={update} />
                <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
                  <FormField
                    label='Label'
                    margin={{ left: 'small' }}
                    width='medium'
                    round='small'
                    info={
                      _.size(label) >= 50 ? (
                        <Text size='small' color={colors.ANOTHER_GREY}>
                          Maximum 50 character
                        </Text>
                      ) : (
                        undefined
                      )
                    }
                  >
                    <TextInput
                      placeholder='Label this bid invite (optional)'
                      color='dark-1'
                      maxLength={50}
                      value={label}
                      onChange={e => this.onChange('label')(e.target.value)}
                    />
                  </FormField>
                </ThemeContext.Extend>
              </Box>

              <Text size='small' margin={{ top: 'medium' }} color={colors.BROWN_GREY_TWO}>
                {`Files${tradeName}`}
              </Text>

              <BidInviteFiles project={project} files={files} setFiles={this.setFiles} workOrderId={workOrderId} />

              <Text size='small' margin={{ top: 'large' }} color={colors.BROWN_GREY_TWO}>
                {`Important dates${tradeName}`}
              </Text>

              <Box direction='row' margin={{ vertical: 'small' }} height={{ min: 'xxsmall' }}>
                <Box flex>
                  <DatePicker
                    title='Job Walk Date'
                    value={jobWalkStartDate}
                    timeZone={timeZone}
                    onChange={this.onChange('jobWalkStartDate')}
                    disabled
                  />
                </Box>
                <Box flex>
                  <DatePicker
                    title='RFI Due Date'
                    value={rfiDueDate}
                    timeZone={timeZone}
                    onChange={this.onChange('rfiDueDate')}
                  />
                </Box>
              </Box>
              <Box direction='row' margin={{ bottom: 'medium' }} height={{ min: 'xxsmall' }}>
                <Box flex>
                  <DatePicker
                    title='Bids Due Date'
                    value={bidsDueDate}
                    timeZone={timeZone}
                    onChange={this.onChange('bidsDueDate')}
                  />
                </Box>
                <Box flex>
                  <DatePicker
                    title='Expected Start Date'
                    value={startDate}
                    timeZone={timeZone}
                    onChange={this.onChange('startDate')}
                  />
                </Box>
              </Box>
            </CardBody>
            <CardFooter align='center'>
              <Box direction='row' justify='between' fill='horizontal' pad={{ right: 'medium', left: 'large' }}>
                <Box justify='center'>
                  {update && (
                    <Button
                      color={colors.CORAL_TWO}
                      label={deleted > 0 ? 'Unarchive' : 'Archive'}
                      onClick={this.handleArchiveClick}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    primary
                    margin='medium'
                    label={update ? 'Save' : 'Create'}
                    onClick={this.saveWorkOrder}
                    customStyle='border-radius: 4px'
                    disabled={disabled}
                  />
                </Box>
              </Box>
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  contractorTypes: getTradesDict(state),
  workOrders: state.workOrders,
  user: state.user
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(BidInviteModal)
