import React from 'react'
import { Box } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faProjectDiagram, faAngleDown } from '@fortawesome/pro-light-svg-icons'
// import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { faProjectDiagram as faProjectDiagramSolid } from '@fortawesome/pro-solid-svg-icons'

import colors from 'shared/constants/colors'

const AlternatesIcon = ({ active, compact }) => {
  const color = active ? colors.LIGHT_NAVY_BRIGHT : colors.DARK_GRAY_TWO
  return (
    <Box direction='row' fill='vertical' align='center' justify='start' flex={false} pad={{ top: 'xxsmall' }}>
      <FontAwesomeIcon icon={active ? faProjectDiagramSolid : faProjectDiagram} size={compact ? 16 : 14} color={color} />
      <FontAwesomeIcon icon={faAngleDown} size={compact ? 18 : 16} color={color} />
    </Box>
  )
}

export default React.memo(AlternatesIcon)
