// import 'react-hot-loader'
import React, { Component } from 'react'
// import { hot } from 'react-hot-loader/root'
import { AppState, Linking, StatusBar } from 'react-native'
import { Provider } from 'react-redux'
import { AppLoading } from 'expo'
import * as Notifications from 'expo-notifications'
import * as SplashScreen from 'expo-splash-screen'
import * as Permissions from 'expo-permissions'
import { Asset } from 'expo-asset'
import * as Font from 'expo-font'
import Sentry from 'shared/utils/sentry'
import { Grommet } from 'grommet'
import './style.css'
import _ from 'lodash'
import '@stripe/stripe-js'

import store from 'model/store'
import { updateKeyboardHeight } from 'model/actions/viewportAC'
import { appInitialized } from 'controllers/init'
import { enableUninstallTracking, trackAppLaunch, trackDeepLinkUrl } from 'utils/analytics'
import config from 'shared/config'
import { DevIndicator, DevIndicatorContainer, StyledText } from 'shared/components/StyledComponents'
import lightNavyTheme from 'shared/themes/lightNavyTheme'
import AppRouter from 'navigation/AppRouter'
import HtmlHead from 'webPages/HtmlHead'
// import GoogleFontsLoader from 'shared/GoogleFontsLoader'

if (!config.isDev) {
  Sentry.init({
    dsn: config.sentryHICWebPublicDSN,
    debug: true,
    enableInExpoDevelopment: false,
    release: config.internalVersion,
    environment: config.isDev ? 'dev' : 'prod'
  })
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
})

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appState: AppState.currentState,
      currentLocale: null,
      isReady: false,
      googleFontsLoaded: true
    }
  }

  notificationResponseReceived = notification => {
    console.log('notificationResponseReceived', notification)
    // const { origin } = notification
    // if (origin === 'selected') {
    //   const {
    //     data: { isChatNotification, projectId, subId }
    //   } = notification
    //   if (isChatNotification) {
    //     return universalNavigation.push(screens.CHAT, { projectId, subId })
    //   }
    // }
  }

  componentDidMount = async () => {
    // this._responseListener = Notifications.addNotificationReceivedListener(this.handleNotificationReceived)
    this._notificationSubscription = Notifications.addNotificationResponseReceivedListener(
      this.notificationResponseReceived
    )
    try {
      await SplashScreen.preventAutoHideAsync()
    } catch (e) {
      console.warn(e)
      Sentry.captureException(e)
    }
    // this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.keyboardDidShow)
    // this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.keyboardDidHide)
    // this.dimensionsListener = Dimensions.addEventListener('change', () => {
    //   // const { viewport: prevViewport } = store.getState()
    //   store.dispatch(updateViewport())
    //   // const { viewport, user } = store.getState()
    //   // if (isMobileWeb(prevViewport) && !isMobileWeb(viewport)) {
    //   //   const route = user ? routes.HOME : routes.ENTER_PHONE
    //   //   history.push(route)
    //   // } else if (!isMobileWeb(prevViewport) && isMobileWeb(viewport)) {
    //   //   history.push(routes.WEB_MOBILE_PLACEHOLDER)
    //   // }
    // })
    this.appStateListener = AppState.addEventListener('change', this.appStateChanged)
    StatusBar.setBarStyle('light-content')

    Linking.getInitialURL().then(url => {
      console.log('initial url', url)
      trackDeepLinkUrl(url)
    })

    enableUninstallTracking()
  }

  loadResourcesAsync = async () => {
    return Promise.all([
      Asset.loadAsync([
        require('./assets/drawer/profile.png'),
        require('./assets/drawer/security.png'),
        require('./assets/drawer/work-info.png'),
        require('./assets/drawer/bank.png'),
        require('./assets/drawer/rewards.png'),
        require('./assets/drawer/help.png'),
        require('./assets/images/create-group.png'),
        require('./assets/images/mason-logo.png'),
        require('./assets/images/search.png'),
        require('./assets/images/logo.png')
        // require('./assets/images/check_progress.png'),
        // require('./assets/icons/green-checkbox.png'),
        // require('./assets/icons/immediate.png'),
        // require('./assets/icons/error-exclamation.png'),
        // require('./assets/icons/yellow-verifying.png'),
        // require('./assets/images/iconfinder-gift.png'),
        // require('./assets/images/maxed-out-icon.png')
      ]),
      Font.loadAsync({
        'CircularStd-Bold': require('assets/fonts/CircularStd-Bold.ttf'),
        'CircularStd-Book': require('assets/fonts/CircularStd-Book.ttf'),
        'CircularStd-BookItalic': require('assets/fonts/CircularStd-BookItalic.ttf'),
        'CircularStd-Medium': require('assets/fonts/CircularStd-Medium.ttf'),
        'Lato-Light': require('assets/fonts/Lato-Light.ttf'),
        'Lato-Bold': require('assets/fonts/Lato-Bold.ttf'),
        'Tondo-Bold': require('assets/fonts/Tondo-Bold.ttf'),
        Roboto: require('assets/fonts/Roboto.ttf'),
        DejaVuSerif: require('assets/fonts/DejaVuSerif.ttf'),
        'DejaVuSerif-Bold': require('assets/fonts/DejaVuSerif-Bold.ttf'),
        // 'DejaVu': require('assets/fonts/DejaVuSans.ttf'),
        // 'DejaVu-Bold': require('assets/fonts/DejaVuSans-Bold.ttf'),
        DejaVu: require('assets/fonts/freeserif.ttf'),
        'DejaVu-Bold': require('assets/fonts/freeserifbold.ttf'),
        entypo: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf'),
        MaterialCommunityIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        Foundation: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Foundation.ttf'),
        Feather: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf')
      })
    ])
  }

  keyboardDidShow = e => {
    store.dispatch(updateKeyboardHeight(e.endCoordinates.height))
  }

  keyboardDidHide = () => {
    store.dispatch(updateKeyboardHeight(0))
  }

  checkPermission = async permission => {
    const { status } = await Permissions.getAsync(permission)
    console.log('receive', permission, status)
    // store.dispatch(receivePermission(permission, status))
  }

  async updatePermissions () {
    // await this.checkPermission(Permissions.LOCATION)
    await this.checkPermission(Permissions.NOTIFICATIONS)
  }

  appStateChanged = async nextAppState => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      try {
        trackAppLaunch()
        await this.updatePermissions()
      } catch (e) {
        console.log('something went wrong', e)
        Sentry.captureException(e)
      }
    }
    this.setState({ appState: nextAppState })
  }

  componentWillUnmount () {
    // this.keyboardDidShowListener.remove()
    // this.keyboardDidHideListener.remove()
    if (_.has(this.dimensionsListener, 'remove')) this.dimensionsListener.remove()
    if (_.has(this.appStateChanged, 'remove')) this.appStateChanged.remove()
    if (_.has(this, 'unsubscribeFromMessageListener')) this.unsubscribeFromMessageListener()
    if (_.has(this, 'unsubscribeFromLinkListener')) this.unsubscribeFromLinkListener()
    Notifications.removeNotificationSubscription(this._notificationSubscription)
  }

  renderDevIndicator = () => {
    if (config.isDev) {
      return (
        <DevIndicatorContainer>
          <DevIndicator />
          <StyledText color='red' fontSize={14}>{`dev: ${config.internalVersion}`}</StyledText>
        </DevIndicatorContainer>
      )
    }
  }

  onLoadingDone = () => {
    this.setState({ isReady: true })
    store.dispatch(appInitialized())
  }

  renderContent = () => {
    const { isReady, googleFontsLoaded } = this.state
    if (!isReady || !googleFontsLoaded) {
      return (
        <AppLoading
          startAsync={this.loadResourcesAsync}
          onFinish={this.onLoadingDone}
          onError={console.warn}
          autoHideSplash={false}
        />
      )
    }
    return (
      <Provider store={store}>
        <Grommet theme={lightNavyTheme} full>
          <AppRouter />
          {this.renderDevIndicator()}
        </Grommet>
      </Provider>
    )
  }

  onGoogleFontsLoaded = () => {
    this.setState({ googleFontsLoaded: true })
  }

  render = () => {
    return (
      <React.Fragment>
        {this.renderContent()}
        <HtmlHead />
        {/* <GoogleFontsLoader onLoaded={this.onGoogleFontsLoaded} /> */}
      </React.Fragment>
    )
  }
}

// export default hot(App)
export default App
