import React, { useMemo } from 'react'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import HeaderFirstCell from 'webPages/leveling/levelingTable/HeaderFirstCell'
import levelingTableMode from 'constants/levelingTableMode'
import CellTitle from 'components/leveling/CellTitle'
import InviteSubDrop from 'components/workOrder/InviteSubDrop'
import lineItemSection from 'shared/constants/lineItemSection'
import CellContainer from 'components/leveling/CellContainer'

const TableContainer = styled.div`
  flex: 1;

  div:first-child {
    position: sticky;
    top: 0;
    z-index: 5;
  }
`
const TableRow = styled.div`
  display: grid;
  height: 69px;
  grid-template-columns: ${props => props.gridTemplateColumns};
  ${props => props.customStyle};
  div {
    z-index: 1;
  }
  div:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`

const GeneralExclusionsTable = ({
  scope,
  invitations,
  workOrder,
  selectedAlternates,
  accountsProfiles,
  selectedCell,
  openSubDetails
}) => {
  console.log('GeneralExclusionsTable scope', scope)
  console.log('GeneralExclusionsTable invitations', invitations)

  const workOrderId = _.get(workOrder, 'id')

  const gridTemplateColumns = useMemo(() => {
    const bidsArray = _.fill(Array(_.size(invitations) + 1), '224px')
    return `400px ${bidsArray.join(' ')}`
  }, [invitations])

  const items = useMemo(() => {
    const res = {}
    _.forEach(invitations, inv => {
      const sections = _.get(inv, 'bid.sections', [])
      const s = _.find(sections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
      const sItemsIds = _.get(s, 'items', [])
      _.forEach(sItemsIds, itemId => {
        let itemName = _.get(scope, [itemId, 'name'], false)
        if (!itemName) {
          const item = _.get(inv, ['bid', 'items', itemId])
          if (_.isEmpty(item)) {
            return true
          } else {
            itemName = _.get(item, ['name'])
          }
        }
        _.set(res, [itemId, 'name'], itemName)
        _.set(res, [itemId, 'subs', inv.id], true)
      })
    })
    return res
  }, [invitations, scope])

  console.log('items', items)

  const tableHeader = (
    <TableRow gridTemplateColumns={gridTemplateColumns} isHeader>
      <Box border={['bottom', 'right']}>
        <HeaderFirstCell mode={levelingTableMode.GENERAL_EXLUSIONS} />
      </Box>
      {_.map(invitations, inv => {
        return (
          <Box
            border={['bottom', 'right']}
            key={inv.id}
            hoverIndicator={{ color: colors.TABLE_HOVER_SOLID, opacity: 1 }}
            background={colors.WHITE}
          >
            <CellTitle
              key={inv.id}
              inv={inv}
              workOrder={workOrder}
              scope={scope}
              selectedAlternates={selectedAlternates}
              accountsProfiles={accountsProfiles}
              selectedCell={selectedCell}
              openSubDetails={openSubDetails}
            />
          </Box>
        )
      })}
      <Box border={['bottom', 'right']}>
        <InviteSubDrop
          key='inviteSubDrop'
          dropButtonLabel={
            <Box fill pad='none' align='center' justify='center'>
              <FontAwesomeIcon icon={faPlus} color={colors.LIGHT_NAVY} size={20} />
            </Box>
          }
          invitations={invitations}
          workOrderId={workOrderId}
          dropButtonProps={{ fill: true, round: 'none' }}
        />
      </Box>
    </TableRow>
  )

  return (
    <Box fill>
      <TableContainer>
        {tableHeader}
        {_.map(items, (item, itemId) => {
          return (
            <TableRow key={itemId} gridTemplateColumns={gridTemplateColumns}>
              <CellContainer>
                <Box flex justify='center' pad={{ left: 'small' }} background={colors.WHITE}>
                  <Text>{item.name}</Text>
                </Box>
              </CellContainer>
              {_.map(invitations, inv => {
                if (_.has(item, ['subs', inv.id])) {
                  return (
                    <CellContainer key={`${itemId}_${inv.id}`}>
                      <Box flex key={`${itemId}_${inv.id}`} background={colors.WHITE} align='center' justify='center'>
                        <Text color={colors.CORAL_TWO}>Excluded</Text>
                      </Box>
                    </CellContainer>
                  )
                } else {
                  return <Box key={`${itemId}_${inv.id}`} background={colors.PALE_GREY} border={['bottom', 'right']} />
                }
              })}
              <Box />
            </TableRow>
          )
        })}
      </TableContainer>
    </Box>
  )
}

export default GeneralExclusionsTable
