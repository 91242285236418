import * as Contacts from 'expo-contacts'
import * as Permissions from 'expo-permissions'
import Sentry from 'shared/utils/sentry'
import _ from 'lodash'
import generate from 'firebase-auto-ids'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { addListener } from 'controllers/listeners'
import { receiveContacts } from 'model/actions/contactsAC'
import { db, fieldValue, generateId } from 'constants/firebase'
import store from 'model/store'
import validator from 'validator'

export async function getPermission (permission) {
  const { status: existingStatus } = await Permissions.getAsync(permission)
  console.log('getPermission', permission, existingStatus)
  let finalStatus = existingStatus
  if (existingStatus !== 'granted') {
    const { status } = await Permissions.askAsync(permission)
    finalStatus = status
  }
  const res = finalStatus === 'granted'
  console.log('has permission', res)
  return res
}

function parsePhones (c) {
  const phones = []
  const contactPhones = _.get(c, 'phoneNumbers', [])
  for (const cPhone of contactPhones) {
    const countryCode = _.get(cPhone, 'countryCode', '')
    const phoneRaw = _.get(cPhone, 'digits', '') || _.get(cPhone, 'number', '')
    const phoneNumber = parsePhoneNumberFromString(phoneRaw, _.upperCase(countryCode))
    if (phoneNumber && phoneNumber.isValid()) {
      phones.push(phoneNumber.number)
    }
  }
  return phones
}

export async function getDeviceContacts () {
  try {
    const hasPermission = await getPermission(Permissions.CONTACTS)
    if (hasPermission) {
      const contactsRes = await Contacts.getContactsAsync()
      // console.log('getDeviceContacts', contactsRes)
      const dataRaw = _.get(contactsRes, 'data', [])
      const data = {}
      // console.log('contacts dataRaw', dataRaw)
      for (const c of dataRaw) {
        const id = generate(_.now())
        const hasFirstName = _.has(c, 'firstName')
        const hasLastName = _.has(c, 'lastName')
        const hasName = _.has(c, 'name')
        console.log('name', c.name, 'company', c.company, 'contactType', c.contactType)
        const phones = parsePhones(c)
        if (((hasFirstName && hasLastName) || hasName) && phones.length > 0) {
          const contact = {
            id: id,
            email: _.get(c, ['emails', 0, 'email'], null),
            emails: _.map(c.emails, emailInfo => emailInfo.email),
            phone: phones[0],
            phones,
            company: _.get(c, 'company', null)
          }
          if (hasFirstName) contact.firstName = _.get(c, 'firstName')
          if (hasLastName) contact.lastName = _.get(c, 'lastName')
          if (hasName) contact.name = _.get(c, 'name')
          if (_.get(c, 'imageAvailable', false)) {
            contact.rawImage = _.get(c, 'rawImage', null)
            contact.image = _.get(c, 'image', null)
          }
          // const aInfo = _.get(c, ['addresses', 0])
          // if (aInfo) {
          //   const city = _.get(aInfo, 'city', null)
          //   const street = _.get(aInfo, 'street', null)
          //   const stateAbbr = _.get(aInfo, 'region', null)
          //   const zipcode = _.get(aInfo, 'postalCode', null)
          //   const country = _.get(aInfo, 'country', null)
          //   const address = {
          //     city,
          //     description: `${street || ''}, ${city || ''}, ${stateAbbr || ''} ${zipcode || null}, ${country || null}`,
          //     name: street,
          //     stateAbbr,
          //     zipcode,
          //     structured: {
          //       main: street,
          //       secondary: `${city || ''}, ${stateAbbr || ''}, ${country || ''}`
          //     }
          //   }
          //   contact.address = address
          // }
          _.set(data, contact.id, contact)
        } else {
          // console.log('skip contact', c, 'hasFirstName', hasFirstName, 'hasLastName', hasLastName, 'hasPhone', hasPhone)
        }
      }
      return data
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log(e)
    return {}
  }
}

export const fetchContacts = accountId => {
  const unsubscribe = db
    .collection('accountsContacts')
    .doc(accountId)
    .onSnapshot(
      contactsSN => {
        store.dispatch(receiveContacts(contactsSN.data() || {}))
      },
      err => {
        console.log('fetchContacts onSnapshot error: ', err)
        Sentry.captureException(err)
      }
    )
  addListener('contacts', unsubscribe)
}

const initContacts = async accountId => {
  await db
    .collection('accountsContacts')
    .doc(accountId)
    .set({ contacts: {}, companies: {} }, { merge: true })
}

export const updateAccountContact = async p => {
  const state = store.getState()
  const isSet = _.isEmpty(state.contacts)
  const accountId = _.get(state, 'account.id')
  if (isSet) {
    await initContacts(accountId)
  }
  const upd = {
    [`contacts.${p.id}`]: p
  }
  console.log('update account contacts', upd)

  db.collection('accountsContacts')
    .doc(accountId)
    .update(upd)
}

export const deleteAccountContact = contactId => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    [`contacts.${contactId}`]: fieldValue.delete()
  }
  console.log('update account contacts', upd)
  db.collection('accountsContacts')
    .doc(accountId)
    .update(upd)
}

export const updateAccountContactCompany = async (companyId, params) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const isSet = _.isEmpty(state.contacts)
  params = _.omitBy(params, _.isNil)
  if (!_.isEmpty(params)) {
    if (isSet) {
      await initContacts(accountId)
    }
    const upd = {
      [`companies.${companyId}`]: params
    }
    console.log('update account contacts', upd)
    db.collection('accountsContacts')
      .doc(accountId)
      .update(upd)
  }
}

export const deleteAccountContacts = contactIds => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  let upd = {}
  _.map(contactIds, contactId => {
    _.set(upd, [`contacts.${contactId}`], fieldValue.delete())
  })
  db.collection('accountsContacts')
    .doc(accountId)
    .update(upd)
}

// export const updateAccountContacts = async (companyId, contactIds, tags) => {
//   const state = store.getState()
//   const accountId = _.get(state, 'account.id')
//   const isSet = _.isEmpty(state.contacts)
//   if (isSet) {
//     await initContacts(accountId)
//   }
//   let upd = {}
//   _.map(contactIds, contactId => {
//     _.set(upd, [`contacts.${contactId}.companyId`], companyId)
//     _.set(upd, [`contacts.${contactId}.tags`], tags)
//   })
//   db.collection('accountsContacts')
//     .doc(accountId)
//     .update(upd)
//   updateTagBin(tags)
// }

export const updateAccountContacts = async (contactIds, tags) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const isSet = _.isEmpty(state.contacts)
  if (isSet) {
    await initContacts(accountId)
  }
  let upd = {}
  _.map(contactIds, contactId => {
    _.set(upd, [`contacts.${contactId}.tags`], tags)
  })
  db.collection('accountsContacts')
    .doc(accountId)
    .update(upd)
  updateTagBin(tags)
}

const isValidPhone = phone => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  if (phoneNumber) {
    return phoneNumber.isValid()
  } else {
    return false
  }
}

const exploreContact = contact => {
  const { contactName, companyName, extraData } = contact
  let emails = []
  let phones = []
  let tags = []
  _.map(extraData, exd => {
    if (validator.isEmail(exd)) {
      emails.push(exd)
    } else if (isValidPhone(exd)) {
      phones.push(exd)
    } else {
      tags.push(exd)
    }
  })
  return {
    contactName,
    companyName,
    emails: _.uniqBy(emails),
    phones: _.uniqBy(phones),
    tags: _.uniqBy(tags)
  }
}

export const addNewContacts = async (companies, contacts) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const upd = {
    companies: { ..._.get(state, 'contacts.companies', {}), ...companies },
    contacts: { ..._.get(state, 'contacts.contacts', {}), ...contacts }
  }
  console.log('addNewContacts', upd)
  await db
    .collection('accountsContacts')
    .doc(accountId)
    .set(upd)
  // _.map(contacts, async contact => {
  //   const extraData = _.get(contact, 'extraData', [])
  //   const _contact = !_.isEmpty(extraData) ? exploreContact(contact) : contact
  //   const existingCompanyId = _.get(_contact, 'companyId', '')
  //   const contactId = await generateId()
  //   const companyId = await generateId()
  //   let upd = {}
  //   if (_.isEmpty(existingCompanyId)) {
  //     _.set(upd, [`companies.${companyId}`], {
  //       name: _.get(_contact, 'companyName', '')
  //     })
  //   }
  //   _.set(upd, [`contacts.${contactId}`], {
  //     id: contactId,
  //     companyId: _.isEmpty(existingCompanyId) ? companyId : existingCompanyId,
  //     name: _.get(_contact, 'contactName', ''),
  //     ..._.pick(_contact, ['emails', 'phones', 'tags'])
  //   })
  //   db.collection('accountsContacts')
  //     .doc(accountId)
  //     .update(upd)
  //   updateTagBin(_.get(_contact, 'tags', []))
  // })
}

export const updateContact = async (contactId, params) => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  let upd = {}
  if (_.get(params, 'name', null)) {
    _.set(upd, [`contacts.${contactId}.name`], params.name)
  }
  if (_.get(params, 'emails', null)) {
    _.set(upd, [`contacts.${contactId}.emails`], params.emails)
  }
  if (_.get(params, 'phones', null)) {
    _.set(upd, [`contacts.${contactId}.phones`], params.phones)
  }
  if (_.get(params, 'tags', null)) {
    _.set(upd, [`contacts.${contactId}.tags`], params.tags)
    updateTagBin(params.tags)
  }
  await db
    .collection('accountsContacts')
    .doc(accountId)
    .update(upd)
}

export const editContactRow = async contact => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const companyId = await generateId()
  const existingCompanyId = _.get(contact, 'companyId', '')
  let upd = {}
  if (_.isEmpty(existingCompanyId)) {
    _.set(upd, [`companies.${companyId}`], {
      name: _.get(contact, 'companyName', '')
    })
  }
  _.set(upd, [`contacts.${_.get(contact, 'contactId')}`], {
    id: _.get(contact, 'contactId'),
    companyId: _.isEmpty(existingCompanyId) ? companyId : existingCompanyId,
    name: _.get(contact, 'contactName', ''),
    ..._.pick(contact, ['emails', 'phones', 'tags'])
  })
  await db
    .collection('accountsContacts')
    .doc(accountId)
    .update(upd)
  updateTagBin(_.get(contact, 'tags', []))
}

const updateTagBin = async tags => {
  const state = store.getState()
  const accountId = _.get(state, 'account.id')
  const existingTags = _.get(state.contacts, 'tags', {})
  let upd = {}
  if (_.isEmpty(existingTags)) {
    _.set(upd, ['tags'], tags)
  } else {
    _.set(upd, ['tags'], _.uniqBy([...existingTags, ...tags]))
  }
  await db
    .collection('accountsContacts')
    .doc(accountId)
    .update(upd)
}
