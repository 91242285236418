import React, { useState } from 'react'
import { Button, DropButton } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEllipsisV, faTimes } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import DropContent from 'components/DropContent'
import { removeInvite } from 'controllers/auth'

const menuActionId = {
  REMOVE_USER: 'REMOVE_USER'
}

const ActionButton = ({ user, setRemovingUser, currentUser, canManageUsers }) => {
  const [open, setOpen] = useState(false)
  const handleAction = async (actionId) => {
    setOpen(false)
    switch (actionId) {
      case menuActionId.REMOVE_USER: {
        setRemovingUser(user)
        break
      }
      default: break
    }
  }

  if (user.invited) {
    return (
      <Button
        plain
        label={<FontAwesomeIcon icon={faTimes} size={18} color={colors.MEDIUM_GREY} />}
        onClick={() => removeInvite(user.inviteId)}
      />
    )
  } else {
    return (
      <DropButton
        plain
        label={<FontAwesomeIcon icon={faEllipsisV} size={18} color={colors.MEDIUM_GREY} />}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        dropContent={
          <DropContent
            onOptionClick={actionId => handleAction(actionId)}
            options={[{
              id: menuActionId.REMOVE_USER,
              label: 'Remove user',
              disabled: _.isEqual(user.id, currentUser.id) || _.get(user, 'role') === 'owner' || !canManageUsers
            }]}
            boxProps={{ width: '140px' }}
            optionLabelProps={{ textAlign: 'start' }}
          />
        }
        dropProps={{ align: { top: 'bottom' } }}
      />
    )
  }
}

export default ActionButton
