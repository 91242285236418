import React, { useState, useEffect } from 'react'
import { Box, TextInput, FormField, ThemeContext, Text, Button } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import { setOnboardingComplete } from 'controllers/onboarding'
import { navigateToHomePage } from 'controllers/init'
import { updateAdditionalInfo } from 'controllers/auth'
import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'
import { getCurrentAccountProfile, getCurrentUserProfile } from 'model/selectors/profiles'
import { getAddress } from 'shared/utils/stringUtils'
import { trackOnboardingStarted } from 'controllers/zapier'
import { getCanEditCompanySettings } from 'model/selectors/permissionsSelector'
import AddressAutocompleteGrommet from 'components/AddressAutocompleteGrommet'

const AdditionalInfo = ({ dispatch, accountProfile, userProfile, accountId, canEditCompanySettings }) => {
  const [userName, setUserName] = useState(_.get(userProfile, 'name', ''))
  const [accountName, setAccountName] = useState(_.get(accountProfile, 'name', ''))
  const [address, setAddress] = useState(null)
  // const [addressFocused, setAddressFocused] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    dispatch(trackOnboardingStarted(userProfile, accountId, accountProfile))
  }, [])

  useEffect(() => {
    setAccountName(_.get(accountProfile, 'name', ''))
    setAddress(_.get(accountProfile, 'address'))
  }, [accountProfile])

  useEffect(() => {
    setUserName(_.get(userProfile, 'name', ''))
  }, [userProfile])

  const handleSubmit = () => {
    const curErrors = {}
    if (_.isEmpty(userName)) curErrors.userName = 'Enter your name'
    if (_.isEmpty(accountName)) curErrors.accountName = 'Enter company name'
    if (!_.has(address, 'timeZone')) curErrors.address = 'Enter a valid company address'
    if (_.isEmpty(curErrors)) {
      dispatch(updateAdditionalInfo(userName, accountName, address))
      dispatch(setOnboardingComplete())
      dispatch(navigateToHomePage())
    } else {
      setErrors(curErrors)
    }
  }

  // const handleSkip = () => {
  //   dispatch(setOnboardingComplete())
  //   dispatch(navigateToHomePage())
  // }

  const resetError = k => () => {
    setErrors({ ...errors, [k]: undefined })
  }

  const onFocusAddress = () => {
    resetError('address')()
    // setAddressFocused(true)
  }
  // const onBlurAddress = () => setAddressFocused(false)

  // const nextDisabled =
  //   (_.isEmpty(userName) || _.isEmpty(accountName) || !_.has(address, 'timeZone')) && canEditCompanySettings

  return (
    <Box pad='medium' align='start' fill>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box fill='horizontal' align='center' margin={{ top: 'small' }} flex={false} pad='medium'>
        <Box flex={false} width='500px'>
          <Box align='start'>
            <Text textAlign='start' size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT}>
              Complete your profile
            </Text>
          </Box>

          <ThemeContext.Extend
            value={{
              formField: {
                round: '5px'
              }
            }}
          >
            <Box margin={{ vertical: 'medium' }} flex={{ shrink: 0 }}>
              <FormField label={<Text>Your name</Text>} flex={{ shrink: 0 }} error={_.get(errors, 'userName')}>
                <TextInput
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                  onFocus={resetError('userName')}
                />
              </FormField>
              <FormField label={<Text>Company name</Text>} error={_.get(errors, 'accountName')} flex={{ shrink: 0 }}>
                <TextInput
                  value={accountName}
                  onChange={e => setAccountName(e.target.value)}
                  disabled={!canEditCompanySettings}
                  onFocus={resetError('accountName')}
                />
              </FormField>
              <FormField label='Company address' flex={{ shrink: 0 }} error={_.get(errors, 'address')}>
                <AddressAutocompleteGrommet
                  onSelect={setAddress}
                  value={getAddress(address)}
                  // placeholder='Type an address...'
                  onFocus={onFocusAddress}
                  // onBlur={onBlurAddress}
                  disabled={!canEditCompanySettings}
                />
              </FormField>
            </Box>
          </ThemeContext.Extend>
          <Box>
            <Box fill='horizontal' height='45px'>
              <Button
                fill
                primary
                color={colors.AQUA_MARINE}
                label='Next'
                onClick={handleSubmit}
                size='large'
                // disabled={nextDisabled}
              />
            </Box>
            {/* <Box margin='medium' align='center'>
              <Anchor color={colors.LINK} label='Skip' onClick={handleSkip} />
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  accountProfile: getCurrentAccountProfile(state),
  userProfile: getCurrentUserProfile(state),
  accountId: _.get(state, 'account.id'),
  canEditCompanySettings: getCanEditCompanySettings(state)
})

export default connect(mapStateToProps)(AdditionalInfo)
