import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Box, Text } from 'grommet'
import { faGripVertical } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import LineItemTitleCell from 'components/workOrder/LineItemTitle'
import LineItemDelete from 'components/workOrder/LineItemDelete'
import LineItemRequired from 'components/workOrder/LineItemRequired'
import LineItemComments from 'components/workOrder/LineItemComments'
import lineItemType from 'shared/constants/lineItemType'
import DropDownMenu from 'components/DropDownMenu'

const draggingStyle = css`
  border-top: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`

const GridRow = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 26px 2fr 70px 70px 3fr 70px 70px 60px;
  ${props => (props.isDragging ? draggingStyle : '')};
  ${props =>
    !props.isDraggingOver &&
    `:hover:not(.alt-row) {
      background-color: ${colors.TABLE_HOVER};
      #delete_line_item_icon svg path {
        fill: ${colors.CORAL_TWO};
      }
      #drag_icon path {
        fill: ${colors.BROWN_GREY_TWO};
      }
    }
  `}
`

const LineItemQuestion = ({
  scope,
  li,
  parentLi,
  rowId,
  updateItem,
  selectRow,
  unselectRow,
  removeLineItem,
  removalRequest,
  gcProfile,
  onRemoveAction,
  setCommentId,
  messagesAmount,
  nextLiId,
  items,
  tableRowProps,
  dragHandleProps,
  isDraggingOver
}) => {
  const getIdsToFocus = rowId => [
    `${rowId}_title`,
    `${rowId}_excluded`,
    `${rowId}_optional`,
    `${rowId}_quantity`,
    `${rowId}_price`,
    `${rowId}_total`,
    `${rowId}_description`
  ]
  const focusNextElement = (current, e) => {
    e.preventDefault()
    const currentId = `${li.id}_${current}`
    const idsToFocus = getIdsToFocus(li.id)
    const currentIndex = _.indexOf(idsToFocus, currentId)
    let focused = false
    if (currentIndex < _.size(idsToFocus) - 1) {
      for (let i = currentIndex; !focused && i <= idsToFocus.length - 1; i++) {
        const element = document.getElementById(idsToFocus[i + 1])
        if (element && !element.disabled) {
          element.focus()
          focused = true
          break
        }
      }
    }
    if (!focused) {
      focusNextLine()
    }
  }

  const focusNextLine = () => {
    if (nextLiId) {
      const idsToFocusForNextRow = getIdsToFocus(nextLiId)
      let focused = false
      for (let i = 0; !focused && i <= idsToFocusForNextRow.length - 1; i++) {
        const element = document.getElementById(idsToFocusForNextRow[i])
        if (element && !element.disabled) {
          element.focus()
          focused = true
          break
        }
      }
    }
  }

  const existsInScope = _.isEmpty(parentLi) ? _.has(scope, li.id) : _.has(scope, [parentLi.id, 'alternates', li.id])

  const renderTitleField = () => {
    return (
      <LineItemTitleCell
        li={li}
        scope={scope}
        updateItem={updateItem}
        removeLineItem={removeLineItem}
        editable={!existsInScope}
        parentLi={parentLi}
        selectRow={selectRow}
        unselectRow={unselectRow}
        removalRequest={removalRequest}
        gcProfile={gcProfile}
        onRemoveAction={onRemoveAction}
        focusNextElement={focusNextElement}
        currentItems={items}
        hideBorder
        hideAlternates
        isQuestion
      />
    )
  }

  const renderDeleteItemIcon = () => {
    return <LineItemDelete onClick={() => removeLineItem(li.id, parentLi)} />
  }

  const renderDragIcon = () => {
    if (!_.has(parentLi) && !_.isEmpty(dragHandleProps)) {
      return (
        <Box align='end' justify='center' {...dragHandleProps}>
          <FontAwesomeIcon
            icon={faGripVertical}
            color={tableRowProps.isDragging ? colors.BROWN_GREY_TWO : 'transparent'}
            size={18}
            id='drag_icon'
          />
        </Box>
      )
    } else {
      return <Box />
    }
  }

  const renderRequiredField = () => {
    const onChange = event => updateItem(li.id, { required: event.target.checked }, parentLi)
    return <LineItemRequired li={li} parentLi={parentLi} onChange={onChange} />
  }

  const renderCommentsField = () => (
    <LineItemComments messagesAmount={messagesAmount} li={li} parentLi={parentLi} setCommentId={setCommentId} />
  )

  const onChangeLiType = typeId => {
    console.log('onChangeLiType', typeId)
    const curType = _.get(li, 'type', lineItemType.DEFAULT)
    if (typeId !== curType) {
      updateItem(li.id, { type: typeId }, parentLi)
    }
  }

  const renderTypeSwitch = () => {
    const type = _.get(li, 'type', lineItemType.QUESTION)
    return (
      <DropDownMenu
        options={[
          { id: lineItemType.QUESTION, label: 'Text' },
          { id: lineItemType.QUESTION_BOOL, label: 'Yes/No' }
        ]}
        selectedId={type}
        onOptionClick={onChangeLiType}
      >
        <Box
          pad={{ left: 'xxsmall', right: 'xsmall' }}
          width='56px'
          direction='row'
          align='center'
          gap='xxsmall'
          justify='end'
        >
          <Text size='xsmall' color={colors.LIGHT_NAVY_BRIGHT}>
            {type === lineItemType.QUESTION ? 'Text' : 'Yes/No'}
          </Text>
          <FontAwesomeIcon icon={faChevronDown} color={colors.ANOTHER_GREY} size={10} />
        </Box>
      </DropDownMenu>
    )
  }

  return (
    <GridRow key={rowId} id={rowId} {...tableRowProps} isDraggingOver={isDraggingOver}>
      {renderDragIcon()}
      <Box direction='row' border={['bottom', 'right']}>
        <Box customStyle={{ flex: 1 }}>{renderTitleField()}</Box>
        {renderTypeSwitch()}
      </Box>
      {renderCommentsField()}
      <Box border='bottom' />
      <Box border={['bottom', 'right']} />
      {renderRequiredField()}
      <Box border='bottom' />
      {renderDeleteItemIcon()}
    </GridRow>
  )
}

LineItemQuestion.propTypes = {
  scope: PropTypes.object,
  li: PropTypes.object.isRequired,
  parentLi: PropTypes.object,
  rowId: PropTypes.string.isRequired,
  updateItem: PropTypes.func,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  expanded: PropTypes.bool,
  removeLineItem: PropTypes.func.isRequired,
  removalRequest: PropTypes.object,
  gcProfile: PropTypes.object,
  onRemoveAction: PropTypes.func,
  setCommentId: PropTypes.func
}

export default LineItemQuestion
