import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { db } from 'constants/firebase'
import { receiveTakeoffsQuotes } from 'model/actions/takeoffsQuotesAC'

export const fetchTakeoffsQuotes = accountId => {
  try {
    console.log('fetchTakeoffsQuotes, accountId:', accountId)
    const unsubscribe = db
      .collection('takeoffsQuotes')
      .where('accountId', '==', accountId)
      .onSnapshot(
        sn => {
          const res = {}
          sn.forEach(doc => _.set(res, doc.id, doc.data()))
          console.log('takeoffs quotes received:', _.size(res))
          store.dispatch(receiveTakeoffsQuotes(res))
        },
        err => {
          console.log('fetchTakeoffsQuotes', err)
          Sentry.captureException(err)
        }
      )
    addListener('takeoffsQuotes', unsubscribe)
  } catch (e) {
    console.log('fetchTakeoffsQuotes error', e)
    Sentry.captureException(e)
  }
}

export const updateTakeoffsQuote = async (params, quoteId) => {
  await db
    .collection('takeoffsQuotes')
    .doc(quoteId)
    .update(params)
}
