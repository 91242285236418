import _ from 'lodash'
import generate from 'firebase-auto-ids'
import Sentry from 'shared/utils/sentry'

import { auth, db } from 'constants/firebase'
import {
  receiveReferences,
  // receiveJobTypes,
  receiveContractorTypes,
  // receiveProjectTypes,
  // receiveProjectTypeGroups,
  // receiveTerritories,
  receivePricingPlans,
  receivePricingPlansGroups,
  receiveDivisions,
  receiveDivisionsSubgroups
} from 'model/actions/referencesAC'
import store from 'model/store'
import * as roles from 'constants/roles'
import config from 'shared/config'
import { appVersion } from 'constants/index'
import { isWeb } from 'shared/constants'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import compareVersions from 'compare-versions'
import headers from 'shared/controllers/headers'
import { addListener } from './listeners'
import { firstNameByName, lastNameByName } from 'shared/utils/stringUtils'

export let isOldVersion = false

export const isUserShouldUpdateApp = references => {
  const minInternalVersion = _.get(references, 'minInternalVersion', 0)
  const minAppVersion = _.get(references, 'minAppVersion', '1.0.0')
  if (isWeb) return config.internalVersion < minInternalVersion
  return compareVersions.compare(appVersion, minAppVersion, '<')
}

export async function fetchContractorTypes () {
  const ctSn = await db
    .collection('appSettings')
    .doc('contractorTypes')
    .get()
  return ctSn.data()
}

export function fetchReferences () {
  return async function (dispatch) {
    try {
      console.log('fetchReferences')
      const referencesUnsubscribe = db
        .collection('appSettings')
        .doc('references')
        .onSnapshot(
          referencesSN => {
            const references = referencesSN.data()
            if (isUserShouldUpdateApp(references)) {
              isOldVersion = true
              universalNavigation.navigate(screens.UPDATE_APP)
            }
            dispatch(receiveReferences(references))
          },
          err => console.log('fetchReferences error', err)
        )
      addListener('references', referencesUnsubscribe)

      // const jobTypesUnsubscribe = db
      //   .collection('pipedrive')
      //   .doc('jobTypes')
      //   .onSnapshot(
      //     jobTypesSN => {
      //       const jobTypes = jobTypesSN.data()
      //       dispatch(receiveJobTypes(jobTypes))
      //     },
      //     err => console.log('fetchJobTypes error', err)
      //   )
      // addListener('jobTypes', jobTypesUnsubscribe)

      const contractorTypesUnsubscribe = db
        .collection('appSettings')
        .doc('contractorTypes')
        .onSnapshot(
          sn => {
            dispatch(receiveContractorTypes(sn.data()))
          },
          err => console.log('fetch contractorTypes error', err)
        )
      addListener('contractorTypes', contractorTypesUnsubscribe)

      const pricingPlansUnsubscribe = db
        .collection('appSettings')
        .doc('pricingPlans')
        .onSnapshot(
          sn => {
            dispatch(receivePricingPlans(sn.data()))
          },
          err => console.log('fetch pricingPlansUnsubscribe error', err)
        )
      addListener('pricingPlans', pricingPlansUnsubscribe)

      const pricingPlansGroupsUnsubscribe = db
        .collection('appSettings')
        .doc('pricingPlansGroups')
        .onSnapshot(
          sn => {
            dispatch(receivePricingPlansGroups(sn.data()))
          },
          err => console.log('fetch pricingPlansGroupsUnsubscribe error', err)
        )
      addListener('pricingPlansGroups', pricingPlansGroupsUnsubscribe)

      // const projectTypesUnsubscribe = db
      //   .collection('appSettings')
      //   .doc('projectTypes')
      //   .onSnapshot(
      //     sn => {
      //       dispatch(receiveProjectTypes(sn.data()))
      //     },
      //     err => console.log('fetch projectTypes error', err)
      //   )
      // addListener('projectTypes', projectTypesUnsubscribe)

      // const projectTypeGroupsUnsubscribe = db
      //   .collection('appSettings')
      //   .doc('projectTypeGroups')
      //   .onSnapshot(
      //     sn => {
      //       dispatch(receiveProjectTypeGroups(sn.data()))
      //     },
      //     err => console.log('fetch projectTypeGroups error', err)
      //   )
      // addListener('projectTypeGroups', projectTypeGroupsUnsubscribe)

      // const territoriesUnsubscribe = db
      //   .collection('appSettings')
      //   .doc('territories')
      //   .onSnapshot(
      //     sn => {
      //       dispatch(receiveTerritories(sn.data()))
      //     },
      //     err => console.log('fetch territories error', err)
      //   )
      // addListener('territories', territoriesUnsubscribe)

      const divisionsUnsubscribe = db
        .collection('appSettings')
        .doc('divisions')
        .onSnapshot(
          sn => {
            dispatch(receiveDivisions(sn.data()))
          },
          err => console.log('fetch divisions error', err)
        )
      addListener('divisions', divisionsUnsubscribe)

      const divisionsSubgroupsUnsubscribe = db
        .collection('appSettings')
        .doc('divisionsSubgroups')
        .onSnapshot(
          sn => {
            dispatch(receiveDivisionsSubgroups(sn.data()))
          },
          err => console.log('fetch divisions subgroups error', err)
        )
      addListener('divisionsSubgroups', divisionsSubgroupsUnsubscribe)
    } catch (e) {
      Sentry.captureException(e)
      console.log('fetchReferences error:', e.message)
    }
  }
}

export async function deleteProposal (proposalId) {
  const state = store.getState()
  const accountId = _.get(state, 'user.currentAccountId')
  const hasRights = _.get(state, ['user', 'accounts', accountId, 'role']) === roles.OWNER
  if (hasRights) {
    await db
      .collection('proposals')
      .doc(proposalId)
      .delete()
  } else {
    console.log('User has not rights to delete proposal')
  }
}

export function editPermission (roleId, permId, value) {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'user.currentAccountId')
    console.log('editPermission', roleId, permId, value)
    const upd = { [`roles.${roleId}.permissions.${permId}`]: value }
    await db
      .collection('accounts')
      .doc(accountId)
      .update(upd)
  }
}

export function saveRole (role) {
  return async function (dispatch, getState) {
    const state = getState()
    const accountId = _.get(state, 'user.currentAccountId')
    const roleId = generate(_.now())
    const upd = { [`roles.${roleId}`]: role }
    await db
      .collection('accounts')
      .doc(accountId)
      .update(upd)
  }
}

export async function userIdByPhone (phone) {
  const uidByPhoneSN = await db
    .collection('uidByPhone')
    .doc(phone)
    .get()
  if (uidByPhoneSN.exists) {
    const uidByPhone = uidByPhoneSN.data()
    return uidByPhone.uid
  } else {
    return null
  }
}

export async function subProfileByPhone (phone) {
  console.log('subProfileByPhone', phone)
  try {
    const uidByPhoneSN = await db
      .collection('uidByPhone')
      .doc(phone)
      .get()
    if (uidByPhoneSN.exists) {
      const uidByPhone = uidByPhoneSN.data()
      const userId = uidByPhone.uid
      const profileSN = await db
        .collection('usersProfiles')
        .doc(userId)
        .get()
      if (profileSN.exists) {
        const profile = profileSN.data()
        // console.log('subProfileByPhone', phone, profile)
        let accountProfile = {}
        const currentAccountId = _.get(profile, 'currentAccountId')
        if (currentAccountId) {
          const accProfileSN = await db
            .collection('accountsProfiles')
            .doc(currentAccountId)
            .get()
          if (accProfileSN.exists) accountProfile = accProfileSN.data()
        }
        console.log('return profile', profile, 'accountProfile', accountProfile)
        return {
          profile,
          accountProfile
        }
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (e) {
    console.log('subProfileByPhone error', e)
    Sentry.captureException(e)
    return null
  }
}

export async function fetchContractSignatureUrl (proposalId) {
  try {
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const body = {
      authToken,
      proposalId
    }
    const url = `${config.dynamicUrl}/proto/contractSignUrl`
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body)
    })
    const answer = await response.json()
    console.log('fetchContractSignatureUrl answer', answer)
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch proposal signature url error:', e)
    return null
  }
}

export function setContractSigned (proposalId) {
  return async () => {
    try {
      await db
        .collection('proposals')
        .doc(proposalId)
        .update({ signedByHic: true })
    } catch (e) {
      console.log('setContractSigned: proposal is not found')
    }
    try {
      await db
        .collection('projects')
        .doc(proposalId)
        .update({ signedByHic: true })
    } catch (e) {
      console.log('setContractSigned: project is not found')
    }
  }
}

export async function updateProfile (params) {
  try {
    const state = store.getState()
    const currentUser = auth.currentUser

    const name = _.get(params, 'name')
    const firstName = firstNameByName(name)
    const lastName = lastNameByName(name)

    const accountId = _.get(state, 'user.currentAccountId')
    const accountProfile = _.get(state, ['accountsProfiles', accountId])
    const companyName = _.get(params, 'companyName')
    if (!_.has(accountProfile, 'name') && _.isEmpty(companyName)) {
      await db
        .collection('accountsProfiles')
        .doc(accountId)
        .update({ name: companyName })
    }
    const userProfileUpd = _.omitBy(
      {
        contactPhone: _.get(params, 'contactPhone'),
        title: _.get(params, 'title'),
        name,
        firstName,
        lastName
      },
      _.isNil
    )
    if (!_.isEmpty(name)) {
      await currentUser.updateProfile({ displayName: name })
    }
    if (!_.isEmpty(userProfileUpd)) {
      await db
        .collection('usersProfiles')
        .doc(currentUser.uid)
        .update(userProfileUpd)
    }
    const email = _.get(params, 'email')
    if (!_.isEmpty(email)) {
      const authToken = await currentUser.getIdToken()
      const response = await fetch(`${config.dynamicUrl}/proto/updateUserEmail`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify({ email, authToken })
      })
      const answer = await response.json()
      return answer
    } else {
      return { success: true }
    }
  } catch (e) {
    console.log('updateProfile error', e)
    Sentry.captureException(e)
    return { success: false, error: e.message }
  }
}

export async function licenseLookup (searchString, isHis, requestId) {
  try {
    console.log('start licenseLookup', searchString, isHis, requestId)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/licenseLookup`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ searchString, isHis, requestId, authToken, limit: 20 })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('licenseLookup error', e)
    return {
      requestId,
      result: []
    }
  }
}

export async function hicInfoByLicenseNumber (licenseNumber) {
  try {
    console.log('start hicInfoByLicenseNumber', licenseNumber)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/hicInfoByLicenseNumber`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ licenseNumber, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('licenseLookup error', e)
    return { error: e.message }
  }
}

export async function getGeojson () {
  try {
    console.log('start getGeojson', config.geojsonUrl)
    const response = await fetch(config.geojsonUrl, {
      method: 'get'
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('getGeojson error', e)
    return null
  }
}

export async function processInvId (invId) {
  try {
    console.log('start processInvId', invId)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/processInvId`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ invId, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('licenseLookup error', e)
    return { error: e.message }
  }
}

export const processSubProposalId = async subProposalId => {
  try {
    console.log('start processSubProposalId', subProposalId)
    const currentUser = auth.currentUser
    const authToken = await currentUser.getIdToken()
    const response = await fetch(`${config.dynamicUrl}/proto/processSubProposalId`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ subProposalId, authToken })
    })
    const answer = await response.json()
    return answer
  } catch (e) {
    Sentry.captureException(e)
    console.log('licenseLookup error', e)
    return { error: e.message }
  }
}

export const getPreconConf = async preconConfId => {
  if (!_.isEmpty(preconConfId)) {
    const preconConfSN = await db
      .collection('preconConfs')
      .doc(preconConfId)
      .get()
    return preconConfSN.data()
  } else {
    return null
  }
}

export const getPricingPackageConf = async pckgId => {
  if (!_.isEmpty(pckgId)) {
    const pricingPackageSN = await db
      .collection('pricingPlansPackages')
      .doc(pckgId)
      .get()
    const pckg = pricingPackageSN.data()
    const pricesIds = []
    const groups = _.get(pckg, 'groups', {})
    _.forEach(groups, g => {
      _.forEach(g.plans, plan => {
        pricesIds.push(plan.stripePlanId)
      })
    })
    const uniqPricesIds = _.uniq(_.compact(pricesIds))
    const chunks = _.chunk(uniqPricesIds, 10)
    const pricesArAr = await Promise.all(
      _.map(chunks, async ids => {
        const pricesSN = await db
          .collection('stripePrices')
          .where('id', 'in', ids)
          .get()
        const prices = _.map(pricesSN.docs, doc => doc.data())
        return prices
      })
    )
    const prices = _.keyBy(_.flatten(pricesArAr), 'id')
    console.log('package', pckg, prices)
    console.log('pricesIds', pricesIds)
    console.log('prices', prices)
    return { pckg, prices }
  } else {
    return null
  }
}
