export const INVITE_SUBS = 'INVITE_SUBS'
export const BIDDING = 'BIDDING'
export const BIDS_RECEIVED = 'BIDS_RECEIVED'
export const COMPLETED = 'COMPLETED'

export default {
  INVITE_SUBS,
  BIDDING,
  BIDS_RECEIVED,
  COMPLETED
}
