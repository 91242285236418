import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import qs from 'qs'

import ResolveInvite from 'webPages/invitation/ResolveInvite'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { processInvId } from 'controllers/data'
import Loading from 'shared/pages/Loading'
import { navigateToHomePage } from 'controllers/init'
import { getInvitationInfo, setInvId } from 'controllers/auth'
// import { updateSeenStatus } from 'controllers/workOrder'
import signInMode from 'constants/signInMode'
import authAction from 'shared/constants/authAction'

class Invitation extends Component {
  constructor (props) {
    super(props)
    const queryParams = qs.parse(_.get(props, 'location.search'), { ignoreQueryPrefix: true })
    console.log('invitation page queryParams', queryParams)
    const invId = _.get(queryParams, 'r')
    console.log('invId', invId)
    this.state = {
      loading: true,
      invId,
      invInfo: {}
    }
  }

  // static getDerivedStateFromProps (props, state) {
  //   if (state.loading && !_.isEmpty(props.authData) && !_.isNil(props.workOrders) && !_.isEmpty(props.account)) {
  //     console.log('GDSFP work orders received')
  //     const wo = _.get(props, ['workOrders', state.invId])
  //     if (!_.isEmpty(wo)) {
  //       console.log('work order exists', wo)
  //       universalNavigation.navigate(screens.SUB_CREATE_BID, { projectId: wo.projectId, workOrderId: wo.id })
  //     } else {
  //       console.log('-----> workOrder does not exist, start processInvId ')
  //       if (!state.skipNextProcessInvId) {
  //         processInvId(state.invId).then(res => {
  //           console.log('processInvId res', res)
  //           const projectId = _.get(res, 'redirectParams.projectId')
  //           const workOrderId = _.get(res, 'redirectParams.workOrderId')
  //           console.log('projectId', projectId, 'workOrderId', workOrderId)
  //           if (projectId && workOrderId) {
  //             universalNavigation.navigate(screens.SUB_CREATE_BID, { projectId, workOrderId })
  //           } else {
  //             props.dispatch(navigateToHomePage())
  //           }
  //         })
  //       } else {
  //         console.log('---> skip')
  //       }
  //       return {
  //         skipNextProcessInvId: true
  //       }
  //     }
  //   }
  //   return null
  // }

  componentWillUnmount = () => {
    this.onInvitationInfoReceived = () => null
    this.onWorkOrderReceived = () => null
    this.onAdditionalParamsReceived = () => null
  }

  componentDidMount = async () => {
    const { authData, dispatch } = this.props
    const { invId } = this.state
    if (_.isNil(invId) && _.isEmpty(authData)) {
      // no invId and user is not autorized, go to auth page
      universalNavigation.push(screens.AUTH)
    } else if (_.isEmpty(authData)) {
      const invInfo = await getInvitationInfo(invId)
      console.log('invInfo', invInfo)
      this.onInvitationInfoReceived(invInfo)
    } else {
      const res = await processInvId(invId)
      console.log('processInvId res', res)
      const params = _.get(res, 'params')
      const action = _.get(params, 'action')
      switch (action) {
        case authAction.SHOW_CREATE_BID: {
          universalNavigation.push(screens.SUB_CREATE_BID, {
            workOrderId: _.get(params, 'workOrderId'),
            projectId: _.get(params, 'projectId')
          })
          break
        }
        default: {
          console.log('the action is unhandled, navigate to home page')
          dispatch(navigateToHomePage())
        }
      }
    }
  }

  onInvitationInfoReceived = async invInfo => {
    if (_.isEmpty(invInfo)) {
      console.log('inInfo does not exist, navigate to AUTH')
      universalNavigation.push(screens.ENTER_PHONE, { mode: signInMode.SIGN_IN })
    } else {
      this.setState({
        loading: false,
        invInfo
      })
    }
  }

  onHandleAccept = async v => {
    console.log('handleAccept', v)
    const { invId } = this.state
    console.log('invitation exists in the db')
    await setInvId(invId)
    universalNavigation.push(screens.ENTER_PHONE, { mode: signInMode.BID_INVITE })
  }

  render () {
    const { loading, invInfo } = this.state
    const accountId = _.get(invInfo, 'invId')
    const mode = _.get(this.props, 'queryParams.mode')
    if (loading) {
      return <Loading />
    } else {
      return <ResolveInvite handleAccept={this.onHandleAccept} accountId={accountId} {...invInfo} mode={mode} />
    }
  }
}

const mapStateToProps = state => ({
  authData: state.authData,
  workOrders: state.workOrders,
  account: state.account
})

export default connect(mapStateToProps)(Invitation)
