import React, { useState, useImperativeHandle } from 'react'
import { DropButton } from 'grommet'
import PropTypes from 'prop-types'

import DropContent from 'components/DropContent.web'

const DropDownMenu = ({
  dropRef,
  children,
  dropContentProps,
  title,
  onOptionClick,
  options,
  selectedId,
  onOpen,
  multiple,
  ...rest
}) => {
  const [opened, setOpened] = useState(false)

  useImperativeHandle(dropRef, () => ({
    open: () => setOpened(true),
    close: () => setOpened(false)
  }))

  const onClick = (id, e, params) => {
    e.stopPropagation()
    setOpened(false)
    onOptionClick(id, params)
  }

  const dropContent = (
    <DropContent
      title={title}
      onOptionClick={onClick}
      options={options}
      selectedId={selectedId}
      multiple={multiple}
      {...dropContentProps}
    />
  )

  const _onOpen = e => {
    e.stopPropagation()
    setOpened(true)
    onOpen()
  }

  const _onClose = e => {
    e.stopPropagation()
    setOpened(false)
  }

  return (
    <DropButton
      plain
      label={children}
      dropAlign={{ top: 'bottom', left: 'left' }}
      dropContent={dropContent}
      open={opened}
      onOpen={_onOpen}
      onClose={_onClose}
      {...rest}
    />
  )
}

const optionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string
})

DropDownMenu.defaultProps = {
  onOptionClick: () => null,
  onOpen: () => null
}

DropDownMenu.propTypes = {
  title: PropTypes.string,
  onOptionClick: PropTypes.func,
  options: PropTypes.arrayOf(optionType),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOpen: PropTypes.func
}

export default DropDownMenu
