import React, { useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { Box, Text, Avatar, Button, Layer, ThemeContext, MaskedInput, TextArea } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import validator from 'validator'

import { PHONE_NUMBER_LENGTH } from 'shared/constants/index'
import colors from 'shared/constants/colors'
import { getAccountAdmins } from 'model/selectors/base'
import { sendInvite, resendInvite } from 'controllers/auth'
import { getInitials, getName } from 'shared/utils/stringUtils'
import { emailMask, phoneMask } from 'utils/inputMask'
import roles from 'shared/constants/roles'
import { editUserRole } from 'controllers/account'
import ActionButton from 'components/teammates/ActionButton'
import RemoveTeammateModal from 'components/teammates/RemoveTeammateModal'
import LayoutContext from 'webPages/layout/LayoutContext'
import InviteAdmin from 'pages/settings/settingsAccount/InviteAdmin'

const borderBottom = {
  color: colors.VERY_LIGHT_GREY_TWO,
  size: 'xsmall',
  side: 'bottom'
}

const Row = ({ onClick, children }) => (
  <Box
    border={borderBottom}
    pad='small'
    direction='row'
    align='center'
    hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
    onClick={onClick}
    height='52px'
    flex={{ shrink: 0 }}
  >
    {children}
  </Box>
)

const Teammates = ({ currentUser, admins, invitations, isMasonAdmin, masonAdmins, accountId, dispatch }) => {
  const [open, setOpen] = useState(false)
  const [adminOpen, setAdminOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const removeUserModalRef = useRef()
  const canLeaveAccount = _.size(_.get(currentUser, 'adminOfAccounts', [])) > 1 && _.size(admins) > 1
  const { openLeaveAccountModal } = useContext(LayoutContext)

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSetRemovingUser = user => {
    removeUserModalRef.current.open(user)
  }

  const onEditUserRole = (userId, role) => {
    dispatch(editUserRole(userId, role))
  }

  const handleSubmit = () => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US')
    dispatch(sendInvite(_.get(phoneNumber, 'number'), roles.OWNER, null, null, email, message))
    setOpen(false)
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const renderRole = user => {
    if (user.invited) {
      return (
        <Button
          primary
          color={colors.AQUA_MARINE}
          label='Resend'
          onClick={() => dispatch(resendInvite(user.inviteId))}
        />
      )
    } else {
      return (
        <Text size='small' color={colors.VERY_LIGHT_PINK}>
          {_.upperFirst(user.role)}
        </Text>
      )
    }
  }

  const renderUser = user => {
    const email = _.get(user, 'email')
    const phone = _.get(user, 'phone') || ''
    const phoneNumber = parsePhoneNumberFromString(phone)
    const contacts = email ? `${email}${phoneNumber ? `, ${phoneNumber.formatNational()}` : ''}` : phone
    return (
      <Box
        flex={false}
        direction='row'
        align='center'
        key={user.id}
        pad='small'
        border={borderBottom}
        hoverIndicator={{ color: colors.SILVER_SAND, opacity: 0.1 }}
        height='52px'
        onClick={() => null}
      >
        <Box width='32px' height='32px' align='center' justify='center' flex={false}>
          <Avatar background={colors.LIGHT_NAVY_BRIGHT} size='32px' src={user.avatarSmall}>
            <Text color={colors.WHITE}>{getInitials(user)}</Text>
          </Avatar>
        </Box>
        <Box fill margin={{ left: 'medium' }} align='start' justify='center' gap='xsmall'>
          <Text>{getName(user)}</Text>
          <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
            {contacts}
          </Text>
        </Box>
        <Box align='end' width='small' pad={{ horizontal: 'small' }}>
          {renderRole(user)}
        </Box>
        <Box width='xsmall' align='center'>
          <ActionButton user={user} onEditUserRole={onEditUserRole} onSetRemovingUser={onSetRemovingUser} />
        </Box>
      </Box>
    )
  }

  return (
    <Box fill overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
      <Box alignSelf='center' width='large' direction='column'>
        <Box flex={false} fill='vertical' direction='column'>
          <Box flex border={borderBottom} fill='vertical' pad={{ vertical: 'small' }} justify='between' align='center' direction='row'>
            <Text size='large' color={colors.BLACK} weight={400}>
              Teammates
            </Text>
            {canLeaveAccount && (
              <Button
                color={colors.CORAL_TWO}
                label='Leave this account'
                onClick={openLeaveAccountModal}
              />
            )}
          </Box>
          <Row onClick={handleOpen}>
            <Box
              width='32px'
              height='32px'
              border={{ color: colors.ANOTHER_GREY, style: 'dashed' }}
              round='xxsmall'
              align='center'
              justify='center'
              responsive={false}
            >
              <FontAwesomeIcon icon={faPlus} size={20} color={colors.ANOTHER_GREY} />
            </Box>
            <Text margin={{ left: 'medium' }} color={colors.ANOTHER_GREY}>
              Invite Teammate
            </Text>
          </Row>
          {isMasonAdmin && <Row onClick={() => setAdminOpen(true)}>
            <Box
              width='32px'
              height='32px'
              border={{ color: colors.AQUA_MARINE, style: 'dashed' }}
              round='xxsmall'
              align='center'
              justify='center'
              responsive={false}
            >
              <FontAwesomeIcon icon={faPlus} size={20} color={colors.AQUA_MARINE} />
            </Box>
            <Text margin={{ left: 'medium' }} color={colors.AQUA_MARINE}>
              Invite Mason Admin
            </Text>
          </Row>}
          {_.map(_.concat(admins, invitations), renderUser)}
        </Box>
      </Box>
      <RemoveTeammateModal modalRef={removeUserModalRef} />
      {open && (
        <ThemeContext.Extend value={{ layer: { border: { radius: 'xsmall' } } }}>
          <Layer
            position='top'
            onClickOutside={handleOpen}
            onEsc={handleOpen}
            modal
            margin={{ top: 'large' }}
            flex={false}
          >
            <Box width='medium' height='500px' gap='small' flex={false}>
              <Box pad={{ top: 'medium', horizontal: 'medium' }} flex={false}>
                <Text color={colors.TEXT} size='large'>
                  Invite teammate
                </Text>
              </Box>
              <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                <Text color={colors.TEXT}>Email</Text>
                <MaskedInput mask={emailMask} value={email} onChange={e => setEmail(e.target.value)} />
              </Box>
              <Box pad={{ horizontal: 'medium' }} gap='xsmall' flex={false}>
                <Text color={colors.TEXT}>Phone number:</Text>
                <MaskedInput mask={phoneMask} value={phone} onChange={e => setPhone(e.target.value)} />
              </Box>
              <Box pad={{ horizontal: 'medium' }} height='100px' flex={false}>
                <TextArea
                  resize={false}
                  fill
                  placeholder='Add message (optional)'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Box>
              <Box
                fill='horizontal'
                margin={{ top: 'auto' }}
                pad={{ vertical: 'small', horizontal: 'medium' }}
                align='end'
                border='top'
                flex={false}
              >
                <Box width='xsmall'>
                  <Button
                    disabled={!validator.isEmail(email) && phone.length < PHONE_NUMBER_LENGTH}
                    primary
                    color={colors.AQUA_MARINE}
                    label='Send'
                    onClick={handleSubmit}
                  />
                </Box>
              </Box>
            </Box>
          </Layer>
        </ThemeContext.Extend>
      )}
      <InviteAdmin adminOpen={adminOpen} setAdminOpen={setAdminOpen} masonAdmins={masonAdmins} accountId={accountId} />
    </Box>
  )
}

const mapStateToProps = state => {
  const admins = _.map(getAccountAdmins(state), admin => ({ ...admin, ..._.get(state, ['profiles', admin.id]) }))
  const adminsIds = _.map(admins, admin => admin.id)
  return {
    currentUser: state.user,
    isMasonAdmin: _.get(state, ['profiles', state.user.id, 'isMasonAdmin'], false),
    accountId: _.get(state, 'account.id'),
    admins,
    masonAdmins: _.filter(_.get(state, 'masonAdmins'), user => user.id !== state.user.id && !_.includes(adminsIds, user.id)),
    invitations: _.map(_.values(state.outgoingInvitations), inv => ({
      ...inv,
      ..._.get(state, ['profiles', inv.userId]),
      invited: true,
      inviteId: inv.id
    }))
  }
}

export default connect(mapStateToProps)(Teammates)
