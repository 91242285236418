import React, { Component } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tip,
  Keyboard
  // Tabs,
  // Tab,
  // ThemeContext
} from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'

import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import colors from 'shared/constants/colors'
import { saveProject, changeArchiveProjectStatus } from 'controllers/project'
import { navigateToHomePage } from 'controllers/init'
import { defaultTimeZone } from 'shared/constants/index'
import CreateProjectForm from 'webPages/createProject/CreateProjectForm'
// import OrderDetailsForm from 'webPages/createProject/OrderDetailsForm'
import { generateId } from 'constants/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

class ProjectModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  setFiles = files => {
    console.log('setFiles', files)
    this.setState({ attachments: files })
  }

  open = (projectId, activeTab = 0) => {
    console.log('open', projectId, 'activeTab', activeTab)
    const { projects } = this.props
    const id = projectId || generateId()
    const project = _.get(projects, id, {})
    this.setState({
      visible: true,
      id,
      address: _.get(project, 'address'),
      attachments: _.get(project, 'attachments'),
      apartment: _.get(project, 'apartment'),
      startDate: _.get(project, 'startDate'),
      isNew: _.isEmpty(project),
      sessionToken: uuidv4(),
      projectType: _.get(project, 'projectType'),
      typeOfWork: _.get(project, 'typeOfWork'),
      size: _.get(project, 'size'),
      description: _.get(project, 'description'),
      deleted: _.get(project, 'deleted', 0),
      label: _.get(project, 'label', null),
      orderDetails: {
        notes: '',
        ..._.get(project, 'orderDetails', {}),
        sync: _.now()
      },
      activeTab
    })
  }

  onActive = i => {
    console.log('onActive', i)
    this.setState({ activeTab: i })
  }

  close = () => {
    this.setState({ visible: false })
  }

  onChange = kind => v => {
    console.log('onChange', kind, v)
    this.setState({ [kind]: v })
  }

  saveProject = async () => {
    console.log('save project')
    const {
      id,
      address,
      apartment,
      attachments,
      startDate,
      projectType,
      typeOfWork,
      size,
      description,
      label,
      orderDetails,
      isNew,
      activeTab
    } = this.state
    const { user, account, projects } = this.props
    const params = {
      id,
      accountId: _.get(account, 'id'),
      address,
      apartment,
      createdBy: _.get(user, 'id'),
      startDate,
      attachments,
      projectType,
      typeOfWork,
      size,
      description,
      label,
      orderDetails
    }
    if (isNew) {
      _.set(params, 'createdAt', _.now())
    } else {
      _.set(params, 'updatedAt', _.now())
    }
    let isNewTakeoffsRequest = isNew && orderDetails.requireTakeoffs && !_.isEmpty(orderDetails.takeoffsFiles)
    const project = _.get(projects, id)
    if (!isNew && orderDetails.requireTakeoffs && !_.isEmpty(orderDetails.takeoffsFiles)) {
      const requireTakeoffsPrev = _.get(project, 'orderDetails.requireTakeoffs', false)
      const takeoffsFilesPrev = _.get(project, 'orderDetails.takeoffsFiles', [])
      isNewTakeoffsRequest = isNewTakeoffsRequest || !requireTakeoffsPrev || _.isEmpty(takeoffsFilesPrev)
    }
    if (isNewTakeoffsRequest) {
      _.set(params, 'orderDetails.takeoffsOrderedAt', _.now())
      _.set(params, 'orderDetails.takeoffsOrderedBy', _.get(user, 'id', null))
    }

    if (!_.isEqual(_.get(project, 'orderDetails.createScopesFor'), orderDetails.createScopesFor)) {
      _.set(params, 'orderDetails.scopesOrderedAt', _.now())
      _.set(params, 'orderDetails.scopesOrderedBy', _.get(user, 'id', null))
    }

    saveProject(params, [])
    if (!isNew && activeTab === 0) {
      this.onActive(1)
    } else {
      this.close()
      universalNavigation.push(screens.GC_PROJECT, { projectId: params.id })
    }
  }

  handleArchiveClick = () => {
    const { id, deleted } = this.state
    const { dispatch } = this.props
    changeArchiveProjectStatus(id, deleted === 0)
    this.close()
    dispatch(navigateToHomePage())
  }

  handleLabelModeChange = () => {
    if (this.state.editLabelMode && this.state.label === '') {
      this.handleLabelRemove()
    } else {
      this.setState({
        editLabelMode: !this.state.editLabelMode
      })
    }
  }

  handleLabelRemove = () => {
    this.onChange('label')(null)
    this.handleLabelModeChange()
  }

  renderLabel = () => {
    const { label, address, editLabelMode } = this.state
    const value = _.isNil(label) ? _.get(address, 'name') : label
    if (editLabelMode) {
      return (
        <Box
          direction='row'
          gap='medium'
          align='center'
          customStyle={`
            .submit-label {
              display: none;
            }
            :focus-within .submit-label {
              display: flex;
            }
          `}
          flex={{ grow: 1 }}
        >
          <Box width='medium' customStyle='input { padding: 4px; height: 28px; }'>
            <Keyboard onEnter={this.handleLabelModeChange}>
              <TextInput autoFocus value={value} onChange={e => this.onChange('label')(e.target.value)} />
            </Keyboard>
          </Box>
          <Button
            className='submit-label'
            plain
            label={<Text color={colors.CLEAR_BLUE}>update</Text>}
            onClick={this.handleLabelModeChange}
          />
          <Button
            className='submit-label'
            plain
            label={<Text color={colors.CORAL_TWO}>remove label</Text>}
            onClick={this.handleLabelRemove}
          />
        </Box>
      )
    }
    return (
      <Box
        direction='row'
        gap='medium'
        align='center'
        customStyle={`
          .submit-label {
            display: none;
          }
          :hover .submit-label {
            display: flex;
          }
        `}
        flex={{ grow: 1 }}
      >
        <Box justify='center' width={{ max: 'medium' }}>
          <Text size='large' color={colors.LIGHT_NAVY_BRIGHT} weight={500} truncate>
            {_.isNil(label) ? _.get(address, 'name') : label}
          </Text>
        </Box>
        <Button
          className='submit-label'
          plain
          label={<Text color={colors.CLEAR_BLUE}>rename</Text>}
          onClick={this.handleLabelModeChange}
        />
      </Box>
    )
  }

  renderSubmitButton = () => {
    const { address, isNew, activeTab } = this.state
    const disabled = !_.has(address, 'timeZone')
    let buttonName = isNew ? 'Create' : 'Save'
    if (activeTab === 1) {
      buttonName = isNew ? 'Order' : 'Update order'
    }
    const submitButton = (
      <Box>
        <Button
          primary
          margin='medium'
          label={buttonName}
          onClick={this.saveProject}
          disabled={disabled}
          color={activeTab === 0 ? colors.LIGHT_NAVY_BRIGHT : colors.AQUA_MARINE}
        />
      </Box>
    )
    if (!_.has(address, 'timeZone')) {
      return <Tip content='Enter a valid project address to continue'>{submitButton}</Tip>
    } else {
      return submitButton
    }
  }

  renderFooter = () => {
    const { isNew, deleted, activeTab } = this.state
    if (activeTab === 1) return null
    return (
      <CardFooter align='center' justify='end'>
        <Box direction='row' justify='between' fill='horizontal' pad={{ right: 'medium', left: 'large' }}>
          <Box justify='center'>
            {!isNew && (
              <Button
                color={colors.CORAL_TWO}
                label={deleted > 0 ? 'Unarchive' : 'Archive'}
                onClick={this.handleArchiveClick}
              />
            )}
          </Box>
          <Box>{this.renderSubmitButton()}</Box>
        </Box>
      </CardFooter>
    )
  }

  // TABS WITH ORDER SERVICES PAGE

  // renderCardBody = () => {
  //   const {
  //     isNew,
  //     id,
  //     attachments,
  //     startDate,
  //     address,
  //     apartment,
  //     projectType,
  //     typeOfWork,
  //     size,
  //     description,
  //     orderDetails,
  //     activeTab
  //   } = this.state
  //   const { projects } = this.props

  //   const timeZone = _.get(address, 'timeZone', defaultTimeZone)
  //   const project = _.get(projects, id, {})
  //   const params = {
  //     address,
  //     apartment,
  //     projectType,
  //     typeOfWork,
  //     size,
  //     attachments,
  //     startDate,
  //     timeZone,
  //     description,
  //     orderDetails,
  //     sizeMason: _.get(project, 'sizeMason')
  //   }
  //   // const scopesOrderDisabled = _.has(project, ['scopes', 'assigned', 'userId'])
  //   return (
  //     <ThemeContext.Extend
  //       value={{
  //         tabs: {
  //           panel: { extend: 'overflow-y: auto;' },
  //           header: {
  //             extend: `justify-content: space-around; display: ${isNew ? 'none' : 'flex'}`,
  //             border: { size: '0px' }
  //           }
  //         },
  //         tab: {
  //           active: { weight: 600 },
  //           hover: { background: 'none' },
  //           background: 'none',
  //           extend: `background-color: transparent; padding: 4px 30px;`
  //         }
  //       }}
  //     >
  //       <Tabs
  //         fill='horizontal'
  //         alignControls='stretch'
  //         alignSelf='stretch'
  //         pad={{ top: 'medium' }}
  //         activeIndex={activeTab}
  //         onActive={this.onActive}
  //       >
  //         <Tab title='Project Details'>
  //           <Box fill='horizontal' align='center'>
  //             <Box width={{ max: 'large' }} alignSelf='center'>
  //               <CreateProjectForm isNew={isNew} projectId={id} params={params} onChange={this.onChange} />
  //             </Box>
  //           </Box>
  //         </Tab>
  //         <Tab title='Order Mason Services'>
  //           <Box fill='horizontal' pad={{ top: 'xsmall' }}>
  //             <OrderDetailsForm
  //               // isNew={isNew}
  //               projectId={id}
  //               // params={orderDetails}
  //               // saveOrderDetails={this.onChange('orderDetails')}
  //               // timeZone={timeZone}
  //               // attachments={attachments}
  //               // onChangeAttachments={this.onChange('attachments')}
  //               // scopesOrderDisabled={scopesOrderDisabled}
  //             />
  //           </Box>
  //         </Tab>
  //       </Tabs>
  //     </ThemeContext.Extend>
  //   )
  // }

  renderCardBody = () => {
    const {
      isNew,
      id,
      attachments,
      startDate,
      address,
      apartment,
      projectType,
      typeOfWork,
      size,
      description
      // orderDetails,
      // activeTab
    } = this.state
    const { projects } = this.props

    const timeZone = _.get(address, 'timeZone', defaultTimeZone)
    const project = _.get(projects, id, {})
    const params = {
      address,
      apartment,
      projectType,
      typeOfWork,
      size,
      attachments,
      startDate,
      timeZone,
      description,
      // orderDetails,
      sizeMason: _.get(project, 'sizeMason')
    }
    // const scopesOrderDisabled = _.has(project, ['scopes', 'assigned', 'userId'])
    return (
      <Box fill='horizontal' align='center'>
        <Box width={{ max: 'large' }} alignSelf='center'>
          <CreateProjectForm isNew={isNew} projectId={id} params={params} onChange={this.onChange} />
        </Box>
      </Box>
    )
  }

  render () {
    const { visible, isNew, activeTab } = this.state
    let tabTitle = activeTab === 0 ? 'Create Project' : 'Preconstruction services'
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card width='xlarge'>
            <CardHeader
              pad='medium'
              border={{
                color: 'border',
                size: '1px',
                side: 'bottom'
              }}
            >
              <Box
                direction='row'
                gap='medium'
                align='center'
                customStyle='* span { white-space: nowrap }'
                flex={{ grow: 1 }}
              >
                <Text size='xxlarge' color={colors.BLACK} weight={600}>
                  {isNew ? tabTitle : 'Edit Project'}
                </Text>
                {this.renderLabel()}
              </Box>
              <Button plain icon={<FontAwesomeIcon icon={faTimes} />} onClick={this.close} />
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium' }} overflow='auto' wrap={false} width='xxlarge'>
              {this.renderCardBody()}
            </CardBody>
            {this.renderFooter()}
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  user: state.user,
  account: state.account
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(ProjectModal)
