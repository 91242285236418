export const SELECTED_BIDS = 'selectedBids'
export const SUMMARY = 'summary'
export const ALL_BIDS = 'allBids'
export const INVITATIONS = 'invitations'
export const SCOPES_ONLY = 'scopes_only'
export const SELECTED_BIDS_CSV = 'selectedBidsCsv'

export const stringOfType = v => {
  switch (v) {
    case SELECTED_BIDS:
      return 'Selected bids'
    case SUMMARY:
      return 'Summary'
    case ALL_BIDS:
      return 'All Bids'
    case INVITATIONS:
      return 'Invitations'
    case SCOPES_ONLY:
      return 'Scope of work'
  }
}

export const type2code = t => {
  switch (t) {
    case SELECTED_BIDS:
      return 'b'
    case SUMMARY:
      return 's'
    case ALL_BIDS:
      return 'a'
    case INVITATIONS:
      return 'i'
    case SCOPES_ONLY:
      return 'w'
    case SELECTED_BIDS_CSV:
      return 'bc'
  }
}

export const code2type = c => {
  switch (c) {
    case 'b':
      return SELECTED_BIDS
    case 's':
      return SUMMARY
    case 'a':
      return ALL_BIDS
    case 'i':
      return INVITATIONS
    case 'w':
      return SCOPES_ONLY
    case 'bc':
      return SELECTED_BIDS_CSV
  }
}

export default {
  SELECTED_BIDS,
  SUMMARY,
  ALL_BIDS,
  INVITATIONS,
  SCOPES_ONLY,
  SELECTED_BIDS_CSV,
  stringOfType,
  type2code,
  code2type
}
