import React from 'react'
import numeral from 'numeral'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'

const ItemPriceRow = ({ quantity, quantityType, cost, total, compact = false }) => {
  // @jam42, there should be exactly `total`, we do not have to calculate it
  // const totalValue = total || cost * quantity
  const totalValue = total
  return (
    <React.Fragment>
      <Box direction='row' width='120px' justify='between'>
        {quantity && <Box direction='row' align='end'>
          <Text color={colors.TEXT}>{numeral(quantity).format('0,0')}</Text>
          <Text size='small' margin={{ left: '2px' }} color={colors.BROWN_GREY_TWO}>
            {quantityType || 'units'}
          </Text>
        </Box>}
        {!compact && <Box direction='row' margin={{ left: 'small' }} align='end'>
          <Text size='small' color={colors.DARK_GRAY_TWO}>
            $
          </Text>
          <Text color={colors.TEXT} margin={{ left: '1px' }}>
            {numeral(cost).format('0,0.00')}
          </Text>
          {!_.isEmpty(quantityType) && (
            <Text margin={{ left: '1px' }} color={colors.BROWN_GREY_TWO} size='small'>{`/${quantityType}`}</Text>
          )}
        </Box>}
      </Box>
      {!compact && <Box>
        <Text color={colors.LIGHT_NAVY} weight={600}>
          {numeral(totalValue).format('$0,0.00')}
        </Text>
      </Box>}
    </React.Fragment>
  )
}

export default ItemPriceRow
