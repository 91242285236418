import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, DropButton } from 'grommet'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import AddPlugArrow from 'components/leveling/AddPlugArrow'
import LineItemPlug from 'components/leveling/LineItemPlug'
import colors from 'shared/constants/colors'
import NewLineItemDropContent from 'components/leveling/NewLineItemDropContent'
import CancelRemovingRequestDropContent from 'components/leveling/CancelRemovingRequestDropContent'
import CellContainer from 'components/leveling/CellContainer'
import StatusExcluded from 'components/leveling/cellElements/StatusExcluded'
import StatusIncluded from 'components/leveling/cellElements/StatusIncluded'
import ItemTotal from 'components/leveling/cellElements/ItemTotal'
import ActionRequiredLabel from 'components/leveling/cellElements/ActionRequiredLabel'
import MessagesIcon from 'components/leveling/MessagesIcon'
import StatusRemovalRequested from 'components/leveling/cellElements/StatusRemovalRequested'
import { getName } from 'shared/utils/stringUtils'

const UnapprovedItemCell = ({
  lineItemBid,
  item,
  activeCell,
  inv,
  selectedAlternate,
  onNewLineItemAction,
  removalRequest,
  onClick,
  id,
  plug,
  selected,
  hasNewMessage,
  hasMessages,
  accountsProfiles,
  compact
}) => {
  const companyName = getName(_.get(accountsProfiles, _.get(inv, 'id')))
  const itemId = _.get(item, 'id')
  const [dropOpen, setDropOpen] = useState(activeCell === `${inv.id}_${itemId}`)
  const onDropOpen = () => {
    setDropOpen(true)
  }
  const onDropClose = () => {
    setDropOpen(false)
  }

  const altItem = _.get(inv, ['bid', 'lineItems', itemId, 'alternates', selectedAlternate])
  const removalDenied = _.get(removalRequest, 'declined')
  const total = _.get(altItem, 'total', _.get(lineItemBid, 'total'))

  const onAction = action => {
    onDropClose()
    onNewLineItemAction(action, inv.id, itemId)
  }

  const unapprovedItemLabel = useMemo(() => {
    if (_.isEmpty(removalRequest)) {
      return <ActionRequiredLabel />
    } else {
      return (
        <Box pad={{ right: 'xsmall', top: 'xxsmall' }}>
          <FontAwesomeIcon icon={faAngleDown} size={13} color={colors.LIGHT_NAVY_BRIGHT} />
        </Box>
      )
    }
  }, [removalRequest])

  const dropContent = !_.isEmpty(removalRequest) ? (
    <CancelRemovingRequestDropContent onAction={onAction} companyName={companyName} />
  ) : (
    <NewLineItemDropContent onAction={onAction} companyName={companyName} dropContentProps={{ marginX: 0 }} />
  )

  const onDropClick = event => {
    event.stopPropagation()
    onDropOpen()
  }

  let itemAction = (
    <DropButton
      plain
      onClose={onDropClose}
      onOpen={onDropOpen}
      open={dropOpen}
      label={unapprovedItemLabel}
      dropAlign={{ top: 'bottom', left: 'right' }}
      dropProps={{ style: { overflow: 'visible' } }}
      dropContent={dropContent}
      onClick={onDropClick}
    />
  )

  if (!_.isEmpty(removalRequest) && removalDenied) {
    itemAction = null
  }

  const rendeLeftTopBadge = () => {
    const excluded = _.get(lineItemBid, 'excluded', false)
    if (!_.isEmpty(removalRequest)) {
      console.log('%cremovalRequest', 'color: red;', removalRequest)
      return <StatusRemovalRequested isDeclined={_.get(removalRequest, 'declined', false)} />
    } else if (excluded) {
      return <StatusExcluded />
    } else if (_.isNil(total)) {
      return <StatusIncluded />
    } else {
      return <ItemTotal total={total} />
    }
  }

  const plugVisible = _.get(lineItemBid, 'excluded', false)

  if (compact) {
    return (
      <CellContainer selected={selected} onClick={onClick} fill direction='row' justify='between' pad={{ right: 'xxsmall' }}>
        <Box>{rendeLeftTopBadge()}</Box>
        <Box direction='row' fill='vertical' justify='center'>
          {itemAction}
          <Box fill='vertical' justify='center'>
            {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} size={16} pad='xsmall' customStyle={null} />}
          </Box>
          <Box fill='vertical' justify='center'>
            {plugVisible && !_.isNil(plug) ? <LineItemPlug plug={plug} compact /> : <Box />}
          </Box>
        </Box>
      </CellContainer>
    )
  }

  return (
    <CellContainer selected={selected} onClick={onClick} fill>
      <Box direction='row' justify='between'>
        <Box>{rendeLeftTopBadge()}</Box>
        <Box fill='vertical' justify='start'>{itemAction}</Box>
      </Box>
      {plugVisible && <AddPlugArrow />}
      <Box direction='row' align='center' justify='between' pad={{ left: 'xsmall', right: 'xsmall', bottom: 'xsmall' }}>
        {plugVisible ? <LineItemPlug plug={plug} /> : <Box />}
        {hasMessages && <MessagesIcon hasNewMessage={hasNewMessage} />}
      </Box>
    </CellContainer>
  )
}

UnapprovedItemCell.propTypes = {
  lineItemBid: PropTypes.object,
  item: PropTypes.object,
  activeCell: PropTypes.string,
  inv: PropTypes.object,
  selectedAlternate: PropTypes.string,
  onNewLineItemAction: PropTypes.func,
  removalRequest: PropTypes.object
}

const mapStateToProps = state => ({
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(UnapprovedItemCell)
