import { PAGE_WIDTH } from 'shared/constants/documents'
export const FONT_SIZE = 15
import _ from 'lodash'

// letter: 612 x 791
// A4: 595 pixels x 842

export function getSizeF (viewport, paddingHorizontalK, ssr) {
  if (ssr) {
    return v => v * (1 - paddingHorizontalK * 2)
  } else {
    return v => {
      const k = (viewport.width * (1 - paddingHorizontalK * 2)) / PAGE_WIDTH
      return k * v
    }
  }
}
