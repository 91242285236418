import { createSelector } from 'reselect'
import _ from 'lodash'

import { getBids, getWorkOrders, getProjects, getUser } from 'model/selectors/base'

export const getFilesDict = createSelector(
  [getBids, getProjects, getWorkOrders, getUser],
  (bids, projects, workOrders, user) => {
    const res = {}
    _.forEach(workOrders, wo => {
      _.forEach(wo.files, (f, fId) => _.set(res, fId, f))
    })
    _.forEach(projects, p => {
      _.forEach(p.attachments, (f, fId) => _.set(res, fId, f))
    })
    _.forEach(bids, bid => {
      _.forEach(bid.files, (f, fId) => _.set(res, fId, f))
    })
    const drafts = _.get(user, 'quoteDrafts', {})
    _.forEach(drafts, draft => {
      _.forEach(draft.files, (f, fId) => _.set(res, fId, f))
    })
    return res
  }
)
