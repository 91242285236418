import React, { Component } from 'react'
import { Box, Anchor, Text } from 'grommet'
import validator from 'validator'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import AuthLayout from 'pages/auth/AuthLayout'
import { appName } from 'constants/index'
import signInMode from 'constants/signInMode'
import { signIn } from 'controllers/auth'
import { updateUserProfileAsync } from 'controllers/profiles'
import localStorage from 'controllers/localStorage'

const FOOTER = (
  <Box
    alignSelf='start'
    pad={{ top: 'medium' }}
    border={{ side: 'top', color: colors.VERY_LIGHT_GREY_TWO }}
    margin={{ top: 'medium' }}
    width='medium'
    direction='row'
    gap='xsmall'
    align='center'
  >
    <Text size='medium' color={colors.TEXT}>
      Already have an account?
    </Text>
    <Anchor
      label='Sign in'
      color={colors.LINK}
      onClick={() => universalNavigation.push(screens.ENTER_PHONE, { mode: signInMode.SIGN_IN })}
    />
  </Box>
)

class EnterEmail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      isValid: false,
      isPrecon: false
    }
  }

  componentDidMount = async () => {
    const preconConfId = await localStorage.get('preconConfId')
    if (!_.isEmpty(preconConfId)) {
      this.setState({ isPrecon: true })
    }
  }

  onSubmit = async () => {
    const { email } = this.state
    const { authToken } = this.props
    if (authToken) {
      const authData = await signIn(authToken)
      console.log('%cEnterEmail authData', 'color: antiquewhite;', authData)
      if (_.has(authData, 'user.uid')) {
        await updateUserProfileAsync(authData.user.uid, { email, emails: [email] })
      }
    } else {
      universalNavigation.push(screens.ENTER_PHONE, { email, mode: signInMode.SIGN_UP })
    }
  }

  onEmailChange = event => {
    const email = event.target.value
    const isValid = validator.isEmail(email)
    this.setState({ email, isValid })
  }

  render () {
    const { email, isValid, isPrecon } = this.state
    return (
      <AuthLayout
        header={isPrecon ? `Get started with ${appName}` : `Try ${appName} for free`}
        error={null}
        isError={false}
        valueName='Email'
        value={email}
        placeholder='name@company.com'
        handleChange={this.onEmailChange}
        handleSubmit={this.onSubmit}
        isValid={isValid}
        footer={FOOTER}
        submitName={isPrecon ? 'Continue' : 'Try for free'}
        subheader={
          isPrecon
            ? 'Create your Mason account in a few easy steps'
            : 'We will text you a verification code to complete your registration.'
        }
      />
    )
  }
}

export default EnterEmail
