import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormField, TextInput, ThemeContext } from 'grommet'
import _ from 'lodash'

import { getTradesDict } from 'model/selectors/references'

const TradeSelect = ({ onSelect, tradeId, contractorTypes, disabled, formFieldProps = {} }) => {
  const allSuggestions = _.map(contractorTypes, ct => ({ label: ct.name, value: ct.id }))
  const [value, setValue] = useState(_.get(contractorTypes, [tradeId, 'name'], ''))
  const [error, setError] = useState(null)
  const onChange = e => {
    const v = e.target.value
    const existingCT = _.find(contractorTypes, ct => _.lowerCase(ct.name) === _.lowerCase(v))
    setValue(_.get(existingCT, 'name', v).replace(/\\/g, ''))
    onSelect(_.get(existingCT, 'id', null))
  }

  const onSuggestionSelect = e => {
    // console.log('suggestion selected', e.suggestion)
    const ctId = _.get(e, 'suggestion.value')
    const trade = _.get(e, 'suggestion.label')
    // console.log('onSuggestionSelect', ctId, trade)
    onSelect(ctId)
    setValue(trade)
  }

  const onBlur = () => {
    if (_.isNil(tradeId)) {
      setError('Choose one from the list')
    }
  }

  const onFocus = () => {
    setError(null)
  }

  const reg = new RegExp(value || '', 'gi')
  let filteredSuggestions = allSuggestions
  if (!_.isEmpty(value)) {
    filteredSuggestions = _.reduce(
      contractorTypes,
      (res, ct) => {
        if (_.get(ct, 'name', '').match(reg)) res.push({ label: ct.name, value: ct.id })
        return res
      },
      []
    )
  }
  // console.log('suggestions', filteredSuggestions)
  if (_.size(filteredSuggestions) === 1 && _.get(filteredSuggestions, [0, 'label']) === value) filteredSuggestions = []
  return (
    <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
      <FormField
        label='Trade'
        margin={{ right: 'small' }}
        width='medium'
        error={error}
        round={'small'}
        height={{ min: '120px' }}
        {...formFieldProps}
      >
        <TextInput
          placeholder='Select a trade...'
          color='dark-1'
          suggestions={filteredSuggestions}
          value={value}
          onChange={onChange}
          onSuggestionSelect={onSuggestionSelect}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
      </FormField>
    </ThemeContext.Extend>
  )
}

TradeSelect.propTypes = {
  onSelect: PropTypes.func,
  tradeId: PropTypes.string,
  disabled: PropTypes.bool,
  formFieldProps: PropTypes.object
}

const mapStateToProps = state => ({
  contractorTypes: getTradesDict(state)
})

export default connect(mapStateToProps)(TradeSelect)
