import _ from 'lodash'
import { createSelector } from 'reselect'

export const getFromProps = (props, paramName) => {
  return _.get(props, paramName, _.get(props, ['route', 'params', paramName]))
}

export const getProjectId = (state, props) => getFromProps(props, 'projectId')
export const getWorkOrderId = (state, props) => getFromProps(props, 'workOrderId')
export const getPrId = (state, props) => getFromProps(props, 'prId')

export const sortByTimeAndSeen = requests => {
  return _.orderBy(requests, ['needsAction', 'seen', 'timestamp'], ['desc', 'asc', 'desc'])
  // return requests.sort((x, y) => y.timestamp - x.timestamp).sort((x, y) => (Boolean(x.seen) === Boolean(y.seen)) ? 0 : x.seen ? 1 : -1)
}

export const getBids = state => state.bids
const _getWorkOrders = state => state.workOrders
const _getPrivateWorkOrders = state => state.privateWorkOrders
export const getAccountAdmins = state => _.get(state, 'account.admins', {})
export const getAccountId = state => _.get(state, 'account.id')
export const getAccount = state => state.account
export const getAccountsProfiles = state => state.accountsProfiles
export const getUser = state => state.user
export const getUserId = state => _.get(state, 'user.id')
export const getProfiles = state => state.profiles
export const getPaymentRequests = state => state.paymentRequests
export const getReimbursementRequests = state => state.reimbursementRequests
export const getProposals = state => _.get(state, 'proposals')
export const getProjects = state => _.get(state, 'projects')
export const getAppointments = state => _.get(state, 'appointments')
export const getSupplyOrders = state => _.get(state, 'supplyOrders')
export const getSuppliers = state => _.get(state, 'suppliers')
export const getReferences = state => _.get(state, 'references')
export const getContractorTypes = state => _.get(state, 'references.contractorTypes')
export const getAppointmentsOffersRaw = state => state.appointmentsOffers
export const getGCAppointments = state => state.gcAppointments
export const getInbox = state => state.inbox
export const getChannel = state => state.channelWeb
export const getImportedLi = state => state.importedLi
export const getAppointmentsOffers = state => {
  const pending = _.get(state, 'appointmentsOffers.pending', {})
  const missing = _.get(state, 'appointmentsOffers.missing', {})
  return { ...pending, ...missing }
}

export const stringServiceTypes = (serviceTypes, projectTypes) => {
  const r = []
  _.forEach(serviceTypes, stId => {
    const name = _.get(projectTypes, [stId, 'name'], '')
    if (!_.isEmpty(name)) r.push(name)
  })
  return _.join(r, ', ')
}

export const getWorkOrders = createSelector(
  [_getWorkOrders, _getPrivateWorkOrders, getAccountId],
  (workOrders, privateWorkOrders, accountId) => {
    const res = {}
    _.forEach(workOrders, (wo, woId) => {
      const newWo = {
        ...wo,
        id: woId,
        alternatesConf: _.get(privateWorkOrders, [woId, 'alternatesConf'], {}),
        plugs: _.get(privateWorkOrders, [woId, 'plugs', accountId], {}),
        merging: _.get(privateWorkOrders, [woId, 'merging'], {})
      }
      _.set(res, woId, newWo)
    })
    return res
  }
)

export const getAppLoaded = createSelector(
  [_getWorkOrders, getBids, getAccount, getUser, getProjects, getAccountsProfiles],
  (workOrders, bids, account, user, projects, accountsProfiles) => {
    const lst = [workOrders, bids, account, user, projects, accountsProfiles]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
