import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'grommet'
import colors from 'shared/constants/colors'
// import DropDownMenu from 'components/DropDownMenu'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
// import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import { WithTags } from 'components/contacts/TagInput'

const ContactPanelContent = ({
  contactIds,
  onClose,
  onUpdate,
  onDelete,
  contacts,
  sidePanelCompanyId,
  updateSidePanelCompanyId,
  editTags,
  setEditTags
}) => {
  // const _contacts = _.get(contacts, 'contacts', {})
  const _companies = _.get(contacts, 'companies', {})

  let companyMenuOptions = []
  _.map(_.keys(_companies), companyId => {
    companyMenuOptions.push({
      id: companyId,
      label: _.get(_companies, `${companyId}.name`, '')
    })
  })
  companyMenuOptions = _.orderBy(companyMenuOptions, ['label'], ['asc'])

  _.map(contactIds, contactId => {
    // const contact = _.get(_contacts, contactId, {})
    // const companyId = _.get(contact, 'companyId', '')
    // const company = _.get(_companies, companyId, {})
  })

  // const handleCompanyClick = id => {
  //   updateSidePanelCompanyId(id)
  // }

  const deleteContacts = () => {
    onDelete(contactIds)
  }

  const updateContacts = () => {
    // if (!sidePanelCompanyId) return
    // onUpdate(sidePanelCompanyId, contactIds, editTags)
    onUpdate(contactIds, editTags)
  }

  // const renderCompanySelector = () => {
  //   return (
  //     <Box pad={{ horizontal: 'small' }} gap='small' margin={{ top: 'medium' }}>
  //       <Text size='medium' color={colors.CONTACT_LABEL}>Company</Text>
  //       <DropDownMenu
  //         options={companyMenuOptions}
  //         dropContentProps={{ boxProps: { width: { min: '260px' } } }}
  //         dropAlign={{ top: 'bottom', left: 'left' }}
  //         onOptionClick={handleCompanyClick}
  //         disabled={false}
  //       >
  //         <Box
  //           gap='xsmall'
  //           hoverTextColor={colors.WHITE}
  //           secondary
  //           direction='row'
  //           align='center'
  //           border={{ side: 'all', color: colors.VERY_LIGHT_GREY_TWO, size: '1px' }}
  //           pad={{ horizontal: 'small', vertical: 'xsmall' }}
  //           justify='between'
  //           round='xsmall'
  //         >
  //           <Text size='small' color={colors.TEXT_PRIMARY} style={{ fontWeight: 400 }}>
  //             {_.get(_companies, `${sidePanelCompanyId}.name`, '')}
  //           </Text>
  //           <FontAwesomeIcon color={colors.TEXT_PRIMARY} icon={faAngleDown} size={18} />
  //         </Box>
  //       </DropDownMenu>
  //     </Box>
  //   )
  // }

  const renderTags = () => {
    return (
      <Box pad={{ horizontal: 'small' }} gap='small' margin={{ top: 'medium' }}>
        <Text>Tags</Text>
        <WithTags
          tags={editTags}
          updateTags={tags => {
            setEditTags(tags)
          }}
          allSuggestions={_.get(contacts, 'tags', {})}
        />
      </Box>
    )
  }

  return (
    <Box justify='between' fill pad={{ right: 'small' }}>
      <Box
        pad={{ vertical: 'small', horizontal: 'small' }}
        border={{
          side: 'bottom'
        }}
      >
        <Text size='medium' style={{ fontWeight: 'bold' }} color={colors.TEXT_PRIMARY}>
          {`Bulk edit ${contactIds.length} contact${contactIds.length > 1 ? 's' : ''}`}
        </Text>
      </Box>
      <Box fill>
        {/* {renderCompanySelector()} */}
        {renderTags()}
      </Box>
      <Box
        justify='between'
        align='center'
        direction='row'
        pad={{ right: 'medium', left: 'small', vertical: 'medium' }}
        background={colors.PALE_GREY}
      >
        <Box
          hoverTextColor={colors.WHITE}
          align='center'
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          justify='center'
          onClick={() => {
            deleteContacts()
          }}
        >
          <Text size='small' color={colors.CORAL_TWO} style={{ fontWeight: 400 }}>
            {`Delete ${contactIds.length} contact${contactIds.length > 1 ? 's' : ''}`}
          </Text>
        </Box>

        <Box direction='row' align='center' gap='small'>
          <Box
            hoverTextColor={colors.WHITE}
            align='center'
            border={{ side: 'all', color: colors.LIGHT_NAVY_BRIGHT }}
            pad={{ horizontal: 'medium', vertical: 'xsmall' }}
            justify='center'
            round='xsmall'
            onClick={onClose}
          >
            <Text size='small' color={colors.LIGHT_NAVY_BRIGHT} style={{ fontWeight: 400 }}>
              Cancel
            </Text>
          </Box>
          <Box
            hoverTextColor={colors.WHITE}
            align='center'
            border={{ side: 'all' }}
            pad={{ horizontal: 'medium', vertical: 'xsmall' }}
            justify='center'
            background={colors.AQUA_MARINE}
            round='xsmall'
            onClick={() => {
              updateContacts()
            }}
          >
            <Text size='small' color={colors.WHITE} style={{ fontWeight: 400 }}>
              Update
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

ContactPanelContent.propTypes = {
  contactIds: PropTypes.array,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  contacts: PropTypes.object
}

export default ContactPanelContent
