import React from 'react'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'

const Subheader = ({ name }) => {
  return (
    <Box direction='row' justify='between' pad={{ vertical: 'small', horizontal: 'small' }} align='center' gap='medium'>
      <Text size='medium' weight={600} color={colors.BLACK}>
        Comments
      </Text>
      <Text size='small' weight={400} color={colors.ANOTHER_GREY}>
        Only {name} can see these comments
      </Text>
    </Box>
  )
}

export default Subheader
