import React, { useState, useCallback } from 'react'
import { Box, Button } from 'grommet'
import styled from 'styled-components'
import _ from 'lodash'

import LineItemTableRow from 'components/createBid/LineItemTableRow'
import { generateId } from 'constants/firebase'
import lineItemSection from 'shared/constants/lineItemSection'
import lineItemType from 'shared/constants/lineItemType'

const GridRow = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 26px 3.2fr 240px 5.2fr 60px;
`

const AddItemButton = ({
  sectionIndex,
  isGeneralExclusions,
  createLineItem,
  selectRow,
  unselectRow,
  isDraggingOver,
  disabled,
  typeOfAddedLi,
  setTypeOfAddedLi,
  resetTypeOfAddedLi,
  sectionId
}) => {
  const [lineItemMode, setLineItemMode] = useState(false)
  const [liId, setLiId] = useState()
  const isQuestion = sectionId === lineItemSection.QUESTIONS_ID

  const _selectRow = useCallback(() => {
    selectRow(liId)
  }, [selectRow, liId])

  const _unselectRow = useCallback(() => {
    unselectRow(liId)
    resetTypeOfAddedLi()
  }, [unselectRow, liId])

  const toCreateLineItemMode = () => {
    if (isQuestion && _.isEqual(typeOfAddedLi, lineItemType.DEFAULT)) {
      setTypeOfAddedLi(isQuestion ? lineItemType.QUESTION_BOOL : lineItemType.DEFAULT)
    }
    setLiId(generateId())
    setLineItemMode(true)
  }

  const removeLineItem = () => {
    setLineItemMode(false)
  }

  const updateItem = (itemId, params, parentLi) => {
    console.log('update line item', itemId, params, parentLi)
    const li = {
      id: liId,
      type: typeOfAddedLi,
      ...params
    }
    createLineItem(li, sectionIndex)
    setLineItemMode(false)
    resetTypeOfAddedLi()
  }

  const label = isGeneralExclusions ? 'Add general exclusion...' : 'Add line item...'
  if (lineItemMode) {
    const li = {
      id: liId,
      type: typeOfAddedLi,
      name: ''
    }
    return (
      <LineItemTableRow
        key={liId}
        rowId={liId}
        li={li}
        updateItem={updateItem}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        removeLineItem={removeLineItem}
        cannotAddToTemplate
      />
    )
  } else {
    return (
      <GridRow key={liId}>
        <Box />
        <Box
          pad={{ left: 'medium', top: 'xxsmall' }}
          key={`add_to_scope_button_${sectionIndex}`}
          height={'52px'}
          direction='row'
          align='start'
        >
          <Button
            label={label}
            onClick={toCreateLineItemMode}
            size='small'
            id={`add_li_button_${sectionIndex}`}
            disabled={isDraggingOver || disabled}
          />
        </Box>
        <Box />
        <Box />
        <Box />
      </GridRow>
    )
  }
}

export default AddItemButton
