import { auth, db, generateId } from 'constants/firebase'
import Sentry from 'shared/utils/sentry'
import _ from 'lodash'
import Constants from 'expo-constants'
import qs from 'qs'

import { addListener, clearListeners, clearUserListener, setUserUnsubscribe } from 'controllers/listeners'
import { fetchReferences, isOldVersion } from 'controllers/data'
import { clear, logoutUser, receiveUser } from 'model/actions/userAC'
import store from 'model/store'
import { receiveAccount } from 'model/actions/accountAC'
import screens from 'constants/screens'
import { fetchChannelWeb } from 'controllers/chat'
import { fetchPaymentRequests } from 'controllers/paymentRequests'
import { fetchSupplyOrders } from 'controllers/supplyOrders'
import { fetchAppointments, fetchAppointmentsOffers } from 'controllers/appointments'
import { fetchSuppliers } from 'controllers/suppliers'
import { fetchReimbursementRequests } from 'controllers/reimbursementRequests'
import { registerForPushNotificationsAsync } from 'shared/controllers/notifications'
import { isWeb } from 'shared/constants/index'
import history from 'shared/utils/history'
import { fetchProjects } from 'controllers/project'
import { fetchProposals } from 'controllers/proposals'
import { fetchWorkOrders } from 'controllers/workOrder'
import { fetchPrivateWorkOrders } from 'controllers/privateWorkOrder'
import { fetchAccountsProfiles, fetchUsersProfiles, fetchOwnAccountsProfiles, fetchMasonAdmins } from 'controllers/profiles'
import { fetchBids } from 'controllers/bids'
import universalNavigation from 'utils/universalNavigation'
// import locationTracking from 'controllers/locationTracking'
import { trackAppInitialization } from 'controllers/zapier'
import { fetchOutgoingInvitations, fetchIncomingInvitations } from 'controllers/invitations'
import { fetchGCAppointments } from 'controllers/gcAppointments'
import analytics from 'utils/analytics'
import { receiveAuthData } from 'model/actions/authDataAC'
import { resetOpenPageOnAuth, getOpenPageOnAuth, setOpenPageOnAuth, setPreconConfId } from 'controllers/auth'
import { fetchContacts } from 'controllers/contacts'
import { fetchInbox } from 'controllers/inbox'
import { fetchTakeoffsQuotes } from 'controllers/takeoffs'

export const navigateToHomePage = () => {
  return async function (dispatch, getState) {
    console.log('%cnavigateToHomePage', 'color: orange;')
    if (isWeb) {
      const state = getState()
      const isGC = _.get(state, 'account.isGC', false)
      const screenName = isGC ? screens.GC_APP : screens.SUB_APP
      const openPage = await getOpenPageOnAuth()
      if (_.isEmpty(openPage)) {
        universalNavigation.navigate(screenName)
      } else {
        console.log('navigate to open page on auth')
        await resetOpenPageOnAuth()
        universalNavigation.push(openPage.screen, openPage.params)
      }
    } else {
      universalNavigation.push(screens.APP)
    }
  }
}

function fetchAccount (accountId) {
  try {
    const accountUnsubscribe = db
      .collection('accounts')
      .doc(accountId)
      .onSnapshot(
        docSN => {
          const account = docSN.data()
          store.dispatch(receiveAccount(account))
        },
        err => {
          console.log(`fetch account error: ${err}`)
          Sentry.captureException(err)
        }
      )
    addListener('account', accountUnsubscribe)
  } catch (e) {
    console.log('fetch account error', e)
    Sentry.captureException(e)
  }
}

const getAccount = async accountId => {
  const accSN = await db
    .collection('accounts')
    .doc(accountId)
    .get()
  return accSN.data()
}

async function initAccountData (user) {
  console.log('initAccountData, userId', user.id)
  console.log('user: ', user)
  const currentAccountId = _.get(user, 'currentAccountId')
  console.log('initAccountData: currentAccountId', currentAccountId)
  if (currentAccountId) {
    const account = await getAccount(currentAccountId)
    fetchAccount(currentAccountId)
    fetchProjects(currentAccountId)
    fetchProposals(currentAccountId)
    fetchPaymentRequests(currentAccountId)
    fetchReimbursementRequests(currentAccountId)
    fetchSuppliers(currentAccountId)
    fetchSupplyOrders(currentAccountId)
    fetchWorkOrders(currentAccountId, account.isGC)
    fetchPrivateWorkOrders(currentAccountId)
    // fetchChannels(currentAccountId)
    fetchChannelWeb(user.id)
    fetchUsersProfiles(currentAccountId, user.id)
    fetchMasonAdmins()
    fetchAccountsProfiles(currentAccountId)
    fetchOwnAccountsProfiles(user)
    fetchBids(currentAccountId)
    fetchOutgoingInvitations(currentAccountId)
    fetchAppointments(currentAccountId)
    fetchAppointmentsOffers(currentAccountId)
    fetchGCAppointments(currentAccountId)
    fetchContacts(currentAccountId)
    // fetchMessagesInbox(currentAccountId, user.id)
    fetchInbox(user.id, currentAccountId)
    const isMasonAdmin = _.get(user, 'isMasonAdmin', false)
    if (isMasonAdmin) {
      fetchTakeoffsQuotes(currentAccountId)
    }
  } else {
    const e = new Error(`User ${user.id} has no currentAccountId`)
    Sentry.captureException(e)
  }
}

async function init () {
  console.log('---- init ----')
  store.dispatch(fetchReferences())
  fetchIncomingInvitations()
}

let existingUser = null

async function userChanged (user, isLocalChange) {
  try {
    // check if account switched
    const currentAccountNow = _.get(existingUser, 'currentAccountId')
    console.log('%cuser changed, currentAccountNow', 'color: lightgreen', currentAccountNow)
    console.log('%cuser changed, newAccountId', 'color: lightgreen', _.get(user, 'currentAccountId'))
    if (isWeb) {
      const queryParams = qs.parse(_.get(window, 'location.search', ''), { ignoreQueryPrefix: true })
      const toAccountId = _.get(queryParams, 'accountId')
      console.log('%ctoAccountId', 'color: yellow', toAccountId)
      if (!_.isNil(toAccountId)) {
        history.replace(window.location.pathname)
        if (_.includes(user.adminOfAccounts, toAccountId)) {
          console.log('is account admin true')
          const dbUserAccountId = _.get(user, 'currentAccountId')
          console.log('dbUserAccountId', dbUserAccountId, 'toAccountId', toAccountId)
          if (toAccountId !== dbUserAccountId) {
            console.log('need to switch acount true')
            await db
              .collection('users')
              .doc(user.id)
              .update({ currentAccountId: toAccountId })
            return null
          }
        }
      }
    }
    if (!currentAccountNow && _.has(user, 'currentAccountId') && !isLocalChange) {
      existingUser = user
      store.dispatch(receiveUser(user))
      await initAccountData(user)
    } else if (currentAccountNow !== user.currentAccountId && !isLocalChange) {
      existingUser = user
      store.dispatch(clear())
      clearListeners()
      store.dispatch(receiveUser(user))
      const screenName = await getDestinationScreen(user.id)
      console.log('currentAccountNow', currentAccountNow)
      console.log('screenName', screenName)
      await navigateToStart(screenName)
      await initAccountData(user)
    } else if (!isLocalChange) {
      existingUser = user
      store.dispatch(receiveUser(user))
    } else {
      store.dispatch(receiveUser(user))
    }
    // locationTracking.trackLocation(user)
  } catch (e) {
    console.log('userChanged error', e)
  }
}

async function fetchUser (authData) {
  console.log('fetch user', authData.uid)
  try {
    clearUserListener()
    const userId = authData.uid
    const unsubscribe = await db
      .collection('users')
      .doc(userId)
      .onSnapshot(
        { includeMetadataChanges: true },
        userSN => {
          const isLocalChange = userSN.metadata.hasPendingWrites
          console.log('isLocalChange', isLocalChange)
          const user = userSN.data() || {}
          // console.log('user received', user)
          userChanged(user, isLocalChange)
        },
        err => console.warn('fetch user error', err)
      )
    setUserUnsubscribe(unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch user error', e)
  }
}

const unsubscribeFromNotifications = () => null

const processDeppLinkParams = params => {
  const p = _.get(params, '$deeplink_path')
  const paramsId = _.get(params, 'params.id')
  if (p === 'appointmentOffer') {
    universalNavigation.navigate(screens.APPOINTMENT_OFFER, { appointmentId: paramsId })
  } else if (p === 'bidInvite') {
    universalNavigation.navigate(screens.VIEW_WORK_ORDER, { workOrderId: paramsId })
  }
}

export const processDeepLinkBundle = bundle => {
  // const tmpid = `${generate(_.now())}_listener`
  // db.collection('tmp').doc(tmpid).set(bundle)
  processDeppLinkParams(_.get(bundle, 'params'))
}

let branchUnsubscribe = null

const initBranch = async () => {
  console.log('initBranch', Constants.appOwnership)
  if (Constants.appOwnership === 'standalone') {
    const ExpoBranch = await import('expo-branch')
    const Branch = ExpoBranch.default
    // Alert.alert(`branch ${typeof ExpoBranch} | ${typeof Branch}`)
    branchUnsubscribe = Branch.subscribe(bundle => {
      if (bundle && bundle.params && !bundle.error) {
        processDeepLinkBundle(bundle)
      }
    })
  }
}

const getDestinationScreen = async uid => {
  try {
    const userSN = await db
      .collection('users')
      .doc(uid)
      .get()
    const user = userSN.data()
    // console.log('getDestinationScreen user', user)
    const userProfileSN = await db
      .collection('usersProfiles')
      .doc(uid)
      .get()
    const profile = userProfileSN.data()
    // console.log('getDestinationScreen profile', profile)
    const accountId = _.get(user, 'currentAccountId')
    // console.log('getDestinationScreen accountId', accountId)
    const accountProfileSN = await db
      .collection('accountsProfiles')
      .doc(accountId)
      .get()
    const accountProfile = accountProfileSN.data()
    // console.log('getDestinationScreen accountProfile', accountProfile)
    const accountSN = await db
      .collection('accounts')
      .doc(accountId)
      .get()
    const account = accountSN.data()
    // console.log('getDestinationScreen account', account)
    // const isAccountCreator = accountId === user.id
    // console.log('is account creator:', isAccountCreator)
    store.dispatch(trackAppInitialization(profile, accountId, accountProfile))
    const onboardingComplete = _.get(account, ['admins', user.id, 'onboardingComplete'], false)
    console.log('onboardingComplete', onboardingComplete)

    if (isOldVersion) return

    if (onboardingComplete) {
      if (isWeb) {
        if (_.get(account, 'isGC', false)) {
          return screens.GC_APP
        } else {
          return screens.SUB_APP
        }
      } else {
        return screens.APP
      }
    } else {
      return screens.ONBOARDING
    }
  } catch (e) {
    console.log('getDestinationScreen error', e)
    Sentry.captureException(e)
    return screens.APP
  }
}

const getPreconConf = async () => {
  const pathname = _.trim(history.location.pathname, '/')
  const preconConfsSN = await db
    .collection('preconConfs')
    .where('path', '==', pathname)
    .limit(1)
    .get()
  if (_.size(preconConfsSN.docs) > 0) {
    return _.get(preconConfsSN.docs, 0).data()
  } else {
    return null
  }
}

const navigateToStart = async screenName => {
  console.log('destination screen is', screenName)
  let needToRedirect = true
  if (isWeb && screenName !== screens.ONBOARDING) {
    const openPage = await getOpenPageOnAuth()
    const preconConf = await getPreconConf()
    console.log('preconConf', preconConf)
    if (!_.isEmpty(preconConf)) {
      needToRedirect = false
      universalNavigation.navigate(screens.PRECON_PROJECT, { preconConfId: preconConf.id, projectId: generateId() })
    } else if (_.isEmpty(openPage)) {
      const pathname = history.location.pathname
      console.log('pathName', pathname)
      if (screenName === screens.GC_APP && _.startsWith(pathname, '/gc')) needToRedirect = false
      if (screenName === screens.SUB_APP && _.startsWith(pathname, '/sub')) needToRedirect = false
      if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
      if (_.startsWith(pathname, '/invitation')) needToRedirect = false
      if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
      if (_.startsWith(pathname, '/workorderfile')) needToRedirect = false
      if (_.startsWith(pathname, '/projectfile')) needToRedirect = false
      if (_.startsWith(pathname, '/precon')) needToRedirect = false
    } else {
      console.log('navigate to open page on auth')
      await resetOpenPageOnAuth()
      needToRedirect = false
      universalNavigation.push(openPage.screen, openPage.params)
    }
  }
  console.log('navigateToStart. need to redirect', needToRedirect)
  if (needToRedirect) universalNavigation.navigate(screenName)
}

async function onAuthStateChanged (authData, handleNotification) {
  if (authData) {
    console.log('authData', authData)
    store.dispatch(receiveAuthData(authData))
    analytics.trackUserId(authData.uid)
    init()
    const screenName = await getDestinationScreen(authData.uid)
    await navigateToStart(screenName)
    await fetchUser(authData)
    if (handleNotification) {
      setTimeout(handleNotification, 100)
    }
    if (!isWeb) {
      await registerForPushNotificationsAsync(authData.uid)
    }
    await initBranch()
  } else {
    store.dispatch(receiveAuthData({}))
    clearUserListener()
    clearListeners()
    unsubscribeFromNotifications()
    let needToRedirect = true
    let isSignIn = true
    if (isWeb) {
      const preconConf = await getPreconConf()
      console.log('preconConf no auth', preconConf)
      if (!_.isEmpty(preconConf)) {
        await setPreconConfId(preconConf.id)
        await setOpenPageOnAuth(screens.PRECON_PROJECT, { preconConfId: preconConf.id, projectId: generateId() })
        isSignIn = false
      }
      const pathname = history.location.pathname
      if (_.startsWith(pathname, '/acceptinvite')) needToRedirect = false
      if (_.startsWith(pathname, '/invitation')) needToRedirect = false
      if (_.startsWith(pathname, '/subproposal')) needToRedirect = false
      if (_.startsWith(pathname, '/auth/enter-email')) needToRedirect = false
    }
    if (needToRedirect) {
      universalNavigation.navigate(isSignIn ? screens.AUTH : screens.ENTER_EMAIL)
    }
    store.dispatch(logoutUser())
    if (branchUnsubscribe) branchUnsubscribe()
  }
}

export function appInitialized (handleNotification) {
  return async function (dispatch, getState) {
    try {
      auth.onAuthStateChanged(authData => onAuthStateChanged(authData, handleNotification))
    } catch (e) {
      Sentry.captureException(e)
      console.log('app initialization error', e)
    }
  }
}
