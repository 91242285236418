import React, { useEffect, useRef } from 'react'
import { Box, Button, Main, Text } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import PdfEditor from 'webPages/pdf/PdfEditor'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import universalNavigation from 'utils/universalNavigation'
import FilesPanel from 'components/panels/FilesPanel'
import { getFilesDict } from 'model/selectors/files'
import { updateAnnotationSeenStatus } from 'controllers/chat'
import CommentsInboxButton from 'components/chat/CommentsInboxButton'
import screens from 'constants/screens'
import FilePreview from 'components/FilePreview'

const WorkOrderFile = ({ workOrderId, workOrder, isGC, fileId, filesDict, selectAnnotationId, account }) => {
  const filesPanelRef = useRef()

  const file = _.get(filesDict, fileId)

  useEffect(() => {
    updateAnnotationSeenStatus(fileId)
  }, [fileId])

  const renderFile = () => {
    const contentType = _.get(file, 'contentType')
    if (contentType === 'application/pdf') {
      return <PdfEditor key={fileId} file={file} workOrderId={workOrderId} selectAnnotationId={selectAnnotationId} />
    } else {
      return <FilePreview file={file} />
    }
  }

  const openFilesPanel = () => {
    filesPanelRef.current.open(workOrderId)
  }

  const goBack = () => {
    const projectId = _.get(workOrder, 'projectId')
    const screen = isGC ? screens.GC_PROJECT_WORKORDER_LEVELING : screens.SUB_CREATE_BID
    universalNavigation.push(screen, { projectId, workOrderId })
  }

  return (
    <Main background={colors.WHITE} flex>
      <Box
        direction='row'
        justify='between'
        align='center'
        pad={{ horizontal: 'medium', vertical: 'small' }}
        border='bottom'
      >
        <Button
          plain
          icon={<FontAwesomeIcon icon={faChevronLeft} color={colors.LIGHT_NAVY_BRIGHT} size={16} />}
          label={
            <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
              Back
            </Text>
          }
          onClick={() => goBack()}
        />

        <Box direction='row' gap='medium'>
          <Box border='right' pad={{ horizontal: 'small' }}>
            <CommentsInboxButton />
          </Box>
          <Button plain onClick={openFilesPanel}>
            <Box direction='row' align='center' gap='small'>
              <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
                {_.get(file, 'name', 'unknown')}
              </Text>
              <FontAwesomeIcon icon={faChevronDown} color={colors.LIGHT_NAVY_BRIGHT} size={16} />
            </Box>
          </Button>
        </Box>
      </Box>
      {renderFile()}
      <FilesPanel ref={filesPanelRef} readOnly />
    </Main>
  )
}

const mapStateToProps = (state, props) => ({
  filesDict: getFilesDict(state),
  workOrder: _.get(state, ['workOrders', props.workOrderId]),
  isGC: _.get(state, 'account.isGC', false)
})

export default connect(mapStateToProps)(WorkOrderFile)
