import React from 'react'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'

const HeaderFirstCell = () => {
  return (
    <Box
      flex
      pad={{ horizontal: 'medium' }}
      align='center'
      justify='between'
      direction='row'
      background={colors.WHITE}
      border={[
        {
          side: 'bottom',
          color: colors.VERY_LIGHT_GREY_TWO
        }
      ]}
    >
      <Text circular size='large' weight={600}>
        Trades
      </Text>
    </Box>
  )
}

export default React.memo(HeaderFirstCell)
