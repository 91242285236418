import React, { useContext } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import { timeZone as defaultTimeZone } from 'shared/constants/index'
import DatePicker from 'components/DatePicker.web'

const ImportantDates = () => {
  const { getField, address, setField } = useContext(CreateBidContext)
  const timeZone = _.get(address, 'timeZone', defaultTimeZone)

  const renderRow = (title, value, onChange) => {
    // console.log('title', title, 'value', value)
    return (
      <Box direction='row' align='center' pad={{ left: 'medium' }}>
        <Box basis='1/2'>
          <Text>{title}</Text>
        </Box>
        <DatePicker
          value={value}
          timeZone={timeZone}
          onChange={onChange}
          placeholder='No date'
          // alignSelf='center'
        />
      </Box>
    )
  }

  return (
    <Box pad={{ top: 'small' }} flex={{ shrink: 0 }}>
      {renderRow('Approximate start', getField('startDate', null), setField('startDate'))}
      {renderRow('Approximate completion', getField('endDate', null), setField('endDate'))}
    </Box>
  )
}

export default ImportantDates
