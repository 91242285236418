import React, { useEffect, useContext } from 'react'
import { Box, ResponsiveContext, Text } from 'grommet'
import _ from 'lodash'
import { connect } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
// import { faComment, faPaperclip, faCalendarAlt, faAngleLeft, faComments } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'
// import moment from 'moment'
// import generate from 'firebase-auto-ids'

import colors from 'shared/constants/colors'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'
// import screens from 'constants/screens'
// import universalNavigation from 'utils/universalNavigation'
import { sendBid } from 'controllers/bids'
import Loading from 'shared/pages/Loading'

import LayoutContext from 'webPages/layout/LayoutContext'
import { fetchMessagesWeb } from 'controllers/chat'
import MobilePlaceholder from 'webPages/layout/MobilePlaceholder'
// import CalendarSidepanel from 'components/CalendarSidepanel'
// import jobOfferStatus from 'shared/constants/jobOfferStatus'
import CreateBidNavBar from 'webPages/createBid/CreateBidNavBar'
import CreateBidContext from 'webPages/createBid/CreateBidContext'
import ProposalPreview from 'webPages/createBid/ProposalPreview'
import ProposalPreviewPanel from 'webPages/createBid/ProposalPreviewPanel'
import { navigateToHomePage } from 'controllers/init'
import { sendResolveRemovalMessage } from 'controllers/workOrder'

const SubProposalPreview = ({ workOrderId, workOrder, account, workOrders, dispatch, bids }) => {
  const { toggleSideMenu } = useContext(LayoutContext)
  const { getField, isSubProposal, tradeId, setHighlightTradeId, canSendBid, getNewBid, bidId } = useContext(
    CreateBidContext
  )
  const size = useContext(ResponsiveContext)
  const accountId = _.get(account, 'id')

  const bid = getNewBid()

  console.log('bid', bid)

  useEffect(() => {
    if (!_.isNil(workOrderId)) fetchMessagesWeb(workOrderId, accountId)
  }, [workOrderId, accountId])

  useEffect(() => toggleSideMenu(false), [])

  const onNextClick = () => {
    console.log('onNextClick')
    if (isSubProposal && _.isNil(tradeId)) {
      setHighlightTradeId(true)
    } else {
      if (canSendBid()) {
        const prevBidState = _.get(bids, bidId)
        const declinedRemovalRequestItems = []
        const acceptedRemovalRequestItems = []
        _.forOwn(_.get(bid, 'removalRequests', {}), (value, key) => {
          const prevRemovalRequest = _.get(prevBidState, ['removalRequests', key])
          if (!_.has(prevRemovalRequest, 'declined') && _.has(value, 'declined')) {
            const item = _.get(bid, ['items', key])
            declinedRemovalRequestItems.push(item)
          }
        })
        _.forOwn(_.get(prevBidState, 'removalRequests', {}), (value, key) => {
          const currentRemovalRequest = _.get(bid, ['removalRequests', key])
          if (_.isNil(currentRemovalRequest)) {
            const item = _.get(prevBidState, ['items', key])
            acceptedRemovalRequestItems.push(item)
          }
        })

        console.log('send bid', bid)
        const removalStatus =
          _.size(acceptedRemovalRequestItems) > 0 && _.size(declinedRemovalRequestItems) > 0
            ? 'updated'
            : _.size(acceptedRemovalRequestItems) > 0
              ? 'approved'
              : _.size(declinedRemovalRequestItems) > 0
                ? 'declined'
                : null
        if (!_.isNil(removalStatus)) {
          const multiple = _.size(_.concat(declinedRemovalRequestItems, acceptedRemovalRequestItems)) > 1
          const subAccountId = _.get(prevBidState, 'accountId')
          dispatch(
            sendResolveRemovalMessage(
              subAccountId,
              workOrderId,
              {
                accepted: {
                  list: acceptedRemovalRequestItems,
                  multiple: _.size(acceptedRemovalRequestItems) > 1
                },
                declined: {
                  list: declinedRemovalRequestItems,
                  multiple: _.size(declinedRemovalRequestItems) > 1
                }
              },
              removalStatus,
              multiple
            )
          )
        }
        dispatch(sendBid(bid))
        dispatch(navigateToHomePage())
      }
    }
  }

  const isSendToSetup = () => {
    const sendTo = getField('sendTo')
    return !_.isEmpty(_.filter(sendTo, info => info.checked))
  }

  // console.log('scopeForTheSub', scopeForTheSub)
  const renderBidPreview = () => {
    return (
      <Box flex={false} background={colors.WHITE} height='100%'>
        <CreateBidNavBar
          onNextClick={onNextClick}
          nextDisabled={!canSendBid() || !isSendToSetup()}
          workOrder={workOrder}
          previewMode
        />
        <Box direction='row' flex>
          <Text>Test</Text>
          <ProposalPreview bid={bid} />
          <ProposalPreviewPanel />
        </Box>
      </Box>
    )
  }

  if (_.isNil(workOrders) || _.isEmpty(account)) {
    return <Loading />
  } else {
    if (size === 'small') {
      return <MobilePlaceholder />
    } else {
      return renderBidPreview()
    }
  }
}

SubProposalPreview.propTypes = {
  projectId: PropTypes.string,
  workOrderId: PropTypes.string,
  workOrder: PropTypes.object
}

const mapStateToProps = (state, props) => ({
  workOrder: getWorkOrder(state, props),
  workOrders: state.workOrders,
  account: state.account,
  bids: state.bids
})

export default connect(mapStateToProps)(SubProposalPreview)
