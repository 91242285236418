import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TextInput, Keyboard } from 'grommet'
import numeral from 'numeral'

const CurrencyInput = ({ value, onFocus, onBlur, onChange, placeholder, formatString, inputRef, ...rest }) => {
  const [v, setV] = useState(null)
  const _inputRef = useRef()

  const ref = _.isNil(inputRef) ? _inputRef : inputRef

  const handleOnChange = event => {
    const valueRaw = event.target.value.replace(/[^0-9.,$]/g, '')
    const newValue = valueRaw.replace(/[^0-9.,]/g, '')
    // console.log('valueRaw', valueRaw, 'newValue', newValue)
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(v).value()
      // console.log('newV', newV, 'curV', curV)
      if (newV === curV || _.isNil(v)) {
        setV(valueRaw)
      } else {
        setV(numeral(newValue).format(formatString))
      }
    } else {
      setV('')
    }
  }

  let curValue = value
  // console.log('li cost', curValue)
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format(formatString)
  }
  // console.log('curValue after formatting', curValue)
  if (!_.isNil(v)) curValue = v
  if (_.isNil(curValue)) curValue = ''
  // console.log('final cur value', curValue)

  const handleOnBlur = () => {
    onBlur()
    if (_.isNil(v)) {
    } else {
      const cost = _.size(v) > 0 ? numeral(v).value() : null
      const finalValue = onChange(cost)
      if (!_.isNil(finalValue)) {
        setV(numeral(finalValue).format(formatString))
      } else {
        setV(null)
      }
    }
  }

  const onEnter = () => {
    ref.current.blur()
  }

  return (
    <Keyboard onEnter={onEnter}>
      <TextInput
        value={curValue}
        alignSelf='center'
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    </Keyboard>
  )
}

CurrencyInput.defaultProps = {
  placeholder: '',
  onFocus: () => null,
  onBlur: () => null,
  onChange: () => null,
  formatString: '$0,0.[00]'
}

CurrencyInput.propTypes = {
  value: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default CurrencyInput
