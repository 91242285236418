import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Box, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import DropDownMenu from 'components/DropDownMenu'
import colors from 'shared/constants/colors'
import { getWorkOrdersByProjectWithTitles } from 'model/selectors/projectsInfoSelector'
import { getBidsByWorkOrders } from 'model/selectors/bids'
import { getName } from 'shared/utils/stringUtils'
import { updateReportSettingBidInclude, excludeAllBids, showAllBidsInReport } from 'controllers/reports'
import reportWorkOrderOption from 'shared/constants/reportWorkOrderOption'
import reportSetting from 'shared/constants/reportSetting'
import { getSelectedBidOption } from 'shared/utils/reports'
import rt from 'shared/constants/reportType'

const BidsToInclude = ({ projectId, reportType, workOrders, reportSettings, updateReportSetting, bidsByWorkOrders, accountsProfiles }) => {
  // console.log('reportSettings', reportSettings)

  const showTradesWithNoBids = reportSetting.getValue(reportSettings, reportSetting.SHOW_TRADES_WITH_NO_BIDS)

  const workOrdersWithBids = useMemo(() => {
    if (showTradesWithNoBids) {
      return _.values(workOrders)
    } else {
      return _.filter(workOrders, wo => !_.isEmpty(_.get(bidsByWorkOrders, wo.id)))
    }
  }, [workOrders, bidsByWorkOrders, showTradesWithNoBids])

  const hideTradeOption = { id: reportWorkOrderOption.HIDE, label: 'Hide trade', color: colors.BROWN_GREY_TWO }
  const includeTradeOption = { id: reportWorkOrderOption.INCLUDED, label: 'Include', color: colors.AQUA_MARINE }
  const excludeTradeOption = { id: reportWorkOrderOption.EXCLUDED, label: 'Exclude', color: colors.CORAL_TWO }
  const pendingTradeOption = { id: reportWorkOrderOption.PENDING, label: 'Pending', color: colors.BROWN_GREY_TWO }

  const renderWorkOrderRow = wo => {
    const workOrderId = _.get(wo, 'id')
    const bids = _.get(bidsByWorkOrders, workOrderId)
    const options = _.map(bids, bid => {
      const subProfile = _.get(accountsProfiles, bid.accountId, {
        name: _.get(wo, ['invitations', bid.accountId, 'companyName'])
      })
      const companyName = getName(subProfile)
      return {
        id: bid.id,
        label: companyName
      }
    })

    const selectedIds = getSelectedBidOption(reportSettings, workOrderId, bids)
    const statuses = [reportWorkOrderOption.HIDE, reportWorkOrderOption.PENDING, reportWorkOrderOption.EXCLUDED, reportWorkOrderOption.INCLUDED]

    const onOptionClick = id => {
      let bidIds = null
      if (_.includes(statuses, id)) {
        bidIds = id
      } else if (selectedIds !== id) {
        if (_.includes(selectedIds, id)) {
          bidIds = _.filter(selectedIds, i => i !== id)
          if (_.size(bidIds) === 0) {
            bidIds = reportWorkOrderOption.EXCLUDED
          }
        } else {
          if (_.includes(statuses, selectedIds)) {
            bidIds = [id]
          } else if (!_.isArray(selectedIds)) {
            bidIds = [selectedIds, id]
          } else {
            bidIds = [...selectedIds, id]
          }
        }
      }
      updateReportSetting(`includedBids.${workOrderId}`, bidIds)
    }

    const currentBid = _.get(bids, selectedIds)
    const subAccountId = _.get(currentBid, 'accountId')
    const subProfile = _.get(accountsProfiles, subAccountId, {
      name: _.get(wo, ['invitations', subAccountId, 'companyName'])
    })
    const curCompanyName = getName(subProfile)
    let label
    if (selectedIds === reportWorkOrderOption.HIDE) {
      label = (
        <Text color={colors.TEXT_PRIMARY} size='small'>
          Hide trade
        </Text>
      )
    } else if (selectedIds === reportWorkOrderOption.PENDING) {
      label = (
        <Text color={colors.TEXT_PRIMARY} size='small'>
          Pending
        </Text>
      )
    } else if (selectedIds === reportWorkOrderOption.EXCLUDED) {
      label = (
        <Text color={colors.CORAL_TWO} size='small'>
          Excluded
        </Text>
      )
    } else if (selectedIds === reportWorkOrderOption.INCLUDED) {
      label = (
        <Text color={colors.AQUA_MARINE} size='small'>
          Included
        </Text>
      )
    } else if (_.isArray(selectedIds) && _.size(selectedIds) > 1) {
      label = (
        <Text color={colors.TEXT_PRIMARY} size='small'>
          Multiple bids
        </Text>
      )
    } else if (_.size(selectedIds) === 1 || !_.isArray(selectedIds)) {
      label = (
        <Text color={colors.TEXT_PRIMARY} size='small'>
          {curCompanyName}
        </Text>
      )
    } else {
      label = (
        <Text color={colors.VERY_LIGHT_PINK} size='small'>
          Select subcontractor bid
        </Text>
      )
    }

    const menuOptions = _.isEmpty(options)
      ? [hideTradeOption, includeTradeOption, excludeTradeOption, pendingTradeOption]
      : [hideTradeOption, excludeTradeOption, ...options]

    return (
      <Box key={wo.id} direction='row' align='center' justify='between'>
        <Text>{wo.title}</Text>
        <DropDownMenu multiple options={menuOptions} selectedId={selectedIds} onOptionClick={onOptionClick}>
          <Box
            width='175px'
            height='36px'
            align='center'
            justify='between'
            direction='row'
            border='all'
            round='xsmall'
            pad={{ horizontal: 'small' }}
            background={_.isNull(selectedIds) ? colors.VERY_LIGHT_GREY_TWO : undefined}
          >
            {label}
            <FontAwesomeIcon
              icon={faAngleDown}
              color={_.isUndefined(selectedIds) ? colors.VERY_LIGHT_PINK : colors.TEXT_PRIMARY}
              size={16}
            />
          </Box>
        </DropDownMenu>
      </Box>
    )
  }

  const handleSelectAll = canClearSelectedBids => {
    if (canClearSelectedBids) {
      const excludedBids = {}
      _.forEach(workOrders, ({ id }) => {
        _.set(excludedBids, id, reportWorkOrderOption.EXCLUDED)
      })
      updateReportSetting('includedBids', excludedBids)
    } else {
      const includedBids = {}
      _.forEach(workOrdersWithBids, ({ id }) => {
        let bids = _.keys(_.get(bidsByWorkOrders, id))
        if (_.size(bids) === 0) bids = reportWorkOrderOption.INCLUDED
        _.set(includedBids, id, bids)
      })
      updateReportSetting('includedBids', includedBids)
    }
  }

  if (reportType === rt.SCOPES_ONLY) {
    return null
  } else {
    const canClearSelectedBids = _.size(_.filter(workOrdersWithBids, ({ id }) => {
      const bids = _.get(bidsByWorkOrders, id)
      const selectedIds = getSelectedBidOption(reportSettings, id, bids)
      return selectedIds !== reportWorkOrderOption.EXCLUDED
    }))
    return (
      <Box pad={{ horizontal: 'medium', vertical: 'medium' }} flex={{ shrink: 0 }} gap='xsmall'>
        <Box direction='row' justify='between'>
          <Text color={colors.ASANA_GRAY_TEXT_HOVERED} size='small'>Bids to include</Text>
          <Button
            plain
            onClick={() => handleSelectAll(canClearSelectedBids)}
            label={canClearSelectedBids
              ? <Text color={colors.CORAL_TWO} size='small'>Clear selected bids</Text>
              : <Text color={colors.LINK} size='small'>Show all bids</Text>}
          />
        </Box>
        {_.map(workOrdersWithBids, renderWorkOrderRow)}
      </Box>
    )
  }
}

BidsToInclude.propTypes = {
  projectId: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => ({
  workOrders: _.get(getWorkOrdersByProjectWithTitles(state), props.projectId),
  bidsByWorkOrders: getBidsByWorkOrders(state),
  accountsProfiles: state.accountsProfiles
})

export default connect(mapStateToProps)(BidsToInclude)
