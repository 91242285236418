import moment from 'moment-timezone'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import { toTimestamp } from 'shared/utils/date'

export const NEW = 'new'
export const PENDING = 'pending'
export const ACCEPTED = 'accepted'
export const DECLINED = 'declined'
export const LOST = 'lost'
export const DECLINED_BY_SUB = 'declined_by_sub'
export const ACCEPTED_BY_SUB = 'accepted_by_sub'
export const ARCHIVED = 'archived'

export const stringOfJobOfferStatus = (status, companyName) => {
  switch (status) {
    case NEW:
      return 'Undecided'
    case PENDING:
      return 'Bid submitted'
    case ACCEPTED:
      return 'Won'
    case DECLINED:
      return 'Bid declined'
    case LOST:
      return 'Lost'
    case DECLINED_BY_SUB:
      return 'Declined'
    case ACCEPTED_BY_SUB:
      return 'Accepted'
    case ARCHIVED: {
      if (!_.isNil(companyName)) return `Archived by ${companyName}`
      return 'Archived'
    }
  }
}

export const stringOfBidInviteStatus = (status, workOrder, bid, accountId) => {
  const timeZone = _.get(workOrder, 'projectAddress.timeZone')
  switch (status) {
    case NEW: {
      const invitedAt = toTimestamp(_.get(workOrder, ['invitations', accountId, 'timestamp']))
      if (invitedAt > 0) {
        const invitationDate = moment.tz(invitedAt, timeZone).format('M/D')
        return `Invited ${invitationDate}`
      } else {
        return 'Waiting for bid'
      }
    }
    case PENDING:
      const bidCreatedAt = toTimestamp(_.get(bid, 'timestamp', 0))
      const submittedDate = moment.tz(bidCreatedAt, timeZone).format('M/D')
      return `Submitted ${submittedDate}`
    case ACCEPTED:
      return 'Won'
    case DECLINED:
      return 'Declined'
    case LOST:
      return 'Declined'
    case DECLINED_BY_SUB:
      const declinedAt = toTimestamp(_.get(workOrder, ['declinedBy', accountId, 'timestamp']))
      const declinedDate = moment.tz(declinedAt, timeZone).format('M/D')
      return `Declined ${declinedDate}`
    case ACCEPTED_BY_SUB:
      const acceptedAt = toTimestamp(_.get(workOrder, ['acceptedBy', accountId, 'timestamp']))
      const acceptedDate = moment.tz(acceptedAt, timeZone).format('M/D')
      return `Accepted ${acceptedDate}`
  }
}

export const jobOfferStatusColor = status => {
  switch (status) {
    case NEW:
    case ACCEPTED_BY_SUB:
      return colors.AQUA_MARINE
    case ACCEPTED:
      return colors.LIGHT_NAVY_BRIGHT
    case PENDING:
      return colors.LIGHT_NAVY
    case DECLINED:
    case LOST:
    case DECLINED_BY_SUB:
      return colors.CORAL
    case ARCHIVED:
      return colors.MEDIUM_GREY
  }
}

export default {
  NEW,
  PENDING,
  ACCEPTED,
  DECLINED,
  LOST,
  DECLINED_BY_SUB,
  ACCEPTED_BY_SUB,
  ARCHIVED,
  stringOfJobOfferStatus,
  jobOfferStatusColor,
  stringOfBidInviteStatus
}
