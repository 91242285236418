import React, { useRef } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { faCalendar as faCalendarRegular } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import DatePicker from 'components/DatePicker'

const InputPhaseDate = ({ phaseId, date, timeZone, onChangePhase, onFocus, onBlur, highlightIncomlete }) => {
  const pickerRef = useRef()

  const onChange = v => {
    onChangePhase(phaseId, 'date', v)
  }

  const openPicker = () => pickerRef.current.open()

  const renderLabel = () => {
    if (_.isNil(date)) {
      const color = highlightIncomlete ? colors.CORAL_TWO : colors.VERY_LIGHT_PINK
      const hoverColor = highlightIncomlete ? colors.CORAL_TWO : colors.BLACK80
      const icon = (
        <Box
          border={{ style: 'dashed', color }}
          round='full'
          width='29px'
          height='29px'
          align='center'
          justify='center'
        >
          <FontAwesomeIcon icon={highlightIncomlete ? faCalendarRegular : faCalendar} size={12} />
        </Box>
      )
      return (
        <Box
          direction='row'
          align='center'
          gap='xsmall'
          onClick={openPicker}
          customStyle={`
            color: ${color};
            svg path {
              color: ${color};
              fill: ${color};
            }
            : hover {
              color: ${hoverColor};
              div {
                border-color: ${hoverColor};
              }
              svg path {
                fill: ${hoverColor};
              }
            }
          `}
        >
          {icon}
          <Text>Date</Text>
        </Box>
      )
    } else {
      return (
        <Box onClick={openPicker}>
          <Text>{moment.tz(date, timeZone).format('ll')}</Text>
        </Box>
      )
    }
  }

  return (
    <DatePicker
      title='Expected Start Date'
      value={date}
      timeZone={timeZone}
      onChange={onChange}
      label={renderLabel()}
      pickerRef={pickerRef}
      alignSelf='center'
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default InputPhaseDate
