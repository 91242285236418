import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Box } from 'grommet'
import { faGripVertical } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import lineItemType from 'shared/constants/lineItemType'
import LineItemTitleCell from 'components/createBid/LineItemTitleCell.web'
import LineItemAlternateCell from 'components/createBid/LineItemAlternateCell.web'
import LineItemExcludedCell from 'components/createBid/LineItemExcludedCell'
import LineItemOptionalCell from 'components/createBid/LineItemOptionalCell'
import LineItemCommentsCell from 'components/createBid/LineItemCommentsCell'
import LineItemQuantityField from 'components/createBid//LineItemQuantityField'
import CurrencyInputCell from 'components/createBid/CurrencyInputCell'
import LineItemDescriptionCell from 'components/createBid/LineItemDescriptionCell.web'
import LineItemDelete from 'components/workOrder/LineItemDelete'

const draggingStyle = css`
  border-top: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`

const GridRow = styled.div`
  display: grid;
  background-color: white;
  flex-shrink: 0;
  grid-template-columns: 26px 3.2fr 60px 60px 60px 60px 0.8fr 0.8fr 0.8fr 2fr 60px;
  ${props => (props.isDragging ? draggingStyle : '')};
  ${props => (props.dropRejected ? `background-color: ${colors.CORAL_TWO10};` : '')}
  ${props =>
    !props.isDraggingOver &&
    `:hover:not(.alt-row) {
      background-color: ${colors.TABLE_HOVER};
      #delete_line_item_icon svg path {
        fill: ${colors.CORAL_TWO};
      }
      #drag_icon path {
        fill: ${colors.BROWN_GREY_TWO};
      }
    }
  `}
`

const LineItemTableRow = ({
  scope,
  li,
  parentLi,
  rowId,
  addAlternate,
  toggleAlternates,
  updateItem,
  selectRow,
  unselectRow,
  expanded,
  removeLineItem,
  removalRequest,
  gcProfile,
  onRemoveAction,
  setCommentId,
  messagesAmount,
  nextLiId,
  hasNewMessage,
  tableRowProps,
  dragHandleProps,
  isDraggingOver,
  items,
  cannotAddToTemplate,
  disabled,
  dropRejected
}) => {
  const getIdsToFocus = rowId => [
    `${rowId}_title`,
    `${rowId}_excluded`,
    `${rowId}_optional`,
    `${rowId}_quantity`,
    `${rowId}_price`,
    `${rowId}_total`,
    `${rowId}_description`
  ]
  const excluded = _.get(li, 'excluded')
  const focusNextElement = (current, e) => {
    e.preventDefault()
    const currentId = `${li.id}_${current}`
    const idsToFocus = getIdsToFocus(li.id)
    const currentIndex = _.indexOf(idsToFocus, currentId)
    let focused = false
    if (currentIndex < _.size(idsToFocus) - 1) {
      for (let i = currentIndex; !focused && i <= idsToFocus.length - 1; i++) {
        const element = document.getElementById(idsToFocus[i + 1])
        if (element && !element.disabled) {
          element.focus()
          focused = true
          break
        }
      }
    }
    if (!focused) {
      focusNextLine()
    }
  }

  const focusNextLine = () => {
    if (nextLiId) {
      const idsToFocusForNextRow = getIdsToFocus(nextLiId)
      let focused = false
      for (let i = 0; !focused && i <= idsToFocusForNextRow.length - 1; i++) {
        const element = document.getElementById(idsToFocusForNextRow[i])
        if (element && !element.disabled) {
          element.focus()
          focused = true
          break
        }
      }
    }
  }

  const _selectRow = useCallback(() => {
    selectRow(li.id)
  }, [selectRow, li.id])

  const _unselectRow = useCallback(() => {
    unselectRow(li.id)
  }, [unselectRow, li.id])

  const renderCommentsField = (li, parentLi) => (
    <LineItemCommentsCell
      li={li}
      parentLi={parentLi}
      messagesAmount={messagesAmount}
      hasNewMessage={hasNewMessage}
      setCommentId={setCommentId}
      disabled={disabled}
    />
  )

  const renderAlternatesField = (li, parentLi) => {
    return (
      <LineItemAlternateCell
        li={li}
        parentLi={parentLi}
        setShow={() => toggleAlternates(li.id, false)}
        addAlternate={addAlternate}
        disabled={disabled}
      />
    )
  }

  const existsInScope = _.isEmpty(parentLi) ? _.has(scope, li.id) : _.has(scope, [parentLi.id, 'alternates', li.id])

  const renderTitleField = (li, parentLi) => {
    return (
      <LineItemTitleCell
        li={li}
        scope={scope}
        show={expanded}
        handleShowChange={() => toggleAlternates(li.id)}
        updateItem={updateItem}
        removeLineItem={removeLineItem}
        editable={!existsInScope}
        parentLi={parentLi}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        removalRequest={removalRequest}
        gcProfile={gcProfile}
        onRemoveAction={onRemoveAction}
        focusNextElement={focusNextElement}
        items={items}
        cannotAddToTemplate={cannotAddToTemplate}
        disabled={disabled}
      />
    )
  }

  const renderDescriptionField = (li, parentLi) => {
    return (
      <LineItemDescriptionCell
        li={li}
        parentLi={parentLi}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        updateItem={updateItem}
        scope={scope}
        focusNextElement={focusNextElement}
        disabled={disabled}
        highlighted
      />
    )
  }

  const onQuantityCostChange = (quantity, cost) => {
    if (quantity > 0 && cost > 0) {
      const total = _.floor(quantity * cost, 2)
      updateItem(li.id, { quantity, cost, total }, parentLi)
    } else {
      updateItem(
        li.id,
        {
          quantity: quantity > 0 ? quantity : null,
          cost: cost > 0 ? cost : null
        },
        parentLi
      )
    }
  }

  const setQuantity = v => {
    console.log('setQuantity', v)
    onQuantityCostChange(v, li.cost)
  }

  const setCost = v => {
    console.log('setCost', v)
    onQuantityCostChange(li.quantity, v)
  }

  const setTotal = v => {
    console.log('set total', v)
    const quantity = _.get(li, 'quantity')
    if (v > 0) {
      if (quantity > 0) {
        const newCost = v / quantity
        updateItem(li.id, { total: v, cost: newCost }, parentLi)
      } else {
        updateItem(li.id, { total: v, quantity: null, cost: null }, parentLi)
      }
    } else {
      updateItem(li.id, { total: null, cost: null, quantity: null }, parentLi)
    }
  }

  const renderQuantityField = (li, parentLi) => {
    return (
      <LineItemQuantityField
        li={li}
        parentLi={parentLi}
        updateItem={updateItem}
        setQuantity={setQuantity}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        placeholder=''
        focusNextElement={focusNextElement}
        disabled={disabled || excluded}
        highlighted
      />
    )
  }

  const renderPriceField = (li, parentLi) => {
    const liType = _.get(li, 'type', lineItemType.DEFAULT)
    const enabled = liType === lineItemType.DEFAULT
    return (
      <CurrencyInputCell
        initialValue={_.get(li, 'cost')}
        enabled={enabled}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        save={setCost}
        placeholder=''
        focusNextElement={focusNextElement}
        name='price'
        id={li.id}
        disabled={disabled || excluded}
        highlighted
      />
    )
  }

  const renderExcludedField = (li, parentLi) => {
    return (
      <LineItemExcludedCell
        li={li}
        parentLi={parentLi}
        updateItem={updateItem}
        scope={scope}
        focusNextElement={focusNextElement}
        disabled={disabled}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
      />
    )
  }

  const renderOptionalField = (li, parentLi) => {
    return (
      <LineItemOptionalCell
        li={li}
        parentLi={parentLi}
        updateItem={updateItem}
        scope={scope}
        focusNextElement={focusNextElement}
        disabled={disabled}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
      />
    )
  }

  const renderTotalField = li => {
    const liType = _.get(li, 'type', lineItemType.DEFAULT)
    const enabled = liType === lineItemType.DEFAULT
    return (
      <CurrencyInputCell
        initialValue={_.get(li, 'total')}
        enabled={enabled}
        selectRow={_selectRow}
        unselectRow={_unselectRow}
        save={setTotal}
        placeholder=''
        focusNextElement={focusNextElement}
        name='total'
        id={li.id}
        disabled={disabled || excluded}
        highlighted
      />
    )
  }

  const renderDeleteItemIcon = (li, parentLi) => {
    return <LineItemDelete onClick={() => removeLineItem(li.id, parentLi)} disabled={existsInScope || disabled} />
  }

  const renderDragIcon = (li, parentLi) => {
    if (!_.has(parentLi) && !_.isEmpty(dragHandleProps) && !disabled) {
      return (
        <Box align='end' justify='center' {...dragHandleProps}>
          <FontAwesomeIcon
            icon={faGripVertical}
            color={tableRowProps.isDragging ? colors.BROWN_GREY_TWO : 'transparent'}
            size={18}
            id='drag_icon'
          />
        </Box>
      )
    } else {
      return <Box />
    }
  }

  return (
    <GridRow
      key={rowId}
      id={rowId}
      {...tableRowProps}
      isDraggingOver={isDraggingOver}
      className='table_row'
      dropRejected={dropRejected}
    >
      {renderDragIcon(li, parentLi)}
      {renderTitleField(li, parentLi)}
      {renderCommentsField(li, parentLi)}
      {renderAlternatesField(li, parentLi)}
      {renderExcludedField(li, parentLi)}
      {renderOptionalField(li, parentLi)}
      {renderQuantityField(li, parentLi)}
      {renderPriceField(li)}
      {renderTotalField(li)}
      {renderDescriptionField(li, parentLi)}
      {renderDeleteItemIcon(li, parentLi)}
    </GridRow>
  )
}

LineItemTableRow.defaultProps = {
  canDnd: true
}

LineItemTableRow.propTypes = {
  scope: PropTypes.object,
  li: PropTypes.object,
  parentLi: PropTypes.object,
  rowId: PropTypes.string,
  addAlternate: PropTypes.func,
  toggleAlternates: PropTypes.func,
  updateItem: PropTypes.func,
  selectRow: PropTypes.func,
  unselectRow: PropTypes.func,
  expanded: PropTypes.bool,
  removeLineItem: PropTypes.func,
  removalRequest: PropTypes.object,
  gcProfile: PropTypes.object,
  onRemoveAction: PropTypes.func
}

const areEqual = (prevProps, nextProps) => {
  const checkEqual = k => {
    const v1 = _.get(prevProps, k)
    const v2 = _.get(nextProps, k)
    return _.isEqual(v1, v2)
  }
  const res = []
  _.forEach(prevProps, (v, k) => {
    if (!checkEqual(k)) res.push(k)
  })
  // console.log('fields are not equal', res)

  return _.isEmpty(res)
}

export default React.memo(LineItemTableRow, areEqual)
