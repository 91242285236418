import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, Keyboard, Animated, Easing } from 'react-native'
import styled from 'styled-components/native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'

import { getWidth, getHeight, fontSize } from 'shared/utils/dynamicSize'
import colors from 'shared/constants/colors'

const Row = styled(Animated.View)`
  width: 100%;
  border-top-width: 1px;
  border-color: ${colors.PALE_BLUE};
  align-items: flex-end;
  background-color: ${props => props.backgroundColor};
  ${props => props.customStyle};
`
const ButtonContainer = styled(TouchableOpacity)`
  padding-horizontal: ${props => getWidth(16, props.viewport)}px;
  padding-vertical: ${props => getHeight(10, props.viewport)}px;
  z-index: 2;
  background-color: transparent;
`

class CloseKeyboardButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
    this._containerOpacity = new Animated.Value(0)
    this._containerRef = React.createRef()
  }

  componentDidMount () {
    this.keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', this._keyboardWillShow)
    this.keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', this._keyboardWillHide)
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow)
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide)
  }

  componentWillUnmount () {
    this.keyboardWillShowListener.remove()
    this.keyboardDidShowListener.remove()
    this.keyboardDidHideListener.remove()
    this.keyboardWillHideListener.remove()
  }

  show = () => {
    this.setState({ visible: true })
    if (this._containerRef) {
      Animated.timing(this._containerOpacity, { toValue: 1, duration: 400, useNativeDriver: true }).start()
    }
  }

  hide = () => {
    this.setState({ visible: false })
    if (this._containerRef) {
      Animated.timing(this._containerOpacity, { toValue: 0, duration: 100, useNativeDriver: true }).start()
    }
  }

  _keyboardDidShow = () => {
    // console.log('_keyboardDidShow')
    this.props.keyboardDidShow()
    const { visible } = this.state
    if (!visible) this.show()
  }

  _keyboardWillShow = () => {
    // console.log('_keyboardWillShow')
    this.show()
  }

  _keyboardDidHide = () => {
    // console.log('_keyboardDidHide')
    this.setState({ visible: false })
    const { visible } = this.state
    if (visible) this.hide()
  }

  _keyboardWillHide = () => {
    // console.log('_keyboardWillHide')
    this.hide()
  }

  render () {
    const { visible } = this.state
    const { viewport, backgroundColor, hideCloseKeyboardButton, customStyle } = this.props
    if (visible) {
      return (
        <Row viewport={viewport} backgroundColor={backgroundColor} customStyle={customStyle}>
          <ButtonContainer
            ref={this._containerRef}
            useNativeDriver
            viewport={viewport}
            onPress={Keyboard.dismiss}
            style={{
              // opacity: this._containerOpacity,
              transform: [{ scale: this._containerOpacity }, { perspective: 1000 }]
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              size={getHeight(20, viewport)}
              color={hideCloseKeyboardButton ? 'transparent' : colors.DARK}
            />
          </ButtonContainer>
        </Row>
      )
    } else {
      return null
    }
  }
}

CloseKeyboardButton.defaultProps = {
  backgroundColor: 'transparent',
  keyboardDidShow: () => null
}

CloseKeyboardButton.propTypes = {
  keyboardDidShow: PropTypes.func,
  backgroundColor: PropTypes.string,
  viewport: PropTypes.object.isRequired,
  hideCloseKeyboardButton: PropTypes.bool,
  customStyle: PropTypes.string
}

export default CloseKeyboardButton
