import React, { useState, useImperativeHandle } from 'react'
import { connect } from 'react-redux'
import { Box, Text, Button, Layer } from 'grommet'

import colors from 'shared/constants/colors'
import { removeUserFromAccount } from 'controllers/auth'
import { getName } from 'shared/utils/stringUtils'

const RemoveTeammateModal = ({ dispatch, modalRef }) => {
  const [opened, setOpened] = useState(false)
  const [user, setUser] = useState()

  const handleRemove = () => {
    dispatch(removeUserFromAccount(user.id))
    setOpened(false)
  }

  useImperativeHandle(modalRef, () => ({
    open: userToRemove => {
      setUser(userToRemove)
      setOpened(true)
    }
  }))

  const close = () => {
    setOpened(false)
  }

  if (!opened) return null
  return (
    <Layer margin='large' onEsc={close} onClickOutside={close} animate={false}>
      <Box width='medium' height='small'>
        <Box pad='small' fill='horizontal' align='center' direction='row' border='bottom' flex={false}>
          <Text color={colors.BLACK} size='medium'>
            Removing{' '}
            <Text size='medium' color={colors.BLACK} weight='bold'>
              {getName(user)}
            </Text>{' '}
            from company
          </Text>
        </Box>
        <Box pad='small' fill='horizontal' align='center' direction='row' gap='small'>
          <Text size='medium' color={colors.BLACK}>
            Are you sure?
          </Text>
        </Box>
        <Box direction='row' justify='end' gap='small' margin={{ top: 'auto' }} pad='small'>
          <Box>
            <Button primary color={colors.AQUA_MARINE} onClick={handleRemove} label='Confirm' />
          </Box>
          <Box>
            <Button primary color={colors.CORAL_TWO} onClick={close} label='Cancel' />
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}

export default connect()(RemoveTeammateModal)
