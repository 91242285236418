import React, { Component } from 'react'
import { Layer, Button, Box, Text, TextArea, Card, CardHeader, CardBody, CardFooter, ThemeContext } from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'
import CurrencyInput from 'components/CurrencyInput'

class RemoveLineItemModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false,
      invId: undefined,
      item: undefined,
      companyName: undefined,
      comment: '',
      bidTotal: 0
    }
  }

  onCommentChange = event => this.setState({ comment: event.target.value })

  open = (item, isRemoving, companyName, bidTotal, unpricedAmount) => {
    this.setState({
      item,
      companyName,
      visible: true,
      isRemoving,
      comment: '',
      bidTotal,
      unpricedAmount,
      value: _.get(item, 'total', null)
    })
  }

  close = () => {
    this.setState({ visible: false })
  }

  removeButtonClick = () => {
    const { comment, item, isRemoving, value } = this.state
    const { onRemovingDeclined, onRemovingApproved } = this.props
    if (isRemoving) {
      onRemovingApproved(item, comment, value)
    } else {
      onRemovingDeclined(item, comment)
    }
    this.close()
  }

  onValueChange = v => {
    const { unpricedAmount } = this.state
    const newValue = _.min([unpricedAmount, v])
    this.setState({ value: newValue })
  }

  renderLineItemValue = () => {
    const { item, value, unpricedAmount } = this.state
    const itemTotalRaw = _.get(item, 'total')
    const itemTotal = _.get(item, 'total', 0)
    console.log('renderLineItemValue value', value)
    if (_.size(_.toString(itemTotalRaw)) > 0 || unpricedAmount === 0) {
      return <Text color={colors.TEXT_PRIMARY}>{numeral(itemTotal).format('$0,0.[00]')}</Text>
    } else {
      return (
        <ThemeContext.Extend value={{ global: { input: { padding: { horizontal: 'small', vertical: 'xsmall' } } } }}>
          <Box pad={{ top: 'small' }} width={{ max: '200px' }}>
            <CurrencyInput value={value} onChange={this.onValueChange} />
          </Box>
        </ThemeContext.Extend>
      )
    }
  }

  renderTotals = () => {
    const { isRemoving, bidTotal, value } = this.state
    if (isRemoving) {
      const newBidTotal = bidTotal - _.toNumber(value)
      return (
        <Box
          direction='row'
          height='120px'
          width='100%'
          margin={{ vertical: 'medium' }}
          // pad={{ bottom: 'small' }}
          justify='between'
          align='start'
        >
          <Box flex height='100%' basis='1/3' justify='between' pad={{ bottom: 'medium' }}>
            <Text color={colors.DARK_GRAY_TWO}>Line item value</Text>
            {this.renderLineItemValue()}
          </Box>
          <Box flex height='100%' justify='between'>
            <Text color={colors.DARK_GRAY_TWO}>Previous bid total</Text>
            <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
              {numeral(bidTotal).format('$0,0.[00]')}
            </Text>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>
              excluding discounts
            </Text>
          </Box>
          <Box flex height='100%' justify='center' align='center'>
            <FontAwesomeIcon icon={faArrowRight} size={24} color={colors.VERY_LIGHT_PINK} />
          </Box>
          <Box flex height='100%' justify='between'>
            <Text color={colors.DARK_GRAY_TWO}>New bid total</Text>
            <Text size='large' color={colors.LIGHT_NAVY_BRIGHT}>
              {numeral(newBidTotal).format('$0,0.[00]')}
            </Text>
            <Text size='small' color={colors.VERY_LIGHT_PINK}>
              excluding discounts
            </Text>
          </Box>
        </Box>
      )
    }
  }

  renderPrimarybutton = () => {
    const { isRemoving, comment } = this.state
    if (isRemoving) {
      return <Button primary label='Remove' color={colors.CORAL_TWO} onClick={this.removeButtonClick} />
    } else {
      return (
        <Button
          primary
          label='Decline'
          color={colors.CORAL_TWO}
          onClick={this.removeButtonClick}
          disabled={_.isEmpty(comment)}
        />
      )
    }
  }

  render () {
    const { visible, companyName, item, isRemoving } = this.state

    let headerTitle = `You are declining ${companyName}’s request to remove this line item. Are you sure?`
    let headerSubtitle
    let textAreaPlaceholder = 'Add comment'

    if (isRemoving) {
      headerTitle = `You are approving ${companyName}’s request to remove this line item.`
      textAreaPlaceholder = 'Add comment (optional)'
    }

    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card height='medium' width='large'>
            <CardHeader pad='medium'>
              <Box gap='small'>
                <Text size='xlarge' color={colors.BLACK} weight={600}>
                  {headerTitle}
                </Text>
                {headerSubtitle && <Text color={colors.DARK_GRAY_TWO}>{headerSubtitle}</Text>}
              </Box>
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium' }} overflow='scroll' gap='small'>
              <Text margin={{ top: 'small' }} color={colors.LIGHT_NAVY_BRIGHT}>
                {_.get(item, 'name')}
              </Text>
              {this.renderTotals()}
              <TextArea
                placeholder={textAreaPlaceholder}
                resize='vertical'
                height={'80px'}
                onChange={this.onCommentChange}
              />
            </CardBody>
            <CardFooter align='center' justify='end' pad='medium'>
              <Button plain label={<Text color={colors.ANOTHER_GREY}>Cancel</Text>} onClick={this.close} />
              {this.renderPrimarybutton()}
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

RemoveLineItemModal.propTypes = {
  onRemovingDeclined: PropTypes.func,
  onRemovingApproved: PropTypes.func
}

export default RemoveLineItemModal
