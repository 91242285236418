import * as types from 'model/actionTypes'

export function receiveMessages (messages, channelKey) {
  return {
    type: types.RECEIVE_MESSAGES,
    messages,
    channelKey
  }
}
export function clearMessages (channelKey) {
  return {
    type: types.CLEAR_MESSAGES,
    channelKey
  }
}
export function receiveMessagesWeb (messages) {
  return {
    type: types.RECEIVE_MESSAGES_WEB,
    messages
  }
}

export function receiveAnnouncments (messages) {
  return {
    type: types.RECEIVE_ANNOUNCEMENTS,
    messages
  }
}

export function receiveMessagesInbox (messages) {
  return {
    type: types.RECEIVE_MESSAGES_INBOX,
    messages
  }
}

export function receiveInboxFirstMessage (firstMessage) {
  return {
    type: types.RECEIVE_INBOX_FIRST_MESSAGE,
    firstMessage
  }
}

export function receiveInboxFirstNote (firstNote) {
  return {
    type: types.RECEIVE_INBOX_FIRST_NOTE,
    firstNote
  }
}
