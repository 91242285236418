const NEW_CONSTRUCTION = 'newConstruction'
const REMODEL = 'remodel'
const ADDITION = 'addition'

const stringOfType = v => {
  switch (v) {
    case NEW_CONSTRUCTION:
      return 'New Construction'
    case REMODEL:
      return 'Remodel'
    case ADDITION:
      return 'Addition'
  }
}

const list = [NEW_CONSTRUCTION, REMODEL, ADDITION]

export default { NEW_CONSTRUCTION, REMODEL, ADDITION, stringOfType, list }
