import React from 'react'
import { Box, Button, Text, TextInput } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'

export const MobilePlaceholder = ({ url }) => (
  <Box pad='large'>
    <Box align='center' margin={{ top: 'xlarge', bottom: 'xlarge' }}>
      <Text size='xlarge' textAlign='center'>
        Open this page in a desktop browser to view
      </Text>
    </Box>
    <Box margin={{ top: 'xlarge' }} gap='medium'>
      <Box>
        <Text>URL</Text>
      </Box>
      <Box direction='row' gap='small'>
        <TextInput value={window.location.href} readOnly />
        <Button
          primary
          size='small'
          color={colors.PALE_GREY}
          onClick={() => {
            navigator.clipboard.writeText(window.location.href)
          }}
          label={
            <Box direction='row' justify='between' gap='medium' pad='none' align='center'>
              <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faCopy} size={16} />
              <Text size='medium' weight={700} color={colors.LIGHT_NAVY_BRIGHT}>
                Copy
              </Text>
            </Box>
          }
        />
      </Box>
    </Box>
  </Box>
)

export default MobilePlaceholder
