import React from 'react'
import _ from 'lodash'
import { Text, Box, Tip } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import lineItemType from 'shared/constants/lineItemType'

const LineItemAlternateCell = ({ li, addAlternate, parentLi, disabled }) => {
  const liType = _.get(li, 'type', lineItemType.DEFAULT)
  const enabled = liType === lineItemType.DEFAULT && _.isNil(parentLi)
  const isNamed = _.size(_.filter(li.alternates, alt => _.size(alt.name) === 0)) === 0
  const amount = _.size(_.get(li, 'alternates'))
  const canCreate = isNamed && enabled
  // console.log(
  //   'LineItemAlternateCell, li.id',
  //   li.id,
  //   'parentId',
  //   _.get(parentLi, 'id'),
  //   'liType',
  //   liType,
  //   'isNamed',
  //   isNamed,
  //   'canCreate',
  //   canCreate
  // )
  return (
    <Box scope='col' align='center' pad='xxsmall' size='45px' border={['bottom', 'right']}>
      <Tip content={'Add alternate option'}>
        <Box
          justify='end'
          fill
          width='45px'
          pad={{ right: '22px' }}
          customStyle={`
            cursor: ${canCreate && !disabled ? 'pointer' : 'not-allowed'};
            .alt_amount {
              display: ${amount > 0 ? 'flex' : 'none'};
              left: 10px;
            }
            :hover {
              * {
                color: ${!canCreate || disabled ? colors.ANOTHER_GREY : colors.AQUA_MARINE};
                fill: ${!canCreate || disabled ? colors.ANOTHER_GREY : colors.AQUA_MARINE};
              };
              .alt_amount {
                display: ${(amount >= 0 && enabled && !disabled) || (amount > 0 && disabled) ? 'flex' : 'none'}
              }
            }
          `}
          direction='row'
          align='center'
          gap='xxsmall'
          onClick={canCreate && !disabled ? () => addAlternate(li) : undefined}
        >
          <Text size='xsmall' className='alt_amount' color={colors.ANOTHER_GREY}>
            {amount > 0 ? amount : '+'}
          </Text>
          <FontAwesomeIcon icon={faProjectDiagram} color={colors.ANOTHER_GREY} />
        </Box>
      </Tip>
    </Box>
  )
}

export default LineItemAlternateCell
