import React from 'react'
import { Card, Box, Text, Button } from 'grommet'
import _ from 'lodash'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

const PlanCard = ({ plan, price, onPlanClick }) => {
  // console.log('plan card', plan)
  // console.log('plan price', price)
  const v = _.get(price, 'unit_amount')

  const renderPrice = () => {
    const interval = _.get(price, 'recurring.interval')
    if (v) {
      return (
        <Box direction='row' align='baseline' gap='xsmall'>
          <Text size='xxlarge' weight={600}>
            {numeral(v / 100).format('$0,0.00')}
          </Text>
          <Text color={colors.ANOTHER_GREY}>/ {interval}</Text>
        </Box>
      )
    } else {
      return (
        <Text size='xlarge' color={colors.MASON_NAVY_900} weight={600}>
          Custom pricing
        </Text>
      )
    }
  }

  const onButtonClick = () => {
    onPlanClick(plan, price)
  }

  const checkmark = <FontAwesomeIcon icon={faCheck} size={18} color={colors.AQUA_MARINE} />

  const renderBullets = () => {
    return (
      <Box gap='medium' pad={{ top: 'medium' }} height='330px'>
        {_.map(plan.features, (b, i) => {
          return (
            <Box key={i} direction='row' gap='xsmall'>
              {checkmark}
              <Text color={colors.MASON_NAVY_500}>
                <span dangerouslySetInnerHTML={{ __html: b }} />
              </Text>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Card
      margin={{ top: 'medium' }}
      pad='large'
      width='340px'
      flex={{ shrink: 0 }}
      customStyle={`opacity: ${plan.disabled ? 0.5 : 1}`}
      border={plan.highlighted ? { color: colors.AQUA_MARINE, size: 'small' } : undefined}
    >
      <Box border='bottom' height='180px'>
        <Box height='40px' justify='end'>
          {renderPrice()}
        </Box>
        <Box pad={{ top: 'small' }}>
          <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT}>
            {plan.name}
          </Text>
        </Box>
        <Box pad={{ top: 'medium' }} fill='horizontal'>
          <Text color={colors.ASANA_TEXT}>{plan.desc}</Text>
        </Box>
      </Box>
      {renderBullets()}
      <Button
        primary
        size='large'
        label={v ? 'Get Started' : 'Contact Us'}
        color={colors.AQUA_MARINE}
        disabled={plan.disabled}
        onClick={onButtonClick}
      />
    </Card>
  )
}

export default PlanCard
