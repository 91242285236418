import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Button, ThemeContext, Avatar, TextArea, Keyboard } from 'grommet'
import _ from 'lodash'
import { css } from 'styled-components'
import ReactResizeDetector from 'react-resize-detector'

import { getInitials } from 'shared/utils/stringUtils'
import colors from 'shared/constants/colors'
import { sendMessage } from 'controllers/chat'
import messageType from 'constants/messageType'

const SubInfoPanelContentFooter = ({
  subCompanyName,
  subAccountId,
  messagesEndRef,
  userProfile,
  workOrder,
  userId
}) => {
  const [message, setMessage] = useState('')
  const [focused, setFocused] = useState(false)

  const workOrderId = _.get(workOrder, 'id')
  const scrollToBottom = () => {
    if (messagesEndRef.current && focused) messagesEndRef.current.scrollIntoView(false)
  }

  const handleFocus = () => setFocused(true)

  const updateMessages = () => {
    if (message !== '' && subAccountId) {
      const gcAccountId = _.get(workOrder, 'accountId')
      const params = {
        text: message,
        accountId: gcAccountId,
        subId: subAccountId,
        userId,
        accounts: [gcAccountId, subAccountId],
        workOrderId,
        type: messageType.SUB,
        projectId: _.get(workOrder, 'projectId')
      }
      sendMessage(params)
      setMessage('')
    }
    scrollToBottom()
  }

  const handleBlur = e => {
    const { relatedTarget } = e
    if (_.get(relatedTarget, 'id') === 'send_message') {
      updateMessages()
    }
    setFocused(false)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
        updateMessages()
      } else {
        setMessage(message + '\n')
      }
    }
  }

  const onChange = event => {
    setMessage(event.target.value)
  }

  const textInputThemeValue = {
    textArea: {
      extend: css`
        background: ${colors.WHITE};
      `
    }
  }

  return (
    <Box
      background={colors.PALE_GREY}
      direction='row'
      align='center'
      height={focused ? { min: '115px' } : { min: '60px' }}
      pad={focused ? { bottom: 'small' } : 'none'}
    >
      <Box fill='vertical' justify='start' pad='small' width={{ min: '55px' }}>
        <Avatar size='32px' src={_.get(userProfile, 'avatar')}>
          {getInitials(userProfile)}
        </Avatar>
      </Box>
      <Box
        fill
        margin={{ right: 'small' }}
        background={colors.WHITE}
        customStyle={`
          height: ${focused ? '90px' : '40px'};
          border-radius: 5px;
          padding-top: ${focused ? '6px' : '0'};
        `}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <ThemeContext.Extend value={textInputThemeValue}>
          <ReactResizeDetector handleHeight onResize={scrollToBottom}>
            <Keyboard onKeyDown={handleKeyDown}>
              <TextArea
                plain
                onChange={onChange}
                value={message}
                size='small'
                resize={false}
                placeholder='Ask a question about this line item or post an update...'
              />
            </Keyboard>
          </ReactResizeDetector>
        </ThemeContext.Extend>
        {focused && (
          <Box
            direction='row'
            gap='small'
            align='center'
            customStyle={`
              position: absolute;
              bottom: 20px;
              right: 15px;
              animation: slide-up 0.5s ease;
              @keyframes slide-up {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
              }
            `}
          >
            <Text color={colors.ANOTHER_GREY} size='xsmall'>
              {subCompanyName} will be notified
            </Text>
            <Box width='xsmall'>
              <Button
                disabled={!focused}
                id='send_message'
                fill
                size='small'
                primary
                label='Comment'
                color={colors.AQUA_MARINE}
                onClick={() => updateMessages()}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

SubInfoPanelContentFooter.propTypes = {
  subCompanyName: PropTypes.string,
  subAccountId: PropTypes.string,
  userProfile: PropTypes.object,
  messagesEndRef: PropTypes.any,
  workOrder: PropTypes.object,
  userId: PropTypes.string
}

export default SubInfoPanelContentFooter
