import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Box, Text, Collapsible } from 'grommet'
import PropTypes from 'prop-types'
import generate from 'firebase-auto-ids'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import colors from 'shared/constants/colors'
import LineItemRow from 'components/workOrder/LineItemRow'
import LineItemCommentsPanel from 'webPages/workOrder/LineItemCommentsPanel'
import AddLineItemFromTemplate from 'components/createBid/AddLineItemFromTemplate'
import lineItemSection from 'shared/constants/lineItemSection'
import SectionRow from 'components/createBid/SectionRow'
import AddItemButton from 'components/workOrder/AddItemButton'
import { arrayMove } from 'shared/utils/array'
import ScopeOfWorkSubmenu from 'webPages/workOrder/ScopeOfWorkSubmenu'
import FilesPanel from 'components/panels/FilesPanel'
import lineItemType from 'shared/constants/lineItemType'
import LineItemQuestion from 'components/workOrder/LineItemQuestion'
import { useStateWithCallbackLazy } from 'shared/utils/hooks'
import ImportedLiPanel from 'webPages/workOrder/ImportedLiPanel'

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 26px 2fr 70px 70px 1fr 2fr 70px 70px 60px;
  ${props => props.customStyle}
`
const TableBodyContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  .selected_row {
    background-color: ${colors.AQUA_MARINE_LIGHT} !important;
  }
  .selected_cell {
    border-color: ${colors.AQUA_MARINE} !important;
  }
`
const draggingCss = css`
  border: 1px solid ${colors.VERY_LIGHT_GREY_TWO};
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`
const SectionContainer = styled.div`
  ${props => (props.isDragging ? draggingCss : '')}
`
const SectionItemsContainer = styled.div`
  min-height: 1px;
`
const DraggableGridContainer = styled.div`
  display: grid;
  ${props => (props.isDragging ? draggingCss : '')}
`

const ScopeOfWork = ({
  sow,
  setSow,
  workOrderId,
  messages,
  userId,
  sectionsDB,
  setSections,
  onDoneClick,
  projectId,
  importedLi
}) => {
  const [focused, setFocus] = useState(null)
  const [collapsedRows, setCollapsedRows] = useState({})
  const [draggingMode, setDraggingMode] = useState()
  const [typeOfAddedLi, setTypeOfAddedLi] = useStateWithCallbackLazy(lineItemType.DEFAULT)
  const commentsPanelRef = useRef()
  const addLineItemRef = useRef()
  const tableEndRef = useRef()
  const filesPanelRef = useRef()
  const addImportedLiRef = useRef()

  const resetTypeOfAddedLi = () => setTypeOfAddedLi(lineItemType.DEFAULT)

  useEffect(() => {
    if (focused) {
      const elt = document.getElementById(focused)
      if (elt) elt.focus()
      setFocus(null)
    }
  }, [focused, sow])

  const openFilesModal = useCallback(() => {
    filesPanelRef.current.open(workOrderId)
  }, [workOrderId])

  const openAddImportedLiModal = () => {
    addImportedLiRef.current.open()
  }

  const sections = useMemo(() => {
    console.log('%cstart DB sections processing', 'color: lightblue', sectionsDB)
    // const itemsKeys = _.keys(sow)
    // console.log('itemsKeys', itemsKeys)
    const scopeOfWorkSection = {
      id: lineItemSection.SCOPE_OF_WORK_ID,
      title: 'Scope of Work',
      items: []
    }

    const questionsSection = {
      id: lineItemSection.QUESTIONS_ID,
      title: 'Questions',
      items: []
    }

    if (_.isEmpty(sectionsDB)) {
      _.forEach(sow, (item, key) => {
        const itemType = _.get(item, 'type', lineItemType.DEFAULT)
        if (itemType === lineItemType.DEFAULT) {
          scopeOfWorkSection.items.push(key)
        } else {
          questionsSection.items.push(key)
        }
      })
      const data = [scopeOfWorkSection, questionsSection]
      console.log('sections are empty data', data)
      return data
    } else {
      const newSections = _.values(sectionsDB)
      let sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      let qSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.QUESTIONS_ID)
      console.log('sowSectionIndex', sowSectionIndex, 'qSectionIndex', qSectionIndex)
      if (sowSectionIndex < 0) {
        newSections.push(scopeOfWorkSection)
      }
      if (qSectionIndex < 0) {
        newSections.push(questionsSection)
      }
      const geSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
      if (geSectionIndex >= 0) {
        newSections.splice(geSectionIndex, 1)
      }
      console.log('sowSectionIndex', sowSectionIndex, 'qSectionIndex', qSectionIndex)
      sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      qSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.QUESTIONS_ID)
      // remove incorrect items from sections
      const itemsIdsInSections = {}
      _.forEach(newSections, s => {
        const sItems = [..._.get(s, 'items', [])]
        _.forEach(sItems, (itemId, i) => {
          const item = _.get(sow, itemId)
          let isIncorrect = _.isEmpty(item)
          const itemType = _.get(item, 'type', lineItemType.DEFAULT)
          isIncorrect = isIncorrect || (itemType === lineItemType.DEFAULT && s.id === lineItemSection.QUESTIONS_ID)
          isIncorrect = isIncorrect || (itemType !== lineItemType.DEFAULT && s.id !== lineItemSection.QUESTIONS_ID)
          isIncorrect = isIncorrect || _.has(itemsIdsInSections, itemId)
          if (isIncorrect) {
            s.items.splice(i, 1)
          } else {
            _.set(itemsIdsInSections, itemId, s.id)
          }
        })
      })
      console.log('newSections after removing incorrect items', newSections)
      console.log('itemsIdsInSections', itemsIdsInSections)

      _.forEach(sow, (item, itemId) => {
        const itemType = _.get(item, 'type', lineItemType.DEFAULT)
        if (!_.has(itemsIdsInSections, itemId)) {
          const sectionIndex = itemType === lineItemType.DEFAULT ? sowSectionIndex : qSectionIndex
          newSections[sectionIndex].items.push(itemId)
          _.set(itemsIdsInSections, itemId, _.get(newSections, [sectionIndex, 'id']))
        }
      })

      return newSections
    }
  }, [sectionsDB, sow])

  const openTemplateModal = useCallback(() => {
    addLineItemRef.current.open()
  }, [addLineItemRef])

  const addNewSection = useCallback(() => {
    const id = generate(_.now())
    const section = {
      title: '',
      id,
      items: []
    }
    console.log('sections now', sections)
    console.log('add new section', section)
    console.log('final to save', [section, ...sections])
    setSections([section, ...sections])
  }, [sections, setSections])

  const onMenuAddLineItemClick = useCallback(() => {
    const sowSectionIndex = _.findIndex(sections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
    const btnId = `add_li_button_${sowSectionIndex}`
    const elt = document.getElementById(btnId)
    if (elt) {
      elt.click()
    }
  }, [sections])

  const onMenuAddQuestionClick = useCallback(
    id => {
      setTypeOfAddedLi(id, () => {
        const sowSectionIndex = _.findIndex(sections, s => s.id === lineItemSection.QUESTIONS_ID)
        const btnId = `add_li_button_${sowSectionIndex}`
        const elt = document.getElementById(btnId)
        if (elt) {
          elt.click()
        }
      })
    },
    [sections, setTypeOfAddedLi]
  )

  const toggleAlternates = (id, expand = null) => {
    let isCollapced = _.get(collapsedRows, id, false)
    if (!_.isNil(expand)) isCollapced = expand
    if (isCollapced) {
      setCollapsedRows({ ...collapsedRows, [id]: false })
    } else {
      setCollapsedRows({ ...collapsedRows, [id]: true })
    }
  }

  const updateItem = (liId, params, parentLi) => {
    if (!_.isNil(parentLi)) {
      const parentItem = _.get(sow, parentLi.id)
      const newParentItem = {
        ...parentItem,
        alternates: {
          ..._.get(parentItem, 'alternates', {}),
          [liId]: {
            ..._.get(parentItem, ['alternates', liId], {}),
            id: liId,
            ...params
          }
        }
      }
      setSow({ ...sow, [parentItem.id]: newParentItem })
    } else {
      const newItems = {
        ...sow,
        [liId]: {
          ..._.get(sow, liId, {}),
          id: liId,
          ...params
        }
      }
      setSow(newItems)
    }
  }

  const renderTableHeaderTitle = (t, props) => (
    <Text size='xsmall' color={colors.ANOTHER_GREY} {...props}>
      {t}
    </Text>
  )

  const renderTableHeader = () => {
    return (
      <GridRow customStyle={`position: sticky; top: 0; background-color: white; z-index: 3;`}>
        <Box />
        <Box pad={{ horizontal: 'medium', vertical: 'medium' }} border={['bottom', 'right']}>
          {renderTableHeaderTitle('Scope of Work', { margin: { left: 'small' } })}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Comments')}
        </Box>
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Alternates')}
        </Box>
        {/* <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Type')}
        </Box> */}
        <Box justify='center' align='center' width={{ min: '60px' }} pad='xxsmall' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Quantity')}
        </Box>
        <Box justify='center' align='center' pad='none' size='150px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Description')}
        </Box>
        <Box justify='center' align='center' pad='none' size='120px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Required')}
        </Box>
        <Box justify='center' align='center' pad='none' size='120px' border={['bottom', 'right']}>
          {renderTableHeaderTitle('Labor only')}
        </Box>
        <Box justify='center' align='center' pad='none' size='50px' border={['bottom']} />
      </GridRow>
    )
  }

  const generateId = () => generate(_.now())

  const openLineItemPanel = itemId => {
    const params = {
      itemId,
      itemName: _.get(sow, [itemId, 'name']),
      workOrderId,
      canApproveForAll: false,
      item: _.get(sow, itemId)
    }
    commentsPanelRef.current.open(params)
  }

  const addAlternate = li => {
    const id = generateId()
    const alternates = {
      ...li.alternates,
      [id]: {
        name: '',
        desc: '',
        id,
        labor: false,
        required: false,
        quantity: ''
      }
    }
    setSow({ ...sow, [li.id]: { ...li, alternates } })
    setFocus(`name_${li.id}-${id}`)
  }

  const getRowId = (li, parentLi) => {
    return _.isNil(parentLi) ? li.id : `${parentLi.id}-${li.id}`
  }

  const selectRow = id => () => {
    const elt = document.getElementById(id)
    if (elt) elt.classList.add('selected_row')
  }

  const unselectRow = id => () => {
    const elt = document.getElementById(id)
    if (elt) elt.classList.remove('selected_row')
  }

  const _removeLineItemFromSections = (liId, sections) => {
    const newSections = _.map(sections, s => {
      const newItems = [..._.get(s, 'items')]
      _.remove(newItems, v => v === liId)
      return {
        ...s,
        items: newItems
      }
    })
    return newSections
  }

  const removeLineItem = (liId, parentLi) => {
    const newItems = { ...sow }
    if (!_.isNil(parentLi)) {
      _.unset(newItems, [parentLi.id, 'alternates', liId])
      setSow(newItems)
    } else {
      _.unset(newItems, liId)
      const newSections = _.map(sections, s => {
        const newItems = [..._.get(s, 'items')]
        _.remove(newItems, v => v === liId)
        return {
          ...s,
          items: newItems
        }
      })
      setSections(newSections)
      setSow(newItems)
    }
  }

  const renderTableRow = (li, parentLi, tableRowProps, dragHandleProps) => {
    const rowId = getRowId(li, parentLi)
    const expanded = !_.get(collapsedRows, li.id, false)
    return (
      <LineItemRow
        isDraggingOver={draggingMode}
        tableRowProps={tableRowProps}
        dragHandleProps={dragHandleProps}
        key={rowId}
        rowId={rowId}
        scope={sow}
        li={li}
        parentLi={parentLi}
        addAlternate={addAlternate}
        toggleAlternates={toggleAlternates}
        selectRow={selectRow(rowId)}
        unselectRow={unselectRow(rowId)}
        removeLineItem={removeLineItem}
        expanded={expanded}
        parentExpanded={!_.get(collapsedRows, _.get(parentLi, 'id'), false)}
        updateItem={updateItem}
        setCommentId={openLineItemPanel}
        messagesAmount={_.size(_.filter(_.get(messages, li.id), msg => msg.subId === userId))}
      />
    )
  }

  const renderLineItem = (li, index) => {
    const expanded = !_.get(collapsedRows, li.id, false)

    if (!_.isEmpty(li.alternates)) {
      return (
        <Draggable draggableId={li.id} index={index} key={li.id}>
          {(provided, snapshot) => {
            const tableRowProps = {
              ...provided.draggableProps,
              // ...provided.dragHandleProps,
              ref: provided.innerRef,
              isDragging: snapshot.isDragging
            }
            return (
              <DraggableGridContainer {...tableRowProps}>
                {renderTableRow(li, null, {}, provided.dragHandleProps)}
                <Collapsible open={expanded}>{_.map(li.alternates, alt => renderTableRow(alt, li))}</Collapsible>
              </DraggableGridContainer>
            )
          }}
        </Draggable>
      )
    } else {
      return (
        <Draggable draggableId={li.id} index={index} key={li.id}>
          {(provided, snapshot) => {
            const tableRowProps = {
              ...provided.draggableProps,
              // ...provided.dragHandleProps,
              ref: provided.innerRef,
              isDragging: snapshot.isDragging
            }
            return renderTableRow(li, null, tableRowProps, provided.dragHandleProps)
          }}
        </Draggable>
      )
    }
  }

  const addNewLineItems = (addItems, sectionInfo) => {
    console.log('addNewLineItemsFromTemplate, items', addItems)
    console.log('addNewLineItemsFromTemplate, sectionInfo', sectionInfo)
    const sectionId = _.get(sectionInfo, 'id', lineItemSection.SCOPE_OF_WORK_ID)
    const sectionIndex = _.findIndex(sections, s => s.id === sectionId)
    const s = _.get(sections, sectionIndex)
    console.log('sow section found', s)
    const sectionsCleaned = _.reduce(
      addItems,
      (res, li, liId) => {
        return _removeLineItemFromSections(liId, res)
      },
      sections
    )
    if (_.isEmpty(s)) {
      console.log('section ', sectionIndex, 'not found, add a new section')
      const newS = {
        id: sectionInfo.id,
        title: sectionInfo.title,
        items: _.keys(addItems)
      }
      const newSections = [newS, ...sectionsCleaned]
      const newItems = { ...sow, ...addItems }
      setSow(newItems)
      setSections(newSections)
    } else {
      const newItems = { ...sow, ...addItems }
      const newSections = sectionsCleaned
      const sectionItems = _.get(s, 'items', [])
      const newSectionItems = [...sectionItems, ..._.keys(addItems)]
      _.set(newSections, [sectionIndex, 'items'], newSectionItems)
      setSow(newItems)
      setSections(newSections)
    }
  }

  const removeSection = sectionIndex => {
    console.log('removeSection', sectionIndex)
    const sectionToRemove = _.get(sections, sectionIndex)
    const sectionId = _.get(sectionToRemove, 'id')
    if (sectionId === lineItemSection.SCOPE_OF_WORK_ID || sectionId === lineItemSection.GENERAL_EXCLUSIONS_ID) {
      console.log('cancel removing, cannot remove standard section', sectionId)
    }
    const sectionItems = _.get(sectionToRemove, 'items')
    const newSections = [...sections]
    newSections.splice(sectionIndex, 1)
    if (!_.isEmpty(sectionItems)) {
      const sowSectionIndex = _.findIndex(newSections, s => s.id === lineItemSection.SCOPE_OF_WORK_ID)
      const sowItems = _.get(newSections, [sowSectionIndex, 'items'], [])
      const newSowItems = [...sowItems, ...sectionItems]
      _.set(newSections, [sowSectionIndex, 'items'], newSowItems)
    }
    console.log('sections after removing', newSections)
    setSections(newSections)
  }

  const updateSection = (id, params) => {
    const newSections = _.map(sections, elt => {
      if (elt.id === id) {
        return { ...elt, ...params }
      } else {
        return elt
      }
    })
    setSections(newSections)
  }

  const createLineItem = (li, sectionIndex) => {
    console.log('%c createLineItem', 'color: red;', li, sectionIndex)
    const newItems = { ...sow, [li.id]: li }
    const newSections = [...sections]
    const sectionItems = _.get(newSections, [sectionIndex, 'items'], [])
    _.set(newSections, [sectionIndex, 'items'], _.uniq([...sectionItems, li.id]))
    setSow(newItems)
    setSections(newSections)
  }

  const renderSectionLine = (section, sectionIndex, provided, isDragging) => {
    const sectionId = _.get(section, 'id', lineItemSection.SCOPE_OF_WORK_ID)
    return (
      <SectionRow
        key={section.id}
        li={section}
        index={sectionIndex}
        isDraggingOver={draggingMode}
        isDragging={isDragging}
        dragHandleProps={provided.dragHandleProps}
        removeSection={() => removeSection(sectionIndex)}
        updateSection={updateSection}
        canAddItem={!_.includes([lineItemSection.GENERAL_EXCLUSIONS_ID], sectionId)}
        canRename={
          !_.includes(
            [lineItemSection.GENERAL_EXCLUSIONS_ID, lineItemSection.QUESTIONS_ID, lineItemSection.SCOPE_OF_WORK_ID],
            sectionId
          )
        }
        canDelete={
          !_.includes(
            [lineItemSection.GENERAL_EXCLUSIONS_ID, lineItemSection.QUESTIONS_ID, lineItemSection.SCOPE_OF_WORK_ID],
            sectionId
          )
        }
      />
    )
  }

  const renderAddItemButton = (sectionIndex, sectionId) => {
    return (
      <AddItemButton
        sectionIndex={sectionIndex}
        sectionId={sectionId}
        createLineItem={createLineItem}
        selectRow={selectRow}
        unselectRow={unselectRow}
        isDraggingOver={draggingMode}
        typeOfAddedLi={typeOfAddedLi}
        setTypeOfAddedLi={setTypeOfAddedLi}
        resetTypeOfAddedLi={resetTypeOfAddedLi}
      />
    )
  }

  const renderQuestionLineItem = (li, index) => {
    return (
      <Draggable draggableId={li.id} index={index} key={li.id}>
        {(provided, snapshot) => {
          const tableRowProps = {
            ...provided.draggableProps,
            ref: provided.innerRef,
            isDragging: snapshot.isDragging
          }
          return (
            <LineItemQuestion
              isDraggingOver={draggingMode}
              tableRowProps={tableRowProps}
              dragHandleProps={provided.dragHandleProps}
              key={li.id}
              rowId={li.id}
              scope={sow}
              li={li}
              updateItem={updateItem}
              selectRow={selectRow(li.id)}
              unselectRow={unselectRow(li.id)}
              removeLineItem={removeLineItem}
              setCommentId={openLineItemPanel}
            />
          )
        }}
      </Draggable>
    )
  }

  const renderSectionItems = (section, sectionIndex) => {
    const items = section.items
    return (
      <Droppable droppableId={section.id} type='ITEM'>
        {(provided, snapshot) => {
          // console.log('onSection droppable snapshot', snapshot)
          return (
            <SectionItemsContainer ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(items, (itemId, i) => {
                const li = _.get(sow, itemId)
                if (!_.isEmpty(li)) {
                  if (section.id === lineItemSection.QUESTIONS_ID) {
                    return renderQuestionLineItem(li, i)
                  } else {
                    return renderLineItem(li, i)
                  }
                } else {
                  return <Box key={itemId} />
                }
              })}
              {provided.placeholder}
            </SectionItemsContainer>
          )
        }}
      </Droppable>
    )
  }

  const renderSection = (section, sectionIndex) => {
    console.log('render section', sectionIndex)
    // console.log('renderSection', section.id, 'isDragDisabled', isDragDisabled)
    return (
      <Draggable draggableId={section.id} index={sectionIndex} key={section.id}>
        {(provided, snapshot) => {
          return (
            <SectionContainer {...provided.draggableProps} isDragging={snapshot.isDragging} ref={provided.innerRef}>
              {renderSectionLine(section, sectionIndex, provided, snapshot.isDragging)}
              {renderSectionItems(section, sectionIndex)}
              {renderAddItemButton(sectionIndex, section.id)}
            </SectionContainer>
          )
        }}
      </Draggable>
    )
  }

  const onDragEnd = event => {
    console.log('onDragEnd', event)
    const fromIndex = _.get(event, 'source.index')
    const toIndex = _.get(event, 'destination.index')
    console.log('move', fromIndex, ' => ', toIndex)
    setDraggingMode(false)
    if (_.isNil(fromIndex) || _.isNil(toIndex)) {
      console.log('cannot get indexes')
      return
    }
    if (event.type === 'SECTION') {
      const fromSectionId = _.get(sections, [fromIndex, 'id'])
      const toSectionId = _.get(sections, [toIndex, 'id'])
      if (
        toSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID ||
        fromSectionId === lineItemSection.GENERAL_EXCLUSIONS_ID
      ) {
        console.log('cannot move scope of work and general exclusions')
        return null
      }
      const newSections = arrayMove(sections, fromIndex, toIndex)
      // console.log('section move', newSections)
      setSections(newSections)
    } else {
      const fromSectionId = _.get(event, 'source.droppableId')
      const toSectionId = _.get(event, 'destination.droppableId')
      if (fromSectionId === toSectionId) {
        const sectionIndex = _.findIndex(sections, s => s.id === fromSectionId)
        const sectionItems = _.get(sections, [sectionIndex, 'items'], [])
        const newItems = arrayMove(sectionItems, fromIndex, toIndex)
        const newSections = [...sections]
        _.set(newSections, [sectionIndex, 'items'], newItems)
        setSections(newSections)
      } else {
        // console.log('fromSectionId', fromSectionId, 'toSectionId', toSectionId)
        const fromSectionIndex = _.findIndex(sections, s => s.id === fromSectionId)
        const toSectionIndex = _.findIndex(sections, s => s.id === toSectionId)
        // console.log('fromSectionIndex', fromSectionIndex, 'toSectionIndex', toSectionIndex)
        const fromSectionItems = _.get(sections, [fromSectionIndex, 'items'], [])
        const toSectionItems = _.get(sections, [toSectionIndex, 'items'], [])
        // console.log('fromSectionItems', fromSectionItems, 'toSectionItems', toSectionItems)
        const newSections = [...sections]
        const newFromSectionItems = [...fromSectionItems]
        const itemId = _.get(newFromSectionItems, fromIndex)
        newFromSectionItems.splice(fromIndex, 1)
        _.set(newSections, [fromSectionIndex, 'items'], newFromSectionItems)
        const newToSectionItems = [...toSectionItems]
        newToSectionItems.splice(toIndex, 0, itemId)
        _.set(newSections, [toSectionIndex, 'items'], newToSectionItems)
        if (toSectionId === lineItemSection.QUESTIONS_ID || fromSectionId === lineItemSection.QUESTIONS_ID) {
          const type = toSectionId === lineItemSection.QUESTIONS_ID ? lineItemType.QUESTION_BOOL : lineItemType.DEFAULT
          updateItem(itemId, { type })
        }

        // console.log('new Sections after moving item', itemId, newSections)
        setSections(newSections)
      }
    }
  }

  const onDragStart = () => {
    setDraggingMode(true)
  }

  const renderTableBody = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <TableBodyContainer>
          {renderTableHeader()}
          <Droppable droppableId='board' type='SECTION'>
            {(provided, snapshot) => {
              // console.log('Droppable isDragging over', snapshot.isDraggingOver)
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {_.map(sections, (s, i) => renderSection(s, i))}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
          <div ref={tableEndRef} />
        </TableBodyContainer>
      </DragDropContext>
    )
  }

  return (
    <Box flex>
      <ScopeOfWorkSubmenu
        addLineItem={onMenuAddLineItemClick}
        addQuestion={onMenuAddQuestionClick}
        addSection={addNewSection}
        addLineItemFromTemplate={openTemplateModal}
        onDone={onDoneClick}
        openFilesModal={openFilesModal}
        addImportedLi={openAddImportedLiModal}
        importedLi={importedLi}
      />
      <Box direction='row' flex>
        <Box pad={{ bottom: 'medium' }} customStyle={'flex: 1;'} flex={{ shrink: 0 }}>
          {renderTableBody()}
        </Box>
        <LineItemCommentsPanel ref={commentsPanelRef} workOrderId={workOrderId} />
      </Box>
      <AddLineItemFromTemplate ref={addLineItemRef} addNewLineItem={addNewLineItems} sections={sections} />
      <FilesPanel ref={filesPanelRef} />
      <ImportedLiPanel
        ref={addImportedLiRef}
        addNewLineItems={addNewLineItems}
        projectId={projectId}
        workOrderId={workOrderId}
        sections={sections}
        sow={sow}
      />
    </Box>
  )
}

ScopeOfWork.propTypes = {
  sow: PropTypes.object,
  setSow: PropTypes.func
}

const mapStateToProps = state => ({
  userId: _.get(state, ['user', 'id']),
  messages: _.get(state, 'messagesWeb.items'),
  channel: state.channelWeb,
  profiles: state.profiles,
  importedLi: state.importedLi
})

export default connect(mapStateToProps)(ScopeOfWork)
