import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress'
import { Box, Text } from 'grommet'

import colors from 'shared/constants/colors'

const LoadingWithProgress = ({ text, progress }) => {
  // console.log('LoadingWithProgress', progress)
  return (
    <Box fill align='center' justify='center' gap='small'>
      <Text color={colors.LIGHT_NAVY_BRIGHT}>{text}</Text>
      <Box height='10px' width='200px'>
        {!_.isNil(progress) && (
          <Line percent={progress} strokeColor={colors.LIGHT_NAVY_BRIGHT} trailColor={colors.PALE_GREY} />
        )}
      </Box>
    </Box>
  )
}

LoadingWithProgress.defaultProps = {
  text: 'Loading...'
}

LoadingWithProgress.propTypes = {
  text: PropTypes.string,
  progress: PropTypes.number
}

export default LoadingWithProgress
