import _ from 'lodash'
import Sentry from 'shared/utils/sentry'

import { db, fieldValue } from 'constants/firebase'

export const saveNewNote = async note => {
  console.log('saveNewNote', note)
  const text = _.get(note, 'text')
  if (_.isEmpty(_.toString(text))) {
    console.warn('the message is empty', note)
    return null
  }
  try {
    await db
      .collection('notes')
      .doc(note.workOrderId)
      .collection('messagesList')
      .doc(note.id)
      .set({ ...note, timestamp: fieldValue.serverTimestamp() })
  } catch (e) {
    console.log('save note error', e)
    Sentry.captureException(e)
  }
}

export const fetchSubNotes = (accountId, workOrderId, subId, callback) => {
  console.log('fetchSubNotes', accountId, workOrderId, subId)
  const unsubscribe = db
    .collection('notes')
    .doc(workOrderId)
    .collection('messagesList')
    .orderBy('timestamp')
    .where('accountId', '==', accountId)
    .where('subId', '==', subId)
    .onSnapshot(sn => {
      const notes = _.map(sn.docs, doc => doc.data())
      console.log('onNotes received', notes)
      callback(notes)
    })
  return unsubscribe
}

export const fetchSubsNotes = async (accountId, workOrderId, ids) => {
  const res = {}
  for (const subId of ids) {
    const sn = await db
      .collection('notes')
      .doc(workOrderId)
      .collection('messagesList')
      .orderBy('timestamp')
      .where('accountId', '==', accountId)
      .where('subId', '==', subId)
      .get()
    _.set(res, subId, !sn.empty)
  }
  return res
}
