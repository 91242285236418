export default [
  {
    tmpl: '_sub_name_',
    pth: 'owner.name',
    desc: 'subcontractor company name'
  },
  {
    tmpl: '_sub_address_',
    pth: 'owner.address',
    desc: 'subcontractor company full address',
    type: 'address'
  },
  {
    tmpl: '_gc_name_',
    pth: 'client.name',
    desc: 'contractor company name'
  },
  {
    tmpl: '_gc_address_',
    pth: 'client.address',
    desc: 'contractor company full address',
    type: 'address'
  },
  {
    tmpl: '_gc_email_',
    pth: 'client.email',
    desc: 'contractor company email'
  },
  {
    tmpl: '_gc_phone_',
    pth: 'client.phone',
    type: 'phone',
    desc: 'contractor company phone'
  },
  {
    tmpl: '_project_address_',
    pth: 'projectAddress',
    desc: 'project address',
    type: 'address'
  },
  {
    tmpl: '_bid_date_',
    pth: 'timestamp',
    type: 'date',
    desc: 'bid creation date'
  },
  {
    tmpl: '_bid_total_',
    pth: 'total',
    type: 'currency',
    desc: 'bid total'
  }
]
