import React, { useMemo } from 'react'
import { Box, Text } from 'grommet'
import { useResizeDetector } from 'react-resize-detector'
import { connect } from 'react-redux'
import _ from 'lodash'

import reportType from 'shared/constants/reportType'
import SelectedBidsReport from 'shared/reports/SelectedBidsReport'
import ScopesOnlyReport from 'shared/reports/ScopesOnlyReport'
import { getAccountsProfiles, getCurrentAccountProfile } from 'model/selectors/profiles'
import { getBidsByWorkOrders } from 'model/selectors/bids'
import { getWorkOrdersByProjectSorted } from 'model/selectors/projectsInfoSelector'
import { getTradesDict } from 'model/selectors/references'
import colors from 'shared/constants/colors'

const ReportView = ({
  reportSettings,
  project,
  type,
  accountProfile,
  bidsByWorkOrders,
  workOrders,
  contractorTypes,
  accountsProfiles
}) => {
  const { width, ref } = useResizeDetector()

  const content = useMemo(() => {
    // console.log('renderContent width', width)
    const docBaseProps = {
      width,
      paddingHorizontalK: 0.037,
      paddingVerticalK: 0.02,
      pageProps: {
        customStyle: `margin-bottom: ${width / 50}px;`,
        style: {
          shadowOpacity: 0.75,
          shadowRadius: 5,
          shadowColor: colors.BLACK20,
          shadowOffset: { height: 2, width: 2 }
        }
      }
    }
    switch (type) {
      case reportType.SELECTED_BIDS: {
        const doc = {
          gcAccountProfile: accountProfile,
          project,
          reportSettings,
          workOrders,
          bidsByWorkOrders,
          contractorTypes,
          accountsProfiles
        }
        return <SelectedBidsReport {...docBaseProps} doc={doc} />
      }
      case reportType.SCOPES_ONLY: {
        const doc = {
          gcAccountProfile: accountProfile,
          project,
          reportSettings,
          contractorTypes,
          workOrders
        }
        return <ScopesOnlyReport {...docBaseProps} doc={doc} />
      }
      default:
        return <Text>not implemented</Text>
    }
  }, [
    width,
    type,
    accountProfile,
    project,
    reportSettings,
    workOrders,
    bidsByWorkOrders,
    contractorTypes,
    accountsProfiles
  ])

  return (
    <Box fill width={{ max: '1024px' }} pad={{ vertical: 'medium' }} ref={ref}>
      {content}
    </Box>
  )
}

const mapStateToProps = (state, props) => ({
  project: _.get(state.projects, props.projectId),
  accountProfile: getCurrentAccountProfile(state),
  bidsByWorkOrders: getBidsByWorkOrders(state),
  workOrders: _.get(getWorkOrdersByProjectSorted(state), props.projectId),
  contractorTypes: getTradesDict(state),
  accountsProfiles: getAccountsProfiles(state)
})

export default connect(mapStateToProps)(ReportView)
