import Cookies from 'js-cookie'

const set = async (k, v, options) => {
  Cookies.set(k, v, options)
}

const get = async k => {
  return Cookies.get(k)
}

const remove = async k => {
  Cookies.remove(k)
}

export default {
  set,
  get,
  remove
}
