import { Platform, Alert, Linking } from 'react-native'
import * as Permissions from 'expo-permissions'
import _ from 'lodash'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import generate from 'firebase-auto-ids'

export async function browseFiles (source = 'images', targetWidth = 400) {
  let hasPermission = Platform.OS !== 'ios'
  let action

  if (source === 'camera') {
    action = ImagePicker.launchCameraAsync
  } else {
    action = ImagePicker.launchImageLibraryAsync
  }

  if (Platform.OS === 'ios') {
    console.log('check permissions')
    const { status } = await Permissions.getAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL)
    console.log('current permission status', status)
    if (status !== 'granted') {
      console.log('current status !== granted, ask permission')
      const { status } = await Permissions.askAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL)
      console.log('ask permission res status', status)
      hasPermission = status === 'granted'
    } else {
      hasPermission = true
    }
  }
  if (hasPermission) {
    const options = {
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.6
    }
    const res = await action(options)
    if (res.cancelled) {
      return null
    }
    console.log('picker res', res)
    const uri = _.get(res, 'uri', null)
    const sourceWidth = _.get(res, 'width', null)
    const sourceHeight = _.get(res, 'height', null)
    let thumbWidth
    let thumbHeight
    if (sourceWidth === 0 || sourceHeight === 0) {
      thumbHeight = thumbWidth = targetWidth
    } else if (sourceWidth > targetWidth) {
      const ratio = sourceWidth / targetWidth
      thumbWidth = targetWidth
      thumbHeight = sourceHeight / ratio
    } else {
      thumbWidth = sourceWidth
      thumbHeight = sourceHeight
    }
    const actions = [{
      resize: {
        width: thumbWidth,
        height: thumbHeight
      }
    }]
    const outputOptions = {
      compress: 1,
      format: ImageManipulator.SaveFormat.PNG
    }
    const manipResult = await ImageManipulator.manipulateAsync(uri, actions, outputOptions)
    const fileId = generate(_.now())
    return _.omitBy({
      id: fileId,
      name: 'image.png',
      url: uri,
      thumbUrl: _.get(manipResult, 'uri', null),
      isImage: true,
      width: res.width > 0 ? res.width : null,
      height: res.height > 0 ? res.height : null,
    }, _.isNil)
  } else {
    Alert.alert(
      'Allow "Camera" and "Photo" to add images to the chat',
      'Access to the camera or your photo library has been prohibited. Please enable it in the Settings app to continue.',
      [
        {
          text: 'Settings',
          onPress: () => Linking.openURL('app-settings:')
        },
        { text: 'OK', onPress: () => console.log('OK Pressed') }
      ],
      { cancelable: false }
    )
    return null
  }
}
