import React, { useState, useContext } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Avatar, Box, Text, Button, DropButton, ResponsiveContext } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'

import Logo from 'components/logos/LogoOnLight'
import universalNavigation from 'utils/universalNavigation'
import colors from 'shared/constants/colors'
import screens from 'constants/screens'
import DropContent from 'components/DropContent'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { getInitials } from 'shared/utils/stringUtils'
import { signOut } from 'controllers/auth'
import NavBarContainer from 'components/web/NavBarContainer'
import { generateId } from 'constants/firebase'
import CommentsInboxButton from 'components/chat/CommentsInboxButton'

const menuActionId = {
  SETTINGS: 'SETTINGS',
  HELP: 'HELP',
  SIGN_OUT: 'SIGN_OUT'
}
const menuActions = [
  { id: menuActionId.SETTINGS, label: 'Settings' },
  { id: menuActionId.HELP, label: 'Help & Support' },
  { id: menuActionId.SIGN_OUT, label: 'Sign out' }
]

const BidInvitesListNavBar = ({ userProfile }) => {
  const size = useContext(ResponsiveContext)
  const [selectOpened, setSelectOpened] = useState()

  const avatar = _.get(userProfile, 'avatarSmall')

  const toCreateProposal = () => {
    universalNavigation.push(screens.SUB_CREATE_PROPOSAL, { projectId: generateId() })
  }

  const onMenuAction = actionId => {
    console.log('onMenuAction', actionId)
    setSelectOpened(false)
    switch (actionId) {
      case menuActionId.SETTINGS:
        universalNavigation.navigate(screens.SETTINGS)
        break
      case menuActionId.SIGN_OUT:
        signOut()
        break
      case menuActionId.HELP:
        window.open('http://help.bidlevel.co/en/')
        break
    }
  }

  const dropContent = (
    <DropContent
      onOptionClick={onMenuAction}
      options={size === 'small' ? _.filter(menuActions, action => action.id !== menuActionId.SETTINGS) : menuActions}
      boxProps={{ width: '160px' }}
      optionLabelProps={{ textAlign: 'center' }}
    />
  )

  console.log('user profile', userProfile, 'initials', getInitials(userProfile))

  const dropButtonLabel = (
    <Box direction='row' align='center'>
      <Avatar size='29px' src={avatar} background={colors.LIGHT_NAVY_BRIGHT}>
        <Text color={colors.WHITE}>{getInitials(userProfile)}</Text>
      </Avatar>
    </Box>
  )

  if (size === 'small') {
    return (
      <Box align='center' pad='medium' direction='row' justify='between'>
        <Logo height='20px' />
        <DropButton
          plain
          label={dropButtonLabel}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropContent={dropContent}
          open={selectOpened}
          onOpen={() => setSelectOpened(true)}
          onClose={() => setSelectOpened(false)}
          dropProps={{ margin: { top: 'small' } }}
        />
      </Box>
    )
  } else {
    return (
      <NavBarContainer>
        <Box align='center' pad={{ horizontal: 'small' }}>
          <Logo height='20px' />
        </Box>
        <Box direction='row' gap='small'>
          <Button
            gap='xsmall'
            margin={{ right: 'xsmall', left: 'xsmall' }}
            label='Create proposal'
            color={colors.LIGHT_NAVY_BRIGHT}
            icon={<FontAwesomeIcon icon={faPlus} size={12} />}
            secondary
            onClick={toCreateProposal}
          />
          {/* <Button label='Order takeoff' primary color={colors.AQUA_MARINE} /> */}
          {/* <Button
            plain
            icon={<FontAwesomeIcon icon={faCalendarAlt} size={20} />}
            color={colors.LIGHT_NAVY_BRIGHT}
            disabled
          /> */}
          <CommentsInboxButton />
          {/* <Button
            icon={<FontAwesomeIcon icon={faCog} size={20} />}
            color={colors.LIGHT_NAVY_BRIGHT}
            onClick={onSettingsClick}
          /> */}
          <DropButton
            plain
            label={dropButtonLabel}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={dropContent}
            open={selectOpened}
            onOpen={() => setSelectOpened(true)}
            onClose={() => setSelectOpened(false)}
            dropProps={{ margin: { top: 'small' } }}
          />
        </Box>
      </NavBarContainer>
    )
  }
}

BidInvitesListNavBar.propTypes = {
  bidInvites: PropTypes.array
}

const mapStateToProps = state => ({
  userProfile: getCurrentUserProfile(state)
})

export default connect(mapStateToProps)(BidInvitesListNavBar)
