import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import _ from 'lodash'
import styled from 'styled-components'

import DropContentContainer from 'components/DropContentContainer.web'
import colors from 'shared/constants/colors'

const StyledText = styled(Text)`
  line-height: 1;
  width: 100%;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const DropContent = ({
  title,
  titleBorder,
  options,
  selectedId,
  multiple,
  onOptionClick,
  boxProps,
  optionFontColor,
  optionBorder,
  fontSize,
  optionLabelProps,
  renderOption,
  selectedColor,
  selectedBackground
}) => {
  const renderOptionLabel = (option, selected) => {
    if (!_.isNil(renderOption)) {
      return renderOption(option, selected)
    } else {
      const optionColor = _.get(option, 'color', colors.TEXT)
      const color = optionFontColor || optionColor
      const disabled = _.get(option, 'disabled', false)
      return (
        <Box direction='row' align='center' gap='xsmall' pad='xsmall' width='100%'>
          <StyledText
            size={fontSize || 'medium'}
            color={selected ? selectedColor || colors.LIGHT_NAVY : color}
            weight={selected ? 600 : 400}
            disabled={disabled}
            {...optionLabelProps}
          >
            {option.label}
          </StyledText>
        </Box>
      )
    }
  }
  const renderOptionRow = option => {
    const selected = multiple ? _.includes(selectedId, option.id) : option.id === selectedId
    const disabled = _.get(option, 'disabled', false)
    // const optionColor = _.get(option, 'color', colors.TEXT)
    // const color = optionFontColor || optionColor
    const externalAction = _.get(option, 'externalAction', false)
    return (
      <Box
        flex={false}
        key={option.id}
        pad={{ horizontal: 'small', vertical: 'small' }}
        round='none'
        onClick={disabled || externalAction ? undefined : e => onOptionClick(option.id, e, { ...option })}
        hoverIndicator={!selected}
        border={optionBorder || { side: 'bottom' }}
        fill='horizontal'
        background={selected ? selectedBackground || '' : ''}
      >
        {renderOptionLabel(option, selected)}
      </Box>
    )
  }

  const renderTitle = () => {
    if (!_.isEmpty(title)) {
      return (
        <Box border={titleBorder || { side: 'bottom' }} flex={false}>
          <Text margin={{ vertical: 'small', left: 'small' }} size={fontSize || 'medium'} color={colors.ANOTHER_GREY}>
            {title}
          </Text>
        </Box>
      )
    }
  }

  return (
    <DropContentContainer
      boxProps={{ pad: { top: !_.isEmpty(title) ? 'xsmall' : 'none' }, width: 'fill', ...boxProps }}
    >
      {renderTitle()}
      <Box overflow='auto'>{_.map(options, renderOptionRow)}</Box>
    </DropContentContainer>
  )
}

DropContent.defaultProps = {
  optionLabelProps: {}
}

const option = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool
})

DropContent.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(option),
  selectedId: PropTypes.string,
  onOptionClick: PropTypes.func,
  boxProps: PropTypes.object,
  optionLabelProps: PropTypes.object,
  renderOption: PropTypes.func
}

export default DropContent
