import React from 'react'
import { Box, Text, Button, Avatar, Image } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { getName, getInitials } from 'shared/utils/stringUtils'
import { getRolesTitles } from 'model/selectors/rolesSelectors'
import promoImage from 'assets/images/bidLevelPromoImage.png'
import BidLevelIcon from 'assets/icons/bidLevelIcon.svg'

const GcViewBidPanel = ({ account, profiles, rolesDict }) => {
  const renderTeammates = () => {
    const admins = _.get(account, 'admins', {})
    return (
      <Box overflow={{ horizontal: 'hidden', vertical: 'auto' }}>
        {_.map(admins, (adm, admId) => {
          const profile = _.get(profiles, admId)
          return (
            <Box
              key={admId}
              border={{ side: 'bottom' }}
              height={{ min: '54px' }}
              direction='row'
              align='center'
              pad={{ horizontal: 'small' }}
            >
              <Box flex={{ shrink: 0 }}>
                <Avatar size='24px' src={_.get(profile, 'avatarSmall')} background={colors.LIGHT_NAVY_BRIGHT}>
                  <Text color={colors.WHITE}>{getInitials(profile)}</Text>
                </Avatar>
              </Box>
              <Box fill='horizontal' pad={{ left: 'small' }}>
                <Text color={colors.TEXT}>{getName(profile)}</Text>
                <Text size='xsmall' color={colors.VERY_LIGHT_PINK}>
                  {_.get(profile, 'email')}
                </Text>
              </Box>
              <Text size='small' color={colors.MEDIUM_GREY}>
                {_.get(rolesDict, adm.role)}
              </Text>
              <Button color={colors.MEDIUM_GREY} icon={<FontAwesomeIcon icon={faEllipsisV} />} />
            </Box>
          )
        })}
      </Box>
    )
  }

  const compareSection = (
    <Box
      fill='horizontal'
      align='center'
      background={colors.PALE_GREY}
      pad={{ vertical: 'medium', horizontal: 'medium' }}
      flex={{ shrink: 0 }}
    >
      <Text color={colors.TEXT_PRIMARY} size='large' weight={600}>
        Compare to your other bids
      </Text>
      <Text color={colors.TEXT} textAlign='center' margin={{ top: 'small', horizontal: 'medium' }}>
        Add your other subcontractor bids to quickly get apples to apples comparisons
      </Text>
      <Box flex={{ shrink: 0 }} pad={{ vertical: 'medium' }}>
        <Image fit='cover' src={promoImage} />
      </Box>
      <Button icon={<BidLevelIcon />} label='Get started' secondary />
    </Box>
  )

  const header = (
    <Box
      border='bottom'
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      height='60px'
      align='center'
      justify='between'
      flex={{ shrink: 0 }}
      direction='row'
    >
      <Box>
        <Text size='large' weight={600}>
          Invite your team
        </Text>
        <Text color={colors.ANOTHER_GREY} size='small'>
          To share this bid
        </Text>
      </Box>
      <Button primary color={colors.AQUA_MARINE} label='Invite teammate' />
    </Box>
  )

  return (
    <Box
      fill='vertical'
      width='400px'
      border='start'
      customStyle={`box-shadow: -1px 4px 4px 1px rgba(0, 0, 0, 0.25)`}
      flex={{ shrink: 0 }}
    >
      {header}
      <Box flex justify='between'>
        {renderTeammates()}
        {compareSection}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  profiles: state.profiles,
  rolesDict: getRolesTitles(state)
})

export default connect(mapStateToProps)(GcViewBidPanel)
