import React, { Component } from 'react'
import { TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import styled, { css } from 'sc'
import _ from 'lodash'
import { LayoutContext } from 'shared/documents/components/LayoutContext'
import Block from 'shared/documents/components/Block'

const pageStyle = css`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: ${({ paddingHorizontal }) => paddingHorizontal}px;
  padding-right: ${({ paddingHorizontal }) => paddingHorizontal}px;
  padding-top: ${({ paddingVertical }) => paddingVertical}px;
  padding-bottom: ${({ paddingVertical }) => paddingVertical}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  ${props => props.customStyle};
  ${props => (props.isFirst ? props.firstPageCustomStyle : '')};
  ${props => (props.isLast ? props.lastPageCustomStyle : '')};
`

const ClickablePage = styled(TouchableOpacity)`
  ${pageStyle}
`
const SimplePage = styled(View)`
  ${pageStyle}
`

const SsrContainer = styled(View)`
  /* display: flex; */
  /* flex-direction: column; */
  display: block;
  background-color: white;
  margin-left: ${({ paddingHorizontal }) => paddingHorizontal}px;
  margin-right: ${({ paddingHorizontal }) => paddingHorizontal}px;
  width: ${props => props.width - props.paddingHorizontal * 2}px;
`

const HiddenContainer = styled(View)`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: -3000px;
  width: ${props => props.width}px;
`

class Document extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pages: [],
      childrenAr: []
    }
    this.sizes = {}
    this.hiddenContainerKey = 'def'
    this.hiddenContaierRef = React.createRef()
  }

  static getDerivedStateFromProps (props, state) {
    if (props.children !== state.prevChildren) {
      const childrenAr = React.Children.toArray(props.children)
      // console.log('GDSFP update childrenAr', childrenAr)
      return {
        childrenAr,
        prevChildren: props.children,
        hiddenContainerKey: _.now()
      }
    } else {
      return null
    }
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   const checkEqual = k => {
  //     const v1 = _.get(this.props, k)
  //     const v2 = _.get(nextProps, k)
  //     return _.isEqual(v1, v2)
  //   }
  //   const res = []
  //   _.forEach(nextProps, (v, k) => {
  //     if (!checkEqual(k)) res.push(v)
  //   })
  //   // console.log('fields are not equal', res)
  //   // console.log('SCU: props are same', _.isEqual(nextProps, this.props))
  //   return !_.isEmpty(res) || nextState !== this.state
  // }

  splitOnPages = () => {
    const { pageHeight, paddingVertical } = this.props
    // const { childrenAr } = this.state
    // console.log('split on pages', this.sizes, pageHeight)
    const freeHeight = pageHeight - paddingVertical * 2
    // const sum = _.sum(_.values(this.sizes))
    // console.log('sum blocks heights', sum)
    // console.log('height without paddings', freeHeight)
    const pages = [[]]
    let curPage = 0
    let curH = 0
    for (const i in this.sizes) {
      const h = this.sizes[i]
      // height = null indicates page break component
      // console.log(curPage, i, '- h', h, 'curH', curH)
      if (h === null || curH + h > freeHeight) {
        // console.log(curPage, i, 'new height becomes more free height', curH + h, '>', freeHeight, 'create new page')
        pages.push([])
        curPage = curPage + 1
        curH = 0
      }
      // add the child to the current page
      curH = curH + h
      pages[curPage].push(i)
    }
    // console.log('pages', pages)
    this.setState({ pages })
  }

  onLayout = (index, layout, isBreak) => {
    // console.log('onLayout', index, 'layout', layout, 'isBreak', isBreak)
    const { hiddenContainerKey } = this.state
    if (hiddenContainerKey !== this.hiddenContainerKey) {
      this.hiddenContainerKey = hiddenContainerKey
      this.sizes = {}
    }
    const { childrenAr } = this.state
    const elementSize = isBreak ? null : layout.height
    // console.log('onLayout, index', index, 'elementSize', elementSize, 'cur size', _.get(this.sizes, index))
    // if (elementSize === _.get(this.sizes, index)) {
    //   console.log('%cthis can be skipped', 'color: yellow;')
    // }
    _.set(this.sizes, index, elementSize)
    // console.log('onLayout', index, layout.height)
    // console.log('this.sizes', this.sizes, '_.size(sizes)', _.size(this.sizes))
    // console.log('childrenAr', childrenAr, '_.size(childrenAr)', _.size(childrenAr))
    if (_.size(this.sizes) >= _.size(childrenAr)) {
      const maxIndex = _.size(childrenAr) - 1
      this.sizes = _.omitBy(this.sizes, (v, k) => k > maxIndex)
      this.splitOnPages()
    }
  }

  renderPage = (p, pNum) => {
    const { pages, childrenAr } = this.state
    // console.log('render page', p, pNum)
    const {
      pageWidth,
      pageHeight,
      pageProps,
      onPageClick,
      activeOpacity,
      paddingVertical,
      paddingHorizontal
    } = this.props
    // console.log('renderPage pageWidth, pageHeight', pageWidth, pageHeight, 'paddingVertical', paddingVertical, 'paddingHorizontal', paddingHorizontal)
    const Page = _.isNil(onPageClick) ? SimplePage : ClickablePage
    return (
      <Page
        paddingVertical={paddingVertical}
        paddingHorizontal={paddingHorizontal}
        key={`page_${pNum}`}
        width={pageWidth}
        height={pageHeight}
        {...pageProps}
        isFirst={pNum === 0}
        isLast={pNum === pages.length - 1}
        activeOpacity={activeOpacity}
        onPress={onPageClick ? () => onPageClick(pNum) : undefined}
        // customStyle={pNum === 2 ? `${pageProps.customStyle}; background-color: yellow;` : pageProps.customStyle}
      >
        {_.map(p, childNum => {
          const ch = childrenAr[childNum]
          // console.log('renderPage: childNum', childNum, 'key', _.get(ch, 'key'), 't:', this.getChildrenText(ch))
          return ch
        })}
      </Page>
    )
  }

  renderPages () {
    const { pages } = this.state
    // console.log('renderPages', pages)
    const { pageNum } = this.props
    // return this.renderPage(pages[2], 2)
    if (_.isNil(pageNum)) {
      return _.map(pages, this.renderPage)
    } else {
      return this.renderPage(pages[pageNum], pageNum)
    }
  }

  getChildrenText = ch => {
    const res = _.get(ch, ['props', 'children', 1, 'props', 'children'])
    if (_.isString(res)) return res
  }

  onHiddenContainerLayout = () => {
    // console.log('on hidden container layout', this.hiddenContaierRef.current)
    const hiddenChildren = _.get(this.hiddenContaierRef.current, 'children')
    // console.log('hiddenChildren', hiddenChildren)
    const sizes = _.map(hiddenChildren, ch => {
      const brect = ch.getBoundingClientRect()
      // console.log('ch.id', ch.getBoundingClientRect())
      const isBreak = brect.width === 0 && brect.height === 0
      return isBreak ? null : ch.getBoundingClientRect().height
    })
    // console.log('sizes', sizes)
    // const childrenProps = _.map(hiddenChildren, ch => _.get(ch, 'id'))
    // console.log('childrenProps', childrenProps)
    // console.log('hidden container height:', this.hiddenContaierRef.current.getBoundingClientRect().height)
    this.sizes = sizes
    this.splitOnPages()
  }

  renderHiddenContainer = layoutContextValue => {
    const { hiddenContainerKey, childrenAr } = this.state
    const { pageWidth, paddingHorizontal } = this.props
    // console.log('renderHiddenContainer', childrenAr)
    // const children = React.Children.toArray(this.props.children)
    // console.log('---> renderHiddenContainer ', _.size(childrenAr), React.Children.count(this.props.children))
    // console.log(
    //   'hiddedContainer width',
    //   pageWidth - paddingHorizontal * 2,
    //   'pageWidth',
    //   pageWidth,
    //   'paddingHorizontal',
    //   paddingHorizontal
    // )
    return (
      <HiddenContainer
        // key={hiddenContainerKey}
        width={pageWidth - paddingHorizontal * 2}
        ref={this.hiddenContaierRef}
        onLayout={this.onHiddenContainerLayout}
      >
        {/* <LayoutContext.Provider value={layoutContextValue}> */}
        {_.map(childrenAr, (ch, index) => (
          <Block index={index} key={index} debugParams={{ t: this.getChildrenText(ch) }}>
            {ch}
          </Block>
        ))}
        {/* </LayoutContext.Provider> */}
      </HiddenContainer>
    )
  }

  render () {
    const { pages, childrenAr } = this.state
    const { pageWidth, pageHeight, children, ssr, paddingHorizontal } = this.props
    // console.log('Document render pageWidth', pageWidth, 'pageHeight', pageHeight)
    if (pageWidth === 0 || pageHeight === 0) {
      return null
    } else if (ssr) {
      return (
        <SsrContainer paddingHorizontal={paddingHorizontal} width={pageWidth}>
          {_.map(childrenAr, (ch, index) => (
            <div key={`cmp${index}`} className={'doc-block'}>
              {ch}
            </div>
          ))}
        </SsrContainer>
      )
    } else {
      // const viewport = {
      //   width: pageWidth,
      //   height: pageHeight
      // }
      // const layoutContextValue = {
      //   onLayout: this.onLayout,
      //   viewport
      // }
      return (
        <View>
          {/* {this.renderHiddenContainer(layoutContextValue)} */}
          {this.renderHiddenContainer()}
          {this.renderPages()}
        </View>
      )
    }
  }
}

Document.defaultProps = {
  pageProps: {},
  pageWidth: 595,
  pageHeight: 842,
  ssr: false,
  activeOpacity: 1,
  paddingVertical: 0,
  paddingHorizontal: 0
}

Document.propTypes = {
  ssr: PropTypes.bool,
  padding: PropTypes.number,
  pageWidth: PropTypes.number,
  pageHeight: PropTypes.number,
  pageNum: PropTypes.number,
  pageProps: PropTypes.object,
  onPageClick: PropTypes.func,
  activeOpacity: PropTypes.number,
  paddingVertical: PropTypes.number,
  paddingHorizontal: PropTypes.number
}

export default Document
