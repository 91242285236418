import React, { useMemo, useRef } from 'react'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import _ from 'lodash'
import { connect } from 'react-redux'

import colors from 'shared/constants/colors'
import { getWorkOrdersByProject } from 'model/selectors/projectsInfoSelector'
import { getWorkOrdersWithTitlesDict } from 'model/selectors/workOrdersSelector'
import { cutString } from 'shared/utils/stringUtils'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import DropDownMenu from 'components/DropDownMenu'
import { changeArchiveProjectStatus } from 'controllers/project'

const EDIT_PROJECT = 'EDIT_PROJECT'
const TOGGLE_ARCHIVED = 'TOGGLE_ARCHIVED'

const ProjectRow = ({ onClick, onEditProjectClick, project, workOrdersByProject, workOrdersWithTitlesDict }) => {
  const dropRef = useRef()

  const onRowClick = () => {
    onClick(project.id)
  }

  const projectId = _.get(project, 'id')

  const isArchived = _.toNumber(_.get(project, 'deleted', 0)) > 0

  const hasInvitations = !_.isEmpty(_.get(workOrdersByProject, projectId))

  const tradesNames = useMemo(() => {
    const projectWorkOrders = _.get(workOrdersByProject, projectId)
    const titles = _.map(projectWorkOrders, wo => _.get(workOrdersWithTitlesDict, [wo.id, 'title']))
    return titles.join(', ')
  }, [projectId, workOrdersByProject, workOrdersWithTitlesDict])

  const projectNameColor = isArchived ? colors.BADGE_BG : colors.TEXT

  const { label, labelColor } = useMemo(() => {
    if (isArchived) {
      return { label: undefined, labelColor: colors.BADGE_BG }
    } else if (!hasInvitations) {
      return { label: 'No bid invitations', labelColor: colors.VERY_LIGHT_PINK }
    } else {
      return { label: tradesNames, labelColor: colors.AQUA_MARINE }
    }
  }, [tradesNames, hasInvitations, isArchived])

  const onMenuActionClick = actionId => {
    switch (actionId) {
      case EDIT_PROJECT:
        onEditProjectClick(projectId)
        break
      case TOGGLE_ARCHIVED:
        changeArchiveProjectStatus(projectId, !isArchived)
        break
    }
  }

  const onMoreClick = e => {
    console.log('onMoreClick')
    e.stopPropagation()
    dropRef.current.open()
  }

  return (
    <Box
      direction='row'
      height='54px'
      align='center'
      justify='between'
      onClick={onRowClick}
      hoverIndicator={colors.ALMOST_WHITE}
      pad={{ right: 'xsmall' }}
      customStyle={`
        :hover {
          #project_row_button_icon {
            display: flex;
          }
        }
      `}
    >
      <Box direction='row' align='center'>
        <Box
          width='50px'
          direction='row'
          align='center'
          justify='center'
          margin={{ right: 'xsmall' }}
          flex={{ shrink: 0 }}
        >
          <Box width='19px' height='19px' align='center' justify='center' round='full' background={labelColor} />
        </Box>
        <Box gap='xxsmall'>
          <Text color={projectNameColor}>{_.get(project, 'label', _.get(project, 'address.name'))}</Text>
          <Text color={labelColor} size='xsmall'>
            {cutString(label, 70)}
          </Text>
        </Box>
      </Box>
      <Box direction='row' align='center'>
        {isArchived && (
          <Text italic color={colors.ASANA_GRAY_TEXT_HOVERED}>
            archived
          </Text>
        )}
        <Box width='40px' direction='row' align='center' justify='end'>
          <DropDownMenu
            options={[
              { id: EDIT_PROJECT, label: 'Edit project details' },
              {
                id: TOGGLE_ARCHIVED,
                label: isArchived ? 'Unarchive' : 'Archive',
                color: isArchived ? undefined : colors.CORAL_TWO
              }
            ]}
            dropRef={dropRef}
            dropContentProps={{ boxProps: { width: { min: '260px' } } }}
            dropAlign={{ top: 'bottom', right: 'right' }}
            onOptionClick={onMenuActionClick}
          >
            <Box
              customStyle='display: none;'
              id='project_row_button_icon'
              onClick={onMoreClick}
              pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
              hoverIndicator={colors.VERY_LIGHT_GREY_TWO}
              round='xsmall'
            >
              <FontAwesomeIcon icon={faEllipsisV} color={colors.ASANA_GRAY_TEXT_HOVERED} size={18} />
            </Box>
          </DropDownMenu>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  workOrdersByProject: getWorkOrdersByProject(state),
  workOrdersWithTitlesDict: getWorkOrdersWithTitlesDict(state)
})

export default connect(mapStateToProps)(ProjectRow)
