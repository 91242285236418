import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SlidingLeftPanel from 'components/SlidingLeftPanel.web'
import LineItemPanelContent from 'components/leveling/levelingPanel/LineItemPanelContent'

class LineItemCommentsPanel extends Component {
  constructor (props) {
    super(props)
    this.panelRef = React.createRef()
    this.state = {
      panelProps: undefined
    }
  }

  open = params => {
    this.setState({ panelProps: params })
    this.panelRef.current.open()
  }

  close = () => this.panelRef.current.close()

  render () {
    const { onClose, workOrderId } = this.props
    const { panelProps } = this.state
    return (
      <SlidingLeftPanel ref={this.panelRef} onClose={onClose}>
        <LineItemPanelContent onClose={this.close} {...panelProps} workOrderId={workOrderId} />
      </SlidingLeftPanel>
    )
  }
}

LineItemCommentsPanel.propTypes = {
  onClose: PropTypes.func,
  workOrderId: PropTypes.string
}

export default LineItemCommentsPanel
