import React from 'react'
import { Box, Button, Text } from 'grommet'

import colors from 'shared/constants/colors'

const Footer = ({ handleResolve, isResolved }) => {
  if (isResolved) {
    return (
      <Box align='center' pad='large' height='xxsmall' background={colors.LIGHT_NAVY_BRIGHT} direction='row' justify='center' gap='medium'>
        <Text size='medium' color={colors.WHITE}>Open in a desktop browser to create your bid</Text>
      </Box>
    )
  }
  return (
    <Box customStyle='position: fixed; bottom: 0; left: 0;' width='100%'>
      <Box pad='large' height='xxsmall' background={colors.LIGHT_NAVY_BRIGHT} direction='row' justify='center' gap='medium'>
        <Box justify='center' width='small' flex>
          <Button color={colors.CORAL_TWO} primary label='Decline' onClick={() => handleResolve(false)} />
        </Box>
        <Box justify='center' width='small' flex>
          <Button color={colors.AQUA_MARINE} primary label='Accept' onClick={() => handleResolve(true)} />
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
