import React from 'react'
import _ from 'lodash'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'

const Details = ({ address, projectName, gcName, bidsDue, isEmptySow = true, props = { background: colors.PALE_GREY } }) => {
  return (
    <Box height='xxsmall' direction='row' gap='large' pad={{ horizontal: 'small' }} {...props} width='100%'>
      <Box direction='row' gap='xsmall' align='center'>
        <Text weight={600} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>{_.get(projectName, 'isLabel') ? 'Project label:' : 'Project address:'}</Text>
        <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT}>{_.get(projectName, 'name', address)}</Text>
      </Box>
      <Box direction='row' gap='xsmall' align='center'>
        <Text weight={600} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>Client name:</Text>
        <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT}>{gcName || '–'}</Text>
      </Box>
      <Box direction='row' gap='xsmall' align='center'>
        <Text weight={600} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>Bids due:</Text>
        <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT}>{bidsDue || '–'}</Text>
      </Box>
      <Box direction='row' gap='xsmall' align='center'>
        <Text weight={600} size='medium' color={colors.LIGHT_NAVY_BRIGHT}>Scope of work:</Text>
        <FontAwesomeIcon
          icon={isEmptySow ? faTimesSquare : faCheckSquare}
          color={isEmptySow ? colors.CORAL_TWO : colors.AQUA_MARINE}
        />
      </Box>
    </Box>
  )
}

export default Details
