import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Text, DropButton, Button } from 'grommet'
import _ from 'lodash'
import Tip from 'components/Tip'
import DropDownMenu from 'components/DropDownMenu'
import colors from 'shared/constants/colors'
import { formatPhone } from 'shared/utils/phone'
// import StyledCheckbox from 'components/StyledCheckbox'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus, faAngleDown, faWindowClose, faTimes, faTrash } from '@fortawesome/pro-light-svg-icons'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'
// import { columns } from './SettingsContactsData'
// import Tag from 'components/contacts/Tag'
import SlidingLeftPanel from 'components/SlidingLeftPanel.web'
import ContactPanelContent from 'components/contacts/ContactPanelContent'
import { updateAccountContacts, deleteAccountContacts, addNewContacts } from 'controllers/contacts'
import AddContactModal from 'components/contacts/AddContactModal'
import NavBar from 'webPages/layout/NavBar'
import NavBarContainer from 'components/web/NavBarContainer'
import { isEmail } from 'validator'
import ContactsTable from 'pages/settings/settingsContacts/ContactsTable'
import ImportedLiPanelFilterMenu from 'webPages/workOrder/importedLiPanel/ImportedLiPanelFilterMenu'

const menuActions = {
  UPLOAD_CSV: 'UPLOAD_CSV',
  ADD_NEW_CONTACT: 'ADD_NEW_CONTACT'
}

const ALL_OPTIONS = 'ALL_OPTIONS'

const menuOptions = [
  { id: menuActions.UPLOAD_CSV, label: 'Import .csv' },
  { id: menuActions.ADD_NEW_CONTACT, label: 'Add new contact' }
]

const Contacts = () => {
  const [contactIds, setContactIds] = useState([])
  const [importedCompanies, setImportedCompanies] = useState({})
  const [importedContacts, setImportedContacts] = useState({})
  const contacts = useSelector(state => state.contacts)
  const canOpenNewContactModal = useRef(true)
  const [currentFilters, setCurrentFilters] = useState({})
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const filtersSettings = useMemo(() => {
    const tagsDict = {}
    _.forEach(_.get(contacts, 'contacts'), c => {
      _.forEach(_.get(c, 'tags'), tag => {
        _.set(tagsDict, tag, true)
      })
    })
    const allTags = _.sortBy(_.keys(tagsDict))
    return {
      byTags: {
        label: 'By tags',
        options: _.map(allTags, v => ({ label: v, value: v }))
      }
    }
  }, [contacts])

  useEffect(() => {
    if (contactIds.length > 0) {
      canOpenNewContactModal.current = false
      setEditTags(getAllTags(contactIds))
      panelRef.current.open() // open side panel
    } else {
      setEditTags([])
      panelRef.current.close() // close side panel
      setSidePanelCompanyId(null) // remove company from side panel
      setTimeout(() => {
        canOpenNewContactModal.current = true
      }, 500)
    }
  }, [contactIds])

  const [csvShow, setCSVShow] = useState(false)
  const [sidePanelCompanyId, setSidePanelCompanyId] = useState(null)
  const [editTags, setEditTags] = useState([])
  const panelRef = useRef()
  const contactModalRef = useRef()

  const updateContactIds = idArray => {
    setContactIds(idArray)
  }

  const closeSidePanel = () => {
    updateContactIds([])
  }

  const getAllTags = contactIds => {
    let _tags = []
    _.map(contactIds, cId => {
      _tags.push(..._.get(contacts, `contacts.${cId}.tags`, []))
    })
    return _tags
  }

  // const updateContacts = (_companyId, _contactIds, _editingTags) => {
  //   closeSidePanel()
  //   updateAccountContacts(_companyId, _contactIds, _editingTags)
  // }

  const updateContacts = (_contactIds, _editingTags) => {
    closeSidePanel()
    updateAccountContacts(_contactIds, _editingTags)
  }

  const deleteContacts = contactIds => {
    closeSidePanel()
    deleteAccountContacts(contactIds)
  }

  const handleDropDownClick = id => {
    switch (id) {
      case menuActions.UPLOAD_CSV: {
        setCSVShow(true)
        break
      }
      case menuActions.ADD_NEW_CONTACT: {
        if (canOpenNewContactModal.current) {
          contactModalRef.current.open({ contactId: null })
        }
        break
      }
      default:
        break
    }
  }

  // const _toggleContactCheck = contactId => {
  //   const _contactIds = _.map(contactIds)
  //   if (_.includes(_contactIds, contactId)) {
  //     // already checked
  //     _.remove(_contactIds, id => id === contactId)
  //   } else {
  //     // not checked
  //     _contactIds.push(contactId)
  //   }
  //   updateContactIds(_contactIds)
  // }

  // const _onHeaderCheckToggle = () => {
  //   updateContactIds([])
  // }

  // const renderCheckBox = contactId => (
  //   <StyledCheckbox
  //     checked={_.includes(contactIds, contactId)}
  //     onChange={() => {
  //       canOpenNewContactModal.current = false
  //       _toggleContactCheck(contactId)
  //     }}
  //   />
  // )

  // const renderHeaderCheckBox = () => (
  //   <StyledCheckbox
  //     checked={contactIds.length > 0}
  //     onChange={() => {
  //       _onHeaderCheckToggle()
  //     }}
  //   />
  // )

  // const renderEmails = (contactId, email, name) => {
  //   return (
  //     <Box>
  //       <Text size='small' color={colors.TEXT}>
  //         {email}
  //       </Text>
  //     </Box>
  //   )
  // }

  // const renderPhones = (contactId, phone, name) => {
  //   return (
  //     <Box>
  //       <Text size='small' color={colors.TEXT}>
  //         {phone}
  //       </Text>
  //     </Box>
  //   )
  // }

  // const renderTags = contactId => {
  //   const tags = _.get(contacts, `contacts.${contactId}.tags`, {})
  //   return (
  //     <Box direction='row' gap='xsmall'>
  //       {_.map(tags, (tag, index) => {
  //         return <Tag key={`render-contact-tag-${index}`} tagId='' label={tag} noNeedRemoveTag />
  //       })}
  //     </Box>
  //   )
  // }

  // const renderContacts = () => {
  //   const _columns = columns(renderCheckBox, renderTags, renderHeaderCheckBox, renderEmails, renderPhones)
  //   const contact = _.get(contacts, 'contacts')
  //   const data = []
  //   _.map(contact, c => {
  //     const id = _.get(c, 'id', '')
  //     const name = _.get(c, 'name', '')
  //     const company = _.get(contacts, `companies.${_.get(c, 'companyId', '')}.name`, '')
  //     const email = _.concat(_.get(c, 'emails', {})).join(', ')
  //     const phones = _.map(_.get(c, 'phones', {}), p => formatNational(p))
  //     const phone = _.concat(phones).join(', ')
  //     data.push({
  //       id,
  //       name,
  //       company,
  //       email,
  //       phone,
  //       tag: 'Tag'
  //     })
  //   })
  //   const tableColumns = _columns.map(c => ({ ...c, search: c.property === 'name' || c.property === 'company' }))
  //   console.log('data', data)
  //   console.log('tableColumns', tableColumns)
  //   return (
  //     <DataTable
  //       columns={tableColumns}
  //       data={data}
  //       sortable
  //       // // resizeable
  //       paginate
  //       show={{ page: 2 }}
  //       border={{
  //         body: {
  //           side: 'all',
  //           color: colors.VERY_LIGHT_GREY_TWO
  //         },
  //         header: {
  //           side: 'all',
  //           color: colors.VERY_LIGHT_GREY_TWO
  //         }
  //       }}
  //     />
  //   )
  // }

  const stringToId = s => {
    return _.snakeCase(_.trim(s))
  }

  const parsePhones = s => {
    const ar = _.split(s, /(?:,|\/)+/)
    const phonesRaw = _.map(ar, formatPhone)
    return _.compact(phonesRaw)
  }

  const parseEmails = s => {
    const ar = _.split(s, /(?:,|\/)+/)
    const emailsRaw = _.filter(ar, emailRaw => isEmail(_.trim(emailRaw)))
    return _.compact(_.map(emailsRaw, _.trim))
  }

  const parseTags = s => _.compact(_.map(_.split(s, /(?:,|\/)+/), _.trim))

  const processRows = async rows => {
    console.log('rows', rows)
    const companies = {}
    const contacts = {}
    _.forEach(rows, row => {
      const company = {
        id: stringToId(_.get(row, 'companyName')),
        name: _.get(row, 'companyName'),
        phones: parsePhones(_.get(row, 'phone')),
        emails: parseEmails(_.get(row, 'email'))
      }
      const contact = {
        companyId: company.id,
        id: stringToId(_.get(row, 'contactName')),
        name: _.get(row, 'contactName'),
        phones: parsePhones(_.get(row, 'phone')),
        emails: parseEmails(_.get(row, 'email')),
        tags: parseTags(_.get(row, 'tag'))
      }
      if (!_.isEmpty(company.id)) {
        _.set(companies, company.id, company)
      }
      if (!_.isEmpty(contact.id)) {
        _.set(contacts, contact.id, contact)
      }
    })
    setImportedCompanies(importedCompanies => ({ ...importedCompanies, ...companies }))
    setImportedContacts(importedContacts => ({ ...importedContacts, ...contacts }))
  }

  const applyImport = async () => {
    console.log('imported companies', importedCompanies)
    console.log('imported contacts', importedContacts)
    await addNewContacts(importedCompanies, importedContacts)
    setCSVShow(false)
  }

  const applyFilter = (category, options) => {
    console.log('applyFilter', category, options)
    setCurrentFilters(currentFilters => {
      const newFilters = {
        ...currentFilters,
        [category.value]: {
          category,
          options
        }
      }
      if (
        !_.isEqual(_.get(filterMenuOpen, 'value'), _.get(category, 'value')) &&
        !_.isEqual(filterMenuOpen, ALL_OPTIONS)
      ) {
        delete newFilters[filterMenuOpen.value]
      }
      return newFilters
    })
    setFilterMenuOpen(false)
  }

  const renderFilterMenu = () => {
    return (
      <ImportedLiPanelFilterMenu
        filtersSettings={filtersSettings}
        close={() => setFilterMenuOpen(false)}
        onApply={applyFilter}
        filters={currentFilters}
        filterMenuOpen={filterMenuOpen}
      />
    )
  }

  const removeFilter = category => {
    setCurrentFilters(currentFilters => {
      const newFilters = { ...currentFilters }
      _.unset(newFilters, category.value)
      return newFilters
      // this.setState({ currentFilters: newFilters, selectedRows: [] }, this.recalcLineItems)
    })
  }

  const renderFilter = ({ category, options }) => {
    console.log('renderFilter', category, options)
    if (_.isEmpty(options)) return null
    const label = _.get(category, 'label')
    let value = _.get(_.values(options), [0, 'label'])
    const tipMessage = _.map(options, opt => (
      <Text key={opt.value}>
        {opt.label}
        <br />
      </Text>
    ))
    if (_.size(options) > 1) {
      value = `${_.get(_.values(options), [0, 'label'])} or ${_.size(options) - 1} other`
    }
    const renderText = (
      <Text color={colors.MEDIUM_GREY}>
        <Text weight={700} color={colors.MEDIUM_GREY}>
          {_.capitalize(label)}
        </Text>{' '}
        is {value}
      </Text>
    )
    return (
      <Tip message={tipMessage} down>
        <DropButton
          key={`filter_${label}`}
          open={_.isEqual(filterMenuOpen, category)}
          onClick={() => setFilterMenuOpen(category)}
          dropContent={renderFilterMenu()}
          dropAlign={{ top: 'top', left: 'left' }}
          onClose={() => setFilterMenuOpen(false)}
        >
          <Box align='center' direction='row' gap='small' pad='xsmall' round='xsmall'>
            <Box
              background={colors.VERY_LIGHT_PINK_TWO}
              pad='xsmall'
              align='center'
              round='xsmall'
              direction='row'
              gap='small'
              // flex={{ shrink: 0 }}
            >
              {renderText}
              <Box
                onClick={e => {
                  e.stopPropagation()
                  removeFilter(category)
                }}
                align='center'
              >
                <FontAwesomeIcon icon={faTimes} size={12} color={colors.MEDIUM_GREY} />
              </Box>
            </Box>
          </Box>
        </DropButton>
      </Tip>
    )
  }

  const resetFilters = () => {
    setCurrentFilters({})
  }

  console.log('current filters', currentFilters)

  const renderFilters = () => {
    return (
      <Box direction='row' gap='small' pad={{ vertical: 'small' }} fill='horizontal' flex={{ shrink: 0 }}>
        <Box direction='row' gap='small' width={{ max: '75%' }} overflow={{ horizontal: 'auto' }}>
          {_.map(currentFilters, renderFilter)}
        </Box>
        <Box align='center' justify='center'>
          <DropButton
            open={_.isEqual(filterMenuOpen, ALL_OPTIONS)}
            onClick={() => setFilterMenuOpen(ALL_OPTIONS)}
            dropContent={renderFilterMenu()}
            dropAlign={{ top: 'top', left: 'left' }}
            onClose={() => setFilterMenuOpen(false)}
          >
            <Box
              align='center'
              direction='row'
              gap='small'
              hoverIndicator
              onClick={() => null}
              pad='xsmall'
              round='xsmall'
            >
              <FontAwesomeIcon icon={faPlus} size={14} color={colors.LIGHT_NAVY_BRIGHT} />
              <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>
                Add filter
              </Text>
            </Box>
          </DropButton>
        </Box>
        <Box direction='row' margin={{ left: 'auto', right: 'small' }} align='center' gap='medium'>
          {_.size(currentFilters) > 0 && (
            <Button
              size='small'
              color={colors.LIGHT_NAVY_BRIGHT}
              label={<Text color={colors.LIGHT_NAVY_BRIGHT}>Clear filters</Text>}
              icon={<FontAwesomeIcon icon={faTrash} color={colors.LIGHT_NAVY_BRIGHT} size={8} />}
              onClick={resetFilters}
            />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box fill>
      <NavBarContainer border={false}>
        <NavBar fill='horizontal' pad={{ vertical: 'small', horizontal: 'medium' }} border={false}>
          <Text size='large' weight={600}>
            Contacts
          </Text>
        </NavBar>
      </NavBarContainer>
      <Box flex pad={{ left: 'medium', vertical: 'small' }}>
        <Box direction='row' justify='between' align='center' flex={false} pad={{ bottom: 'small' }}>
          <DropDownMenu
            options={menuOptions}
            dropContentProps={{ boxProps: { width: { min: '260px' } } }}
            dropAlign={{ top: 'bottom', left: 'left' }}
            onOptionClick={handleDropDownClick}
            disabled={false}
          >
            <Box
              gap='xsmall'
              hoverTextColor={colors.WHITE}
              secondary
              direction='row'
              align='center'
              border={{ side: 'all', color: colors.LIGHT_NAVY_BRIGHT, size: '2px' }}
              pad='xsmall'
              justify='between'
            >
              <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faPlus} size={12} />
              <Text size='medium' color={colors.LIGHT_NAVY_BRIGHT} style={{ fontWeight: 400 }}>
                Add contact
              </Text>
              <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faAngleDown} size={18} />
            </Box>
          </DropDownMenu>
          {csvShow && (
            <Box
              onClick={() => {
                setCSVShow(false)
              }}
            >
              <FontAwesomeIcon color={colors.LIGHT_NAVY_BRIGHT} icon={faWindowClose} size={22} />
            </Box>
          )}
        </Box>
        {csvShow && (
          <Importer
            chunkSize={10000} // optional, internal parsing chunk size in bytes
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            restartable={false} // optional, lets user choose to upload another file when import is complete
            onStart={({ file, fields }) => {
              // optional, invoked when user has mapped columns and started import
            }}
            processChunk={processRows}
            onComplete={async ({ file, fields }) => {
              await applyImport()
            }}
            onClose={() => {
              setCSVShow(false)
            }}

            // CSV options passed directly to PapaParse if specified:
            // delimiter={...}
            // newline={...}
            // quoteChar={...}
            // escapeChar={...}
            // comments={...}
            // skipEmptyLines={...}
            // delimitersToGuess={...}
          >
            <ImporterField name='contactName' label='Contact Name' optional />
            <ImporterField name='companyName' label='Company Name' optional />
            <ImporterField name='email' label='Emails' optional />
            <ImporterField name='phone' label='Phones' optional />
            <ImporterField name='tag' label='Tags' optional />
          </Importer>
        )}

        {renderFilters()}
        <Box fill direction='row'>
          {/* <Box flex overflow='auto' margin={{ top: 'small', right: 'medium' }}>
            {renderContacts()}
          </Box> */}

          <ContactsTable selectedRows={[]} setSelectedRows={() => null} currentFilters={currentFilters} />
          <SlidingLeftPanel ref={panelRef} disableOutsideClick>
            <ContactPanelContent
              contactIds={contactIds}
              onClose={() => {
                closeSidePanel()
              }}
              onUpdate={updateContacts}
              onDelete={deleteContacts}
              contacts={contacts}
              updateSidePanelCompanyId={companyId => {
                setSidePanelCompanyId(companyId)
              }}
              sidePanelCompanyId={sidePanelCompanyId}
              editTags={editTags}
              setEditTags={setEditTags}
            />
          </SlidingLeftPanel>
        </Box>
        <AddContactModal ref={contactModalRef} contacts={contacts} />
      </Box>
    </Box>
  )
}

export default Contacts
