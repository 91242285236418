import React, { useCallback, useEffect, useState } from 'react'

import { FormClose } from 'grommet-icons'
import { Box, Button, Keyboard, Text, TextInput } from 'grommet'
import colors from 'shared/constants/colors'
import _ from 'lodash'

const SUGGESTIONS_LIMIT = 12

const Tag = ({ children, onRemove, ...rest }) => {
  const tag = (
    <Box
      direction='row'
      align='center'
      background={colors.AQUA_MARINE}
      pad={{ horizontal: 'small', vertical: 'xxsmall' }}
      margin={{ vertical: 'xxsmall' }}
      round='medium'
      {...rest}
    >
      <Text size='medium' color={colors.WHITE} margin={{ right: 'xxsmall' }}>
        {children}
      </Text>
      {onRemove && <FormClose size='small' color='white' />}
    </Box>
  )

  if (onRemove) {
    return <Button onClick={onRemove}>{tag}</Button>
  }
  return tag
}

const TagInput = ({ value = [], onAdd, onChange, onRemove, ...rest }) => {
  const [currentTag, setCurrentTag] = useState('')
  const [box, setBox] = useState()
  const boxRef = useCallback(setBox, [])

  const updateCurrentTag = event => {
    setCurrentTag(event.target.value)
    if (onChange) {
      onChange(event)
    }
  }

  const onAddTag = tag => {
    if (onAdd) {
      onAdd(tag)
    }
  }

  const onEnter = () => {
    if (currentTag.length) {
      onAddTag(currentTag)
      setCurrentTag('')
    }
  }

  const renderValue = () =>
    value.map((v, index) => (
      <Tag margin='xxsmall' key={`${v}${index + 0}`} onRemove={() => onRemove(v)}>
        {v}
      </Tag>
    ))

  return (
    <Keyboard onEnter={onEnter}>
      <Box
        direction='row'
        align='center'
        pad={{ horizontal: 'xsmall', vertical: 'xsmall' }}
        border='all'
        ref={boxRef}
        wrap
        round='xsmall'
      >
        {value.length > 0 && renderValue()}
        <Box flex style={{ minWidth: '120px' }}>
          <TextInput
            type='search'
            plain
            dropTarget={box}
            {...rest}
            onChange={updateCurrentTag}
            value={currentTag}
            onSuggestionSelect={event => onAddTag(event.suggestion)}
          />
        </Box>
      </Box>
    </Keyboard>
  )
}

export const WithTags = ({ updateTags, tags, allSuggestions }) => {
  useEffect(() => {
    setSelectedTags(tags)
  }, [tags])
  const [selectedTags, setSelectedTags] = useState(tags)
  const [suggestions, setSuggestions] = useState(_.take(allSuggestions, SUGGESTIONS_LIMIT))

  const onRemoveTag = tag => {
    const removeIndex = selectedTags.indexOf(tag)
    const newTags = [...selectedTags]
    if (removeIndex >= 0) {
      newTags.splice(removeIndex, 1)
    }
    setSelectedTags(newTags)
    updateTags(newTags)
  }

  const onAddTag = tag => {
    if (!_.includes(selectedTags, tag)) {
      setSelectedTags([...selectedTags, tag])
      updateTags([...selectedTags, tag])
    }
  }

  const onFilterSuggestion = value =>
    setSuggestions(
      _.take(
        _.filter(allSuggestions, suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0),
        SUGGESTIONS_LIMIT
      )
    )

  return (
    <Box flex={{ shrink: 0 }}>
      <TagInput
        placeholder='Add tags...'
        suggestions={suggestions}
        value={selectedTags}
        onRemove={onRemoveTag}
        onAdd={onAddTag}
        onChange={({ target: { value } }) => onFilterSuggestion(value)}
      />
    </Box>
  )
}

export default {
  title: 'Input/TextInput/With tags'
}
