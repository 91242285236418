import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Box, ResponsiveContext } from 'grommet'
import _ from 'lodash'

import { navigateToHomePage } from 'controllers/init'
import MobilePlaceholder from 'webPages/layout/MobilePlaceholder'
import colors from 'shared/constants/colors'
import GcViewBidNavBar from 'webPages/gcViewBid/GcViewBidNavBar'
import GcViewBidPanel from 'webPages/gcViewBid/GcViewBidPanel'
import SimplePdfPreview from 'components/SimplePdfPreview'
import Loading from 'shared/pages/Loading'

const GcViewBid = ({ bids, bidId, dispatch }) => {
  const bid = _.get(bids, bidId)

  const windowSize = useContext(ResponsiveContext)

  useEffect(() => {
    if (_.isNil(bid)) {
      dispatch(navigateToHomePage())
    }
  }, [bid])

  if (windowSize === 'small') {
    return <MobilePlaceholder />
  } else if (_.isNil(bid)) {
    return <Loading />
  } else {
    return (
      <Box flex={false} background={colors.WHITE} height='100%'>
        <GcViewBidNavBar
          // onNextClick={onNextClick}
          // nextDisabled={!canSendBid() || !isSendToSetup()}
          // workOrder={workOrder}
          // previewMode
          bid={bid}
        />
        <Box direction='row' flex>
          <SimplePdfPreview id={bid.id} name={'Proposal'} url={bid.pdfUrl} />
          <GcViewBidPanel />
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  bids: state.bids
})

export default connect(mapStateToProps)(GcViewBid)
