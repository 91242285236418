import { combineReducers } from 'redux'
import references from 'model/reducers/references'
import user from 'model/reducers/user'
import account from 'model/reducers/account'
import viewport from 'model/reducers/viewport'
import projects from 'model/reducers/projects'
import workOrders from 'model/reducers/workOrders'
import messages from 'model/reducers/messages'
import profiles from 'model/reducers/profiles'
import accountsProfiles from 'model/reducers/accountsProfiles'
import paymentRequests from 'model/reducers/paymentRequests'
import reimbursementRequests from 'model/reducers/reimbursementRequests'
import proposals from 'model/reducers/proposals'
import supplyOrders from 'model/reducers/supplyOrders'
import suppliers from 'model/reducers/suppliers'
import tempData from 'model/reducers/tempData'
import channels from 'model/reducers/channels'
import channelWeb from 'model/reducers/channelWeb'
import bids from 'model/reducers/bids'
import appointments from 'model/reducers/appointments'
import appointmentsOffers from 'model/reducers/appointmentsOffers'
import outgoingInvitations from 'model/reducers/outgoingInvitations'
import incomingInvitations from 'model/reducers/incomingInvitations'
import mode from 'model/reducers/mode'
import gcAppointments from 'model/reducers/gcAppointments'
import privateWorkOrders from 'model/reducers/privateWorkOrders'
import authData from 'model/reducers/authData'
import messagesWeb from 'model/reducers/messagesWeb'
import inbox from 'model/reducers/inbox'
import contacts from 'model/reducers/contacts'
import settings from 'model/reducers/settings'
import customizeSettings from 'model/reducers/customizeSettings'
import takeoffsQuotes from 'model/reducers/takeoffsQuotes'
import masonAdmins from 'model/reducers/masonAdmins'
import importedLi from 'model/reducers/importedLi'

export default combineReducers({
  projects,
  references,
  user,
  account,
  viewport,
  workOrders,
  messages,
  profiles,
  paymentRequests,
  reimbursementRequests,
  proposals,
  supplyOrders,
  suppliers,
  tempData,
  channels,
  accountsProfiles,
  bids,
  appointments,
  appointmentsOffers,
  outgoingInvitations,
  incomingInvitations,
  mode,
  gcAppointments,
  privateWorkOrders,
  authData,
  channelWeb,
  messagesWeb,
  inbox,
  contacts,
  settings,
  customizeSettings,
  takeoffsQuotes,
  masonAdmins,
  importedLi
})
