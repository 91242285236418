import UrlPattern from 'url-pattern'

import routes from 'constants/routes'

const patterns = {}
Object.keys(routes).forEach(key => {
  patterns[key] = new UrlPattern(routes[key])
})

export default patterns
