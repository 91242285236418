import React from 'react'
import { connect } from 'react-redux'
import { View, Image } from 'react-native'
import styled from 'sc'

import Page from 'shared/components/Page'
import { StyledText } from 'shared/components/StyledComponents'
import colors, { WHITE, AQUA_MARINE } from 'shared/constants/colors'
import { getWidth, getHeight, fontSize } from 'shared/utils/dynamicSize'
import PrimaryButton from 'shared/components/buttons/PrimaryButton'
// images
import logoImage from 'assets/images/logo.png'
import notFoundImage from 'assets/images/not-found.png'

const Navbar = styled(View)`
  padding-top: ${props => getHeight(45, props.viewport)}px;
  padding-bottom: ${props => getHeight(53, props.viewport)}px;
`
const MasonLogo = styled(Image)`
  margin-left: ${props => getWidth(98, props.viewport)}px;
  width: ${props => getWidth(126, props.viewport)}px;
  height: ${props => getHeight(38, props.viewport)}px;
`
const ContentContainer = styled(View)`
  flex: 1;
  flex-direction: row;
`
const NotFoundImageContainer = styled(View)`
  justify-content: center;
  flex: 1;
  max-width: ${props => getWidth(682, props.viewport)}px;
`
const NotFoundImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const Content = styled(View)`
  flex: 1;
  align-items: center;
`
const TextContainer = styled(View)`
  margin-top: ${props => getHeight(90, props.viewport)}px;
`

const NotFound = ({ viewport }) => (
  <Page backgroundColor={WHITE}>
    <Navbar viewport={viewport}>
      <MasonLogo source={logoImage} resizeMode='contain' viewport={viewport} />
    </Navbar>
    <ContentContainer>
      <NotFoundImageContainer viewport={viewport}>
        <NotFoundImage source={notFoundImage} resizeMode='contain' />
      </NotFoundImageContainer>
      <Content>
        <TextContainer viewport={viewport}>
          <StyledText color={colors.LIGHT_NAVY} fontSize={230} bold>
            404
          </StyledText>
          <StyledText color={colors.BLACK20} fontSize={23}>
            Sorry, Broken Link Here
          </StyledText>
          <PrimaryButton
            title='Home page'
            onPress={() => {
              window.location.href = '/'
            }}
            color={AQUA_MARINE}
            customStyle={`
              border-radius: ${fontSize(37, viewport)}px;
              width: ${getWidth(345, viewport)}px;
              height: ${getHeight(74, viewport)}px;
              margin-top: ${getHeight(40, viewport)}px;
            `}
            textCustomStyle={`
              font-size: ${fontSize(23, viewport)}px;
              font-weight: bold;
            `}
          />
        </TextContainer>
      </Content>
    </ContentContainer>
  </Page>
)

const mapStateToProps = state => ({
  viewport: state.viewport
})

export default connect(mapStateToProps)(NotFound)
