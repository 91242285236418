import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Box, Button, Text } from 'grommet'

import colors from 'shared/constants/colors'
import Logo from 'components/logos/LogoOnLight'

const InvalidUrl = ({ onNext }) => {
  const renderButtons = () => {
    return (
      <Box
        fill
        align='center'
        direction='row'
        gap='medium'
        margin={{ top: 'large', bottom: 'large' }}
        height={{ min: '48px' }}
      >
        <Box align='center' height={{ min: '48px' }} fill>
          <Button fill primary color={colors.AQUA_MARINE} onClick={onNext} label='Continue' />
        </Box>
      </Box>
    )
  }

  // const address = `${_.get(invInfo, 'address.structured.main')}, ${_.get(invInfo, 'address.structured.secondary')}`
  return (
    <Box pad='medium' align='center'>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box direction='row' align='center' pad={{ top: '200px', bottom: '20px' }} gap='xsmall'>
        <Text color={colors.CORAL_TWO} weight={700} size='xxlarge' textAlign='center'>
          {`The url is invalid or expired`}
        </Text>
      </Box>

      <Box
        align='center'
        margin={{ top: 'medium' }}
        pad={{ top: 'medium' }}
        fill={'horizontal'}
        width={{ max: '520px' }}
        flex={{ grow: 1, shrink: 0 }}
      >
        {renderButtons()}
      </Box>
    </Box>
  )
}

InvalidUrl.propTypes = {
  invInfo: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
}

export default InvalidUrl
