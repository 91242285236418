import * as types from 'model/actionTypes'

const initialState = {}

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.RECEIVE_OUTGOING_INVITATIONS:
      return action.invitations
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
