import React from 'react'
import { Box, Text } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import Subheader from 'webPages/comments/Subheader'

const GCHeader = ({ name, onClose }) => {
  return (
    <Box height='150px'>
      <Box pad='small' border='bottom' direction='row' align='center' justify='between'>
        <Box justify='center' fill>
          <Box direction='row' align='center'>
            <Text size='large' margin={{ right: 'small' }} color={colors.TEXT_PRIMARY} weight={700}>
              {name}
            </Text>
          </Box>
        </Box>
        <Box onClick={onClose}>
          <FontAwesomeIcon icon={faArrowToRight} size={24} color={colors.ANOTHER_GREY} />
        </Box>
      </Box>
      <Subheader name={name} />
    </Box>
  )
}

export default GCHeader
