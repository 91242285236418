export const emailMask = [
  {
    regexp: /^[\w\-_+.]+$/,
    placeholder: 'example'
  },
  { fixed: '@' },
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'my'
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com'
  }
]

export const phoneMask = [
  { fixed: '(' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: '500'
  },
  { fixed: ')' },
  { fixed: ' ' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: '555'
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /^[0-9]{1,4}$/,
    placeholder: '0000'
  }
]
