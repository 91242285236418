import React from 'react'
import PropTypes from 'prop-types'
import { Circle } from 'rc-progress'
import { Layer, Box, Button, ThemeContext, Stack, Text } from 'grommet'

import colors from 'shared/constants/colors'

const ProgressBar = ({ progress, onCancel }) => {
  if (_.isNil(progress)) {
    return null
  } else {
    return (
      <ThemeContext.Extend value={{ layer: { background: colors.BLACK70 } }}>
        <Layer onEsc={onCancel} full>
          <Box fill align='center' justify='center'>
            <Box
              width='small'
              background='transparent'
              align='center'
              // gap='small'
              margin={{ bottom: 'medium' }}
            >
              <Stack anchor='center' fill>
                <Circle
                  percent={progress}
                  strokeColor={colors.LIGHT_NAVY}
                  strokeWidth={4}
                  trailColor={colors.PALE_GREY}
                />
                <Text color={colors.WHITE}>{`${progress}%`}</Text>
              </Stack>
              <Button
                margin={{ top: 'small', bottom: 'large' }}
                background='transparent'
                label='cancel'
                onClick={onCancel}
                color={colors.WHITE}
              />
            </Box>
          </Box>
        </Layer>
      </ThemeContext.Extend>
    )
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  onCancel: PropTypes.func
}

export default ProgressBar
