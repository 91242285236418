import React, { useCallback, useContext } from 'react'
import { Box, Text, Button, ResponsiveContext } from 'grommet'
import _ from 'lodash'

import Logo from 'components/logos/LogoOnLight'
import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import history from 'shared/utils/history'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'
import Teammates from 'pages/settings/SettingsTeammates'
import PreconTeammatesMobile from 'webPages/precon/mobile/PreconTeammatesMobile'
import PreconContext from 'webPages/precon/PreconContext'

const PreconInviteTeammates = () => {
  const size = useContext(ResponsiveContext)
  const { goToNextScreen } = useContext(PreconContext)

  const goBack = () => {
    universalNavigation.goBack()
  }

  const goNext = useCallback(() => {
    console.log('goNext')
    goToNextScreen(screens.PRECON_TEAMMATES)
  }, [goToNextScreen])

  const desktopVersion = (
    <Box pad='medium' align='start'>
      <Box
        direction='row'
        flex={false}
        fill='horizontal'
        justify='between'
        align='center'
        margin={{ bottom: 'medium' }}
      >
        <Logo height='32px' />
      </Box>
      <Box flex={false} alignSelf='center'>
        <Box align='start' pad={{ bottom: 'medium' }}>
          <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
            Invite your teammates
          </Text>
        </Box>
        <Box>
          <Teammates />
        </Box>
        <Box pad={{ top: 'large', horizontal: 'xlarge' }}>
          <Button size='large' primary label='Next' color={colors.AQUA_MARINE} onClick={goNext} />
        </Box>
        {_.get(history, 'length', 0) > 0 && (
          <Box pad={{ top: 'medium', horizontal: 'xlarge' }} direction='row' justify='center'>
            <Button
              size='large'
              icon={<FontAwesomeIcon icon={faArrowLeft} size={14} />}
              label='Back'
              color={colors.LINK}
              onClick={goBack}
            />
          </Box>
        )}
      </Box>
    </Box>
  )

  if (size === 'small') {
    return (
      <Box align='start'>
        <Box
          pad='medium'
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='28px' />
        </Box>
        <Box flex={false} pad={{ horizontal: 'medium', vertical: 'medium' }} alignSelf='center' width='large'>
          <Box pad={{ bottom: 'medium' }} direction='row' align='center' gap='small'>
            <Button plain icon={<FontAwesomeIcon icon={faArrowLeft} onClick={goBack} />} />
            <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start' weight={600}>
              Invite your teammates
            </Text>
          </Box>
        </Box>
        <Box pad='small'>
          <PreconTeammatesMobile goNext={goNext} />
        </Box>
      </Box>
    )
  } else {
    return desktopVersion
  }
}

export default PreconInviteTeammates
