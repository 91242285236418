import React, { useState, useRef, useEffect } from 'react'
import { TextInput, Keyboard, ThemeContext } from 'grommet'
import _ from 'lodash'
import { css } from 'styled-components'

import colors from 'shared/constants/colors'

const InputPhaseName = ({ phaseId, name, onChangePhase, onFocus, onBlur, highlightIncomlete }) => {
  const [value, setValue] = useState(name)
  const [focused, setFocused] = useState(false)
  const inputRef = useRef()

  const isIncomplete = highlightIncomlete && _.isEmpty(_.toString(name))

  useEffect(() => {
    if (_.isEmpty(name)) {
      inputRef.current.focus()
    } else {
      inputRef.current.blur()
    }
  }, [name])

  const onChange = e => {
    setValue(e.target.value)
  }

  const _onBlur = () => {
    console.log('Input phase, onBlur called')
    setFocused(false)
    onChangePhase(phaseId, 'name', value)
    onBlur()
  }

  const _onFocus = () => {
    console.log('InputPhaseName onFocus')
    setFocused(true)
    onFocus()
  }

  const onEnter = () => inputRef.current.blur()

  return (
    <Keyboard onEnter={onEnter}>
      <ThemeContext.Extend
        value={{
          global: {
            control: {
              border: { color: focused ? colors.AQUA_MARINE : 'transparent', radius: '0px' }
            },
            input: {
              extend: css`
                background-color: ${focused ? colors.WHITE : 'transparent'};
              `
            },
            colors: {
              placeholder: isIncomplete ? colors.CORAL_TWO : colors.VERY_LIGHT_PINK
            }
          }
        }}
      >
        <TextInput
          // plain={!focused}
          ref={inputRef}
          value={value}
          onChange={onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          placeholder={isIncomplete ? 'Enter phase name' : 'Phase name'}
          maxLength={50}
        />
      </ThemeContext.Extend>
    </Keyboard>
  )
}

export default InputPhaseName
