import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { ResponsiveContext } from 'grommet'
import _ from 'lodash'

import ResolveInviteDesktop from 'webpages/invitation/ResolveInviteDesktop'
import ResolveInviteMobile from 'webpages/invitation/ResolveInviteMobile'
import ResolveInvitePlaceholder from 'webpages/invitation/ResolveInvitePlaceholder'
import {
  updateSeenStatus,
  acceptJobOffer,
  removeAccountFromWorkOrder,
  cancelRemindersBidInviteMessages
} from 'controllers/workOrder'
import { navigateToHomePage } from 'controllers/init'
import { getWorkOrder } from 'model/selectors/workOrdersSelector'

const ResolveInvite = ({
  userId,
  accountId,
  workOrderId,
  gcName = '-',
  projectName = {},
  address = '-',
  files,
  scope,
  sections,
  bidsDue = '-',
  invitedBy,
  id,
  mode = 'scope',
  workOrder,
  dispatch,
  projectId,
  hasInAccepted,
  hasInDeclined,
  tradeName,
  avatar
}) => {
  const size = useContext(ResponsiveContext)
  const isMobile = _.isEqual(size, 'small')
  const Component = userId ? (isMobile ? ResolveInviteMobile : ResolveInviteDesktop) : ResolveInvitePlaceholder
  useEffect(() => {
    if (userId) {
      updateSeenStatus(accountId, userId, workOrderId)
    }
  }, [userId, workOrderId, accountId])

  const handleAccept = v => {
    cancelRemindersBidInviteMessages(`${workOrderId}_${accountId}`)
    if (v) {
      dispatch(acceptJobOffer(workOrderId))
    } else {
      dispatch(removeAccountFromWorkOrder(workOrder))
      dispatch(navigateToHomePage())
    }
  }

  return (
    <Component
      userId={userId}
      gcName={gcName}
      projectName={projectName}
      address={address}
      files={files}
      scope={scope}
      sections={sections}
      bidsDue={bidsDue}
      mode={mode}
      workOrderId={workOrderId}
      invitedBy={invitedBy}
      isEmptySow={_.size(scope) === 0}
      invId={id}
      handleResolve={handleAccept}
      projectId={projectId}
      hasInAccepted={hasInAccepted}
      hasInDeclined={hasInDeclined}
      tradeName={tradeName}
      avatar={avatar}
    />
  )
}

const mapStateToProps = (state, props) => {
  const workOrder = getWorkOrder(state, props)
  const gcAccountId = _.get(workOrder, 'accountId')
  return {
    workOrder,
    avatar: _.get(state.accountsProfiles, [gcAccountId, 'avatar'], null)
  }
}

export default connect(mapStateToProps)(ResolveInvite)
