import React from 'react'
import _ from 'lodash'
import { View } from 'react-native'
import styled from 'sc'
import moment from 'moment'

import { StyledText } from 'shared/documents/components/StyledComponents'
import ccVariables from 'shared/constants/ccVariables'
import { toTimestamp } from 'shared/utils/date'
import { formatNational } from 'shared/utils/phone'
import { moneyWithSymbolAbbr } from 'shared/utils/money'
import { getAddress } from 'shared/utils/stringUtils'

const RowContainer = styled(View)`
  padding-left: ${props => props.paddingLeft}px;
  padding-top: ${props => props.paddingTop}px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const createRowProps = (r, getSize) => {
  const res = {}
  const hSizes = [12, 24, 20, 16, 14]
  if (r.bold) res.bold = true
  if (r.italic) res.italic = true
  if (r.underline) res.underline = true
  res.fontSize = getSize(12)
  if (!_.isNil(r.h)) {
    res.fontSize = getSize(_.get(hSizes, r.h, 12))
    res.bold = true
  }
  res.textAlign = r.alignment
  return res
}

const renderListLabel = (getSize, rows, i) => {
  const row = _.get(rows, i)
  const indent = _.get(row, 'indent', 0)
  const l = _.get(row, 'list')
  if (_.isNil(l)) return
  // console.log('---> renderListLabel', row)
  let label = '•'
  if (l === 'number') {
    const rowsBefore = _.take(rows, i)
    let n = 1
    _.forEachRight(rowsBefore, r => {
      // console.log('process row', r)
      const rIndent = _.get(r, 'indent', 0)
      if (rIndent !== indent || r.list !== l) {
        return false
      } else {
        n = n + 1
      }
    })
    label = `${n}.`
  }
  if (!_.isEmpty(l)) {
    return (
      <StyledText {...createRowProps(row, getSize)} customstyle={'flex: 0.03;'}>
        {label}
      </StyledText>
    )
  }
}

const renderRow = (getSize, rows, content, i, editMode, customRowRender) => {
  const row = _.get(rows, i)
  const indent = _.get(row, 'indent', 0)
  const paddingLeft = getSize(indent * 25)
  const pad = _.get(row, 'pad', 0)
  const paddingTop = getSize(pad * 16)
  const rowWithContainer = (
    <RowContainer paddingLeft={paddingLeft} paddingTop={paddingTop} key={`cc_${i}`}>
      {renderListLabel(getSize, rows, i)}
      {content}
    </RowContainer>
  )
  return editMode && customRowRender ? customRowRender(rowWithContainer, i) : rowWithContainer
}

const processRowVariables = (c, bid) => {
  let res = c
  _.forEach(ccVariables, ccv => {
    let newValue = _.get(bid, ccv.pth)
    switch (ccv.type) {
      case 'date':
        const timestamp = toTimestamp(newValue)
        const m = timestamp > 0 ? moment(timestamp) : moment()
        newValue = m.format('L')
        break
      case 'phone':
        newValue = formatNational(newValue)
        break
      case 'currency':
        newValue = moneyWithSymbolAbbr(newValue)
        break
      case 'address':
        newValue = getAddress(newValue)
        break
    }
    res = res.replaceAll(ccv.tmpl, newValue)
  })
  return res
}

export const renderCustomContract = (getSize, doc, bid, editMode, customRowRender) => {
  const rows = _.get(doc, 'rows', [])
  if (_.isEmpty(rows) && editMode) {
    return [
      renderRow(
        getSize,
        null,
        <StyledText fontSize={getSize(12)}>'Start by editing the row'</StyledText>,
        0,
        editMode,
        customRowRender
      )
    ]
  } else {
    return _.map(rows, (r, i) => {
      let rowContent = _.get(r, 'content', '')
      // console.log(i, 'bPad length', _.size(bPadWOWs), _.size(bPad))
      if (_.isEmpty(rowContent)) rowContent = 'empty'
      if (!editMode) rowContent = processRowVariables(rowContent, bid)
      return renderRow(
        getSize,
        rows,
        <StyledText {...createRowProps(r, getSize)} customstyle={'flex: 1;'}>
          {rowContent}
        </StyledText>,
        i,
        editMode,
        customRowRender
      )
    })
  }
}
