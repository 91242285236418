import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Layer, Text, CheckBox, DropButton, ThemeContext, Heading } from 'grommet'
import Tip from 'components/Tip'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faTrash, faClipboardCheck } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import Table, { Column, AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'

import lineItemType from 'shared/constants/lineItemType'
import colors from 'shared/constants/colors'
import { getWorkOrdersWithTitles, getWorkOrderIdsByLineItemId } from 'model/selectors/workOrdersSelector'
import { updateImportedLi } from 'controllers/project'
// import RemoveImportedLi from 'webPages/workOrder/RemoveImportedLi'
import ImportedLiPanelFilterMenu from 'webPages/workOrder/importedLiPanel/ImportedLiPanelFilterMenu'
import SectionsSelector from 'shared/pages/createBid/addLineItemFromTemplate/SectionsSelector'

const ALL_OPTIONS = 'ALL_OPTIONS'

const renderCellData = (cellData, rowData, workOrderIdByLineItemId) => {
  if (_.isBoolean(cellData)) {
    return cellData ? (
      <Box fill align='center' justify='center'>
        <FontAwesomeIcon icon={faCheck} size={12} color={colors.AQUA_MARINE} />
      </Box>
    ) : (
      ''
    )
  } else {
    const added = _.size(_.get(workOrderIdByLineItemId, rowData.id, [])) > 0
    return (
      <Tip message={cellData} down>
        <Text size='small' color={added ? colors.ANOTHER_GREY : colors.DARK_GRAY_TWO}>
          {cellData}
        </Text>
      </Tip>
    )
  }
}

const TableHeaderCell = ({ column }) => {
  if (_.isEqual(_.get(column, 'key'), 'included')) {
    return <Box>{column.title}</Box>
  }
  return (
    <Tip message={column.title} down>
      <Text size='small'>{column.title}</Text>
    </Tip>
  )
}

class ImportedLiPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      headers: [],
      lineItems: {},
      selectedRows: [],
      selectedFilterValue: null,
      selectedFilterColumn: null,
      filterMenuOpen: false,
      removeFromWorkOrders: null,
      selectedSection: null,
      sortBy: { key: 'name', order: 'asc' }
    }
  }

  getAllLineItems = () => {
    const { importedLi } = this.props
    return _.values(_.get(importedLi, 'lineItems'))
  }

  open = () => {
    const { importedLi } = this.props
    const lineItems = this.getAllLineItems()
    const filtersSettingsRaw = {}
    const headersDict = {}
    _.forEach(lineItems, li => {
      _.forEach(li, (v, k) => {
        _.set(headersDict, k, true)
        if (k !== 'name' && k !== 'id') {
          _.set(filtersSettingsRaw, [k, v], 1)
        }
      })
    })
    console.log('filtersSettingsRaw', filtersSettingsRaw)
    const filtersSettings = _.reduce(
      filtersSettingsRaw,
      (res, options, category) => {
        if (_.size(options) > 1) {
          res[category] = {
            label: _.capitalize(category),
            options: _.sortBy(
              _.map(options, (v, k) => ({ label: _.isString(k) && _.isEmpty(k) ? 'Empty' : k, value: k })),
              ['value']
            )
          }
        }
        return res
      },
      {}
    )
    filtersSettings.fileId = {
      label: 'By file',
      options: _.map(_.get(importedLi, 'files', []), f => ({
        label: `${f.filename} - ${moment(f.timestamp).format('lll')}`,
        value: f.id
      }))
    }
    filtersSettings.distributed = {
      label: 'Is distributed',
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ]
    }
    console.log('filtersSettings', filtersSettings)
    const headers = _.keys(_.omit(headersDict, ['name', 'id', 'fileId']))
    this.setState({ show: true, headers, lineItems, allLineItems: lineItems, filtersSettings, selectedSection: null })
  }

  showChange = () => {
    const show = !this.state.show
    this.setState({
      show,
      selectedRows: [],
      currentFilters: {},
      selectedFilterValue: null,
      selectedFilterColumn: null,
      removeFromWorkOrders: null,
      showConfirmation: false
    })
  }

  onSort = sortBy => {
    const { lineItems } = this.state
    if (_.isEqual(_.get(sortBy, 'key'), 'included')) {
      const { workOrderIdByLineItemId } = this.props
      const res = lineItems.sort(({ id: aId }, { id: bId }) => {
        const aIds = _.get(workOrderIdByLineItemId, aId, [])
        const bIds = _.get(workOrderIdByLineItemId, bId, [])
        return sortBy.order === 'asc' ? _.size(aIds) - _.size(bIds) : _.size(bIds) - _.size(aIds)
      })
      this.setState({ sortBy, lineItems: res })
    } else {
      const res = _.orderBy(lineItems, _.get(sortBy, 'key'), [sortBy.order])
      this.setState({ sortBy, lineItems: res })
    }
  }

  selectAll = () => {
    let { selectedRows, lineItems } = this.state
    if (_.size(selectedRows) < _.size(lineItems)) {
      selectedRows = _.map(lineItems, li => _.get(li, 'id'))
    } else {
      selectedRows = []
    }
    this.setState({ selectedRows })
  }

  selectRow = i => {
    let { selectedRows } = this.state
    if (_.includes(selectedRows, i)) {
      selectedRows = _.pull(selectedRows, i)
    } else {
      selectedRows.push(i)
    }
    this.setState({ selectedRows })
  }

  resetFilters = () => {
    const lineItems = this.getAllLineItems()
    this.setState({
      currentFilters: {},
      lineItems,
      selectedRows: []
    })
  }

  renderRow = ({ rowData, cells }) => {
    const { selectedRows } = this.state
    const selected = _.includes(selectedRows, _.get(rowData, 'id'))
    return (
      <Box key={_.get(rowData, 'id')} background={selected ? colors.RIPTIDE10 : colors.WHITE} fill direction='row'>
        {cells}
      </Box>
    )
  }

  renderCell = ({ rowData, cellData }) => {
    const { workOrderIdByLineItemId } = this.props
    return renderCellData(cellData, rowData, workOrderIdByLineItemId)
  }

  renderLiIncludedIcon = ({ id }) => {
    if (_.isNil(id)) return null
    const { workOrdersDict, workOrderIdByLineItemId } = this.props
    const ids = _.get(workOrderIdByLineItemId, id, [])
    if (_.isEmpty(ids)) return null
    const titles = _.map(ids, woId => _.get(workOrdersDict, [woId, 'title']))
    return (
      <Tip message={`Included in: ${_.join(titles, ', ')}`} down>
        <Box>
          <FontAwesomeIcon icon={faClipboardCheck} size={16} color={colors.VERY_LIGHT_PINK} />
        </Box>
      </Tip>
    )
  }

  recalcLineItems = () => {
    const allLineItems = this.getAllLineItems()
    const { currentFilters } = this.state
    const { workOrderIdByLineItemId } = this.props
    const lineItems = _.filter(allLineItems, li => {
      let needed = true
      _.forEach(currentFilters, ({ category, options }) => {
        const categoryValue = category.value
        if (!_.isEmpty(options)) {
          let matchesOptions = false
          _.forEach(options, ({ value }) => {
            switch (categoryValue) {
              case 'file': {
                matchesOptions = matchesOptions || li.fileId === value
                break
              }
              case 'distributed': {
                matchesOptions = matchesOptions || _.has(workOrderIdByLineItemId, li.id) === value
                break
              }
              default: {
                matchesOptions = matchesOptions || li[categoryValue] === value
              }
            }
          })
          needed = needed && matchesOptions
        }
      })
      return needed
    })
    this.setState({ lineItems })
  }

  removeFilter = category => {
    const { currentFilters } = this.state
    const newFilters = { ...currentFilters }
    _.unset(newFilters, category.value)
    this.setState({ currentFilters: newFilters, selectedRows: [] }, this.recalcLineItems)
  }

  renderFilter = ({ category, options }) => {
    console.log('renderFilter', category, options)
    if (_.isEmpty(options)) return null
    const { filterMenuOpen } = this.state
    const label = _.get(category, 'label')
    let value = _.get(_.values(options), [0, 'label'])
    const tipMessage = _.map(options, opt => (
      <Text key={opt.value}>
        {opt.label}
        <br />
      </Text>
    ))
    if (_.size(options) > 1) {
      value = `${_.get(_.values(options), [0, 'label'])} or ${_.size(options) - 1} other`
    }
    const renderText =
      label === 'by_file' ? (
        <Text color={colors.MEDIUM_GREY}>
          <Text weight={700} color={colors.MEDIUM_GREY}>
            {`File${_.size(value) > 1 ? 's' : ''}:`}
          </Text>
          {value}
        </Text>
      ) : (
        <Text color={colors.MEDIUM_GREY}>
          <Text weight={700} color={colors.MEDIUM_GREY}>
            {_.capitalize(label)}
          </Text>{' '}
          is {value}
        </Text>
      )
    return (
      <DropButton
        key={`filter_${label}`}
        open={_.isEqual(filterMenuOpen, category)}
        onClick={() => this.setState({ filterMenuOpen: category })}
        dropContent={this.renderFilterMenu()}
        dropAlign={{ top: 'top', left: 'left' }}
        onClose={() => this.setState({ filterMenuOpen: false, selectedFilterColumn: null })}
      >
        <Box
          align='center'
          direction='row'
          gap='small'
          onClick={() => null}
          pad='xsmall'
          round='xsmall'
        >
          <Box
            background={colors.VERY_LIGHT_PINK_TWO}
            pad='xsmall'
            align='center'
            round='xsmall'
            direction='row'
            gap='small'
            flex={{ shrink: 0 }}
          >
            <Tip message={tipMessage} down>
              {renderText}
            </Tip>
            <Box
              onClick={e => {
                e.stopPropagation()
                this.removeFilter(category)
              }}
              align='center'
            >
              <FontAwesomeIcon icon={faTimes} size={12} color={colors.MEDIUM_GREY} />
            </Box>
          </Box>
        </Box>
      </DropButton>
    )
  }

  remove = () => {
    const { selectedRows, lineItems } = this.state
    const { importedLi, workOrdersDict, projectId } = this.props
    const newImportedLi = { ...importedLi }
    let removeFromWorkOrders = {}
    _.forEach(selectedRows, liId => {
      liId = _.toString(liId)
      _.forEach(workOrdersDict, ({ id: woId, scope }) => {
        if (_.has(scope, liId)) {
          removeFromWorkOrders[liId] = removeFromWorkOrders[liId] || []
          removeFromWorkOrders[liId].push(woId)
        }
      })
      const li = _.find(lineItems, li => _.isEqual(_.toString(_.get(li, 'id')), liId))
      const fileId = _.get(li, 'fileId')
      const liIndex = _.findIndex(_.get(newImportedLi, [fileId, 'lineItems']), item =>
        _.isEqual(_.toString(_.get(item, 'id')), liId)
      )
      _.pullAt(newImportedLi[fileId].lineItems, [liIndex])
    })
    updateImportedLi(newImportedLi, projectId)
    if (_.size(removeFromWorkOrders) > 0) {
      this.setState({ removeFromWorkOrders })
    } else {
      this.showChange()
    }
  }

  submit = () => {
    const { selectedRows } = this.state
    const { workOrderIdByLineItemId } = this.props
    const showConfirmation = _.some(selectedRows, id => _.size(_.get(workOrderIdByLineItemId, id)) > 0)
    if (showConfirmation) {
      this.setState({ showConfirmation })
    } else {
      this.accept()
    }
  }

  accept = () => {
    const { selectedRows, selectedSection } = this.state
    const { addNewLineItems, importedLi } = this.props
    const lineItemsDict = _.get(importedLi, 'lineItems')
    const selectedLineItems = _.reduce(
      selectedRows,
      (res, liId) => {
        const li = _.get(lineItemsDict, liId)
        res[liId] = { ...li, type: _.get(li, 'type', lineItemType.DEFAULT) }
        return res
      },
      {}
    )
    addNewLineItems(selectedLineItems, selectedSection)
    this.showChange()
  }

  closeConfirmation = () => {
    this.setState({ showConfirmation: false })
  }

  handleRemoveResolve = () => {
    this.showChange()
  }

  closeFilterMenu = () => {
    this.setState({ filterMenuOpen: false })
  }

  applyFilter = (category, options) => {
    console.log('applyFilter', category, options)
    const { currentFilters, filterMenuOpen } = this.state
    const newFilters = {
      ...currentFilters,
      [category.value]: {
        category,
        options
      }
    }
    if (!_.isEqual(_.get(filterMenuOpen, 'value'), _.get(category, 'value')) && !_.isEqual(filterMenuOpen, ALL_OPTIONS)) {
      delete newFilters[filterMenuOpen.value]
    }
    this.setState({ currentFilters: newFilters, filterMenuOpen: null, selectedRows: [] }, this.recalcLineItems)
  }

  renderFilterMenu = () => {
    const { filtersSettings, currentFilters, filterMenuOpen } = this.state
    return (
      <ImportedLiPanelFilterMenu
        filtersSettings={filtersSettings}
        close={this.closeFilterMenu}
        onApply={this.applyFilter}
        filters={currentFilters}
        filterMenuOpen={filterMenuOpen}
      />
    )
  }

  renderFilters = () => {
    let { selectedRows, lineItems, currentFilters, filterMenuOpen } = this.state
    return (
      <Box>
        <Box direction='row' gap='small' pad='small' fill='horizontal'>
          <Box direction='row' gap='small' width={{ max: '75%' }} overflow={{ horizontal: 'auto' }}>
            {_.map(currentFilters, this.renderFilter)}
          </Box>
          <Box align='center' justify='center'>
            <DropButton
              open={_.isEqual(filterMenuOpen, ALL_OPTIONS)}
              onClick={() => this.setState({ filterMenuOpen: ALL_OPTIONS })}
              dropContent={this.renderFilterMenu()}
              dropAlign={{ top: 'top', left: 'left' }}
              onClose={() => this.setState({ filterMenuOpen: false, selectedFilterColumn: null })}
            >
              <Box
                align='center'
                direction='row'
                gap='small'
                hoverIndicator
                onClick={() => null}
                pad='xsmall'
                round='xsmall'
              >
                <FontAwesomeIcon icon={faPlus} size={14} color={colors.LIGHT_NAVY_BRIGHT} />
                <Text color={colors.LIGHT_NAVY_BRIGHT} size='small'>
                  Add filter
                </Text>
              </Box>
            </DropButton>
          </Box>
          <Box direction='row' margin={{ left: 'auto', right: 'small' }} align='center' gap='medium'>
            <Text size='small' color={colors.MEDIUM_GREY}>{`${_.size(lineItems)} row${
              _.size(lineItems) > 1 ? 's' : ''
            } found`}</Text>
            {_.size(currentFilters) > 0 && (
              <Button
                size='small'
                color={colors.LIGHT_NAVY_BRIGHT}
                label={<Text color={colors.LIGHT_NAVY_BRIGHT}>Clear filters</Text>}
                icon={<FontAwesomeIcon icon={faTrash} color={colors.LIGHT_NAVY_BRIGHT} size={8} />}
                onClick={this.resetFilters}
              />
            )}
          </Box>
        </Box>
        <Box pad={{ horizontal: 'medium', bottom: 'small' }}>
          <Text size='small' color={colors.LIGHT_NAVY_BRIGHT}>
            {`${_.size(selectedRows)} row${_.size(selectedRows) > 1 ? 's' : ''} selected`}
          </Text>
        </Box>
      </Box>
    )
  }

  setSelectedSection = v => this.setState({ selectedSection: v })

  renderSectionsBlock = () => {
    const { sections } = this.props
    const { selectedSection } = this.state
    return (
      <SectionsSelector
        sections={sections}
        setSelectedSection={this.setSelectedSection}
        selectedSection={selectedSection}
        containerProps={{
          pad: 'none'
        }}
      />
    )
  }

  render () {
    let { show, selectedRows, headers, lineItems, showConfirmation, sortBy } = this.state
    if (!show) return null
    return (
      <ThemeContext.Extend
        value={{
          checkBox: {
            hover: {
              border: {
                color: colors.AQUA_MARINE
              }
            },
            size: '18px',
            color: colors.AQUA_MARINE
          }
        }}
      >
        <Layer full margin='large' onEsc={this.showChange} onClickOutside={this.showChange}>
          <Box fill justify='between'>
            <Box pad='small' align='center' justify='between' direction='row' border='bottom'>
              <Text color={colors.BLACK} size='large'>
                Add line items to bid package
              </Text>
              <Button
                icon={<FontAwesomeIcon icon={faTimes} size={24} color={colors.BLACK} />}
                plain
                onClick={this.showChange}
              />
            </Box>
            {this.renderFilters()}
            <Box flex={{ grow: 1 }} overflow='auto' width='100%' margin={{ bottom: 'small' }}>
              <AutoResizer key='autoresizer'>
                {({ width, height }) => (
                  <Table
                    key='imported_li_table'
                    data={lineItems}
                    width={width}
                    height={height}
                    components={{ TableCell: this.renderCell, TableHeaderCell }}
                    ignoreFunctionInColumnCompare={false}
                    rowRenderer={this.renderRow}
                    onColumnSort={this.onSort}
                    sortBy={sortBy}
                    fixed
                  >
                    <Column
                      key='select'
                      dataKey='select'
                      headerRenderer={() => (
                        <CheckBox
                          checked={_.isEqual(_.size(selectedRows), _.size(lineItems))}
                          onChange={this.selectAll}
                          indeterminate={
                            _.size(selectedRows) > 0 && !_.isEqual(_.size(selectedRows), _.size(lineItems))
                          }
                        />
                      )}
                      cellRenderer={({ rowData }) => (
                        <CheckBox
                          checked={_.includes(selectedRows, `${_.get(rowData, 'id')}`)}
                          onChange={() => this.selectRow(`${_.get(rowData, 'id')}`)}
                        />
                      )}
                      title='Select Line Items'
                      width={50}
                      frozen={Column.FrozenDirection.LEFT}
                    />
                    <Column
                      key='included'
                      dataKey='included'
                      headerRenderer={() => <FontAwesomeIcon icon={faClipboardCheck} size={16} color={colors.VERY_LIGHT_PINK} />}
                      width={50}
                      cellRenderer={({ rowData }) => this.renderLiIncludedIcon(rowData)}
                      sortable
                      frozen={Column.FrozenDirection.LEFT}
                    />
                    <Column sortable key='name' dataKey='name' title='Name' width={100} resizable frozen={Column.FrozenDirection.LEFT} />
                    {_.map(headers, h => (
                      <Column sortable key={h} dataKey={h} title={_.capitalize(h)} width={100} resizable />
                    ))}
                  </Table>
                )}
              </AutoResizer>
            </Box>
            <Box
              flex={{ grow: 0 }}
              direction='row'
              fill='horizontal'
              pad={{ horizontal: 'medium', bottom: 'small' }}
              gap='small'
              justify='end'
            >
              {/* <Box width='small'>
                <Button
                  disabled={_.size(selectedRows) === 0}
                  fill
                  primary
                  color={colors.CORAL_TWO}
                  label={
                    _.size(selectedRows) === 0
                      ? 'Remove'
                      : `Remove ${_.size(selectedRows)} line item${_.size(selectedRows) > 1 ? 's' : ''}`
                  }
                  onClick={this.remove}
                />
              </Box> */}
              <Box>{this.renderSectionsBlock()}</Box>
              <Box width='small'>
                <Button
                  disabled={_.size(selectedRows) === 0}
                  fill
                  primary
                  color={colors.AQUA_MARINE}
                  label={
                    _.size(selectedRows) === 0
                      ? 'Add'
                      : `Add ${_.size(selectedRows)} line item${_.size(selectedRows) > 1 ? 's' : ''}`
                  }
                  onClick={this.submit}
                />
              </Box>
            </Box>
          </Box>
          {/* {_.size(removeFromWorkOrders) > 0 && (
            <RemoveImportedLi lineItems={removeFromWorkOrders} handleResolve={this.handleRemoveResolve} />
          )} */}
          {showConfirmation && (
            <Layer margin='large' onEsc={this.closeConfirmation} onClickOutside={this.closeConfirmation}>
              <Box>
                <Box pad='small' align='center' justify='between' direction='row' border='bottom'>
                  <Text color={colors.BLACK} size='large'>
                    Add line items
                  </Text>
                  <Button
                    icon={<FontAwesomeIcon icon={faTimes} size={24} color={colors.BLACK} />}
                    plain
                    onClick={this.closeConfirmation}
                  />
                </Box>
                <Box
                  pad='large'
                  margin={{ horizontal: 'xlarge' }}
                  flex={{ shrink: 0 }}
                  align='center'
                  gap='medium'
                  customStyle={'h2 { font-weight: 400; }'}
                >
                  <Heading margin='none' color={colors.LIGHT_NAVY_BRIGHT} level='2'>Warning</Heading>
                  <Text color={colors.LIGHT_NAVY_BRIGHT} size='medium' customStyle='white-space: pre'>
                    You are adding already distributed line items{'\n'}
                    This may be a cause of line items duplications
                  </Text>
                  <Box
                    flex
                    direction='row'
                    gap='small'
                    fill
                    align='center'
                    justify='between'
                    margin={{ top: 'small' }}
                    customStyle={'button { flex: 1 0 auto }'}
                  >
                    <Button label='Cancel' secondary color={colors.CORAL_TWO} onClick={() => this.setState({ showConfirmation: false })} />
                    <Button label='Add' primary color={colors.AQUA_MARINE} onClick={this.accept} />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </Layer>
      </ThemeContext.Extend>
    )
  }
}

const mapStateToProps = (state, props) => {
  const woId = _.get(props, 'workOrderId')
  const workOrderIdByLineItemId = _.reduce(
    getWorkOrderIdsByLineItemId(state, props),
    (res, woIds, liId) => {
      if (_.includes(woIds, woId)) {
        woIds = _.filter(woIds, id => !_.isEqual(id, woId))
      }
      res[liId] = woIds
      return res
    },
    {}
  )
  const sow = _.keys(_.get(props, 'sow'))
  const workOrderLineItems = _.reduce(
    sow,
    (res, liId) => {
      let woIds = _.get(workOrderIdByLineItemId, liId, [])
      if (!_.isEmpty(woIds)) {
        woIds.push(woId)
      } else {
        woIds = [woId]
      }
      res[liId] = woIds
      return res
    },
    {}
  )
  return {
    importedLi: state.importedLi,
    workOrdersDict: _.keyBy(getWorkOrdersWithTitles(state, props), 'id'),
    workOrderIdByLineItemId: { ...workOrderIdByLineItemId, ...workOrderLineItems }
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ImportedLiPanel)
