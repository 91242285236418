import React from 'react'
import { View } from 'react-native'
import styled from 'sc'
import _ from 'lodash'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import { SpaceBetweenFlexDiv, StyledText } from 'shared/documents/components/StyledComponents'
import LineItem from 'shared/documents/components/proposal/LineItem'
import TableHeader from 'shared/documents/components/proposal/TableHeader'
import lineItemType from 'shared/constants/lineItemType'
import lineItemSection from 'shared/constants/lineItemSection'

const TitleContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-height: ${props => props.getSize(40)}px;
`

const GeItemContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-height: ${props => props.getSize(24)}px;
`

const SubtotalContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;
`

const renderItemRow = (getSize, item, key, showBidsTotals = true) => {
  const itemKey = key || _.get(item, 'id')
  if (itemKey === '-MMyqymkwFOcftycu3yx') console.log('%citem', 'color: orange;', item, 'key', key)
  const res = [
    <LineItem
      key={`table_row_${itemKey}`}
      getSize={getSize}
      item={item}
      itemId={_.get(item, 'id', key)}
      isCalculated
      showBidsTotals={showBidsTotals}
    />
  ]
  _.forEach(item.alternates, (altItem, altId) => {
    res.push(
      <LineItem
        key={`table_row_${key}_${_.get(altItem, 'id')}`}
        getSize={getSize}
        item={altItem}
        itemId={altId}
        isCalculated
        isAlternate
        showBidsTotals={showBidsTotals}
      />
    )
  })
  return res
}

export const renderHeader = (getSize, key = 'tableHeader', isCalculated = true) => {
  return <TableHeader key={key} getSize={getSize} isCalculated={isCalculated} />
}

const renderSectionTitle = ({ getSize, title, key, color, subtotal }) => {
  return (
    <TitleContainer key={key} getSize={getSize}>
      <SpaceBetweenFlexDiv customStyle='align-items: center;'>
        <StyledText bold fontSize={getSize(18)} color={color || colors.TEXT_PRIMARY}>
          {title}
        </StyledText>
        {!_.isNil(subtotal) && (
          <SubtotalContainer>
            <StyledText
              fontSize={getSize(8)}
              color={colors.ASANA_GRAY_TEXT_HOVERED}
              customstyle={`margin-right: ${getSize(10)}px;`}
            >
              SUBTOTAL
            </StyledText>
            <StyledText fontSize={getSize(12)} color={colors.TEXT_PRIMARY}>
              {numeral(subtotal).format('$0,0.00')}
            </StyledText>
          </SubtotalContainer>
        )}
      </SpaceBetweenFlexDiv>
    </TitleContainer>
  )
}

const renderGeItem = (getSize, item, key) => {
  return (
    <GeItemContainer key={key} getSize={getSize}>
      <StyledText fontSize={getSize(12)}>{item.name}</StyledText>
    </GeItemContainer>
  )
}

export const renderItems = (
  getSize,
  items,
  scope,
  sections,
  keyPrefix = '',
  showBidsTotals = true,
  showSectionSubtotals = false
) => {
  if (_.isEmpty(sections)) {
    const allIds = _.sortBy(_.uniq([..._.keys(scope), ..._.keys(items)]))
    return _.reduce(
      allIds,
      (res, itemId) => {
        const item = {
          ..._.get(items, itemId, {}),
          ..._.get(scope, itemId, {})
        }
        const itemType = _.get(item, 'type', lineItemType.DEFAULT)
        if (itemType === lineItemType.DEFAULT) {
          return [...res, ...renderItemRow(getSize, item, `${keyPrefix}_${itemId}`, showBidsTotals)]
        } else {
          return res
        }
      },
      []
    )
  } else {
    return _.reduce(
      sections,
      (res, s) => {
        if (s.id === lineItemSection.GENERAL_EXCLUSIONS_ID) {
          return res
        } else {
          const sectionItems = []
          const sItems = _.uniq(s.items)
          const sectionSubtotal = _.sumBy(sItems, itemId => _.get(items, [itemId, 'total'], 0))
          _.forEach(sItems, itemId => {
            const item = {
              ..._.get(items, itemId, {}),
              ..._.get(scope, itemId, {})
            }
            const itemType = _.get(item, 'type', lineItemType.DEFAULT)
            if (itemType === lineItemType.DEFAULT) {
              return sectionItems.push(...renderItemRow(getSize, item, `${keyPrefix}_${itemId}`, showBidsTotals))
            }
          })
          if (!_.isEmpty(sectionItems)) {
            return [
              ...res,
              renderSectionTitle({
                getSize,
                title: s.title,
                key: `${keyPrefix}_${s.id}`,
                subtotal: showSectionSubtotals ? sectionSubtotal : undefined
              }),
              ...sectionItems
            ]
          } else {
            return res
          }
        }
      },
      []
    )
  }
}

export const renderGeneralExclusions = (getSize, items, scope, sections, keyPrefix = '') => {
  if (_.isNil(sections)) {
    return []
  } else {
    const s = _.find(sections, s => s.id === lineItemSection.GENERAL_EXCLUSIONS_ID)
    const itemsIds = _.uniq(_.get(s, 'items', []))
    if (_.isEmpty(itemsIds)) {
      return []
    } else {
      const sectionItems = []
      _.forEach(itemsIds, itemId => {
        const item = {
          ..._.get(scope, itemId, {}),
          ..._.get(items, itemId, {})
        }
        // const itemType = _.get(item, 'type', lineItemType.DEFAULT)
        // if (itemType === lineItemType.DEFAULT) {
        return sectionItems.push(renderGeItem(getSize, item, `${keyPrefix}_${itemId}`))
        // }
      })
      if (!_.isEmpty(sectionItems)) {
        return [
          renderSectionTitle({
            getSize,
            title: 'General Exclusions',
            key: `${keyPrefix}_${s.id}`,
            color: colors.CORAL_TWO
          }),
          ...sectionItems
        ]
      } else {
        return []
      }
    }
  }
}

const renderScopeItemRow = (getSize, item, key) => {
  const itemKey = key || _.get(item, 'id')
  const res = [
    <LineItem
      key={`table_row_${itemKey}`}
      getSize={getSize}
      item={item}
      itemId={_.get(item, 'id', key)}
      isCalculated={false}
      showLineWhenEmpty={false}
    />
  ]
  _.forEach(item.alternates, (altItem, altId) => {
    res.push(
      <LineItem
        key={`table_row_${key}_${_.get(altItem, 'id')}`}
        getSize={getSize}
        item={altItem}
        itemId={altId}
        isCalculated={false}
        isAlternate
        showLineWhenEmpty={false}
      />
    )
  })
  return res
}

export const renderScope = (getSize, scope, sections, keyPrefix = '') => {
  if (_.isEmpty(sections)) {
    const allIds = _.sortBy(_.keys(scope))
    return _.reduce(
      allIds,
      (res, itemId) => {
        const item = _.get(scope, itemId, {})
        const itemType = _.get(item, 'type', lineItemType.DEFAULT)
        if (itemType === lineItemType.DEFAULT) {
          return [...res, ...renderScopeItemRow(getSize, item, `${keyPrefix}_${itemId}`)]
        } else {
          return res
        }
      },
      []
    )
  } else {
    return _.reduce(
      sections,
      (res, s) => {
        if (s.id === lineItemSection.GENERAL_EXCLUSIONS_ID) {
          return res
        } else {
          const sectionItems = []
          const sItems = _.uniq(s.items)
          _.forEach(sItems, itemId => {
            const item = _.get(scope, itemId, {})
            const itemType = _.get(item, 'type', lineItemType.DEFAULT)
            if (itemType === lineItemType.DEFAULT) {
              return sectionItems.push(...renderScopeItemRow(getSize, item, `${keyPrefix}_${itemId}`))
            }
          })
          if (!_.isEmpty(sectionItems)) {
            return [
              ...res,
              renderSectionTitle({
                getSize,
                title: s.title,
                key: `${keyPrefix}_${s.id}`
              }),
              ...sectionItems
            ]
          } else {
            return res
          }
        }
      },
      []
    )
  }
}
