import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Box, Text, Button, ResponsiveContext } from 'grommet'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import { loadStripe } from '@stripe/stripe-js'

import Logo from 'components/logos/LogoOnLight'
import colors from 'shared/constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { navigateToHomePage } from 'controllers/init'
import history from 'shared/utils/history'
import universalNavigation from 'utils/universalNavigation'
import PaymentPlans from 'components/payments/PaymentPlans'
import { getPricingPackageConf } from 'controllers/data'
import Loading from 'shared/pages/Loading'
import { createStripeSession } from 'controllers/account'
import { stripeApiKey } from 'shared/config'
import screens from 'constants/screens'
import PreconContext from 'webPages/precon/PreconContext'
import { getCurrentAccountProfile } from 'model/selectors/profiles'

const PreconPaymentPlan = ({ account, accountProfile }) => {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)
  const size = useContext(ResponsiveContext)
  const { preconConf, goToNextScreen } = useContext(PreconContext)
  const [pckg, setPackage] = useState()
  const [prices, setPrices] = useState()
  const accountId = _.get(account, 'id')

  const goNext = useCallback(() => {
    goToNextScreen(screens.PRECON_PAYMENT_PLAN)
  }, [goToNextScreen])

  useEffect(() => {
    const run = async () => {
      const planId = _.get(preconConf, 'plan')
      const res = await getPricingPackageConf(planId)
      if (!_.isEmpty(res)) {
        setPackage(res.pckg)
        setPrices(res.prices)
      } else {
        dispatch(navigateToHomePage())
      }
    }
    if (!_.isEmpty(_.get(account, 'subscription'))) {
      goNext()
    } else {
      run()
    }
  }, [preconConf, dispatch, account, goNext])

  const onPlanClick = async (plan, price) => {
    console.log('onPlanClick', plan, price)
    if (_.has(price, 'id')) {
      setProcessing(true)
      const res = await createStripeSession(price.id, _.get(preconConf, 'id'))
      const sessionId = _.get(res, 'sessionId')
      if (!_.isNil(sessionId)) {
        console.log('createStripeSession res', res)
        const stripe = await loadStripe(stripeApiKey)
        stripe.redirectToCheckout({ sessionId })
      } else {
        setProcessing(false)
      }
    } else {
      const subject = `Custom pricing request by ${_.get(accountProfile, 'name')} (${accountId})`
      window.open(`mailto:team@usemason.com?subject=${subject}`)
    }
  }

  const goBack = () => {
    universalNavigation.goBack()
  }

  if (_.isNil(pckg)) return <Loading />

  if (processing) {
    return <Loading text='Redirecting to checkout page' />
  }

  if (size === 'small') {
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='28px' />
        </Box>
        <Box flex={false} pad={{ horizontal: 'small', vertical: 'medium' }} alignSelf='center' width='large'>
          <Box pad={{ bottom: 'medium' }} direction='row' align='center' gap='small'>
            <Button plain icon={<FontAwesomeIcon icon={faArrowLeft} onClick={goBack} />} />
            <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start' weight={600}>
              Select a plan
            </Text>
          </Box>
          <Box pad={{ top: 'large', bottom: 'large' }}>
            <Text weight={600} textAlign='center' size='large'>
              Tell us your average monthly construction volume and we’ll suggest the plan that’s right for your business
            </Text>
          </Box>
          <PaymentPlans pckg={pckg} prices={prices} onPlanClick={onPlanClick} />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box pad='medium' align='start'>
        <Box
          direction='row'
          flex={false}
          fill='horizontal'
          justify='between'
          align='center'
          margin={{ bottom: 'medium' }}
        >
          <Logo height='32px' />
        </Box>
        <Box flex={false} pad='medium' alignSelf='center' width={{ min: '500px' }}>
          <Box align='start' pad={{ bottom: 'medium' }}>
            <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
              Select a plan
            </Text>
          </Box>
          <Box align='start' pad={{ bottom: 'medium' }}>
            <Text size='large' color={colors.BLACK} textAlign='start' weight={600}>
              Tell us your average monthly construction volume and we’ll suggest the plan that’s right for your business
            </Text>
          </Box>
          <Box gap='small'>
            <PaymentPlans pckg={pckg} prices={prices} onPlanClick={onPlanClick} />
          </Box>
          {_.get(history, 'length', 0) > 0 && (
            <Box pad={{ top: 'medium', horizontal: 'xlarge' }} direction='row' justify='center'>
              <Button
                size='large'
                icon={<FontAwesomeIcon icon={faArrowLeft} size={14} />}
                label='Back'
                color={colors.LINK}
                onClick={goBack}
              />
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  account: state.account,
  accountProfile: getCurrentAccountProfile(state)
})

export default connect(mapStateToProps)(PreconPaymentPlan)
