import React, { useState, useMemo } from 'react'
import { Box, Text, Button, FormField, TextInput, ThemeContext, TextArea, RadioButtonGroup } from 'grommet'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import AddressAutocompleteGrommet from 'components/AddressAutocompleteGrommet'
import { getAddress } from 'shared/utils/stringUtils'
import projectTypes from 'shared/constants/projectTypes'
import workTypes from 'shared/constants/workTypes'
import DatePicker from 'components/DatePicker'
import { defaultTimeZone } from 'shared/constants/index'
import ProjectFiles from 'webPages/projectModal/ProjectFiles'
import universalNavigation from 'utils/universalNavigation'
import screens from 'constants/screens'

const SETUP_PROJECT = '0'
const PROJECT_FILES = '1'

const PreconCreateProjectMobile = ({ projectId, params, goNext, onChange, queryParams }) => {
  const { address, apartment, projectType, typeOfWork, size, attachments, startDate, timeZone, description } = params
  const step = _.get(queryParams, 'mode', SETUP_PROJECT)
  const [addressFocused, setAddressFocused] = useState(false)
  const onChangeApartment = e => onChange('apartment')(_.get(e, 'target.value'))
  const onFocusAddress = () => setAddressFocused(true)
  const onBlurAddress = () => setAddressFocused(false)

  const projectTypesOptions = useMemo(() => {
    return _.map(projectTypes.list, k => ({
      id: k,
      key: k,
      name: projectTypes.stringOfType(k),
      value: k,
      label: projectTypes.stringOfType(k)
    }))
  }, [])
  const onChangeProjectType = e => {
    console.log('onChangeProjectType', e.target.value)
    const projectTypeId = _.get(e, 'target.value')
    if (!_.isEmpty(projectTypeId)) onChange('projectType')(projectTypeId)
  }

  const workTypesOptions = useMemo(() => {
    return _.map(workTypes.list, k => ({ id: k, label: workTypes.stringOfType(k), key: k, value: k }))
  }, [])
  const onChangeTypeOfWork = e => {
    console.log('on change type of work', e.target.value)
    // console.log('onChangeTypeOfWork', value)
    const typeOfWorkId = _.get(e, 'target.value')
    if (!_.isEmpty(typeOfWorkId)) onChange('typeOfWork')(typeOfWorkId)
  }

  const onChangeSize = e => onChange('size')(_.get(e, 'target.value'))

  const onNextClick = () => {
    if (step === SETUP_PROJECT) {
      // setStep(PROJECT_FILES)
      universalNavigation.push(screens.PRECON_PROJECT, { projectId }, { mode: PROJECT_FILES })
    } else {
      goNext()
    }
  }

  const goBack = () => {
    universalNavigation.goBack()
  }

  const disabled = !_.has(params, 'address.timeZone')

  console.log('step', step)

  const renderProjectStep = () => {
    return (
      <Box gap='medium'>
        <Box flex={false} direction='column' margin={{ top: 'medium' }} align='start'>
          <FormField
            label={
              <Text size='small' color={colors.CONTACT_LABEL}>
                Project address
              </Text>
            }
            fill='horizontally'
            round='small'
            margin={{ right: 'small' }}
            error={
              !addressFocused && !_.has(address, 'timeZone') && !_.isEmpty(address) && 'Please enter a valid address'
            }
          >
            <AddressAutocompleteGrommet
              onSelect={onChange('address')}
              value={getAddress(address)}
              placeholder='Type an address...'
              onFocus={onFocusAddress}
              onBlur={onBlurAddress}
            />
          </FormField>
          {_.has(address, 'timeZone') && (
            <FormField margin={{ top: 'small' }} flex basis='1/3' round='small'>
              <TextInput
                color='dark-1'
                maxLength={20}
                value={_.toString(apartment)}
                onChange={onChangeApartment}
                plain
                placeholder='Apt, unit, suite, etc'
              />
            </FormField>
          )}
        </Box>
        <Box pad={{ vertical: 'medium' }} gap='small'>
          <Text size='small' color={colors.CONTACT_LABEL}>
            Project type
          </Text>
          <RadioButtonGroup
            wrap
            direction='row'
            gap='medium'
            value={projectType}
            onChange={onChangeProjectType}
            options={projectTypesOptions}
          />
        </Box>
        <Box pad={{ vertical: 'medium' }} gap='small'>
          <Text size='small' color={colors.CONTACT_LABEL}>
            Commercial or residential
          </Text>
          <RadioButtonGroup
            wrap
            direction='row'
            gap='medium'
            value={typeOfWork}
            onChange={onChangeTypeOfWork}
            options={workTypesOptions}
            color={colors.CONTACT_LABEL}
          />
        </Box>
        <FormField
          round='small'
          label={
            <Text size='small' color={colors.CONTACT_LABEL}>
              Size of project
            </Text>
          }
        >
          <TextInput
            placeholder='in sf, buildable area, etc'
            color='dark-1'
            value={size || ''}
            onChange={onChangeSize}
          />
        </FormField>
        <Box>
          <Text size='small' margin={{ top: 'small' }} color={colors.CONTACT_LABEL}>
            Project timeline
          </Text>
          <Box direction='row' margin={{ vertical: 'small' }} height={{ min: 'xxsmall' }}>
            <DatePicker
              title='Expected Start Date'
              value={startDate}
              timeZone={timeZone || defaultTimeZone}
              onChange={onChange('startDate')}
            />
          </Box>
        </Box>
        <Box>
          <Text size='small' color={colors.CONTACT_LABEL}>
            Notes
          </Text>
          <Box margin={{ vertical: 'small' }} height={{ min: 'xsmall' }}>
            <TextArea
              placeholder='optional'
              value={description || ''}
              onChange={e => onChange('description')(e.target.value)}
              fill
              resize={false}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  const renderFileStep = () => {
    return (
      <Box pad={{ bottom: 'large' }}>
        <ProjectFiles
          files={attachments}
          setFiles={onChange('attachments')}
          storagePath={`/projects/${projectId}/`}
          hideTime
          hideDownload
          pickerButtonProps={{
            ButtonComponent: props => (
              <Box direction='row' align='center' pad={{ vertical: 'small' }} gap='small' {...props}>
                <Box border={{ style: 'dashed', width: '1px' }} pad='small' round='small'>
                  <FontAwesomeIcon icon={faPlus} size={18} color={colors.ANOTHER_GREY} />
                </Box>
                <Text color={colors.ANOTHER_GREY} size='small'>
                  Upload plan file
                </Text>
              </Box>
            )
          }}
        />
      </Box>
    )
  }

  return (
    <Box flex={false} pad={{ horizontal: 'small', vertical: 'medium' }} alignSelf='center' width='large'>
      <Box pad={{ bottom: 'medium' }} direction='row' align='center' gap='small'>
        {step !== SETUP_PROJECT && <Button plain icon={<FontAwesomeIcon icon={faArrowLeft} onClick={goBack} />} />}
        <Text size='xlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start' weight={600}>
          {step === SETUP_PROJECT ? 'Start your project' : 'Upload project plans'}
        </Text>
      </Box>
      <ThemeContext.Extend
        value={{
          formField: { round: 'xsmall' },
          radioButton: {
            size: '18px',
            border: { width: '1px' },
            container: { extend: `color: ${colors.CONTACT_LABEL};` },
            icon: {
              extend: 'border-width: 1px;'
            }
          }
        }}
      >
        {step === SETUP_PROJECT ? renderProjectStep() : renderFileStep()}
      </ThemeContext.Extend>
      <Box pad={{ top: 'medium' }}>
        <Button
          size='large'
          primary
          label='Next'
          color={colors.AQUA_MARINE}
          disabled={disabled}
          onClick={onNextClick}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  accountId: _.get(state, 'account.id'),
  userId: _.get(state, 'user.id')
})

export default connect(mapStateToProps)(PreconCreateProjectMobile)
