import * as types from 'model/actionTypes'

const initialState = { showArchived: false }

export default function (state = initialState, action = '') {
  switch (action.type) {
    case types.UPDATE_SHOW_ARCHIVED: {
      return { ...state, showArchived: action.value }
    }
    default :
      return state
  }
}
