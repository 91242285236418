import { Platform } from 'react-native'
import { isMobileScreen } from 'shared/utils/breakpoints'
import { css } from 'styled-components'

import colors from 'shared/constants/colors'

export const isIos = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isWeb = Platform.OS === 'web'

export const timeZone = 'America/Los_Angeles'
export const defaultTimeZone = timeZone
export const FIRST_PART_LENGTH = 7
export const PHONE_NUMBER_LENGTH = 14
export const UNFORMATTED_PHONE_NUMBER_LENGTH = 12
export const defaultRole = 'owner'

export const PRIVATE_CHAT_CHANNEL = 'private'
export const REGULAR_MARGIN_HORIZONTAL = 16
export const ACTIVE_OPACITY = 0.75

export const MAX_PDF_SIZE = 5000000

export const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outside-click-flag'

export const isMobileWeb = screenViewport => {
  if (!isWeb) {
    return null
  }
  if (!screenViewport) {
    console.warn('isMobileWeb: No screenViewport passed')
    return null
  }
  return isMobileScreen(screenViewport)
}

export const scrollBarStyleOnDark = css`
  scrollbar-width: thin;
  scrollbar-color: ${colors.AS_SCROLL_GREY} ${colors.LIGHT_NAVY_BRIGHT};
  /* Works on Chrome/Edge/Safari */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.LIGHT_NAVY_BRIGHT};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.AS_SCROLL_GREY};
    border-radius: 20px;
    border: 2px solid ${colors.LIGHT_NAVY_BRIGHT};
  }
`
export const scrollBarStyleOnWhite = css`
  scrollbar-width: thin;
  scrollbar-color: ${colors.LIGHT_NAVY_GREY} transparent;
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${colors.LIGHT_NAVY_GREY};
    border-radius: 20px;
    border: 2px solid ${colors.WHITE};
  }
`
