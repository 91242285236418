import React, { useContext } from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import CreateBidContext from 'webPages/createBid/CreateBidContext'
import DiscountInput from 'components/createBid/DiscountInput.web'
import colors from 'shared/constants/colors'
import amountType from 'shared/constants/amountType'

const Deposit = () => {
  const { getField, setDepositType, setDepositValue } = useContext(CreateBidContext)

  const renderDepositInput = () => {
    const deposit = getField('deposit')
    const type = _.get(deposit, 'type', amountType.FLAT_AMOUNT)
    const value = _.get(deposit, 'value')
    return (
      <DiscountInput
        title=''
        discountType={type}
        discountValue={value}
        setDiscountType={setDepositType}
        setDiscountValue={setDepositValue}
        boxProps={{ border: undefined, gap: undefined, pad: undefined }}
        inputBoxProps={{
          customStyle: `color: ${colors.TEXT_PRIMARY};`,
          width: '100%',
          height: '46px'
        }}
        inputProps={{
          size: 'medium',
          customStyle: 'padding: 2px; font-weight: 400;',
          placeholder: 'none'
        }}
        titleProps={{ color: colors.TEXT }}
      />
    )
  }

  return (
    <Box direction='row' align='center' justify='between' pad={{ horizontal: 'medium', top: 'small' }}>
      <Text>Deposit</Text>
      <Box width='220px'>{renderDepositInput()}</Box>
    </Box>
  )
}

export default Deposit
