import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Tip } from 'grommet'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faBellSlash, faStickyNote, faCommentsAlt } from '@fortawesome/pro-light-svg-icons'

import colors from 'shared/constants/colors'
import { getName } from 'shared/utils/stringUtils'
import { stringDiff } from 'shared/utils/date'
import lineItemType from 'shared/constants/lineItemType'
import sendTypes from 'shared/constants/inviteStatus'

const renderName = name => {
  if (_.size(name) > 40) {
    return (
      <Tip content={name}>
        <Text>{_.truncate(name, { length: 40 })}</Text>
      </Tip>
    )
  } else {
    return name
  }
}

const CellTitle = ({ inv, workOrder, scope, selectedAlternates, accountsProfiles, selectedCell, openSubDetails, cellMessages, cellNotes }) => {
  const invId = useMemo(() => inv.id, [inv])
  let desc = (
    <Text alignSelf='end' color={colors.VERY_LIGHT_PINK} size='small'>
      <i>invited</i>
    </Text>
  )
  let subtitle = (
    <Text size='small' alignSelf='start' color={colors.ANOTHER_GREY}>
      {`Invite sent ${stringDiff(moment(), moment(inv.invitedAt))}`}
    </Text>
  )

  const isSilent = _.isEqual(inv.sendType, sendTypes.SILENT)
  const isDelayed = _.isEqual(inv.sendType, sendTypes.LATER)

  if (isDelayed) {
    desc = (
      <Text alignSelf='end' color={colors.SUNFLOWER_YELLOW} size='small'>
        <i>Invite not sent</i>
      </Text>
    )
    subtitle = (
      <Text size='xsmall' alignSelf='start' color={colors.ANOTHER_GREY}>
        Click Send All to send this invitation
      </Text>
    )
  }

  if (inv.acceptedAt) {
    desc = (
      <Text alignSelf='end' color={colors.AQUA_MARINE} size='small'>
        <i>waiting for bid</i>
      </Text>
    )
    if (!_.has(inv, 'bid.total')) {
      subtitle = (
        <Text size='small' alignSelf='start' color={colors.ANOTHER_GREY}>
          {`Invite accepted ${stringDiff(moment(), moment(inv.acceptedAt))}`}
        </Text>
      )
    }
  }
  const bid = _.get(inv, 'bid')
  const plugs = _.get(workOrder, 'plugs')
  const approvedBidId = _.get(workOrder, 'approvedBidId')
  const isWon = !_.isEmpty(approvedBidId) && approvedBidId === _.get(bid, 'id')
  const isDeclined = _.isEqual(_.get(bid, 'status.type'), 'declined') || _.get(inv, 'declinedBy')
  const items = _.get(bid, 'items', {})
  const allItems = useMemo(() => ({ ...scope, ...items }), [scope, items])
  if (!_.isEmpty(bid)) {
    subtitle = null
  }
  const bidTotal = _.get(bid, 'total', 0)

  const { total, plugTotal, showPlug } = useMemo(
    () =>
      _.reduce(
        allItems,
        (res, lineItem, liId) => {
          const type = _.get(lineItem, 'type', lineItemType.DEFAULT)
          if (type === lineItemType.DEFAULT) {
            const altItemId = _.get(selectedAlternates, [invId, liId])
            const altInfo = _.get(items, [liId, 'alternates', altItemId])
            const itemTotal = lineItem.excluded ? 0 : _.get(lineItem, 'total', 0)
            let newTotal = res.total
            let newPlugTotal = res.plugTotal
            const isExcluded = _.get(lineItem, 'excluded', false)
            const plug = _.get(plugs, [liId, invId])
            const itemPlugTotal = isExcluded ? _.get(plug, 'total') : 0
            if (!_.isNil(itemPlugTotal) && isExcluded) {
              const itemPrice = lineItem.excluded ? 0 : _.get(altInfo, 'total', itemTotal)
              newPlugTotal = newPlugTotal - itemPrice + itemPlugTotal
            }
            if (!_.isNil(altInfo) && !lineItem.excluded) {
              newTotal = newTotal - itemTotal + _.get(altInfo, 'total', 0)
            }
            return {
              total: newTotal,
              plugTotal: newPlugTotal,
              showPlug: res.showPlug || (!_.isNil(itemPlugTotal) && isExcluded)
            }
          } else {
            return { ...res }
          }
        },
        { total: bidTotal, plugTotal: bidTotal, showPlug: false }
      ),
    [invId, allItems, bidTotal, items, plugs, selectedAlternates]
  )

  if (!_.isEmpty(bid)) {
    const plugString = showPlug ? `${numeral(plugTotal).format('$ 0,0.[00]')} Internal` : ''
    const totalString = numeral(total).format('$ 0,0.[00]')
    const size = _.size(plugString) + _.size(totalString) > 24 ? 'small' : undefined
    desc = (
      <Box direction='row' align='center' justify='between'>
        <Text alignSelf='end' color={colors.VERY_LIGHT_PINK} truncate size={size}>
          {plugString}
        </Text>
        <Text alignSelf='end' color={colors.LIGHT_NAVY_BRIGHT} weight={500} size={size}>
          {totalString}
        </Text>
      </Box>
    )
  }
  const accountProfile = _.get(accountsProfiles, inv.id)
  const name = accountProfile ? getName(accountProfile) : _.get(inv, 'companyName')
  let nameSize = 'large'
  if (_.size(name) > 20) nameSize = 'medium'
  if (!_.isNil(subtitle)) nameSize = 'small'
  let borderStyle = `border: 2px solid ${colors.PALE_GREY}; border-top-color: ${colors.ALMOST_WHITE};`
  const selected = selectedCell === inv.id
  if (selected) {
    borderStyle = `border: 2px solid ${colors.AQUA_MARINE};`
  }
  return (
    <Box
      gap='xsmall'
      justify='between'
      flex={false}
      fill
      background={colors.WHITE}
      customStyle={borderStyle}
      hoverIndicator={colors.ALMOST_WHITE}
      onClick={() => openSubDetails(inv.id)}
    >
      <Box
        direction='row'
        align='center'
        margin={{ top: 'small', horizontal: 'small' }}
        justify='between'
        gap='small'
      >
        <Text truncate weight={600} color={colors.TEXT} size={nameSize}>
          {renderName(name)}
        </Text>
        <Box direction='row' align='center' gap='xsmall' flex={false}>
          {isDeclined && (
            <Box
              background={colors.CORAL_TWO}
              round
              flex={{ shrink: 0 }}
              pad={{ vertical: 'xxsmall', horizontal: 'xsmall' }}
            >
              <Text truncate size='xsmall' color={colors.WHITE}>
                Declined
              </Text>
            </Box>
          )}
          {isWon && (
            <Box
              background={colors.AQUA_MARINE}
              round
              flex={{ shrink: 0 }}
              pad={{ vertical: 'xxsmall', horizontal: 'xsmall' }}
            >
              <Text truncate size='xsmall' color={colors.WHITE}>
                Won
              </Text>
            </Box>
          )}
          {cellNotes && (
            <Box
              flex={{ shrink: 0 }}
              pad={{ vertical: 'xxsmall', horizontal: 'xxsmall' }}
            >
              <FontAwesomeIcon icon={faStickyNote} size={14} color={colors.LIGHT_NAVY_BRIGHT} />
            </Box>
          )}
          {_.size(cellMessages) > 0 && (
            <Box
              flex={{ shrink: 0 }}
              pad={{ vertical: 'xxsmall', horizontal: 'xxsmall' }}
            >
              <FontAwesomeIcon icon={faCommentsAlt} size={14} color={colors.LIGHT_NAVY_BRIGHT} />
            </Box>
          )}
          {isSilent && (
            <Tip content='Internal Bid – Silently invited without notifications'>
              <Box
                flex={{ shrink: 0 }}
                pad={{ vertical: 'xxsmall', horizontal: 'xxsmall' }}
              >
                <FontAwesomeIcon icon={faBellSlash} size={18} color={colors.LIGHT_NAVY_BRIGHT} />
              </Box>
            </Tip>
          )}
        </Box>
      </Box>
      <Box pad={{ bottom: 'xsmall', horizontal: 'small' }}>
        {subtitle}
        {desc}
      </Box>
    </Box>
  )
}

CellTitle.propTypes = {
  inv: PropTypes.object,
  workOrder: PropTypes.object,
  scope: PropTypes.object,
  selectedAlternates: PropTypes.object,
  accountsProfiles: PropTypes.object,
  selectedCell: PropTypes.string
}

export default CellTitle
