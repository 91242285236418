import { createSelector } from 'reselect'
import _ from 'lodash'
import { getReferences } from 'model/selectors/base'

export const serviceTypesByContractorType = createSelector([getReferences], references => {
  const res = {}
  const contractorTypes = _.get(references, 'contractorTypes', {})
  const projectTypes = _.get(references, 'projectTypes', {})
  const projectTypeGroups = _.get(references, 'projectTypeGroups', {})
  const projectTypesByGroup = {}
  _.forEach(projectTypes, pt => {
    _.set(projectTypesByGroup, [pt.group, pt.id], pt)
  })
  _.forEach(contractorTypes, (ct, ctId) => {
    const groups = _.get(ct, 'groups', [])
    const ctGroups = _.map(groups, gId => {
      const g = _.get(projectTypeGroups, gId, {})
      const groupProjectTypes = _.values(_.get(projectTypesByGroup, gId, {}))
      const gInfo = {
        ...g,
        projectTypes: _.orderBy(groupProjectTypes, ['displayOrder'])
      }
      return gInfo
    })
    _.set(res, ctId, _.orderBy(ctGroups, ['displayOrder']))
  })
  return res
})

export const getTradesDict = createSelector([getReferences], references => {
  const res = {}
  const contractorTypes = _.get(references, 'contractorTypes', {})
  _.forEach(contractorTypes, (ct, ctId) => {
    let name = _.get(ct, 'tradeName')
    if (_.isEmpty(name)) name = _.get(ct, 'name')
    _.set(res, ctId, { ...ct, name, id: ctId })
  })
  return res
})
