export const APPROVERS = 'approvers'
export const CHATS = 'chats'
export const CREATE_ROPOSALS = 'createProposals'
export const CUSTOMER_PAYMENTS = 'customerPayments'
export const EDIT_PROJECTS_PROPOSALS = 'editProjectsProposals'
export const MANAGE_USERS = 'manageUsers'
export const PAYOUT = 'payout'
export const ROLES = 'roles'
export const VIEW_ALL_PROJECTS = 'viewAllProjects'
export const VIEW_GLOBAL_REPORTS = 'viewGlobalReports'
export const PAYMENT_REQUESTS = 'paymentRequests'
export const CAN_ACCEPT_APPOINTMENT = 'canAcceptAppointment'
export const CAN_REASSIGN_APPOINTMENT = 'canReassignAppointment'
export const EDIT_COMPANY_SETTINGS = 'editCompanySettings'


export default {
  APPROVERS,
  CHATS,
  CREATE_ROPOSALS,
  CUSTOMER_PAYMENTS,
  EDIT_PROJECTS_PROPOSALS,
  MANAGE_USERS,
  PAYOUT,
  ROLES,
  VIEW_ALL_PROJECTS,
  VIEW_GLOBAL_REPORTS,
  PAYMENT_REQUESTS,
  CAN_ACCEPT_APPOINTMENT,
  CAN_REASSIGN_APPOINTMENT,
  EDIT_COMPANY_SETTINGS
}
