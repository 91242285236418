import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Router, Switch } from 'navigation/rrouter'
import _ from 'lodash'

import { getAppLoaded } from 'model/selectors/base'
import LoadingWithProgress from 'shared/pages/LoadingWithProgress'
import WebMobilePlaceholder from 'pages/WebMobilePlaceholder'
import NotFound from 'pages/NotFound'
import UpdateApp from 'pages/UpdateApp'
import routes from 'constants/routes'
import history from 'shared/utils/history'
import PrivateRoute from 'components/routes/PrivateRoute'
import PublicRoute from 'components/routes/PublicRoute'
import SettingsLayout from 'pages/settings/SettingsLayout'
import EnterPhone from 'webPages/auth/EnterPhone'
import EnterCode from 'webPages/auth/EnterCode'
import AdditionalInfo from 'pages/auth/AdditionalInfo.web'
import { getViewports } from 'shared/model/selectors/viewportSelector'

// import NoProjectSelected from 'webPages/NoProjectSelected'
import Layout from 'webPages/layout/Layout'
import WorkOrder from 'webPages/WorkOrder'
import Leveling from 'webPages/Leveling'
import BidInvitesList from 'webPages/BidInvitesList'
import ScopeCreationOptions from 'webPages/ScopeCreationOptions'
// import NoScopeNoSubs from 'webPages/NoScopeNoSubs'

import CreateBid from 'webPages/CreateBid'
import CreateBidDataProvider from 'webPages/createBid/CreateBidDataProvider'
import Invitation from 'webPages/Invitation'
import Loading from 'shared/pages/Loading'
import WorkOrderFile from 'webPages/WorkOrderFile'
import SubProposalPreview from 'webPages/SubProposalPreview'
import ProposalToGC from 'webPages/ProposalToGC'
import GcViewBid from 'webPages/GcViewBid'
import EnterEmail from 'webPages/auth/EnterEmail'
import RequestAccess from 'webPages/auth/RequestAccess'
import InviteTeammateResolve from 'webPages/InviteTeammateResolve'
import GCProjectWrapper from 'webPages/GCProjectWrapper'
import Dashboard from 'webPages/Dashboard'
import LevelingDataProvider from 'webPages/leveling/LevelingDataProvider'
import ProjectsList from 'webPages/ProjectsList'
import ShareView from 'webPages/ShareView'
import Contacts from 'pages/settings/SettingsContacts.web'
import PreconCreateProject from 'webPages/precon/PreconCreateProject'
import PreconOrderDetails from 'webPages/precon/PreconOrderDetails'
import PreconPaymentPlan from 'webPages/precon/PreconPaymentPlan'
import PreconInviteTeammates from 'webPages/precon/PreconInviteTeammates'
import PreconBookACall from 'webPages/precon/PreconBookACall'
import PreconDataProvider from 'webPages/precon/PreconDataProvider'

class AppRouter extends Component {
  authRoute = () => {
    return (
      <PublicRoute path={routes.AUTH}>
        <PublicRoute exact path={routes.ENTER_PHONE} component={EnterPhone} />
        <PublicRoute exact path={routes.ENTER_CODE} component={EnterCode} />
        <PublicRoute exact path={routes.ENTER_EMAIL} component={EnterEmail} />
        <PublicRoute exact path={routes.REQUEST_ACCESS} component={RequestAccess} />
        <PublicRoute exact path={routes.AUTH} component={EnterPhone} />
      </PublicRoute>
    )
  }

  settingsRoute = () => {
    return <Route path={routes.SETTINGS}>{props => <SettingsLayout params={_.get(props, 'location.state')} />}</Route>
  }

  onboardingRoute = () => {
    const { appLoaded } = this.props
    if (!_.isNil(appLoaded)) return <LoadingWithProgress progress={appLoaded} />
    return <PublicRoute exact path={routes.ONBOARDING} component={AdditionalInfo} />
  }

  gcRoute = () => {
    const { appLoaded } = this.props
    if (!_.isNil(appLoaded)) return <LoadingWithProgress progress={appLoaded} />
    return (
      <Route path={routes.GC_APP}>
        <Switch>
          <Route path={[routes.GC_PROJECT_WORKORDER, routes.GC_PROJECT]}>
            {props => {
              console.log('%cprops', 'color: green; font-style: bold;', props)
              const projectId = _.get(props, 'location.state.projectId', _.get(props, 'match.params.projectId'))
              const workOrderId = _.get(props, 'location.state.workOrderId', _.get(props, 'match.params.workOrderId'))
              return (
                <Layout params={{ projectId, workOrderId }}>
                  <PublicRoute
                    exact
                    path={[routes.GC_PROJECT, routes.GC_PROJECT_WORKORDER, routes.GC_PROJECT_WORKORDER_LEVELING]}
                  >
                    <LevelingDataProvider projectId={projectId} workOrderId={workOrderId} key={projectId}>
                      <GCProjectWrapper projectId={projectId} workOrderId={workOrderId}>
                        <PublicRoute exact path={routes.GC_PROJECT} component={Dashboard} />
                        <PublicRoute exact path={routes.GC_PROJECT_WORKORDER} component={WorkOrder} />
                        <PublicRoute exact path={routes.GC_PROJECT_WORKORDER_LEVELING} component={Leveling} />
                      </GCProjectWrapper>
                    </LevelingDataProvider>
                  </PublicRoute>
                  <PublicRoute
                    exact
                    path={routes.GC_PROJECT_WORKORDER_SELECT_OPTION}
                    component={ScopeCreationOptions}
                  />
                  {/* <PublicRoute exact path={routes.GC_PROJECT_WORKORDER_NO_SCOPE_NO_SUBS} component={NoScopeNoSubs} /> */}
                  <PublicRoute exact path={routes.GC_PROJECT_SHARE} component={ShareView} />
                </Layout>
              )
            }}
          </Route>
          <Route path={routes.GC_APP}>
            <Layout>
              <PublicRoute exact path={routes.GC_APP} component={ProjectsList} />
              <PublicRoute exact path={routes.GC_VIEW_BID} component={GcViewBid} />
              <PublicRoute exact path={routes.GC_CONTACTS} component={Contacts} />
            </Layout>
          </Route>
        </Switch>
      </Route>
    )
  }

  subRoute = () => {
    const { appLoaded } = this.props
    if (!_.isNil(appLoaded)) return <LoadingWithProgress progress={appLoaded} />
    return (
      <Route path={routes.SUB_APP}>
        {props => {
          const params = _.get(props, 'location.state')
          return (
            <Layout params={params}>
              <CreateBidDataProvider
                key={_.get(params, 'workOrderId', _.get(params, 'projectId'))}
                {...props}
                {...params}
              >
                <PublicRoute exact path={routes.SUB_CREATE_BID_PREVIEW} component={SubProposalPreview} />
                <PublicRoute exact path={routes.SUB_CREATE_BID} component={CreateBid} />
                <PublicRoute exact path={routes.SUB_CREATE_PROPOSAL} component={CreateBid} />
              </CreateBidDataProvider>
              <PublicRoute exact path={routes.SUB_APP} component={BidInvitesList} />
            </Layout>
          )
        }}
      </Route>
    )
  }

  preconRoute = () => {
    const { appLoaded } = this.props
    if (!_.isNil(appLoaded)) return <LoadingWithProgress progress={appLoaded} />
    return (
      <Route path={routes.PRECON}>
        {props => (
          <PreconDataProvider {..._.get(props, 'location.state', {})} {..._.get(props, 'match.params', {})}>
            <PublicRoute exact path={routes.PRECON_PROJECT} component={PreconCreateProject} />
            <PublicRoute exact path={routes.PRECON_ORDER_DETAILS} component={PreconOrderDetails} />
            <PublicRoute exact path={routes.PRECON_PAYMENT_PLAN} component={PreconPaymentPlan} />
            <PublicRoute exact path={routes.PRECON_TEAMMATES} component={PreconInviteTeammates} />
            <PublicRoute exact path={routes.PRECON_BOOK_A_CALL} component={PreconBookACall} />
          </PreconDataProvider>
        )}
      </Route>
    )
  }

  filePreviewRoute = () => {
    const { appLoaded } = this.props
    if (!_.isNil(appLoaded)) return <LoadingWithProgress progress={appLoaded} />
    return (
      <Layout params={{}}>
        <PrivateRoute path={routes.WORKORDER_FILE} component={WorkOrderFile} />
      </Layout>
    )
  }

  renderRoutes () {
    const { authData } = this.props
    if (_.isNil(authData)) return <Loading />
    return (
      <Switch>
        {this.authRoute()}
        <PublicRoute exact path={routes.WEB_MOBILE_PLACEHOLDER} component={WebMobilePlaceholder} />
        <PublicRoute exact path={routes.NOT_FOUND} component={NotFound} />
        <PublicRoute exact path={routes.UPDATE_APP} component={UpdateApp} />
        <PublicRoute exact path={routes.INVITATION} component={Invitation} />
        <PublicRoute exact path={routes.ACCEPT_INVITE} component={InviteTeammateResolve} />
        <PublicRoute exact path={routes.PROPOSAL_TO_GC} component={ProposalToGC} />
        {this.preconRoute()}
        {this.gcRoute()}
        {this.subRoute()}
        {this.settingsRoute()}
        {this.onboardingRoute()}

        <Route path={routes.WORKORDER_FILE}>{this.filePreviewRoute()}</Route>
      </Switch>
    )
  }

  render () {
    return <Router history={history}>{this.renderRoutes()}</Router>
  }
}

const mapStateToProps = state => ({
  ...getViewports(state, {}),
  appLoaded: getAppLoaded(state),
  authData: state.authData
})

export default connect(mapStateToProps)(AppRouter)
