import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import qs from 'qs'

import InvalidUrl from 'components/InvalidUrl'
import ProposalInfo from 'webPages/proposalToGC/Proposalnfo'
import screens from 'constants/screens'
import universalNavigation from 'utils/universalNavigation'
import { processSubProposalId } from 'controllers/data'
import Loading from 'shared/pages/Loading'
import { navigateToHomePage } from 'controllers/init'
import { getSubProposalInfo, setSubProposalId, setOpenPageOnAuth } from 'controllers/auth'
import signInMode from 'constants/signInMode'

class ProposalToGC extends Component {
  constructor (props) {
    super(props)
    const queryParams = qs.parse(_.get(props, 'location.search'), { ignoreQueryPrefix: true })
    console.log('invitation page queryParams', queryParams)
    const invId = _.get(queryParams, 'r')
    console.log('invId', invId)
    this.state = {
      loading: true,
      invId
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.loading && !_.isEmpty(state.invId) && !_.isEmpty(props.authData)) {
      console.log('GDSFP work orders received')
      console.log('-----> workOrder does not exist, start processInvId ')
      if (!state.skipNextProcessInvId) {
        processSubProposalId(state.invId).then(res => {
          console.log('processInvId res', res)
          const bidId = _.get(res, 'bidId')
          console.log('bidId', bidId)
          if (bidId) {
            universalNavigation.navigate(screens.GC_VIEW_BID, { bidId })
          } else {
            props.dispatch(navigateToHomePage())
          }
        })
      } else {
        console.log('---> skip')
      }
      return {
        skipNextProcessInvId: true
      }
    }
    return null
  }

  componentWillUnmount = () => {
    this.onInvitationInfoReceived = () => null
  }

  componentDidMount = async () => {
    const { authData } = this.props
    const { invId } = this.state
    if (_.isNil(invId) && _.isEmpty(authData)) {
      // no invId and user is not autorized, go to auth page
      universalNavigation.navigate(screens.AUTH)
    } else if (_.isEmpty(authData)) {
      const invInfo = await getSubProposalInfo(invId)
      console.log('invInfo', invInfo)
      this.onInvitationInfoReceived(invInfo)
    }
  }

  onInvitationInfoReceived = async invInfo => {
    // TODO: check the invitation does not exist
    this.setState({
      loading: false,
      invInfo
    })
  }

  reset = () => {
    universalNavigation.navigate(screens.ENTER_PHONE)
  }

  onNext = async () => {
    console.log('onNext')
    const { invId, invInfo } = this.state
    await setSubProposalId(invId)
    await setOpenPageOnAuth(screens.GC_VIEW_BID, {
      bidId: invInfo.bidId
    })
    universalNavigation.navigate(screens.ENTER_PHONE, { mode: signInMode.SUB_PROPOSAL })
  }

  render () {
    const { loading, invInfo } = this.state
    if (loading) {
      return <Loading />
    } else if (_.isNil(invInfo)) {
      return <InvalidUrl onNext={this.reset} />
    } else {
      return <ProposalInfo invInfo={invInfo} onNext={this.onNext} />
    }
  }
}

const mapStateToProps = state => ({
  authData: state.authData,
  workOrders: state.workOrders,
  account: state.account
})

export default connect(mapStateToProps)(ProposalToGC)
