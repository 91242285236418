import React, { useRef } from 'react'
import { Box, Select, ThemeContext, TextInput, Keyboard } from 'grommet'
import { css } from 'styled-components'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import colors from 'shared/constants/colors'

const InputWithPrefix = ({
  value,
  onChange,
  onSelectChange,
  selectValue,
  options,
  onSubmit,
  inputBoxProps = {},
  inputProps = {},
  ...rest
}) => {
  const inputRef = useRef()
  const onEnter = () => {
    inputRef.current.blur()
  }
  return (
    <ThemeContext.Extend
      value={{
        select: {
          control: {
            extend: css`
              > div input {
                padding-right: 3px;
                font-weight: 400;
              }
              > div > div {
                width: 26px;
                margin: 0;
                padding-right: 0;
              }
            `
          },
          container: {
            extend: css`
              button > div {
                align-items: center;
              }
            `
          }
        },
        textInput: {
          container: {
            extend: css`
              display: flex;
              width: 100%;
            `
          }
        }
      }}
    >
      <Box
        direction='row'
        border={{ side: 'all', color: colors.LIGHT_NAVY_BRIGHT }}
        round='xsmall'
        height={'38px'}
        pad={{ right: 'xsmall' }}
        width={'195px'}
        background={colors.WHITE}
        {...inputBoxProps}
      >
        <Select
          options={options}
          onChange={onSelectChange}
          size='medium'
          value={selectValue}
          plain
          labelKey='label'
          valueKey='id'
          textAlign='end'
          icon={<FontAwesomeIcon icon={faChevronDown} size={10} />}
          {...rest}
        />
        <Keyboard onEnter={onEnter}>
          <TextInput
            value={_.toString(value)}
            onChange={onChange}
            plain
            size='large'
            placeholder='0'
            onBlur={onSubmit}
            ref={inputRef}
            textAlign='end'
            customStyle={'padding: 2px; font-weight: 600;'}
            {...inputProps}
            {...rest}
          />
        </Keyboard>
      </Box>
    </ThemeContext.Extend>
  )
}

export default InputWithPrefix
