import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Text, Box } from 'grommet'
import numeral from 'numeral'

import colors from 'shared/constants/colors'
import InputWithPrefix from 'components/InputWithPrefix.web'
import amountType from 'shared/constants/amountType'

const DiscountInput = ({
  discountType = amountType.FLAT_AMOUNT,
  setDiscountType,
  discountValue,
  setDiscountValue,
  title,
  boxProps,
  inputBoxProps,
  titleProps,
  inputProps,
  ...rest
}) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    setValue(null)
  }, [discountValue])

  // console.log('DiscountInput, discountType', discountType)

  const options = {
    [amountType.FLAT_AMOUNT]: { id: amountType.FLAT_AMOUNT, label: '$' },
    [amountType.PERCENTAGE]: { id: amountType.PERCENTAGE, label: '%' }
  }

  const onChange = event => {
    const newValue = event.target.value.replace(/[^0-9.,]/g, '')
    // console.log('valueRaw', valueRaw, 'newValue', newValue)
    if (!_.isEmpty(_.toString(newValue))) {
      const newV = numeral(newValue).value()
      const curV = numeral(value).value()
      // console.log('newV', newV, 'curV', curV)
      if (newV === curV || _.isNil(value)) {
        setValue(newValue)
      } else {
        setValue(numeral(newValue).format('0,0.[00]'))
      }
    } else {
      setValue('')
    }
  }

  const onChangeType = ({ value }) => {
    console.log('onChangeType', value)
    setDiscountType(_.get(value, 'id'))
  }

  const onSubmit = () => {
    console.log('onSubmit')
    if (!_.isNil(value)) {
      setDiscountValue(numeral(value).value())
    } else {
      console.log('value was not change, nothing to save')
    }
  }

  let curValue = discountValue
  if (!_.isEmpty(_.toString(curValue))) {
    curValue = numeral(curValue).format('0,0.[00]')
  }
  if (!_.isNil(value)) curValue = value
  if (_.isNil(curValue)) curValue = ''

  return (
    <Box border='right' direction='row' gap='small' pad={{ right: 'small' }} align='center' {...boxProps}>
      <Text alignSelf='center' color={colors.LIGHT_NAVY_BRIGHT} {...titleProps}>
        {title}
      </Text>
      <InputWithPrefix
        onChange={onChange}
        onSelectChange={onChangeType}
        value={curValue}
        selectValue={_.get(options, discountType || amountType.FLAT_AMOUNT)}
        options={_.values(options)}
        onSubmit={onSubmit}
        inputBoxProps={inputBoxProps}
        inputProps={inputProps}
        {...rest}
      />
    </Box>
  )
}

DiscountInput.defaultProps = {
  title: 'Discounts:',
  boxProps: {},
  inputBoxProps: {},
  inputProps: {},
  titleProps: {}
}

DiscountInput.propTypes = {
  title: PropTypes.string,
  discountType: PropTypes.string,
  setDiscountType: PropTypes.func,
  discountValue: PropTypes.number,
  setDiscountValue: PropTypes.func,
  boxProps: PropTypes.object,
  inputProps: PropTypes.object
}

export default DiscountInput
