import React from 'react'
import { Box, Text } from 'grommet'
import _ from 'lodash'

import colors from 'shared/constants/colors'
import CellContainer from 'components/leveling/CellContainer'
import { cutString } from 'shared/utils/stringUtils'
import MessagesIcon from 'components/leveling/MessagesIcon'
import StatusPendingAnswer from 'components/leveling/cellElements/StatusPendingAnswer'

const AnswerText = ({ answer, selected, onClick, hasMessages, hasNewMessage, compact }) => {
  if (!_.isNil(answer)) {
    if (compact) {
      return (
        <CellContainer selected={selected} onClick={onClick} direction='row' fill>
          <Box
            justify='center'
            align='center'
            alignSelf='start'
            gap='xsmall'
            background={colors.VERY_LIGHT_PINK}
            width='78px'
          >
            <Text weight={600} color={colors.WHITE} size='xsmall'>
              Text Answer
            </Text>
          </Box>
          <Box direction='row' justify='center' align='center'>
            {hasMessages && (
              <MessagesIcon hasNewMessage={hasNewMessage} size={14} customStyle={null} />
            )}
          </Box>
        </CellContainer>
      )
    }
    return (
      <CellContainer selected={selected} onClick={onClick}>
        <Box direction='row'>
          <Box
            direction='row'
            justify='center'
            alignSelf='center'
            gap='xsmall'
            align='center'
            background={colors.VERY_LIGHT_PINK}
            width='78px'
          >
            <Text weight={600} color={colors.WHITE} size='xsmall'>
              Text Answer
            </Text>
          </Box>
        </Box>
        <Box fill direction='row' align='start' pad={{ horizontal: 'xsmall', vertical: 'xsmall' }} gap='small'>
          <Box fill>
            <Text size='xsmall' color={colors.DARK_GRAY_TWO} customStyle={'line-height: 1.4'}>
              <i>{cutString(answer, 130)}</i>
            </Text>
          </Box>
        </Box>
        {hasMessages && (
          <Box margin={{ horizontal: 'xxsmall', vertical: 'xxsmall' }} alignSelf='end'>
            <MessagesIcon hasNewMessage={hasNewMessage} />
          </Box>
        )}
      </CellContainer>
    )
  } else {
    return (
      <CellContainer selected={selected} onClick={onClick}>
        <Box fill direction='row' align='start'>
          <StatusPendingAnswer />
        </Box>
      </CellContainer>
    )
  }
}

export default AnswerText
