// import { Platform } from 'react-native'
import * as Analytics from 'expo-firebase-analytics'
import _ from 'lodash'
import { isWeb } from 'shared/constants/index'

export function trackScreen (screenName) {
  console.log('analitycs: track screen', screenName)
  Analytics.setCurrentScreen(screenName)
}

export function trackUserId (userId) {
  Analytics.setUserId(userId)
    .then()
    .catch(e => console.log('trackUserId err', e))
  // console.log('analitycs: track userId', userId)
  if (isWeb && typeof FS !== 'undefined') {
    FS.identify(userId)
  }
}

export function trackUserParams (params) {
  console.log('analitycs: trackUserParams', params)
  Analytics.setUserProperties(params)
    .then()
    .catch(e => console.log('trackUserParams err', e))
  if (_.has(params, 'id') && isWeb && typeof FS !== 'undefined') {
    FS.setUserVars({ ...params, dislayName: _.get(params, 'username') })
  }
}

export function trackAppLaunch () {}

export function trackEvent (kind, params = {}) {
  console.log('analitycs: trackEvent', kind, params)
  Analytics.logEvent(kind, params)
    .then()
    .catch(e => console.log('trackEvent err', e))
}

export function trackDeepLinkUrl (url) {}

export function enableUninstallTracking () {}

export const category = {
  ONBOARD: 'onboard',
  CASHOUT: 'cashout',
  ACCOUNT: 'account'
}

export default {
  trackScreen,
  trackUserId,
  trackUserParams,
  trackEvent
}
