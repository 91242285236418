import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import Document from 'shared/documents/components/Document'
import { getSizeF } from 'shared/documents/utils/dynamicSize'
import { A4K, PAGE_WIDTH } from 'shared/constants/documents'
import DocHeader from 'shared/documents/components/DocHeader'
import { getName, getAddressLine2 } from 'shared/utils/stringUtils'
import DocContacts from 'shared/documents/components/DocContacts'
import { formatNational } from 'shared/utils/phone'
import DocTableHeader from 'shared/documents/components/DocTableHeader'
import colors from 'shared/constants/colors'
import renderProjectSummary from 'shared/reports/components/projectSummary'
import renderTradeBid from 'shared/reports/components/tradeBid'
import reportWorkOrderOption from 'shared/constants/reportWorkOrderOption'
import reportSetting from 'shared/constants/reportSetting'
import { getSelectedBidOption } from 'shared/utils/reports'

const SelectedBidsReport = ({
  width,
  paddingHorizontalK,
  paddingVerticalK,
  ssr,
  pageProps,
  pageNum,
  onPageClick,
  activeOpacity,
  doc
}) => {
  const [docDate, setDocDate] = useState(_.now())

  const height = width * A4K
  // const paddingHorizontal = width * paddingHorizontalK
  // console.log('width', width, 'paddingHorizontalK', paddingHorizontalK, 'paddingHorizontal', paddingHorizontal)
  const getSize = useMemo(() => getSizeF({ width }, paddingHorizontalK, ssr), [width, paddingHorizontalK, ssr])
  const reportSettings = _.get(doc, 'reportSettings')

  const contactsBlock = useMemo(() => {
    const profile = _.get(doc, 'gcAccountProfile')
    const project = _.get(doc, 'project')
    const preparedBy = _.filter(
      [
        getName(profile),
        _.get(profile, 'address.name', ''),
        getAddressLine2(_.get(profile, 'address')),
        _.get(profile, 'email', ''),
        formatNational(_.get(profile, 'phone', ''))
      ],
      v => !_.isEmpty(v)
    )
    const preparedFor = _.filter(
      [_.get(project, 'address.name'), getAddressLine2(_.get(project, 'address'))],
      v => !_.isEmpty(v)
    )
    return (
      <DocContacts
        getSize={getSize}
        preparedFor={preparedFor}
        preparedBy={preparedBy}
        titleLeft='Project:'
        customStyle={`padding-top: ${getSize(20)}px`}
      />
    )
  }, [doc, getSize])

  const renderSummary = useCallback(() => {
    const showSummary = reportSetting.getValue(reportSettings, reportSetting.SHOW_SUMMARY)
    if (showSummary) {
      return [
        <DocTableHeader
          key='project_summary_header'
          getSize={getSize}
          title='PROJECT SUMMARY'
          color={colors.BLACK}
          customStyle='justify-content: center;'
        />,
        ...renderProjectSummary({
          getSize,
          reportSettings,
          workOrders: doc.workOrders,
          bidsByWorkOrders: doc.bidsByWorkOrders,
          contractorTypes: doc.contractorTypes,
          accountsProfiles: doc.accountsProfiles
        })
      ]
    } else {
      return []
    }
  }, [reportSettings, doc, getSize])

  const renderTrades = useCallback(() => {
    const bidsByWorkOrders = _.get(doc, 'bidsByWorkOrders')
    const showSubName = reportSetting.getValue(reportSettings, reportSetting.SHOW_SUB_NAME)
    const showSectionSubtotals = reportSetting.getValue(reportSettings, reportSetting.SHOW_SECTION_SUBTOTALS)
    const showBidsTotals = reportSetting.getValue(reportSettings, reportSetting.SHOW_BIDS_TOTALS)
    let res = []
    _.forEach(doc.workOrders, wo => {
      const bids = _.get(bidsByWorkOrders, wo.id)
      const bidsAmount = _.size(bids)
      if (bidsAmount === 0) {
        return
      }
      const t = getSelectedBidOption(reportSettings, wo.id, bids)
      if (_.size(t) > 1 && _.isArray(t)) {
        _.forEach(t, (bidId, index) => {
          const bid = _.get(bidsByWorkOrders, [wo.id, bidId])
          if (bid) {
            const subName = showSubName ? getName(_.get(doc, ['accountsProfiles', bid.accountId])) : undefined
            res.push(
              renderTradeBid({
                getSize,
                bid,
                wo,
                contractorTypes: doc.contractorTypes,
                showBidsTotals,
                subName,
                showSectionSubtotals,
                index: index + 1,
                bidsAmount: _.size(t)
              })
            )
          }
        })
      } else {
        if (t !== reportWorkOrderOption.HIDE && t !== reportWorkOrderOption.EXCLUDED) {
          const bid = _.get(bidsByWorkOrders, [wo.id, t])
          if (bid) {
            const subName = showSubName ? getName(_.get(doc, ['accountsProfiles', bid.accountId])) : undefined
            res.push(
              renderTradeBid({
                getSize,
                bid,
                wo,
                contractorTypes: doc.contractorTypes,
                showBidsTotals,
                subName,
                showSectionSubtotals
              })
            )
          }
        }
      }
    })
    res = _.flatten(res)

    const tradesHeader = (
      <DocTableHeader
        key='project_details_header'
        getSize={getSize}
        title='PROJECT DETAILS'
        color={colors.BLACK}
        customStyle={`padding-top: ${getSize(80)}px; justify-content: center;`}
      />
    )
    if (!_.isEmpty(res)) {
      res = [tradesHeader, ...res]
    }
    return res
  }, [reportSettings, doc, getSize])

  const showCompanyLogo = reportSetting.getValue(reportSettings, reportSetting.SHOW_COMPANY_LOGO)
  const companyLogo = showCompanyLogo
    ? _.get(doc, 'gcAccountProfile.avatar', _.get(doc, 'gcAccountProfile.avatarSmall'))
    : undefined
  const timeZone = _.get(doc, 'project.address.timeZone')

  return (
    <Document
      paddingHorizontal={width * paddingHorizontalK}
      paddingVertical={height * paddingVerticalK}
      pageWidth={width}
      pageHeight={height}
      ssr={ssr}
      pageProps={pageProps}
      pageNum={pageNum}
      onPageClick={onPageClick}
      activeOpacity={activeOpacity}
    >
      <DocHeader
        getSize={getSize}
        companyName={getName(doc.gcAccountProfile)}
        companyLogo={companyLogo}
        title='PROJECT REPORTS'
        docDate={moment.tz(docDate, timeZone).format('L')}
      />
      {contactsBlock}
      {renderSummary()}
      {renderTrades()}
    </Document>
  )
}

SelectedBidsReport.defaultProps = {
  width: PAGE_WIDTH,
  paddingHorizontalK: 0,
  paddingVerticalK: 0,
  ssr: false,
  pageProps: {}
}

const docType = {
  gcAccountProfile: PropTypes.object,
  project: PropTypes.object,
  reportSettings: PropTypes.object,
  workOrders: PropTypes.array,
  bidsByWorkOrders: PropTypes.object,
  contractorTypes: PropTypes.object,
  accountsProfiles: PropTypes.object
}

SelectedBidsReport.propTypes = {
  width: PropTypes.number,
  paddingHorizontalK: PropTypes.number,
  paddingVerticalK: PropTypes.number,
  ssr: PropTypes.bool,
  pageProps: PropTypes.object,
  pageNum: PropTypes.number,
  onPageClick: PropTypes.func,
  activeOpacity: PropTypes.number,
  doc: PropTypes.shape(docType)
}

export default React.memo(SelectedBidsReport)
