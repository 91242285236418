import React, { Component } from 'react'
import {
  Layer,
  Button,
  Box,
  Text,
  FormField,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ThemeContext
} from 'grommet'
import _ from 'lodash'
import validator from 'validator'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'

import colors from 'shared/constants/colors'
import { FIRST_PART_LENGTH } from 'shared/constants/index'
import { generateId } from 'constants/firebase'

class CreateClientModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  open = (name = '') => {
    this.setState({
      visible: true,
      name,
      email: '',
      phone: '',
      errors: {}
    })
  }

  close = () => {
    this.setState({ visible: false })
  }

  onChangeName = e => this.setState({ name: e.target.value })
  onChangeEmail = e => this.setState({ email: e.target.value })

  phoneIsValid = () => {
    const { phone } = this.state
    if (_.isNil(phone) || phone === '') {
      return false
    } else {
      const phoneNumber = parsePhoneNumberFromString(phone, 'US')
      if (phoneNumber) {
        return phoneNumber.isValid()
      } else {
        return false
      }
    }
  }

  createClient = () => {
    const { phone, email, name } = this.state
    const { onClientCreated } = this.props
    const errors = {}
    if (!this.phoneIsValid()) _.set(errors, 'phone', 'Enter a valid phone number')
    if (!validator.isEmail(email)) _.set(errors, 'email', 'Enter a valid email address')
    if (name.length === 0) _.set(errors, 'name', 'Enter the client name')
    if (!_.isEmpty(errors)) {
      this.setState({ errors })
    } else {
      console.log('create client')
      const id = generateId()
      const profile = {
        phone: parsePhoneNumberFromString(phone, 'US').number,
        name,
        email,
        id
      }
      onClientCreated(profile)
      this.close()
    }
  }

  resetErrors = () => this.setState({ errors: {} })

  onChangePhone = event => {
    const v = event.target.value
    console.log('onPhoneCnahge', v)
    if (v && v.length < FIRST_PART_LENGTH) {
      this.setState({ phone: v })
    } else if (v) {
      const aYT = new AsYouType('US')
      const formattedPhone = aYT.input(v)
      this.setState({
        phone: formattedPhone
      })
    }
  }

  render () {
    const { visible, name, email, phone, errors } = this.state
    // console.log('projectAddress', address, 'timeZone', timeZone)
    const formFieldHeight = '120px'
    if (visible) {
      return (
        <Layer onEsc={this.close} onClickOutside={this.close}>
          <Card>
            <CardHeader
              pad='medium'
              border={{
                color: 'border',
                size: '1px',
                side: 'bottom'
              }}
            >
              <Text size='xlarge' color={colors.BLACK} weight={600}>
                Create a new client
              </Text>
            </CardHeader>
            <CardBody pad={{ horizontal: 'medium', top: 'medium' }} overflow='auto' wrap={false} width='large'>
              <ThemeContext.Extend value={{ formField: { round: 'xsmall' } }}>
                <Box flex height={{ min: formFieldHeight }}>
                  <FormField label='Company Name' flex round='small' error={errors.name}>
                    <TextInput
                      placeholder='Enter the client name'
                      color='dark-1'
                      maxLength={20}
                      value={name}
                      onChange={this.onChangeName}
                      plain
                      onFocus={this.resetErrors}
                    />
                  </FormField>
                </Box>
                <Box flex height={{ min: formFieldHeight }}>
                  <FormField label='Company Email address' flex round='small' error={errors.email}>
                    <TextInput
                      placeholder='examplea@company.com'
                      color='dark-1'
                      maxLength={100}
                      value={email}
                      onChange={this.onChangeEmail}
                      plain
                      onFocus={this.resetErrors}
                    />
                  </FormField>
                </Box>
                <Box flex height={{ min: formFieldHeight }}>
                  <FormField label='Company Phone number' flex round='small' error={errors.phone}>
                    <TextInput
                      placeholder='(xxx) xxx-xxxx'
                      color='dark-1'
                      maxLength={20}
                      value={phone}
                      onChange={this.onChangePhone}
                      plain
                      onFocus={this.resetErrors}
                    />
                  </FormField>
                </Box>
              </ThemeContext.Extend>
            </CardBody>
            <CardFooter align='center' justify='end'>
              <Button
                primary
                margin='medium'
                label={'Create'}
                onClick={this.createClient}
                customStyle='border-radius: 4px'
              />
            </CardFooter>
          </Card>
        </Layer>
      )
    } else {
      return null
    }
  }
}

CreateClientModal.propTypes = {
  onClientCreated: PropTypes.func.isRequired
}

export default CreateClientModal
