import React from 'react'
import styled from 'sc'
import { View, Image } from 'react-native'
import _ from 'lodash'

import { StyledText } from 'shared/documents/components/StyledComponents'
import colors from 'shared/constants/colors'
import { getName } from 'shared/utils/stringUtils'
import DocAvatar from 'shared/documents/components/DocAvatar'
import { getInitials } from 'shared/utils/stringUtils'
import { formatNational } from 'shared/utils/phone'

const Container = styled(View)`
  width: ${props => props.pageWidth}px;
  /* height: ${props => props.pageHeight}px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  /* background-color: lightgray; */
`
const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
`

const BackgroundOveraly = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.getSize(160)}px;
  background-color: rgba(51, 51, 51, 0.73);
`
const HeaderContainer = styled(View)`
  position: relative;
`
const BottomLeftContainer = styled(View)`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`
const TitleContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${props => props.getSize(10)}px;
`
const TitleTextContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: ${props => props.getSize(20)}px;
  height: ${props => props.getSize(32)}px;
`
const AboutTextContainer = styled(View)`
  margin-top: ${props => props.getSize(10)}px;
`

const ContentContainer = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.getSize(200)}px;
`
const LeftColumn = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: ${props => props.getSize(20)}px;
`

const RightColumn = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: ${props => props.getSize(20)}px;
  padding-top: ${props => props.getSize(10)}px;
`
const ServiceRow = styled(View)`
  margin-bottom: ${props => props.getSize(10)}px;
  display: flex;
  flex-direction: row;
`
const GroupHeaderContainer = styled(View)`
  margin-top: ${props => props.getSize(20)}px;
`


const defaultImageUri = 'https://firebasestorage.googleapis.com/v0/b/mason-dev.appspot.com/o/assets%2Four_services_header.png?alt=media&token=bf9accf1-067d-49b7-af08-9ee4945bbb85'

export const renderTeammates = (getSize, width, height, ssr, teammates) => {
  let owner
  const members = []
  _.forEach(teammates, m => {
    if (!owner && m.role === 'owner') {
      owner = m
    } else {
      members.push(m)
    }
  })

  const renderGroupHeader = gName => {
    return (
      <GroupHeaderContainer getSize={getSize}>
        <StyledText fontSize={getSize(12)} color={colors.AQUA_MARINE}>{gName}</StyledText>
      </GroupHeaderContainer>
    )
  }

  const renderMember = (m) => {
    return (
      <TitleContainer getSize={getSize} key={m.id}>
        <DocAvatar
          size={getSize(40)}
          ssr={ssr}
          url={_.get(m, 'avatarSmall', _.get(m, 'avatar'))}
          initials={getInitials(m)}
        />
        <TitleTextContainer getSize={getSize}>
          <StyledText fontSize={getSize(16)}>{getName(m)}</StyledText>
          <StyledText fontSize={getSize(10)}>{formatNational(_.get(m, 'phone'))}</StyledText>
        </TitleTextContainer>
      </TitleContainer>
    )
  }

  return (
    <Container pageWidth={width} pageHeight={height - 1} ssr={ssr} getSize={getSize} key='teammates'>
       <HeaderContainer getSize={getSize}>
        <StyledText
          // color={colors.WHITE}
          fontSize={getSize(32)}
          textAlign='center'
          bold
        >
          Contacts
        </StyledText>
      </HeaderContainer>
      {owner && renderGroupHeader('Owner')}
      {owner && renderMember(owner)}
      {!_.isEmpty(members) && renderGroupHeader('Team Members')}
      {_.map(members, renderMember)}
    </Container>
  )
}
