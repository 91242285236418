import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Text } from 'grommet'
import _ from 'lodash'
import { loadStripe } from '@stripe/stripe-js'

import colors from 'shared/constants/colors'
import PaymentPlans from 'components/payments/PaymentPlans'
import { getPricingPackageConf } from 'controllers/data'
import Loading from 'shared/pages/Loading'
import { createStripeSession, createCustomerPortalSession, cancelSubscription } from 'controllers/account'
import { stripeApiKey } from 'shared/config'
import { getCurrentAccountProfile } from 'model/selectors/profiles'

const SettingsBilling = () => {
  const [pckg, setPackage] = useState()
  const [prices, setPrices] = useState()
  const [processing, setProcessing] = useState(false)

  const companyName = useSelector(state => _.get(getCurrentAccountProfile(state), 'name'))
  const accountId = useSelector(state => _.get(state, 'account.id'))
  const subscription = useSelector(state => _.get(state, 'account.subscription'))
  const planId = useSelector(state => _.get(state, 'references.defaultPricingPackageId'))

  const status = _.get(subscription, 'status')
  const isActive = status === 'active' || status === 'trialing'
  const invoiceUrl = _.get(subscription, 'invoice.url')

  useEffect(() => {
    const run = async () => {
      const res = await getPricingPackageConf(planId)
      if (!_.isEmpty(res)) {
        setPackage(res.pckg)
        setPrices(res.prices)
      }
    }
    if (_.isEmpty(subscription)) {
      run()
    }
  }, [subscription, planId])

  const onPlanClick = async (plan, price) => {
    console.log('onPlanClick', plan, price)
    if (_.has(price, 'id')) {
      setProcessing(true)
      const res = await createStripeSession(price.id, planId)
      const sessionId = _.get(res, 'sessionId')
      if (!_.isNil(sessionId)) {
        console.log('createStripeSession res', res)
        const stripe = await loadStripe(stripeApiKey)
        stripe.redirectToCheckout({ sessionId })
      } else {
        setProcessing(false)
      }
    } else {
      const subject = `Custom pricing request by ${companyName} (${accountId})`
      window.open(`mailto:team@usemason.com?subject=${subject}`)
    }
  }

  const toCustomerPortal = async () => {
    setProcessing(true)
    const res = await createCustomerPortalSession()
    setProcessing(false)
    if (_.has(res, 'sessionUrl')) {
      window.open(res.sessionUrl)
    }
  }

  const toInvoice = () => {
    window.open(invoiceUrl)
  }

  const onCancelSubscription = async () => {
    console.log('onCancelSubscription')
    setProcessing(true)
    await cancelSubscription()
    setProcessing(false)
  }

  if (processing) {
    let message = 'Redirecting to Customer Portal'
    if (!isActive) {
      message = _.isEmpty(subscription) ? 'Redirecting to checkout page' : 'Processing'
    }
    return <Loading text={message} />
  } else if (_.isNil(pckg) && _.isEmpty(subscription)) {
    return <Loading />
  } else if (isActive) {
    return (
      <Box fill='horizontal' align='center' pad={{ top: 'large' }}>
        <Button primary color={colors.AQUA_MARINE} label='Open Customer Portal' onClick={toCustomerPortal} loading />
      </Box>
    )
  } else if (!_.isEmpty(invoiceUrl)) {
    const planName = _.get(subscription, 'planName', '')
    return (
      <Box fill='horizontal' align='center' pad={{ top: 'large' }}>
        <Box pad={{ bottom: 'large' }}>
          <Text size='large' color={colors.TEXT_PRIMARY}>
            Your{' '}
            <Text weight={600} size='large' color={colors.LIGHT_NAVY_BRIGHT}>
              {planName}
            </Text>{' '}
            subscription is not active
          </Text>
        </Box>
        <Box width='280px' gap='small'>
          <Button primary color={colors.AQUA_MARINE} label='Add a payment method' onClick={toInvoice} loading />
          <Text textAlign='center' color={colors.ANOTHER_GREY}>
            or
          </Text>
          <Button
            primary
            color={colors.CORAL_TWO}
            label='Cancel current subscription'
            onClick={onCancelSubscription}
            loading
          />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box fill='horizontal' direction='column' align='center' overflow={{ vertical: 'auto' }} gap='medium'>
        <Text size='xxlarge' color={colors.LIGHT_NAVY_BRIGHT} textAlign='start'>
          Select a plan
        </Text>
        <Box flex={{ shrink: 0 }} pad={{ bottom: 'medium' }}>
          <PaymentPlans pckg={pckg} prices={prices} onPlanClick={onPlanClick} hideDesc />
        </Box>
      </Box>
    )
  }
}

export default SettingsBilling
